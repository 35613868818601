import React, { useMemo } from 'react'

import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MTableCell from '@mui/material/TableCell'
import MTableFooter from '@mui/material/TableFooter'
import MTableRow from '@mui/material/TableRow'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

import { useDataManager } from './DataManager'
import useStyles from './styles'

const PAGE_SIZE_OPTIONS = [10, 25, 50]

function PaginationMenu({count, value, setValue}) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'paginationMenu' })
  const classes = useStyles()

  return (
    <>
      <span className={classes.paginationMenu} {...bindTrigger(popupState)} aria-label="Items per page">
        {count}
      </span>
      <Menu {...bindMenu(popupState)}>
        {PAGE_SIZE_OPTIONS.map(pageSize => (
          <MenuItem key={pageSize} selected={pageSize === value} onClick={() => { setValue(pageSize); popupState.close() }}>{pageSize}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

function makePagePartitions(page, maxPage) {
  const partitions = []
  const currentRange = [Math.max(1, page-4), Math.min(maxPage, page+4)]
  partitions.push(currentRange)
  if(currentRange[0] > 1) {
    currentRange[0]++
    partitions.unshift([1, 1])
  }
  if(currentRange[1] < maxPage) {
    currentRange[1]--
    partitions.push([maxPage, maxPage])
  }
  return partitions.map(([start, end]) => [...Array(end-start+1)].map((_,i) => start+i))
}

function PrevNextIcon({Icon, onClick, disabled, label}) {
  const classes = useStyles()

  return (
    <div className={`${classes.paginatorWidget} ${classes.paginatorPrevNext}`} onClick={disabled ? null : onClick} disabled={disabled} aria-disabled={disabled} aria-label={label}>
      <Icon className={classes.paginatorIcon}/>
    </div>
  )
}

function Paginator({page, maxPage, setPage}) {
  const classes = useStyles()

  const pagePartitions = useMemo(() => makePagePartitions(page, maxPage), [page, maxPage])

  return (
    <div className={classes.paginator} aria-label="pagination">
      <PrevNextIcon Icon={ChevronLeftIcon} onClick={() => setPage(Math.max(page-1, 1))} disabled={page <= 1} label="Previous page"/>
      <div className={classes.paginatorWidget}>
        {pagePartitions.map((pages, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className={classes.paginatorDivider}>. . .</div>
            )}
            {pages.map(p => (
              <div
                key={p}
                className={`${classes.paginatorPage} ${p === page ? classes.paginatorPageActive : ''}`}
                onClick={p === page ? null : () => setPage(p)}
                role="link"
                aria-label={`Page ${p}`}
                aria-current={p === page && 'page'}
              >
                {p}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      <PrevNextIcon Icon={ChevronRightIcon} onClick={() => setPage(Math.min(page+1, maxPage))} disabled={page >= maxPage} label="Next page"/>
    </div>
  )
}

export default function PaginationControls() {
  const classes = useStyles()
  const dataManager = useDataManager()

  const maxPage = Math.ceil(dataManager.totalCount / dataManager.pageSize)

  return (
    <MTableFooter className={classes.tfoot}>
      <MTableRow>
        <MTableCell
          className={classes.td}
          colSpan={dataManager.getColSpan()}
        >
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              Showing <PaginationMenu count={dataManager.data.length} value={dataManager.pageSize} setValue={dataManager.setPageSize.bind(dataManager)}/> of {dataManager.totalCount} entries
            </Grid>
            <Grid item>
              <Paginator page={dataManager.page} maxPage={maxPage} setPage={dataManager.setPage.bind(dataManager)}/>
            </Grid>
          </Grid>
        </MTableCell>
      </MTableRow>
    </MTableFooter>
  );
}
