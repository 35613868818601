import { isObject } from "lodash-es"
import { createContext, ReactNode, useContext, useMemo } from "react"

export type Feature = boolean | (Record<string, string> & { enabled: boolean })
export type Features = Record<string, Feature>
export type FeatureConfig = Record<string, string>

class FeatureManager {
  features: Features
  isFeatureFlagEnabled: (feature: string) => boolean
  getFeatureConfig: <T,>(feature: string, key: string, defaultValue: T) => T

  constructor(features: Features) {
    this.features = features

    this.isFeatureFlagEnabled = (feature: string, defaultEnabled = false) => {
      const value = this.features[feature]
      if(isObject(value)) {
        return value.enabled ?? defaultEnabled
      }
      else {
        return value ?? defaultEnabled
      }
    }

    this.getFeatureConfig = <T,>(feature: string, key: string, defaultValue: T): T => {
      const value = this.features[feature]
      if(isObject(value) && key in value) {
        return value[key] as T
      }

      return defaultValue
    }
  }
}

interface FeatureProviderProps {
  features: Features
  children: ReactNode
}

const FeaturesContext = createContext(new FeatureManager({}))

export default function FeaturesProvider({ features, children }: FeatureProviderProps) {
  const featureManager = useMemo(() => new FeatureManager(features), [features])

  return (
    <FeaturesContext.Provider value={featureManager}>
      {children}
    </FeaturesContext.Provider>
  )
}

export function useFeatures(): FeatureManager {
  return useContext(FeaturesContext)
}
