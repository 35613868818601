import { useSuspenseQuery } from "@apollo/client"
import Suspenseful from '../../../../Suspenseful'
import BookendsProvider from "lib/BookendsProvider"
import LandingPageEditNavigation from "./Navigation/LandingPageEditNavigation"
import LandingPageProvider from "../LandingPageProvider"
import { GET_LANDING_PAGE_QUERY } from "../../data/queries"
import { tabs } from "../../data/constants"
import MainPage from "./MainPage/MainPage"
import QuestionsPage from "./QuestionsPage"
import RulesPage from "./RulesPage/RulesPage"
import TrackingPage from "./TrackingPage/TrackingPage"
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"


function LandingPageEditForm() {
  return (
    <LandingPageEditNavigation>
      <Routes>
        <Route path="" element={<MainPage />}/>
        <Route path={tabs.MAIN} element={<MainPage />}/>
        <Route path={tabs.TRACKING} element={<TrackingPage />}/>
        <Route path={tabs.RULES} element={<RulesPage />}/>
        <Route path={tabs.QUESTIONS} element={<QuestionsPage />}/>
        <Route path="*" element={<Navigate to="main"/>}/>
      </Routes>
    </LandingPageEditNavigation>
  )
}

function LandingPageNew({ landingPage }) {
  return (
    <BookendsProvider>
      <LandingPageProvider initialState={landingPage} isRefetching={false}>
        <LandingPageEditForm />
      </LandingPageProvider>
    </BookendsProvider>
  )
}

function LandingPageLoader({ id, queryKey }) {
  // Refetch the landingPage from the server when navigating to or within the LPE as determined by the queryKey
  // Use isRefetching flag to create a loader overlay and avoid displaying a loader on a blank/white page.
  // useSuspenseQuery will not update data if the request returns the same response
  // In order to cause the page to reload, and the loader to disappear,
  // use useState to store the response as a new response object
  // (as mutating the useRef .current property alone doesn’t cause a re-render)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: initialData, refetch } = useSuspenseQuery(GET_LANDING_PAGE_QUERY, { variables: { id: id }, fetchPolicy: 'network-only' })
  const [data, setData] = useState(initialData)
  useEffect(() => {
    setData(initialData)
  }, [initialData])
  const queryKeyRef = useRef(queryKey)
  const isRefetchingRef = useRef(false)

  if(queryKeyRef.current !== queryKey) {
    queryKeyRef.current = queryKey
    isRefetchingRef.current = true
    refetch().then(({ data }) => {
      isRefetchingRef.current = false
      setData({ ...data })
    })
  }

  return (
    <BookendsProvider>
      <LandingPageProvider initialState={data.landingPage} isRefetching={isRefetchingRef.current}>
        <LandingPageEditForm />
      </LandingPageProvider>
    </BookendsProvider>
  )
}

export default function LandingPageEdit() {
  const { landingPageId } = useParams()
  const location = useLocation()

  if(landingPageId) {
    return (
      <Suspenseful component={LandingPageLoader} id={landingPageId} queryKey={location.key} />
    )
  } else if(location.state?.['landingPage']) {
    return <LandingPageNew landingPage={location.state.landingPage}/>
  } else {
    return <Navigate to=".."/>
  }
}
