import Box from "@mui/material/Box";
import StatusPanel from "./StatusPanel";
import { userEvent, within } from "@storybook/test";

export default {
  title: "UI/StatusPanel",
  component: StatusPanel,
  parameters: {
    zeplinLink: "https://zpl.io/llJO6A5",
    layout: "fullscreen",
  },
};

const Template = (args) => {
  return (
    <Box sx={{ height: "300px" }}>
      <StatusPanel {...args} />
    </Box>
  );
};

export const Default = Template.bind({});
Default.args = {
  statuses: [
    {
      variant: "success",
      tipTitle: (
        <Box>
          <Box>Interview: 2/28/23</Box>
          <Box>Application: 2/17/23</Box>
        </Box>
      ),
      statusText: "Data valid through 2/28/23",
    },
    {
      variant: "warning",
      tipTitle: (
        <Box>
          <Box>Column sorting and filtering</Box>
          <Box>not applied to full data set.</Box>
        </Box>
      ),
      statusText: "Data truncated to 10,000 lines",
    },
    {
      variant: "error",
      tipTitle: "error details",
      statusText: "error status text",
    },
  ],
};

export const LeftIcon = Template.bind({});
LeftIcon.args = {
  ...Default.args,
  leftIcon: true,
};

export const ShowTooltip = Template.bind({});
ShowTooltip.args = {
  ...Default.args,
};

ShowTooltip.play = async ({ canvasElement }) => {
  const StatusPanel = await within(canvasElement).findByTestId(
    "CheckCircleOutlineIcon",
  );

  await userEvent.pointer({ target: StatusPanel, coords: { clientX: 10, clientY: 10 }});
};
