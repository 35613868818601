import MTableCell from '@mui/material/TableCell'
import { TableCellProps } from '@mui/material/TableCell'
import useStyles from './styles'
import { sum } from '../../lib/utils'
import { compact } from 'lodash-es'
import { MouseEvent, MouseEventHandler } from 'react'
import { Column } from 'components/SimpleTable';


function getColumnCount(tr) {
  return sum([...tr.children], 'colSpan')
}

const toggleHover = (hoverClass, isHover) => {
  return event => {
    const td = event.target.closest('td')
    if(!td) {
      return
    }
    let tr = td.closest('tr')
    if(!tr) {
      return
    }
    const tbody = tr.closest('tbody')
    if(!tbody) {
      return
    }

    let colsHovered = 0
    const numCols = getColumnCount(tbody.querySelector('tr'))
    for(; tr && colsHovered < numCols; tr = tr.previousElementSibling) {
      const curCols = getColumnCount(tr);
      if(curCols > colsHovered) {
        [...tr.children].slice(0, curCols - colsHovered).forEach(prevCol => {
          prevCol.classList.toggle(hoverClass, isHover)
        })
        colsHovered = curCols
      }
    }
  }
}

function mergeEventHandlers(...eventHandlers) {
  eventHandlers = compact(eventHandlers)
  if(eventHandlers.length === 0) {
    return undefined
  }
  return (event) => {
    eventHandlers.forEach(eventHandler => {
      eventHandler(event)
    })
  }
}

type Row = Record<string, number | string | null>

export interface BaseHoverableTableCellProps extends Omit<TableCellProps, 'onClick'> {
  row?: Row
  column?: Column
  renderContext?: unknown
  hover: unknown
  hoverClass: unknown
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  className: string
}

export interface ClickableTableCellProps {
  row: Row
  onClick: (event: MouseEvent, row: Row, ...eventContexts: ([unknown] | [])) => void
  eventContext: unknown
}

export interface NonclickableTableCellProps {
  row?: never
  onClick?: never
  eventContext?: never
}

export default function HoverableTableCell({
  row,
  hover,
  hoverClass,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  eventContext,
  renderContext: _renderContext,
  ...props
} : BaseHoverableTableCellProps & (ClickableTableCellProps | NonclickableTableCellProps)) {
  const classes = useStyles()

  return (
    <MTableCell
      className={[className, onClick && classes.clickable].filter(Boolean).join(' ')}
      onMouseEnter={mergeEventHandlers(onMouseEnter, hover ? toggleHover(hoverClass, true) : null)}
      onMouseLeave={mergeEventHandlers(onMouseLeave, hover ? toggleHover(hoverClass, false) : null)}
      onClick={onClick ? event => { onClick(event, row, ...(eventContext ? [eventContext] : [])) } : undefined}
      {...props}
    />
  )
}
