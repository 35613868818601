import { get } from 'lodash-es'
import { useFormikContext } from 'formik'
import { FormTextField } from "components/Formik/forms"
import { useLandingPageStateContext } from '../ui/LandingPageProvider'

export default function DefaultedTextField({name, label, ...props }) {
  const { landingPage } = useLandingPageStateContext()
  const { values } = useFormikContext()
  const skeletonLandingPageValue = get(landingPage.skeletonLandingPage, name, null)
  const showDefaultValue = (get(values, name, null) === null) && (skeletonLandingPageValue || props['defaultValue'])

    return (
      <FormTextField
        name={name}
        label={showDefaultValue ? `${label} (default)` : label}
        defaultValue={skeletonLandingPageValue || ''}
        {...props}
      />
    )
}
