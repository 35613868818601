import AllocationsTable from '../../../AllocationsTable'

import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom'
import MainContainer from 'components/layout/MainContainer/MainContainer'
import FooterBar from 'components/UI/FooterBar'
import BookendsProvider, { useBookends } from 'lib/BookendsProvider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import HeaderTab from 'components/UI/HeaderTab/HeaderTab'
import PieChartIcon from '@mui/icons-material/PieChart';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForumIcon from '@mui/icons-material/Forum';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useCurrentUser } from 'lib/CurrentUserProvider'
import DegreePrograms from '../../DegreePrograms'
import FieldsEdit from '../../FieldsEdit'
import VendorTable from '../../VendorTable'
import DegreeProgramEdit from '../../DegreeProgramEdit'
import { useEffect } from 'react'
import InvoicePage from '../Invoice/InvoicePage'
import { useFeatures } from 'providers/FeaturesProvider'


const tabs = {
  ALLOCATIONS: 'allocations',
  INVOICES: 'invoices',
  PROGRAMS: 'degree-programs',
  FIELDS: 'fields',
  VENDORS: 'vendors',
} as const

function HeaderTabs({ match, pathPrefix }) {
  const selectedTab = match?.params?.tab || tabs.ALLOCATIONS
  const navigate = useNavigate()
  const { setBookends } = useBookends()
  const { isFeatureFlagEnabled } = useFeatures()
  const isInvoiceEnabled = isFeatureFlagEnabled('invoice')

  // clear footer buttons on navigate so they don't remain on pages that don't reset bookend buttons
  useEffect(() => {
    setBookends({
      footer: {
        buttons: []
      }
    })
  }, [match, setBookends])

  return (
    <Tabs
      value={selectedTab}
      onChange={(_event, newValue) => navigate(`${pathPrefix}/${newValue}`)}
      sx={{ marginTop: '9px' }}
    >
      <HeaderTab
        icon={<PieChartIcon />}
        value={tabs.ALLOCATIONS}
      />
      {isInvoiceEnabled &&
        <HeaderTab
          icon={<RequestPageOutlinedIcon />}
          value={tabs.INVOICES}
        />
      }
      <HeaderTab
        icon={<AccountTreeIcon />}
        value={tabs.PROGRAMS}
      />
      <HeaderTab
        icon={<ForumIcon />}
        value={tabs.FIELDS}
      />
      <HeaderTab
        icon={<StorefrontIcon />}
        value={tabs.VENDORS}
      />
    </Tabs>
  )
}

function ManagePageNavigation({ children, pathPrefix }) {
  const match = useMatch({ path: `${pathPrefix}/:tab/*`, end: false })

  return (
    <BookendsProvider>
      <MainContainer>
        <Paper
          sx={{
            padding: '18px 24px',
            boxShadow: 0,
          }}
        >
          <Typography variant='h1'>Manage</Typography>

          <HeaderTabs match={match} pathPrefix={pathPrefix} />

          {children}
        </Paper>
      </MainContainer>

      <FooterBar/>
    </BookendsProvider>
  )
}

export default function ClientManagePage() {
  const { currentUser, currentOrganization } = useCurrentUser()
  const { clientId } = useParams()
  const pathPrefix = `/clients/${clientId}`

  const { isFeatureFlagEnabled } = useFeatures()
  const isInvoiceEnabled = isFeatureFlagEnabled('invoice')

  return (
    <ManagePageNavigation pathPrefix={pathPrefix}>
      <Routes>
        <Route path={tabs.ALLOCATIONS} element={<AllocationsTable hasTabNavigation={true} />} />
        {isInvoiceEnabled &&
          <Route path={tabs.INVOICES} element={<InvoicePage clientId={clientId} />} />
        }
        <Route path={tabs.PROGRAMS}>
          <Route index element={<DegreePrograms />}/>
          <Route path="new" element={<DegreeProgramEdit />}/>
          <Route path=":degreeProgramId">
            <Route index element={<DegreeProgramEdit />}/>
          </Route>
        </Route>
        <Route path={tabs.FIELDS} element={<FieldsEdit clientId={clientId} />}/>
        <Route path={tabs.VENDORS} element={<VendorTable currentOrganization={currentOrganization} currentUser={currentUser} hasTabNavigation={true} />}/>
        {/* With `manage` removed from path, this is needed to redirect non-existent pages (starting with /client/:clientId/) to the dashboard  */}
        <Route path="*" element={<Navigate to={`${pathPrefix}/dashboard`}/>}/>
      </Routes>
    </ManagePageNavigation>
  )
}
