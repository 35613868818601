import { gql } from "@apollo/client"
import { LANDING_PAGE_FIELDS } from "fragments/LandingPageFields"
import { constantize, deepEqual, pluralize } from "lib/utils"
import { isEqual, has, isPlainObject, find, omit } from "lodash-es"
import { isNewRecord } from "./utils"


const LANDING_PAGE_MUTATION_FIELDS = `
  $variables: LandingPageVariablesInput
  $tags: LandingPageTagsInput
  $clientWeights: [ClientWeightInput!]
  $fallbackLeadTarget: LeadTargetInput
  $createLandingPageRules: [LandingPageRuleInput!]
  $updateLandingPageRules: [LandingPageRuleUpdateInput!],
  $destroyLandingPageRules: [ID!]
  $createLeadTargets: [LeadTargetInput!]
  $updateLeadTargets: [LeadTargetUpdateInput!]
  $destroyLeadTargets: [ID!]
  $createClientCampaignRules: [ClientCampaignRuleInput!]
  $updateClientCampaignRules: [ClientCampaignRuleUpdateInput!]
  $destroyClientCampaignRules: [ID!]
  $createDegreeProgramRules: [DegreeProgramRuleInput!]
  $updateDegreeProgramRules: [DegreeProgramRuleUpdateInput!]
  $destroyDegreeProgramRules: [ID!]
  $createRoutes: [RouteInput!]
  $updateRoutes: [RouteUpdateInput!]
  $destroyRoutes: [ID!]
  $createSteps: [StepInput!]
  $updateSteps: [StepUpdateInput!]
  $destroySteps: [ID!]
`

const LANDING_PAGE_MUTATION_INPUT_MAPPING = `
  name: $name
  templateId: $templateId
  image1Url: $image1Url
  image2Url: $image2Url
  duplicateRedirectUrl: $duplicateRedirectUrl
  variables: $variables
  tags: $tags
  clientWeights: $clientWeights
  fallbackLeadTarget: $fallbackLeadTarget
  createLandingPageRules: $createLandingPageRules
  updateLandingPageRules: $updateLandingPageRules
  destroyLandingPageRules: $destroyLandingPageRules
  createLeadTargets: $createLeadTargets
  updateLeadTargets: $updateLeadTargets
  destroyLeadTargets: $destroyLeadTargets
  createClientCampaignRules: $createClientCampaignRules
  updateClientCampaignRules: $updateClientCampaignRules
  destroyClientCampaignRules: $destroyClientCampaignRules
  createDegreeProgramRules: $createDegreeProgramRules
  updateDegreeProgramRules: $updateDegreeProgramRules
  destroyDegreeProgramRules: $destroyDegreeProgramRules
  createRoutes: $createRoutes
  updateRoutes: $updateRoutes
  destroyRoutes: $destroyRoutes
  createSteps: $createSteps
  updateSteps: $updateSteps
  destroySteps: $destroySteps
`

const LANDING_PAGE_MUTATION_RETURNS = `
  id
  errors
  landingPage {
    ...LandingPageFields
  }
`

export const UPDATE_LANDING_PAGE_MUTATION = gql`
  ${LANDING_PAGE_FIELDS}
  mutation UpdateLandingPage(
    $id: ID!
    $name: String
    $templateId: ID
    $image1Url: String
    $image2Url: String
    $duplicateRedirectUrl: String
    ${LANDING_PAGE_MUTATION_FIELDS}
  ) {
    mutationResult:updateLandingPage (input: {
      id: $id
      ${LANDING_PAGE_MUTATION_INPUT_MAPPING}
    }) {
      ${LANDING_PAGE_MUTATION_RETURNS}
    }
  }
`

export const CREATE_LANDING_PAGE_MUTATION = gql`
  ${LANDING_PAGE_FIELDS}
  mutation CreateLandingPage(
    $name: String!
    $templateId: ID!
    $image1Url: String
    $image2Url: String
    $duplicateRedirectUrl: String
    ${LANDING_PAGE_MUTATION_FIELDS}
  ) {
    mutationResult:createLandingPage (input: {
      ${LANDING_PAGE_MUTATION_INPUT_MAPPING}
    }) {
      ${LANDING_PAGE_MUTATION_RETURNS}
    }
  }
`

export function buildQuestionMutation(origQuestions, revisedQuestions, revisedQuestionIds) {
  const createQuestions = []
  const updateQuestions = []
  const destroyQuestions = []
  const addAndUpdateQuestions = []

  revisedQuestions.forEach(revisedQuestion => {
    if(!isNewRecord(revisedQuestion)) {
      const origQuestion = origQuestions.find(origQuestion => origQuestion.id === revisedQuestion.id)

      if(origQuestion) {
        const updatedAttributes = Object.fromEntries(
          Object.entries(revisedQuestion).filter(([key, newValue]) => (
            !deepEqual(origQuestion[key], newValue)
          ))
        )

        if(Object.keys(updatedAttributes).length !== 0) {
          updateQuestions.push({ id: revisedQuestion.id, ...updatedAttributes })
        }
      } else {
        addAndUpdateQuestions.push(revisedQuestion)
      }
    } else {
      const newAttributes = { ...revisedQuestion }
      delete newAttributes.id
      createQuestions.push(newAttributes)
    }
  })

  origQuestions.map(origQuestion => {
    if(!revisedQuestionIds.includes(origQuestion.id)) {
      destroyQuestions.push(origQuestion.id)
    }
  })

  return { createQuestions, updateQuestions, destroyQuestions, addAndUpdateQuestions }
}

function modifyAttributes(questionMutations, attributes) {
  Object.keys(questionMutations).forEach(key => {
    if(questionMutations[key].length > 0) {
      attributes[key] = questionMutations[key]
    }
  })
  delete attributes.questions
}

export function buildStepMutation(origSteps, revisedSteps) {
  const revisedQuestionIds = revisedSteps.flatMap(step => step.questions.map(question => question.id)).filter(id => id)
  const createSteps = []
  const updateSteps = []
  const destroySteps = []

  revisedSteps.forEach(revisedStep => {
    if(revisedStep.id) {
      const origStep = origSteps.find(origStep => origStep.id === revisedStep.id)

      const updatedAttributes = Object.fromEntries(
        Object.entries(revisedStep).filter(([key, newValue]) => (
          !deepEqual(origStep?.[key], newValue)
        ))
      )

      if(Object.keys(updatedAttributes).length !== 0) {
        if('questions' in updatedAttributes) {
          const questionMutations = buildQuestionMutation(origStep?.questions || [], revisedStep.questions, revisedQuestionIds)
          modifyAttributes(questionMutations, updatedAttributes)
        }
        if(Object.keys(updatedAttributes).length !== 0) {
          updateSteps.push({ id: revisedStep.id, ...updatedAttributes })
        }
      }
    } else {
      const newAttributes = { ...revisedStep }
      if('questions' in newAttributes) {
        const questionMutations = buildQuestionMutation([], revisedStep.questions, revisedQuestionIds)
        modifyAttributes(questionMutations, newAttributes)
      }

      delete newAttributes.id
      createSteps.push(newAttributes)
    }
  })

  origSteps.map(origStep => {
    if(!revisedSteps.some(revisedStep => revisedStep.id === origStep.id)) {
      destroySteps.push(origStep.id)
    }
  })

  return { createSteps, updateSteps, destroySteps }
}

function buildMutationForArray(oldArray, newArray, key) {
  const create = []
  const update = []
  const destroy = []

  const keyPlural = constantize(pluralize(key))

  newArray.forEach(entry => {
    if (!isNewRecord(entry)) {
      const oldEntry = find(oldArray, { id: entry.id })
      const mutation = buildMutation(oldEntry, entry)
      if(!isEqual(Object.keys(mutation), ['id'])) {
        update.push(mutation)
      }
    } else {
      create.push(buildMutation({}, entry))
    }
  })

  oldArray.map(oldEntry => {
    if (!newArray.some(newRoute => newRoute.id === oldEntry.id)) {
      destroy.push(oldEntry.id)
    }
  })

  const result = {}
  if(create.length > 0) {
    result[`create${keyPlural}`] = create
  }
  if(update.length > 0) {
    result[`update${keyPlural}`] = update
  }
  if(destroy.length > 0) {
    result[`destroy${keyPlural}`] = destroy
  }
  return result
}

function buildMutationForSingleton(oldObject, newObject, key) {
  const result = {}
  if(!isEqual(oldObject, newObject)) {
    if(!newObject) {
      result[key] = null
    }
    else {
      result[key] = omit(buildMutation({}, newObject), ["id"])
    }
  }

  return result
}

function isRecord(value) {
  return isPlainObject(value) && has(value, 'id')
}

function isArrayOfRecords(value) {
  return Array.isArray(value) && value.some(isRecord)
}

function ensureArray(value) {
  return Array.isArray(value) ? value : []
}

function setIfChanged(object, key, oldValue, newValue) {
  if(!isEqual(oldValue, newValue)) {
    object[key] = newValue
  }
}

/** A value is ambiguous if it may be an array of records or it may be an array
 * of non-records. This is true of it's an empty array, or null/undefined.
 */
function isAmbiguous(value) {
  return value === null || value === undefined || (Array.isArray(value) && value.length === 0)
}

/* TODO: Use the GraphQL schema, taking the parent object's __typename into
+ * account to determine the type of association. Note that this will require
+ * adding __typename to newly-built records, e.g. in buildLandingPage().
+ */
function isHasOneAssociation(_parent, key) {
  return key === 'fallbackLeadTarget'
}

export function buildMutation(oldObject, newObject) {
  const mutation = {}

  Object.entries(newObject).forEach(([key, newValue]) => {
    const oldValue = oldObject[key]

    if(key === 'id') {
      if(!isNewRecord(newObject)) {
        mutation.id = newValue
      }
    }
    else if(isArrayOfRecords(newValue) || isArrayOfRecords(oldValue)) {
      Object.assign(mutation, buildMutationForArray(ensureArray(oldValue), ensureArray(newValue), key))
    }
    else if(isRecord(newValue) || isRecord(oldValue)) {
      if(isHasOneAssociation(newObject, key)) {
        Object.assign(mutation, buildMutationForSingleton(oldValue, newValue, key))
      }
      else {
        const oldId = isRecord(oldValue) ? oldValue.id : null
        const newId = isRecord(newValue) ? newValue.id : null
        setIfChanged(mutation, `${key}Id`, oldId, newId)
      }
    }
    else if (!isAmbiguous(oldValue) || !isAmbiguous(newValue)) {
      setIfChanged(mutation, key, oldValue, newValue)
    }
  })

  return mutation
}
