import { useState, useCallback, useEffect } from 'react'
import MultiStepModalContainer from '../../../../UI/MultiStepModal/MultiStepModalContainer'
import LeadTargetForm from './LeadTargetForm'
import ClientsForm from './ClientsForm'
import ClientWeightForm from './ClientWeightForm'
import LeadTargetsConfirmation from './LeadTargetsConfirmation'
import { removeItemAtIndex } from '../../data/utils'
import { useNotifications } from 'lib/NotificationsProvider'
import { useSuspenseQuery } from '@apollo/client'
import { GET_CLIENTS_QUERY } from '../../data/queries'
import { uniq } from 'lodash-es'
import { recomputeDependencies } from '../../data/helpers'

function checkForMissingSelections(selectedLeadTargets, selectedClientIds) {
  let clientsWithoutSelections = [...selectedClientIds]

  selectedLeadTargets.some(leadTarget => {
    const leadTargetClientId = leadTarget.clientCampaign.programGroup.client.id
    const idIndex = clientsWithoutSelections.indexOf(leadTargetClientId)
    if(idIndex > -1) {
      clientsWithoutSelections = removeItemAtIndex(clientsWithoutSelections, idIndex)
    }

    return clientsWithoutSelections.length === 0
  })
  return clientsWithoutSelections
}

export default function PortalProgramSelectionModal({landingPage: initialLandingPage, onClose, onComplete}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: { clients } } = useSuspenseQuery(GET_CLIENTS_QUERY)
  const [landingPage, setLandingPage] = useState(initialLandingPage)
  const title = initialLandingPage.id ? "Edit Portal Landing Page" : "Add New Portal Landing Pages"
  const lastStep = 3
  const selectCampProgStep = 2
  const [stepNumber, setStepNumber] = useState(0)
  const [selectedClientIds, setSelectedClientIds] = useState(() => uniq(landingPage.leadTargets.map(leadTarget => leadTarget.clientCampaign.programGroup.client.id)))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [warningText, _setWarningText] = useState()
  const [errorClientIds, setErrorClientIds] = useState([])
  const { addNotification } = useNotifications()

  const handleClickNext = useCallback(() => {
    if(stepNumber === selectCampProgStep) {
      const clientIdsWithoutSelectedPrograms = checkForMissingSelections(landingPage.leadTargets, selectedClientIds)
      setErrorClientIds(clientIdsWithoutSelectedPrograms)

      if(clientIdsWithoutSelectedPrograms.length === 0) {
        setStepNumber(stepNumber + 1)
      } else {
        addNotification({variant: 'alert', message: 'Select at least one program/campaign for all included Clients.'})
      }
      // TODO: set warningText if necessary
      // setWarningText("There is only one shared campaign selected for this landing page. Are you sure you want to proceed?")
    } else {
      if(stepNumber === lastStep) {
        setStepNumber(stepNumber + 1)
        setIsSubmitting(true)
        onClose()
        setStepNumber(0)
        onComplete(recomputeDependencies({ landingPage, allClients: clients }))
      }

      setStepNumber(stepNumber + 1)
    }
  }, [stepNumber, landingPage, selectedClientIds, addNotification, onClose, onComplete, clients])

  const handleClickBack = useCallback(() => {
    if(stepNumber > 0) {
      setStepNumber(stepNumber - 1)
    }
  }, [stepNumber])

  useEffect(() => {
    setLandingPage(currentLandingPage => {
      const updatedLeadTargets = currentLandingPage.leadTargets.filter(lead => selectedClientIds.includes(lead.clientCampaign.programGroup.client.id))
      return {...currentLandingPage, leadTargets: updatedLeadTargets}
    })
  }, [selectedClientIds])

  return (
    (stepNumber <= lastStep) && <MultiStepModalContainer
      onClose={onClose}
      scroll='paper'
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "840px",
          },
        },
      }}
      >
        {stepNumber === 0 && (
          <ClientsForm
            clients={clients}
            selectedClientIds={selectedClientIds}
            setSelectedClientIds={setSelectedClientIds}
            onClose={onClose}
            isSubmitting={isSubmitting}
            stepNumber={stepNumber}
            onClickBackButton={handleClickBack}
            onClickSelectButton={handleClickNext}
            modalTitle={title}
          />
        ) || stepNumber === 1 && (
          <ClientWeightForm
            clients={clients}
            clientIds={selectedClientIds}
            landingPage={landingPage}
            setLandingPage={setLandingPage}
            onClose={onClose}
            isSubmitting={isSubmitting}
            stepNumber={stepNumber}
            onClickBackButton={handleClickBack}
            onClickSelectButton={handleClickNext}
            modalTitle={title}
          />
        ) || stepNumber === 2 && (
          <LeadTargetForm
            clientIds={selectedClientIds}
            landingPage={landingPage}
            setLandingPage={setLandingPage}
            onClose={onClose}
            isSubmitting={isSubmitting}
            stepNumber={stepNumber}
            onClickBackButton={handleClickBack}
            onClickSelectButton={handleClickNext}
            modalTitle={title}
            warningText={warningText}
            errorClientIds={errorClientIds}
            setErrorClientIds={setErrorClientIds}
          />
        ) || stepNumber === 3 && (
          <LeadTargetsConfirmation
            leadTargets={landingPage.leadTargets}
            onClose={onClose}
            isSubmitting={isSubmitting}
            stepNumber={stepNumber}
            onClickBackButton={handleClickBack}
            onClickSelectButton={handleClickNext}
            modalTitle={title}
          />
        )}
    </MultiStepModalContainer> || null
  )
}
