import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import COLORS from 'lib/colors'
import RadioButton from '../RadioButton'

export function MultiGroupRadioButton({ value, checked, onChange, label }) {
  return (
    <RadioButton
      value={value}
      checked={checked}
      onChange={onChange}
      label={label}
      sx={{ marginLeft: '10px',
      '.MuiRadio-root': {
        marginRight: '7px',
      },
      '.MuiFormControlLabel-label': {
        marginLeft: '5px'
      }
    }}
    />
  )
}

export function InputGroupSection({ title, children, role='group' }) {
  return (
    <Box role={role} aria-label={title}>
      <Box
        sx={{ fontSize: '16px',
              fontWeight: '600',
              color: COLORS.charcoal,
              margin: '6px 2px 4px 16px'
           }}
      >{title}</Box>
      {children}
    </Box>
  )
}

export default function MultiGroupDropdown({ value, renderValue, inputLabel, sx = {}, children, error=false }) {
  return (
    <Box sx={{ ...sx }}>
        <TextField
          select
          value={value}
          label={inputLabel}
          aria-label={`Dropdown ${inputLabel}`}
          SelectProps={{
            multiple: true,
            renderValue: renderValue,
            MenuProps:{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
            }
          }}
          error={error}
          fullWidth
        >
          {children}
        </TextField>
    </Box>
  )
}
