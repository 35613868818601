import { gql } from "@apollo/client"
import { Radio } from "@mui/material"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { StatusIcon } from "components/UI/AlertBadge/AlertBadge"
import { StatusWithToolTip } from "components/UI/StatusPanel/StatusPanel"
import { useTimezone } from "lib/TimezoneProvider"
import moment from 'moment-timezone'
import COLORS from 'lib/colors'
import { NullableMomentRange, convertToAbsoluteTimeRange, MomentRange } from "components/DatePickerPopover"
import { formatDate, formatTime, getTimeAndDate } from "lib/time"

export const MARKETING_PLATFORM_FIELDS = gql`
  fragment MarketingPlatformFields on MarketingPlatform {
    id
    name
    type
    validFrom
    lastSyncedAt
  }
`

export const GET_MARKETING_PLATFORMS_QUERY = gql`
  ${MARKETING_PLATFORM_FIELDS}
  query GetMarketingPlatforms {
    marketingPlatforms {
      ...MarketingPlatformFields
    }
  }
`

function rangesOverlap(selectedRange : NullableMomentRange, comparisonRange: MomentRange): boolean {
  if(selectedRange) {
    const selectedStartBeforeComparisonEnd = selectedRange[0].isBefore(comparisonRange[1])
    const selectedEndAfterComparisonStart = selectedRange[1].isAfter(comparisonRange[0])

    return selectedStartBeforeComparisonEnd && selectedEndAfterComparisonStart
  } else {
    return true
  }
}

function getRadialColor(disabled, selected) {
  if(selected) {
    return COLORS.regalBlue
  } else if(disabled) {
    return COLORS.mediumGray
  } else {
    return COLORS.slateGray
  }
}

function SpendDataHoverContent({marketingPlatforms, absoluteTimeRange, setDateRange, selected, setSelected}) {
  const handleChange = (platformId, validRange) => {
    setSelected(platformId)
    const roundedValidRange = [validRange[0].startOf('hour'), validRange[1].subtract(1,'hour').endOf('hour')]
    if(absoluteTimeRange === null) {
      setDateRange(roundedValidRange)
    } else {
      const startRange = moment.max(roundedValidRange[0], absoluteTimeRange[0])
      const endRange = moment.min(roundedValidRange[1], absoluteTimeRange[1])
      setDateRange({timeRange: [startRange, endRange]})
    }
  }

  const isPlatformDisabled = platform => !rangesOverlap(absoluteTimeRange, [platform.validFrom, platform.lastSyncedAt])
  const isSomeDisabled = marketingPlatforms.some(platform => isPlatformDisabled(platform))

  return (
    <Box>
      <Typography variant="subtitle1" align="right" sx={{ px: 1.5 }}>Limit search range to within valid Spend Data:</Typography>
      {isSomeDisabled &&
        <Typography variant="subtitle1" align="right" sx={{ px: 1.5 }}> Option(s) completely outside of the selected range are disabled</Typography>
      }
      <Table sx={{ '.MuiTableCell-root': {padding: '3px', fontWeight: 600, fontSize: '14px', border: 0 } }} aria-label="spend data validity">
        <TableBody>
          {marketingPlatforms.map(platform => {
            const isDisabled = isPlatformDisabled(platform)
            const isSelected = selected === platform.id
            const startDate = formatDate(platform.validFrom)
            const startTime = formatTime(platform.validFrom, 'start')
            const endDate = formatDate(platform.lastSyncedAt)
            const endTime = formatTime(platform.lastSyncedAt, 'end')

            return (
              <TableRow key={platform.id} onClick={() => !isDisabled && handleChange(platform.id, [moment(platform.validFrom), moment(platform.lastSyncedAt)])} sx={{ color: isDisabled ? COLORS.slateGray : COLORS.charcoal }} >
                <Tooltip title={`Spend Data ${platform.status == 'success' ? 'complete' : 'incomplete'} for selected date range`}>
                  <TableCell sx={{ pt: '1px !important' }}><StatusIcon variant={platform.status} /></TableCell>
                </Tooltip>
                <TableCell scope="row" sx={{ color: 'inherit' }}>{platform.name}:</TableCell>
                <TableCell align="right" sx={{ color: 'inherit' }}>{startDate}{startTime && ','}</TableCell>
                <TableCell align="right" sx={{ color: 'inherit' }}>{startTime}</TableCell>
                <TableCell sx={{ color: 'inherit' }}>-</TableCell>
                <TableCell align="right" sx={{ color: 'inherit' }}>{endDate}{endTime && ','}</TableCell>
                <TableCell align="right" sx={{ color: 'inherit' }}>{endTime}</TableCell>
                <TableCell>
                  <Radio checked={selected === platform.id} value={platform.id} name={platform.name} disabled sx={{ p: 0, color: `${getRadialColor(isDisabled, isSelected)} !important` }} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

function getStatus(dateRange, validFrom, lastSyncedAt, now) {
  const BEGINNING_OF_TIME = moment.utc([1970, 0, 1, 0])
  const [startTime, endTime] = dateRange || [BEGINNING_OF_TIME, now]
  const isValid = startTime.isSameOrAfter(validFrom) && endTime.isSameOrBefore(lastSyncedAt)

  return isValid ? "success" : "warning"
}

export function SpendValidityStatus({dateRange, setDateRange, marketingPlatforms, selectedPlatformValidity, setSelectedPlatformValidity}) {
  const { timezone } = useTimezone()
  const now = moment.tz(moment(), timezone)
  const absoluteTimeRange = convertToAbsoluteTimeRange(dateRange, timezone)

  const marketingPlatformsWithStatus = marketingPlatforms.map(marketingPlatform => {
    const validFrom = moment.utc(marketingPlatform.validFrom, 'YYYY-MM-DD hh:mm:ss UTC').tz(timezone)
    const lastSyncedAt = moment.utc(marketingPlatform.lastSyncedAt, 'YYYY-MM-DD hh:mm:ss UTC').tz(timezone)
    return (
      {
        ...marketingPlatform,
        validFrom: validFrom,
        lastSyncedAt: lastSyncedAt,
        status: getStatus(absoluteTimeRange, validFrom, lastSyncedAt, now)
      }
    )
  })

  const allValidFrom = moment.max(marketingPlatformsWithStatus.map(mp => mp.validFrom))
  const allLastSyncedAt = moment.min(marketingPlatformsWithStatus.map(mp => mp.lastSyncedAt))
  const allDataValidRow = {
    "id": "0",
    "name": "All",
    "validFrom": allValidFrom,
    "lastSyncedAt": allLastSyncedAt,
    "status": getStatus(absoluteTimeRange, allValidFrom, allLastSyncedAt, now)
  }

  const sortedMarketingPlatforms = marketingPlatformsWithStatus.sort((a, b) => a.lastSyncedAt - b.lastSyncedAt);
  const marketingPlatformsWithAll = [allDataValidRow, ...sortedMarketingPlatforms]

  const status = {
    variant: getStatus(absoluteTimeRange, allValidFrom, allLastSyncedAt, now),
    tipTitle: <SpendDataHoverContent marketingPlatforms={marketingPlatformsWithAll} absoluteTimeRange={absoluteTimeRange} setDateRange={setDateRange} selected={selectedPlatformValidity} setSelected={setSelectedPlatformValidity} />,
    statusText: `Spend Data valid from ${getTimeAndDate(allValidFrom, 'start', {useReferentialNames: true})} to ${getTimeAndDate(allLastSyncedAt, 'end', {useReferentialNames: true})}`
  }

  return (
    <StatusWithToolTip status={status} />
  )
}
