import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import COLORS from 'lib/colors';

function DialogTitle({ children, onClose, sx={}, ...props }) {
  return (
    <MuiDialogTitle sx={{ pb: 0.75, display: 'flex', alignItems: 'center', ...sx }} {...props}>
      <>
        {children}
        {onClose ? (
          <IconButton aria-label="close" sx={{ color: COLORS.charcoal, mr: '-9px' }} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </>
    </MuiDialogTitle>
  );
}

const DialogContent = withStyles(_theme => ({
  root: {
    minWidth: '300px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export { DialogTitle, DialogContent, DialogActions };
