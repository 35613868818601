import moment from 'moment-timezone'

// A really dumbed-down version of Temporal.PlainDate
export default class PlainDate {
  constructor(iso8601String) {
    this.iso8601String = iso8601String
  }

  toString() {
    return this.iso8601String
  }

  static from(iso8601String) {
    return new PlainDate(iso8601String)
  }
}
