import { Chip } from "@mui/material"
import { getIn } from "formik"
import { useOrbit } from "providers/OrbitProvider"
import { InitializedRecord } from '@orbit/records'
import { getRecordIdentity } from "lib/DataModel"

function RoleChip({ clientRole }) {
  const { store } = useOrbit()
  const client = store.cache.query(q => q.findRelatedRecord(clientRole, 'client'))

  return (
    <Chip label={`${getIn(client, 'attributes.name') || 'Unknown client'}: ${getIn(clientRole, 'attributes.name') || 'Unknown role'}`}/>
  )
}

export function Roles({ user }) {
  const { store } = useOrbit()

  const clientRoles = store.cache.query(q => q.findRelatedRecords(getRecordIdentity('user', user.id), 'clientRoles')) as InitializedRecord[] || []

  return clientRoles.map(clientRole => (
    <RoleChip key={clientRole.id} clientRole={clientRole}/>
  ))
}
