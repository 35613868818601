import { useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import SyncIcon from '@mui/icons-material/Sync'
import Tooltip from '@mui/material/Tooltip'
import WarningIcon from '@mui/icons-material/Warning'

import SimpleTable from '../SimpleTable'
import { groupBy, sum, toPercent } from '../../lib/utils'
import { getIn } from '../Formik/forms'

const useStyles = makeStyles({
  summaryTable: {
    '& td': {
      border: '1px solid #e0e0e0',
    },
  },
})

export default function MTDPacingTable({groupingColumns, currentMonthPacingResource, refreshCurrentMonthPacingResource}) {
  const classes = useStyles()
  const idColumns = useMemo(() => groupingColumns.map(({field}) => field), [groupingColumns])

  const tableRows = useMemo(() => (
    currentMonthPacingResource.promise.then(pacing => (
      groupBy(pacing, value => idColumns.map(idColumn => getIn(value, idColumn)).join('/'), { asEntries: true }).map(([ids, rows]) => (
        {
          date: rows[0].date,
          ...Object.fromEntries(ids.split('/').map((id, index) => [idColumns[index], id])),
          monthlyCap: sum(rows, 'monthlyCap'),
          goodLeadCount: sum(rows, 'goodLeadCount'),
          mtdProjectedCount: sum(rows, 'mtdProjectedCount') || '',
        }
      ))
    ))
  ), [currentMonthPacingResource, idColumns])

  const renderWarning = (_, row) => {
    if(!row.monthlyCap || !row.mtdProjectedCount) {
      return null
    }
    if(row.mtdProjectedCount > row.monthlyCap * 1.1) {
      return (
        <Tooltip title={`${row.mtdProjectedCount} projected monthly leads exceeds monthly cap by over 10%`}>
          <WarningIcon color="error"/>
        </Tooltip>
      )
    }
    if(row.mtdProjectedCount < row.monthlyCap * 0.9) {
      return (
        <Tooltip title={`${row.mtdProjectedCount} projected monthly leads falls short of monthly cap by over 10%`}>
          <WarningIcon color="error"/>
        </Tooltip>
      )
    }
    return null
  }

  return <SimpleTable
    className={classes.summaryTable}
    data={tableRows}
    title="MTD Pacing"
    columns={[
      ...groupingColumns,
      { title: 'Monthly Cap', align: 'right', field: 'monthlyCap', summary: 'sum', visible: true },
      { title: 'Good Leads', align: 'right', field: 'goodLeadCount', summary: 'sum', visible: true },
      { title: 'Projected Leads', align: 'right', field: 'mtdProjectedCount', summary: data => sum(data, 'mtdProjectedCount') || '', visible: true },
      { title: 'Projected Cap Met', align: 'right', value: row => row.mtdProjectedCount/row.monthlyCap, render: value => toPercent(value), summary: data => sum(data, 'mtdProjectedCount')/sum(data, 'monthlyCap'), visible: true },
      { title: '', value: '', render: renderWarning, headerStyle: { borderLeft: '2px solid transparent' }, visibilityLocked: true, visible: true },
    ]}
    options={{
      summaryRow: true,
      hover: true,
      columnsButton: true,
      sortColumn: idColumns[0],
    }}
    actions={[
      {
        icon: () => <SyncIcon style={{ transform: 'rotate(45deg)' }} />,
        tooltip: 'Refresh',
        onClick: refreshCurrentMonthPacingResource,
      }
    ]}
  />
}
