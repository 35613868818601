import React, { useMemo } from "react";
import SimpleTable from ".";

export default {
  title: "Components/SimpleTable",
  component: SimpleTable,
  argTypes: {
    numberOfGroupingColumns: {
      control: "number",
    },
    numberOfMetricColumns: {
      control: "number",
    },
    branchFactor: {
      control: "number",
    },
    hover: {
      control: "boolean",
    },
    summaryRow: {
      control: "boolean",
    },
    summaryCollapse: {
      control: "boolean",
    },
  },
};

function getColumnValue(currentColumn, index) {
  if (currentColumn.groupable) {
    return `${currentColumn.field}${index + 1}`;
  }

  return Math.floor(Math.random() * 100);
}

function generateData(groupingColumns, branchFactor = 2) {
  if (groupingColumns.length === 0) {
    return [];
  }

  const [current, ...rest] = groupingColumns;

  const adjustedBranchFactor = current.groupable ? branchFactor : 1;
  const currentValues = [...Array(adjustedBranchFactor)].map((_, index) => ({
    [current.field]: getColumnValue(current, index),
  }));

  if (rest.length === 0) {
    return currentValues;
  }

  return currentValues.flatMap((value) =>
    generateData(rest, branchFactor).map((subvalue) => ({
      ...value,
      ...subvalue,
    })),
  );
}

function buildDataFromArgs({
  numberOfGroupingColumns,
  numberOfMetricColumns,
  branchFactor,
}) {
  const columns = [];

  for (let n = 0; n < numberOfGroupingColumns; n++) {
    columns.push({
      title: `Group ${String.fromCharCode(n + 65)}`,
      field: `group${String.fromCharCode(n + 65)}`,
      groupable: true,
      summary: { text: "Total" },
      visible: true,
    });
  }

  for (let j = 0; j < numberOfMetricColumns; j++) {
    columns.push({
      title: `Metric ${String.fromCharCode(j + 65)}`,
      field: `metric${String.fromCharCode(j + 65)}`,
      category: "metrics",
      align: "right",
      summary: "sum",
      visible: true,
    });
  }

  const data = generateData(columns, branchFactor);
  return [data, columns];
}

const Template = (args) => {
  const { numberOfGroupingColumns, numberOfMetricColumns, branchFactor } = args;
  const [dummyData, dummyColumns] = useMemo(
    () =>
      buildDataFromArgs({
        numberOfGroupingColumns,
        numberOfMetricColumns,
        branchFactor,
      }),
    [numberOfGroupingColumns, numberOfMetricColumns, branchFactor],
  );
  return (
    <SimpleTable
      title={`${args.title} with ${dummyData.length} rows`}
      data={dummyData}
      columns={dummyColumns}
      options={{
        hover: args.hover,
        summaryRow: args.summaryRow,
        summaryCollapse: args.summaryCollapse,
        sortColumn: "group0",
        sortOrder: -1,
        columnsButton: false,
      }}
    />
  );
};

export const Dummy = Template.bind({});
(Dummy.parameters = {
  // Disables Chromatic's snapshotting on a story level
  chromatic: { disableSnapshot: true },
}),
  (Dummy.args = {
    title: "Sample Table",
  });
