import SvgIcon from '@mui/material/SvgIcon'

export default function BuildPageIcon({sx, ...props}) {
  return (
    <SvgIcon
      viewBox="0 0 30 30"
      sx={{
        width: 30,
        height: 30,
        ...sx
      }}
      {...props}
    >
      <path d="m17 5 6 6v12c0 1.1-.9 2-2 2H8.99C7.89 25 7 24.1 7 23l.01-16c0-1.1.89-2 1.99-2h8zm.523 13.154-1.372 1.372 3.878 3.878 1.372-1.372-3.878-3.878zm-5.266-6.183-2.29 2.29.912.912H9.055l-.459.459 2.29 2.29.459-.46v-1.83l.912.912.459-.46 1.15 1.152-4.791 4.792 1.37 1.37 7.36-7.352c.233.084.485.135.75.135a2.266 2.266 0 0 0 2.264-2.263c0-.375-.104-.724-.265-1.035l-1.746 1.746-.964-.963 1.746-1.746a2.234 2.234 0 0 0-1.035-.266 2.266 2.266 0 0 0-2.263 2.264c0 .265.052.517.136.75l-1.197 1.197-1.15-1.152.458-.459-.911-.912 1.37-1.37a1.94 1.94 0 0 0-2.741 0z" />
    </SvgIcon>
  )
}
