import useSize from '@react-hook/size';
import { useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref) => {
  const [isOverflow, setIsOverflow] = useState(undefined);
  const [width, height] = useSize(ref)

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const hasOverflow = current.scrollHeight > current.clientHeight;
      setIsOverflow(hasOverflow);
    }
  }, [ref, width, height]);

  return isOverflow;
};
