import { useCallback, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ColumnsIcon from '@mui/icons-material/Tune'

import { useDataManager } from './DataManager'
import CheckboxMenu from '../controls/CheckboxMenu'
import { sortBy } from '../../lib/utils'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import COLORS from 'lib/colors'

export default function ColumnSelector() {
  const popupState = usePopupState({ variant: 'popover', popupId: 'columnSelector' })
  const dataManager = useDataManager()

  // Using JSON.stringify(dataManager.columns) here is a kludge. The issue is
  // that (I think) LeadTable depends on the `columns` passed in to the
  // DataManager being constant, so we need to modify it in-place (setting
  // columns to hidden and reordering them) rather than replacing the entire
  // array. We really should change this so that the `columns` array is
  // immutable and gets replaced whenever it changes.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const unlockedColumns = useMemo(() => dataManager.columns.filter(column => !column.visibilityLocked), [JSON.stringify(dataManager.columns)])
  const columns = useMemo(() => [...unlockedColumns.filter(column => column.visible), ...sortBy(unlockedColumns.filter(column => !column.visible), 'title')], [unlockedColumns])

  const moveColumn = useCallback(({object: dragColumn}, dropColumn) => {
    const indexAfterLastVisibleColumn = dataManager.columns.map(column => column.visible).lastIndexOf(true) + 1

    const dragIndex = dataManager.columns.indexOf(dragColumn)
    const dropIndex = dropColumn.visible ? dataManager.columns.indexOf(dropColumn) : indexAfterLastVisibleColumn

    if(dragIndex === -1 || dropIndex === -1) {
      return
    }

    if(dragIndex === dropIndex) {
      return
    }

    const columns = dataManager.columns
    columns.splice(dragIndex, 1)
    columns.splice(dropIndex + (dragIndex < dropIndex ? -1 : 0), 0, dragColumn)
    dataManager.onChangeColumns()
  }, [dataManager])

  const handleToggleVisible = useCallback(column => event => {
    column.visible = event.target.checked
    dataManager.onChangeColumns()
  }, [dataManager])

  const isColumnVisible = useCallback(column => column.visible, [])
  const getColumnTitle = useCallback(column => column.title, [])

  return (
    <>
      <Tooltip title="Change columns">
        <IconButton aria-label="Change columns" {...bindTrigger(popupState)} size="large" sx={{color: COLORS.frenchBlue }}>
          <ColumnsIcon/>
        </IconButton>
      </Tooltip>
      <CheckboxMenu
        {...bindPopover(popupState)}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        menuOptions={columns}
        reorderable={true}
        updatePosition={moveColumn}
        isChecked={isColumnVisible}
        isReorderable={isColumnVisible}
        render={getColumnTitle}
        onChange={handleToggleVisible}
      />
    </>
  )
}
