import SvgIcon from '@mui/material/SvgIcon'

export default function SourceNotesIcon(props) {
  return (
    <SvgIcon viewBox="0 0 30 30" sx={{ width: 30, height: 30 }} {...props}>
      <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
          <circle cx="15" cy="15" r="15"/>
          <path d="M7.037 21V7v14zM9 11h10V9H9v2zm0 4h4.75c.333-.4.696-.77 1.088-1.113.391-.341.82-.637 1.287-.887H9v2zm0 4h3.05c.033-.35.096-.692.188-1.025.091-.333.204-.658.337-.975H9v2zm-2 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 21V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 7 5h14c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v5.575a7.924 7.924 0 0 0-.975-.337A6.005 6.005 0 0 0 21 12.05V7H7v14h5.05c.033.35.096.692.188 1.025.091.333.204.658.337.975H7zm13 2c-1.217 0-2.28-.38-3.188-1.137A4.9 4.9 0 0 1 15.1 21h1.55c.217.733.63 1.333 1.238 1.8.608.467 1.312.7 2.112.7.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 23.5 20c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 20 16.5a3.405 3.405 0 0 0-2.45 1H19V19h-4v-4h1.5v1.425c.45-.433.975-.78 1.575-1.038A4.815 4.815 0 0 1 20 15c1.383 0 2.563.488 3.538 1.462C24.512 17.438 25 18.617 25 20s-.488 2.563-1.462 3.538C22.562 24.512 21.383 25 20 25z" fill="#006EB3" fillRule="nonzero"/>
      </g>
    </SvgIcon>
  )
}
