import { getIn } from "components/Formik/forms";
import withFormik from "@gentslava/storybook-formik";
import FormCollectionSelector from "./";

export default {
  title: "UI/FormCollectionSelector",
  component: FormCollectionSelector,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4090a5a278f108057aec8",
  },
};

const Template = (args) => {
  return (
    <FormCollectionSelector
      name="clientCampaignIds"
      render={(clientCampaign) => getIn(clientCampaign, "attributes.name")}
      {...args}
    />
  );
};

export const Default = Template.bind({});

Default.decorators = [withFormik];
Default.parameters = {
  formik: {
    initialValues: {
      clientCampaignIds: [],
    },
  },
};
Default.args = {
  minItemsPerColumn: 4,
  numColumns: 2,
  disabled: false,
  collection: [
    {
      attributes: {
        publicId: "b632e191",
        name: "Gold",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "af65f8af-145d-40ec-80c1-e0a3941ff485",
      keys: { remoteId: "218" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "171" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "1c16e28d",
        name: "Silver",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "772dfd9d-f76f-43e4-ba36-de04452b2942",
      keys: { remoteId: "219" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "171" },
      },
      type: "clientCampaign",
    },

    {
      attributes: {
        publicId: "0ea94ee",
        name: "Health - Exclusive",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "0864a242-78d4-4580-911c-498d900584c5",
      keys: { remoteId: "25" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "58" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "b6eb211",
        name: "Education - Exclusive",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "44bd76ea-9bb6-4f62-9e18-1487005d5165",
      keys: { remoteId: "26" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "59" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "f79d143",
        name: "Business - Exclusive",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "12dd3811-6abb-4a77-8677-81e5ab5caec7",
      keys: { remoteId: "28" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "61" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "04db385",
        name: "Master's - Exclusive",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "09a8d726-9dbf-492f-b049-e8f11ae1b953",
      keys: { remoteId: "29" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "62" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "ea208e7",
        name: "Other - Exclusive",
        campaignType: "exclusive",
        status: "active",
        billableLeadCount: 0,
      },
      id: "fb1219dc-1185-4107-a28a-87aa28ed0739",
      keys: { remoteId: "64" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "128" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "d4e796c",
        name: "Health - Shared",
        campaignType: "shared",
        status: "active",
        billableLeadCount: 0,
      },
      id: "49beda0e-0a53-4242-bf33-e98c1632bcc5",
      keys: { remoteId: "126" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "58" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "53197ca",
        name: "Education - Shared",
        campaignType: "shared",
        status: "active",
        billableLeadCount: 0,
      },
      id: "09d68a96-3c8a-42b1-b1b3-c1fedb223d22",
      keys: { remoteId: "127" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "59" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "9ea5157",
        name: "Liberal Arts - Shared",
        campaignType: "shared",
        status: "active",
        billableLeadCount: 0,
      },
      id: "439c9cfe-b460-4086-8bbc-b17e302052e0",
      keys: { remoteId: "128" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "60" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "c1ca710",
        name: "Business - Shared",
        campaignType: "shared",
        status: "active",
        billableLeadCount: 0,
      },
      id: "37763b85-3a4b-45f8-af5d-c1e55560ea37",
      keys: { remoteId: "129" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "61" },
      },
      type: "clientCampaign",
    },
    {
      attributes: {
        publicId: "755e4b0",
        name: "Master's - Shared",
        campaignType: "shared",
        status: "active",
        billableLeadCount: 0,
      },
      id: "1221b25b-da61-4181-98d0-d9f6f2117d8e",
      keys: { remoteId: "130" },
      relationships: {
        contract: {
          type: "contract",
          id: "1bd4d885-0a34-4f8a-8b5e-0649a4c1a711",
        },
        programGroup: { type: "programGroup", id: "62" },
      },
      type: "clientCampaign",
    },
  ],
};
