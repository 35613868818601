import { CollapseWrapper, ContentWrapper } from "components/UI/StyledCard"
import { useFormikContext } from "formik"
import { replaceElement, sortBy } from "lib/utils"
import { useCallback, useMemo, useState } from "react"
import CampaignSelector from './CampaignSelector';
import clsx from 'clsx'
import EditIcon from '@mui/icons-material/Edit'
import COLORS from 'lib/colors'
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { List, ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material"
import { getClientCampaignNamesFromLeadTargets, getClientsAndCampaigns, buildDefaultClientCampaignRule, getProgramGroupDescriptionFromLeadTargets } from "components/pages/LandingPages/data/helpers"
import { MISSING_DEFAULT_CAMPAIGNS_ERROR } from "components/pages/LandingPages/data/yupSchemas"
import StandardDialog from "components/UI/Dialog/StandardDialog"
import { uniq } from "lodash-es"
import StopIcon from '@mui/icons-material/Stop'
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"


export function getUnsetClientMappingCount(clientMapping, clientCampaigns) {
  const programGroupIds = uniq(clientCampaigns.map(cc => cc.programGroup.id))
  const missingMappings = programGroupIds.filter(id => !clientMapping.some(entry => entry.programGroupId === id))

  return missingMappings.length
}

function ClientDefaultCampaignList({ sortedClientMapping, client, clientWeight, groupedClientCampaigns }) {
  const { values } = useFormikContext()
  const landingPage = values
  const clientCampaignNames = getClientCampaignNamesFromLeadTargets(landingPage.leadTargets)
  const programGroupNames = getProgramGroupDescriptionFromLeadTargets(landingPage.leadTargets)
  const filteredClientMapping = sortedClientMapping.filter(entry => entry.clientId === client.id)
  const unsetCount = useMemo(() => getUnsetClientMappingCount(filteredClientMapping, groupedClientCampaigns), [filteredClientMapping, groupedClientCampaigns])

  return (
    <Box
      sx={{minWidth: '276px', lineHeight: '18px', mt: '2px', flexBasis: `calc((100% - 36px) / 3)`}}
      role="listitem"
      aria-roledescription='Client Default Campaign Selection'
    >
      <Stack direction="row" spacing={2} role="heading">
        <Box sx={{fontWeight: 'bold', lineHeight: '18px', mt: '2px'}}>
          {client.internalName || client.name}
        </Box>
        { clientWeight && (
          <Chip label={`${clientWeight}%`}/>
        )}
      </Stack>
      <List disablePadding>
        {unsetCount > 0 &&
          <ListItem disablePadding sx={{ mt: '6px', lineHeight:'18px', alignItems:'baseline'  }}>
            <ListItemIcon sx={{ml: '-1px', minWidth: '19px'}}><StopIcon sx={{fontSize:'8px'}} /></ListItemIcon>
            <ListItemText sx={{my: 0, lineHeight:'18px'}}>
              <Typography sx={{color: COLORS.copperRed, fontSize: '16px', fontWeight: 600, margin: '0 auto', lineHeight: '18px' }}>
                Missing default campaign{unsetCount > 1 && `(s)`}
              </Typography>
            </ListItemText>
          </ListItem>
        }
        {filteredClientMapping.map(entry =>
          <Box key={entry.programGroupId}>
            <ListItem disablePadding sx={{ mt: '6px', mb: '2px', lineHeight:'18px', alignItems:'baseline'  }}>
              <ListItemIcon sx={{ml: '-1px', minWidth: '19px'}}><StopIcon sx={{fontSize:'8px'}} /></ListItemIcon>
              <ListItemText sx={{my:'0px', lineHeight:'18px'}}>
                <Typography lineHeight='18px'>
                  {programGroupNames[entry.programGroupId]}
                </Typography>
              </ListItemText>
            </ListItem>
            {entry.clientCampaignIds.map(id =>
              <ListItem disablePadding key={id} sx={{ mt: '6px', mb: '2px', lineHeight:'18px', alignItems:'baseline', color: COLORS.slateGray  }}>
                <ListItemIcon sx={{ml: '20px', minWidth: '19px'}}><StopIcon sx={{fontSize:'8px'}} /></ListItemIcon>
                <ListItemText sx={{my:'0px', lineHeight:'18px'}}>
                  <Typography lineHeight='18px'>
                    {clientCampaignNames[id]}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </Box>
        )}
      </List>
    </Box>
  )
}

export function DefaultCampaignSelectionMulti() {
  const { setFieldValue, values, initialValues, initialErrors } = useFormikContext()
  const landingPage = initialValues
  const [modalClientMapping, setModalClientMapping] = useState(null)

  const defaultClientCampaignRule = useMemo(() => values.clientCampaignRules.find(ltc => ltc.default) || buildDefaultClientCampaignRule(), [values.clientCampaignRules])

  const getClientWeight = client => landingPage.clientWeights?.find(cw => cw.clientId === client.id)?.weight
  const clientsAndCampaigns = sortBy(
    getClientsAndCampaigns(landingPage.leadTargets),
    { key: ([client, _campaigns]) => getClientWeight(client), desc: true },
    ([client, _campaigns]) => client.internalName || client.name,
  )

  const clientMapping = defaultClientCampaignRule.clientMapping
  const clients = clientsAndCampaigns.map(([clients, _]) => clients)
  const sortedClientMapping = sortBy(clientMapping, entry => clients[entry.clientId])

  const missingDefaultCampaign = initialErrors.clientCampaignRules === MISSING_DEFAULT_CAMPAIGNS_ERROR

  const showEditModal = useCallback(() => (
    setModalClientMapping(clientMapping)
  ), [clientMapping])

  const closeEditModal = useCallback(() => {
    setModalClientMapping(null)
  }, [])

  const handleSave = useCallback(() => {
    setFieldValue(
      'clientCampaignRules',
      replaceElement(
        values.clientCampaignRules,
        defaultClientCampaignRule,
        { ...defaultClientCampaignRule, clientMapping: modalClientMapping, leadTarget: null }
      )
    )
    closeEditModal()
  }, [closeEditModal, defaultClientCampaignRule, modalClientMapping, setFieldValue, values.clientCampaignRules])

  return (
    <>
      <CollapseWrapper maxHeight={120} >
        <Stack direction='column' spacing={2} marginTop={2}>
          <SubsectionTitle
            title='Default Campaign Selection'
            controls={[{icon: <EditIcon/>, onClick: showEditModal, label: "Edit Default Campaigns"}]}
          />
          {sortedClientMapping.length !== 0 &&
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                columnGap:'18px',
                rowGap:'12px',
              }}
              className={clsx({ persistedError: missingDefaultCampaign })}
              role="list"
              aria-roledescription="Default Campaign Selection"
            >
              {sortedClientMapping.length !== 0 && clientsAndCampaigns.map(([client, groupedClientCampaigns]) =>
                <ClientDefaultCampaignList
                  sortedClientMapping={sortedClientMapping}
                  client={client}
                  clientWeight={getClientWeight(client)}
                  groupedClientCampaigns={groupedClientCampaigns}
                  key={"clientDefaultCampaignList_" + client.id}
                />
              )}
            </Box>
          }
        </Stack>
      </CollapseWrapper>
      {sortedClientMapping.length === 0 &&
        <ContentWrapper>
          <Typography
            color="error"
            // @ts-ignore
            variant="status"
            align="center"
            className={clsx({ persistedError: missingDefaultCampaign })}
          >
            No default campaigns selected.
          </Typography>
        </ContentWrapper>
      }

      {modalClientMapping &&
        <StandardDialog
         title="Edit Default Campaign Selection"
         description={<>Select the <b>Default Campaigns</b> for each client included in this landing page.</>}
         onCancel={closeEditModal}
         onConfirm={handleSave}
         maxWidth='716px'
        >
          <Grid container spacing={2}>
            {clientsAndCampaigns.map(([client, clientCampaigns]) =>
              <Grid item xs={12} sm={6} key={"leadTargetDropdown_" + client.id} >
                <CampaignSelector
                  inputLabel={client.name}
                  clientCampaigns={clientCampaigns}
                  clientMapping={modalClientMapping}
                  setClientMapping={setModalClientMapping}
                  sx={{ flexGrow: 1 }}
                />
              </Grid>
            )}
          </Grid>
        </StandardDialog>
      }
    </>
  )
}
