import { Formik, Form as FormikForm } from "formik";
import { useState } from "react";
import { FormTextField } from "../../Formik/forms";

export default {
  title: "UI/TextInputField",
  component: FormTextField,
  parameters: {
    zeplinLink: "https://zpl.io/bJB55J3",
  },
};

function SampleForm({ args }) {
  const [passedArgs, setPassedArgs] = useState(args);

  return (
    <FormikForm
      autoComplete="off"
      onChange={() => {
        setPassedArgs({ ...args, error: false });
      }}
    >
      <FormTextField {...passedArgs} />
    </FormikForm>
  );
}

const Template = (args) => (
  <Formik
    initialValues={[]}
    onSubmit={() => {
      /*no op*/
    }}
  >
    <SampleForm args={args} />
  </Formik>
);

export const Default = Template.bind({});
Default.args = {
  sx: { width: "200px" },
  label: "Name",
  name: "name",
  required: false,
  error: false,
  size: "medium",
};

export const Required = Template.bind({});
Required.args = {
  ...Default.args,
  required: true,
};

export const Error = Template.bind({});
Error.args = {
  ...Default.args,
  error: true,
};
