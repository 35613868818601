import { useLandingPageStateContext } from "../ui/LandingPageProvider"
import StandardDialog from "components/UI/Dialog/StandardDialog"
import { unstable_Blocker } from "react-router-dom"


export function UnsavedChangesDialog({onCancel, onConfirm}) {
  return (
    <StandardDialog
      title='Unsaved Changes'
      description="You have unsaved changes; continuing will discard them. Are you sure you want to continue?"
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText='Cancel'
      confirmText='Continue'
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

export function CancelPortalCreationDialog({onCancel, onConfirm}) {
  return (
    <StandardDialog
      title='Cancel Portal Creation'
      description={<>Do you want to <b>cancel</b> creating a new portal? All entered information will be lost.</>}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onClose={onConfirm}
      cancelText='Yes, Cancel'
      confirmText='Stay'
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

function ExistingLandingPageDialogs({confirmableCallback, setConfirmableCallback, blocker}) {
  const handleConfirm = () => {
    confirmableCallback?.()
    setConfirmableCallback(null)
  }

  return (
    <>
      {blocker?.state === "blocked" && (
        <UnsavedChangesDialog
          onCancel={blocker.reset}
          onConfirm={blocker.proceed}
        />
      ) || Boolean(confirmableCallback) && (
        <UnsavedChangesDialog
          onCancel={() => setConfirmableCallback(null)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}

function NewLandingPageDialogs({confirmableCallback, setConfirmableCallback, blocker}) {
  const handleConfirmCancel = () => {
    confirmableCallback?.()
    setConfirmableCallback(null)
  }

  return (
    <>
       {blocker?.state === "blocked" && (
        <CancelPortalCreationDialog
          onCancel={blocker.proceed}
          onConfirm={blocker.reset}
        />
      ) || Boolean(confirmableCallback) && (
        <CancelPortalCreationDialog
          onCancel={handleConfirmCancel}
          onConfirm={() => setConfirmableCallback(null)}
        />
      )}
    </>
  )
}

export function CancelOrConfirmDialog(
  {
    confirmableCallback,
    setConfirmableCallback,
    blocker = null, 
  } : {
    confirmableCallback: (() => void) | null,
    setConfirmableCallback: (c: (() => void) | null) => void,
    blocker?: unstable_Blocker | null,
  })
{
  const { landingPage } = useLandingPageStateContext()

  return (
    <>
      {landingPage.id && (
        <ExistingLandingPageDialogs
          confirmableCallback={confirmableCallback}
          setConfirmableCallback={setConfirmableCallback}
          blocker={blocker}
        />
      ) || (
        <NewLandingPageDialogs
          confirmableCallback={confirmableCallback}
          setConfirmableCallback={setConfirmableCallback}
          blocker={blocker}
        />
      )}
    </>
  )
}
