import { PropsWithChildren, useMemo, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import COLORS from '../lib/colors'

import ViewportProvider from '../lib/ViewportProvider'
import TimezoneProvider from '../lib/TimezoneProvider'
import NotificationsProvider from '../lib/NotificationsProvider'
import HostnameProvider from '../lib/HostnameProvider'

import Allocations from './pages/Allocations'
import ClientEdit from './pages/ClientEdit'
import ClientTable from './pages/ClientTable'
import ClientManagePage from './pages/client/Manage/Manage'
import VendorEdit from './pages/VendorEdit'
import VendorTable from './pages/VendorTable'
import LeadTable from './pages/LeadTable'
import PacingView from './pages/PacingView'
import Performance from './pages/Performance'
import UserTable from './pages/UserTable'
import OrganizationTable from './pages/OrganizationTable'
import LandingPageEdit from './pages/LandingPages/ui/LandingPageEditor/LandingPageEdit'
import LandingPages from './pages/LandingPages/ui/LandingPageIndex'
import GlobalDashboard from './pages/GlobalDashboard'
import ClientDashboard from './pages/ClientDashboard'
import VendorDashboard from './pages/VendorDashboard'
import Integrations from './pages/Integrations'
import Sidebar from './layout/Sidebar/Sidebar'
import OutletContainer from './layout/OutletContainer/OutletContainer'
import Notifications from './UI/Notifications'

import { getDefaultScope, organizationIsClient, organizationIsVendor, someVendorPermitLandingPages, userIsAdmin, userIsGlobalAdmin } from '../lib/auth-helpers'

import ApolloProvider from '../providers/ApolloProvider'

import {
  RouterProvider,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
} from 'react-router-dom'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryParamProvider } from 'use-query-params'
import ModalProvider from 'mui-modal-provider'
import CurrentUserProvider, { useCurrentUser } from 'lib/CurrentUserProvider'
import FeaturesProvider, { Features } from 'providers/FeaturesProvider'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Compose from 'lib/Compose'
import moment from 'moment-timezone'
import AdminClientsPage from './pages/admin/Clients/ClientsPage'
import MainContainer from './layout/MainContainer/MainContainer'
import { LicenseInfo } from '@mui/x-license'
import Suspenseful from './Suspenseful'
import CurrentUserResource from 'resources/CurrentUserResource'
import ScopeProvider from 'lib/ScopeProvider'
import OrbitProvider, { useOrbit } from 'providers/OrbitProvider'


const isTest = window.navigator.userAgent === 'Capybara'

LicenseInfo.setLicenseKey('7fbcfd7e5d829b82edf90448be47b8d5Tz05NTQxMSxFPTE3NTQxNjQzNDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

// https://github.com/facebook/react/pull/22114
// Error removed in React 18
const IGNORED_VIOLATIONS = [
  "Warning: Can't perform a React state update on an unmounted component"
]

const origError = console.error
console.error = (...args) => {
  const firstArg = args[0]
  if (typeof firstArg === 'string' && IGNORED_VIOLATIONS.some((v) => firstArg.includes(v))) {
    return
  }
  origError(...args)
}

export const customTheme = createTheme({
  palette: {
    mode: 'light',
    type: 'light',
    primary: {
      light: COLORS.frenchBlue,
      main: COLORS.regalBlue,
      dark: COLORS.frenchBlue
    },
    secondary: {
      light: COLORS.charcoal,
      main: COLORS.charcoal,
      dark: COLORS.charcoal,
    },
    divider: COLORS.slateGray,
    lightBackground: COLORS.backgroundGray,
    text: {
      primary: COLORS.charcoal,
      secondary: COLORS.slateGray,
    },
    success: {
      main: COLORS.olivine, // Olivine
      light: '#CFE9D9',
    },
    error: {
      main: COLORS.copperRed, // Copper Red
      light: '#F39E8B', // 15% Copper Red
    },
    warning: {
      main: COLORS.goldenrod, // Goldenrod
    },
    info: {
      main: COLORS.slateGray,
    },
    action: {
      active: COLORS.charcoal
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 22,
      fontWeight: 600,
      color: COLORS.frenchBlue,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,
      color: COLORS.frenchBlue,
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    table: {
      fontSize: 14,
      fontWeight: 600,
      display: 'block',
    },
    modal: {
      fontSize: 18,
      display: 'block',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 12,
      color: COLORS.slateGray,
    },
    subtitle2: {
      fontSize: 11,
      color: COLORS.slateGray,
    },
    subtitle3: {
      fontSize: 10,
      color: COLORS.slateGray,
    },
    button: {
      fontSize: 18,
      textTransform: 'none',
    },
    status: {
      fontSize: 16,
      fontWeight: 600,
      display: 'block',
    },
  },
  spacing: 6,
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          lineHeight: '24px',
          fontSize: 18,
          padding: '12px 24px',
          "&.Mui-disabled": {
            color: COLORS.slateGray,
          },
          whiteSpace: 'nowrap',
        },
        sizeSmall: {
          padding: '8px 12px',
          fontSize: 13,
          lineHeight: '20px',
          '&.MuiButton-outlined': {
            padding: '7px 11px',
          },
        },
        contained: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
          backgroundImage: `linear-gradient(to bottom, ${COLORS.regalBlue}, ${COLORS.frenchBlue})`,
          "&.Mui-disabled": {
            backgroundColor: COLORS.veryLightGray,
            backgroundImage: 'unset',
            boxShadow: 'unset'
          }
        },
        outlined: {
          padding: '11px 23px',
        },
        text: {
          color: COLORS.charcoal,
        },
      }
    },
    MuiChip: {
      defaultProps: {
        variant: 'filled',
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '3px',
          '.MuiChip-label': {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        },
        sizeMedium: {
          fontSize: 16,
          height: '28px',
          padding: '3px 8px',
        },
        sizeSmall: {
          fontSize: 14,
          height: '22px',
          padding: '1.5px 6px'
        },
        colorDefault: {
          backgroundColor: COLORS.aliceBlue,
          color: COLORS.charcoal,
        },
        /* Prior to Material 5.9.3, we can't use `filledError`, `filledWarning`, etc. *SOME* of the following should be moved from `colorX` to `filledX`. */
        colorError: {
          border: `1px solid ${COLORS.copperRed}`,
          backgroundColor: COLORS.mistyRose,
          color: COLORS.copperRed,
        },
        colorWarning: {
          border: `1px solid ${COLORS.goldenrod}`,
          backgroundColor: COLORS.island,
          color: COLORS.goldenrod,
        },
        colorSuccess: {
          border: `1px solid ${COLORS.olivine}`,
          backgroundColor: COLORS.cabbageLeaf,
          color: COLORS.olivine,
        },
        /* We can remove this override for backgroundColor once we change colorX to filledX above */
        outlined: {
          backgroundColor: 'transparent',
        },
        deleteIcon: {
          margin: '0 -4px 0 2px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          fontWeight: 600,
          color: COLORS.frenchBlue,
          padding: '18px 24px',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: COLORS.regalBlue,
          padding: 6,
          '& + .MuiIconButton-root': {
            marginLeft: 6
          },
          '&.Mui-disabled': {
            color: COLORS.mediumGray
          }
        },
        colorSecondary: {
          color: COLORS.slateGray,
          padding: 0,
          '& + .MuiIconButton-root': {
            marginLeft: 6
          },
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.veryLightGray
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          MenuListProps: {
            sx: { paddingY: 1 }
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: COLORS.slateGray,
        },
        root: {
          ':hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.charcoal,
            },
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.frenchBlue,
            },
          },
          '&.Mui-error': {
            backgroundColor: COLORS.seashell,
            color: COLORS.copperRed,
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px'
            },
            '.MuiOutlinedInput-input': {
              ".MuiBox-root": {
                color: COLORS.copperRed,
                fontWeight: 600,
              },
            },
            '.MuiSvgIcon-root': {
              color: COLORS.copperRed,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: COLORS.veryLightGray,
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${COLORS.slateGray}`,
            },
            '.MuiOutlinedInput-input.MuiInputBase-input': {
              color: COLORS.slateGray,
              WebkitTextFillColor: COLORS.slateGray
            },
            '.MuiSvgIcon-root': {
              color: COLORS.slateGray,
            },
          },
        },
        input: {
          padding: '12.5px 12px'
        },
        inputSizeSmall: {
          padding: '8px 12px',
          fontSize: 13,
          height: '20px'
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: '-10px 0 -10px 0',
          color: `${COLORS.cadetGray} !important`,
          "&.Mui-checked": {
            color: `${COLORS.regalBlue} !important`,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: `${COLORS.cadetGray} !important`,
          "&.Mui-checked": {
            color: `${COLORS.regalBlue} !important`,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        margin: 'none',
      },
      styleOverrides: {
        root: {
          ':hover:not(:has(.Mui-disabled)):not(.Mui-focused):not(.Mui-error):not(:has(.Mui-error)) label': {
            color: COLORS.charcoal
          },
          '.MuiInputLabel-outlined.Mui-disabled': {
            color: COLORS.slateGray
          },
          '.MuiInputLabel-outlined.Mui-focused': {
            color: COLORS.frenchBlue
          },
          '.MuiInputLabel-outlined': {
            transform: 'translate(12px, 12.5px) scale(1)',
            "&.MuiInputLabel-shrink": {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
          },
          '.MuiInputLabel-outlined.MuiInputLabel-sizeSmall': {
            transform: 'translate(12px, 8px) scale(1)',
            "&.MuiInputLabel-shrink": {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
          },
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error, &.Mui-required' : {
            display: 'none'
          }
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: `${COLORS.mediumGray} !important`,
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: `${COLORS.frenchBlue} !important`,
          },
          "&:hover": {
            backgroundColor: `${COLORS.backgroundGray} !important`,
          },
          "&.Mui-disabled": {
            color: COLORS.mediumGray,
            opacity: '1 !important'
          },
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: `${COLORS.frenchBlue} !important`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 'normal'
        },
      }
    },
    MuiTooltip: {
      defaultProps: {
        followCursor: true,
      },
    },
  },
  ...(isTest && { transitions: { create: () => 'none' } })
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 36,
  },
  title: {
    flexGrow: 1,
  },
}))

export const ADMIN_CLIENTS_PATH = 'clients' as const

function buildRouter({ currentUser, currentOrganization, accessibleVendors, authenticityToken }) {
  return createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <ApplicationContent authenticityToken={authenticityToken}/>
      </QueryParamProvider>}>
      <Route path="/" element={<Navigate to={defaultDashboard(currentUser)}/>} />

      <Route path="/dashboard/" element={<GlobalDashboard/>}/>
      <Route path="/clients/:clientId/dashboard/" element={<ClientDashboard/>}/>
      <Route path="/contracts/:contractId/dashboard/" element={<VendorDashboard/>}/>

      {userIsGlobalAdmin({currentUser}) && (
        <Route>
          <Route path={ADMIN_CLIENTS_PATH}>
            <Route index element={<ClientTable/>}/>
            <Route path="new">
              <Route index element={<MainContainer><ClientEdit /></MainContainer> }/>
            </Route>
            <Route path=":clientId/*" element={<AdminClientsPage />} />
          </Route>
          <Route path="vendors">
            <Route index element={<VendorTable currentUser={currentUser}/>}/>
            <Route path="new" element={<VendorEdit/>}/>
            <Route path=":vendorId" element={<VendorEdit/>}/>
          </Route>
        </Route>
      )}

      {(userIsGlobalAdmin({currentUser}) || someVendorPermitLandingPages({accessibleVendors})) &&
        <Route path="vendors/:vendorId/landing-pages">
          <Route index element={<LandingPages/>}/>
          <Route path="new/*" element={<LandingPageEdit/>}/>
          <Route path=":landingPageId/*" element={<LandingPageEdit/>}/>
        </Route>
      }

      {userIsGlobalAdmin({currentUser}) && (
        <Route path="organizations" element={<OrganizationTable/>}/>
      )}

      {userIsAdmin({currentUser}) && (
        <Route path="users" element={<Navigate to={`/organizations/${currentOrganization.id}/users`}/>}/>
      )}

      {(userIsGlobalAdmin({currentUser}) || userIsAdmin({currentUser})) && (
        <Route path="organizations/:organizationId/users" element={<UserTable/>}/>
      )}

      {organizationIsClient({currentUser}) && (
        <Route>
          <Route path="/clients/:clientId/*" element={<ClientManagePage/>}/>
          <Route path="/clients/:clientId/pacing" element={<MainContainer><PacingView /></MainContainer>}/>
        </Route>
      )}

      {organizationIsVendor({currentUser}) && (
        <Route>
          <Route path="/contracts/:contractId/integrations" element={<Integrations/>}/>
          <Route path="/contracts/:contractId/allocations" element={<Allocations/>}/>
        </Route>
      )}

      <Route path="/leads" element={<LeadTable/>}/>
      <Route path="/performance/*" element={<Performance/>}/>
      <Route path="*" element={<Navigate to='/'/>}/>
    </Route>
  ))
}

function defaultDashboard(currentUser) {
  return `${getDefaultScope(currentUser)}/dashboard/`
}

function ApplicationContent({ authenticityToken }) {
  const classes = useStyles()
  const sidebarRef = useRef(null)

  const { currentUser, currentOrganization } = useCurrentUser()

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Notifications/>

      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <Sidebar
          ref={sidebarRef}
          authenticityToken={authenticityToken}
          currentUser={currentUser}
          currentOrganization={currentOrganization}
        />
        <OutletContainer>
          <Outlet/>
        </OutletContainer>
      </Box>
    </div>
  )
}

function MainRouter({ authenticityToken }) {
  const { currentUser, currentOrganization, accessibleVendors } = useCurrentUser()
  const router = buildRouter({ currentUser, currentOrganization, accessibleVendors, authenticityToken })

  return (
    <RouterProvider router={router}/>
  )
}

type ApplicationContextProps = {
  portalHostname: string
  complyedHostname: string
  defaultLeadsHostname: string
  features: Features
}

type ApplicationProps = ApplicationContextProps & {
  authenticityToken: string
}

function ApplicationContextProviders({
  portalHostname,
  complyedHostname,
  defaultLeadsHostname,
  features,
  children,
}: PropsWithChildren<ApplicationContextProps>
) {
  return (
    <Compose items={[
      [FeaturesProvider, { features }],
      [ApolloProvider],
      [OrbitProvider],
      [StyledEngineProvider, { injectFirst: true }],
      [ThemeProvider, { theme: customTheme }],
      [ViewportProvider],
      [TimezoneProvider],
      [LocalizationProvider, { dateAdapter: AdapterMoment, dateLibInstance: moment }],
      [ModalProvider],
      [NotificationsProvider],
      [HostnameProvider, { portalHostname, complyedHostname, defaultLeadsHostname }],
      [ScopeProvider],
    ]}
    >
      {children}
    </Compose>
  )
}

export default function Application({
  portalHostname,
  complyedHostname,
  defaultLeadsHostname,
  authenticityToken,
  features }: ApplicationProps
) {
  const { remote } = useOrbit()
  const currentUserResource = useMemo(() => new CurrentUserResource({ remote }), [remote])

  return (
    <Suspenseful component={CurrentUserProvider} currentUserResource={currentUserResource}>
      <ApplicationContextProviders portalHostname={portalHostname} complyedHostname={complyedHostname} defaultLeadsHostname={defaultLeadsHostname} features={features}>
        <MainRouter authenticityToken={authenticityToken}/>
      </ApplicationContextProviders>
    </Suspenseful>
  )
}
