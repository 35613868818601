import { sumBy } from 'lodash-es'
import * as yup from 'yup'
import { getUnsetClientMappingCount } from '../ui/LandingPageEditor/RulesPage/DefaultCampaignSelection'
import { getClientsAndCampaigns } from './helpers'

export const MISSING_DEFAULT_CAMPAIGNS_ERROR = 'Missing default campaign selections'
export const MISSING_CAMPAIGN_FOR_LEAD_TARGET_ERROR = 'Missing client campaign for lead target'
export const MISSING_DEFAULT_DEGREE_PROGRAM_ERROR = 'Missing default degree program selection'

// Looser URL regex than the one built in to yup
export const URL_REGEX = /^https?:\/\/[a-z0-9\-._]+(?:\/[a-z0-9.\-_~!$&'"(){}*+,;=:@%?/]*)?$/i

const ruleCondition = yup.array(
  yup.object({
    id: yup.string().nullable(),
    key: yup.string().nullable(),
    question: yup.object({
      id: yup.string().nullable(),
      key: yup.string().nullable(),
    }).nullable(),
    operator: yup.string().required('Operator is required').typeError('Operator is required'),
    value: yup.array().compact().required('Value is required').min(1, 'Value is required'),
  }).test({
    name: 'is-attribute',
    test(value, ctx) {
      if(!(value.key || value.question?.id)) {
        return ctx.createError({ path: `${ctx.path}.questionOrKey`, message: 'Attribute is required', type: 'key or questionId'})
      }
      return true
    },
  })
)

yup.addMethod(yup.string, 'url', function url(message) {
  return this.matches(URL_REGEX, {
      name: 'url',
      message,
      excludeEmptyString: true,
    })
})

export function validatePageSchema(landingPage, pageSchema) {
  if(!landingPage.id) {
    return []
  }

  try {
    pageSchema.validateSync(landingPage, { abortEarly: false })
    return []
  }
  catch(error) {
    return error.errors
  }
}

export const mainSchema = yup.object({
  id: yup.string().nullable(),
  name: yup.string().required('Name is required').typeError('Name should not be a number'),
  template: yup.object({}),
  tags: yup.object({}),
  variables: yup.object({
    heading: yup.string().nullable(),
    subheading: yup.string().nullable(),
    accredInfo: yup.string().nullable(),
    autoOpenModal: yup.boolean().nullable(),
    blurb: yup.string().nullable(),
    complianceText: yup.string().nullable(),
    copyright: yup.string().nullable(),
    createdFromDegreeProgramId: yup.string().nullable(),
    cta: yup.string().nullable(),
    cta2: yup.string().nullable(),
    customCss: yup.string().nullable(),
    footerText: yup.string().nullable(),
    googleAdwordsConversionId: yup.string().nullable(),
    googleOptimizeContainerId: yup.string().nullable(),
    hasAccredInfo: yup.boolean().nullable(),
    hasSecondCta: yup.boolean().nullable(),
    hasTestimonial: yup.boolean().nullable(),
    implicitTcpa: yup.string().nullable(),
    modalHeading: yup.string().nullable(),
    modalOnlyDisclaimer: yup.string().nullable(),
    modalSubheading: yup.string().nullable(),
    title: yup.string().trim().min(1, 'Page Title cannot be blank').typeError('Page Title cannot be blank').nullable(),
    privacyPolicy: yup.string().nullable(),
    primaryColor: yup.string().nullable(),
    programName: yup.string().nullable(),
    programShortName: yup.string().nullable(),
    schoolName: yup.string().nullable(),
    secondCta: yup.string().nullable(),
    secondCta2: yup.string().nullable(),
    secondCtaHeader: yup.string().nullable(),
    secondCtaLeft: yup.string().nullable(),
    secondCtaRight: yup.string().nullable(),
    secondaryColor: yup.string().nullable(),
    showClock: yup.boolean().nullable(),
    showLabels: yup.boolean().nullable(),
    showSecurityMessage: yup.boolean().nullable(),
    submitButtonLabel: yup.string().nullable(),
    tcpaDisclosure: yup.string().nullable(),
    testimonial: yup.string().nullable(),
    testimonialFooter: yup.string().nullable(),
    testimonialFootnote: yup.string().nullable(),
    testimonialName: yup.string().nullable(),
    videoUrl: yup.string().nullable(),
  }),
  leadTargets: yup.array(
    yup.object({
      clientCampaignId: yup.string(),
      degreeProgramId: yup.string(),
    })
  ),
  routes: yup.array(
    yup.object({
      path: yup.string().defined('Path must be a string').typeError('Path must be a string').strict(true),
      hostname: yup.string().required('Hostname is required')
    }).required('Route is required')
  )
})

export const questionsSchema = yup.object().shape({
  steps: yup.array(
    yup.object({
      id: yup.string().nullable(),
      position: yup.number().required('Step position is required'),
      title: yup.string().nullable(),
      questions: yup.array(
        yup.object({
          id: yup.string().nullable(),
          position: yup.number().required('Question position is required'),
          text: yup.string().required('Question Text is required').typeError('Question Text is required'),
          key: yup.string().required('Question key is required').typeError('Question is required'),
          options: yup.array(yup.string().trim().required("Answer Option cannot be blank")).nullable(),
          fieldMapping: yup.array(
            yup.object({
              values: yup.array(yup.string().typeError('Select missing client answer to match to displayed answer')).nullable(),
              field: yup.string(),
              clientId: yup.string()
            })
          )
        }),
      )
    })
  )
})

export const rulesSchema = yup.object().shape({
  variables: yup.object({
    redirectUrlFailure: yup.string().url('Redirect URL must be a valid URL (or empty)').nullable(),
  }),
  duplicateRedirectUrl: yup.string().url('Redirect URL must be a valid URL (or empty)').nullable(),
  fallbackLeadTarget: yup.object().shape({
    id: yup.string(),
    clientCampaign: yup.object().when("degreeProgram", {
      is: null,
      then: yup.object(),
      otherwise: yup.object({
        campaignType: yup.string(),
        id: yup.string(),
        name: yup.string(),
        programGroup: yup.object({
          client: yup.object({
            id: yup.string(),
            name: yup.string(),
          }).nullable(),
          description: yup.string(),
          id: yup.string(),
        }),
      }).nullable().required("Campaign required when Program is specified.")
    }),
    degreeProgram: yup.object().when("clientCampaign", {
      is: null,
      then: yup.object(),
      otherwise: yup.object({
        id: yup.string(),
        name: yup.string(),
      }).nullable().required("Program required when Campaign is specified."),
    }),
  },  [["clientCampaign", "degreeProgram"]]).nullable(),
  leadTargets: yup.array(
    yup.object({
      id: yup.string(),
      clientCampaign: yup.object({
        id: yup.string(),
      }),
      degreeProgram: yup.object({
        id: yup.string(),
      }),
    })
  ),
  degreeProgramRules: yup.array(
    yup.object({
      id: yup.string().nullable(),
      position: yup.number().required('Position is required').typeError("Position is required"),
      default: yup.bool().nullable(),
      degreeProgramId: yup.string().required('DegreeProgramId is required').typeError("DegreeProgramId is required"),
      conditions: ruleCondition.nullable(),
    })
    .test('Non-default Degree Rules require at least one condition', 'Non-default Program Rules require at least one condition', function(value) {
      return !!(value.default || !!(value.conditions && value.conditions.length > 0))
    }),
  )
  .test('missingDefaultDegreeProgram', MISSING_DEFAULT_DEGREE_PROGRAM_ERROR, function() {
    return (this.parent.template.multiClient || this.parent.degreeProgramRules.some(rule => rule.default))
  }),
  clientCampaignRules: yup.array(
    yup.object({
      id: yup.string().nullable(),
      position: yup.number().required('Position is required').typeError("Position is required"),
      default: yup.bool().nullable(),
      clientMapping: yup.array(
        yup.object({
          clientCampaignId: yup.string(),
          clientId: yup.string(),
          programGroupIds: yup.array(yup.string()),
        })
      ).compact(),
      conditions: ruleCondition,
    })
    .test('At least one campaign selection is required', 'At least one campaign selection is required', function(value) {
      return !!(value.clientMapping && value.clientMapping.length > 0)
    })
    .test('Non-default Campaign Rules require at least one condition', 'Non-default Client Rules require at least one condition', function(value) {
      return !!(value.default || !!(value.conditions && value.conditions.length > 0))
    }),
  )
  .test('missingDefaultCampaigns', MISSING_DEFAULT_CAMPAIGNS_ERROR, function() {
    return (
      this.parent.clientCampaignRules.some(ccr => ccr.default === true)
    )
  })
  .test('un-mapped lead targets', MISSING_CAMPAIGN_FOR_LEAD_TARGET_ERROR, function() {
    return (
      !getUnsetCampaignCount(this.parent.leadTargets || [], this.parent.clientCampaignRules || [])
    )
  }),
  landingPageRules: yup.array(
    yup.object({
      id: yup.string().nullable(),
      position: yup.number().required('Position is required'),
      actionType: yup.string().required('Redirect or Exclude Programs is required').typeError('Redirect or Exclude Programs is required'),
      redirectUrl: yup.string().url('Redirect URL must be a valid URL (or empty)').nullable(),
      conditions: ruleCondition,
      degreeProgramSets: yup.array(
        yup.object({
          id: yup.string().nullable(),
          client: yup.object({
            id: yup.string().required('Client is required'),
          }),
          degreeProgramIds: yup.array(yup.string().trim()).required("Degree program is required for filter.").min(1, "Degree program is required for filter.")
        }),
      ).when("actionType", { is: "exclude_programs", then: yup.array().required("Filter is required for rule.").min(1, "Filter is required for rule.")}),
    }).test('Rules require at least one condition', 'Rules require at least one condition', function(value) {
      return value.conditions && value.conditions.length > 0
    }),
  ),
})

export const trackingSchema = yup.object()

function getUnsetCampaignCount(leadTargets, clientCampaignRules) {
  const selected = clientCampaignRules.flatMap(clientCampaignRules => clientCampaignRules.clientMapping)
  const clientsAndCampaigns = getClientsAndCampaigns(leadTargets)

  const unsetCount = sumBy(clientsAndCampaigns, ([client, clientCampaigns]) => {
    const filteredSelected = selected.filter(entry => entry.clientId === client.id)
    return getUnsetClientMappingCount(filteredSelected, clientCampaigns)
  })
  return unsetCount
}
