import { useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Fab from '@mui/material/Fab'

import AddIcon from '@mui/icons-material/Add'

import { buildRecord, getRemoteId } from '../../../lib/DataModel'
import Suspenseful from '../..//Suspenseful'
import SimpleTable from '../../SimpleTable'
import VendorsResource from '../../../resources/VendorsResource'
import { userIsGlobalAdmin } from 'lib/auth-helpers'
import { useOrbit } from 'providers/OrbitProvider'

function VendorTable({resource, currentUser, hasTabNavigation}) {
  const navigate = useNavigate()

  const vendors = resource.get()

  const isGlobalAdmin = userIsGlobalAdmin({ currentUser })
  const columns = [
    {
      title: 'Name',
      field: 'attributes.name', value: row => row.attributes.name,
      click: isGlobalAdmin ? (_event, row, {navigate}) => { navigate(getRemoteId(row)) } : undefined,
      visible: true,
    }
  ]


  return (
    <>
      <SimpleTable
        data={vendors}
        columns={columns}
        hasTabNavigation={hasTabNavigation}
        title={hasTabNavigation ? 'Vendors' : ''}
        options={{
          hover: isGlobalAdmin,
          eventContext: {navigate},
        }}
      />
      {isGlobalAdmin && (
        <Link to="/vendors/new" style={{display: 'flex', justifyContent: 'center', marginTop: '28px'}}>
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Link>
      )}
    </>
  )
}

export default function VendorTableWrapper(props) {
  const { remote } = useOrbit()
  const { clientId } = useParams()
  const client = clientId ? buildRecord('client', clientId) : null
  const resource = useMemo(() => new VendorsResource({ remote, client }), [remote, client])

  return (
    <Suspenseful
      component={VendorTable}
      resource={resource}
      {...props}
    />
  )
}
