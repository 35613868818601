import { http, HttpResponse } from "msw";
import Box from "@mui/material/Box";
import Performance from "./";
import { MockedProvider } from "@apollo/client/testing";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { GET_CLIENTS_QUERY } from "components/PerformanceTable/PerformanceTable";
import { screen, userEvent, waitFor, within } from "@storybook/test";
import FeaturesProvider, { useFeatures } from "providers/FeaturesProvider";
import { setStorage } from "lib/utils";
import { GET_MARKETING_PLATFORMS_QUERY } from "components/PerformanceTable/SpendDataValidity";
import TimezoneProvider from "lib/TimezoneProvider";
import ScopeProvider from "lib/ScopeProvider";
import { GET_SAVED_REPORTS_QUERY } from "components/PerformanceTable/PerformanceSidebar";

export default {
  title: "pages/Performance",
  component: Performance,
};

const Template = (args, { parameters }) => {
  const { features } = useFeatures()
  setStorage('PerformanceSidebar.open', parameters.sidebarOpen)

  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <ScopeProvider>
        <Box sx={{ minHeight: "2000px" }}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <FeaturesProvider
              features={{
                ...features,
                performance_table_column_menu: true,
                performance_table_media_columns: true,
              }}
            >
              <TimezoneProvider>
                <Performance {...args} />
              </TimezoneProvider>
            </FeaturesProvider>
          </QueryParamProvider>
        </Box>
      </ScopeProvider>
    </MockedProvider>
  );
};
export const Default = Template.bind({});
Default.args = {
  currentOrganization: {
    type: "organization",
    id: "1",
    attributes: {
      name: "Cygnus Education",
    },
    relationships: {
      client: {
        data: null,
      },
      vendor: {
        data: {
          type: "vendor",
          id: "c99af147-405e-4390-b16e-411e3a858faa",
        },
      },
    },
  },
  currentUser: {
    type: "user",
    id: "1",
    attributes: {
      permitGlobalAdmin: true,
    },
  },
};
Default.parameters = {
  sidebarOpen: false,
  reactRouter: {
    routePath: "/performance/*",
    browserPath: "/performance/quality",
  },
  orbit: {
    user: {
      permitGlobalAdmin: true,
    },
  },
  msw: {
    handlers: [
      http.get("/api/v1/contracts/*client-campaigns*", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Health",
              "campaign-type": "exclusive",
              "billable-lead-count": 808,
              "smart-score": "green",
              "public-id": "00003820",
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "96",
                  type: "program-groups",
                },
              },
            },
          },
          {
            id: "34",
            type: "client-campaigns",
            attributes: {
              name: "Two Line Campaign Title Example",
              "campaign-type": "shared",
              "billable-lead-count": 111,
              "smart-score": "yellow",
              "public-id": "29300280",
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "97",
                  type: "program-groups",
                },
              },
            },
          },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Education",
                "campaign-type": "exclusive",
                "billable-lead-count": 634,
                "smart-score": "red",
                "public-id": "00003820",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
          {
            id: "136",
            type: "client-campaigns",
            attributes: {
              name: "Liberal Arts",
              "campaign-type": "exclusive",
              "billable-lead-count": 105,
              "smart-score": null,
              "public-id": "29300280",
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "98",
                  type: "program-groups",
                },
              },
            },
          },
          ],
        });
      }),
      http.get("/api/v1/program-groups", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "96",
            type: "program-groups",
            attributes: {
              id: 96,
              "client-id": 123,
              description: "Health",
            },
            relationships: {
              client: { data: { id: "123", type: "clients" } },
            },
          },
          {
            id: "97",
            type: "program-groups",
            attributes: {
              id: 97,
              "client-id": 123,
              description: "Education",
            },
            relationships: {
              client: { data: { id: "123", type: "clients" } },
            },
          },
            {
              id: "98",
              type: "program-groups",
              attributes: {
                id: 98,
                "client-id": 123,
                description: "Liberal Arts",
              },
              relationships: {
                client: { data: { id: "123", type: "clients" } },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/sources", (_request, _params, _cookies) => {
        return HttpResponse.json([
          "asdf",
          "facebook",
          "fb",
          "fbl",
          "fb-rd",
          "foo",
          "go",
          "ig",
          "ju",
          "li",
          "lil",
          "or",
          "pn",
          "qu",
          "rd",
          "sn",
          "test",
          "test01",
          "test1",
          "tester",
          "testing",
          "testing123",
          "testwh",
          "tt",
          "yt",
        ]);
      }),
      http.get("/api/v1/vendors", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "1",
            type: "vendors",
            attributes: {
              id: 1,
              name: "Cygnus Education",
              "created-at": "2021-01-25T01:26:50.313Z",
              "updated-at": "2021-01-25T01:26:50.313Z",
              "contact-name": null,
              "contact-email": null,
              "street-address": null,
              city: null,
              state: null,
              "zip-code": null,
              phone: null,
              "external-config": {},
              "internal-for-client-id": null,
            },
            relationships: { organization: { data: { id: "1", type: "organizations" } } },
          },
            {
              id: "3",
              type: "vendors",
              attributes: {
                id: 3,
                name: "EducationStation",
                "created-at": "2022-01-07T12:21:14.095Z",
                "updated-at": "2022-01-07T12:21:14.095Z",
                "contact-name": null,
                "contact-email": null,
                "street-address": null,
                city: null,
                state: null,
                "zip-code": null,
                phone: null,
                "external-config": {},
                "internal-for-client-id": null,
              },
              relationships: { organization: { data: { id: "6", type: "organizations" } } },
            },
            {
              id: "2",
              type: "vendors",
              attributes: {
                id: 2,
                name: "Zovio Vendor 1",
                "created-at": "2021-12-10T16:18:13.031Z",
                "updated-at": "2021-12-10T16:18:13.031Z",
                "contact-name": "ZovioVendor1",
                "contact-email": "zoviovendor1@othervendor.com",
                "street-address": "123 Main St",
                city: "City",
                state: "KS",
                "zip-code": "19072",
                phone: "(215) 555-5555",
                "external-config": {},
                "internal-for-client-id": null,
              },
              relationships: { organization: { data: null } },
            },
          ],
        });
      }),
      http.get("/api/v1/degree-programs*", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "286",
            type: "degree-programs",
            attributes: { name: "" },
            relationships: {
              client: { data: { id: "135", type: "clients" } },
              "program-group": {
                data: { id: "61", type: "program-groups" },
              },
            },
          },
          {
            id: "195",
            type: "degree-programs",
            attributes: { name: "Accounting Associate's Degree" },
            relationships: {
              client: { data: { id: "94", type: "clients" } },
              "program-group": {
                data: { id: "153", type: "program-groups" },
              },
            },
          },
          ],
        });
      }),
      http.get("/api/v1/landing-pages", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [],
        });
      }),
      http.get("/api/v1/client-campaigns", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [],
        });
      }),
      http.get("/api/v1/ad-campaigns", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [],
        });
      }),
      http.get("/api/v1/ad-sets", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [],
        });
      }),
      http.post("/api/v1/performance", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            client_id: 123,
            complyed_creative_id: 1111,
            lead_count: 3,
            pending_lead_count: 0,
            filtered_lead_count: 1,
            rejected_lead_count: 0,
            error_lead_count: 1,
            returned_lead_count: 0,
            good_lead_count: 1,
            contacted_lead_count: 0,
            interviewed_lead_count: 0,
            applied_lead_count: 0,
            enrolled_lead_count: 0,
            started_lead_count: 0,
            class_passed_lead_count: 0,
            billable_lead_count: 1,
            total_cost_in_cents: 0,
            id: null,
          },
            {
              client_id: 125,
              complyed_creative_id: 22222,
              lead_count: 4,
              pending_lead_count: 0,
              filtered_lead_count: 2,
              rejected_lead_count: 0,
              error_lead_count: 2,
              returned_lead_count: 0,
              good_lead_count: 0,
              contacted_lead_count: 0,
              interviewed_lead_count: 0,
              applied_lead_count: 0,
              enrolled_lead_count: 0,
              started_lead_count: 0,
              class_passed_lead_count: 0,
              billable_lead_count: 1,
              total_cost_in_cents: 0,
              id: null,
            },
          ],
        });
      }),
      http.get("/api/v1/media", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [],
        });
      }),
    ],
  },
  mocks: [
    {
      request: {
        query: GET_CLIENTS_QUERY,
      },
      result: {
        data: {
          clients: [
            {
              id: 123,
              name: "Hover Over My Creative ID Column College",
              internalName: "Hover Over My Creative ID Column College",
              showDegreeProgramChanges: false,
              fields: [],
            },
            {
              id: 125,
              name: "College University",
              internalName: "College University",
              showDegreeProgramChanges: false,
              fields: [],
            },
          ],
        },
      },
    },
    {
      request: {
        query: GET_SAVED_REPORTS_QUERY,
        variables: {
          userId: 'me',
        },
      },
      result: {
        data: {
          savedReports: [
          ],
        },
      },
      maxUsageCount: Number.POSITIVE_INFINITY,
    },
    {
      request: {
        query: GET_MARKETING_PLATFORMS_QUERY,
      },
      result: {
        data: {
          marketingPlatforms: [
            {
              id: "3",
              name: "Facebook",
              type: "ConnectedService::MarketingPlatform::Facebook",
              validFrom: "2020-01-01 05:00:00 UTC",
              lastSyncedAt: "2021-01-02 22:00:00 UTC",
              __typename: "MarketingPlatform",
            },
            {
              id: "69",
              name: "Tiktok",
              type: "ConnectedService::MarketingPlatform::Tiktok",
              validFrom: "2020-01-01 05:00:00 UTC",
              lastSyncedAt: "2021-01-03 22:00:00 UTC",
              __typename: "MarketingPlatform",
            },
            {
              id: "70",
              name: "Snapchat",
              type: "ConnectedService::MarketingPlatform::Snapchat",
              validFrom: "2019-01-01 05:00:00 UTC",
              lastSyncedAt: "2020-12-03 22:00:00 UTC",
              __typename: "MarketingPlatform",
            },
          ],
        },
      },
      maxUsageCount: Number.POSITIVE_INFINITY,
    },
  ],
};

export const WithCreativePreview = Template.bind({});
WithCreativePreview.args = {
  ...Default.args,
};
WithCreativePreview.parameters = {
  ...Default.parameters,
};
WithCreativePreview.play = async ({ canvasElement }) => {
  await waitFor(async () => {
    userEvent.hover(
      within(canvasElement).getByTestId("TableChartOutlinedIcon"),
    );
  });

  userEvent.click(await screen.findByLabelText("Creative ID"));
  userEvent.unhover(await screen.findByLabelText("Creative ID"));

  const creativePreviewCell = await within(canvasElement).findByText("1111");
  await waitFor(async () => {
    userEvent.pointer({ target: creativePreviewCell, coords: { clientX: 10, clientY: 10 }});
  });
};

// Unable to have the snapshot capture the cell in its hover state
// Instead the error state is present and visible with manual hover
export const WithCreativePreviewError = Template.bind({});
WithCreativePreviewError.args = {
  ...WithCreativePreview.args,
};
WithCreativePreviewError.parameters = {
  ...WithCreativePreview.parameters,
};
WithCreativePreviewError.play = async (context) => {
  await WithCreativePreview.play(context);
  userEvent.click(await within(context.canvasElement).findByText("1111"));

  const theCell = within(context.canvasElement).getByRole("cell", { name: "1111" });
  userEvent.unhover(theCell);
};

export const ColumnSettings = Template.bind({});
ColumnSettings.args = {
  ...Default.args,
};
ColumnSettings.parameters = {
  ...Default.parameters,
};

ColumnSettings.play = async ({ canvasElement }) => {
  // Starts querying the component from its root
  const canvas = within(canvasElement);
  await waitFor(async () => {
    userEvent.hover(canvas.getByText("Good Leads"));
  });
  userEvent.click(canvas.getAllByLabelText("Column Settings")[2]);
};

export const GroupingColumnFilter = Template.bind({});
GroupingColumnFilter.args = {
  ...Default.args,
};
GroupingColumnFilter.parameters = {
  ...Default.parameters,
};

async function openColumnSettings(canvas, columnName) {
  const tableCell = await canvas.findByRole("columnheader", { name: columnName })
  userEvent.hover(tableCell)
  userEvent.click(await within(tableCell).findByLabelText("Column Settings"));
}

async function openFilterDialog(canvas, columnName) {
  openColumnSettings(canvas, columnName)
  userEvent.click(await screen.findByText("Filter column"));
  return await screen.findByRole("dialog")
}

GroupingColumnFilter.play = async ({ canvasElement }) => {
  // wait for table to load before getting the table
  const canvas = within(canvasElement);
  const filterDialog = await openFilterDialog(canvas, "Client")
  userEvent.click(within(filterDialog).getByLabelText("Filter By"));
  userEvent.click(await screen.findByText("Does not contain"));
  const filterValueOne = await within(filterDialog).findByRole("textbox", { name: "Value" })
  await userEvent.type(filterValueOne, "hover");
  userEvent.click(await within(filterDialog).findByText("Apply"));
};

export const ColumnSummarySingleValue = Template.bind({});
ColumnSummarySingleValue.args = {
  ...Default.args,
};
ColumnSummarySingleValue.parameters = {
  ...Default.parameters,
};

ColumnSummarySingleValue.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);
  const filterDialog = await openFilterDialog(canvas, "Good Leads")
  userEvent.click(await within(filterDialog).findByLabelText("Filter By"));
  userEvent.click(await screen.findByText("Greater than"));
  const filterValueOne = await within(filterDialog).findByRole("textbox", { name: "Value" })
  await userEvent.type(filterValueOne, "0");
  userEvent.click(await within(filterDialog).findByText("Apply"));
};

export const ColumnSummaryDoubleValue = Template.bind({});
ColumnSummaryDoubleValue.args = {
  ...Default.args,
};
ColumnSummaryDoubleValue.parameters = {
  ...Default.parameters,
};

ColumnSummaryDoubleValue.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);
  const filterDialog = await openFilterDialog(canvas, "Good Leads")
  userEvent.click(await within(filterDialog).findByLabelText("Filter By"));
  userEvent.click(await screen.findByText("Is between"));
  const [filterValueOne, filterValueTwo] = await within(filterDialog).findAllByRole("textbox", { name: "Value" })
  await userEvent.type(filterValueOne, "0");
  await userEvent.type(filterValueTwo, "1000");
  userEvent.click(await within(filterDialog).findByText("Apply"));
};

export const Subtotals = Template.bind({});
Subtotals.args = {
  ...Default.args,
};
Subtotals.parameters = {
  ...Default.parameters,
};

Subtotals.play = async ({ canvasElement }) => {
  // Starts querying the component from its root
  const canvas = within(canvasElement);
  await openColumnSettings(canvas, "Client")
  userEvent.click(await screen.findByText("Subtotal"));
};

export const DataValidity = Template.bind({});
DataValidity.args = {
  ...Default.args,
};
DataValidity.parameters = {
  ...Default.parameters,
};

DataValidity.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);
  await waitFor(async () => {
    await userEvent.hover(canvas.getByTestId("AnalyticsOutlinedIcon"));
  });
  userEvent.click(screen.getByText("Spend"));
  await userEvent.keyboard("{Escape}");
  await waitFor(async () => {
    await userEvent.hover(canvas.getByTestId("WarningAmberIcon"));
  });
}

export const SidebarExpanded = Template.bind({})
SidebarExpanded.args = {
  ...Default.args,
}
SidebarExpanded.sidebarOpen = true;
SidebarExpanded.parameters = {
  ...Default.parameters,
  sidebarOpen: true,
}

export const SavedReports = Template.bind({})
SavedReports.args = {
  ...Default.args,
}
SavedReports.sidebarOpen = true;
SavedReports.parameters = {
  ...Default.parameters,
  sidebarOpen: true,
  mocks: [
    ...Default.parameters.mocks.filter(mock => mock.request.query !== GET_SAVED_REPORTS_QUERY),
    {
      request: {
        query: GET_SAVED_REPORTS_QUERY,
        variables: {
          userId: 'me',
        },
      },
      result: {
        data: {
          savedReports: [
            {
              __typename: 'SavedReport',
              id: '1',
              name: 'Daily Report',
              userId: 'me',
              reportConfig: {
                tableSettings: {
                  columnFilters: [
                    {
                      field: 'filteredLeadCount',
                      operator: 'greater-than',
                      value: ['1'],
                    },
                  ],
                  reportingMode: 'COHORT',
                  rowFilters: [
                    {
                      field: 'source',
                      values: ['facebook'],
                    },
                  ],
                  showAdjusted: false,
                  sortColumn: 'clientId',
                  sortOrder: 'ASC',
                  subtotalColumns: [],
                  visibleColumns: ['clientId', 'billableLeadCount', 'filteredLeadCount'],
                },
                timeRange: {
                  relativeTimeRange: {
                    label: 'LAST_YEAR',
                  },
                  absoluteTimeRange: null,
                },
              },
            },
          ],
        },
      },
      maxUsageCount: Number.POSITIVE_INFINITY,
    }
  ],
}
SavedReports.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);
  userEvent.click(await canvas.findByLabelText("Daily Report"));
}

export const SidebarCollapsedMouseOver = Template.bind({})
SidebarCollapsedMouseOver.args = {
  ...Default.args,
}
SidebarCollapsedMouseOver.parameters = {
  ...Default.parameters,
}
SidebarCollapsedMouseOver.play = async ({ canvasElement }) => {
  // Starts querying the component from its root
  const canvas = within(canvasElement);
  // Looks up the input and fills it.
  await waitFor(async () => {
    await userEvent.hover(canvas.getByTestId('TableChartOutlinedIcon'));
  });
}
