import { useMemo, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import Suspenseful from '../../Suspenseful'

import AdCampaignSetsResource from '../../../resources/AdCampaignSetsResource'
import ClientResource from '../../../resources/ClientResource'
import { buildRecord, newRecord } from '../../../lib/DataModel'
import { replaceElement } from '../../../lib/utils'

import AdCampaignSet from './AdCampaignSet'
import { useOrbit } from 'providers/OrbitProvider';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
}));

function newAdCampaignSet(client) {
  return newRecord({
    type: "adCampaignSet",
    relationships: {
      client: { data: client },
    },
    attributes: {
      adCampaignIds: [],
    }
  })
}

function AdCampaignSetTable({ clientResource, adCampaignSetsResource }) {
  const classes = useStyles();
  const client = useMemo(() => clientResource.get(), [clientResource])
  const [adCampaignSets, setAdCampaignSets] = useState(() => adCampaignSetsResource.get());

  const handleAddAdCampaignSet = useCallback(() => {
    setAdCampaignSets([...adCampaignSets, newAdCampaignSet(client)])
  }, [adCampaignSets, setAdCampaignSets, client])

  const handleRemoveAdCampaignSet = useCallback(adCampaignSet => {
    setAdCampaignSets(replaceElement(adCampaignSets, adCampaignSet))
  }, [adCampaignSets, setAdCampaignSets])

  const handleSave = useCallback((oldRecord, newRecord) => {
    setAdCampaignSets(replaceElement(adCampaignSets, oldRecord, newRecord))
  }, [adCampaignSets, setAdCampaignSets])

  return (
    <>
      <Typography variant="h2" sx={{ mt: 4, mb:3 }}>
        Integrations
      </Typography>
      <div role="list" aria-label="Integrations">
        { adCampaignSets.map(adCampaignSet => (
          <AdCampaignSet
            key={adCampaignSet.id}
            adCampaignSet={adCampaignSet}
            onSave={handleSave}
            onRemove={handleRemoveAdCampaignSet}
            client={client}
          />
        ))}
      </div>

      <div className={classes.buttons}>
        <Button onClick={handleAddAdCampaignSet}>
          <AddIcon/>
          Add Integration
        </Button>
      </div>
    </>
  )
}

export default function AdCampaignSetTableLoader() {
  const { remote } = useOrbit()
  const { clientId } = useParams()
  const clientResource = useMemo(() => new ClientResource({ remote, id: clientId }), [remote, clientId])
  const adCampaignSetsResource = useMemo(() => new AdCampaignSetsResource({ remote, client: buildRecord('client', clientId) }), [clientId, remote])

  return (
    <Suspenseful
      component={AdCampaignSetTable}
      clientResource={clientResource}
      adCampaignSetsResource={adCampaignSetsResource}
      errorMessage="Error loading integrations"
    />
  )
}
