import { difference } from "lodash-es"
import { mainSchema, questionsSchema, rulesSchema, trackingSchema } from "./yupSchemas"

export const actions = {
  UPDATE_LANDING_PAGE: "UPDATE_LANDING_PAGE",
}

export const OPERATORS = [
  { id: 'in', value: 'is one of the following' },
  { id: 'not_in', value: 'is not any of the following' },
  { id: 'less_than', value: 'is less than' },
  { id: 'greater_than', value: 'is greater than' },
]

export const MAX_ERRORS = 3

export const tabs = {
  MAIN: 'main',
  QUESTIONS: 'questions',
  RULES: 'rules',
  TRACKING: 'tracking',
}

export const campaignTypes = {
  EXCLUSIVE: 'exclusive',
  SHARED: 'shared'
}

export const CAMPAIGN_TYPES_VALUES = Object.values(campaignTypes)

export const SCHEMAS = { main: mainSchema, questions: questionsSchema, rules: rulesSchema, tracking: trackingSchema }

export const SUGGESTED_QUESTIONS = [
  'GraduationYear',
  'StartClasses',
  'FirstName',
  'LastName',
  'StreetAddress',
  'City',
  'ZIP_Code',
  'Address',
  'ZipCodeSource',
  'AddressFromZip',
  'Email',
  'Phone',
  'State',
  'Citizen',
  'Age',
]

export const SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS = difference(SUGGESTED_QUESTIONS, ['GraduationYear', 'Citizen', 'StartClasses'])

export const CLIENT_FIELD_TYPE_BY_QUESTION_KEY = {
  FirstName:     'first_name',
  LastName:      'last_name',
  StreetAddress: 'street_address',
  City:          'city',
  State:         'state',
  ZIP_Code:      'zip_code',
  Email:         'email',
  Phone:         'phone',
}
