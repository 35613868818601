import { useCallback, useEffect, useMemo, useState } from "react"
import { alpha, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { CalendarProps, DateRange, Range, RangeFocus, RangeKeyDict } from 'react-date-range'
import moment, { Moment } from 'moment-timezone'
import { TimeRangeBuilder, isEndOfDay } from '../lib/time'
import { useTimezone } from '../lib/TimezoneProvider'
import { generateRange } from '../lib/utils'
import tinycolor from 'tinycolor2'
import SmallSelect from './SmallSelect'

import IconButton from '@mui/material/IconButton'
import Popover, { PopoverProps } from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import { DateField } from '@mui/x-date-pickers/DateField';
import Box from "@mui/material/Box";
import { bindPopover } from "material-ui-popup-state";
import useStateRef from "react-usestateref";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import COLORS from 'lib/colors';
import useId from '@mui/utils/useId'
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { addYears } from "date-fns";
import { IconButtonProps } from "@mui/material/IconButton";

export type MomentRange = [Moment, Moment]
export type NullableMomentRange = MomentRange | null

const useStyles = makeStyles(theme => ({
  /* Work around the fact that react-date-range highlights ALL days when the range is empty. See https://github.com/hypeserver/react-date-range/issues/360 */
  emptyRange: {
    '& .rdrSelected, & .rdrInRange, & .rdrStartEdge, & .rdrEndEdge': {
      background: '#fff',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span': {
      color: 'black',
      '&:after': {
        background: theme.palette.primary.main,
      },
    },
  },
  dateRange: {
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span': {
      color: 'black',
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: theme.palette.primary.main,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    margin: '18px 24px 12px 24px',
  },
  predefinedValuesContainer: {
    margin: '0 6px',
    borderBottom: '1px solid #f0f3f5',
  },
  formGroup: {
    maxWidth: 'fit-content',
    margin: '0 14px 12px 14px',
  },
  monthAndYearWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 12px',
  },
  monthAndYearDivider: {
    display: 'inline-block',
    width: 12,
  },
  prevNextButton: {
    color: theme.palette.action.active,
  },
}))

function isValidTimeRange(value: TimeRange) {
  return !!(value.label || (value.timeRange[0]?.isValid() && value.timeRange[1]?.isValid()))
}

function valueToDateRange(value : NullableMomentRange) : Range {
  if(value && value.length == 2 && value[0]?.isValid() && value[1]?.isValid()) {
    return {startDate: new Date(value[0].year(), value[0].month(), value[0].date()), endDate: new Date(value[1].year(), value[1].month(), value[1].date()), key: 'selection'}
  } else {
    return {
      key: 'selection'
    }
  }
}

function setYearMonthDay(moment : Moment, date : Date) : Moment {
  return moment.clone().set({year: date.getFullYear(), month: date.getMonth(), date: date.getDate() })
}

function dateRangeToTimeRange(range : Range, currentValue : NullableMomentRange, timezone : string) : TimeRange {
  if(range && range.startDate && range.endDate) {
    if(currentValue === null) {
      return {
        timeRange: [
          moment.tz([range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate()], timezone).startOf('day'),
          moment.tz([range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate()], timezone).endOf('day'),
        ]
      }
    } else {
      return {
        timeRange: [
          setYearMonthDay(currentValue[0], range.startDate),
          setYearMonthDay(currentValue[1], range.endDate),
        ]
      }
    }
  } else {
    return {
      label: 'Lifetime'
    }
  }
}

function MonthAndYearSelector({
  focusedDate,
  changeShownDate,
  showMonthArrow,
  minDate,
  maxDate,
  showMonthAndYearPickers,
} : CalendarProps & { focusedDate: Date, changeShownDate: ChangeShownDateFunction }) {
  const classes = useStyles()
  const monthNames = useMemo(() => [...generateRange(0,11)].map(month => moment().month(month).format('MMMM')), [])

  const handleMouseUp = useCallback((e : React.MouseEvent) => e.stopPropagation(), [])
  const handleClickPrev = useCallback(() => changeShownDate(-1, 'monthOffset'), [changeShownDate])
  const handleClickNext = useCallback(() => changeShownDate(+1, 'monthOffset'), [changeShownDate])
  const handleChangeMonth = useCallback((e: SelectChangeEvent) => changeShownDate(e.target.value, 'setMonth'), [changeShownDate])
  const handleChangeYear = useCallback((e: SelectChangeEvent) => changeShownDate(e.target.value, 'setYear'), [changeShownDate])

  const upperYearLimit = (maxDate || addYears(new Date(), 20)).getFullYear();
  const lowerYearLimit = (minDate || addYears(new Date(), -100)).getFullYear();

  return (
    <div onMouseUp={handleMouseUp} className={classes.monthAndYearWrapper}>
      {showMonthArrow ? (
        <IconButton onClick={handleClickPrev} size="small" className={classes.prevNextButton}>
          <ChevronLeftIcon fontSize="small"/>
        </IconButton>
      ) : null}
      {showMonthAndYearPickers ? (
        <span>
          <span>
            <SmallSelect
              value={focusedDate.getMonth()}
              onChange={handleChangeMonth}
              aria-label="Month"
            >
              {monthNames.map((monthName, i) => (
                <MenuItem key={i} value={i}>
                  {monthName}
                </MenuItem>
              ))}
            </SmallSelect>
          </span>
          <span className={classes.monthAndYearDivider} />
          <span>
            <SmallSelect
              value={focusedDate.getFullYear()}
              onChange={handleChangeYear}
              aria-label="Year"
            >
              {new Array(upperYearLimit - lowerYearLimit + 1)
                .fill(upperYearLimit)
                .map((val, i) => {
                  const year = val - i
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                })}
            </SmallSelect>
          </span>
        </span>
      ) : (
        <span>
          {monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
        </span>
      )}
      {showMonthArrow ? (
        <IconButton onClick={handleClickNext} size="small" className={classes.prevNextButton}>
          <ChevronRightIcon fontSize="small"/>
        </IconButton>
      ) : null}
    </div>
  )
}

type ToggleButtonProps = {
  onClick: (e: React.MouseEvent) => void
  icon: React.ReactNode
  label?: string
  toggleOn: boolean
} & IconButtonProps

function ToggleButton({ onClick, icon, label, toggleOn, ...props }: ToggleButtonProps) {
  return(
    <IconButton
      aria-label={label}
      aria-pressed={toggleOn}
      onClick={onClick}
      sx={{
        width: '30px',
        height: '30px',
        backgroundColor: toggleOn ? `${COLORS.frenchBlue} !important` : 'white',
        color: toggleOn ? 'white' : COLORS.frenchBlue
      }}
      {...props}
    >
      {icon}
    </IconButton>
  )
}

export function convertToAbsoluteTimeRange(timeRange: TimeRange, timezone: string) : NullableMomentRange {
  const timeRangeBuilder = new TimeRangeBuilder({ timezone: timezone })

  if (timeRange.timeRange) {
    return timeRange.timeRange
  }

  switch(timeRange.label) {
    case 'Today':
      return timeRangeBuilder.today()
    case 'Yesterday':
      return timeRangeBuilder.yesterday()
    case 'This Week':
      return timeRangeBuilder.weekToDate()
    case 'Last Week':
      return timeRangeBuilder.lastWeek()
    case 'This Month':
      return timeRangeBuilder.monthToDate()
    case 'Last Month':
      return timeRangeBuilder.lastMonth()
    case 'This Quarter':
      return timeRangeBuilder.quarterToDate()
    case 'Last Quarter':
      return timeRangeBuilder.lastQuarter()
    case 'This Year':
      return timeRangeBuilder.yearToDate()
    case 'Last Year':
      return timeRangeBuilder.lastYear()
    case 'Lifetime':
      return null
    default: // Shouldn't be reachable
      return null
  }
}

type BindPopoverProps = ReturnType<typeof bindPopover>

export type DatePickerPopoverProps = {
  value: TimeRange,
  onChange: (value: TimeRange) => void,
  timeInputsEnabled: boolean,
  showTimeInitial: boolean,
} & BindPopoverProps & Omit<PopoverProps, "value" | "onChange">

export const RELATIVE_RANGE_LABELS = ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Quarter', 'Last Quarter', 'This Year', 'Last Year', 'Lifetime'] as const
export type RelativeTimeRangeLabel = typeof RELATIVE_RANGE_LABELS[number]
export type RelativeTimeRange = {
  label: RelativeTimeRangeLabel,
  timeRange?: null
}
export type AbsoluteTimeRange = {
  label?: null,
  timeRange: MomentRange
}
export type TimeRange = RelativeTimeRange | AbsoluteTimeRange

type ChangeShownDateFunction = (value: Date | number | string, mode?: "set" | "setYear" | "setMonth" | "monthOffset") => void

function generatePredefinedTimeRanges() : RelativeTimeRange[] {
  return RELATIVE_RANGE_LABELS.map(label => ({ label: label }))
}

function isStartOfDayEndOfDay(timeRange : MomentRange) {
  return timeRange[0].hour() === 0 && timeRange[0].minute() === 0 && timeRange[1].hour() === 23 && timeRange[1].minute() === 59
}

export function isSameTimeRange(range1: TimeRange, range2: TimeRange) {
  if(range1.timeRange && range2.timeRange) {
    return range1.timeRange[0].isSame(range2.timeRange[0]) && range1.timeRange[1].isSame(range2.timeRange[1])
  }
  else if(!range1.timeRange && !range2.timeRange) {
    return range1.label === range2.label
  }
  else {
    return false
  }
}

type TimeSelectorProps = {
  currentAbsoluteValue: MomentRange
  setTimeRange: React.Dispatch<React.SetStateAction<TimeRange>>
}

type HourSelectorProps = {
  value: number
  setValue: (hour: number) => void
  label: string
  'aria-label': string
  isEnd?: boolean
}

function HourSelector({ value, setValue, label, 'aria-label': ariaLabel, isEnd = false }: HourSelectorProps) {
  const id = useId()

  const range = useMemo(() => isEnd ? [...generateRange(1, 24)] : [...generateRange(0, 23)], [isEnd])

  return (
    <TextField
      select
      value={value}
      onChange={event => setValue(Number(event.target.value))}
      label={label}
      id={id}
      sx={{ flex: '1' }}
      SelectProps={{
        inputProps: {
          "aria-label": ariaLabel,
        }
      }}
    >
      {isEnd && (
        <MenuItem disabled value="">--</MenuItem>
      )}
      {range.map(option => (
        <MenuItem value={option} key={option}>
          {option === 24 ? 'Midnight' : moment(option, 'HH').format('h A')}
        </MenuItem>
      ))}
      {!isEnd && (
        <MenuItem disabled value="">--</MenuItem>
      )}
    </TextField>
  )
}

function TimeSelector({currentAbsoluteValue, setTimeRange}: TimeSelectorProps) {
  const changeStartHour = useCallback((hour: number) => {
    const newValue = currentAbsoluteValue[0].clone().set('hour', hour)

    setTimeRange({
      timeRange: [newValue, currentAbsoluteValue[1]]
    })
  }, [currentAbsoluteValue, setTimeRange])

  const changeEndHour = useCallback((hour: number) => {
    const newValue = hour === 24 ?
      currentAbsoluteValue[1].clone().endOf('day') :
      currentAbsoluteValue[1].clone().set('hour', hour).set('minute', 0)

    setTimeRange({
      timeRange: [currentAbsoluteValue[0], newValue]
    })
  }, [currentAbsoluteValue, setTimeRange])

  return (
    <Stack direction='row' gap={2} sx={{mx: 4}}>
      <Box sx={{ flex: '2' }} />
      <HourSelector
        value={currentAbsoluteValue[0].hours()}
        setValue={(hour: number) => changeStartHour(hour)}
        label={currentAbsoluteValue[0].format("MMM D")}
        aria-label="Start Time"
      />

      <HourSelector
        value={isEndOfDay(currentAbsoluteValue[1]) ? 24 : currentAbsoluteValue[1].clone().add(1, 'minute').hours() || 24}
        setValue={(hour: number) => changeEndHour(hour)}
        label={currentAbsoluteValue[0].format("MMM D")}
        aria-label="End Time"
        isEnd
      />
    </Stack>
  )
}

export default function DatePickerPopover({
  value: initialValue,
  onChange = () => undefined,
  timeInputsEnabled = false,
  ...popoverProps
} : DatePickerPopoverProps) {
  const classes = useStyles()
  const theme = useTheme()
  const rangeColors = useMemo(() => [tinycolor(theme.palette.primary.main).setAlpha(0.1).toRgbString()], [theme.palette.primary.main])
  const [currentValue, setCurrentValue, valueRef] = useStateRef<TimeRange>(initialValue)
  const { timezone } = useTimezone()
  const predefinedValues = useMemo(() => generatePredefinedTimeRanges(), [])
  const maxDate = useMemo(() => moment.tz(timezone).toDate(), [timezone])
  const [showTime, setShowTime] = useState(() => Boolean(timeInputsEnabled && initialValue.timeRange && !isStartOfDayEndOfDay(initialValue.timeRange)))
  const uniqueId = useId()

  const currentAbsoluteValue = useMemo(() => convertToAbsoluteTimeRange(currentValue, timezone), [currentValue, timezone])

  // Reset state if initialValue prop changes
  useEffect(() => {
    if(valueRef.current !== initialValue) {
      setCurrentValue(initialValue)
      setShowTime(Boolean(timeInputsEnabled && initialValue.timeRange && !isStartOfDayEndOfDay(initialValue.timeRange)))
    }
  }, [initialValue, setCurrentValue, timeInputsEnabled, valueRef])

  useEffect(() => {
    if(!currentAbsoluteValue || !timeInputsEnabled) {
      setShowTime(false)
    }
  }, [currentAbsoluteValue, timeInputsEnabled])

  const handleClose = useCallback((value: TimeRange) => {
    if(!isSameTimeRange(initialValue, valueRef.current) && isValidTimeRange(value)) {
      onChange(value)
    }
    popoverProps.onClose()
  }, [initialValue, onChange, popoverProps, valueRef])

  const handleSelect = useCallback(({selection} : RangeKeyDict) => {
    setCurrentValue((prevValue: TimeRange) => {
      return dateRangeToTimeRange(selection, convertToAbsoluteTimeRange(prevValue, timezone), timezone)
    })
  }, [setCurrentValue, timezone])

  const handleRangeFocusChange = useCallback((focusRange: RangeFocus) => {
    if(focusRange[0] === 0 && focusRange[1] === 0) {
      handleClose(valueRef.current)
    }
  }, [handleClose, valueRef])

  const handleSelectPredefined = useCallback((event: SelectChangeEvent) => {
    const newValue = {
      label: event.target.value as RelativeTimeRangeLabel
    }
    setCurrentValue(newValue)
    handleClose(newValue)
  }, [handleClose, setCurrentValue])

  const renderMonthAndYear = useCallback((focusedDate: Date, changeShownDate: ChangeShownDateFunction, props: CalendarProps) => (
    <MonthAndYearSelector focusedDate={focusedDate} changeShownDate={changeShownDate} {...props} />
  ), [])

  const isEmpty = currentAbsoluteValue === null

  return (
    <Popover
      {...popoverProps}
      onClose={() => { handleClose(valueRef.current) }}
    >
      <div className={classes.root}>
        <div className={classes.title}>
          <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            Select Date Range
            <Stack direction='row' gap={1}>
              {timeInputsEnabled &&
                <ToggleButton
                  disabled={!currentAbsoluteValue}
                  onClick={() => setShowTime(prev => !prev)}
                  icon={<AccessTimeIcon />}
                  toggleOn={showTime}
                  label="Toggle Time Fields"
                />
              }
            </Stack>
          </Stack>
        </div>

        <Stack direction='column' gap={2}>
          <Stack direction='row' gap={2} sx={{mx: 4}}>
            <FormControl sx={{ flex: '2' }}>
              <InputLabel id={`date-range-label_${uniqueId}`}>Date Range</InputLabel>
              {/* Relative range */}
              <Select
                labelId={`date-range-label_${uniqueId}`}
                id={`date-range_${uniqueId}`}
                value={currentValue.label || "Custom"}
                label="Date Range"
                onChange={handleSelectPredefined}
                data-testid='predefinedRange'
              >
                {predefinedValues.map((predefinedValue, index) => (
                  <MenuItem key={index} value={predefinedValue.label}>{predefinedValue.label}</MenuItem>
                ))}
                <MenuItem disabled value={'Custom'}>Custom</MenuItem>
              </Select>
            </FormControl>
            {/* Absolute range */}
            <DateField
              value={currentAbsoluteValue?.[0]}
              label="Date"
              inputProps={{
                "aria-label": "Start Date",
              }}
              onChange={(value) => setCurrentValue(prevValue => ({ timeRange: [value as Moment, prevValue.timeRange?.[1] ?? currentAbsoluteValue?.[1] ?? value as Moment]}) ) }
              sx={{ flex: '1' }}
            />
            <DateField
              value={currentAbsoluteValue?.[1]}
              label="Date"
              inputProps={{
                "aria-label": "End Date",
              }}
              onChange={(value) => setCurrentValue(prevValue => ({ timeRange: [prevValue.timeRange?.[0] ?? currentAbsoluteValue?.[0] ?? value as Moment, value as Moment]}) ) }
              sx={{ flex: '1' }}
            />
          </Stack>

          {/* Absolute time range */}
          {showTime && currentAbsoluteValue &&
            <TimeSelector currentAbsoluteValue={currentAbsoluteValue} setTimeRange={setCurrentValue} />
          }
        </Stack>
        <Divider sx={{mt: 2, mx: 2, mb: .5, borderColor: alpha(theme.palette.divider, 0.08) }}/>
        {/* Absolute range */}
        <DateRange
          className={clsx(classes.dateRange, {[classes.emptyRange]: isEmpty})}
          dateDisplayFormat="MM/dd/yyyy"
          onChange={handleSelect}
          onRangeFocusChange={handleRangeFocusChange}
          ranges={[valueToDateRange(currentAbsoluteValue)]}
          showDateDisplay={false}
          moveRangeOnFirstSelection={false}
          color={theme.palette.primary.main}
          rangeColors={rangeColors}
          navigatorRenderer={renderMonthAndYear}
          maxDate={maxDate}
          months={2}
          direction="horizontal"
          calendarFocus="backwards"
          preventSnapRefocus
        />
      </div>
    </Popover>
  )
}
