import { useCallback, useState } from 'react'

import MTableBody from '@mui/material/TableBody'
import MTableCell from '@mui/material/TableCell'
import MTableRow from '@mui/material/TableRow'

import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import clsx from 'clsx'
import useStyles from './styles'
import HoverableTableCell from './HoverableTableCell'
import RowActionsButton from './RowActionsButton'
import { renderCell, renderSummary } from './render'
import { useDataManager } from './DataManager'
import { groupBy } from 'lodash-es'
import React from 'react'

function EmptyBody({children}) {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <MTableBody className={classes.tbody}>
      <MTableRow>
        <MTableCell className={classes.td} colSpan={dataManager.getColSpan()} align="center">
          {children}
        </MTableCell>
      </MTableRow>
    </MTableBody>
  )
}

function TableRow({row, rowSpans, skipColumns, hasRowActions, rowActions, hover, hoverClass, eventContext, renderContext, rowErrors={}}) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const [isHoveringRow, setHoveringRow] = useState(false)
  const hasRowError = rowErrors[row.id?.toString()]

  const handleMouseEnter = useCallback(() => {
    setHoveringRow(true)
  }, [setHoveringRow])

  const handleMouseLeave = useCallback(() => {
    setHoveringRow(false)
  }, [setHoveringRow])

  return (
    <MTableRow data-testid={hasRowError ? 'errorRow' : 'tableRow'} className={hasRowError ? classes.errorRow : null} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <>
        {dataManager.getVisibleColumns().map((column, colIndex) => {
          const TableCell = column.tableCellComponent || HoverableTableCell

          return (
            !skipColumns[colIndex] && (
              <TableCell
                row={row}
                column={column}
                renderContext={renderContext}
                className={classes.td}
                key={colIndex}
                rowSpan={rowSpans[colIndex]}
                hover={hover}
                hoverClass={hoverClass}
                onClick={column.click}
                eventContext={eventContext}
                align={column.align}
                sx={column.sx}
              >
                {renderCell(row, column, renderContext)}
              </TableCell>
            )
          )
        })}
        {hasRowActions && (
          <HoverableTableCell row={row} className={classes.td} hover={hover} hoverClass={hoverClass} eventContext={eventContext}>
            <RowActionsButton row={row} rowActions={rowActions} isHoveringRow={isHoveringRow}/>
          </HoverableTableCell>
        )}
      </>
    </MTableRow>
  )
}

function getNumColumnsToCollapse(columns) {
  const firstDynamicSummary = columns.findIndex(column => column.summary && typeof(column.summary) === 'function' || typeof(column.summary) === 'string')
  if(firstDynamicSummary === -1) {
    return columns.length
  } else {
    return firstDynamicSummary
  }
}

function computeSkipColumns(summaryCollapse, visibleColumns) {
  if(summaryCollapse) {
    // numColumnsToCollapse is the number of consecutive columns at the beginning of the table with no dynamic summary
    const numColumnsToCollapse = getNumColumnsToCollapse(visibleColumns)
    if(numColumnsToCollapse > 1) {
      return [false, ...Array(numColumnsToCollapse - 1).fill(true), ...Array(visibleColumns.length - numColumnsToCollapse).fill(false)]
    }
  }

  return Array(visibleColumns.length).fill(false)
}

function SummaryRow({ hover, hoverClass, renderContext, summaryCollapse, hasRowActions, summaryData=null, skipColumns, topSummaryRow=false, subgroupColumn='', totalColumn='' }) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const data = summaryData || dataManager.data
  const visibleColumns = dataManager.getVisibleColumns()
  const isSubgroup = subgroupColumn.length > 0
  const summaryTitleReplacement = isSubgroup ? `${data[0][subgroupColumn]}` : 'Total'

  if(!skipColumns) {
    skipColumns = computeSkipColumns(summaryCollapse, visibleColumns)
  }

  return (
    <MTableRow className={clsx(classes.summaryRow, summaryData && classes.subtotalRow, topSummaryRow && classes.topSummaryRow, isSubgroup && classes.subgroupSummaryRow)}>
      <>
        {visibleColumns.map((column, colIndex) => {
          // colSpan is the number of consecutive true values in skipColumns immediately following the current column
          const colSpan = 1 + [...skipColumns.slice(colIndex + 1), false].findIndex(v => v === false)
          return !skipColumns[colIndex] && (
            <HoverableTableCell key={colIndex} colSpan={colSpan} className={classes.td} hover={hover} hoverClass={hoverClass} align={column.align} sx={column.sx}>
              {column.field === totalColumn && totalColumn.length &&
                summaryTitleReplacement
              ||
                renderSummary(data, column, renderContext)
              }
            </HoverableTableCell>
          )
        })}
        {hasRowActions && (
          <HoverableTableCell className={classes.td} hover={hover} hoverClass={hoverClass}>
          </HoverableTableCell>
        )}
      </>
    </MTableRow>
  )
}

export default function TableBody({hover, hoverClass, summaryRow, topSummaryRow, eventContext, renderContext, summaryCollapse, hasRowActions, rowActions = [], rowErrors = {}, subgroupColumn = '', totalColumn = '' }) {
  const classes = useStyles()
  const dataManager = useDataManager()
  hasRowActions ||= !!rowActions.length // Typically this already gets passed in as per the nature of options, but default if not

  const groupedData = dataManager.group(dataManager.data)
  const dataWithSubgroups = Object.entries(groupBy(groupedData, `row[${subgroupColumn}]`))
  const hasSubgroups = subgroupColumn.length > 0  && dataWithSubgroups.length > 1

  if(dataManager.error) {
    return (
      <EmptyBody>
        Failed to load data: {dataManager.error.message}
        <IconButton onClick={() => dataManager.reload()} size="large" aria-label="Refresh">
          <RefreshIcon/>
        </IconButton>
      </EmptyBody>
    );
  } else if(dataManager.data.length === 0) {
    return (
      <EmptyBody>
        {!dataManager.loading && 'No data to show.'}
      </EmptyBody>
    )
  } else {

    return (
      <MTableBody className={classes.tbody} key={dataManager.version}>
        {!!topSummaryRow && (
          <SummaryRow summaryCollapse={summaryCollapse} hasRowActions={hasRowActions} hover={false} hoverClass={hoverClass} renderContext={renderContext} skipColumns={false} topSummaryRow={true} />
        )}
        {hasSubgroups && dataWithSubgroups.map(([subgroupName, subgroupData]) => (
          <React.Fragment key={`${subgroupName}`}>
            <SummaryRow summaryData={subgroupData.map(data => data.row)} summaryCollapse={summaryCollapse} hasRowActions={hasRowActions} hover={hover} hoverClass={hoverClass} renderContext={renderContext} skipColumns={false} subgroupColumn={subgroupColumn} totalColumn={totalColumn} />
            {subgroupData.map((rowProps, rowIndex) => (
              <TableRow key={rowIndex} hasRowActions={hasRowActions} rowActions={rowActions} hover={hover} hoverClass={hoverClass} eventContext={eventContext} renderContext={renderContext} rowErrors={rowErrors} {...rowProps}/>
            ))}
          </React.Fragment>
        ))}

        {!hasSubgroups && groupedData.map((rowProps, rowIndex) => (
          <TableRow key={rowIndex} hasRowActions={hasRowActions} rowActions={rowActions} hover={hover} hoverClass={hoverClass} eventContext={eventContext} renderContext={renderContext} rowErrors={rowErrors} {...rowProps}/>
        ))}
        {!!summaryRow && (
          <SummaryRow summaryCollapse={summaryCollapse} hasRowActions={hasRowActions} hover={hover} hoverClass={hoverClass} renderContext={renderContext} skipColumns={false}/>
        )}
      </MTableBody>
    )
  }
}
