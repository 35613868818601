import { useCallback, useMemo, useState } from 'react'
import Suspenseful from '../../Suspenseful'
import EntitySelector from './EntitySelector'
import AdCampaignsSelector from './AdCampaignsSelector'

import MarketingPlatformsResource from '../../../resources/MarketingPlatformsResource'
import AdAccountsResource from '../../../resources/AdAccountsResource'
import AdCampaignsResource from '../../../resources/AdCampaignsResource'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import DeleteIcon from '@mui/icons-material/Delete'

import makeStyles from '@mui/styles/makeStyles'
import { getRemoteId } from '../../../lib/DataModel'
import { useNotifications } from 'lib/NotificationsProvider'
import { useOrbit } from 'providers/OrbitProvider'

const useStyles = makeStyles(_theme => ({
  main: {
    flex: 1,
  },
  actions: {
    margin: 10,
  },
  button: {
    margin: 5
  },
  loader: {
    justifyContent: 'flex-start',
    marginBottom: 10,
    transform: 'scale(0.5) translate(-50%)',
  },
}))

export default function AdCampaignSetForm({
  adAccount,
  adCampaignIds,
  adCampaignSet,
  client,
  marketingPlatform,
  onRemove,
  onSave,
  selectAll,
  setAdAccount,
  setAdCampaignIds,
  setInitialState,
  setMarketingPlatform,
  setSelectAll,
  toggleEditing,
}) {
  const classes = useStyles()
  const { remote } = useOrbit()
  const { addNotification } = useNotifications()

  const [submitting, setSubmitting] = useState(false)
  const marketingPlatformsResource = useMemo(() => new MarketingPlatformsResource({ remote }), [remote])
  const adAccountsResource = useMemo(() => marketingPlatform && new AdAccountsResource({ remote, marketingPlatform }), [remote, marketingPlatform])
  const adCampaignsResource = useMemo(() => adAccount && new AdCampaignsResource({ remote, adAccount }), [remote, adAccount])

  const canSubmit = !submitting && marketingPlatform && adAccount && (selectAll || adCampaignIds.length > 0)

  const handleSave = useCallback(() => {
    setSubmitting(true)

    const record = {
      type: 'adCampaignSet',
      id: adCampaignSet.id,
      relationships: {
        client: { data: client },
        adAccount: { data: adAccount },
      },
      attributes: {
        adCampaignIds: adCampaignIds,
      },
    }

    let promise

    if(!getRemoteId(adCampaignSet)) {
      promise = remote.update(q => q.addRecord(record), {url: `${remote.requestProcessor.urlBuilder.resourceURL(client.type, client.id)}/ad-campaign-sets`})
    } else {
      promise = remote.update(q => q.updateRecord(record))
    }

    promise.then((newRecord) => {
      onSave(adCampaignSet, newRecord)
      toggleEditing()
      addNotification({variant: 'notice', message: 'Successfully saved integration'})
    }).catch(error => {
      console.log(error)
      addNotification({variant: 'alert', message: "Error saving integration"})
    }).finally(() => {
      setSubmitting(false)
    })
  }, [adCampaignSet, client, adAccount, adCampaignIds, remote, onSave, toggleEditing, addNotification])

  const handleCancel = useCallback(() => {
    if(!getRemoteId(adCampaignSet)) {
      onRemove(adCampaignSet)
    } else {
      setInitialState(adCampaignSet)
    }

    toggleEditing()
  }, [adCampaignSet, onRemove, setInitialState, toggleEditing])

  const handleDelete = useCallback(() => {
    setSubmitting(true)
    if (!confirm(`Are you sure you want to delete this integration?`)) {
      return
    }

    remote.update(q => q.removeRecord(adCampaignSet))
      .then(() => {
        onRemove(adCampaignSet)
        addNotification({variant: 'notice', message: 'Successfully removed integration'})
      })
      .catch(error => {
        console.log(error)
        addNotification({variant: 'alert', message: "Error removing integration"})
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [remote, adCampaignSet, onRemove, addNotification])

  const handleChangeAdAccount = useCallback(adAccount => {
    setAdAccount(adAccount)
    setAdCampaignIds([])
    setSelectAll(true)
  }, [setAdAccount, setAdCampaignIds, setSelectAll])

  const handleChangeMarketingPlatform = useCallback(marketingPlatform => {
    setMarketingPlatform(marketingPlatform)
    handleChangeAdAccount(null)
  }, [setMarketingPlatform, handleChangeAdAccount])

  return (
    <Grid container direction="row">
      <Grid item className={classes.main}>
        <Grid container direction="column">
          <Grid item>
            <Suspenseful
              component={EntitySelector}
              loaderClassName={classes.loader}
              entitiesResource={marketingPlatformsResource}
              entity={marketingPlatform}
              setEntity={handleChangeMarketingPlatform }
              label="Source"
              nameAttribute="typeName"
            />
          </Grid>
          { adAccountsResource && (
            <Grid item>
              <Suspenseful
                component={EntitySelector}
                loaderClassName={classes.loader}
                entitiesResource={adAccountsResource}
                entity={adAccount}
                setEntity={handleChangeAdAccount}
                label="Ad Account"
              />
            </Grid>
          )}
          { adCampaignsResource && (
            <Grid item>
              <Suspenseful
                component={AdCampaignsSelector}
                loaderClassName={classes.loader}
                adCampaignsResource={adCampaignsResource}
                adCampaignIds={adCampaignIds}
                setAdCampaignIds={setAdCampaignIds}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Tooltip title="Delete integration">
          <IconButton aria-label="Delete integration" size="small" onClick={handleDelete} disabled={submitting}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid container justifyContent='center' className={classes.actions}>
        <Button disabled={!canSubmit} className={classes.button} onClick={handleSave}>
          Save
        </Button>
        <Button disabled={submitting} className={classes.button} variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}
