import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import moment from 'moment-timezone'
import * as yup from 'yup'
import makeStyles from '@mui/styles/makeStyles'

import ClientCampaignsResource from '../../resources/ClientCampaignsResource'
import AllocationsResource from '../../resources/AllocationsResource'
import ContractSelector, { ALL_CONTRACT, isAllContract } from '../ContractSelector'
import { Form, getIn, useFormikContext } from '../Formik/forms'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

import AllocationsTableFooter from './AllocationsTableFooter'
import MonthSelector from '../UI/MonthSelector'
import DailyCapModal from '../DailyCapModal/DailyCapModal'
import TableContainer from '../SimpleTable/TableContainer'
import TableContent from '../SimpleTable/TableContent'
import TableTitle from '../SimpleTable/TableTitle'
import AddClientCampaignButton from '../AddClientCampaignButton'

import DateRangeIcon from '@mui/icons-material/DateRange'

import { getRecordIdentity, getRemoteId, isNew } from '../../lib/DataModel'
import { isBlank, multiplyOrNull, sum, titlecase, toDollars, toFormattedNumber } from '../../lib/utils'
import { camelize } from '@orbit/serializers'
import { useDataManager } from 'components/SimpleTable/DataManager'
import { useNotifications } from 'lib/NotificationsProvider'
import { useFeatures } from 'providers/FeaturesProvider'
import { sum as lodashSum, uniq } from 'lodash-es'
import ContractsResource from 'resources/ContractsResource'
import { useCurrentUser } from 'lib/CurrentUserProvider'
import { useParams } from 'react-router-dom'
import { useOrbit } from 'providers/OrbitProvider'


const useStyles = makeStyles(theme => ({
  'smartScore-green': {
    color: theme.palette.success.main,
  },
  'smartScore-yellow': {
    color: theme.palette.warning.main,
  },
  'smartScore-red': {
    color: theme.palette.error.main,
  },
}))

function BlankSmartScoreIcon() {
  return (
    <Box
      sx={{
        fontSize: 14,
        fontWeight: 700
      }}
    >
      <span>
        —
      </span>
    </Box>
  )
}

function SmartScore({smartScore, smartScoreExplanation}) {
  const classes = useStyles()

  const iconLookup = {
    green: TrendingUpIcon,
    yellow: TrendingFlatIcon,
    red: TrendingDownIcon
  }

  const Icon = iconLookup[smartScore] || BlankSmartScoreIcon

  if(smartScoreExplanation) {
    return (
      <Tooltip title={smartScoreExplanation}>
        <Icon className={`${classes[`smartScore-${smartScore}`]}`}/>
      </Tooltip>
    )
  } else {
    return (
      <Icon className={`${classes[`smartScore-${smartScore}`]}`}/>
    )
  }
}

function AllocationField({row, field, format, onChange, placeholder = null}) {
  const inputRef = useRef()
  const dataManager = useDataManager()

  const { errors } = useFormikContext()
  const hasError = !!getIn(errors, `${row.id}.${field}`)

  // Determine if this field is the first error for autoFocus
  const isFirstError = useMemo(() => {
    const firstCampaignId = Object.keys(errors)[0]
    if(row.id !== firstCampaignId)
      return false
    return Object.keys(errors[firstCampaignId])[0] === field
  }, [errors, row.id, field])

  useEffect(() => {
    if(isFirstError && inputRef.current)
      inputRef.current.focus()
  }, [isFirstError])

  const toFormatted = useCallback(raw => {
    if(format === 'currency') {
      return toDollars(raw, { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else if(format === 'number') {
      return toFormattedNumber(raw)
    } else {
      return raw
    }
  }, [format])

  const [displayValue, setDisplayValue] = useState(() => toFormatted(getIn(row.allocation, `attributes.${field}`)))

  const fromFormatted = useCallback(formatted => {
    if(format === 'currency') {
      if(formatted === '') {
        return null
      } else {
        return Math.round(parseFloat(formatted.replace(/[^0-9.]/g, '')) * 100);
      }
    } else if(format === 'number') {
      if(formatted === '') {
        return null
      } else {
        return parseInt(formatted.replace(/[^0-9.]/g, ''));
      }
    } else {
      return formatted
    }
  }, [format])

  const handleChange = useCallback(event => {
    if(hasError) {
      delete errors[row.id]
    }
    const newValue = fromFormatted(event.target.value)
    setDisplayValue(event.target.value)
    onChange(row, field, newValue)
  }, [fromFormatted, setDisplayValue, onChange, row, field, hasError, errors])

  const finalizeDisplayValue = useCallback(_event => {
    setDisplayValue(toFormatted(getIn(row.allocation, `attributes.${field}`)))
  }, [setDisplayValue, toFormatted, row.allocation, field])

  useEffect(() => {
    finalizeDisplayValue()
  }, [row.allocation, finalizeDisplayValue])

  const interceptEnter = useCallback(event => {
    if(event.keyCode === 13) {
      event.target.blur()
    }
  }, [])

  const inputProps = useMemo(() => {
    if(format === 'currency') {
      return {
        startAdornment: <InputAdornment
                          position="start"
                          disableTypography={true}
                          sx={{
                            marginRight: '4px',
                            marginLeft: '12px',
                            fontSize: 14,
                            color: 'text.secondary',
                          }}
                        >
                          $
                        </InputAdornment>,
      }
    } else if (format === 'number') {
      return {
        inputMode: 'numeric',
        pattern: '[0-9]*'
      }
    } else {
      return {}
    }
  }, [format])

  return (
    <TextField
      InputProps={inputProps}
      sx={{
        width: '100px',
        mr: '-12px',
        '.MuiOutlinedInput-root': {
          backgroundColor: '#FFF',
          paddingLeft: 0
        },
        '.MuiOutlinedInput-input': {
          textAlign: 'right',
          lineHeight: '20px',
        },
        '.Mui-error': {
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
            borderColor: '#cd7865 !important'
          }
        }
      }}
      size="small"
      value={displayValue}
      onChange={handleChange}
      onBlur={finalizeDisplayValue}
      onKeyDown={interceptEnter}
      placeholder={placeholder}
      error={hasError}
      inputRef={inputRef}
      disabled={dataManager.loading}
    />
  )
}

function AllocationsTableContent({reloadContent, setNestedValue, deleteNestedValue}) {
  const { values, errors, resetForm } = useFormikContext()

  const handleChange = useCallback((row, field, value) => {
    row.allocation.attributes[field] = value
    row[field] = value
    reloadContent()
    const { cplInCents, cap } = row.allocation.attributes
    if (!cplInCents && !cap) {
      if (row.allocationId) {
        deleteNestedValue('clientCampaigns.allocations', row.allocation)
      } else if (values['_nested']) {
        // Remove the entire field from the form values and reset the form to undirty it if necessary
        const campaignIndex = values['_nested']['client_campaigns_attributes'].findIndex(r => r.id === row.clientCampaignId)
        values['_nested']['client_campaigns_attributes'].splice(campaignIndex, 1)
        if(values['_nested']['client_campaigns_attributes'].length === 0) {
          resetForm()
        }
      }
    } else {
      setNestedValue('clientCampaigns.allocations', row.allocation)
    }
  }, [reloadContent, setNestedValue, deleteNestedValue, values, resetForm])

  return (
    <TableContent
      title={"Campaign Allocations"}
      options={{
        topSummaryRow: true,
        hover: true,
        renderContext: {handleChange},
        rowErrors: errors,
        subgroupColumn: 'vendorName',
        totalColumn:'clientCampaignName'
      }}
    />
  )
}

export const generateAllocationSchema = cplLabel => yup.object().shape({
  cap: yup.number().min(0).typeError('Cap must be a number'),
  cpl_in_cents: yup.number().when('cap', {
    is: (val) => val > 0,
    then: yup.number().required(`${cplLabel} is required`).min(0, `${cplLabel} is required`).typeError(`${cplLabel} is required`)
  })
})

function AllocationsTableForm({contract, month, data, setData, refreshData, setRefreshData, cplLabel, client, permitSendIO}) {
  const { addNotification } = useNotifications()
  const allocationSchema = generateAllocationSchema(cplLabel)

  const validate = (values) => {
    const campaignAttrs = values['_nested']?.['client_campaigns_attributes'] || []
    const errors = {}
    campaignAttrs.forEach(campaign => {
      const allocation = campaign['allocations_attributes'][0] || {}

      try {
        allocationSchema.validateSync(allocation)
      } catch(e) {
        if (e instanceof yup.ValidationError) {
          errors[campaign.id] = { [camelize(e.path)]: e.errors }
        } else {
          throw e
        }
      }
    })

    // Flatten out all error messages stored in nested errors object to only show unique messages
    // e.g { campaignId => { fieldName => [error_message] } }
    const errorMessages = Object.values(errors).map((campaignErrors) => Object.values(campaignErrors)).flat(2)
    const uniqueErrors = [...new Set(errorMessages)]
    uniqueErrors.forEach((error) => {
      addNotification({variant: 'alert', message: error})
    })

    return errors
  }

  // Post to client endpoint in non-vendor case and do not reset contract
  const resource = (!contract || contract.id === ALL_CONTRACT.id) ? client : contract

  return (
    <Form
      resource={resource}
      redirectAfterSave={false}
      resetOnChange={[contract, month]}
      promptOnNavigate={true}
      validate={validate}
      withoutSubmitButton
    >
      {({setNestedValue, deleteNestedValue, resetForm}) => (
        <>
          <AllocationsTableContent setNestedValue={setNestedValue} deleteNestedValue={deleteNestedValue} reloadContent={() => setData([...data])}/>
          <AllocationsTableFooter
            permitSendIO={permitSendIO}
            contract={contract}
            month={month}
            onCancel={() => {
              setRefreshData(!refreshData)
              resetForm()
            }}
          />
        </>
      )}
    </Form>
  )
}

function getPayoutAbbrev(payout) {
  if(payout === 'cost_per_enroll') {
    return 'CPE'
  }

  return 'CPL'
}

// Payout column title is:
//    "CPE" or "CPL" if all rows are of the same payout type
//    "CPE/CPL" if the rows have a mix of CPE & CPL campaigns
//    "CPL" if there are no rows
function getPayoutColumnTitle(data) {
  if(isBlank(data)) {
    return "CPL"
  }

  return uniq(data.map(row => getPayoutAbbrev(row.payout)))
    .sort()
    .join('/')
}

function AllocationsTableContainer({contractFromRoute, clientFromRoute, contract, setContract, refreshCampaigns, hasTabNavigation}) {
  const { remote, store } = useOrbit()
  const { accessibleClients, accessibleVendorContracts, currentUser } = useCurrentUser()
  const { clientId } = useParams()

  const permitGlobalAdmin = getIn(currentUser, 'attributes.permitGlobalAdmin')
  const permitEditCap = permitGlobalAdmin || accessibleClients.some(c => c.id === clientFromRoute?.id)
  const permitEditCPL = permitGlobalAdmin || accessibleClients.some(c => c.id === clientFromRoute?.id)
  const permitSendIO = accessibleClients.some(c => c.id === clientFromRoute?.id)
  const permitEditVendorProjection = accessibleVendorContracts.some(c => c.id === contractFromRoute?.id)

  const client = clientFromRoute || store.cache.query(q => q.findRelatedRecord(contractFromRoute, 'client'))
  const timeZone = useMemo(() => (client && getIn(client, 'attributes.timeZone')), [client])
  const [month, setMonth] = useState(() => moment.tz(timeZone))
  const currentMonth = moment.tz(timeZone).utc(true).startOf('month')
  const maxMonth = currentMonth.clone().add(1, 'month')
  const [dailyCapsOpen, setDailyCapsOpen] = useState(false)
  const [data, setData] = useState(null)
  const [promise, setPromise] = useState(null)
  const [refreshAllocations, setRefreshAllocations] = useState(false)
  const { isFeatureFlagEnabled } = useFeatures()

  const isSmartScoreEnabled = isFeatureFlagEnabled('smart_score')

  const contractOrClient = isAllContract(contract) ? client : contract
  const allocationsResource = useMemo(() => { void(refreshAllocations); return contractOrClient && new AllocationsResource({remote, contractOrClient: contractOrClient, year: month.year(), month: month.month()+1}) }, [remote, contractOrClient, month, refreshAllocations])
  const clientCampaignsResource = useMemo(() => {void(refreshCampaigns); return contractOrClient && new ClientCampaignsResource({remote, contractOrClient: contractOrClient, year: month.year(), month: month.month()+1, programGroups: true})}, [contractOrClient, month, refreshCampaigns, remote]) // TODO: this is hacky, using "refreshCampaigns" to force reloading of the resource after creating a new campaign

  const contractsResource = useMemo(() => new ContractsResource({remote, clientId}), [clientId, remote])

  const campaignCaps = useMemo(() => (
    data ? data.map(row => ({clientCampaign: row.clientCampaign, cap: row.cap || 0})) : []
  ), [data])

  const newAllocation = useCallback((clientCampaign, year, month) => {
    const allocation = { type: 'allocation', attributes: { year, month }, relationships: { clientCampaign: { data: clientCampaign } } }
    store.update(q => q.addRecord(allocation))
    return allocation
  }, [store])

  useEffect(() => {
    setData(null)
    setPromise(contract && Promise.all([clientCampaignsResource.promise, allocationsResource.promise, contractsResource.promise]).then(([clientCampaigns, allocations, contracts]) => {
      const data = clientCampaigns
        .filter(clientCampaign => getIn(clientCampaign, 'attributes.status') === 'active')
        .map(clientCampaign => {
          const clientCampaignId = getRemoteId(clientCampaign)
          const existingAllocation = allocations.find(allocation => clientCampaign.id === getIn(allocation, 'relationships.clientCampaign.data.id'))
          const allocation = existingAllocation || newAllocation(clientCampaign, month.year(), month.month()+1)
          const cap = getIn(allocation, 'attributes.cap')
          const contract = contracts.find(contract => clientCampaign.relationships.contract.data.id === getIn(contract, 'id'))
          const payout = getIn(contract, 'attributes.payout')
          const vendorName = getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name')
          const goodLeadCount = getIn(clientCampaign, 'attributes.goodLeadCount')
          const billableLeadCount = getIn(clientCampaign, 'attributes.billableLeadCount')
          const acceptedLeadCount = payout === 'cost_per_enroll' ? goodLeadCount : billableLeadCount
          const capPercent = cap > 0 && goodLeadCount > 0 ? toFormattedNumber((goodLeadCount / cap) * 100) : null

          return {
            id: clientCampaignId,
            clientCampaign: clientCampaign,
            clientCampaignName: clientCampaign.attributes.name,
            clientCampaignId: clientCampaignId,
            clientCampaignPublicId: clientCampaign.attributes.publicId,
            clientCampaignType: titlecase(clientCampaign.attributes.campaignType),
            clientCampaignProgramGroup: getIn(store.cache.query(q => q.findRelatedRecord(clientCampaign, 'programGroup')), 'attributes.description'),
            payout: payout,
            smartScore: clientCampaign.attributes.smartScore,
            smartScoreExplanation: clientCampaign.attributes.smartScoreExplanation,
            acceptedLeadCount: acceptedLeadCount,
            totalCostInCents: getIn(clientCampaign, 'attributes.totalCostInCents'),
            cplInCents: getIn(allocation, 'attributes.cplInCents'),
            vendorProjection: getIn(allocation, 'attributes.vendorProjection'),
            cap: cap,
            capPercent: capPercent,
            allocationId: getRemoteId(allocation),
            allocation: allocation,
            vendorName: vendorName
          }
        })

      setData(data)
      return data
    }))
  }, [contract, clientCampaignsResource, allocationsResource, setData, month, contractsResource.promise, newAllocation, store])

  const payoutColumnTitle = useMemo(() => getPayoutColumnTitle(data), [data])
  const showBudget = useMemo(() => isBlank(data) || data?.some(row => row.payout === 'cost_per_lead'), [data])

  const columns = useMemo(() => [
    { title: 'Campaign',          field: 'clientCampaignName', render: (clientCampaignName) => <Box sx={{ minWidth: 144 }}>{clientCampaignName}</Box>, visible: true, summary: { text: 'Total' } },
    { title: 'Campaign ID',       field: 'clientCampaignPublicId', render: (clientCampaignPublicId) => <Box sx={{ minWidth: 70 }}>{clientCampaignPublicId}</Box>, visible: true },
    { title: 'Type',              field: 'clientCampaignType', visible: true },
    { title: 'Program Group',     field: 'clientCampaignProgramGroup', visible: true },
    { title: payoutColumnTitle,   field: 'cplInCents', align: 'right', render: (cplInCents, row, { handleChange }) => permitEditCPL ? <AllocationField row={row} field="cplInCents" format="currency" onChange={handleChange} /> : toDollars(cplInCents) || '—', visible: true, summary: { text: '' } },
    { title: 'Cap',               field: 'cap', align: 'right', render: (cap, row, { handleChange }) => permitEditCap ? <AllocationField row={row} field="cap" format="number" onChange={handleChange} placeholder="0" /> : toFormattedNumber(cap), visible: true, summary: data => sum(data, 'cap'), renderSummary: cap => toFormattedNumber(cap) },
    ...(isSmartScoreEnabled && [
      { title: 'Smart Score',     field: 'smartScore', align: 'center', render: (_smartScore, row) => <SmartScore smartScore={row.smartScore} smartScoreExplanation={row.smartScoreExplanation} />, visible: true, summary: { text: '' } },
    ] || []),
    { title: 'Accepted Leads',    field: 'acceptedLeadCount', align: 'right', render: (acceptedLeadCount, row) => row.capPercent ? <Tooltip title={`${row.capPercent}% Monthly Cap`}><span>{acceptedLeadCount}</span></Tooltip> : acceptedLeadCount, visible: true, summary: data => sum(data, 'acceptedLeadCount') },
    { title: 'Spend',             field: 'totalCostInCents', render: value => <Box sx={{minWidth: 79}}>{toDollars(value)}</Box>, align: 'right', visible: true, summary: data => sum(data, 'totalCostInCents') },
    ...(showBudget && [
      { title: 'Budget',          field: 'budget', value: row => multiplyOrNull(row.cplInCents, row.cap), render: value => toDollars(value), align: 'right', visible: true, summary: data => lodashSum(data.map(row => multiplyOrNull(row.cplInCents, row.cap))) },
    ] || []),
    { title: 'Vendor Projection', field: 'vendorProjection', align: 'right', render: (vendorProjection, row, { handleChange }) => permitEditVendorProjection ? (!isNew(row.allocation) ? <AllocationField row={row} field="vendorProjection" format="number" onChange={handleChange} /> : null) : toFormattedNumber(vendorProjection), visible: true, summary: data => sum(data, 'vendorProjection'), renderSummary: vendorProjection => toFormattedNumber(vendorProjection) },
  ], [payoutColumnTitle, isSmartScoreEnabled, showBudget, permitEditCap, permitEditCPL, permitEditVendorProjection])

  const permitEditDailyCaps = permitGlobalAdmin
  const tableActions = useMemo(() => {
    if(permitEditDailyCaps && contract && !isAllContract(contract)) {
      return [
        {
          icon: () => <DateRangeIcon/>,
          tooltip: 'Edit daily caps',
          onClick: () => setDailyCapsOpen(true),
        },
      ]
    } else {
      return []
    }
  }, [permitEditDailyCaps, contract])

  return (
    <TableContainer
      columns={columns}
      data={promise}
      hasTabNavigation={hasTabNavigation}
      options={{
        columnsButton: true,
        sortColumn: 'budget',
        sortOrder: 'desc'
      }}
      sx={{
        '& .MuiTableCell-root': {
          height: 60,
        },
      }}
    >
      <TableTitle title={"Campaign Allocations"} actions={tableActions} columnsButton={true}>
        <MonthSelector month={month} setMonth={setMonth} maxMonth={maxMonth}/>
        { clientFromRoute && !contractFromRoute && (
          <ContractSelector
            contract={contract}
            setContract={setContract}
            model="vendor"
            clientId={clientId}
            showAll={true}
          />
        )}
        {dailyCapsOpen && (
          <DailyCapModal
            contract={contract}
            clientCampaignsResource={clientCampaignsResource}
            onClose={() => setDailyCapsOpen(false)}
            campaignCaps={campaignCaps}
            month={month}
          />
        )}
      </TableTitle>
      {client ? (
        <AllocationsTableForm
          month={month}
          data={data}
          setData={setData}
          contract={contract}
          refreshData={refreshAllocations}
          setRefreshData={setRefreshAllocations}
          cplLabel={payoutColumnTitle}
          client={client}
          permitSendIO={permitSendIO}
        />
      ) : (
        <Box sx={{
            width: '100%',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#888'
          }}
        >
          Please select a Vendor
        </Box>
      )}
    </TableContainer>
  )
}

export default function AllocationsTable({hasTabNavigation=false}) {
  const { store } = useOrbit()
  const { accessibleClients, currentUser } = useCurrentUser()
  const { clientId, contractId } = useParams()
  const contractFromRoute = contractId ? store.cache.query(q => q.findRecord(getRecordIdentity('contract', contractId))) : null
  const clientFromRoute = clientId ? store.cache.query(q => q.findRecord(getRecordIdentity('client', clientId))) : null

  const [localContract, setLocalContract] = useState()
  const [refreshCampaigns, setRefreshCampaigns] = useState(false)
  const contract = contractFromRoute || localContract

  const permitGlobalAdmin = getIn(currentUser, 'attributes.permitGlobalAdmin')
  const permitAddCampaigns = permitGlobalAdmin || accessibleClients.some(c => c.id === clientFromRoute?.id)

  // Hacky way of resetting contract the currently set one not present in ContractSelector options
  // TODO: a better solution here once the ideal scope behavior is figured out
  useEffect(() => {
    setLocalContract(contractFromRoute || undefined)
  }, [clientId, contractId, contractFromRoute])

  return (
    <Box sx={{marginBottom: '128px'}}>
      <AllocationsTableContainer
        contractFromRoute={contractFromRoute}
        clientFromRoute={clientFromRoute}
        contract={contract}
        setContract={setLocalContract}
        refreshCampaigns={refreshCampaigns}
        hasTabNavigation={hasTabNavigation}
      />
      {permitAddCampaigns && (
        <AddClientCampaignButton
          contract={contract}
          onSave={() => setRefreshCampaigns(!refreshCampaigns)}
          clientId={clientId}
        />
      )}
    </Box>
  )
}
