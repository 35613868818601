import { useMemo } from 'react'

import Chart from 'react-apexcharts'
import moment from 'moment-timezone'

import { groupBy, sortBy, sum } from '../../lib/utils'

const GRADIENT = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAPoCAYAAAAftpReAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV/TiiItDi0o4pChOlkQFRFctApFqFBqhVYdTC79giYNSYqLo+BacPBjserg4qyrg6sgCH6AuLk5KbpIif9LCi1iPDjux7t7j7t3gNCoMNUMjAGqZhnpRFzM5lbF7lcE0I8wZhCSmKnPpVJJeI6ve/j4ehfjWd7n/hwhJW8ywCcSzzLdsIg3iKc2LZ3zPnGElSSF+Jx41KALEj9yXXb5jXPRYYFnRoxMep44QiwWO1juYFYyVOJJ4qiiapQvZF1WOG9xVis11ronf2Ewr60sc53mEBJYxBJSECGjhjIqsBCjVSPFRJr24x7+QcefIpdMrjIYORZQhQrJ8YP/we9uzcLEuJsUjANdL7b9MQx07wLNum1/H9t28wTwPwNXWttfbQDTn6TX21r0COjbBi6u25q8B1zuAANPumRIjuSnKRQKwPsZfVMOCN8CvWtub619nD4AGeoqeQMcHAIjRcpe93h3T2dv/55p9fcDk7BytBg5dYEAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQflBBoTBAbTFkyyAAACRUlEQVRYw+1XMXLDMAwD9P+PduoPgg62JZIC1a69s4dcbFmMCIIgAn19awDAAIEBzW+YH+uZhAEyv6fnFkzb2g+ayOsW6kLNhXUWID0rZy5p+QOt9JVSzQtYP8l9B8AB4jMgcA8aMlLe1p1FGBD1JA1N2GUKpXjcDbW0dyF5PZNyRjChlIpy7Ugvh4xmURZL7gDKzMm4aJ6Ae6grANMtwyF1wx4KFeDULM9aVaZjqUe7qkl5DYiZXIEb6qqqAZHm9OffLTydIF7xtJc7d2MkjTKIrmdmCmKsx8XJUAo1p8LDK9LVo9A2QRejNHtrjS46cgD4HFqc5tfiKjdwtgBT6xziLKsz6GwX2wG540FXLcamQ17dqsoYucCpDBhnBZUlNyw4NQN2YTQ8KASJHQAjN22AphG3ADS0SAsBtVAo5lI8RGIdIk3mcCIYzqJteC05BPIzRzgryPiNz169FZUfptnv7v6VdbBTct4Gk5AVuHaP+VZHggZIdmxvoUsjoVefIB5H/MLQVLIBMpMOyPVV54LUJcOjU5APcACHRu9bz+CE8bMbB5uCacRVt9Y8KWsJjVqoWANFFutM+eDN0HSjlZvS2LmngxctIpjeq2Ox1boKzv4yjmLkpyQOtqf0/j1/U6rOg9iZcrTTllfigPi5Gba8AHNvLb/BHMU5PODkvlxV4QyQdrfpHErbjYcJtjv44gqq0JrBrCpkLS6NYW6psv1jkdHs8k/JuIeJX6vUVao025RKZMiA/cGSPPi913u913v9l+sH4nUS5uVXH04AAAAASUVORK5CYII='

export default function MTDPacingChart({dailyCounts, monthlyCap, year, month, currentDay, title, subtitle, width="100%", height="auto"}) {
  const beginningOfRange = moment.utc([year, month-1]).startOf('month')
  const endOfRange = beginningOfRange.clone().endOf('month')

  const cumulativeData = useMemo(() => {
    const actualCounts = dailyCounts.filter(({attributes: {projection}}) => !projection)
    const dailyCountSumByDay = Object.fromEntries(groupBy(actualCounts, 'attributes.date', { asEntries: true })
      .map(([dateStr, dailyCounts]) => [moment(dateStr).date(), sum(dailyCounts, 'attributes.count')])
    )
    const dailyCountsWithZeroes = [...Array(currentDay)].map((_, index) => (
      [new Date(year, month-1, index+1), dailyCountSumByDay[`${index+1}`] || 0]
    ))
    return dailyCountsWithZeroes
      .reduce((accum, curval, index) => [...accum, [curval[0], index ? curval[1] + accum[accum.length-1][1] : curval[1]]], [])
  }, [currentDay, dailyCounts, month, year])

  const projections = useMemo(() => (
    groupBy(dailyCounts.filter(({attributes: {projection}}) => projection), 'attributes.projection', { asEntries: true })
      .filter(([_projection, projectionDailyCounts]) => !projectionDailyCounts.some(({attributes: {count}}) => count === null))
      .map(([projection, projectionDailyCounts]) => [projection, projectionDailyCounts[0].attributes.date, sum(projectionDailyCounts, 'attributes.count')])
  ), [dailyCounts])

  const ymax = useMemo(() => (
    Math.max(...cumulativeData.map(([_date, count]) => count), ...projections.map(([, , count]) => count), monthlyCap ? monthlyCap * 1.1 : 1)
  ), [cumulativeData, monthlyCap, projections])

  const annotations = useMemo(() => {
    const annotations = { yaxis: [], xaxis: [], points: [] }
    if(monthlyCap) {
      annotations.yaxis.push({
        y: monthlyCap,
        label: {
          text: `${beginningOfRange.format('MMM')} cap: ${monthlyCap}`,
          position: 'left',
          textAnchor: 'start',
        },
        strokeDashArray: 6,
        borderWidth: 3,
        borderColor: '#ff9f1A',
      })
    }
    return annotations
  }, [beginningOfRange, monthlyCap])

  const chartOptions = {
    chart: {
      id: "basic-bar",
      background: 'white',
      toolbar: {
        show: false
      },
      animations: {
        easing: 'easeout',
        speed: 400,
        animateGradually: false
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: title || '',
    },
    subtitle: {
      text: subtitle || '',
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        endingShape: "arrow"
      }
    },
    stroke: {
      width: [4, 4, 4],
      dashArray: [0, 10, 10],
      colors: ["#EF476F", "#FFD4DF", "#CFE9D9"],
      lineCap: 'round',
    },
    xaxis: {
      type: 'datetime',
      min: beginningOfRange.toDate().getTime(),
      max: endOfRange.toDate().getTime(),
      tickInterval: 'days',
      labels: {
        format: 'M/d',
      },
    },
    markers: {
      strokeDashArray: 20,
      size: [0, 4, 4],
      strokeWidth: [0, 3, 3],
      strokeColor: ["#EF476F", "#EF476F80", "#36c06980"],
      colors: ["#EF476F", "white", "white"],
      hover: {
        size: 6
      },
      discrete: [{
        seriesIndex: 1,
        dataPointIndex: 0,
        size: 0,
      },{
        seriesIndex: 2,
        dataPointIndex: 0,
        size: 0,
      }
      ],
    },
    colors: ["#EF476F", "#EF476F", "#36c069"],
    grid: {
      borderColor: '#00000026',
      strokeDashArray: 4,
    },
    fill: {
      type: ['solid', 'image', 'image'],
      colors: ["transparent", "#EF476F", "#EF476F"],
      image: {
        src: [GRADIENT, GRADIENT, GRADIENT],
      },
    },
    legend: {
      show: false,
      markers: {
        strokeWidth: 3,
        fillColors: ["#EF476F", "transparent", "transparent"],
        strokeColor: ["#EF476F", "#EF476F", "#36c069"],
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: ymax
    },
    /*
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    */
    tooltip: {
      /*
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        if(seriesIndex === 1 || seriesIndex === 2) {
          return null
        } else {
          return 'ok'
        }
      },
      */
      shared: true,
      intersect: false,
    },
    annotations,
  }

  const chartSeries = [
    {
      name: "Actual",
      type: "area",
      data: cumulativeData,
    },
  ]

  projections.forEach(([projection, date, count]) => {
    const projectedCount = Math.floor(cumulativeData[cumulativeData.length-1][1] * 31 / 28)
    chartSeries.push({
      name: projection,
      type: "area",
      data: [
        cumulativeData[cumulativeData.length-1],
        [new Date(date), count],
      ]
    })
  })

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="line"
      width={width}
      height={height}
    />
  )
}
