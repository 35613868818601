import { newRecord, getRecordIdentity } from '../lib/DataModel'

function newVendor() {
  return newRecord({
    type: 'vendor'
  })
}

export default class VendorResource {
  constructor({ remote, id }) {
    this.resolved = false
    this.promise = (id ? remote.query(q => q.findRecord(getRecordIdentity('vendor', id))) : Promise.resolve(newVendor()))
      .then(vendor => {
        this.vendor = vendor
        this.resolved = true
      })
      .catch(error => {
        this.error = error
      })
  }

  get() {
    if(this.resolved) {
      return this.vendor
    } else {
      throw this.error || this.promise
    }
  }
}
