import { Box } from "@mui/material";
import { useState } from "react";
import RadioButton from ".";

export default {
  title: "UI/RadioButton",
  component: RadioButton,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4095d86838c1093a6aeb9",
  },
};

const Template = () => {
  const [selectedValue, setSelectedValue] = useState("a");

  const buttonAArgs = {
    value: "a",
    checked: selectedValue === "a",
    onChange: () => setSelectedValue("a"),
    name: "nameA",
    inputProps: { "aria-label": "FooA" },
    label: "A is a short choice to make",
    sx: { marginLeft: "12px" },
  };

  const buttonBArgs = {
    value: "b",
    checked: selectedValue === "b",
    onChange: () => setSelectedValue("b"),
    name: "theB",
    inputProps: { "aria-label": "FooB" },
    label: "B is a very long choice to make that should wrap to the next line",
    sx: { marginLeft: "12px" },
  };

  const buttonCArgs = {
    value: "c",
    checked: selectedValue === "c",
    onChange: () => setSelectedValue("c"),
    name: "nameC",
    inputProps: { "aria-label": "FooC" },
    label: "C is another short choice to make",
    sx: { marginLeft: "12px" },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "2px solid blue",
        padding: "5px",
        width: "400px",
      }}
    >
      <RadioButton {...buttonAArgs} />
      <RadioButton {...buttonBArgs} />
      <RadioButton {...buttonCArgs} />
    </Box>
  );
};

export const Default = Template.bind({});
