import { RelativeTimeRangeLabel, TimeRange } from "components/DatePickerPopover"
import { RelativeTimeRangeLabel as RelativeTimeRangeLabelGraphQL, TimeRange as TimeRangeGraphQL, ReportingMode as ReportingModeGraphQL, SortOrder, Maybe, SavedReportConfigInput, TimeRangeInput, SavedReportConfig, AbsoluteTimeRange } from "generated/graphql"
import { titlecase } from "lib/utils"
import { cloneDeep, snakeCase, toLower, toUpper } from "lodash-es"
import moment from 'moment-timezone'
import { DeepReadonly } from "types/DeepReadonly"
import { ReportingMode, TableSettings } from "./TableSettings/types"
import { removeTypename } from "components/pages/LandingPages/data/utils"

function serializeTimeRangeLabel(label : RelativeTimeRangeLabel) : RelativeTimeRangeLabelGraphQL {
  return toUpper(snakeCase(label)) as RelativeTimeRangeLabelGraphQL
}

function deserializeTimeRangeLabel(label : RelativeTimeRangeLabelGraphQL) : RelativeTimeRangeLabel {
  return titlecase(toLower(label)) as RelativeTimeRangeLabel
}

export function serializeTimeRange(timeRange: DeepReadonly<TimeRange>) : TimeRangeInput {
  if(!timeRange.timeRange) {
    return {
      relativeTimeRange: { label: serializeTimeRangeLabel(timeRange.label || 'Lifetime') },
    }
  }
  else {
    return {
      absoluteTimeRange: {
        startTime: timeRange.timeRange[0],
        endTime: timeRange.timeRange[1],
      }
    }
  }
}

export function deserializeTimeRange(timeRange: TimeRangeGraphQL) : TimeRange {
  if(timeRange.relativeTimeRange) {
    return { label: deserializeTimeRangeLabel(timeRange.relativeTimeRange.label) }
  }
  else {
    const absoluteTimeRange = timeRange.absoluteTimeRange as AbsoluteTimeRange
    return {
      timeRange: [
        moment(absoluteTimeRange.startTime),
        moment(absoluteTimeRange.endTime),
      ]
    }
  }
}

function serializeReportingMode(reportingMode : ReportingMode) : ReportingModeGraphQL {
  return reportingMode.toUpperCase() as ReportingModeGraphQL
}

function deserializeReportingMode(reportingMode : ReportingModeGraphQL) : ReportingMode {
  return reportingMode.toLowerCase() as ReportingMode
}

function serializeSortOrder(sortOrder : 1 | -1 | null) : Maybe<SortOrder> {
  if(sortOrder === 1) {
    return SortOrder.Asc
  }
  else if(sortOrder === -1) {
    return SortOrder.Desc
  }

  return null
}

function deserializeSortOrder(sortOrder : Maybe<SortOrder>) : 1 | -1 | null {
  if(sortOrder === SortOrder.Asc) {
    return 1
  }
  else if(sortOrder === SortOrder.Desc) {
    return -1
  }

  return null
}

export function serializeSavedReportConfig(tableSettings: DeepReadonly<TableSettings>, timeRange : DeepReadonly<TimeRange>) : SavedReportConfigInput {
  return {
    timeRange: serializeTimeRange(timeRange),
    tableSettings: {
      columnFilters: Object.entries(tableSettings.columnFilters).map(([field, columnFilter]) => ({ field, ...cloneDeep(columnFilter) })),
      reportingMode: serializeReportingMode(tableSettings.reportingMode),
      rowFilters: cloneDeep(tableSettings.rowFilters),
      showAdjusted: tableSettings.showAdjusted,
      sortColumn: tableSettings.sortColumn,
      sortOrder: serializeSortOrder(tableSettings.sortOrder),
      subtotalColumns: cloneDeep(tableSettings.subtotalColumns),
      visibleColumns: cloneDeep(tableSettings.visibleColumns),
    }
  }
}

export function deserializeSavedReportConfig(savedReportConfig: DeepReadonly<SavedReportConfig>) : TableSettings {
  const serializedTableSettings = removeTypename(savedReportConfig.tableSettings)

  return {
    columnFilters: Object.fromEntries(serializedTableSettings.columnFilters.map(({ field, operator, value }) => [field, cloneDeep({ operator, value })])),
    reportingMode: deserializeReportingMode(serializedTableSettings.reportingMode),
    rowFilters: cloneDeep(serializedTableSettings.rowFilters),
    showAdjusted: serializedTableSettings.showAdjusted ?? false,
    sortColumn: serializedTableSettings.sortColumn ?? null,
    sortOrder: deserializeSortOrder(serializedTableSettings.sortOrder ?? null),
    subtotalColumns: cloneDeep(serializedTableSettings.subtotalColumns),
    visibleColumns: cloneDeep(serializedTableSettings.visibleColumns),
  }
}
