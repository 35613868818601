import { useFormikContext, FormTextField, getIn } from 'components/Formik/forms'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import DegreeProgramSets from './DegreeProgramSets'

const LANDING_PAGE_RULE_ACTION_TYPES = {redirect: 'Redirect URL', exclude_programs: 'Exclude Programs'}

export default function ActionType({ path }) {
  const { values, setFieldValue } = useFormikContext()
  const landingPageRule = getIn(values, path)

  const handleRuleActionTypeSelect = event => {
    if(event.target.value !== 'redirect') {
      setFieldValue(`${path}.redirectUrl`, null)
    }
    setFieldValue(`${path}.actionType`, event.target.value)
  }

  // offset Grid width and left margin it aligns with the Stack
  const offset = 2 * 6

  return(
    <Grid container spacing={2} sx={{width: `calc(100% + ${offset}px)`, ml: `-${offset}px !important`, mt: '0 !important' }}>
      <Grid item xs={4}>
        <TextField
          select
          value={landingPageRule.actionType || ''}
          onChange={handleRuleActionTypeSelect}
          label='Redirect or Exclude Programs'
          fullWidth
          id={`landing_page_rule_type_${Math.random()}`}
        >
          {Object.entries(LANDING_PAGE_RULE_ACTION_TYPES).map(([actionType, text]) => (
            <MenuItem value={actionType} key={`rule_question_${actionType}`}>{text}</MenuItem>
          ))}
        </TextField>
      </Grid>
      { landingPageRule.actionType === 'redirect' && (
        <Grid item xs={8}>
          <FormTextField
            name={`${path}.redirectUrl`}
            label='Redirect URL (leave blank to use default)'
            value={landingPageRule.redirectUrl || ''}
            fullWidth
            id={`landing_page_rule_operator_redirectUrl}`}
          />
        </Grid>
      )}
      { landingPageRule.actionType === 'exclude_programs' && (
        <DegreeProgramSets
          path={`${path}.degreeProgramSets`}
        />
      )}
    </Grid>
  )
}
