import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Typography from '@mui/material/Typography'
import COLORS from 'lib/colors'
import { useBookends } from 'lib/BookendsProvider'
import Box from '@mui/material/Box'
import MenuButton from "components/UI/MenuButton"
import Button from '@mui/material/Button'

export default function FooterBar({ errorMessage = null }) {
  const { bookends } = useBookends()

  return (
    <>
      <AppBar
        position="static"
        className='footer-bar'
        role='toolbar'
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: 'white',
          flexDirection: 'row',
          zIndex: 100
        }}
      >
        <Toolbar
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: '0px !important',
            margin: "18px 0px"
          }}
        >
          {errorMessage && (
            <>
              <ErrorOutlineIcon sx={{ fontSize: 30, color: COLORS.copperRed, pl: 1, pr: .5 }}></ErrorOutlineIcon>
              <Typography variant="status" color="error" component="div" sx={{ flexGrow: 1 }}>{errorMessage}</Typography>
            </>
          )}
          <Box sx={{ height: 48 }}>
            {bookends.footer.buttons.map((button, index) => {
              const sx = { mr: 4, ...button.sx }

              if(button.type === 'menu') {
                return <MenuButton
                  key={index}
                  items={button.items}
                  disabled={button.disabled}
                  text={button.label}
                  sx={sx}
                  {...button.buttonProps}
                />
              } else {
                return <Button
                  key={index}
                  sx={sx}
                  {...(button.type === 'secondary' && {
                    disableElevation: true,
                    variant: 'text',
                    color: 'secondary',
                  })}
                  {...(button.type === 'primary' && {
                    type: 'submit',
                  })}
                  disabled={button.disabled}
                  onClick={button.onClick}
                  {...button.buttonProps}
                >
                  {button.label}
                </Button>
              }
            })}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
