import { gridClasses, GridColumnMenu, GridColumnMenuProps, GridEventListener, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import DataGrid from "components/DataGrid/DataGrid";
import SendIcon from '@mui/icons-material/Send';
import SectionTitle from "components/UI/SectionTitle";
import { PageSection } from "components/UI/Structure/PageSection";
import StyledCard from "components/UI/StyledCard";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { buildRecord, getRemoteId } from "lib/DataModel";
import { getIn } from "components/Formik/forms";
import ClientCampaignsResource from "resources/ClientCampaignsResource";
import ContractsResource from "resources/ContractsResource";
import IconButton from "@mui/material/IconButton";
import StandardDialog from "components/UI/Dialog/StandardDialog";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { groupBy, sumBy } from "lodash-es";
import { gql, useMutation, useSuspenseQuery } from "@apollo/client";
import { useNotifications } from "lib/NotificationsProvider";
import COLORS from 'lib/colors';
import Suspenseful from "components/Suspenseful";
import moment, { Moment } from "moment-timezone";
import { useTimezone } from "lib/TimezoneProvider";
import { useBookends } from "lib/BookendsProvider";
import { useParams } from "react-router-dom";
import { useOrbit } from "providers/OrbitProvider";


export const INVOICE_FIELDS = gql`
  fragment InvoiceFields on Invoice {
    id
    month
    year
    sentAt
    contract {
      id
      client {
        id
        name
      }
      vendor {
        id
        name
      }
    }
    invoiceLineItems {
      id
      position
      clientCampaignName
      clientCampaignExportableId
      subtotalInCents
      billableLeadCount
    }
  }
`

const GET_INVOICES_QUERY = gql`
  ${INVOICE_FIELDS}
  query GetInvoices {
    invoices {
      ...InvoiceFields
    }
  }
`

const INVOICE_MUTATION_RETURNS = `
  id
  errors
  invoice {
    ...InvoiceFields
  }
`

const CREATE_INVOICE_MUTATION = gql `
  ${INVOICE_FIELDS}
  mutation CreateInvoice(
    $month: Int!
    $year: Int!
    $contractId: ID!
    $createInvoiceLineItems: [InvoiceLineItemInput!]
    $sendEmail: Boolean
  ) {
    mutationResult:createInvoice (input: {
      month: $month
      year: $year
      contractId: $contractId
      createInvoiceLineItems: $createInvoiceLineItems
      sendEmail: $sendEmail
    }) {
      ${INVOICE_MUTATION_RETURNS}
    }
  }
`

const RESEND_INVOICE_MUTATION = gql `
  ${INVOICE_FIELDS}
  mutation UpdateInvoice(
    $id: ID!
    $sendEmail: Boolean
  ) {
    mutationResult:updateInvoice (input: {
      id: $id
      sendEmail: $sendEmail
    }) {
      ${INVOICE_MUTATION_RETURNS}
    }
  }
`

const COMMON_INVOICE_COLUMNS = [
  {
    headerName: "Vendor Name",
    field: "vendorName",
    type: "string",
    minWidth: 200,
    columnOrder: 2,
  },
  {
    headerName: "Billable Leads",
    field: "billableLeadCount",
    type: "number",
    flex: 1,
    columnOrder: 5,
    disableColumnMenu: true,
  },
  {
    headerName: "CPL",
    field: "cplInCents",
    valueGetter: (value, row) => {
      if (!row.billableLeadCount || !row.totalCostInCents) {
        return null;
      }
      return row.totalCostInCents / row.billableLeadCount
    },
    type: "number",
    valueFormatter: (value) => value ? currencyFormatter.format(Number(value/100)) : '—',
    flex: 1,
    columnOrder: 6,
    disableColumnMenu: true,
  },
  {
    headerName: "Total",
    field: "totalCostInCents",
    type: "number",
    valueFormatter: (value) => currencyFormatter.format(Number(value/100)),
    flex: 1,
    columnOrder: 7,
    disableColumnMenu: true,
  },
]

const CLIENT_CAMPAIGN_COLUMN = {
  headerName: "Campaign Name",
  field: "clientCampaignName",
  type: "string",
  minWidth: 200,
  columnOrder: 3,
}

const INVOICED_PERIOD_COLUMN = {
  headerName: "Invoiced Period",
  field: "invoicedPeriod",
  type: "string",
  minWidth: 200,
  columnOrder: 1,
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function InvoiceRequestContent({invoiceLineItems, invoiceMoment, vendorName}) {
  const { clientId } = useParams()
  const { remote, store } = useOrbit()
  const client = store.cache.query(q => q.findRecord(buildRecord('client', clientId as string )))

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    remote.query(q => q.findRelatedRecords(client, 'vendors')).then(vendors => {
      setVendors(vendors)
    })
  }, [client, remote])

  // TODO: find vendor by contract id (stop passing vendorName in both instantiations)
  // will need to troublshoot why vendor is not showing the hasMany contracts relation
  const vendor = vendors && vendors.find(vendor => vendor.attributes.name === vendorName);
  const contactName = getIn(vendor, 'attributes.contactName')
  const contactEmail = getIn(vendor, 'attributes.contactEmail')
  const firstName = contactName && contactName.split(' ')[0]
  const totalLeadCount = sumBy(invoiceLineItems, 'billableLeadCount')
  const totalCostInCents = sumBy(invoiceLineItems, 'subtotalInCents')

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant="h4">
          Email Preview
        </Typography>
        {contactEmail &&
          <Typography
            color="info.main"
            variant="body2"
            sx={{ pl: 2 }}
          >

            Vendor Recipient: {contactName && ` ${contactName}, `}{contactEmail}
          </Typography>
        }
      </Box>
      <Box
        sx={{
          mt: 1,
          p: '12px 18px 18px 18px',
          borderRadius: '5px',
          border: `solid 1px ${COLORS.cadetGray}`,
          minHeight: 270,
          '& > p': {m: 0, mb: 1}
        }}
      >
        <p>Hello{firstName && ` ${firstName}`},</p>
        <p>Please see the {invoiceMoment.format('MMMM YYYY')} billable lead count and campaign costs for invoice submission.</p>

        <Table sx={{ maxWidth: 700, mt: 3, '.MuiTableCell-root': { p: 0, py: 0.5, border: 'none' } }} aria-label="simple table">
          <TableHead sx={{ '.MuiTableCell-root': { fontWeight: 'bold' } }}>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell align="right">Billable Leads</TableCell>
              <TableCell align="right">Cost Per</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceLineItems.map((row) => (
              <TableRow
                key={row.clientCampaignName}

              >
                <TableCell component="th" scope="row">
                  {row.clientCampaignName}
                </TableCell>
                <TableCell>{`CAMP-${row.publicId}`}</TableCell>
                <TableCell align="right">{row.billableLeadCount}</TableCell>
                <TableCell align="right">{currencyFormatter.format((row.subtotalInCents/row.billableLeadCount)/100)}</TableCell>
                <TableCell align="right">{currencyFormatter.format(row.subtotalInCents/100)}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ '.MuiTableCell-root': { fontWeight: 'bold', pt: 1 }, borderTop: `1px solid ${COLORS.charcoal}` }}>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">{totalLeadCount}</TableCell>
              <TableCell align="right">{'—'}</TableCell>
              <TableCell align="right">{totalCostInCents ? currencyFormatter.format(totalCostInCents/100) : '—'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

function statusRenderer(variant) {
  switch (variant) {
    case 'draft':
      return ({ rowNode }) => rowNode.type === 'group' ? 'Draft' : null
    case 'billable':
      return ({ rowNode }) => rowNode.type === 'group' ? 'Active' : null
    default:
      return ({ value }) => value
  }
}

function SendButton({ clickHandler, rowNode, label }) {
  return (
    <IconButton className='actionButton' onClick={(event)=>{ event.stopPropagation(); clickHandler(event, rowNode) }} aria-label={label} size="small" sx={{color: COLORS.charcoal, padding: .5 }}>
      <SendIcon/>
    </IconButton>
  )
}

function CustomColumnMenu(props: GridColumnMenuProps) {

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
    />
  );
}

function InvoiceTable({ data, groupingField, leafField, custom_columns = [{}], variant, controlRenderCell, onLeafRowClick = (_e, _data) => {return} }) {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: [groupingField]
      },
      columns: {
        columnVisibilityModel: {
          // Hide the column used for leaves
        [leafField]: false,
        },
      },
      aggregation: {
        model: {
          billableLeadCount: 'sum',
          totalCostInCents: 'sum',
        },
      },
    },
  });

  const columns = [
    ...COMMON_INVOICE_COLUMNS,
    ...custom_columns,
    {
      headerName: "Status",
      field: "status",
      type: "string",
      flex: 1,
      columnOrder: 4,
      renderCell: statusRenderer(variant),
      disableColumnMenu: true,
    },
    {
      field: 'id',
      headerName: '',
      type: 'string',
      renderCell: controlRenderCell,
      minWidth: 80,
      columnOrder: 8,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    }
  ].sort((a, b) => a.columnOrder - b.columnOrder)

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    ({ id }) => {
      const rowNode = apiRef.current.getRowNode(id);
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      }
      if (rowNode?.type === 'leaf') {
        onLeafRowClick(null, rowNode)
      }
    },
    [apiRef, onLeafRowClick],
  );

  return (
    <DataGrid
      columns={columns}
      rows={data || []}
      apiRef={apiRef}
      initialState={initialState}
      onRowClick={onRowClick}
      groupingColDef={{ minWidth: 400, leafField: leafField }}
      slots={{
        columnMenu: CustomColumnMenu,
      }}
      sx={{
        '.MuiDataGrid-footerContainer': {display: 'none'},
        '.MuiDataGrid-main': {minHeight: '400px'},
        "& .actionButton": {
          opacity: 0
        },
        [`& .${gridClasses.row}:hover`]: {
          ".actionButton": {
            opacity: 1
          }
        }
      }}
    />
  )
}

function incrementIndex(index, arrayLength) {
  return (index + 1) % arrayLength
}

function decrementIndex(index, arrayLength) {
  return (index - 1 + arrayLength) % arrayLength
}

function findInvoiceByLineItemId(invoices: Invoice[], lineItemId: string): Invoice | undefined {
  return invoices.find(invoice =>
    invoice.invoiceLineItems.some(item => item.id === lineItemId)
  );
}

type InvoiceLineItem = {
  // this is the Allocation ID in the draft case, and the InvoiceLineItem ID in any other case
  id: string
  billableLeadCount: number
  subtotalInCents: number
  clientCampaignName: string
  publicId: string
  position: number
}

type Invoice = {
  id?: string
  contractId: string
  vendorName: string
  invoiceMonth: number
  invoiceYear: number
  status: string
  billableLeadCount: number
  subtotalInCents: string
  totalCostInCents: number
  invoiceLineItems: InvoiceLineItem[]
}

type BaseDialogState = {
  open: boolean
  invoices: Invoice[]
}

type ClosedDialogState = BaseDialogState & {
  open: false
}

type OpenSingleDialogState = BaseDialogState & {
  open: true
  selectedContractId: string | undefined
}

type OpenMultipleDialogState = BaseDialogState & {
  open: true
  currentIndex: number
}

type DialogState = ClosedDialogState | OpenSingleDialogState | OpenMultipleDialogState

type DialogAction = {
  type: 'SEND_ALL_INVOICES' | 'CLOSE_DIALOG' | 'INCREMENT_INDEX' | 'DECREMENT_INDEX'
} | {
  type: 'SEND_INVOICE'
  allocationId: string
} | {
  type: 'UPDATE_INVOICES'
  invoices: Invoice[]
}

function dialogStateReducer(state : DialogState, action : DialogAction) : DialogState {
  switch (action.type) {
    case 'SEND_INVOICE':
      return {
        open: true,
        invoices: state.invoices,
        selectedContractId: findInvoiceByLineItemId(state.invoices, action.allocationId)?.contractId
      } as OpenSingleDialogState
    case 'SEND_ALL_INVOICES':
      return {
        open: true,
        invoices: state.invoices,
        currentIndex: 0
      } as OpenMultipleDialogState
    case 'INCREMENT_INDEX':
      return {
        open: true,
        invoices: state.invoices,
        currentIndex: incrementIndex(state.currentIndex || 0, state.invoices.length)
      } as OpenMultipleDialogState
    case 'DECREMENT_INDEX':
      return {
        open: true,
        invoices: state.invoices,
        currentIndex: decrementIndex(state.currentIndex || 0, state.invoices.length)
      } as OpenMultipleDialogState
    case 'CLOSE_DIALOG':
      return {
        open: false,
        invoices: state.invoices,
      } as ClosedDialogState
    case 'UPDATE_INVOICES':
      return {
        ...state,
        invoices: action.invoices
      } as DialogState
    default:
      // TODO: how to type for the unexpected string here (without causing other type errors)
      throw Error('Unknown action: ' + action.type);
  }
}

type SendDialogProps = {
  invoice: Invoice
  invoiceMoment: Moment
  onComplete: () => void
  handleClose: () => void
  onClickPrev: (() => void) | undefined
  onClickNext: (() => void) | undefined
}

function SendDialog({ invoice, invoiceMoment, onComplete, handleClose, onClickPrev, onClickNext } : SendDialogProps) {
  const [submitting, setSubmitting] = useState(false)

  const { addNotification } = useNotifications()
  const [gqlSend] = useMutation(CREATE_INVOICE_MUTATION)
  const handleSend = useCallback(() => {
    setSubmitting(true)

    const mutation = {
      sendEmail: true,
      month: invoice.invoiceMonth,
      year: invoice.invoiceYear,
      contractId: invoice.contractId,
      createInvoiceLineItems: invoice.invoiceLineItems.map(row => {
        return {
          position: row.position,
          clientCampaignName: row.clientCampaignName,
          clientCampaignExportableId: `CAMP-${row.publicId}`,
          clientCampaignId: row.id,
          subtotalInCents: row.subtotalInCents,
          billableLeadCount: row.billableLeadCount,
        }
      }),
    }
    gqlSend({ variables: mutation }).then(() => {
      addNotification({variant: 'notice', message: `Invoice Request sent to ${invoice.vendorName}`})
    }).catch(_error => {
      addNotification({variant: 'alert', message: "Trouble sending Invoice Request. Please try again later."})
    }).finally(() => {
      onComplete()
      setSubmitting(false)
    })
  }, [invoice.invoiceMonth, invoice.invoiceYear, invoice.contractId, invoice.invoiceLineItems, invoice.vendorName, gqlSend, addNotification, onComplete])

  return (
    <StandardDialog
      title={`${invoiceMoment.format('MMMM YYYY')} Invoice Request`}
      description={<>Send the following invoice request for <b>{invoiceMoment.format('MMMM YYYY')}</b> to <b>{invoice.vendorName}</b>?</>}
      onCancel={handleClose}
      onConfirm={handleSend}
      confirmText='Send'
      maxWidth='840px'
      loading={submitting}
      onClickPrev={onClickPrev}
      onClickNext={onClickNext}
    >
      <InvoiceRequestContent invoiceLineItems={invoice.invoiceLineItems} invoiceMoment={invoiceMoment} vendorName={invoice.vendorName} />
    </StandardDialog>
  )
}

function invoicesFromData(data, invoicePeriod) {
  if (!data) {
    return []
  }

  const dataByContract = groupBy(data, 'contractId')
  const contractsToBeInvoiced = Object.keys(dataByContract)
  const invoices = contractsToBeInvoiced.map(contractId => {
    const contractData = dataByContract[contractId]
    const billableLeadCount = sumBy(contractData, 'billableLeadCount')
    const totalCostInCents = sumBy(contractData, 'totalCostInCents')

    return {
      contractId: contractId,
      vendorName: contractData[0].vendorName,
      invoiceMonth: invoicePeriod.month() + 1,
      invoiceYear: invoicePeriod.year(),
      status: 'Draft',
      billableLeadCount: billableLeadCount,
      totalCostInCents: totalCostInCents,
      invoiceLineItems: contractData.map((row, index) => {
        return {
          id: row.id,
          billableLeadCount: row.billableLeadCount,
          subtotalInCents: row.totalCostInCents,
          clientCampaignName: row.clientCampaignName,
          publicId: row.publicId,
          position: index + 1,
        }
      })
    }
  })

  return invoices
}


function DraftInvoices({client, clientId, timezone, existingInvoices, setRefreshData}) {
  const { remote, store } = useOrbit()
  const previousMonth = useMemo(() => (moment.tz(timezone).utc(true).startOf('month').subtract(1, 'month')), [timezone])
  // month() is an index and gives 0-11 as Jan-Dec, so we add one
  const previousMonthNumeric = previousMonth.month() + 1
  const previousMonthInvoices = useMemo(() => (existingInvoices.filter(invoice => invoice.month === previousMonthNumeric && invoice.year === previousMonth.year())), [existingInvoices, previousMonth, previousMonthNumeric])

  const clientCampaignsResource = useMemo(() => {
    return new ClientCampaignsResource({remote, contractOrClient: client, year: previousMonth.year(), month: previousMonthNumeric, programGroups: true})
  }, [remote, client, previousMonth, previousMonthNumeric])
  const contractsResource = useMemo(() => {
    return new ContractsResource({remote, clientId})
  }, [remote, clientId])

  const [data, setData] = useState(null)

  useEffect(() => {
    const invoicedContractIds = [...new Set(previousMonthInvoices.map(i => i.contract.id))]
    Promise.all([clientCampaignsResource.promise, contractsResource.promise]).then(([clientCampaigns, contracts]) => {
      const data = clientCampaigns
        .filter(clientCampaign => getIn(clientCampaign, 'attributes.status') === 'active')
        .map(clientCampaign => {
          const clientCampaignId = getRemoteId(clientCampaign)
          const billableLeadCount = getIn(clientCampaign, 'attributes.billableLeadCount')
          const contract = contracts.find(contract => clientCampaign.relationships.contract.data.id === getIn(contract, 'id'))
          const vendorName = getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name')
          const publicId = getIn(clientCampaign, 'attributes.publicId')

          return {
            id: clientCampaignId,
            publicId: publicId,
            vendorName: vendorName,
            clientCampaignName: clientCampaign.attributes.name,
            status: 'Draft',
            billableLeadCount: billableLeadCount,
            totalCostInCents: getIn(clientCampaign, 'attributes.totalCostInCents'),
            contractId: getRemoteId(contract),
          }
        })
        .filter(row => row.billableLeadCount > 0)
        .filter(row => !invoicedContractIds.includes(row.contractId))
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName))
        .sort((a, b) => a.clientCampaignName.localeCompare(b.clientCampaignName))

      setData(data)
    })
  }, [clientCampaignsResource, setData, contractsResource.promise, previousMonthInvoices, store])

  const initialState = { invoices: [], currentIndex: 0, selectedContractId: undefined, open: false }
  const [state, dispatch] = useReducer(dialogStateReducer, initialState);

  useEffect(() => {
    const invoices = invoicesFromData(data, previousMonth)
    dispatch({ type: 'UPDATE_INVOICES', invoices: invoices })

    // Send CLOSE_DIALOG to maintain correct open state
    // (it will not display already since the dialog depends on currentInvoice)
    if (invoices.length === 0 ) {
      dispatch({ type: 'CLOSE_DIALOG'})
    }
  }, [data, previousMonth])

  const currentInvoice = state.selectedContractId ? state.invoices.find(i => i.contractId === state.selectedContractId ) : state.invoices[state.currentIndex]
  const showPrevAndNext = !state.selectedContractId && state.invoices.length > 1

  const { setBookends } = useBookends()
  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: 'primary',
            label: 'Send All Draft Invoices',
            disabled: state.invoices.length === 0,
            onClick: () => dispatch({ type: 'SEND_ALL_INVOICES' }),
          },
        ]
      }
    })
  }, [setBookends, state.invoices.length])

  function Send({ rowNode }) { return rowNode.type === 'group' ? <SendButton clickHandler={(e, rowData) => dispatch({ type: 'SEND_INVOICE', allocationId: rowData.children[0] })} rowNode={rowNode} label='Send Invoice' /> : null }

  return (
    <>
      <StyledCard
        title={`${previousMonth.format("MMMM")} Draft Invoices`}
        note='Billable lead count and CPL Drafts ready to be sent to vendors for invoice request.'
        controls={[
          {icon: <SendIcon/>, onClick: () => { dispatch({ type: 'SEND_ALL_INVOICES' }) }, label: 'Send All Drafts', disabled: state.invoices.length === 0},
        ]}
        isSubsection
        hasStickyHeader
        role="group"
      >
        <InvoiceTable variant='draft' data={data} groupingField='vendorName' leafField='clientCampaignName' custom_columns={[CLIENT_CAMPAIGN_COLUMN]} controlRenderCell={Send} />
      </StyledCard>

      {state.open && currentInvoice &&
        <SendDialog
          invoice={currentInvoice}
          invoiceMoment={previousMonth}
          onComplete={() => setRefreshData(value => value + 1)}
          handleClose={() => dispatch({ type: 'CLOSE_DIALOG'})}
          onClickPrev={showPrevAndNext ? () => dispatch({ type: 'DECREMENT_INDEX'}) : undefined}
          onClickNext={showPrevAndNext ? () => dispatch({ type: 'INCREMENT_INDEX'}) : undefined}
        />
      }
    </>
  )
}

function InvoiceHistory({ invoices, timezone }) {
  const data = useMemo(() => {
    return invoices.map(invoice => {
      const billableLeadCount = sumBy(invoice.invoiceLineItems, 'billableLeadCount')
      const totalCostInCents = sumBy(invoice.invoiceLineItems, 'subtotalInCents')

      return {
        id: invoice.id,
        // Remember, moment expects months by their index
        invoicedPeriod: moment([invoice.year, invoice.month - 1]).format('MMMM YYYY'),
        vendorName: invoice.contract.vendor.name,
        status: 'Sent',
        billableLeadCount: billableLeadCount,
        totalCostInCents: totalCostInCents,
        invoiceLineItems: invoice.invoiceLineItems.map((item) => {
          return {
            publicId: item.clientCampaignExportableId.replace('CAMP-',''),
            billableLeadCount: item.billableLeadCount,
            subtotalInCents: item.subtotalInCents,
            ...item,
          }
        }),
        sentAt: invoice.sentAt,
      }
    }).sort((a, b) => moment(b.invoicedPeriod, 'MMMM YYYY') - moment(a.invoicedPeriod, 'MMMM YYYY'))
  }, [invoices])

  const [open, setOpen] = useState(false);
  const handleOpen = (_e, data) => {
    setRowData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [rowData, setRowData] = useState({});
  const [submitting, setSubmitting] = useState(false)

  const invoiceData = data?.find(row => row.id === rowData.id)
  const vendorName = invoiceData?.vendorName

    // TODO: remove duplication between Send and Resend
  const { addNotification } = useNotifications()
  const [gqlResend] = useMutation(RESEND_INVOICE_MUTATION)
  const handleSend = useCallback(() => {
    setSubmitting(true)

    const mutation = {
      id: invoiceData.id,
      sendEmail: true,
    }
    gqlResend({ variables: mutation }).then(() => {
      addNotification({variant: 'notice', message: `Invoice Request resent to ${vendorName}`})
      handleClose()
    }).catch(_error => {
      addNotification({variant: 'alert', message: "Trouble sending Invoice Request. Please try again later."})
    }).finally(() => {
      setSubmitting(false)
    })
  }, [addNotification, gqlResend, invoiceData?.id, vendorName])

  function Resend({ rowNode }) { return rowNode.type !== 'group' ? <SendButton clickHandler={handleOpen} rowNode={rowNode} label='Resend Invoice' /> : null }

  return (
    <>
      <StyledCard
        title='Invoice History'
        note='Billable lead count and CPLs are frozen from adjustment once invoice status is Sent.'
        isSubsection
        hasStickyHeader
        role="group"
      >
        <InvoiceTable variant='history' data={data} groupingField='invoicedPeriod' leafField='vendorName' custom_columns={[INVOICED_PERIOD_COLUMN]} controlRenderCell={Resend} onLeafRowClick={handleOpen} />
      </StyledCard>

      {open &&
        <StandardDialog
          title={`${invoiceData.invoicedPeriod} Invoice Request`}
          description={<>Invoice request for <b>{invoiceData.invoicedPeriod}</b> sent to <b>{vendorName}</b> on <b>{moment.tz(invoiceData.sentAt, timezone).format('M/D/YY')}</b> at <b>{moment.tz(invoiceData.sentAt, timezone).format('h:mmA')}</b>.</>}
          onCancel={handleClose}
          onConfirm={handleSend}
          confirmText='Resend'
          maxWidth='840px'
          loading={submitting}
        >
          <InvoiceRequestContent invoiceLineItems={invoiceData.invoiceLineItems} invoiceMoment={moment.tz(invoiceData.invoicedPeriod, 'UTC')} vendorName={vendorName} />
        </StandardDialog>
      }
    </>
  )
}

function InvoicePage({ clientId }) {
  const { store } = useOrbit()
  const client = store.cache.query(q => q.findRecord({type: "client", id: clientId}))
  const { timezone } = useTimezone()
  const [refreshData, setRefreshData] = useState(0)

  const { data: { invoices } , refetch } = useSuspenseQuery(GET_INVOICES_QUERY)
  const clientInvoices = invoices.filter(invoice => invoice.contract.client.id === clientId)

  useEffect(() => {
    refetch()
  }, [refetch, refreshData])

  return (
    <PageSection>
      <SectionTitle title='Invoices' />
      <DraftInvoices client={client} clientId={clientId} timezone={timezone} existingInvoices={clientInvoices} setRefreshData={setRefreshData} />
      <InvoiceHistory invoices={clientInvoices} timezone={timezone} />
    </PageSection>
  )
}

export default function InvoicePageWrapper({ clientId }) {
  return (
    <Suspenseful component={InvoicePage} clientId={clientId} />
  )
}
