import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom'
import MainContainer from 'components/layout/MainContainer/MainContainer'
import FooterBar from 'components/UI/FooterBar'
import BookendsProvider, { useBookends } from 'lib/BookendsProvider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import HeaderTab from 'components/UI/HeaderTab/HeaderTab'
import PieChartIcon from '@mui/icons-material/PieChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForumIcon from '@mui/icons-material/Forum';
import RuleIcon from '@mui/icons-material/Rule';
import InfoIcon from '@mui/icons-material/Info';
import DegreePrograms from '../../DegreePrograms'
import FieldsEdit from '../../FieldsEdit'
import DegreeProgramEdit from '../../DegreeProgramEdit'
import ClientEdit from '../../ClientEdit'
import PacingView from '../../PacingView'
import ExclusionRulesEdit from '../../ExclusionRulesEdit'
import AdCampaignSetTableLoader from '../../AdCampaignSetTable'
import { useEffect, useMemo, useState } from 'react'
import AllocationsTable from 'components/AllocationsTable'
import { ADMIN_CLIENTS_PATH } from 'components/Application'
import { useFeatures } from 'providers/FeaturesProvider'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import InvoicePage from 'components/pages/client/Invoice/InvoicePage'
import { useOrbit } from 'providers/OrbitProvider'
import ClientResource from 'resources/ClientResource'
import Suspenseful from 'components/Suspenseful'


const tabs = {
  ALLOCATIONS: 'allocations',
  INVOICES: 'invoices',
  PACING: 'pacing',
  PROGRAMS: 'degree-programs',
  FIELDS: 'fields',
  RULES: 'rules',
  MAIN: 'main',
} as const

function HeaderTabs({ match, clientId }) {
  const selectedTab = match?.params?.tab || tabs.ALLOCATIONS
  const navigate = useNavigate()
  const { setBookends } = useBookends()
  const { isFeatureFlagEnabled } = useFeatures()
  const isInvoiceEnabled = isFeatureFlagEnabled('invoice')

  // clear footer buttons on navigate so they don't remain on pages that don't reset bookend buttons
  useEffect(() => {
    setBookends({
      footer: {
        buttons: []
      }
    })
  }, [match, setBookends])

  return (
    <Tabs
      // Temporarily handle Integrations not have a tab (waiting for input from Alex)
      value={selectedTab === 'ad-campaign-sets' ? tabs.MAIN : selectedTab}
      onChange={(_event, newValue) => {
        return navigate(`/clients/${clientId}/${newValue}`)}
      }
      sx={{ marginTop: '9px' }}
    >
      <HeaderTab
        icon={<PieChartIcon />}
        value={tabs.ALLOCATIONS}
      />
      {isInvoiceEnabled &&
        <HeaderTab
          icon={<RequestPageOutlinedIcon />}
          value={tabs.INVOICES}
        />
      }
      <HeaderTab
        icon={<TrendingUpIcon />}
        value={tabs.PACING}
      />
      {/* onClick handles getting back to the tab root - this is only necessary for tabs that have sub routes */}
      <HeaderTab
        icon={<AccountTreeIcon />}
        value={tabs.PROGRAMS}
        onClick={() => navigate(`/clients/${clientId}/${tabs.PROGRAMS}`)}
      />
      <HeaderTab
        icon={<ForumIcon />}
        value={tabs.FIELDS}
      />
      <HeaderTab
        icon={<RuleIcon />}
        value={tabs.RULES}
      />
      {/* onClick handles getting back to the tab root, this should go away once Integration's true tab/nav is determined */}
      <HeaderTab
        icon={<InfoIcon />}
        value={tabs.MAIN}
        onClick={() => navigate(`/clients/${clientId}/${tabs.MAIN}`)}
      />
    </Tabs>
  )
}

function nameOf(entity, _entityType) {
  if(entity?.attributes) {
    return entity.attributes?.name || '(No name)'
  } else {
    return '...'
  }
}

function fetchClient(store, id) {
  return store.cache.query(q => q.findRecord({type: "client", id: id})) || store.query(q => q.findRecord({type: "client", id: id}))
}

function ClientsPageNavigation({ clientId, children }) {
  const [client, setClient] = useState();
  const { store } = useOrbit()
  const match = useMatch({ path: `/${ADMIN_CLIENTS_PATH}/:clientId/:tab/*`, end: false })

  useEffect(() => {
    Promise.resolve((clientId ? fetchClient(store, clientId) : null)).then(result => setClient(result))
  }, [store, clientId]);

  return (
    <BookendsProvider>
      <MainContainer>
        <Paper
          sx={{
            padding: '18px 24px',
            boxShadow: 0,
          }}
        >
          <Typography variant='h1'>
            {nameOf(client, 'Client')}
          </Typography>

          <HeaderTabs match={match} clientId={clientId} />

          {children}
        </Paper>
      </MainContainer>

      <FooterBar/>
    </BookendsProvider>
  )
}

function AdminClientsPage({clientResource}) {
  // Load the client to the cache to ensure it's available
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const client = useMemo(() => clientResource.get(), [clientResource])
  const { clientId } = useParams()
  const pathPrefix = `/clients/${clientId}`

  const { isFeatureFlagEnabled } = useFeatures()
  const isInvoiceEnabled = isFeatureFlagEnabled('invoice')

  return (
    <ClientsPageNavigation clientId={clientId}>
      <Routes>
        <Route index element={<AllocationsTable hasTabNavigation={true} />}/>
        <Route path={tabs.ALLOCATIONS} element={<AllocationsTable hasTabNavigation={true} />} />
        <Route path={'clients'} element={<Navigate to={`${pathPrefix}/${tabs.ALLOCATIONS}`} />} />
        {isInvoiceEnabled &&
          <Route path={tabs.INVOICES} element={<InvoicePage clientId={clientId} />} />
        }
        <Route path={tabs.PACING} element={<PacingView />} />
        <Route path={tabs.PROGRAMS}>
          <Route index element={<DegreePrograms />}/>
          <Route path="new" element={<DegreeProgramEdit/>}/>
          <Route path=":degreeProgramId" >
            <Route index element={<DegreeProgramEdit/>}/>
            <Route path="exclusion-rules" element={<ExclusionRulesEdit/>}/>
          </Route>
        </Route>
        <Route path={tabs.FIELDS} element={<FieldsEdit />}/>
        <Route path={tabs.RULES} element={<ExclusionRulesEdit/>}/>
        <Route path={tabs.MAIN} element={<ClientEdit />}/>
        <Route path={"ad-campaign-sets"} element={<AdCampaignSetTableLoader />}/>
        <Route path="*" element={<Navigate to={`${pathPrefix}/dashboard`}/>}/>
      </Routes>
    </ClientsPageNavigation>
  )
}

export default function AdminClientsPageLoader() {
  const { remote } = useOrbit()
  const { clientId } = useParams()
  const clientResource = useMemo(() => new ClientResource({ remote, id: clientId }), [remote, clientId])

  return (
    <Suspenseful
      component={AdminClientsPage}
      clientResource={clientResource}
      errorMessage="Error loading clients page"
    />
  )
}
