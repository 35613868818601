import { userEvent, within } from "@storybook/test";
import FreeTextMultiSelect from "./FreeTextMultiSelect";
import { useArgs } from "@storybook/preview-api";
import { Formik, Form } from "formik";

export default {
  title: "UI/FreeTextMultiSelect",
  component: FreeTextMultiSelect,
};

const Template = (args) => {
  const [, updateArgs] = useArgs();
  const setValue = (value) => updateArgs({ ...args, value: value });
  return (
    <Formik
      initialValues={[]}
      onSubmit={() => {
        /*no op*/
      }}
    >
      <Form autoComplete="off">
        <FreeTextMultiSelect setValue={setValue} {...args} />
      </Form>
    </Formik>
  );
};

export const Default = Template.bind({});
Default.args = {
  id: "FreeTextMultiSelect",
  label: "FreeText",
  placeholder: "Placeholder",
  value: [],
  error: false,
};

export const WithOptions = Template.bind({});
WithOptions.args = {
  ...Default.args,
  options: ["options", "available"],
};

WithOptions.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("FreeText"));
};

export const WithValues = Template.bind({});
WithValues.args = {
  id: "FreeTextMultiSelect",
  label: "FreeText",
  value: ["some", "example", "values"],
};

export const Error = Template.bind({});
Error.args = {
  ...Default.args,
  error: true,
};
