import { Formik, Form as FormikForm } from "formik"
import { MockedProvider } from '@apollo/client/testing'
import CampaignSelectionRules from './CampaignSelectionRules'
import SampleLandingPage from 'test_support/data/SampleLandingPage'
import { userEvent, screen, within } from "@storybook/test"
import SampleClientCampaignRule from 'test_support/data/SampleClientCampaignRule'
import { cloneDeep, remove } from 'lodash-es'
import { GET_FILTERED_LEADS_CLIENTS_QUERY, GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY } from "components/pages/LandingPages/data/queries"
import SampleFilteredLeadsClients from "test_support/data/SampleFilteredLeadsClients"
import LandingPageProvider from "../../LandingPageProvider"


export default {
  title: "Composites/CampaignSelectionRules",
  component: CampaignSelectionRules,
};

const Template = (args, { parameters }) => {
  const landingPage = {
    ...SampleLandingPage,
    clientCampaignRules: parameters.updatedClientCampaignRules
  }
  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <LandingPageProvider initialState={landingPage} isRefetching={false}>
        <Formik
          initialValues={landingPage}
          onSubmit={() => {
            /*no op*/
          }}
        >
          <FormikForm autoComplete="off" onChange={() => {}}>
            <CampaignSelectionRules {...args} />
          </FormikForm>
        </Formik>
      </LandingPageProvider>
    </MockedProvider>
  );
};

export const Default = Template.bind({});
Default.args = {};
Default.parameters = {
  orbit: {
    user: {
      permitGlobalAdmin: true,
      accessibleVendorIds: ['1'],
    },
    records: [
      {
        type: "vendors",
        id: "1",
        attributes: {
          id: 1,
          name: "Cygnus Education",
        },
      },
    ],
  },
  updatedClientCampaignRules: [
    ...SampleLandingPage.clientCampaignRules,
    SampleClientCampaignRule
  ],
  mocks: [
    {
      request: {
        query: GET_FILTERED_LEADS_CLIENTS_QUERY,
        variables: {},
      },
      result: {
        data: {
          filteredLeadsClients: SampleFilteredLeadsClients,
        },
      },
    },
    {
      request: {
        query: GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY,
        variables: { programGroupIds: ["240"], vendorId: "1" },
      },
      result: {
        data: {
          clientCampaigns: [
            {
              __typename: "ClientCampaign",
              id: "a9ba99e5",
              name: "YDD Filtered Campaign",
              campaignType: "shared",
              status: "active",
              programGroup: {
                __typename: "ProgramGroup",
                id: "240",
                description: "YDD Filtered ProgramGroup",
              },
            },
          ],
        },
      },
    },
  ],
};

export const MissingDefaultCampaign = Template.bind({})
const noDefaultParams = cloneDeep(SampleLandingPage.clientCampaignRules)
remove(noDefaultParams[0].clientMapping, { clientId: "29"})
MissingDefaultCampaign.args = {}
MissingDefaultCampaign.parameters = {
  ...Default.parameters,
  updatedClientCampaignRules: noDefaultParams,
  mocks: [...Default.parameters.mocks]
}

export const AddCampaignRule = Template.bind({})
AddCampaignRule.args = {}
AddCampaignRule.parameters = {
  ...Default.parameters
}
AddCampaignRule.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement)

  await userEvent.click(canvas.getByLabelText("Add Campaign Rule"));
};

export const GreaterOrLessOperator = Template.bind({});
GreaterOrLessOperator.args = {};
GreaterOrLessOperator.parameters = {
  ...Default.parameters,
};
GreaterOrLessOperator.play = async ({ canvasElement }) => {
  await userEvent.click(within(canvasElement).getByLabelText("Operator"));
  const listbox = await screen.findByRole("listbox");
  await userEvent.click(within(listbox).getByText("is less than"));
};

export const SourceConditionInput = Template.bind({});
SourceConditionInput.args = {};
SourceConditionInput.parameters = {
  ...Default.parameters,
};
SourceConditionInput.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("Attribute *"));
  const listbox = await screen.findByRole("listbox");
  await userEvent.click(within(listbox).getByText("Source"));

  await userEvent.type(canvas.getByLabelText("Value *"), "sourcetastic");
  await userEvent.keyboard("{Enter}");
};

export const HourConditionInput = Template.bind({});
HourConditionInput.args = {};
HourConditionInput.parameters = {
  ...Default.parameters,
};
HourConditionInput.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("Attribute *"));
  const listbox = await screen.findByRole("listbox");
  await userEvent.click(
    within(listbox).getByText("Hour (in Vendor's time zone)"),
  );

  await userEvent.click(within(canvasElement).getByLabelText("Operator"));
  const operatorListbox = await screen.findByRole("listbox");
  await userEvent.click(within(operatorListbox).getByText("is less than"));

  await userEvent.click(within(canvasElement).getByLabelText("Value *"));
  const valueListbox = await screen.findByRole("listbox");
  await userEvent.click(within(valueListbox).getByText("6:00PM"));
};

export const AddRuleCondition = Template.bind({})
AddRuleCondition.args = {}
AddRuleCondition.parameters = {
  ...Default.parameters
}
AddRuleCondition.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement)

  await userEvent.click(canvas.getByLabelText("Add Condition"));
};

export const RemovingRule = Template.bind({})
RemovingRule.args = {}
RemovingRule.parameters = {
  ...Default.parameters
}
RemovingRule.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement)

  await userEvent.click(canvas.getByLabelText("Delete Rule"));
};
