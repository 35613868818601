export default [
  {
    "client": {
      "id": "29",
      "name": "Grand Canyon University",
      "internalName": "GCU Undergrad",
      "fields": [
        {
          "id": "10",
          "name": "FirstName",
          "fieldType": "first_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "11",
          "name": "LastName",
          "fieldType": "last_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "12",
          "name": "StreetAddress",
          "fieldType": "street_address",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "13",
          "name": "City",
          "fieldType": "city",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "14",
          "name": "State",
          "fieldType": "state",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "15",
          "name": "ZIP_Code",
          "fieldType": "zip_code",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "16",
          "name": "Email",
          "fieldType": "email",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "17",
          "name": "Phone",
          "fieldType": "phone",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "18",
          "name": "StartClasses",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Immediately",
              "value": "Immediately",
              "__typename": "FieldOptions"
            },
            {
              "text": "1-3 Months",
              "value": "1-3 Months",
              "__typename": "FieldOptions"
            },
            {
              "text": "3-6 Months",
              "value": "3-6 Months",
              "__typename": "FieldOptions"
            },
            {
              "text": "More Than 6 Months",
              "value": "More Than 6 Months",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "23",
          "name": "Citizen",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Yes",
              "value": "1",
              "__typename": "FieldOptions"
            },
            {
              "text": "No",
              "value": "0",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "22",
          "name": "GraduationYear",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "2022",
              "value": "2022",
              "__typename": "FieldOptions"
            },
            {
              "text": "2021",
              "value": "2021",
              "__typename": "FieldOptions"
            },
            {
              "text": "2020",
              "value": "2020",
              "__typename": "FieldOptions"
            },
            {
              "text": "2019",
              "value": "2019",
              "__typename": "FieldOptions"
            },
            {
              "text": "2018",
              "value": "2018",
              "__typename": "FieldOptions"
            },
            {
              "text": "2017",
              "value": "2017",
              "__typename": "FieldOptions"
            },
            {
              "text": "2016",
              "value": "2016",
              "__typename": "FieldOptions"
            },
            {
              "text": "2015",
              "value": "2015",
              "__typename": "FieldOptions"
            },
            {
              "text": "2014",
              "value": "2014",
              "__typename": "FieldOptions"
            },
            {
              "text": "2013",
              "value": "2013",
              "__typename": "FieldOptions"
            },
            {
              "text": "2012",
              "value": "2012",
              "__typename": "FieldOptions"
            },
            {
              "text": "2011",
              "value": "2011",
              "__typename": "FieldOptions"
            },
            {
              "text": "2010",
              "value": "2010",
              "__typename": "FieldOptions"
            },
            {
              "text": "2009",
              "value": "2009",
              "__typename": "FieldOptions"
            },
            {
              "text": "2008",
              "value": "2008",
              "__typename": "FieldOptions"
            },
            {
              "text": "2007",
              "value": "2007",
              "__typename": "FieldOptions"
            },
            {
              "text": "2006",
              "value": "2006",
              "__typename": "FieldOptions"
            },
            {
              "text": "2005",
              "value": "2005",
              "__typename": "FieldOptions"
            },
            {
              "text": "2004",
              "value": "2004",
              "__typename": "FieldOptions"
            },
            {
              "text": "2003",
              "value": "2003",
              "__typename": "FieldOptions"
            },
            {
              "text": "2002",
              "value": "2002",
              "__typename": "FieldOptions"
            },
            {
              "text": "2001",
              "value": "2001",
              "__typename": "FieldOptions"
            },
            {
              "text": "2000",
              "value": "2000",
              "__typename": "FieldOptions"
            },
            {
              "text": "1999",
              "value": "1999",
              "__typename": "FieldOptions"
            },
            {
              "text": "1998",
              "value": "1998",
              "__typename": "FieldOptions"
            },
            {
              "text": "1997",
              "value": "1997",
              "__typename": "FieldOptions"
            },
            {
              "text": "1996",
              "value": "1996",
              "__typename": "FieldOptions"
            },
            {
              "text": "1995",
              "value": "1995",
              "__typename": "FieldOptions"
            },
            {
              "text": "1994",
              "value": "1994",
              "__typename": "FieldOptions"
            },
            {
              "text": "1993",
              "value": "1993",
              "__typename": "FieldOptions"
            },
            {
              "text": "1992",
              "value": "1992",
              "__typename": "FieldOptions"
            },
            {
              "text": "1991",
              "value": "1991",
              "__typename": "FieldOptions"
            },
            {
              "text": "1990",
              "value": "1990",
              "__typename": "FieldOptions"
            },
            {
              "text": "1989",
              "value": "1989",
              "__typename": "FieldOptions"
            },
            {
              "text": "1988",
              "value": "1988",
              "__typename": "FieldOptions"
            },
            {
              "text": "1987",
              "value": "1987",
              "__typename": "FieldOptions"
            },
            {
              "text": "1986",
              "value": "1986",
              "__typename": "FieldOptions"
            },
            {
              "text": "1985",
              "value": "1985",
              "__typename": "FieldOptions"
            },
            {
              "text": "1984",
              "value": "1984",
              "__typename": "FieldOptions"
            },
            {
              "text": "1983",
              "value": "1983",
              "__typename": "FieldOptions"
            },
            {
              "text": "1982",
              "value": "1982",
              "__typename": "FieldOptions"
            },
            {
              "text": "1981",
              "value": "1981",
              "__typename": "FieldOptions"
            },
            {
              "text": "1980",
              "value": "1980",
              "__typename": "FieldOptions"
            },
            {
              "text": "1979",
              "value": "1979",
              "__typename": "FieldOptions"
            },
            {
              "text": "1978",
              "value": "1978",
              "__typename": "FieldOptions"
            },
            {
              "text": "1977",
              "value": "1977",
              "__typename": "FieldOptions"
            },
            {
              "text": "1976",
              "value": "1976",
              "__typename": "FieldOptions"
            },
            {
              "text": "1975",
              "value": "1975",
              "__typename": "FieldOptions"
            },
            {
              "text": "1974",
              "value": "1974",
              "__typename": "FieldOptions"
            },
            {
              "text": "1973",
              "value": "1973",
              "__typename": "FieldOptions"
            },
            {
              "text": "1972",
              "value": "1972",
              "__typename": "FieldOptions"
            },
            {
              "text": "1971",
              "value": "1971",
              "__typename": "FieldOptions"
            },
            {
              "text": "1970",
              "value": "1970",
              "__typename": "FieldOptions"
            },
            {
              "text": "1969",
              "value": "1969",
              "__typename": "FieldOptions"
            },
            {
              "text": "1968",
              "value": "1968",
              "__typename": "FieldOptions"
            },
            {
              "text": "1967",
              "value": "1967",
              "__typename": "FieldOptions"
            },
            {
              "text": "Other",
              "value": "Other",
              "__typename": "FieldOptions"
            },
            {
              "text": "No HS Diploma Yet",
              "value": "No HS Diploma Yet",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "1842",
          "name": "Education",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Associate's Degree or over 60 Credits",
              "value": "5",
              "__typename": "FieldOptions"
            },
            {
              "text": "B.S. in Nursing",
              "value": "15",
              "__typename": "FieldOptions"
            },
            {
              "text": "Bachelor Degree",
              "value": "7",
              "__typename": "FieldOptions"
            },
            {
              "text": "Bachelor w/ Cert. & Experience",
              "value": "8",
              "__typename": "FieldOptions"
            },
            {
              "text": "High School Diploma or GED",
              "value": "2",
              "__typename": "FieldOptions"
            },
            {
              "text": "Master Degree or Higher",
              "value": "10",
              "__typename": "FieldOptions"
            },
            {
              "text": "Some College",
              "value": "4",
              "__typename": "FieldOptions"
            },
            {
              "text": "Still in High School",
              "value": "1",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "1843",
          "name": "Age",
          "fieldType": "custom_text",
          "options": null,
          "__typename": "Field"
        }
      ],
      "__typename": "Client"
    },
    "__typename": "Contract"
  },
  {
    "client": {
      "id": "184",
      "name": "Florida Tech",
      "internalName": "BISK",
      "fields": [
        {
          "id": "2496",
          "name": "Age",
          "fieldType": "custom_text",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2498",
          "name": "Citizen",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Yes",
              "value": "Yes",
              "__typename": "FieldOptions"
            },
            {
              "text": "No",
              "value": "No",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "2490",
          "name": "City",
          "fieldType": "city",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2495",
          "name": "EducationLevel",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Associates",
              "value": "Associates",
              "__typename": "FieldOptions"
            },
            {
              "text": "BSN (Nursing)",
              "value": "BSN (Nursing)",
              "__typename": "FieldOptions"
            },
            {
              "text": "Bachelors",
              "value": "Bachelors",
              "__typename": "FieldOptions"
            },
            {
              "text": "Currently Enrolled in High School",
              "value": "Currently Enrolled in High School",
              "__typename": "FieldOptions"
            },
            {
              "text": "Doctoral or Equivalent",
              "value": "Doctoral or Equivalent",
              "__typename": "FieldOptions"
            },
            {
              "text": "High School",
              "value": "High School",
              "__typename": "FieldOptions"
            },
            {
              "text": "MSN (Nursing)",
              "value": "MSN (Nursing)",
              "__typename": "FieldOptions"
            },
            {
              "text": "Masters",
              "value": "Masters",
              "__typename": "FieldOptions"
            },
            {
              "text": "RN (ADN)",
              "value": "RN (ADN)",
              "__typename": "FieldOptions"
            },
            {
              "text": "RN (Diploma Nurse)",
              "value": "RN (Diploma Nurse)",
              "__typename": "FieldOptions"
            },
            {
              "text": "Some College",
              "value": "Some College",
              "__typename": "FieldOptions"
            },
            {
              "text": "Some Graduate",
              "value": "Some Graduate",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "2493",
          "name": "Email",
          "fieldType": "email",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2487",
          "name": "FirstName",
          "fieldType": "first_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2488",
          "name": "LastName",
          "fieldType": "last_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2497",
          "name": "MilitaryStatus",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "None",
              "value": "None",
              "__typename": "FieldOptions"
            },
            {
              "text": "Dependent",
              "value": "Dependent",
              "__typename": "FieldOptions"
            },
            {
              "text": "Servicemember",
              "value": "Servicemember",
              "__typename": "FieldOptions"
            },
            {
              "text": "Spouse of Servicemember",
              "value": "Spouse of Servicemember",
              "__typename": "FieldOptions"
            },
            {
              "text": "Veteran",
              "value": "Veteran",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "2494",
          "name": "Phone",
          "fieldType": "phone",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2499",
          "name": "StartClasses",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Immediately",
              "value": "Immediately",
              "__typename": "FieldOptions"
            },
            {
              "text": "1-3 Months",
              "value": "1-3 Months",
              "__typename": "FieldOptions"
            },
            {
              "text": "3-6 Months",
              "value": "3-6 Months",
              "__typename": "FieldOptions"
            },
            {
              "text": "More Than 6 Months",
              "value": "More Than 6 Months",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "2491",
          "name": "State",
          "fieldType": "state",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2489",
          "name": "StreetAddress",
          "fieldType": "street_address",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "2492",
          "name": "ZIP_Code",
          "fieldType": "zip_code",
          "options": null,
          "__typename": "Field"
        }
      ],
      "__typename": "Client"
    },
    "fieldType": "email",
    "__typename": "Contract"
  },
  {
    "client": {
      "id": "188",
      "name": "Southern Careers Institute",
      "internalName": null,
      "fields": [
        {
          "id": "114",
          "name": "FirstName",
          "fieldType": "first_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "115",
          "name": "LastName",
          "fieldType": "last_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "116",
          "name": "StreetAddress",
          "fieldType": "street_address",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "117",
          "name": "City",
          "fieldType": "city",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "118",
          "name": "State",
          "fieldType": "state",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "119",
          "name": "ZIP_Code",
          "fieldType": "zip_code",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "120",
          "name": "Email",
          "fieldType": "email",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "121",
          "name": "Phone",
          "fieldType": "phone",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "122",
          "name": "HighestEdu",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Current High School Student",
              "value": "Current High School Student",
              "__typename": "FieldOptions"
            },
            {
              "text": "High School Diploma/GED",
              "value": "High School Diploma/GED",
              "__typename": "FieldOptions"
            },
            {
              "text": "Some College",
              "value": "Some College",
              "__typename": "FieldOptions"
            },
            {
              "text": "Associate Degree",
              "value": "Associate Degree",
              "__typename": "FieldOptions"
            },
            {
              "text": "Bachelor Degree",
              "value": "Bachelor Degree",
              "__typename": "FieldOptions"
            },
            {
              "text": "Master Degree",
              "value": "Master Degree",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        },
        {
          "id": "1787",
          "name": "Citizen",
          "fieldType": "custom_select",
          "options": [
            {
              "text": "Yes",
              "value": "Yes",
              "__typename": "FieldOptions"
            },
            {
              "text": "No",
              "value": "No",
              "__typename": "FieldOptions"
            }
          ],
          "__typename": "Field"
        }
      ],
      "__typename": "Client"
    },
    "__typename": "Contract"
  },
  {
    "client": {
      "id": "283",
      "name": "YDD Filtered",
      "internalName": null,
      "fields": [
        {
          "id": "1976",
          "name": "City",
          "fieldType": "city",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1979",
          "name": "Email",
          "fieldType": "email",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1973",
          "name": "FirstName",
          "fieldType": "first_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1974",
          "name": "LastName",
          "fieldType": "last_name",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1980",
          "name": "Phone",
          "fieldType": "phone",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1977",
          "name": "State",
          "fieldType": "state",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1975",
          "name": "StreetAddress",
          "fieldType": "street_address",
          "options": null,
          "__typename": "Field"
        },
        {
          "id": "1978",
          "name": "ZIP_Code",
          "fieldType": "zip_code",
          "options": null,
          "__typename": "Field"
        }
      ],
      "__typename": "Client"
    },
    "__typename": "Contract"
  }
]
