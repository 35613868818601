import { useMemo } from 'react'

import Box from '@mui/material/Box'
import MTable from '@mui/material/Table'

import useStyles from './styles'
import TableBody from './TableBody'
import TableHead from './TableHead'
import TableContentLoading from './TableContentLoading'
import PaginationControls from './PaginationControls'

function extractOptions(options) {
  return {
    hover: options.hover || !!options.hoverClass,
    hoverClass: options.hover && (options.hoverClass || 'hover'),
    hasRowActions: options.hasRowActions,
    ...options
  }
}

export default function TableContent({title, rowActions = [], options}) {
  const tableOptions = useMemo(() => extractOptions({...options, hasRowActions: !!rowActions.length}), [options, rowActions.length])
  const classes = useStyles()

  return (
    <Box sx={{ overflow: 'auto', minHeight: 0 }}>
      <MTable className={classes.table} size={tableOptions.padding === 'dense' ? 'small' : 'medium'} aria-label={title}>
        <TableHead {...tableOptions} />
        <TableBody {...tableOptions} rowActions={rowActions} />
        <TableContentLoading/>
        {tableOptions.paginated && (
          <PaginationControls/>
        )}
      </MTable>
    </Box>
  )
}
