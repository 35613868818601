import { useState, useCallback } from 'react'

import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

import AddClientCampaignModal from '../AddClientCampaignModal'

export default function AddClientCampaignButton({clientId, contract, onSave}) {
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleSave = useCallback(() => {
    setOpen(false)
    onSave()
  }, [setOpen, onSave])

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '-28px'}}>
        <Fab color="primary" aria-label="add" onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
      </div>
      {open && (
        <AddClientCampaignModal
          clientId={clientId}
          contract={contract}
          open={open}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </>
  )
}
