import React, { ReactElement } from 'react'

// Based on https://gist.github.com/sstackus/f351ca6b3c0fed39d3f21e96a02a480c

export default function Compose({ items, children }) {
  return items.reduceRight(
    (acc: ReactElement, [Component, props]) =>
      React.createElement(Component, props, acc),
    children
  );
}
