import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { getIn, useFormikContext } from "components/Formik/forms";
import StyledCard, { ErrorWrapper } from "components/UI/StyledCard";
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle";
import AddIcon from '@mui/icons-material/Add'
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { buildDefaultDegreeProgramRule, getDegreeProgramNamesFromLeadTargets } from "components/pages/LandingPages/data/helpers";
import useId from "@mui/material/utils/useId";
import Box from "@mui/material/Box";
import { CustomRuleSection } from "./CampaignSelectionRules";


export function ProgramSelector({rule, originalIndex}) {
  const { values, setFieldValue, errors } = useFormikContext()
  const textFieldId = useId()
  const programNames = Object.entries(getDegreeProgramNamesFromLeadTargets(values.leadTargets))
  const hasError = !!getIn(errors, `degreeProgramRules[${originalIndex}]`)

  const handleProgramSelect = (event) => {
    setFieldValue(`degreeProgramRules[${originalIndex}]`, { ...rule, degreeProgramId: event.target.value })
  }

  return (
    <Stack direction='row' spacing={2}>
      <TextField
        select
        value={rule.degreeProgramId || ''}
        onChange={handleProgramSelect}
        label='Program'
        fullWidth
        id={`program_selector_${textFieldId}`}
        required
        error={hasError}
      >
        {programNames.map(([programId, programName]) => (
          <MenuItem value={programId} key={`program_selector_${textFieldId}_item_${programId}`}>{programName}</MenuItem>
        ))}
      </TextField>
      <Box sx={{ width: '100%' }} />
    </Stack>
  )
}

function DefaultDegreeProgramSelect() {
  const { values } = useFormikContext()
  const defaultRule = values.degreeProgramRules.find(rule => !!rule.default)
  const defaultRuleIndex = defaultRule ? values.degreeProgramRules.findIndex(rule => !!rule.default) : values.degreeProgramRules.length

  return (
    <ProgramSelector rule={defaultRule || buildDefaultDegreeProgramRule()} originalIndex={defaultRuleIndex} />
  )
}

export default function DegreeProgramRules({ handleAddDegreeProgramRule }) {

  return (
    <StyledCard
      title='Program Selection Rules'
      note='Leads sent to default program or reassigned based on answers to questions. Rules applied in arranged order.'
      controls={[
        {icon: <AddIcon/>, label: 'Add Program Selection Rule', onClick: handleAddDegreeProgramRule}
      ]}
      isSubsection
      hasStickyHeader
      role="group"
    >
      <ErrorWrapper>
        <Stack direction='column' spacing={2}>
          <SubsectionTitle
            title="Default Program Selection"
          />
          <DefaultDegreeProgramSelect />
        </Stack>
      </ErrorWrapper>

      <Divider light sx={{ mx: 4 }}/>

      <CustomRuleSection name='degreeProgramRules' description='program'/>
    </StyledCard>
  )
}
