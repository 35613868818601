import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import { action } from "@storybook/addon-actions";
import SubsectionTitle from "./SubsectionTitle";

export default {
  title: "UI/SubsectionTitle",
  component: SubsectionTitle,
};

const Template = (args) => <SubsectionTitle {...args} />;

export const Default = Template.bind({});
Default.args = {
  // The title prop is required
  title: "Section Title",
  // Notes are optional and will only show if present
  note: "Note that describes the section in more detail.",
  // Controls are optional and will only show if present
  controls: [
    {
      icon: <SchoolIcon />,
      onClick: action("Click handler"),
      label: "School Button",
    },
  ],
};

export const TitleOnly = Template.bind({});
TitleOnly.args = {
  title: "Subsection Title",
};

export const TitleAndNote = Template.bind({});
TitleAndNote.args = {
  title: "Question 2 Answer Matching",
  note: "For each displayed answer, select all matching client answers to link together.",
};

export const TitleAndControls = Template.bind({});
TitleAndControls.args = {
  title: "Campaign Rule 1",
  controls: [
    {
      icon: <AddIcon />,
      onClick: action("Click handler"),
      label: "Add Button",
    },
    {
      icon: <CloseIcon />,
      onClick: action("Click handler"),
      label: "Close Button",
    },
  ],
};

export const TitleControlsAndDragHandle = Template.bind({});
TitleControlsAndDragHandle.args = {
  title: "Campaign Rule 1",
  controls: [
    {
      icon: <CloseIcon />,
      onClick: action("Click handler"),
      label: "Close Button",
    },
  ],
  dragHandleProps: {},
};
