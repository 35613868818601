import type { Meta, StoryObj } from '@storybook/react'

import Chip from "@mui/material/Chip";

const Template = ({ fontWeight, ...args }) => {
  return <Chip sx={{ fontWeight }} {...args} />;
};

const meta : Meta<typeof Template> = {
  title: "UI/Label",
  component: Template,
  parameters: {
    zeplinLink: "https://zpl.io/09nv1No",
  },
  argTypes: {
    size: {
      options: ["small", "medium"],
      control: { type: "inline-radio" },
    },
    variant: {
      options: ["filled", "outlined"],
      control: { type: "inline-radio" },
    },
    color: {
      options: ["default", "warning", "error", "success"],
      control: { type: "select" },
    },
    fontWeight: {
    },
  },
  args: {
    size: "medium",
    variant: "filled",
    color: "default",
    fontWeight: 400,
  },
};

type Story = StoryObj<typeof Template>;

export default meta

export const Default : Story = {
  args: {
    label: "Default",
  },
}

export const Small : Story = {
  args: {
    ...Default.args,
    size: "small",
  },
}

export const Error : Story = {
  args: {
    ...Default.args,
    color: "error",
    label: "Error",
    fontWeight: 600,
  },
}

export const Warning : Story = {
  args: {
    ...Default.args,
    color: "warning",
    label: "Warning",
    fontWeight: 600,
  },
}

export const Success : Story = {
  args: {
    ...Default.args,
    color: "success",
    label: "Success",
    fontWeight: 600,
  },
}
