import React, { useContext, useEffect, useState } from 'react'
import { InitializedRecord } from '@orbit/records'
import { useCurrentUser } from './CurrentUserProvider';
import { getRemoteId } from './DataModel';

const ScopeContext = React.createContext<{
  scope?: null | InitializedRecord,
  setScope: React.Dispatch<React.SetStateAction<null>>,
  lastClientScope: null | InitializedRecord,
  lastContractScope: null | InitializedRecord,
  lastVendorScope: null | InitializedRecord,
}>({
  scope: null,
  setScope: () => {return},
  lastClientScope: null,
  lastContractScope: null,
  lastVendorScope: null,
});


export default function ScopeProvider({ children }) {
  const { accessibleClients, accessibleVendorContracts, accessibleVendors } = useCurrentUser()
  const [scope, setScope] = useState(null)
  const [lastClientScope, setLastClientScope] = useState(accessibleClients[0])
  const [lastContractScope, setLastContractScope] = useState(accessibleVendorContracts[0])
  const [lastVendorScope, setLastVendorScope] = useState(accessibleVendors[0])

  useEffect(() => {
    if(scope?.type === 'client') {
      setLastClientScope(scope)
    }
    else if(scope?.type === 'contract') {
      // ScopeSelector handles pseudo contracts (for easier sorting)
      // Due to this, here we should set scope to the actual contract record
      const contract = accessibleVendorContracts.find(c => getRemoteId(c) === scope.id) as InitializedRecord
      setLastContractScope(contract)
    }
    else if(scope?.type === 'vendor') {
      setLastVendorScope(scope)
    }
  }, [accessibleVendorContracts, scope])

  return (
    <ScopeContext.Provider value={{ scope, setScope, lastClientScope, lastContractScope, lastVendorScope }}>
      {children}
    </ScopeContext.Provider>
  )
}

export function useScope() {
  return useContext(ScopeContext)
}
