import { styled, Theme, CSSObject } from '@mui/material/styles';
import { MUIStyledCommonProps } from '@mui/system'
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

const openedMixin = (theme: Theme, openWidth: number): CSSObject => ({
  width: openWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, closedWidth: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedWidth,
});

interface CustomDrawerProps {
  openWidth: number;
  closedWidth: number;
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'openWidth' && prop !== 'closedWidth' })<CustomDrawerProps & DrawerProps & MUIStyledCommonProps<Theme> & { theme?: Theme }>(
  ({ theme, open, openWidth, closedWidth }) => ({
    width: openWidth,
    flexShrink: 0,
    ...(open && {
      ...openedMixin(theme, openWidth),
      '& .MuiDrawer-paper': openedMixin(theme, openWidth),
    }),
    ...(!open && {
      ...closedMixin(theme, closedWidth),
      '& .MuiDrawer-paper': closedMixin(theme, closedWidth),
    }),
  }),
);

export default Drawer
