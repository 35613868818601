import MenuItem from "@mui/material/MenuItem";
import { useCallback, useState } from "react";
import LabelDropdown from "./LabelDropdown";

export default {
  title: "UI/LabelDropdown",
  component: LabelDropdown,
  parameters: {
    zeplinLink: "https://zpl.io/9qA963w",
  },
};

const Template = (...args) => {
  const [choice, setChoice] = useState("first");
  const handleChange = useCallback((event) => {
    console.log("changed");
    setChoice(event.target.value);
  }, []);

  return (
    <LabelDropdown
      onChange={handleChange}
      value={choice}
      sx={{ width: 125 }}
      {...args}
    >
      <MenuItem value={"first"}>First Choice</MenuItem>
      <MenuItem value={"second"}>Second</MenuItem>
      <MenuItem value={"third"}>Third Choice</MenuItem>
    </LabelDropdown>
  );
};

export const Default = Template.bind({});
Default.args = {};
