import { http } from "msw";
import Button from "@mui/material/Button";
import { userEvent, within } from "@storybook/test";
import { CreativePreviewDialog } from "./CreativePreviewDialog";
import KillerRabbit from "./killer-rabbit.jpg";
import HostnameProvider from "lib/HostnameProvider";
import { useState } from "react";

export default {
  title: "UI/CreativePreviewDialog",
  component: CreativePreviewDialog,
  decorators: [
    (Story) => (
      <div style={{ height: "900px", width: "1200px" }}>
        <Story />
      </div>
    ),
  ],
  parameters: {
    zeplinLink: "https://zpl.io/W4wPp4x",
    layout: "fullscreen",
  },
};

const Template = (args) => {
  const [showCreativePreviewDialog, setShowCreativePreviewDialog] =
    useState(false);

  return (
    <>
      <Button onClick={() => setShowCreativePreviewDialog(true)}>Modal</Button>

      <HostnameProvider
        portalHostname={"portalHostname"}
        complyedHostname={"test.complyed.com"}
      >
        {showCreativePreviewDialog && (
          <CreativePreviewDialog
            cid={args.cid}
            creativeType={args.creativeType}
            onCancel={() => setShowCreativePreviewDialog(false)}
            creativeUrlPath={args.creativeUrlPath}
          />
        )}
      </HostnameProvider>
    </>
  );
};

export const Default = Template.bind({});
Default.args = {
  cid: "1234",
  creativeType: "Image Ad",
  creativeUrlPath: "/creatives/1234.json",
};
Default.parameters = {
  msw: {
    handlers: [
      http.get(
        "https://test.complyed.com/creatives/1234.json",
        async (_request, _params, _cookies) => {
          const image = await fetch(KillerRabbit).then((res) =>
            res.arrayBuffer(),
          );
          return new Response(image, {
            headers: {
              "Content-Length": image.byteLength.toString(),
              "Content-Type": "image/png",
            },
          });
        },
      ),
    ],
  },
};
Default.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);
  await userEvent.click(canvas.getByRole("button"));
};
