import { useCallback, useMemo } from "react";
import Dropdown from "./UI/Dropdown";
import { useCurrentUser } from "lib/CurrentUserProvider";
import { getIn } from "./Formik/forms";
import { find, groupBy, orderBy, sortBy } from "lodash-es";
import { useLocation, useNavigate } from "react-router-dom";
import { singularize } from "lib/utils";
import { useScope } from "lib/ScopeProvider";


const ONLY_GLOBAL_PATHS = ['vendors', 'landing-pages', 'performance', 'leads', 'users', 'teams', 'organizations']

type Heading = { type: 'heading'; id: string };
type Contract = { id: string; type: 'contract'; clientId: string; clientName: string; vendorId: string; vendorName: string };

const normalizeString = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');

function buildCollection(clients, contracts) {
  const sortedClients = sortBy(clients, (client) => normalizeString(client.attributes.name))
  const sortedContracts = orderBy(
    contracts,
    [
      (contract) => normalizeString(contract.vendorName),
      (contract) => normalizeString(contract.clientName)
    ],
    ['asc', 'asc']
  )
  const groupedContracts = groupBy(sortedContracts, 'vendorName')
  const contractsWithVendorHeadings = Object.keys(groupedContracts).reduce((acc: (Heading | Contract)[], vendorName) => {
    !!groupedContracts[vendorName].length && acc.push({ type: 'heading', id: vendorName })
    acc.push(...groupedContracts[vendorName])
    return acc
}, [] as (Heading | Contract)[])

  return [
    {type: 'global', id: 'Global'},
    !!sortedClients.length && {type: 'heading', id: 'Clients'},
    ...sortedClients,
    ...contractsWithVendorHeadings
  ].filter(Boolean)
}

function clientOrContractMatcher(path) {
  const matches = path.match(/^\/(clients|contracts)\/(\d+)/)
  if(!matches) {
    return undefined
  }

  return { type: singularize(matches[1]), id: matches[2] }
}

export default function ScopeSelector() {
  const { accessibleClients, accessibleVendorContractsWithNames } = useCurrentUser()
  const collection = useMemo(() => buildCollection(accessibleClients, accessibleVendorContractsWithNames), [accessibleClients, accessibleVendorContractsWithNames])

  const hasMoreThanOneOption = (accessibleClients.length + accessibleVendorContractsWithNames.length) > 1

  const location = useLocation();
  const currentPath = location.pathname;
  const currentSelection = useMemo(() => find(collection, clientOrContractMatcher(currentPath)) || {type: 'global', id: 'Global'}, [collection, currentPath])
  const isOnlyGlobalPath = ONLY_GLOBAL_PATHS.some(path => currentPath.startsWith(`/${path}`))

  const disabled = !hasMoreThanOneOption || isOnlyGlobalPath

  const navigate = useNavigate();
  const { setScope } = useScope()
  const setScopeAndNavigate = useCallback(scope => {
    setScope(scope)

    const currentPathSuffix = currentPath.replace(/^\/(clients|contracts)\/(\d+)/, '')
    let routeStart = ''

    switch (scope.type) {
      case 'contract':
        routeStart = `/contracts/${scope.id}`
        break
      case 'client':
        routeStart = `/clients/${scope.id}`
        break
      case 'global':
        // routeStart as empty string is correct
        break
      default:
        console.log('Invalid scope type')
    }

    navigate(`${routeStart}${currentPathSuffix}`)
  }, [currentPath, navigate, setScope]);

  const renderItem = useCallback((itemOption) => {
    switch (itemOption.type) {
      case 'contract':
        return itemOption.clientName;
      case 'client':
        return getIn(itemOption, 'attributes.name');
      case 'global':
        return 'Global';
      case 'heading':
        return itemOption.id;
      default:
        return null;
    }
  }, [])

  return (
    <Dropdown
      className='scopeSelector'
      label='Scope'
      collection={collection}
      item={currentSelection}
      setItem={setScopeAndNavigate}
      renderItem={renderItem}
      selectFirstByDefault={false}
      disabled={disabled}
    />
  )
}
