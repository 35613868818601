import { useState, useEffect } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

import { useFormikContext } from '../Formik/forms'
import { getRemoteId } from '../../lib/DataModel'
import { ButtonConfig, useBookends } from 'lib/BookendsProvider'
import { useNotifications } from 'lib/NotificationsProvider'
import { isAllContract } from 'components/ContractSelector'
import { failOnHttpError } from 'lib/utils'


export default function AllocationsTableFooter({permitSendIO, contract, month, onCancel}) {
  const { isSubmitting, dirty, submitForm, validateForm } = useFormikContext()
  const [sendingIO, setSendingIO] = useState(false)
  const { setBookends } = useBookends()
  const { addNotification, clearNotifications } = useNotifications()

  useEffect(() => {
    const buildClientButtons : () => ButtonConfig[] = () => {
      const monthText = month.format('MMMM YYYY')

      const handleSendOrder = () => {
        setSendingIO(true)
        clearNotifications()

        const url = `/api/v1/contracts/${getRemoteId(contract)}/insertion-orders`
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({ io: { month: month.month()+1, year: month.year() } }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }

        fetch(url, requestOptions)
          .then(failOnHttpError)
          .then(_result => {
            setSendingIO(false)
            addNotification({variant: 'notice', message: "Successfully sent IO."})
          })
          .catch(error => {
            setSendingIO(false)
            console.log(error)
            addNotification({variant: 'alert', message: "Error sending IO."});
          })
      }

      return [
        {
          type: 'primary',
          label: `Send ${monthText} IO`,
          onClick: handleSendOrder,
          disabled: sendingIO || dirty || isSubmitting || isAllContract(contract),
          sx: {
            width: 'max-content'
          },
          buttonProps: {
            startIcon: sendingIO ? <CircularProgress size={20}/> : null,
          },
        },
        {
          type: 'menu',
          disabled: !dirty || isSubmitting || sendingIO,
          label: isSubmitting ? 'Please wait...' : 'Save',
          items: [
            { text: 'Save', onClick: () => submitForm() },
            { text: 'Save & Send IO', disabled: isAllContract(contract), onClick: () => {
              // Running the validation first is to work around a Formik bug
              // https://github.com/jaredpalmer/formik/issues/1580
              // If Formik gets fixed to correctly reject submitForm this can change
              validateForm().then((errors) => {
                if(Object.entries(errors).length === 0) {
                  submitForm().then(() => handleSendOrder())
                }
              })
            }}
          ],
        },
      ]
    }

    setBookends({
      footer: {
        buttons: [
          {
            type: 'secondary',
            label: 'Cancel',
            disabled: !dirty || isSubmitting,
            onClick: onCancel,
          },
          ...(permitSendIO ? buildClientButtons() : [
            {
              type: 'primary',
              label: 'Save',
              disabled: !dirty || isSubmitting,
              onClick: submitForm,
            } as const,
          ]),
        ]
      }
    })
  }, [addNotification, clearNotifications, contract, dirty, permitSendIO, isSubmitting, month, onCancel, sendingIO, setBookends, submitForm, validateForm])

  return null
}
