import Login from "@mui/icons-material/Login";
import CygIconButton from "./CygIconButton";
import { action } from "@storybook/addon-actions";

export default {
  title: "UI/Button/IconButton",
  component: CygIconButton,
  parameters: {
    zeplinLink: "https://zpl.io/z8Bq4Ll",
  },
};

const Template = (args) => <CygIconButton {...args}></CygIconButton>;

export const IconButtonDefault = Template.bind({});
IconButtonDefault.args = {
  disabled: false,
  text: "View Compliance Report",
  icon: <Login />,
  onClick: action("onClick handler"),
};

export const IconButtonDisabled = Template.bind({});
IconButtonDisabled.args = {
  ...IconButtonDefault.args,
  disabled: true,
};

export const IconButtonDashed = Template.bind({});
IconButtonDashed.args = {
  ...IconButtonDefault.args,
  dashedOutline: true,
};

export const IconButtonDashedDisabled = Template.bind({});
IconButtonDashedDisabled.args = {
  ...IconButtonDefault.args,
  dashedOutline: true,
  disabled: true,
};
