import { useCallback, useEffect, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import moment from 'moment-timezone'
import COLORS from 'lib/colors'

export default function MonthSelector({month = moment(), setMonth, maxMonth = null}) {
  const nextMonthDisabled = maxMonth && month.startOf('month').isSame(maxMonth, 'day')

  useEffect(() => {
    if(maxMonth && month.startOf('month').isAfter(maxMonth, 'day')) {
      setMonth(maxMonth)
    }
  }, [maxMonth, month, setMonth])

  const handlePrevious = useCallback(() => {
    setMonth(month.clone().subtract(1, 'month'))
  }, [month, setMonth])

  const handleNext = useCallback(() => {
    setMonth(month.clone().add(1, 'month'))
  }, [month, setMonth])

  const monthText = useMemo(() => (
    month.format('MMMM YYYY')
  ), [month])

  return (
    <Box
      sx={{
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        border: 'solid 1px',
        borderColor: 'text.secondary',
        borderRadius: '8px',
        width: '100%',
        minWidth: '251px',
        maxWidth: '251px',
        padding: '6px 12px',
        maxHeight: '50px',
        color: 'rgba(0, 0, 0, 0.86)',
        fontSize: '18px',
        fontFamily: 'default'
      }}
    >
      <div>
        <IconButton
          aria-label="Previous month"
          size="small"
          onClick={handlePrevious}
          sx={{ color: COLORS.charcoal }}
        >
          <ArrowBackIcon/>
        </IconButton>
      </div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        {monthText}
      </Box>
      <div>
        <IconButton
          aria-label="Next month"
          size="small"
          onClick={handleNext}
          disabled={nextMonthDisabled}
          sx={{ color: nextMonthDisabled ? COLORS.veryLightGray : COLORS.charcoal }}
        >
          <ArrowForwardIcon/>
        </IconButton>
      </div>
    </Box>
  )
}
