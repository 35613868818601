import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export default function TypeAheadDropDown({suggestions, label, onChange, value, error=false, ...args}) {

  return (
    <Autocomplete
      freeSolo
      forcePopupIcon={true}
      options={suggestions}
      clearIcon={null}
      renderInput={(params) => <TextField {...params} label={label} error={error}/>}
      onInputChange={onChange}
      blurOnSelect
      value={value}
      {...args}
    />
  )
}
