import { createContext, useContext, useState, useReducer, useEffect } from 'react'

import { compareValues, rawCell, renderCell } from './render'
import { isPresent, isPromise, toPercent } from '../../lib/utils'
import { omit, orderBy } from 'lodash-es'
import { isNullaryOperator, matchesColumnFilter } from './FilterOptions'


function isValidColumnFilter(filter) {
  if(!filter) {
    return false
  }

  if(isNullaryOperator(filter.operator)) {
    return true
  }

  if(!filter.value) {
    return false
  }

  switch(filter.operator) {
    case 'between':
    case 'not-between':
      return isFinite(parseFloat(filter.value[0])) && isFinite(parseFloat(filter.value[1]))
    default:
      return isPresent(filter.value[0])
  }
}

function sortValue(row, column) {
  if (column.sort) {
    return column.sort(row)
  } else if(typeof(column.lookup) === 'object') {
    return column.lookup[row[column.field]]
  } else {
    return rawCell(row, column)
  }
}

// A row is complete if it has all non-null grouping columns, or if it has a
// non-nullish leadCount. The latter matters because some lead-related grouping
// columns (e.g. degreeProgramId) may be null on actual leads, and this scenario
// is distinct from an incomplete row that consists of only data from the spend
// table and no matching lead aggregates.
function isCompleteRow(row, groupingColumnFieldNames) {
  return !groupingColumnFieldNames.some(field => row[field] === null) || (row.leadCount !== null && row.leadCount !== undefined)
}

// Call the callback function on each row nested under a group. Stops iteration
// and returns true if callback returns true; otherwise returns false.
function isSomeRecursively(group, callback) {
  if(group instanceof Array) {
    return group.some(entry => callback(entry))
  }

  return Object.values(group).some(subgroup =>
    isSomeRecursively(subgroup, callback)
  )
}

// Like isSomeRecursively, but ignores the return value and invokes the
// callback on each row nested under a group. Does not stop iteration
// prematurely.
function applyRecursively(group, callback) {
  isSomeRecursively(group, entry => { callback(entry) })
}

function hasCompleteRow(group, groupingColumnFieldNames) {
  return isSomeRecursively(group, entry => isCompleteRow(entry.row, groupingColumnFieldNames))
}

function hasNonBlankCell(row, visibleColumns) {
  return visibleColumns.some(col => {
    const value = rawCell(row, col)
    return value !== undefined && value !== null && !isNaN(value)
  })
}

function isAllBlank(group, visibleColumns) {
  return !isSomeRecursively(group, entry => hasNonBlankCell(entry.row, visibleColumns))
}

function hideIncompleteRows(group, groupingColumnFieldNames) {
  applyRecursively(group, entry => {
    entry.hideRow = !isCompleteRow(entry.row, groupingColumnFieldNames)
  })
}

function setHideRow(groups, groupingColumnFieldNames, visibleColumns) {
  Object.values(groups).forEach(group => {
    // If there's at least one complete row within the group, then hide incomplete rows
    if(hasCompleteRow(group, groupingColumnFieldNames)) {
      hideIncompleteRows(group, groupingColumnFieldNames)
    }

    // If all visible non-grouping columns are blank for all rows in the group, then hide the entire group
    const visibleNonGroupingColumns = visibleColumns.filter(column => !groupingColumnFieldNames.includes(column.field))
    if(isAllBlank(group, visibleNonGroupingColumns)) {
      applyRecursively(group, entry => { entry.hideRow = true })
    }
  })
}

export class DataManager {
  constructor(columns, tableSettings, setTableSettings, dataOrPromiseOrFn, onChange) {
    this.columns                 = columns
    this.tableSettings           = tableSettings
    this.setTableSettings        = setTableSettings
    this.dataOrPromiseOrFn       = dataOrPromiseOrFn
    this.onChangeCallback        = onChange
    this.data                    = []
    this.totalCount              = 0
    this.loading                 = false
    this.error                   = null
    this.version                 = 0

    this.reload()
  }

  onChange() {
    if(this.onChangeCallback) {
      this.version++
      this.onChangeCallback()
    }
  }

  onChangeColumns() {
    this.onChange()

    // TODO: Not sure if this is is necessary, might be worth verifying with a test if we don't have one
    // If the sort column is no longer in this.columns, try to reset it from this.tableSettings.sortColumn
    if(this.tableSettings.sortColumn && !this.columns.includes(this.tableSettings.sortColumn)) {
      this.setTableSettings({...this.tableSettings, sortColumn: this.tableSettings.sortColumn})
    }

    // If any additional promise-based lookups were added, resolve them.
    // TODO: this should set the loading state on the table, which it currently doesn't do.
    const lookupPromises = this.getLookupPromises()
    if(lookupPromises.length > 0) {
      Promise.all(lookupPromises).then(() => this.onChange())
    }
  }

  getLookupPromises() {
    return this.columns.filter(column => isPromise(column.lookup)).map(column => (
      column.lookup.then(lookup => {
        // TODO: can we avoid modifying columns here (since columns is a passed-in value)?
        column.lookup = lookup
      })
    ))
  }

  reload(forceReload = false) {
    if(this.loading && !forceReload) {
      return
    }

    this.loading = true
    this.error   = null

    this.onChange()

    const dataOrPromiseOrFn = this.dataOrPromiseOrFn
    const isStale = () => (dataOrPromiseOrFn !== this.dataOrPromiseOrFn)
    const dataFn = typeof(this.dataOrPromiseOrFn) === 'function' ? this.dataOrPromiseOrFn : () => this.dataOrPromiseOrFn
    const dataPromise = Promise.resolve(dataFn())
    const dataAndLookupsPromise = Promise.all([dataPromise, ...this.getLookupPromises()])

    dataAndLookupsPromise
      .then(([data, ..._lookups]) => {
        if(isStale()) {
          return
        }
        if(typeof(data) === 'object' && data.data instanceof Array) {
          this.data = data.data
          this.totalCount = data.totalCount ? data.totalCount : this.data.length
        } else if(typeof(data) === 'object' && data instanceof Array) {
          this.data = data
          this.totalCount = data.length
        } else {
          this.data = []
          this.totalCount = 0
        }
        if(this.tableSettings.sortColumn) {
          const column = this.columns.find(column => column.field === this.tableSettings.sortColumn)
          this.data.sort((row1, row2) => this.tableSettings.sortOrder * compareValues(sortValue(row1, column), sortValue(row2, column)))
        }
      })
      .catch(error => {
        if(isStale()) {
          return
        }
        this.data  = []
        this.totalCount = 0
        this.error = error
      })
      .finally(() => {
        if(isStale()) {
          this.reload(true)
          return
        }
        this.loading = false
        this.onChange()
      })
  }

  getVisibleColumns() {
    return this.columns.filter(column => this.tableSettings.visibleColumns.includes(column.field))
  }

  passesColumnFilter(key, row) {
    const column = this.columns.find(c => c.field === key)
    if(!column) {
      return true
    }

    let value = sortValue(row, column)
    if(value === null || value === undefined) {
      value = ''
    }
    const columnFilter = this.tableSettings.columnFilters[key]

    return matchesColumnFilter(columnFilter, value.toString())
  }

  getVisibleData() {
    var visibleData = this.data
    const keys = Object.keys(this.tableSettings.columnFilters)

    keys.forEach( key => {
      visibleData = visibleData.filter(row => this.passesColumnFilter(key, row))
    })

    return visibleData
  }

  getColSpan() {
    return this.getVisibleColumns().length
  }

  setData(dataOrPromiseOrFn) {
    if(dataOrPromiseOrFn !== this.dataOrPromiseOrFn) {
      this.dataOrPromiseOrFn = dataOrPromiseOrFn
      this.reload()
    }
  }

  setColumnFilter(field, filter) {
    if(isValidColumnFilter(filter)) {
      this.setTableSettings({...this.tableSettings, columnFilters: {...this.tableSettings.columnFilters, [field]: filter}})
    } else {
      this.setTableSettings({...this.tableSettings, columnFilters: omit(this.tableSettings.columnFilters, [field])})
    }

    this.onChange()
  }

  sortBy(column) {
    if(this.loading || this.error) {
      return
    }
    let sortOrder = this.tableSettings.sortOrder
    if(this.tableSettings.sortColumn !== column.field) {
      sortOrder = 1
      this.setTableSettings({...this.tableSettings, sortColumn: column.field, sortOrder: 1})
    }
    else {
      sortOrder = -sortOrder
      this.setTableSettings({...this.tableSettings, sortOrder: -this.tableSettings.sortOrder})
    }
    this.data.sort((row1, row2) => sortOrder * compareValues(sortValue(row1, column), sortValue(row2, column)))

    this.onChange()
  }

  group(ungroupedData) {
    // groupingColumns is the groupable prefix of visible columns
    const groupingColumns = this.getVisibleColumns().reduce((list, column, idx) => list.length === idx && column.groupable ? [...list, column] : list, [])

    const groups = groupingColumns.length === 0 ? [] : {}
    ungroupedData.forEach(row => {
      const group = groupingColumns.reduce((group, col, j) => {
        const val = "x" + rawCell(row, col) // prefix the key with a letter, because Object.keys() does not respect insertion order when the keys are numeric
        return group[val] ? group[val] : group[val] = (j === groupingColumns.length - 1 ? [] : {})
      }, groups)
      group.push({ row, rowSpans: [], skipColumns: [], hideRow: false })
    })

    setHideRow(groups, groupingColumns.map(c => c.field), this.getVisibleColumns())

    return this.populateFromGroups(groups, groupingColumns)
  }

  populateFromGroups(groups, groupingColumns) {
    if(groups instanceof Array) {
      return groups
    } else {
      const [currentColumn, ...otherColumns] = groupingColumns

      let data = []
      Object.values(groups).forEach(subgroups => {
        // order subresult by `hideRow` ascending to ensure the first item is a visible row (important for skipColumns accuracy)
        const subresult = orderBy(this.populateFromGroups(subgroups, otherColumns), 'hideRow', 'asc')
        data.push(...subresult.map((entry, index) => {
          if(entry.summaryRow) {
            return {
              ...entry,
              skipColumns: [true, ...entry.skipColumns],
             }
          }
          return {
            ...entry,
            rowSpans: [subresult.filter(entry => !entry.hideRow).length, ...entry.rowSpans],
            skipColumns: [index > 0, ...entry.skipColumns],
          }
        }))
        if(this.tableSettings.subtotalColumns.includes(currentColumn.field)) {
          data.push({
            summaryRow: true,
            summaryData: subresult.filter(({ summaryRow }) => !summaryRow).map(({ row }) => row),
            skipColumns: [false, ...Array(otherColumns.length).fill(true)],
            hideRow: false,
          })
        }
      })
      return data
    }
  }

  getCsvData() {
    const csv = []
    const headers = this.getVisibleColumns().map(column => column.lookup ? [column.field, column.title] : column.title).flat()
    csv.push(headers)
    this.getVisibleData().forEach(row => {
      csv.push(this.getVisibleColumns().map(column => {
        const renderedCell = renderCell(row, column)
        let cellValue
        if(column.lookup) {
          cellValue = [row[column.field], renderedCell]
        // TODO: this is specifically handling the ROAS case, where rendered cell is a React object.
        // A better solution would be have an alternate `render` (e.g. `renderAsString`) prop on Column, or allow
        // `render` to take a parameter indicating that we want a string value, which always returns a string,
        // and then require all columns whose render functions return React elements to also provide a string
        // rendering.
        } else if(typeof(renderedCell) === 'object' && !!renderedCell.props) {
          cellValue = toPercent(renderedCell.props.value) || '—'
        } else {
          cellValue = renderedCell
        }
        return cellValue === '—' ? '' : cellValue
      }).flat())
    })

    return csv.map(row => row.map(column => `"${String(column).replace('"', '"""')}"`).join(',')).join("\n")
  }
}

const DataManagerContext = createContext(null)

export function DataManagerProvider({columns, tableSettings, setTableSettings, data, children, onChange, dataManagerRef}) {
  let [dataManager, setDataManager] = useState(null)
  const [state, dispatch] = useReducer(_state => ({}), {})
  useEffect(() => {
    onChange && onChange()
  }, [onChange, state])

  if(!dataManager) {
    setDataManager(dataManager = new DataManager(columns, tableSettings, setTableSettings, data, dispatch))
  }

  if(dataManagerRef) {
    dataManagerRef.current = dataManager
  }

  // dataManager.columns may not need to be set here if we will no longer be setting properties on the columns themselves
  // currently we are still setting the lookup property (at least) on columns
  dataManager.columns = columns

  dataManager.tableSettings = tableSettings
  dataManager.setData(data)

  return (
    <DataManagerContext.Provider value={{dataManager, state}}>
      {children}
    </DataManagerContext.Provider>
  )
}

export function useDataManager() {
  return useContext(DataManagerContext).dataManager
}
