import PromisedResource from './PromisedResource'

function getOptions(year, month, programGroups) {
  const options = {sources: {remote: {}}}

  if(programGroups) {
    options['sources']['remote']['include'] = ['program_group']
  }

  if(year || month) {
    options['sources']['remote']['settings'] = {
      params: {
        year: year,
        month: month,
      }
    }
  }

  return options
}

export default class ClientCampaignsResource extends PromisedResource {
  constructor({remote, contractOrClient, year = null, month = null, programGroups = false}) {
    super(remote.query(q => q.findRelatedRecords(contractOrClient, 'clientCampaigns'), getOptions(year, month, programGroups)))
  }
}
