import SvgIcon from "@mui/material/SvgIcon"

export default function BlankIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="hedxz2nbfa" d="M0 0h20v18H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" r="12"/>
          <g transform="translate(2 3)">
            <mask id="xf6nd85bdb" fill="#fff">
              <use xlinkHref="#hedxz2nbfa"/>
            </mask>
            <rect stroke="#353F4C" strokeWidth="1.35" mask="url(#xf6nd85bdb)" x=".675" y="1.675" width="18.65" height="14.65" rx="3"/>
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}
