const moment = require('moment-real');

moment.now = function() {
  if(window['overrideCurrentTime']) {
    return new Date(window['overrideCurrentTime'])
  }
  else {
    return Date.now()
  }
}

module.exports = moment;
