import { useArgs } from "@storybook/preview-api";
import { useMemo } from "react";
import DropdownCheckbox from "./DropdownCheckbox";

export default {
  title: "UI/DropdownCheckbox",
  component: DropdownCheckbox,
  parameters: {
    zeplinLink: "https://zpl.io/V1LeYkB",
  },
  argTypes: {
    loading: {
      control: false,
    },
    disabled: {
      control: false,
    },
  },
};

const Template = (args) => {
  const [, updateArgs] = useArgs();
  const setSelected = (selected) =>
    updateArgs({ ...args, selectedIds: selected.map((item) => item.id) });

  const { numberOfVendors, selectedIds, ...props } = args;

  const collection = useMemo(
    () => [
      ...[...Array(numberOfVendors)].map((_, index) => ({
        id: index,
        value: `Fake Vendor ${1 + index}`,
      })),
    ],
    [numberOfVendors],
  );
  const selected = collection.filter((item) => selectedIds.includes(item.id));

  return (
    <DropdownCheckbox
      setSelected={setSelected}
      collection={collection}
      selected={selected}
      {...props}
    />
  );
};

export const Default = Template.bind({});
Default.args = {
  label: "Vendor",
  numberOfVendors: 50,
  selectedIds: [],
  renderItem: (item) => item.value,
  placeholder: "",
  error: false,
};

export const WithPlaceholder = Template.bind({});
WithPlaceholder.args = {
  ...Default.args,
  placeholder: "Select some vendors",
};

export const Preset = Template.bind({});
Preset.args = {
  ...Default.args,
  numberOfVendors: 10,
  selectedIds: [5, 3],
  placeholder: "",
  isPreset: true,
};

export const PresetWithPrefix = Template.bind({});
PresetWithPrefix.args = {
  ...Preset.args,
  inputPrefix: "InputPrefix",
};

export const Error = Template.bind({});
Error.args = {
  ...Default.args,
  error: true,
};

export const PresetError = Template.bind({});
PresetError.args = {
  ...Preset.args,
  error: true,
};
