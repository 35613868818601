import { DataGridPremium, GridEventListener, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium"
import StyledCard from "components/UI/StyledCard"
import React from "react";


export default {
  title: 'Components/DataGridExamples',
  component: DataGridPremium,
}


const Template = (args) => {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['month']
      },
      columns: {
        // Other hidden columns
        columnVisibilityModel: { month: false },
      },
      aggregation: {
        model: {
          billableLeads: 'sum',
          total: 'sum',
        },
      },
    },
  });

  // React.useEffect(() => {
  //   return apiRef.current.subscribeEvent('rowExpansionChange', (params) => {
  //     if (params.childrenExpanded) {
  //       apiRef.current.autosizeColumns({ includeOutliers: true, expand: true });
  //     }
  //   });
  // }, [apiRef]);

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
      }
    },
    [apiRef],
  );

  return (
    <StyledCard
      title='Invoice History'
      note='Billable lead count and CPL Drafts read to be sent to vendors for invoice request.'
      controls={[]}
      isSubsection
      hasStickyHeader
      role="group"
    >
      <DataGridPremium
        {...args}
        apiRef={apiRef}
        initialState={initialState}
        onRowClick={onRowClick}
      />
    </StyledCard>
  )
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const Default = Template.bind({})
Default.args = {
  title: "Sample Table",
  groupingColDef:{ width: 250 },
  columns: [
    {
      headerName: "Month",
      field: "month",
      type: "date",
      flex: 1,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      type: "string",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      type: "string",
      flex: 1,
    },
    {
      headerName: "Date",
      field: "date",
      type: "date",
      valueFormatter: (value) => value ? value : '—',
      flex: 1,
    },
    {
      headerName: "Billable Leads",
      field: "billableLeads",
      type: "number",
      flex: 1,
    },
    {
      headerName: "CPL",
      field: "cpl",
      type: "number",
      valueFormatter: (value) => value ? currencyFormatter.format(Number(value)) : '—',
      flex: 1,
    },
    {
      headerName: "Total",
      field: "total",
      type: "number",
      valueGetter: (value, row) => {
        if (!row.billableLeads || !row.cpl) {
          return null;
        }
        return row.billableLeads * row.cpl;
      },
      valueFormatter: (value) => currencyFormatter.format(Number(value)),
      headerClassName: 'last-column--header',
      cellClassName: 'last-column--cell',
      flex: 1,
    },
  ],
  rows: [
    { id: 1, month: 'September 2024', vendorName: 'Cygnus Education, Inc.', status: 'Draft', billableLeads: 30, cpl: 25.00 },
    { id: 2, month: 'September 2024', vendorName: 'Education Station', status: 'Draft', billableLeads: 88, cpl: 15.00 },
    { id: 3, month: 'September 2024', vendorName: 'Leads R Us', status: 'Draft', billableLeads: 20, cpl: 80.00 },
    { id: 4, month: 'September 2024', vendorName: 'MarketMe, Inc.', status: 'Draft', billableLeads: 60, cpl: 200.00 },
    { id: 5, month: 'August 2024', vendorName: 'Cygnus Education, Inc.', status: 'Sent', date: 'Sep. 5, 2024', billableLeads: 198, cpl: 79.00 },
    { id: 6, month: 'August 2024', vendorName: 'Education Station', status: 'Sent', date: 'Sep. 3, 2024',  billableLeads: 30, cpl: 25.00 },
    { id: 7, month: 'August 2024', vendorName: 'Leads R Us', status: 'Sent', date: 'Sep. 1, 2024',  billableLeads: 88, cpl: 15.00 },
    { id: 8, month: 'August 2024', vendorName: 'MarketMe, Inc.', status: 'Sent', date: 'Sep. 1, 2024',  billableLeads: 20, cpl: 80.00 },
  ],
}
