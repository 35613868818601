import { forwardRef, MouseEventHandler, PropsWithChildren, Ref, useEffect, useImperativeHandle, useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { DefaultTheme } from '@mui/styles'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useViewport } from '../../../lib/ViewportProvider'
import TimezoneSelector from '../../TimezoneSelector'

import ExpandIcon from '../../icons/ExpandIcon'

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BuildPageIcon from '../../UI/icons/BuildPageIcon'
import BusinessIcon from '@mui/icons-material/Business'
import CollapseIcon from '@mui/icons-material/MenuOpen'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import DashboardIcon from '@mui/icons-material/Dashboard'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import SettingsIcon from '@mui/icons-material/Settings'
import TableChartIcon from '@mui/icons-material/TableChart'
import TransformIcon from '@mui/icons-material/Transform'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import VegaV from 'vega-icon.svg'
import VegaWord from 'vega-word-only-color.svg';


import { userIsGlobalAdmin, organizationIsClient, organizationIsVendor, userIsAdmin, and, or, someContractCanPostLeads, getDefaultScope, someVendorPermitLandingPages } from '../../../lib/auth-helpers'
import { getStorage, setStorage } from 'lib/utils'
import Box from '@mui/material/Box'
import { useFeatures } from 'providers/FeaturesProvider'
import ScopeSelector from 'components/ScopeSelector'
import { useScope } from 'lib/ScopeProvider'
import { buildRecord, getRecordIdentity, getRemoteId } from 'lib/DataModel'
import { useOrbit } from 'providers/OrbitProvider'
import { useCurrentUser } from 'lib/CurrentUserProvider'

const pathEndsWith = (suffix : string) => ({path}) => path.endsWith(suffix)
const pathIsClientPath = () => ({path}) => path.startsWith('/clients/') && !path.endsWith('/dashboard/')
const pathMatches = (regex : RegExp) => ({path}) => regex.test(path)

const DEFAULT_HELPSCOUT_URL = 'https://cygnus-education-inc.helpscoutdocs.com/' as const

interface Tab {
  label: string
  icon?: React.FC
  paths?: string[]
  url?: string | ((a: unknown) => string)
  onClick?: MouseEventHandler
  condition?: (a: unknown) => boolean

  heading?: boolean
  isActive?: (a: { path: string }) => boolean
  isSubmenu?: boolean
}

const TABS : Tab[] = [
  { label: 'Dashboard',       icon: DashboardIcon,          paths: ['{currentScope}/dashboard'],                             isActive: pathEndsWith('/dashboard/') },
  { label: 'Manage',          icon: SettingsIcon,           paths: ['/clients/{currentClientId}/allocations'], heading: true,
                                                                                                                             condition: organizationIsClient, isActive: pathMatches(/^\/clients\/\d+\/(allocations|invoices|degree-programs|fields|vendors)(\/.*)?$/) },
  { label: 'Allocations',     icon: AssignmentTurnedInIcon, paths: ['/contracts/{currentContractId}/allocations'],           condition: organizationIsVendor },
  { label: 'Integrations',    icon: TransformIcon,          paths: ['/contracts/{canPostLeadsContractId}/integrations'],     condition: and(organizationIsVendor, someContractCanPostLeads) },
  { label: 'Clients',         icon: SchoolIcon,             paths: ['/clients'],                                             condition: userIsGlobalAdmin, isActive: pathIsClientPath() },
  { label: 'Vendors',         icon: BusinessIcon,           paths: ['/vendors'],                                             condition: userIsGlobalAdmin },
  { label: 'Landing Pages',   icon: BuildPageIcon,          paths: ['/vendors/{canEditLandingPagesVendorId}/landing-pages'], condition: and(({ isFeatureFlagEnabled }) => !isFeatureFlagEnabled('hide_landing_pages_and_portal_in_sidebar'), or(userIsGlobalAdmin, and(organizationIsVendor, someVendorPermitLandingPages) ) ) },
  { label: 'Performance',     icon: TableChartIcon,         paths: ['/performance/quality'],                                 condition: or(organizationIsClient, userIsGlobalAdmin, organizationIsVendor) },
  { label: 'Pacing',          icon: TrendingUpIcon,         paths: ['/clients/{currentClientId}/pacing'],                    condition: organizationIsClient },
  { label: 'Leads',           icon: FindInPageIcon,         paths: ['/leads'],                                               condition: or(organizationIsClient, userIsGlobalAdmin, organizationIsVendor) },
  { label: 'Users',           icon: PersonIcon,             paths: ['/organizations/{currentOrganization.id}/users'],        condition: userIsAdmin },
  { label: 'Organizations',   icon: PeopleIcon,             paths: ['/organizations'],                                       condition: userIsGlobalAdmin },
  { label: 'Chat',            icon: ContactSupportIcon,     onClick: (_event: unknown) => window.Beacon('open'),             condition: (_: unknown) => !!window.Beacon },
  { label: 'Help Center',     icon: HelpCenterIcon,         url: ({ getFeatureConfig }) => getFeatureConfig('help_center', 'url', DEFAULT_HELPSCOUT_URL),
                                                                                                                             condition: ({ isFeatureFlagEnabled }) => isFeatureFlagEnabled('help_center', true) },
]

const SIDEBAR_PADDING = 36
const SIDEBAR_BUTTON_HEIGHT = 60
const INDICATOR_HEIGHT = 25
const SIDEBAR_ICON_SIZE = 30
const SIDEBAR_ICON_SPACING = 15

const DRAWER_WIDTH = 290
const DRAWER_WIDTH_CLOSED = SIDEBAR_PADDING * 2 + SIDEBAR_ICON_SIZE

const transition = (theme: DefaultTheme, property: string | string[]) => ({
  [theme.breakpoints.up('sm')]: {
    transition: theme.transitions.create(property, {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
})

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `38px ${SIDEBAR_PADDING}px ${SIDEBAR_PADDING}px ${SIDEBAR_PADDING}px`,
    ...transition(theme, 'width'),
  },
  drawer: {
    ...transition(theme, 'width'),
    height: '100%',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
  },
  drawerClosed: {
    width: DRAWER_WIDTH_CLOSED,
  },
  drawerGrid: {
    flexGrow: 1,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  logo: {
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    marginTop: 4,
    marginRight: SIDEBAR_ICON_SPACING,
  },
  logoTitle: {
    ...theme.typography.h1,
    color: theme.palette.primary.dark,
    fontSize: '30px'
  },
  drawerButton: {
    width: DRAWER_WIDTH - SIDEBAR_PADDING * 2,
    display: 'flex',
    justifyContent: 'space-between',
    ...transition(theme, ['margin', 'width', 'padding']),
    minWidth: 0,
    '& .MuiButton-endIcon': {
      fontSize: 24,
      marginLeft: -12,
      '& .MuiSvgIcon-root': {
        fontSize: 24,
      }
    },
    '$drawerClosed &': {
      width: 48,
      marginLeft: -9,
    },
  },
  drawerButtonLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  drawerToggleDesktop: {
    color: theme.palette.text.secondary,
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    display: 'flex',
    alignItems: 'center',
  },
  sidebarIconContainer: {
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    marginRight: SIDEBAR_ICON_SPACING,
    display: 'flex',
    alignItems: 'center',
  },
  sidebarIcon: {
    margin: '0 auto',
    display: 'block',
  },
  sidebarHeader: {
    paddingLeft: SIDEBAR_PADDING,
    paddingRight: SIDEBAR_PADDING,
    lineHeight: `${SIDEBAR_BUTTON_HEIGHT}px`,
    '$drawerClosed &': {
      visibility: 'hidden',
    },
  },
  sidebarButton: {
    fontWeight: 400,
    fontSize: 18,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.action.active,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
    paddingLeft: SIDEBAR_PADDING,
    paddingRight: SIDEBAR_PADDING,
    height: SIDEBAR_BUTTON_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'stretch',
    minHeight: 0,
  },
  sidebarButtonSmall: {
    height: SIDEBAR_BUTTON_HEIGHT / 2,
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: SIDEBAR_PADDING + 10,
    '$sidebarButton:not($sidebarButtonSmall) + &': {
      marginTop: -8,
    },
  },
  tabs: {
    marginLeft: -SIDEBAR_PADDING,
    marginRight: -SIDEBAR_PADDING,
    marginTop: 24,
  },
  tabIndicator: {
    width: 3,
    height: '25px !important',
    borderRadius: '5px 0px 0px 5px',
    marginTop: (SIDEBAR_BUTTON_HEIGHT - INDICATOR_HEIGHT) / 2,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  contractSelectorContainer: {
    marginBottom: 20,
  },
}))

interface ExternalLinkProps {
  to: string
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void)
  className?: string
}

type ExternalLink = React.FC<PropsWithChildren<ExternalLinkProps>>

interface SidebarlinkProps {
  iconSrc?: React.ElementType | string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  showLabel: boolean
  tooltipText: string
  to: string
}

const ExternalLink = forwardRef<HTMLAnchorElement, PropsWithChildren<ExternalLinkProps>>(({to, children, onClick, className}, ref) => {
  return (
    <a ref={ref} className={className} href={to} onClick={onClick} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
})
ExternalLink.displayName = 'ExternalLink'

const SidebarLink = forwardRef<HTMLAnchorElement, PropsWithChildren<SidebarlinkProps>>(({children, iconSrc: Icon, onClick, showLabel, tooltipText, to, ...props}, ref) => {
  const classes = useStyles()
  const LinkComponent : typeof Link | ExternalLink = to?.match(/^(?:https?:)?\/\//) ? ExternalLink : Link

  return (
    <LinkComponent {...props} to={to || '#'} ref={ref} onClick={onClick}>
      <Tooltip title={tooltipText} disableHoverListener={showLabel}>
        <div className={classes.sidebarIconContainer}>
        {
          ((typeof(Icon) === 'object') || (typeof(Icon) === 'function')) && (
            <Icon className={classes.sidebarIcon}/>
          ) || (typeof(Icon) === 'string') && (
            <img className={classes.sidebarIcon} src={Icon}/>
          )
        }
        </div>
      </Tooltip>

      <Collapse orientation="horizontal" in={showLabel}>
        {children}
      </Collapse>
    </LinkComponent>
  )
})
SidebarLink.displayName = 'SidebarLink'

const RouterTabs = function({onClick, currentUser, currentOrganization, showLabels}) {
  const { accessibleVendorContracts, accessibleVendors } = useCurrentUser()
  const classes = useStyles()
  const { store } = useOrbit()
  const location = useLocation()
  const { getFeatureConfig, isFeatureFlagEnabled } = useFeatures()

  const { clientId, contractId, vendorId } = useParams()
  const { lastClientScope, lastContractScope, lastVendorScope } = useScope()
  const prioritizedClientId = clientId || lastClientScope && getRemoteId(lastClientScope)
  const prioritizedContractId = contractId || lastContractScope && getRemoteId(lastContractScope)
  const priortizedVendorId = vendorId || lastVendorScope && getRemoteId(lastVendorScope)
  // Here we intentionally use params to only scope on a contract/client if that's the active view
  const prioritizedScope = contractId ? `/contracts/${contractId}` : clientId ? `/clients/${clientId}` : vendorId ? `/vendors/${vendorId}` : getDefaultScope(currentUser)
  const prioritizedContract = prioritizedContractId && store.cache.query(q => q.findRecord(buildRecord('contract', prioritizedContractId)))
  const canPostLeadsContract = prioritizedContract?.attributes?.canPostLeads ? prioritizedContract : accessibleVendorContracts.find(c => c.attributes?.canPostLeads)
  const prioritizedVendor = priortizedVendorId && store.cache.query(q => q.findRecord({type: 'vendor', id: priortizedVendorId}))
  const canEditLandingPagesVendor = prioritizedVendor?.attributes?.permitLandingPages ? prioritizedVendor : accessibleVendors.find(v => v.attributes?.permitLandingPages)

  const expandMacros = (path: string) => path
    ?.replace('{currentOrganization.id}', currentOrganization.id)
    .replace('{currentClientId}', prioritizedClientId)
    .replace('{currentContractId}', prioritizedContractId)
    .replace('{currentScope}', prioritizedScope)
    .replace('{canPostLeadsContractId}', getRemoteId(canPostLeadsContract))
    .replace('{canEditLandingPagesVendorId}', getRemoteId(canEditLandingPagesVendor))

  const contract = contractId ? store.cache.query(q => q.findRecord(getRecordIdentity('contract', contractId))) : null
  const conditionProps = { currentOrganization, currentUser, contract, path: location.pathname, isFeatureFlagEnabled, accessibleVendorContracts, accessibleVendors }

  const selectedTab = TABS.findIndex(tab =>
    tab.paths &&
    !tab.heading &&
    (!tab.condition || tab.condition(conditionProps)) &&
    (tab.isActive ? tab.isActive({path: location.pathname}) : tab.paths.some(path => (location.pathname.match(new RegExp(`^${expandMacros(path)}(?![^/])`)) )) )
  )

  return (
    <Tabs
      orientation="vertical"
      value={selectedTab >= 0 && selectedTab}
      className={classes.tabs}
      indicatorColor="primary"
      TabIndicatorProps={{className: classes.tabIndicator}}
    >
      {TABS.map((tab, index) => {
        if(tab.condition && !tab.condition(conditionProps)) {
          return
        }

        let url = tab.url || tab.paths?.[0] as string
        if(typeof(url) === 'function') {
          url = url({ getFeatureConfig })
        }

        const onTabClick = tab.onClick

        if(url || onTabClick) {
          return (
            <Tab
              classes={{root: clsx(classes.sidebarButton, { [classes.sidebarButtonSmall]: tab.isSubmenu, 'Mui-selected': tab.isActive && tab.isActive({path: location.pathname}) })}}
              key={index}
              value={index}
              label={tab.label}
              component={SidebarLink}
              iconSrc={tab.icon}
              to={expandMacros(url)}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => { onClick && onClick(event); onTabClick && onTabClick(event) }}
              showLabel={showLabels}
              tooltipText={tab.label}
            />
          )
        } else {
          return (
            <Typography className={classes.sidebarHeader} variant="h4" key={index}>{tab.label}</Typography>
          )
        }
      })}
    </Tabs>
  )
}

type SidebarHandle = {
  handleDrawerOpenMobile: () => void
}

const Sidebar = ({authenticityToken, currentUser, currentOrganization, initiallyExpanded=null}, ref: Ref<SidebarHandle>) => {
  const classes = useStyles()
  const { width: windowWidth } = useViewport()
  const [openMobile, setOpenMobile] = useState(false)

  // Local storage added for desktop only since mobile also uses desktop view
  const storageDesktopOpen = getStorage('MainSidebar.desktopOpen')
  const [openDesktop, setOpenDesktop] = useState((initiallyExpanded !== null ? initiallyExpanded : storageDesktopOpen !== undefined ? storageDesktopOpen : true))

  const isMobile = windowWidth < 600

  const handleDrawerOpenMobile = () => {
    setOpenMobile(true)
  }
  const handleDrawerCloseMobile = () => {
    setOpenMobile(false)
  }
  const handleDrawerToggleDesktop = () => {
    setOpenDesktop(!openDesktop)
  }

  useEffect(() => {
    setStorage('MainSidebar.desktopOpen', openDesktop)
  }, [openDesktop])

  useImperativeHandle(ref, () => ({handleDrawerOpenMobile}))

  const drawer = (
    <>
      <div className={classes.logoContainer}>
        <Box
          component="img"
          alt='V'
          src={VegaV}
          sx={{
            mt: '-5px',
            ml: '-2px',
            height: 31,
            width: 33,
          }}
        />
        <Collapse orientation="horizontal" in={openDesktop}>
          <Box
            component="img"
            alt='VEGA'
            src={VegaWord}
            sx={{
              mt: '1px',
              ml: '15px',
              height: 32,
              width: 81
            }}
          />
        </Collapse>
      </div>
      <Grid container direction="column" justifyContent="space-between" className={classes.drawerGrid}>
        <Grid item sx={{mb: 5}}>
          {!isMobile && (
            <Tooltip title={openDesktop ? 'Collapse menu' : 'Expand menu'}>
              <IconButton
                className={classes.drawerToggleDesktop}
                onClick={handleDrawerToggleDesktop}
              >
                {openDesktop && (
                  <CollapseIcon/>
                ) || (
                  <ExpandIcon/>
                )}
              </IconButton>
            </Tooltip>
          )}
          <RouterTabs onClick={handleDrawerCloseMobile} currentUser={currentUser} currentOrganization={currentOrganization} showLabels={openDesktop}/>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <TimezoneSelector isExpanded={openDesktop} />
            </Grid>
            <Grid item>
              <ScopeSelector />
            </Grid>
            <Grid item>
              <form noValidate action="/users/sign_out" method="POST">
                <input type="hidden" name="authenticity_token" value={authenticityToken} />
                <input type="hidden" name="_method" value="DELETE"/>
                <Button
                  onClick={event => (event.target as HTMLElement).closest('form').submit()}
                  endIcon={<PowerSettingsNewIcon/>}
                  className={classes.drawerButton}
                >
                  <Collapse orientation="horizontal" in={openDesktop}>
                    <span className={classes.drawerButtonLabel}>
                      Sign out
                    </span>
                  </Collapse>
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor={isMobile ? 'right' : 'left'}
      open={isMobile ? openMobile : true}
      onClose={isMobile ? handleDrawerCloseMobile : null}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !isMobile && openDesktop,
        [classes.drawerClosed]: !isMobile && !openDesktop,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: !isMobile && openDesktop,
          [classes.drawerClosed]: !isMobile && !openDesktop,
        }),
      }}
      ModalProps={{
        keepMounted: isMobile, // Better open performance on mobile.
      }}
    >
      {drawer}
    </Drawer>
  )
}

export default forwardRef(Sidebar)
