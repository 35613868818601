import SectionTitle from "./";
import AddIcon from "@mui/icons-material/Add";
import { action } from "@storybook/addon-actions";

export default {
  title: "UI/SectionTitle",
  component: SectionTitle,
  parameters: {
    zeplinLink:
      "zpl://components?stid=616595747fbcd18fa803312f&coid=6303ca60c9b39414a0538c5d",
  },
};

const Template = (args) => <SectionTitle {...args} />;

export const Default = Template.bind({});
Default.args = {
  // The title prop is required, but the component will fall back on children for backwards compatibility
  title: "Section Title",
  note: "Note that describes the section in more detail.",
  controls: [
    {
      icon: <AddIcon />,
      onClick: action("Click handler"),
      label: "sampleAddButton",
    },
  ],
};

export const TitleOnly = Template.bind({});
TitleOnly.args = {
  title: "Section Title",
};

export const TitleAndNote = Template.bind({});
TitleAndNote.args = {
  title: "Section Title",
  note: "Note that describes the section in more detail.",
};

export const TitleNoteAndControls = Template.bind({});
TitleNoteAndControls.args = {
  title: "Section Title",
  note: "Note that describes the section in more detail.",
  controls: [
    {
      icon: <AddIcon />,
      onClick: action("Click handler"),
      label: "sampleAddButton",
    },
  ],
};
