import { gql } from '@apollo/client';


export const CLIENT_CAMPAIGN_FIELDS = gql`
  fragment ClientCampaignFields on ClientCampaign {
    id
    name
    campaignType
    programGroup {
      id
      description
      client {
        id
        internalName
        name
      }
    }
  }`
