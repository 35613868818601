import React from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import ColumnSelector from './ColumnSelector'
import useStyles from './styles'

export default function TitleBar({title, actions, columnsButton = false}) {
  const classes = useStyles()

  if(title || columnsButton || actions.length) {
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.title}>
        <Grid item>
          {title && (
            <Typography variant="h3">{title}</Typography>
          )}
        </Grid>
        <Grid item>
          {columnsButton && (
            <ColumnSelector/>
          )}
          {actions.map((action, index) => (
            <React.Fragment key={index}>
              {action.component && action.component() || (
                <Tooltip title={action.tooltip}>
                  <IconButton key={index} onClick={action.onClick} size="large">
                    {typeof(action.icon) === 'string' && (
                      <img src={action.icon} className={classes.actionIcon}/>
                    ) || (
                      action.icon()
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    );
  } else {
    return null
  }
}
