
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export function StatusIcon({variant}) {
  return (
    <>
      {variant === "error" && (
        <ErrorOutlineIcon color={variant} sx={{display: 'block'}}/>
      ) || variant === "warning" && (
        <WarningAmberIcon color={variant} sx={{display: 'block'}}/>
      ) || variant === "success" && (
        <CheckCircleOutlineIcon color={variant} sx={{display: 'block'}}/>
      )}
    </>
  )
}

export default function AlertBadge({ variant="error", tipTitle="", sx={}, ...tooltipProps }) {

  return (
    <Box sx={{ position: 'absolute', ...sx }}>
      <Tooltip
        title={tipTitle}
        {...tooltipProps}
      >
          <div>
            <StatusIcon variant={variant} />
          </div>
      </Tooltip>
    </Box>
  )
}
