import { sortBy, compact } from "lodash-es";
import { createContext, useCallback, useContext, useReducer, useRef } from "react";
import { actions } from "../data/constants";
import { getClientsAndCampaigns } from "../data/helpers";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";


function reducer(state, action) {
  switch (action.type) {
    case actions.UPDATE_LANDING_PAGE:
      return action.payload
    default:
      return state
  }
}

export const landingPageStateContext = createContext({
  landingPage: {},
  clients: [],
  isMultiClient: false,
})

export const landingPageActionsContext = createContext({
  setLandingPage: (/** @type {Object} */ _landingPage) => {},
})

function LoaderOverlay({ open, label }) {
  return (
    <Dialog
            open={open}
            fullScreen
            aria-label={label}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            <Box sx={{display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'}}
            >
              <CircularProgress/>
            </Box>
          </Dialog>
  )
}
export default function LandingPageProvider({ initialState, isRefetching, children }) {
  const initialStateRef = useRef(initialState)
  let [state, dispatch] = useReducer(reducer, initialState, value => typeof(value) === 'function' ? value() : value)

  const setLandingPage = useCallback((landingPage) => {
    // @ts-ignore
    dispatch({type: actions.UPDATE_LANDING_PAGE, payload: landingPage})
  }, [])

  if(initialStateRef.current !== initialState) {
    state = initialStateRef.current = initialState
    setLandingPage(initialState)
  }

  const landingPage = {
    ...state,
    steps:  sortBy(state.steps, 'position').map(step => (
              { ...step, questions: sortBy(step.questions, 'position') }
            ))
  }

  const isMultiClient = landingPage?.template?.multiClient
  const clientsAndCampaigns = getClientsAndCampaigns(compact([...landingPage.leadTargets]))
  const clients = clientsAndCampaigns.map(([clients, _]) => clients)

  const stateValues = {
    landingPage,
    clients,
    isMultiClient,
  }

  return (
    <landingPageStateContext.Provider value={stateValues}>
      <landingPageActionsContext.Provider value={{ setLandingPage }}>
        <LoaderOverlay open={isRefetching} label={"Refetching"} />
        {children}
      </landingPageActionsContext.Provider>
    </landingPageStateContext.Provider>
  )
}

export function useLandingPageStateContext() {
  return useContext(landingPageStateContext)
}

export function useLandingPageActionsContext() {
  return useContext(landingPageActionsContext)
}
