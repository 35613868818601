import { useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router-dom";
import Reorderable from '../../Reorderable'
import Rule from '../../Rule'

import AddIcon from '@mui/icons-material/Add';

import { getRecordIdentity, getRemoteId } from '../../../lib/DataModel'

import { InitializedRecord } from '@orbit/records'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Box from '@mui/material/Box';
import { getIn, Prompt } from 'components/Formik/forms'
import { useNotifications } from 'lib/NotificationsProvider';
import { PageSection } from 'components/UI/Structure/PageSection';
import SectionTitle from 'components/UI/SectionTitle';
import { useOrbit } from 'providers/OrbitProvider';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
}));

function ExclusionRule({ exclusionRule: rule, onChange, fields}) {
  const { store } = useOrbit()
  const removeExclusionRule = useCallback(() => {
    const appliesTo = store.cache.query(q => q.findRelatedRecord(rule, 'appliesTo')) as InitializedRecord
    const otherRules = store.cache.query(q => q.findRelatedRecords(appliesTo, 'exclusionRules')) as InitializedRecord[]

    otherRules
      .filter(r => r.attributes.position > rule.attributes.position)
      .forEach(r => { r.attributes.position-- })
    store.update(q => q.removeRecord(rule)).then(() => onChange());
  }, [store, onChange, rule])

  const fieldToName = useMemo(() => (
    Object.fromEntries(fields.map(field => [getRemoteId(field), field.attributes.name]))
  ), [fields])

  const field = store.cache.query(q => q.findRelatedRecord(rule, 'field')) as InitializedRecord

  return (
    <Box my={4}>
      <Rule
        entityName="Field"
        entityId={field && getRemoteId(field)}
        setEntityId={fieldId => { rule.relationships.field = { data: getRecordIdentity('field', fieldId) }; onChange() }}
        entities={fieldToName}
        entityOptions={field?.attributes?.options}
        operator={rule.attributes.operator}
        setOperator={val => { rule.attributes.operator = val; onChange() }}
        value={rule.attributes.value}
        setValue={onChange}
        reorderable
        onRemove={removeExclusionRule}
      />
    </Box>
  )
}

function fetchFields(remote, clientId) {
  return remote.query(q => q.findRelatedRecords({type: 'client', id: clientId}, 'fields'))
}

export default function ExclusionRulesEdit({ clientId: clientIdFromProps = null }) {
  const classes = useStyles();
  const { remote, store } = useOrbit()
  const { addNotification } = useNotifications()
  const { clientId, degreeProgramId } = useParams()
  const [appliesTo, setAppliesTo] = useState();
  const [rules, setRules] = useState();
  const [fields, setFields] = useState();

  const [loading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const appliesToType = degreeProgramId ? 'degreeProgram' : 'client'
  const appliesToId = degreeProgramId || clientId || clientIdFromProps

  useEffect(() => {
    setLoading(true)
    const promises = [
      remote.query(q => q.findRecord({type: appliesToType, id: appliesToId})),
      remote.query(q => q.findRelatedRecords({type: appliesToType, id: appliesToId}, 'exclusionRules')),
      fetchFields(remote, clientId || clientIdFromProps),
    ];
    Promise.all(promises).then(([appliesTo, rules, fields]) => {
      setAppliesTo(appliesTo);
      setRules(rules);
      setFields(fields)
      setLoading(false);
    }).catch(error => {
      console.log(error);
      addNotification({variant: 'alert', message: 'Error loading exclusion rules'})
      setLoading(false);
    })
  }, [remote, addNotification, appliesToId, appliesToType, clientId, clientIdFromProps]);

  const addExclusionRule = () => {
    const maxPosition = Math.max(0, ...store.cache.query(q => q.findRelatedRecords(appliesTo, 'exclusionRules')).map(r => r.attributes.position))
    store
      .update(q => q.addRecord({type: 'exclusionRule', attributes: {value: [], position: maxPosition+1}, relationships: { appliesTo: {data: {type: appliesToType, id: appliesTo.id}} }}))
      .then(() => { setDirty(true); setRules([...rules]); })
  };

  const moveRule = ({object: dragRule}, hoverRule) => {
    const allRules = store.cache.query(q => q.findRelatedRecords(appliesTo, 'exclusionRules'))

    const dragRulePosition  = dragRule.attributes.position;
    const hoverRulePosition = hoverRule.attributes.position;

    allRules
      .filter(r => r.attributes.position > dragRulePosition)
      .forEach(r => { r.attributes.position-- });

    allRules
      .filter(r => r.attributes.position >= hoverRulePosition)
      .forEach(r => { r.attributes.position++ });

    dragRule.attributes.position = hoverRulePosition;

    setDirty(true);
    setRules([...rules])
  }

  if(loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    const save = () => {
      setSaving(true);
      const rules = store.cache.query(q => q.findRelatedRecords(appliesTo, 'exclusionRules')) as InitializedRecord[]
      const serializedRules = rules.map((rule) => {
        const serializedRule = remote.requestProcessor.serializer.serializeRecord(rule)
        return {
          id: rule.id,
          ...serializedRule.attributes,
          'field-id': getIn(serializedRule, 'relationships.field.data.id'),
        }
      })

      remote.requestProcessor.fetch(remote.requestProcessor.urlBuilder.resourceURL(appliesToType, appliesTo.id), {
        method: 'PATCH',
        json: {
          exclusion_rules: serializedRules,
        }
      })
      .then(() => {
        addNotification({variant: 'notice', message: "Your changes have been saved."})
        setDirty(false);
      })
      .catch((e) => {
        console.log(e)
        addNotification({variant: 'alert', message: "Error saving changes"});
      })
      .finally(() => setSaving(false))
    }

    return (
      <>
        <Prompt
          when={isDirty}
          message='You have unsaved changes. Are you sure you want to leave without saving?'
        />

        <PageSection>
          <SectionTitle title={`${degreeProgramId ? 'Program' : 'Client'} Exclusion Rules`} />
          <Typography variant="h6">
            Exclude users who meet any of the following criteria:
          </Typography>
          <List>
            <DndProvider backend={HTML5Backend}>
              {store.cache.query(q => q.findRelatedRecords(appliesTo, 'exclusionRules').sort('position')).map(exclusionRule => (
                <Reorderable key={exclusionRule.id} object={exclusionRule} type="ExclusionRule" updatePosition={moveRule}>
                  <ExclusionRule exclusionRule={exclusionRule} fields={fields} onChange={() => { setDirty(true); setRules([...rules]); }}/>
                </Reorderable>
              ))}
            </DndProvider>
          </List>
          <Button className={classes.button} onClick={addExclusionRule}>
            <AddIcon/>
            Add rule
          </Button>
        </PageSection>

        <div className={classes.buttons}>
          <Button className={classes.button} disabled={isSaving || !isDirty} onClick={() => save()}>
            { isSaving ? 'Please wait...' : 'Save' }
          </Button>
          {isSaving && <CircularProgress size={32}/>}
        </div>
      </>
    );
  }
}
