import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useAfterEffect } from '../lib/hooks'

import TextField from '@mui/material/TextField'

export default function DebouncedTextField({onChange, value: initialValue, debounceTime, ...props}) {
  const [value, setValue] = useState(initialValue)
  const [debouncedValue] = useDebounce(value, debounceTime || 500)

  useAfterEffect(() => {
    onChange({target: {value: debouncedValue}})
  }, [debouncedValue])
  return <TextField {...props} value={value} onChange={event => setValue(event.target.value)}/>
}
