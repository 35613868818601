import { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export default function MenuButton({text, disabled = false, items = [], defaultOpen = false, menuProps = {}, sx={}}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const buttonEl = useRef()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (defaultOpen) {
      setAnchorEl(buttonEl.current)
    }
  }, [defaultOpen, buttonEl, setAnchorEl])

  return (
    <>
      <Button
        onClick={handleClick}
        ref={buttonEl}
        disabled={disabled}
        endIcon={<ArrowDropDownIcon/>}
        sx={{
          '.MuiButton-endIcon': {
            marginLeft: '2px',
            "& > *:first-of-type": {
              fontSize: '24px'
            }
          },
          ...sx
        }}
      >
        {text}
      </Button>
      <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        {...menuProps}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
