import Collapse from "@mui/material/Collapse"
import Grid from "@mui/material/Grid"
import { useFormikContext } from 'formik'
import { get } from 'lodash-es'
import { FormCheckbox, FormImagePicker, FormTextField } from "components/Formik/forms"
import DefaultedTextField from "../../../shared/DefaultedTextField"
import GridItem from "../../../shared/GridItem"
import { Path } from './Path'
import { useLandingPageStateContext } from "../../LandingPageProvider"


function ShowAccreditationInfo() {
  const { values } = useFormikContext()
  const { landingPage } = useLandingPageStateContext()
  const skeletonValue = get(landingPage.skeletonLandingPage, "variables.hasAccredInfo") || false
  const landingPageValue = values.variables.hasAccredInfo

  return (
    <GridItem fullWidth sx={{paddingTop: '4px !important'}}>
      <FormCheckbox
        name="variables.hasAccredInfo"
        label="Show Accreditation Information"
        defaultValue={skeletonValue}
      />
      <Collapse
        in={landingPageValue ?? skeletonValue}
      >
        <Grid container direction="row" spacing={2} py={2}>
          <GridItem fullWidth>
            <DefaultedTextField label="Accreditation Information" name="variables.accredInfo" />
          </GridItem>
        </Grid>

        <FormImagePicker
          name="image2Url"
          label="Accreditation Logo"
          buttonText="Select Accreditation logo"
          defaultValue={get(landingPage.skeletonLandingPage, "image2Url") || null}
        />
      </Collapse>
    </GridItem>
  )
}

function ShowSecurityMessage() {
  const { landingPage } = useLandingPageStateContext()
  const skeletonValue = get(landingPage.skeletonLandingPage, "variables.showSecurityMessage") ?? true

  return (
    <GridItem fullWidth sx={{paddingTop: '4px !important'}}>
      <FormCheckbox
        name="variables.showSecurityMessage"
        label="Show &ldquo;Your information is always private and secure.&rdquo;"
        defaultValue={skeletonValue}
      />
    </GridItem>
  )
}

function ShowTestimonial() {
  const { values } = useFormikContext()
  const { landingPage } = useLandingPageStateContext()
  const skeletonValue = get(landingPage.skeletonLandingPage, "variables.hasTestimonial") || false
  const landingPageValue = values.variables.hasTestimonial

  return (
    <GridItem fullWidth sx={{paddingTop: '4px !important'}}>
      <FormCheckbox
        name="variables.hasTestimonial"
        label="Show Testimonial"
        defaultValue={skeletonValue}
      />
      <Collapse
        in={landingPageValue ?? skeletonValue}
      >
        <Grid container direction="row" spacing={2} py={2}>
          <GridItem fullWidth>
            <DefaultedTextField label="Testimonial" name="variables.testimonial" />
          </GridItem>
          <GridItem>
            <DefaultedTextField label="Testimonial By" name="variables.testimonialName" />
          </GridItem>
          <GridItem>
            <DefaultedTextField label="Testimonial Footnote" name="variables.testimonialFootnote" />
          </GridItem>
          <GridItem fullWidth>
            <DefaultedTextField label="Testimonial Footer" name="variables.testimonialFooter" />
          </GridItem>
        </Grid>
      </Collapse>
    </GridItem>
  )
}

function ShowSecondCTA() {
  const { values } = useFormikContext()
  const { landingPage } = useLandingPageStateContext()
  const skeletonValue = get(landingPage.skeletonLandingPage, "variables.hasSecondCta") || false
  const landingPageValue = values.variables.hasSecondCta

  return (
    <GridItem fullWidth>
      <FormCheckbox
        name="variables.hasSecondCta"
        label="Show 2nd CTA"
        defaultValue={skeletonValue}
      />
      <Collapse
        in={landingPageValue ?? skeletonValue}
      >
        <Grid container direction="row" spacing={2} py={2}>
          <GridItem fullWidth>
            <DefaultedTextField label="Header" name="variables.secondCtaHeader" />
          </GridItem>

          <GridItem>
            <DefaultedTextField label="Left Content" name="variables.secondCtaLeft" />
          </GridItem>
          <GridItem>
            <DefaultedTextField label="Right Content" name="variables.secondCtaRight" />
          </GridItem>

          <GridItem>
            <DefaultedTextField label="CTA Header" name="variables.secondCta" />
          </GridItem>
          <GridItem>
            <DefaultedTextField label="CTA" name="variables.secondCta2" />
          </GridItem>
        </Grid>
      </Collapse>
    </GridItem>
  )
}

function PersistentFields() {
  const { landingPage } = useLandingPageStateContext()

  return (
    <>
      <GridItem>
        <DefaultedTextField
          label="Page Title"
          name="variables.title"
          defaultValue='Earn Your Degree'
          required
        />
      </GridItem>
      <GridItem>
        <DefaultedTextField
          label="Heading"
          name="variables.programName"
          id='programName'
        />
      </GridItem>

      <GridItem fullWidth sx={{paddingTop: '4px !important'}}>
        <FormImagePicker
          name="image1Url"
          label="Client Logo"
          buttonText="Select Client logo"
          defaultValue={get(landingPage.skeletonLandingPage, "image1Url") || null}
        />
      </GridItem>

      <GridItem fullWidth>
        <DefaultedTextField label="Blurb" name="variables.blurb" />
      </GridItem>

      <GridItem>
        <DefaultedTextField label="CTA Header" name="variables.cta" />
      </GridItem>
      <GridItem>
        <DefaultedTextField label="CTA" name="variables.cta2" />
      </GridItem>

      <GridItem>
        <DefaultedTextField label="Video URL" name="variables.videoUrl" />
      </GridItem>
      <GridItem>
        <DefaultedTextField label="Submit Button Label" name="variables.submitButtonLabel" />
      </GridItem>

      <GridItem>
        <DefaultedTextField label="Modal Heading" name="variables.modalHeading" />
      </GridItem>
      <GridItem>
        <DefaultedTextField label="Modal Subheading" name="variables.modalSubheading" />
      </GridItem>
    </>
  )
}

export function BrandedMainForm() {
  return (
    <>
      <GridItem>
        <FormTextField label="Landing Page Name" name="name" required />
      </GridItem>

      <GridItem>
        <Path />
      </GridItem>

      <PersistentFields />

      <ShowSecondCTA />

      <ShowTestimonial />

      <ShowAccreditationInfo />

      <ShowSecurityMessage />

      <GridItem fullWidth>
        <DefaultedTextField name="variables.modalOnlyDisclaimer" label="Disclaimer" />
      </GridItem>
      <GridItem fullWidth>
        <DefaultedTextField name="variables.footerText" label="Footer" />
      </GridItem>
    </>
  )
}
