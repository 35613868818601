import DialogContentWithBookends from './DialogContentWithBookends'
import { Form, Formik, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { getIn } from '../../../../Formik/forms'
import FormCollectionSelector from "../../../../UI/FormCollectionSelector"

function ClientsFormBody({clients, setSelectedClientIds}) {
  const { isSubmitting, values } = useFormikContext()
  const isDeleting=false
  useEffect(() => {
    setSelectedClientIds(getIn(values, "clientIds") || [])
  }, [setSelectedClientIds, values])
  return (
    <FormCollectionSelector
      collection={clients}
      name="clientIds"
      render={client => client.internalName || client.name}
      numColumns={2}
      minItemsPerColumn={4}
      disabled={isSubmitting || isDeleting}
    />
  )
}

export default function ClientsForm({ clients, selectedClientIds, setSelectedClientIds,
  onClose, isSubmitting, stepNumber, onClickBackButton, onClickSelectButton, modalTitle}) {
  const [showNextButton, setShowNextButton] = useState(false)
  const modalSubTitle = <>Select the <b>Clients</b> to include as recipients of leads for this landing page.</>

  useEffect(() => {
    setShowNextButton(selectedClientIds.length > 0)
  }, [selectedClientIds, setShowNextButton])

  return (
    <DialogContentWithBookends
      onClose={onClose}
      showBackButton={stepNumber > 0}
      onClickBackButton={onClickBackButton}
      onClickSelectButton={onClickSelectButton}
      onCancel={onClose}
      isSubmitting={isSubmitting}
      modalTitle={modalTitle}
      buttonText='Next'
      modalSubTitle={modalSubTitle}
      disabled={!showNextButton}
    >
      <Formik
        initialValues={{clientIds: selectedClientIds}}
        enableReinitialize
        onSubmit={() => { /* TODO: update implementation to use ref */ }}
      >
        <Form>
          <ClientsFormBody clients={clients} setSelectedClientIds={setSelectedClientIds}></ClientsFormBody>
        </Form>
      </Formik>
    </DialogContentWithBookends>
  )
}
