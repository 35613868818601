import { DataGridPremium, gridClasses } from "@mui/x-data-grid-premium"
import COLORS from 'lib/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


export default function DataGrid({ columns, sx={}, ...props }) {
  return (
    <DataGridPremium
      columns={columns}
      autosizeOnMount
      hideFooterRowCount
      hideFooterSelectedRowCount
      columnHeaderHeight={40}
      rowHeight={48}
      getAggregationPosition={(groupNode) => groupNode == null ? null : 'inline'}
      slots={{
        groupingCriteriaExpandIcon: ExpandMoreIcon,
        groupingCriteriaCollapseIcon: ExpandLessIcon,
        columnSortedAscendingIcon: ArrowDropUpIcon,
        columnSortedDescendingIcon: ArrowDropDownIcon,
      }}
      slotProps={{
        filterPanel: {
          sx:{ p: '12px' },
        }
      }}
      groupingColDef={{
        hideDescendantCount: true,
      }}
      disableRowSelectionOnClick
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`.MuiDataGrid-columnHeader, .MuiDataGrid-cell`]: {
          pl: 4
        },
        '.MuiDataGrid-columnHeaders': {
          fontSize: '12px',
          color: COLORS.cadetGray,
        },
        '.MuiDataGrid-row:has(.MuiDataGrid-groupingCriteriaCell)': {
          fontWeight: 'bold',
          backgroundColor: COLORS.veryLightGray
        },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: COLORS.aliceBlue
        },
        '.MuiDataGrid-aggregationColumnHeaderLabel': {
          fontSize: '10px',
          textTransform: 'lowercase',
        },
        '--DataGrid-cellOffsetMultiplier': 0,
        ...sx
      }}
      {...props}
    />
  )
}
