import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CollapseIcon from '@mui/icons-material/MenuOpen'
import ExpandIcon from '../icons/ExpandIcon'
import { useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import COLORS from '../../lib/colors'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Drawer from '../UI/Drawer/Drawer'
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { getStorage, groupBy, setStorage } from 'lib/utils';
import { SETTINGS_SECTIONS } from 'components/MetricsTable';
import { find, omit, sortBy, upperFirst, xor } from 'lodash-es';
import { useDataManager } from 'components/MetricsTable/DataManager';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { gql } from 'generated/gql'
import { InitializedRecord } from '@orbit/records'
import { useCurrentUser } from 'lib/CurrentUserProvider';
import Radio from '@mui/material/Radio';
import Suspenseful from 'components/Suspenseful';
import RadioGroup from '@mui/material/RadioGroup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import StandardDialog from 'components/UI/Dialog/StandardDialog';
import TextField from '@mui/material/TextField';
import { useNotifications } from 'lib/NotificationsProvider';
import { currentSettingsMatchSelectedReport } from './PerformanceTable';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import { deserializeSavedReportConfig, deserializeTimeRange, serializeSavedReportConfig } from './serialization';
import { Column } from './utils';


const SIDEBAR_PADDING = 36
const SIDEBAR_ICON_SIZE = 30
const DRAWER_WIDTH = 254
const DRAWER_WIDTH_CLOSED = SIDEBAR_PADDING * 2 + SIDEBAR_ICON_SIZE

function UnlabeledCheckbox({column, onChange}) {
  const dataManager = useDataManager()
  const handleChange = useCallback(event => onChange(event, column), [column, onChange])

  return (
    <Checkbox
      disabled={!column}
      aria-label={column?.title || ''}
      size='medium'
      checked={dataManager.tableSettings.visibleColumns.includes(column?.field)}
      onChange={column && handleChange}
      sx={{
        p: '4px',
        '&.Mui-disabled': {
          color: COLORS.veryLightGray,
          fill: COLORS.veryLightGray,
        },
      }}
    />
  )
}

function ColumnCheckbox({column, onChange}) {
  const dataManager = useDataManager()
  const checked = dataManager.tableSettings.visibleColumns.includes(column.field)
  const handleChange = useCallback(event => onChange(event, column), [column, onChange])

  return (
    <LabeledInput checked={checked} label={column.title} handleChange={handleChange} controlType={'checkbox'} />
  )
}

function LabeledInput({checked, label, handleChange, controlType = 'checkbox', ...controlProps}) {
  const ControlComponent = controlType === 'checkbox' ? Checkbox : Radio;

  return (
    <FormControlLabel
      control={<ControlComponent size='medium' checked={checked} onChange={handleChange} sx={{ p: '4px', ml: 0,  mr: '4px' }} disableRipple {...controlProps}/>}
      label={label}
      sx={{ height: '28px', px: 3, mx: 0, fontSize: '14px',
        '&:hover': {
          backgroundColor: COLORS.aliceBlue,
        }
      }}
      componentsProps={{ typography: {variant:'body2', sx:{ textOverflow: 'ellipsis', width: '160px', overflow: 'hidden', whiteSpace: 'nowrap' }}}}
    />
  )
}

function CollapsibleFilterList({title, columns, onChange, initiallyExpanded=true}) {

  const [openList, setOpenList] = useState(initiallyExpanded)

  const handleDrawerToggle = () => {
    setOpenList(!openList)
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        height="28px"
        onClick={handleDrawerToggle}
      >
        <Typography variant="subtitle1" pl={4} sx={{ whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
        <Box
          onClick={handleDrawerToggle}
          sx={{ p: 0, color: COLORS.slateGray, height: '20px' }}
        >
          {openList && (
            <ArrowDropUpIcon fontSize='small'/>
          ) || (
            <ArrowDropDownIcon fontSize='small'/>
          )}
        </Box>
      </Stack>
      <Collapse orientation="vertical" in={openList} unmountOnExit>
        <FormGroup>
          {columns.map((column, index) => (
            <ColumnCheckbox key={`${column.field}_${index}` || `${column.title}_${index}`} column={column} onChange={onChange} />
          ))}
        </FormGroup>
      </Collapse>
    </>
  )
}
function SaveNewReportDialog({ onClose, setSelectedReport, tableSettings, setTableSettings, dateRange, refetch }) {
  const [reportName, setReportName] = useState(tableSettings.savedReportName || '')
  const { currentUser } = useCurrentUser()

  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()
  const [gqlSave] = useMutation(CREATE_SAVED_REPORT_MUTATION)
  const handleSave = useCallback(() => {
    setSubmitting(true)
    const mutation = {
      reportConfig: serializeSavedReportConfig(tableSettings, dateRange),
      name: reportName,
      userId: (currentUser as InitializedRecord).id,
    }

    gqlSave({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${reportName}".`})
      const savedReport = response.data.mutationResult.savedReport
      refetch()
      // Update query params to include the new saved report id and name
      setTableSettings(tableSettings => ({...tableSettings, savedReportName: savedReport.name, savedReportId: savedReport.id }))
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble creating "${reportName}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [tableSettings, dateRange, currentUser, reportName, gqlSave, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Save as New Report'
      description={<>Do you want to save the current <strong>filters</strong>, <strong>column arrangement</strong>, and <strong>table settings</strong> as a new Saved Report?</>}
      onCancel={onClose}
      onConfirm={handleSave}
      onClose={onClose}
      loading={submitting}
      disabled={reportName.length === 0}
      maxWidth='600px'
    >
      <TextField
        label={'Report Name'}
        value={reportName}
        onChange={(event) => setReportName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function SaveExistingReportDialog({ onClose, setSelectedReport, tableSettings, dateRange, refetch }) {
  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()
  const [gqlUpdate] = useMutation(UPDATE_SAVED_REPORT_MUTATION)

  const handleUpdate = useCallback(() => {
    setSubmitting(true)
    const mutation = {
      id: tableSettings.savedReportId,
      reportConfig: serializeSavedReportConfig(tableSettings, dateRange)
    }

    gqlUpdate({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${tableSettings.savedReportName}".`})
      const savedReport = response.data.mutationResult.savedReport
      refetch()
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble saving "${mutation.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [tableSettings, dateRange, gqlUpdate, addNotification, refetch, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Save Report'
      description={<>Do you want to save <strong>{tableSettings.savedReportName}</strong> with the current <strong>filters</strong>, <strong>column arrangement</strong>, and <strong>table settings</strong>?</>}
      onCancel={onClose}
      onConfirm={handleUpdate}
      onClose={onClose}
      loading={submitting}
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

export function ExportReportDialog({ onClose, selectedReport, tableSettings, handleExport }) {
  const [fileName, setFileName] = useState(tableSettings.savedReportName || selectedReport?.name || 'Performance')

  const handleConfirm = useCallback(() => {
    handleExport(fileName)
    onClose()
  }, [fileName, handleExport, onClose])

  return(
    <StandardDialog
      title='Export Performance Report'
      onCancel={onClose}
      onConfirm={handleConfirm}
      onClose={onClose}
      disabled={fileName.length === 0}
      maxWidth='600px'
    >
      <TextField
        label={'File Name'}
        value={fileName}
        onChange={(event) => setFileName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function RenameReportDialog({ onClose, selectedReport, setSelectedReport, setTableSettings, refetch }) {
  const [reportName, setReportName] = useState(selectedReport.name || '')

  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()

  const [gqlUpdate] = useMutation(UPDATE_SAVED_REPORT_MUTATION)

  const handleRename = useCallback(() => {
    setSubmitting(true)

    // Use selectedReport here to avoid updating properties besides the name (in case there are other changes presently)
    const mutation = {
      id: selectedReport.id,
      name: reportName
    }

    gqlUpdate({ variables: mutation }).then((response) => {
      addNotification({variant: 'notice', message: `Saved "${mutation.name}".`})
      const savedReport = response.data.mutationResult.savedReport
      refetch()
      // Update query params to include the new saved report id and name
      setTableSettings(tableSettings => ({...tableSettings, savedReportName: savedReport.name, savedReportId: savedReport.id }))
      setSelectedReport(savedReport)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble renaming "${selectedReport.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [selectedReport, reportName, gqlUpdate, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Rename Report'
      description={<>Do you want to rename <strong>{selectedReport.name}</strong>?</>}
      onCancel={onClose}
      onConfirm={handleRename}
      onClose={onClose}
      loading={submitting}
      disabled={reportName.length === 0 || reportName === selectedReport.name}
      maxWidth='600px'
    >
      <TextField
        label={'Report Name'}
        value={reportName}
        onChange={(event) => setReportName(event.target.value)}
        fullWidth
        required
        sx={{ pr: 7 }}
      />
    </StandardDialog>
  )
}

function DeleteReportDialog({ onClose, selectedReport, setSelectedReport, setTableSettings, refetch }) {
  const [submitting, setSubmitting] = useState(false)
  const { addNotification } = useNotifications()

  const [gqlDestroy] = useMutation(DESTROY_SAVED_REPORT_MUTATION)

  const handleDelete = useCallback(() => {
    setSubmitting(true)

    const mutation = { id: selectedReport.id }

    gqlDestroy({ variables: mutation }).then((response) => {
      const savedReport = response.data.mutationResult.savedReport
      addNotification({variant: 'notice', message: `Deleted "${savedReport.name}".`})
      refetch()
      // Remove saved report name and id from the query params
      setTableSettings(tableSettings => omit(tableSettings, ['savedReportId', 'savedReportName']))
      setSelectedReport(null)
    }).catch(_error => {
      addNotification({variant: 'alert', message: `Trouble deleting "${selectedReport.name}". Please try again later.`})
    }).finally(() => {
      onClose()
      setSubmitting(false)
    })
  }, [selectedReport.id, selectedReport.name, gqlDestroy, addNotification, refetch, setTableSettings, setSelectedReport, onClose])

  return(
    <StandardDialog
      title='Delete Report'
      description={<>Do you want to delete <strong>{selectedReport.name}</strong>?</>}
      onCancel={onClose}
      confirmText={'Delete'}
      onConfirm={handleDelete}
      onClose={onClose}
      loading={submitting}
      maxWidth='600px'
    >
    </StandardDialog>
  )
}

function SavedReportMenu({title, anchorEl, onClose, sidebarOpen, selectedReport, setSelectedReport, tableSettings, setTableSettings, dateRange, refetch, setExportOpen, revertToSelectedSavedReport}) {
  const [saveAsNewOpen, setSaveAsNewOpen] = useState(false)
  const [saveOpen, setSaveOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const location = useLocation()
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`
  const { addNotification } = useNotifications()

  const savedReportHasChanges = !currentSettingsMatchSelectedReport(selectedReport, tableSettings, dateRange)

  return(
    <>
      <Menu
        id={`${title}-control-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ sx:{ } }}
        anchorOrigin={{vertical: 0, horizontal: sidebarOpen ? 0 : 30}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: { width: 240, textOverflow: 'ellipsis', overflowX: 'hidden', overflowY: 'auto', whiteSpace: 'nowrap' },
        }}
      >
        <MenuItem onClick={() => {setSaveAsNewOpen(true); onClose()}}>Save as New Report</MenuItem>
        <MenuItem onClick={() => {setSaveOpen(true); onClose()}} disabled={!selectedReport || !savedReportHasChanges}>Save Report</MenuItem>
        <MenuItem onClick={() => {revertToSelectedSavedReport(); onClose()}} disabled={!selectedReport || !savedReportHasChanges}>Revert to Saved</MenuItem>
        <MenuItem onClick={() => {setExportOpen(true); onClose()}}>Export</MenuItem>
        <CopyToClipboard text={fullUrl}>
          <MenuItem onClick={() => {addNotification({variant: 'notice', message: `Link for "${tableSettings.savedReportName}" has been copied`}); onClose()}} disabled={!selectedReport}>Share</MenuItem>
        </CopyToClipboard>
        <MenuItem onClick={() => {setRenameOpen(true); onClose()}} disabled={!selectedReport}>Rename</MenuItem>
        <MenuItem onClick={() => {setDeleteOpen(true); onClose()}} disabled={!selectedReport}>Delete</MenuItem>
      </Menu>

      {saveAsNewOpen &&
        <SaveNewReportDialog onClose={() => setSaveAsNewOpen(false)} setSelectedReport={setSelectedReport} tableSettings={tableSettings} setTableSettings={setTableSettings} dateRange={dateRange} refetch={refetch} />
      }
      {saveOpen &&
        <SaveExistingReportDialog onClose={() => setSaveOpen(false)} setSelectedReport={setSelectedReport} tableSettings={tableSettings} dateRange={dateRange} refetch={refetch} />
      }
      {renameOpen &&
        <RenameReportDialog onClose={() => setRenameOpen(false)} selectedReport={selectedReport} setSelectedReport={setSelectedReport} setTableSettings={setTableSettings} refetch={refetch} />
      }
      {deleteOpen &&
        <DeleteReportDialog onClose={() => setDeleteOpen(false)} selectedReport={selectedReport} setSelectedReport={setSelectedReport} setTableSettings={setTableSettings} refetch={refetch} />
      }
    </>
  )
}


function TableSettingSection({title, open, icon, children, selectedReport, setSelectedReport, tableSettings, setTableSettings, dateRange, refetch, setExportOpen, revertToSelectedSavedReport}) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleMouseOver(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleMouseOff() {
    setAnchorEl(null);
  }

  const isSavedReportSection = title === 'Saved Reports'

  const [controlAnchorEl, setControlAnchorEl] = useState(null)

  function handleControlOpen(event) {
    if (controlAnchorEl !== event.currentTarget) {
      setControlAnchorEl(event.currentTarget);
    }
  }

  function handleControlClose() {
    setControlAnchorEl(null);
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1.5}
        sx={{ mt: 3.5, mb: '5px', mx: 3.5, '.MuiCollapse-root': { width: '100% !important', position: 'relative' } }}
      >
        <Box sx={{ color: COLORS.slateGray, height: '24px' }} onMouseOver={!open ? handleMouseOver : undefined}>{icon}</Box>
        {/* Category Header */}
        <Collapse orientation="horizontal" in={open} sx={{ '.MuiCollapse-root': {width: '100%'}, '.MuiCollapse-wrapperInner': { width: '100%' } }} unmountOnExit>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body1" sx={{ color: COLORS.slateGray, fontSize: 14, fontWeight: 600, whiteSpace: 'nowrap' }}>{title}</Typography>
            {isSavedReportSection && open &&
              <Box sx={{ position: 'absolute', right: '-5px', top: '-6px', backgroundColor: 'white' }}>
                <IconButton
                  onClick={handleControlOpen}
                  aria-label="Saved Reports Menu"
                  disabled={false}
                  sx={{
                    color: controlAnchorEl ? COLORS.frenchBlue : COLORS.slateGray,
                    width: SIDEBAR_ICON_SIZE,
                    height: SIDEBAR_ICON_SIZE
                  }}>
                  <MoreHorizIcon sx={{ }} />
                </IconButton>
              </Box>
            }
            <Divider sx={{ width: '100%', height: '1px', my: 'auto !important', borderColor: COLORS.mediumGray }} />
          </Stack>
        </Collapse>
        {/* END Category Header */}
      </Stack>
      {/* Sub Categories */}
      <Collapse orientation="horizontal" in={open} sx={{ '.MuiCollapse-wrapperInner': { width: '100%' } }} unmountOnExit>
        {children}
      </Collapse>
      {/* END Sub Categories */}

      {/* Collapsed Hover Menu */}
      <Menu
        id={`${title}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMouseOff}
        MenuListProps={{ onMouseLeave: handleMouseOff, sx:{ ml: '-6px' }  }}
        anchorOrigin={{vertical: -12, horizontal: -4}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: { width: 240, textOverflow: 'ellipsis', overflowX: 'hidden', overflowY: 'auto', whiteSpace: 'nowrap' },
        }}
      >
        <Stack direction="row" spacing={1.5} sx={{ position: 'relative' }}>
          <Typography variant="body1" sx={{ color: COLORS.slateGray, fontSize: 14, fontWeight: 600, pl: 4, pt: 1, pb: 1 }}>{title}</Typography>
          {isSavedReportSection &&
            <Box sx={{ position: 'absolute', right: '12px', top: '0px', backgroundColor: 'white' }}>
              <IconButton
                onClick={handleControlOpen}
                aria-label="Saved Reports Menu"
                disabled={false}
                sx={{
                  color: controlAnchorEl ? COLORS.frenchBlue : COLORS.slateGray,
                  width: SIDEBAR_ICON_SIZE,
                  height: SIDEBAR_ICON_SIZE
                }}>
                <MoreHorizIcon />
              </IconButton>
            </Box>
          }
          <Divider sx={{ width: '100%', height: '1px', my: 'auto !important', borderColor: COLORS.mediumGray }} />
        </Stack>
        {children}
      </Menu>
      {/* END Collapsed Hover Menu */}

      <SavedReportMenu title={title} anchorEl={controlAnchorEl} onClose={handleControlClose} sidebarOpen={open} selectedReport={selectedReport} setSelectedReport={setSelectedReport} tableSettings={tableSettings} setTableSettings={setTableSettings} dateRange={dateRange} refetch={refetch} setExportOpen={setExportOpen} revertToSelectedSavedReport={revertToSelectedSavedReport} />
    </>
  )
}

function CheckboxColumnLayout({titles, columns, onChange}) {
  const groupedColumns = groupBy(columns, 'subCategory', { asEntries: true })
  const [labelTitle, ...columnTitles] = titles

  return (
    <Box sx={{ px: 4 }}>
      <Grid container sx={{ height: '18px', overflow: 'hidden', alignItems: 'center'}}>
        <Grid item sx={{ mr: 'auto' }}>
          <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
            {labelTitle}
          </Typography>
        </Grid>
        {columnTitles.map((title) => (
          <Grid item key={`${title}`} sx={{ pl: '8px' }}>
            <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {groupedColumns.map(([subCategory, columns]) => {
        return(
          <Grid container key={`${subCategory}`} height="28px" alignItems='center' sx={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }} >
            <Grid item sx={{ mr: 'auto', justifyContent: 'flex-start' }}>
              <Typography variant="body2" sx={{ maxWidth: '94px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {subCategory}:
              </Typography>
            </Grid>
            {columnTitles.map((title, index) => {
              const isLast = index === columnTitles.length - 1

              return(
                <Grid item sx={{ mr: isLast ? '-7px' : undefined }}  key={`${subCategory}_${title}`}>
                  <UnlabeledCheckbox column={columns.find(c => c.fieldType == title.toLowerCase())} onChange={onChange}/>
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </Box>
  )
}

export const GET_SAVED_REPORTS_QUERY = gql(`
  query GetSavedReports($userId: ID!) {
    savedReports(userId: $userId) {
      ...SavedReportFields
    }
  }
`)

const CREATE_SAVED_REPORT_MUTATION = gql(`
  mutation CreateSavedReport(
    $name: String!
    $userId: ID!
    $reportConfig: SavedReportConfigInput!
  ) {
    mutationResult:createSavedReport (input: {
      name: $name
      userId: $userId
      reportConfig: $reportConfig
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

const UPDATE_SAVED_REPORT_MUTATION = gql(`
  mutation UpdateSavedReport(
    $id: ID!
    $name: String
    $userId: ID
    $reportConfig: SavedReportConfigInput
  ) {
    mutationResult:updateSavedReport (input: {
      id: $id
      name: $name
      userId: $userId
      reportConfig: $reportConfig
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

const DESTROY_SAVED_REPORT_MUTATION = gql(`
  mutation DestroySavedReport(
    $id: ID!
  ) {
    mutationResult:destroySavedReport (input: {
      id: $id
    }) {
      id
      errors
      savedReport {
        ...SavedReportFields
      }
    }
  }
`)

export function parseDateRange(dateRange) {
  if (typeof dateRange === 'string') {
    return { label: dateRange };
  } else if (Array.isArray(dateRange)) {
    return { timeRange: dateRange };
  } else {
    throw new Error("Invalid dateRange type: must be a string or an array");
  }
}

function SavedReportList({ savedReports, selectedReport, setSelectedReport, setTableSettings, setDateRange }) {
  const handleChange = useCallback(event => {
    const selectedId = event.target.value
    const savedReport = find(savedReports, { id: selectedId })

    setSelectedReport(savedReport)
    setDateRange(deserializeTimeRange(savedReport.reportConfig.timeRange))
    setTableSettings({
      ...deserializeSavedReportConfig(savedReport.reportConfig),
      savedReportName: savedReport.name,
      savedReportId: savedReport.id
    })
  }, [savedReports, setDateRange, setSelectedReport, setTableSettings])

  return (
    <>
      { savedReports.length === 0 &&
          <Typography variant="body2" sx={{ px: 4, color: COLORS.slateGray }}>
            No saved reports.
          </Typography>
      ||
        <RadioGroup>
          {savedReports.map(savedReport => (
            <LabeledInput
              key={savedReport.id}
              checked={selectedReport?.id === savedReport.id}
              label={savedReport.name}
              handleChange={handleChange}
              controlType={'radio'}
              value={savedReport.id}
            />
          ))}
        </RadioGroup>
      }
    </>
  )
}

function SettingSections({sectionTitle, IconComponent, setSelectedReport, tableSettings, lists, dataManager, selectedReport, handleChange, dateRange, setDateRange, setTableSettings, open, setExportOpen, revertToSelectedSavedReport}) {
  const { currentUser } = useCurrentUser()
  const { data: { savedReports }, refetch } = useSuspenseQuery(GET_SAVED_REPORTS_QUERY, { variables: { userId: (currentUser as InitializedRecord).id }})
  const sortedReports = sortBy(savedReports, 'name')

  // When the query params include a saved report, if the current user owns that report, then set that as the selected report
  useEffect(() => {
    const savedReport = find(sortedReports, { id: tableSettings.savedReportId })
    if(savedReport) {
      setSelectedReport(savedReport)
    }
  }, [tableSettings.savedReportId, sortedReports, setSelectedReport])

  return (
    <TableSettingSection title={sectionTitle} open={open} icon={<IconComponent/>} selectedReport={selectedReport} setSelectedReport={setSelectedReport} tableSettings={tableSettings} setTableSettings={setTableSettings} dateRange={dateRange} refetch={refetch} setExportOpen={setExportOpen} revertToSelectedSavedReport={revertToSelectedSavedReport}>
      {lists.map(({ key, title: listTitle }) => {
        const filteredColumns = dataManager.columns.filter((c: Column) => c.category === key)
        if (key === "savedReports") {
          return <SavedReportList key={key} savedReports={sortedReports} selectedReport={selectedReport} setSelectedReport={setSelectedReport} setTableSettings={setTableSettings} setDateRange={setDateRange} />
        } else if(filteredColumns.length === 0) {
          return null
        } else if (key === "metrics" || key === "performance") {
          return <CheckboxColumnLayout key={key} titles={listTitle} columns={filteredColumns} onChange={handleChange} />
        } else {
          return <CollapsibleFilterList key={key} title={listTitle} columns={filteredColumns} onChange={handleChange} />
        }
      })}
    </TableSettingSection>
  )
}

function SettingSectionsContainer({sectionTitle, IconComponent, setSelectedReport, tableSettings, lists, dataManager, selectedReport, handleChange, dateRange, setDateRange, setTableSettings, open, setExportOpen, revertToSelectedSavedReport}) {
  return (
    <Suspenseful
      component={SettingSections}
      sectionTitle={sectionTitle}
      IconComponent={IconComponent}
      setSelectedReport={setSelectedReport}
      tableSettings={tableSettings}
      lists={lists}
      dataManager={dataManager}
      selectedReport={selectedReport}
      handleChange={handleChange}
      dateRange={dateRange}
      setDateRange={setDateRange}
      setTableSettings={setTableSettings}
      open={open}
      setExportOpen={setExportOpen}
      revertToSelectedSavedReport={revertToSelectedSavedReport}
    />
  )
}

export default function PerformanceSidebar({ selectedReport, setSelectedReport, tableSettings, setTableSettings, dateRange, setDateRange, setExportOpen, revertToSelectedSavedReport, initiallyExpanded=null }) {
  const dataManager = useDataManager()
  const storageOpen = getStorage('PerformanceSidebar.open')
  const [open, setOpen] = useState((initiallyExpanded !== null ? initiallyExpanded : storageOpen !== undefined ? storageOpen : true))

  useEffect(() => {
    setStorage('PerformanceSidebar.open', open)
  }, [open])

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const handleChange = useCallback((event, column) => {
    const updatedColumns = dataManager.tableSettings.showAdjusted ? [column.field, `adjusted${upperFirst(column.field)}`] : [column.field]
    const visibleColumns = xor(dataManager.tableSettings.visibleColumns, updatedColumns)
    dataManager.setTableSettings({...dataManager.tableSettings, visibleColumns: visibleColumns})
  }, [dataManager])

  const drawer = (
    <Box sx={{ pb: 2 }}>
      <Tooltip title={open ? 'Collapse' : 'Expand'} >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            color: COLORS.slateGray,
            width: SIDEBAR_ICON_SIZE,
            height: SIDEBAR_ICON_SIZE,
            display: 'flex',
            alignItems: 'center',
            transform: 'rotateY(180deg)',
            position: 'absolute',
            top: "46px",
            right: "35.5px"
          }}
        >
          {open && (
            <CollapseIcon/>
          ) || (
            <ExpandIcon/>
          )}
        </IconButton>
      </Tooltip>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={'-3px'}
        p={'48px 24px 0 24px'}
      >
        <Collapse orientation="horizontal" in={open}>
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            Table Settings
          </Typography>
        </Collapse>
      </Stack>
      {SETTINGS_SECTIONS.map(({ title: sectionTitle, lists, icon: IconComponent }) => (
        <SettingSectionsContainer key={sectionTitle} sectionTitle={sectionTitle} IconComponent={IconComponent} setSelectedReport={setSelectedReport} tableSettings={tableSettings} lists={lists} dataManager={dataManager} selectedReport={selectedReport} handleChange={handleChange} dateRange={dateRange} setDateRange={setDateRange} setTableSettings={setTableSettings} open={open} setExportOpen={setExportOpen} revertToSelectedSavedReport={revertToSelectedSavedReport} />
      ))}
    </Box>
  )

  return(
    <Drawer
      variant='permanent'
      anchor='right'
      open={open}
      openWidth={DRAWER_WIDTH}
      closedWidth={DRAWER_WIDTH_CLOSED}
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'overlay',
        }
      }}
    >
      {drawer}
    </Drawer>
  )
}
