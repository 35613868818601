import { useRef, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

const useStyles = makeStyles(theme => ({
  hoverIcon: {
    '&:not(:hover)': {
      opacity: 0.5,
    },
  },
  iconButton: {
    padding: 0,
    marginBottom: '8px',
  },
  adornmentIconButton: {
    padding: 0,
  },
}));

export default function EditableField({value, setValue, onDelete = null, containerProps = {}, initiallyEditing = false, readOnly = false}) {
  const classes = useStyles()
  const [isEditing, setEditing] = useState(initiallyEditing)
  const [isUpdating, setUpdating] = useState(false)
  const [internalValue, setInternalValue] = useState(value)
  const inputRef = useRef()

  const handleChange = () => {
    if(internalValue === value) {
      setEditing(false)
    } else {
      setUpdating(true)
      Promise.resolve(setValue(internalValue)).finally(() => {
        setEditing(false)
        setUpdating(false)
      })
    }
  }

  const handleDelete = () => {
    setUpdating(true)
    Promise.resolve(onDelete()).finally(() => {
      setUpdating(false)
      inputRef.current && inputRef.current.focus()
    })
  }

  const preventBlur = event => {
    event.stopPropagation()
    event.preventDefault()
  }

  return (
    <Grid container {...containerProps}>
      {isEditing ? (
        <TextField
          autoFocus
          size="small"
          value={internalValue}
          disabled={isUpdating}
          inputRef={inputRef}
          onChange={event => setInternalValue(event.target.value)}
          onBlur={handleChange}
          onKeyPress={event => event.key === 'Enter' && handleChange()}
          placeholder="Group name"
          InputProps={{
            style:{backgroundColor: 'white'},
            endAdornment: onDelete && (
              <InputAdornment position="end">
                <IconButton
                  disabled={isUpdating}
                  className={classes.hoverIcon}
                  onMouseDown={preventBlur}
                  onClick={handleDelete}
                  classes={{root: classes.adornmentIconButton}}
                  size="large"
                >
                  <DeleteIcon fontSize="small"/>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <>
          <Grid>
            {value}
          </Grid>
          {!readOnly &&
            <Grid style={{marginLeft: 6}}>
              <IconButton
                disabled={isUpdating}
                className={classes.hoverIcon}
                onClick={() => setEditing(true)}
                classes={{root: classes.iconButton}}
                aria-label="Rename group"
                size="large"
              >
                <EditIcon fontSize="small"/>
              </IconButton>
            </Grid>
          }
        </>
      )}
    </Grid>
  );
}
