import { gql } from '@apollo/client';

export const FIELD_FIELDS = gql`
  fragment FieldFields on Field {
    id
    name
    fieldType
    options {
      text
      value
    }
  }
`
