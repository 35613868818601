export default [
  {
    "__typename": "Client",
    "id": "195",
    "internalName": null,
    "name": "YDD Filtered Client",
    "programGroups": [
      {
        "__typename": "ProgramGroup",
        "id": "240",
        "degreePrograms": [
          {
              "__typename": "DegreeProgram",
              "id": "696",
              "name": "YDD Filtered DegreeProgram"
          }
        ]
      }
    ]
  }
]
