import MenuItem from "@mui/material/MenuItem"
import { useCallback } from "react"
import LabelDropdown from "../LabelDropdown/LabelDropdown"

export function ReportingModeSelector({value, setValue, sx = {}}) {
  const handleChange = useCallback(event => setValue(event.target.value), [setValue])

  return (
    <LabelDropdown
      onChange={handleChange}
      value={value}
      sx={{
        minWidth: 97,
        ...sx
      }}
    >
      <MenuItem value={'cohort'}>Cohort</MenuItem>
      <MenuItem value={'snapshot'}>Snapshot</MenuItem>
    </LabelDropdown>
  )
}
