import TypeAheadDropDown from "components/UI/TypeAheadDropDown"
import { memo } from "react"
import { isEqual } from 'lodash-es'
import { isShallowEqual } from "lib/utils"
import { FormTextField } from "components/Formik/forms"
import { SUGGESTED_QUESTIONS } from "components/pages/LandingPages/data/constants"
import Grid from "@mui/material/Grid"


function QuestionKeyAndQuestionText({ question, onChange, typeAheadDropDownError, questionPath }) {
  return (
    <>
      <Grid item sm={4}>
        <TypeAheadDropDown
          value={question.key}
          onChange={onChange}
          suggestions={SUGGESTED_QUESTIONS}
          error={typeAheadDropDownError}
          label={"Question"}
        />
      </Grid>
      <Grid item sm={8}>
        <FormTextField
          id={"textQuestion" + question.key}
          data-lpignore="true"
          label="Question Text"
          name={`${questionPath}.text`}
        />
      </Grid>
    </>
  )
}

function propsAreEqual({ question: prevQuestion, ...prevProps },{ question: nextQuestion, ...nextProps }) {
  return isEqual(prevQuestion, nextQuestion) && isShallowEqual(prevProps, nextProps)
}

export default memo(QuestionKeyAndQuestionText, propsAreEqual)
