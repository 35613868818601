import MCheckbox from '@mui/material/Checkbox'

import CheckedImage from './assets/checkbox-checked.png'
import UncheckedImage from './assets/checkbox-unchecked.png'

export default function Checkbox(props) {
  return <MCheckbox
    size="small"
    icon={<img src={UncheckedImage} width={16} height={16}/>}
    checkedIcon={<img src={CheckedImage} width={16} height={16}/>}
    {...props}
  />
}
