import { useState } from "react";
import NestingMenu, {
  MenuCategory,
  MenuCheckbox,
  MenuItem,
} from "./NestingMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
import StarIcon from "@mui/icons-material/Star";
import Divider from "@mui/material/Divider";
import { userEvent, within, screen } from "@storybook/test";
import Box from "@mui/material/Box";
import { action } from "@storybook/addon-actions";

export default {
  title: "UI/NestingMenu",
  component: NestingMenu,
  parameters: {},
};

const Template = () => {
  const [checkedClients, setCheckedClients] = useState([]);
  const [checkedCampuses, setCheckedCampuses] = useState([]);

  const toggleClients = (...clients) => {
    setCheckedClients((currentClients) => {
      const otherClients = currentClients.filter((id) => !clients.includes(id));
      const isChecked = clients.every((id) => checkedClients.includes(id));
      return isChecked ? otherClients : [...otherClients, ...clients];
    });
  };

  const toggleCampuses = (...campuses) => {
    setCheckedCampuses((currentCampuses) => {
      const otherCampuses = currentCampuses.filter(
        (id) => !campuses.includes(id),
      );
      const isChecked = campuses.every((id) => checkedCampuses.includes(id));
      return isChecked ? otherCampuses : [...otherCampuses, ...campuses];
    });
  };

  return (
    <Box height="800px">
      <NestingMenu
        icon={<FilterListIcon />}
        onClose={action("On menu close handler")}
      >
        <MenuItem label="Client">
          <MenuCategory name="Favorites" icon={<StarIcon />} />
          <MenuItem
            label={
              <MenuCheckbox
                label="Something"
                checked={checkedClients.includes(1)}
                onChange={() => toggleClients(1)}
              />
            }
          />
          <MenuItem
            label={
              <MenuCheckbox
                label="Grand Canyon University (GCU)"
                checked={
                  checkedCampuses.includes(1) && checkedCampuses.includes(2)
                }
                onChange={() => toggleCampuses(1, 2)}
              />
            }
          >
            <MenuItem
              label={
                <MenuCheckbox
                  label="GCU Campus 1"
                  checked={checkedCampuses.includes(1)}
                  onChange={() => toggleCampuses(1)}
                />
              }
            />
            <MenuItem
              label={
                <MenuCheckbox
                  label="GCU Campus 2"
                  checked={checkedCampuses.includes(2)}
                  onChange={() => toggleCampuses(2)}
                />
              }
            />
          </MenuItem>

          <Divider />

          <MenuCategory name="All Clients" />
          <MenuItem
            label={
              <MenuCheckbox
                label="American College of Education (ACE)"
                checked={checkedClients.includes(2)}
                onChange={() => toggleClients(2)}
              />
            }
          />
          <MenuItem
            label={
              <MenuCheckbox
                label="American InterContinental University"
                checked={checkedClients.includes(3)}
                onChange={() => toggleClients(3)}
              />
            }
          />
          <MenuItem
            label={
              <MenuCheckbox
                label="American University"
                checked={checkedClients.includes(4)}
                onChange={() => toggleClients(4)}
              />
            }
          />
          <MenuItem
            label={
              <MenuCheckbox
                label="Bellamine"
                checked={checkedClients.includes(5)}
                onChange={() => toggleClients(5)}
              />
            }
          />
        </MenuItem>
        <MenuItem label="Program Group"></MenuItem>
      </NestingMenu>
    </Box>
  );
};

// Function to emulate pausing between interactions
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const Default = Template.bind({});
Default.args = {};
Default.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  // Note the use of canvasElement and screen, where screen must be used to find elements that don't exist on initial render
  await userEvent.click(canvas.getByTestId("filterMenu"));
  // Waiting for animation to complete to avoid small differences in placement in Chromatic
  await sleep(200);
  await userEvent.hover(screen.getByText("Client"));
  await sleep(200);
  await userEvent.hover(screen.getByText("Grand Canyon University (GCU)"));
  await sleep(200);
  await userEvent.click(screen.getByText("GCU Campus 1"));
  await sleep(200);
};
