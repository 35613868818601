import { useCallback, useMemo, useState } from "react"
import { useFormikContext } from "formik"
import CloseIcon from '@mui/icons-material/Close'
import { removeItemAtIndex, validateOrFixPositions } from '../../../data/utils'
import AnswerMatchingSubsection from './AnswerMatchingSubsection'
import QuestionBodySummary from "./QuestionBodySummary"
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import { ErrorWrapper } from "components/UI/StyledCard"


export default function QuestionSection({ clientFieldGroups, stepIndex, questionIndex, dragHandleProps }) {
  const { values, setFieldValue, errors } = useFormikContext()
  const [displayedOptionIndex, setDisplayedOptionIndex] = useState(0)
  const question = useMemo(() => values.steps[stepIndex].questions[questionIndex], [questionIndex, stepIndex, values.steps])

  const handleDeleteQuestion = useCallback(() => {
    const remainingQuestions = removeItemAtIndex(values.steps[stepIndex].questions, questionIndex)
    setFieldValue(`steps[${stepIndex}].questions`, validateOrFixPositions(remainingQuestions))
  }, [questionIndex, setFieldValue, stepIndex, values])

  const questionControls = useMemo(() => [
    // Disabled until complete
    // {
    //   icon: <SettingsIcon/>,
    //   onClick: () => { /* TODO */ },
    //   label: "Settings",
    // },
    {
      icon: <CloseIcon/>,
      onClick: handleDeleteQuestion,
      label: "Delete",
    },
  ], [handleDeleteQuestion])

  return (
    <Box role="group" aria-label={`Question ${question.position}`}>
      {question.position > 1 && <Divider light sx={{mx: 4}} />}
      <ErrorWrapper>
        <Box sx={{ display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
        >
          <SubsectionTitle
            title={`Question ${question.position}`}
            controls={questionControls}
            dragHandleProps={dragHandleProps}
          />
        </Box>
        <QuestionBodySummary
          question={question}
          clientFieldGroups={clientFieldGroups}
          stepIndex={stepIndex}
          questionIndex={questionIndex}
          displayedOptionIndex={displayedOptionIndex}
          setDisplayedOptionIndex={setDisplayedOptionIndex}
        />
      </ErrorWrapper>

      { question.options?.length > 0 && question.fieldMapping.length > 0 && (
        <>
          <Divider light sx={{mx: 4}} />
          <ErrorWrapper>
            <AnswerMatchingSubsection
              question={question}
              clientFieldGroups={clientFieldGroups}
              stepIndex={stepIndex}
              questionIndex={questionIndex}
              displayedOptionIndex={displayedOptionIndex}
              setDisplayedOptionIndex={setDisplayedOptionIndex}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </ErrorWrapper>
        </>
      )}
    </Box>
  )
}
