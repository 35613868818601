import { MockedProvider } from "@apollo/client/testing";
import HostnameProvider from "lib/HostnameProvider";
import {
  GET_LANDING_PAGE_QUERY,
  GET_CONTRACTS_QUERY,
  GET_FILTERED_LEADS_CLIENTS_QUERY,
  GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY,
} from "../../data/queries";
import LandingPageEdit from "./LandingPageEdit";
import SampleBrandedLandingPage from "test_support/data/SampleBrandedLandingPage";
import SampleContracts from "test_support/data/SampleContracts";
import SampleFilteredLeadsClients from "test_support/data/SampleFilteredLeadsClients";
import OutletContainer from "components/layout/OutletContainer/OutletContainer";

export default {
  title: "Pages/LandingPageEditSingle",
  component: LandingPageEdit,
};

const Template = (args, { parameters }) => {
  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <HostnameProvider
        portalHostname={"dev.yourdreamdegree.com"}
        defaultLeadsHostname='unknown'
        complyedHostname='unknown'
      >
        <OutletContainer>
          <LandingPageEdit {...args} />
        </OutletContainer>
      </HostnameProvider>
    </MockedProvider>
  );
};

export const Main = Template.bind({});
Main.args = {};

Main.parameters = {
  orbit: {
    user: {
      permitGlobalAdmin: true,
      accessibleVendorIds: ['1'],
    },
    records: [
      {
        type: "vendors",
        id: "1",
        attributes: {
          id: 1,
          name: "Cygnus Education",
        },
      },
    ],
  },
  reactRouter: {
    routePath: "/vendors/:vendorId/landing-pages/:landingPageId/*",
    routeParams: { vendorId: "1", landingPageId: "d5a493a9" },
    browserPath: "/vendors/1/landing-pages/d5a493a9/main",
  },
  mocks: [
    {
      request: {
        query: GET_LANDING_PAGE_QUERY,
        variables: {
          id: "d5a493a9",
        },
      },
      result: {
        data: {
          landingPage: SampleBrandedLandingPage,
        },
      },
    },
    {
      request: {
        query: GET_CONTRACTS_QUERY,
        variables: {
          clientIds: ["135"],
          vendorId: "1",
        },
      },
      result: {
        data: {
          contracts: SampleContracts,
        },
      },
    },
    {
      request: {
        query: GET_FILTERED_LEADS_CLIENTS_QUERY,
        variables: {},
      },
      result: {
        data: {
          filteredLeadsClients: SampleFilteredLeadsClients,
        },
      },
    },
    {
      request: {
        query: GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY,
        variables: { programGroupIds: ["240"], vendorId: "1" },
      },
      result: {
        data: {
          clientCampaigns: [
            {
              __typename: "ClientCampaign",
              id: "a9ba99e5",
              name: "YDD Filtered Campaign",
              campaignType: "shared",
              status: "active",
              programGroup: {
                __typename: "ProgramGroup",
                id: "240",
                description: "YDD Filtered ProgramGroup",
              },
            },
          ],
        },
      },
    },
  ],
};

// TODO: For questions page to work, we'll need a branded version of contracts

// export const Questions = Template.bind({})
// Questions.args = {};
// Questions.parameters = {
//   ...Main.parameters,
//   reactRouter: {
//     routePath: '/landing-pages/:landingPageId/*',
//     routeParams: { landingPageId: 'd5a493a9' },
//     browserPath: '/landing-pages/d5a493a9/questions',
//   },
// }

export const Rules = Template.bind({});
Rules.args = {};
Rules.parameters = {
  ...Main.parameters,
  reactRouter: {
    routePath: "/vendors/:vendorId/landing-pages/:landingPageId/*",
    routeParams: { vendorId: "1", landingPageId: "d5a493a9" },
    browserPath: "/vendors/1/landing-pages/d5a493a9/rules",
  },
};

export const Tracking = Template.bind({});
Tracking.args = {
  id: "d5a493a9",
  router: ["/landing-pages/:id/tracking"],
};
Tracking.parameters = {
  ...Main.parameters,
  reactRouter: {
    routePath: "/vendors/:vendorId/landing-pages/:landingPageId/*",
    routeParams: { vendorId: "1", landingPageId: "d5a493a9" },
    browserPath: "/vendors/1/landing-pages/d5a493a9/tracking",
  },
};
