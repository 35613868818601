import Dialog from '@mui/material/Dialog'

export default function MultiStepModalContainer({
  onClose,
  scroll,
  maxWidth = false,
  fullWidth = false,
  sx = {},
  children
}) {
  return (
    <Dialog
      sx={{
        '.MuiDialog-container': {
          alignItems: 'flex-start',
        },
        marginTop: 'max((50vh - 300px), 0px)',
        ...sx,
      }}
      aria-labelledby="modal-title"
      // @ts-ignore
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open
      onClose={onClose}
      scroll={scroll}
    >
        {children}
    </Dialog>
  )
}
