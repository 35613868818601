import Select from "@mui/material/Select"
import COLORS from "lib/colors"

export default function LabelDropdown({ onChange, sx={}, value=null, ...props }) {
  return (
    <Select
      {...props}
      onChange={onChange}
      value={value}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        PaperProps: {
          sx: {
            borderRadius: '10px',
            '.MuiList-root': {
              pt: 0,
              pb: 0
            },
            '.MuiMenuItem-root': {
              fontSize: '14px'
            },
          },
        },
      }}
      sx={{
        height: 28,
        fontSize: 14,
        borderRadius: '3px',
        backgroundColor: COLORS.aliceBlue,
        '.MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '.MuiSelect-select': {
          paddingLeft: '8px',
          pt: '5px',
          pb: '5px',
          textOverflow: 'clip !important'
        },
        '.MuiSelect-icon': {
          right: '2px'
        },
        ...sx
      }}
    />
  )
}
