import DialogContent from '@mui/material/DialogContent'
import MultiStepHeader from './MultiStepHeader'
import MultiStepModalContainer from './MultiStepModalContainer'

export default function MultiStepModal({
  onClose,
  showBackButton,
  onClickBackButton,
  modalTitle,
  scroll,
  maxWidth = false,
  fullWidth = false,
  children
}) {
  return (
    <MultiStepModalContainer
      onClose={onClose}
      scroll={scroll}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <MultiStepHeader
        modalTitle={modalTitle}
        onClose={onClose}
        showBackButton={showBackButton}
        onClickBackButton={onClickBackButton}
      />

      <DialogContent sx={{ py: '8px !important' }}>
        {children}
      </DialogContent>

    </MultiStepModalContainer>
  )
}
