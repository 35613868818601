import { useCallback, useState, useRef, useEffect, ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import { replaceElement, toggleList } from '../lib/utils'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'

import TextField from '@mui/material/TextField';
import { Field, RowFilter } from './PerformanceTable/TableSettings/types';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { PopoverProps } from '@mui/material';

const useStyles = makeStyles(_theme => ({
  container: {
    flex: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #c0c0c0',
    borderRadius: 20,
    padding: '2px 4px',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    marginRight: 4,
    padding: '0 6px',
    borderRadius: 20,
    '& $closeIcon': {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.5,
      },
    },
    whiteSpace: 'nowrap',
    margin: '2px',
  },
  closeIcon: {},
  addFilter: {
    backgroundColor: '#e0e0e0',
    padding: '0 6px',
    borderRadius: 20,
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '14px',
    border: 'none !important',
    outline: 'none !important',
    whiteSpace: 'nowrap',
    margin: '2px',
  },
  filterCategory: {
    fontWeight: 700,
    paddingRight: '4px',
  },
  filterValue: {
    cursor: 'pointer',
    userSelect: 'none',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    flex: 1,
    border: 'none',
    outline: 'none',
  },
}))

function renderValue(field?: Field, value: string) : ReactNode {
  return typeof(field?.render) === 'function' ? field.render(value) : (value || '(none)')
}

type FilterModalProps = {
  filter: RowFilter
  setFilter: (f: RowFilter) => void
  field?: Field
  open: boolean
  onClose: () => void
  onCancel: () => void
}

function FilterModal({filter, setFilter, field, open, onClose, onCancel} : FilterModalProps) {
  const [editingValue, setEditingValue] = useState(() => filter.values.join("\n"))

  const canSubmit = editingValue.length > 0

  const handleChange = useCallback(event => {
    const value = event.target.value
    setEditingValue(value)
    setFilter({ ...filter, values: value.match(/[^\s,]+/g) || [] })
  }, [filter, setFilter])

  const fieldName = field?.displayName || filter.field

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Filter By: {fieldName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Insert the {fieldName}s that you want to filter by. Values can be separated by spaces, new lines, or commas.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="subid"
          label={fieldName}
          value={editingValue}
          multiline
          fullWidth
          rows={8}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!canSubmit} onClick={onClose}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type FilterOptionsProps = {
  anchorEl: PopoverProps['anchorEl']
  filter: RowFilter
  setFilter: React.Dispatch<React.SetStateAction<RowFilter>>
  field: Field
  open: boolean
  onClose: () => void
}

function FilterOptions({anchorEl, filter, setFilter, field, open, onClose} : FilterOptionsProps) {
  const closeMenu = () => {
    onClose()
  }

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
      {field.values?.map(value => (
        <MenuItem key={value} style={{paddingTop: 2, paddingBottom: 2}}>
          <FormControlLabel
            control={
              <Checkbox
                style={{paddingTop: 2, paddingBottom: 2, paddingRight: 4}}
                checked={filter.values.includes(value)}
                onChange={event => { setFilter(filter => ({ ...filter, values: toggleList(filter.values, value, event.target.checked) })) }}
              />
            }
            label={renderValue(field, value)}
          />
        </MenuItem>
      ))}
    </Menu>
  )
}

type FilterProps = {
  filter: RowFilter
  field?: Field
  initiallyOpen?: boolean
  onChange: (f: RowFilter) => void
  onRemove: () => void
}

function Filter({ filter: initialFilter, field, initiallyOpen = false, onChange, onRemove }: FilterProps) {
  const classes = useStyles()
  const anchorEl = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [filter, setFilter] = useState(initialFilter)

  useEffect(() => {
    if(initiallyOpen) {
      setMenuOpen(true)
    }
  }, [initiallyOpen])

  const onClose = () => {
    setMenuOpen(false)

    if(filter.values.length === 0) {
      onRemove()
    } else {
      onChange(filter)
    }
  }

  const onCancel = () => {
    setMenuOpen(false)
    setFilter(initialFilter)
    if(initialFilter.values.length === 0) {
      onRemove()
    }
  }

  const handleRemove = () => {
    setMenuOpen(false)
    onRemove()
  }

  return (
    <div className={classes.filter} role="button">
      <div className={classes.filterCategory}>
        {field?.displayName || filter.field}:
      </div>
      <div className={classes.filterValue} onClick={() => setMenuOpen(!menuOpen)} ref={anchorEl}>
        {filter.values.map((value) => renderValue(field, value)).join(', ')}
      </div>
      <CloseIcon role="button" aria-label="close" className={classes.closeIcon} fontSize="inherit" onClick={handleRemove}/>

      {menuOpen && (
        field?.values && (
          <FilterOptions anchorEl={anchorEl.current} filter={filter} setFilter={setFilter} field={field} open={menuOpen} onClose={onClose}/>
        ) || (
          <FilterModal filter={filter} setFilter={setFilter} field={field} open={menuOpen} onClose={onClose} onCancel={onCancel}/>
        )
      )}
    </div>
  )
}

type AddFilterProps = {
  fields: Field[]
  addFilter: (f: Field) => void
}

function AddFilter({fields, addFilter}: AddFilterProps) {
  const classes = useStyles()
  const popupState = usePopupState({ variant: 'popover', popupId: 'addFilter' })

  return (
    <>
      <button className={classes.addFilter} {...bindTrigger(popupState)}>
        Add filter
        <KeyboardArrowDownIcon fontSize="inherit"/>
      </button>
      <Menu {...bindMenu(popupState)}>
        {fields.map(field => (
          <MenuItem key={field.name} onClick={() => {addFilter(field); popupState.close()}}>{field.displayName || field.name}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default function SweetFilter({fields, filters, setFilters}) {
  const classes = useStyles()
  const [newFilter, setNewFilter] = useState<RowFilter | null>(null)

  const addFilter = field => {
    setNewFilter({ field: field.name, values: [] })
  }

  const availableFields = fields.filter(field =>
    (!field.values || field.values.length > 0) && !filters.some(filter => filter.values.length && filter.field === field.name)
  )

  const fieldsByName = Object.fromEntries(fields.map(field => [field.name, field]))

  return (
    <div className={classes.container}>
      {filters.map(filter => (
        <Filter
          key={filter.field}
          filter={filter}
          field={fieldsByName[filter.field]}
          onChange={updatedFilter => setFilters(replaceElement(filters, filter, updatedFilter))}
          onRemove={() => setFilters(replaceElement(filters, filter))}
        />
      ))}
      {newFilter && (
        <Filter
          initiallyOpen
          filter={newFilter}
          field={fieldsByName[newFilter.field]}
          onChange={updatedFilter => { setFilters([...filters, updatedFilter]); setNewFilter(null) }}
          onRemove={() => setNewFilter(null)}
        />
      )}
      {availableFields.length > 0 && (
        <AddFilter fields={availableFields} addFilter={addFilter}/>
      )}
    </div>
  )
}
