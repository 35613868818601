import { createContext, ReactNode, useContext } from "react"
import { remote as defaultRemote, store as defaultStore } from 'lib/DataModel'
import { JSONAPISource } from '@orbit/jsonapi'
import { MemorySource } from '@orbit/memory'

type Orbit = {
  remote : JSONAPISource
  store : MemorySource
}

interface OrbitProviderProps {
  orbit?: Orbit
  children?: ReactNode
}

const OrbitContext = createContext<Orbit>({ remote: defaultRemote, store: defaultStore })

export default function OrbitProvider({ orbit = { remote: defaultRemote, store: defaultStore }, children }: OrbitProviderProps) {
  return (
    <OrbitContext.Provider value={orbit}>
      {children}
    </OrbitContext.Provider>
  )
}

export function useOrbit(): Orbit {
  return useContext(OrbitContext)
}
