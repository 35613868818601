import MTableCell from '@mui/material/TableCell'
import MTableHead from '@mui/material/TableHead'
import MTableRow from '@mui/material/TableRow'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import useStyles from './styles'
import FilterField from './FilterField'
import { useDataManager } from './DataManager';


function ColumnTitleAndSortIcon({ column }) {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <>
      {column.title}
      {dataManager.sortColumn === column && (
        <ChevronRightIcon className={`${classes.sortIcon} ${dataManager.sortOrder === 1 ? classes.sortIconAscending : classes.sortIconDescending}`}/>
      )}
    </>
  )
}

function TableCell({ column }) {
  const classes = useStyles()
  const dataManager = useDataManager()

  const handleSort = column => _event => {
    dataManager.sortBy(column)
  }

  return(
    <MTableCell className={classes.td} align={column.align} onClick={handleSort(column)} sx={{...column.sx}}>
      <ColumnTitleAndSortIcon column={column} />
    </MTableCell>
  )
}

export default function TableHead({hasRowActions = false, filtering = false}) {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <MTableHead className={classes.thead}>
      <MTableRow>
        <>
          {dataManager.getVisibleColumns().map((column, index) => (
            <TableCell key={`column${index}`} column={column} />
          ))}
          {hasRowActions && (
            <MTableCell className={classes.td}/>
          )}
        </>
      </MTableRow>
      {filtering && (
        <MTableRow>
          {dataManager.getVisibleColumns().map((column, index) => (
            <MTableCell className={classes.td} key={`${index}-${column.field}`} align={column.align} sx={column.sx}>
              <FilterField column={column}/>
            </MTableCell>
          ))}
          {hasRowActions && (
            <MTableCell className={classes.td}/>
          )}
        </MTableRow>
      )}
    </MTableHead>
  )
}
