import { useRef, useMemo, useEffect } from 'react'

// See https://gist.github.com/llamadeus/ff8ffe7ac156e545575dad81142f1b6f
export default function Suspender(): null {
  const resolve = useRef<() => void>()
  const promise = useMemo(() => new Promise<void>((res) => {
    resolve.current = res
  }), [])

  useEffect(() => {
    return () => {
      resolve.current?.()
    }
  })

  throw promise
}
