import { useState, useEffect, useCallback } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'

import AdCampaignSetForm from './AdCampaignSetForm'
import { getRemoteId } from '../../../lib/DataModel'
import makeStyles from '@mui/styles/makeStyles';
import { useOrbit } from 'providers/OrbitProvider'

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: 10,
    marginBottom: 30,
    padding: theme.spacing(2),
  },
  summary: {
    flex: 1,
  },
}))

export default function AdCampaignSet({adCampaignSet, onSave, client, onRemove}) {
  const classes = useStyles()
  const { store } = useOrbit()

  const [editing, setEditing] = useState(() => !getRemoteId(adCampaignSet))
  const [marketingPlatform, setMarketingPlatform] = useState()
  const [adAccount, setAdAccount] = useState()
  const [adCampaignIds, setAdCampaignIds] = useState([])

  const [selectAll, setSelectAll] = useState(true)

  const toggleEditing = useCallback(() => {
    setEditing(state => !state)
  }, [setEditing])

  const setInitialState = useCallback((adCampaignSet) => {
    const adAccount = store.cache.query(q => q.findRelatedRecord(adCampaignSet, 'adAccount'))
    const adCampaigns = store.cache.query(q => q.findRelatedRecords(adCampaignSet, 'adCampaigns'))

    setMarketingPlatform(store.cache.query(q => q.findRelatedRecord(adAccount, 'marketingPlatform')))
    setAdAccount(adAccount)
    setAdCampaignIds(adCampaigns.map(item => item.id))
    setSelectAll(adCampaigns.length === 0)
  }, [store])

  useEffect(() => {
    if(getRemoteId(adCampaignSet)) {
      setInitialState(adCampaignSet)
    }
  }, [adCampaignSet, setInitialState])

  return (
    <Box
      role="listitem"
      boxShadow={1}
      className={classes.box}
    >
      { editing ? (
        <AdCampaignSetForm
          adAccount={adAccount}
          adCampaignIds={adCampaignIds}
          adCampaignSet={adCampaignSet}
          client={client}
          marketingPlatform={marketingPlatform}
          onRemove={onRemove}
          onSave={onSave}
          selectAll={selectAll}
          setAdAccount={setAdAccount}
          setAdCampaignIds={setAdCampaignIds}
          setInitialState={setInitialState}
          setMarketingPlatform={setMarketingPlatform}
          setSelectAll={setSelectAll}
          toggleEditing={toggleEditing}
        />
      ) : (marketingPlatform && adAccount && adCampaignIds && (
        <Grid container direction="row">
          <Grid item className={classes.summary}>
            <Grid container direction='column'>
              <Grid item>
                Source: {marketingPlatform.attributes.typeName}
              </Grid>
              <Grid item>
                Ad Account: {adAccount.attributes.name}
              </Grid>
              <Grid item>
                Ad Campaigns: {selectAll ? "All" : adCampaignIds.length}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton aria-label="Manage integration" size="small" onClick={toggleEditing}>
              <EditIcon fontSize="small"/>
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}
