import { Snackbar, Box, IconButton } from '@mui/material'
import CheckCircleOutlineIcon  from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactNode } from 'react'
import { useNotifications, Notification } from '../../../lib/NotificationsProvider'
import COLORS from 'lib/colors';

interface NotificationControlsProps {
  actionComponent?: ReactNode,
  handleClose: () => void
}

function NotificationControls({ actionComponent, handleClose } : NotificationControlsProps) {
  return (
    <Box sx={{position: 'absolute', right: '24px'}}>
      {actionComponent}
      <IconButton onClick={handleClose} sx={{ padding: '3px', ml: 2, color: COLORS.charcoal }}>
        <CloseOutlinedIcon/>
      </IconButton>
    </Box>
  )
}

interface NotificationContentProps {
  notification: Notification,
  handleClose: () => void
}

export function NotificationContent({notification, handleClose} : NotificationContentProps) {
  const { message, variant, actionComponent } = notification

  let color, icon
  switch (variant) {
    case 'notice':
      color = 'success.main'
      icon = <CheckCircleOutlineIcon/>
      break
    case 'alert':
      color = 'error.main'
      icon = <ErrorOutlineIcon/>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        color: color,
        position: 'relative',
      }}
    >
      {icon}
      <Box component='span' sx={{height: 24, ml: 1}}>{message}</Box>
      {(actionComponent || notification.isPersistent) && <NotificationControls actionComponent={actionComponent} handleClose={handleClose} />}
    </Box>
  )
}

interface NotificationSnackbarProps {
  index: number,
  notification: Notification,
  hideDuration: number
}

export default function NotificationSnackbar({index, notification, hideDuration} : NotificationSnackbarProps) {
  const { removeNotification } = useNotifications()

  function handleClose() {
    removeNotification(index)
  }

  return (
    <Snackbar
      open={true}
      autoHideDuration={notification.isPersistent ? null : hideDuration}
      onClose={handleClose}
      message={
       <NotificationContent notification={notification} handleClose={handleClose}/>
      }
      sx={{
        position: 'static',
        transform: 'nil',
        height: 84,
        '.MuiSnackbarContent-root': {
          backgroundColor: 'white',
          color: 'black',
          width: '100%',
          borderRadius: 0,
          padding: 0,
        },
        '.MuiSnackbarContent-message': {
          fontSize: 18,
          fontWeight: 600,
          width: '100%',
          padding: '0',
          textAlign: 'center',
          div: {
            height: 84,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '.message-text': {
            height: 24,
            ml: 1
          }
        }
      }}
    />
  )
}
