import SvgIcon from "@mui/material/SvgIcon"

export default function IsNotBetweenIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path d="m13.156 5.313-.715 1.032c1.196 1.851 1.793 3.734 1.793 5.648 0 1.875-.625 3.773-1.875 5.695l.762 1.078-.422.434-.75-.961-.797.95-.433-.423.797-1.113c-1.204-1.773-1.805-3.59-1.805-5.45 0-1.077.137-2.046.41-2.905.273-.86.758-1.825 1.453-2.895l-.797-1.113.457-.446.774.915.703-.903.445.457zm-1.183 1.899c-.61 1.453-.914 3.011-.914 4.675 0 .993.07 1.885.21 2.678.141.793.364 1.56.668 2.303.36-.813.612-1.6.756-2.361.145-.762.217-1.655.217-2.678 0-1.594-.312-3.133-.937-4.617z" fill="currentColor" fillRule="nonzero"/>
              <path fill="currentColor" d="M16.25 5.347h1.35L7.6 18.5H6.25z"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
