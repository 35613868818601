import { getIn, useFormikContext } from 'components/Formik/forms'
import { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import { find, compact } from 'lodash-es'
import { useLandingPageStateContext } from '../../LandingPageProvider'
import { stripHTML } from 'lib/utils'
import { generateNewRecordId } from '../../../data/utils'
import ClientSelector from './ClientSelector'
import DropdownCheckbox from 'components/UI/DropdownCheckbox/DropdownCheckbox'
import { uniqBy } from "lodash-es"


function DegreeProgramSelector({ path, client}) {
  const { values, setFieldValue } = useFormikContext()
  const { landingPage } = useLandingPageStateContext()
  const selectedProgramIds = getIn(values, path)
  const degreeProgramsForClient = landingPage.leadTargets.filter(lt => lt.clientCampaign.programGroup.client.id === client.id).map(lt => lt.degreeProgram)
  const uniqueDegreeProgramsForClient = uniqBy(degreeProgramsForClient, 'id')

  const selectedDegreePrograms = uniqueDegreeProgramsForClient.filter(program => selectedProgramIds.includes(program.id))

  const setSelectedPrograms = useCallback((programs) => {
    setFieldValue(path, programs.map((program) => program.id))
  },[path, setFieldValue])


  return (
    <Grid item xs={4} key={"degree_program_set_program_selector" + client.id}>
      <DropdownCheckbox
        collection={uniqueDegreeProgramsForClient}
        selected={selectedDegreePrograms}
        setSelected={setSelectedPrograms}
        renderItem={(item) => stripHTML(item.name)}
        label={client.internal_name || client.name}
        InputLabelProps={{ shrink: true }}
        placeholder="Select programs"
        fullWidth
      />
    </Grid>
  )
}

export default function DegreeProgramSets({ path }) {
  const { values, setFieldValue } = useFormikContext()
  const degreeProgramSets = getIn(values, path, [])
  const { clients: allClients } = useLandingPageStateContext()
  const selectedClients = compact(degreeProgramSets.map(degreeProgramSet => allClients.find(c => c.id === degreeProgramSet.client.id))) || []

  const setSelectedClients = useCallback((clients) => {
    const clientIds = clients.map(client => client.id)
    const newDegreeProgramSets = clientIds.map((clientId) => {
      const existingSet = find(degreeProgramSets, function(set) { return set.client.id === clientId })
      if(existingSet) { return {...existingSet}}

      return {
        id: generateNewRecordId(),
        client: { id: clientId },
        degreeProgramIds: []
      }
    })
    setFieldValue(`${path}`, newDegreeProgramSets)
  },[path, setFieldValue, degreeProgramSets])


  return(
    <>
      <Grid item xs={4}>
        <ClientSelector
          selected={selectedClients}
          setSelected={setSelectedClients}
          path={path}
        />
      </Grid>
      {degreeProgramSets.map((set, index) => {
        const client = allClients.find(c => c.id === set.client.id)

        return client && (
          <DegreeProgramSelector
            key={`degree_program_selector_${set.id}`}
            client={client}
            path={`${path}[${index}].degreeProgramIds`}
          />
        )
      })}
    </>
  )
}
