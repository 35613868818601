import { ColumnFilter } from "components/MetricsTable/FilterOptions"
import { deepEqual } from "lib/utils"
import { decodeJson, encodeJson, QueryParamConfig } from "use-query-params"
import { RawTableSettings, RowFilter, TableSettings } from "./types"

function validateColumnList(columnNames : unknown) : string[] | null {
   if(Array.isArray(columnNames)) {
    return columnNames
  }

  return null
}

function validateString(toValidate : unknown) : string | null {
  if(typeof toValidate === 'string') {
    return toValidate
  }

  return null
}

function validateSortOrder(sortOrder : unknown) {
  if(typeof(sortOrder) === 'string') {
    if(sortOrder.toLowerCase() === 'asc') {
      return 1
    }
    if(sortOrder.toLowerCase() === 'desc') {
      return -1
    }
  }
  if(sortOrder === 1 || sortOrder === -1) {
    return sortOrder
  }
  return null
}

function validateReportingMode(reportingMode : unknown) {
  if(reportingMode === 'cohort' || reportingMode === 'snapshot') {
    return reportingMode
  }

  return null
}

function validateRowFilters(filters : unknown) : RowFilter[] | null {
  if(Array.isArray(filters)) {
    return filters
  }

  return null
}

function validateColumnFilters(filters : unknown) : Record<string, ColumnFilter> {
  // check that filters is an object containing entries with string keys and values (filters).  All filters contain
  // operator and value properties, with the latter being an array
  const isWellFormatted = filters && !!Object.entries(filters).length && Object.entries(filters).every(([columnField, filter]) => {
    return typeof columnField === 'string' &&
      Object.prototype.hasOwnProperty.call(filter, 'operator') &&
      Object.prototype.hasOwnProperty.call(filter, 'value') &&
      Array.isArray(filter.value)
  })
  if(isWellFormatted) {
    return filters as Record<string, ColumnFilter>
  }

  return {}
}

function validateShowAdjusted(showAdjusted : unknown) : boolean {
  return showAdjusted ? !!showAdjusted : false
}

export const TableSettingsAdapter : QueryParamConfig<RawTableSettings> = {
  encode: (tableSettings : TableSettings) => {
    return encodeJson({
      visibleColumns: tableSettings.visibleColumns,
      subtotalColumns: tableSettings.subtotalColumns,
      sortColumn: tableSettings.sortColumn,
      sortOrder: tableSettings.sortOrder,
      reportingMode: tableSettings.reportingMode,
      rowFilters: tableSettings.rowFilters,
      columnFilters: tableSettings.columnFilters,
      showAdjusted: tableSettings.showAdjusted,
      savedReportName: tableSettings.savedReportName ?? undefined,
      savedReportId: tableSettings.savedReportId ?? undefined,
    })
  },
  decode: (encoded : string) => {
    const decoded : Record<string,unknown> = decodeJson(encoded) || {}
    return {
      visibleColumns: validateColumnList(decoded.visibleColumns),
      subtotalColumns: validateColumnList(decoded.subtotalColumns),
      sortColumn: validateString(decoded.sortColumn),
      sortOrder: validateSortOrder(decoded.sortOrder),
      reportingMode: validateReportingMode(decoded.reportingMode),
      rowFilters: validateRowFilters(decoded.rowFilters),
      columnFilters: validateColumnFilters(decoded.columnFilters),
      showAdjusted: validateShowAdjusted(decoded.showAdjusted),
      savedReportName: validateString(decoded.savedReportName),
      savedReportId: validateString(decoded.savedReportId)
    }
  },
  equals: deepEqual,
}
