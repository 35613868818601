import Button from '@mui/material/Button'


export default {
  title: 'UI/Button',
  component: Button,
  parameters: {
    zeplinLink: "https://zpl.io/z8Bq4Ll",
  },
}

const Template = (args) => <Button {...args}>New</Button>

export const PrimaryDefault = Template.bind({})
PrimaryDefault.args = {
  disabled: false,
}

export const PrimaryDisabled = Template.bind({})
PrimaryDisabled.args = {
  disabled: true,
  disableElevation: true,
}

export const Small = Template.bind({})
Small.args = {
  ...PrimaryDefault.args,
  size: "small",
}

export const SecondaryDefault = Template.bind({})
SecondaryDefault.args = {
  disableElevation: true,
  variant: "text",
  color: 'secondary',
  disabled: false,
}

export const SecondaryDisabled = Template.bind({})
SecondaryDisabled.args = {
  ...SecondaryDefault,
  disabled: true,
}
