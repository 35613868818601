import React, { ReactNode, useContext } from 'react'
import CurrentUserResource from 'resources/CurrentUserResource'
import { InitializedRecord } from '@orbit/records';
import { getIn } from 'components/Formik/forms';
import { useOrbit } from 'providers/OrbitProvider';


type ContractWithNames = {
  id: string
  type: string
  clientId: string
  clientName: string
  vendorId: string
  vendorName: string
}

interface CurrentUserProviderBaseProps {
  children?: ReactNode
}

interface CurrentUserProviderResourceProps extends CurrentUserProviderBaseProps {
  currentUserResource: CurrentUserResource
  override: Record<string,never>
}

interface CurrentUserProviderOverrideProps extends CurrentUserProviderBaseProps {
  currentUserResource?: never
  override: {
    currentUser: InitializedRecord
    currentOrganization?: InitializedRecord
    accessibleClients?: InitializedRecord[]
    accessibleVendorContracts?: InitializedRecord[]
    accessibleClientContracts?: InitializedRecord[]
    accessibleVendors?: InitializedRecord[]
  }
}

type CurrentUserProviderProps = CurrentUserProviderResourceProps | CurrentUserProviderOverrideProps

const CurrentUserContext = React.createContext<{
  currentUser?: InitializedRecord;
  currentOrganization?: InitializedRecord;
  accessibleClients: InitializedRecord[];
  accessibleVendorContracts: InitializedRecord[];
  accessibleClientContracts: InitializedRecord[];
  accessibleVendors: InitializedRecord[];
  accessibleVendorContractsWithNames: ContractWithNames[];
}>({
  currentUser: undefined,
  currentOrganization: undefined,
  accessibleClients: [],
  accessibleVendorContracts: [],
  accessibleClientContracts: [],
  accessibleVendors: [],
  accessibleVendorContractsWithNames: [],
});

export default function CurrentUserProvider({ children, currentUserResource, override = {}} : CurrentUserProviderProps) {
  const { store } = useOrbit()

  const currentUser = override.currentUser || (currentUserResource as CurrentUserResource).get()
  const currentOrganization = override.currentOrganization || store.cache.query(q => q.findRelatedRecord(currentUser, 'organization'))
  const accessibleClients = override.accessibleClients || store.cache.query(q => q.findRelatedRecords(currentUser, 'clients')) || []
  const accessibleVendorContracts = override.accessibleVendorContracts || store.cache.query(q => q.findRelatedRecords(currentUser, 'vendorContracts')) || []
  const accessibleClientContracts = override.accessibleClientContracts || store.cache.query(q => q.findRelatedRecords(currentUser, 'clientContracts')) || []
  const accessibleVendors = override.accessibleVendors || store.cache.query(q => q.findRelatedRecords(currentUser, 'vendors')) || []
  const accessibleVendorContractsWithNames = accessibleVendorContracts.map(contract => {
    return {
      id: getIn(contract, 'keys.remoteId'),
      type: 'contract',
      clientId: getIn(contract, 'relationships.client.data.id'),
      clientName: getIn(store.cache.query(q => q.findRelatedRecord(contract, 'client')), 'attributes.name'),
      vendorId: getIn(contract, 'relationships.vendor.data.id'),
      vendorName: getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name'),
    }
  })

  return (
    <CurrentUserContext.Provider value={{ currentUser, currentOrganization, accessibleClients, accessibleVendorContracts, accessibleClientContracts, accessibleVendors, accessibleVendorContractsWithNames }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export function useCurrentUser() {
  return useContext(CurrentUserContext)
}
