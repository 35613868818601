import SvgIcon from "@mui/material/SvgIcon"

export default function LessThanOrEqualIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path fill="currentColor" d="M7 17.85V16.5h9.35v1.35z"/>
              <path d="M16.324 5.707v1.441l-6.41 3.235 6.41 3.176V15l-9.293-4.617z" fill="currentColor" fillRule="nonzero"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
