import { FormControlLabel, Radio } from "@mui/material";
import COLORS from "lib/colors";


export default function RadioButton({ value, label, checked, onChange = null, inputProps = null, sx={}, error=false}) {

  return (
    <FormControlLabel
      sx={{ display: 'flex',
            alignItems: 'flex-start',
            fontSize: '16px',
            fontWeight: 'normal',
            color: COLORS.charcoal,
            margin: '0 0 0 -8px',
            ...sx,
            '.MuiFormControlLabel-label': {
                  color: error ? COLORS.copperRed : null,
                  paddingBottom: '4px',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1,
                  paddingTop: '4px',
                }
         }}
      control={
        <Radio
          checked={checked}
          onChange={onChange}
          value={value}
          inputProps={inputProps}
          sx={{ marginTop: -1,
                marginRight: '4px',
                padding: '5px',
                color: error ? COLORS.copperRed : null,
              }}
        />
      }
      label={
        label
      }
    />
  )
}
