import { useMemo } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import MPopover from '@mui/material/Popover'

import Checkbox from './Checkbox'
import Reorderable, { ReorderableHandle } from '../Reorderable'

import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 20px',
    userSelect: 'none',
  },
  menuOption: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 18,
    fontWeight: 600,
    borderBottom: '1px solid #e6e6e6',
    padding: 10,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  menuOptionClickable: {
    cursor: 'pointer',
  },
  grabber: {
    width: 30,
  },
  checkbox: {
    width: 30,
  },
  submenuCaret: {
    position: 'absolute',
    right: 10,
  },
}))

function MenuOption({menuOption, isReorderable, isChecked, onChange, render, renderSubmenu, entireOptionClickable}) {
  const classes = useStyles()
  const handleChange = useMemo(() => onChange(menuOption), [menuOption, onChange])
  const popupState = usePopupState({ variant: 'popover', popupId: 'checkboxSubmenu' })

  return (
    <div className={clsx({[classes.menuOption]: true, [classes.menuOptionClickable]: entireOptionClickable})} role="menuitem" {...(renderSubmenu && bindHover(popupState))} onClick={entireOptionClickable && handleChange || null}>
      {isReorderable && (
        <div className={classes.grabber}>
          {isReorderable(menuOption) && (
            <ReorderableHandle/>
          )}
        </div>
      )}
      <div className={classes.checkbox}>
        <Checkbox
          style={{padding: 0}}
          checked={isChecked(menuOption)}
          onChange={handleChange}
        />
      </div>
      {render(menuOption)}
      {renderSubmenu && (
        <>
          <div className={classes.submenuCaret}>
            <ArrowRightIcon/>
          </div>
          {renderSubmenu({...bindPopover(popupState), parentMenuOption: menuOption, anchorOrigin: { horizontal: 'right', vertical: 'top' }, transformOrigin: { horizontal: 'left', vertical: 'top' }})}
        </>
      )}
    </div>
  )
}

export default function CheckboxMenu({
  menuOptions = [],
  reorderable = false,
  updatePosition = () => {},
  isChecked = () => false,
  isReorderable = null,
  onChange = () => {},
  render = menuOption => menuOption,
  renderSubmenu = null,
  Popover = MPopover,
  ...popoverProps
}) {
  if(!reorderable) {
    isReorderable = null
  }

  const classes = useStyles()

  return (
    <Popover
      role="menu"
      {...popoverProps}
    >
      <div className={classes.root}>
        {reorderable && (
          <DndProvider backend={HTML5Backend}>
            {menuOptions.map((menuOption, index) => (
              <Reorderable key={index} object={menuOption} type="MenuOption" updatePosition={updatePosition}>
                <MenuOption {...{menuOption, isReorderable, isChecked, onChange, render, renderSubmenu}}/>
              </Reorderable>
            ))}
          </DndProvider>
        ) || (
          menuOptions.map((menuOption, index) => (
            <MenuOption key={index} entireOptionClickable={false} {...{menuOption, isReorderable, isChecked, onChange, render, renderSubmenu}}/>
          ))
        )}
      </div>
    </Popover>
  )

  if(reorderable) {
    return (
      <div className={classes.root}>
      </div>
    )
  }
  else {
    return (
      <div className={classes.root}>
      </div>
    )
  }
}
