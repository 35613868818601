import { createContext, useContext, useState, useReducer, useEffect } from 'react'

import { compareCells, rawCell } from './render'
import { isPresent, isPromise } from '../../lib/utils'
import { omit } from 'lodash-es'

function toNumericSortOrder(sortOrder) {
  if(typeof(sortOrder) === 'string') {
    if(sortOrder.toLowerCase() === 'asc') {
      return 1
    }
    if(sortOrder.toLowerCase() === 'desc') {
      return -1
    }
  }
  if(sortOrder === 1 || sortOrder === -1) {
    return sortOrder
  }
  return 1
}

function extractDefaultFilters(columns) {
  return Object.fromEntries(columns
    .filter(column => column.defaultFilter)
    .map(column => [column.field, column.defaultFilter])
  )
}

function isValidFilter(filter) {
  if(!filter || !filter.operator || !filter.value) {
    return false
  }

  switch(filter.operator) {
    case 'between':
    case 'not-between':
      return isFinite(parseFloat(filter.value[0])) && isFinite(parseFloat(filter.value[1]))
    default:
      return isPresent(filter.value[0])
  }
}

export class DataManager {
  constructor(columns, dataOrPromiseOrFn, options, onChange, onChangeColumns) {
    this.columns                 = columns
    this.dataOrPromiseOrFn       = dataOrPromiseOrFn
    this.options                 = options
    this.onChangeCallback        = onChange
    this.onChangeColumnsCallback = onChangeColumns
    this.sortColumn              = options.sortColumn && columns.find(column => options.sortColumn === column.field)
    this.sortOrder               = toNumericSortOrder(options.sortOrder)
    this.paginated               = !!options.paginated
    this.hasRowActions           = !!options.hasRowActions
    this.page                    = 1
    this.pageSize                = this.paginated ? (options.pageSize || 10) : null
    this.data                    = []
    this.filters                 = extractDefaultFilters(columns)
    this.totalCount              = 0
    this.loading                 = false
    this.error                   = null
    this.version                 = 0

    this.reload()
  }

  setPageSize(pageSize) {
    if(this.paginated) {
      this.pageSize = pageSize
      this.page = 1
      this.reload()
    }
  }

  setPage(page) {
    if(this.paginated) {
      this.page = page
      this.reload()
    }
  }

  onChange() {
    if(this.onChangeCallback) {
      this.version++
      this.onChangeCallback()
    }
  }

  onChangeColumns() {
    if(this.onChangeColumnsCallback) {
      this.onChangeColumnsCallback(this.columns)
    }
    this.onChange()

    // TODO: Not sure if this is is necessary, might be worth verifying with a test if we don't have one
    // If the sort column is no longer in this.columns, try to reset it from this.options.sortColumn
    if(this.sortColumn && !this.columns.includes(this.sortColumn)) {
      this.sortColumn = this.options.sortColumn && this.columns.find(column => this.options.sortColumn === column.field)
    }

    // If any additional promise-based lookups were added, resolve them.
    // TODO: this should set the loading state on the table, which it currently doesn't do.
    const lookupPromises = this.getLookupPromises()
    if(lookupPromises.length > 0) {
      Promise.all(lookupPromises).then(() => this.onChange())
    }
  }

  getLookupPromises() {
    return this.columns.filter(column => isPromise(column.lookup)).map(column => (
      column.lookup.then(lookup => {
        // TODO: can we avoid modifying columns here (since columns is a passed-in value)?
        column.lookup = lookup
      })
    ))
  }

  reload(forceReload = false) {
    if(this.loading && !forceReload) {
      return
    }

    this.loading = true
    this.error   = null

    this.onChange()

    const dataOrPromiseOrFn = this.dataOrPromiseOrFn
    const filters = this.filters
    const isStale = () => (dataOrPromiseOrFn !== this.dataOrPromiseOrFn) || (filters !== this.filters)
    const dataFn = typeof(this.dataOrPromiseOrFn) === 'function' ? this.dataOrPromiseOrFn : () => this.dataOrPromiseOrFn
    const dataPromise = Promise.resolve(dataFn(this.getDataOptions()))
    const dataAndLookupsPromise = Promise.all([dataPromise, ...this.getLookupPromises()])

    dataAndLookupsPromise
      .then(([data, ..._lookups]) => {
        if(isStale()) {
          return
        }
        if(typeof(data) === 'object' && data.data instanceof Array) {
          this.data = data.data
          this.totalCount = data.totalCount ? data.totalCount : this.data.length
        } else if(typeof(data) === 'object' && data instanceof Array) {
          this.data = data
          this.totalCount = data.length
        } else {
          this.data = []
          this.totalCount = 0
        }
        if(this.sortColumn) {
          this.data.sort((row1, row2) => this.sortOrder * compareCells(rawCell(row1, this.sortColumn), rawCell(row2, this.sortColumn)))
        }
      })
      .catch(error => {
        if(isStale()) {
          return
        }
        this.data  = []
        this.totalCount = 0
        this.error = error
      })
      .finally(() => {
        if(isStale()) {
          this.reload(true)
          return
        }
        this.loading = false
        this.onChange()
      })
  }

  getVisibleColumns() {
    return this.columns.filter(column => column.visible)
  }

  getColSpan() {
    return this.getVisibleColumns().length + (this.hasRowActions ? 1 : 0)
  }

  setColumns(columns) {
    const oldColumns = this.columns
    this.columns = columns
    if(oldColumns !== columns) {
      this.onChangeColumns()
    }
  }

  setData(dataOrPromiseOrFn) {
    if(dataOrPromiseOrFn !== this.dataOrPromiseOrFn) {
      this.dataOrPromiseOrFn = dataOrPromiseOrFn
      this.reload()
    }
  }

  setFilter(field, filter) {
    if(isValidFilter(filter)) {
      this.filters = {...this.filters, [field]: filter}
    } else {
      this.filters = omit(this.filters, [field])
    }

    if(this.paginated) {
      this.page = 1
      this.reload()
    } else {
      this.onChange()
    }
  }

  hasFilter(column) {
    return isValidFilter(this.filters[column.field])
  }

  sortBy(column) {
    if(this.loading || this.error) {
      return
    }

    if(this.sortColumn !== column) {
      this.sortColumn = column
      this.sortOrder = 1
    }
    else {
      this.sortOrder = -this.sortOrder
    }

    if(this.paginated) {
      this.reload()
    } else {
      this.data = this.data.sort((row1, row2) => this.sortOrder * compareCells(rawCell(row1, column), rawCell(row2, column)))
      this.onChange()
    }
  }

  getDataOptions() {
    if(this.paginated) {
      return {
        sortColumn: this.sortColumn && this.sortColumn.field || null,
        sortOrder:  this.sortColumn && this.sortColumn.field ? (this.sortOrder === 1 ? 'asc' : this.sortOrder === -1 ? 'desc' : null) : null,
        page:       this.page,
        pageSize:   this.pageSize,
        filters:    Object.entries(this.filters).filter(([_field, filter]) => isPresent(isValidFilter(filter))).map(([field, filter]) => ({field, ...filter}))
      }
    }
  }

  group(ungroupedData) {
    // groupingColumns is the groupable prefix of visible columns
    const groupingColumns = this.getVisibleColumns().reduce((list, column, idx) => list.length === idx && column.groupable ? [...list, column] : list, [])

    const groups = groupingColumns.length === 0 ? [] : {}
    ungroupedData.forEach(row => {
      const group = groupingColumns.reduce((group, col, j) => {
        const val = "x" + rawCell(row, col) // prefix the key with a letter, because Object.keys() does not respect insertion order when the keys are numeric
        return group[val] ? group[val] : group[val] = (j === groupingColumns.length - 1 ? [] : {})
      }, groups)
      group.push(row)
    })

    return this.populateFromGroups(groups)
  }

  populateFromGroups(groups) {
    if(groups instanceof Array) {
      return groups.map((row) => ({row, rowSpans: [], skipColumns: []}))
    } else {
      let data = []
      Object.values(groups).forEach(subgroups => {
        const subresult = this.populateFromGroups(subgroups)
        data.push(...subresult.map(({row, rowSpans, skipColumns}, index) => ({row, rowSpans: [subresult.length, ...rowSpans], skipColumns: [index > 0, ...skipColumns]})))
      })
      return data
    }
  }
}

const DataManagerContext = createContext(null)

export function DataManagerProvider({columns, data, options, children, onChange, onChangeColumns, dataManagerRef}) {
  let [dataManager, setDataManager] = useState(null)
  const [state, dispatch] = useReducer(_state => ({}), {})
  useEffect(() => {
    onChange && onChange()
  }, [onChange, state])

  if(!dataManager) {
    setDataManager(dataManager = new DataManager(columns, data, options, dispatch, onChangeColumns))
  }

  if(dataManagerRef) {
    dataManagerRef.current = dataManager
  }
  dataManager.setColumns(columns)
  dataManager.setData(data)

  return (
    <DataManagerContext.Provider value={{dataManager, state}}>
      {children}
    </DataManagerContext.Provider>
  )
}

export function useDataManager() {
  return useContext(DataManagerContext).dataManager
}
