import MTableCell from '@mui/material/TableCell'
import MTableHead from '@mui/material/TableHead'
import MTableRow from '@mui/material/TableRow'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useStyles from './styles'
import { Box, Chip, Stack } from '@mui/material';
import { useReducer, useRef, useState } from 'react';
import { bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import ColumnSettingMenu from './ColumnSettingMenu';
import FilterOptions, { ColumnFilter, newColumnFilter, getAvailableFiltersForColumn, Filter, isNullaryOperator } from './FilterOptions';
import { useFeatures } from 'providers/FeaturesProvider';
import { useDataManager } from './DataManager';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import COLORS from 'lib/colors';
import { isPresent, titlecase } from 'lib/utils';


type ReducerState = {
  columnFilter: ColumnFilter,
  visible: {
    columnMenuIcon: boolean,
    settingsMenu: boolean,
    filterOptions: boolean,
  },
  enabled: {
    subtotal: boolean,
  }
}

type ReducerAction = {
  type: 'SHOW_FILTER_OPTIONS' | 'HIDE_FILTER_OPTIONS' | 'TOGGLE_SETTINGS_MENU',
} | {
  type: 'APPLY_FILTER'
  payload: ColumnFilter
}


function tableCellReducer(state: ReducerState, action: ReducerAction) {
  const newState = { ...state }

  switch (action.type) {
    case 'SHOW_FILTER_OPTIONS':
      newState.visible.filterOptions = true
      newState.visible.columnMenuIcon = true
      break
    case 'HIDE_FILTER_OPTIONS':
      newState.visible.filterOptions = false
      newState.visible.columnMenuIcon = false
      break
    case 'TOGGLE_SETTINGS_MENU':
      newState.visible.settingsMenu = !state.visible.settingsMenu
      newState.visible.columnMenuIcon = newState.visible.settingsMenu
      break
    case 'APPLY_FILTER':
      newState.columnFilter = action.payload
      newState.visible.filterOptions = false
      newState.visible.columnMenuIcon = false
      break
  }

  return newState
}

function getInitialState() {
  return {
    columnFilter: newColumnFilter(),
    visible: {
      columnMenuIcon: false,
      settingsMenu: false,
      filterOptions: false,
    },
    enabled: {
      subtotal: false,
    }
  }
}

function ColumnTitleAndSortIcon({ column }) {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <>
      {column.title}
      {dataManager.tableSettings.sortColumn === column.field && (
        <ChevronRightIcon className={`${classes.sortIcon} ${dataManager.tableSettings.sortOrder === 1 ? classes.sortIconAscending : classes.sortIconDescending}`}/>
      )}
    </>
  )
}

function TableCell({ column }) {
  const [state, dispatch] = useReducer(tableCellReducer, null, getInitialState);
  const classes = useStyles()
  const dataManager = useDataManager()
  const menuPopupState = usePopupState({ variant: 'popover', popupId: 'columnSettings' })
  const filterPopupState = usePopupState({ variant: 'popover', popupId: 'filterOptions' })
  const { isFeatureFlagEnabled } = useFeatures()
  const headMenu = isFeatureFlagEnabled('performance_table_column_menu')
  const anchorEl = useRef(null)
  const showMeatBallMenuIcon = state.visible.columnMenuIcon || state.visible.filterOptions
  const columnFilter = dataManager.tableSettings.columnFilters[column.field] || newColumnFilter()
  const hasColumnFilters = isPresent(dataManager.tableSettings.columnFilters)

  const handleSort = column => _event => {
    dataManager.sortBy(column)
  }

  return(
    <MTableCell className={classes.td} align={headMenu ? "left" : column.align} sx={{p: '0px !important', ...column.sx}}>
      <Box onClick={handleSort(column)} sx={{borderBottom: '2px solid #e0e0e0'}}>
        {headMenu && (
          <Stack direction="row" justifyContent="space-between" alignItems="end" sx={{p: '6px 0px 6px 13px', postion: 'relative',
            '&:not(:hover) .showHamburgerOnHover': {visibility: showMeatBallMenuIcon ? undefined : 'hidden'}
          }}>
            <Box sx={{ mr: '15px' }}>
              <ColumnTitleAndSortIcon column={column} />
            </Box>

            <Box className='showHamburgerOnHover' onClick={(e) => { e.stopPropagation(); dispatch({ type: 'TOGGLE_SETTINGS_MENU' })}} sx={{ pr: '3px', height: '20px', mb: '-2.5px' }} >
              <MoreHorizIcon fontSize='small' {...bindTrigger(menuPopupState)} aria-label='Column Settings' ref={anchorEl}/>

              <ColumnSettingMenu menuPopupState={menuPopupState} filterPopupState={filterPopupState} showSettingsMenu={state.visible.settingsMenu} dispatch={dispatch} column={column}/>
            </Box>

            {state.visible.filterOptions &&
              <FilterOptions column={column} popupState={filterPopupState} anchorEl={anchorEl} onClose={() => dispatch({ type: 'HIDE_FILTER_OPTIONS' })} onApply={(columnFilter) => dispatch({ type: 'APPLY_FILTER', payload: columnFilter })} existingColumnFilter={columnFilter} />
            }
          </Stack>
        ) || (
          <ColumnTitleAndSortIcon column={column} />
        )}
      </Box>

      {hasColumnFilters &&
        <FilterSummary column={column}/>
      }
    </MTableCell>
  )
}

function getChipValue(filter: Filter) {
  if(isNullaryOperator(filter.operator)) {
    return titlecase(filter.operator)
  }

  const value = filter.value

  switch (true) {
    case (value.length === 1):
      return value[0]
    case (value.length === 2):
      return `${value[0]}-${value[1]}`
    case (value.length > 2):
      return value.join(", ")
    default:
      // this should never happen
      return ''
  }
}

function FilterSummary({ column }) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const [filterOpen, setFilterOpen] = useState(false)

  const columnFilter = dataManager.tableSettings.columnFilters[column.field]
  const filters = getAvailableFiltersForColumn(column)
  const Icon = columnFilter ? filters[columnFilter.operator]?.[1] : null

  const filterPopupState = usePopupState({ variant: 'popover', popupId: 'filterOptions' })
  const anchorEl = useRef(null)

  return (
    <Box className={classes.td} sx={{p: '0px !important', height: '28px'}} onClick={() => setFilterOpen(true)} ref={anchorEl}>
      <Stack
        sx={{
          height: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: '2px solid #e0e0e0',
          p: '0px 2px',
          '& svg': {
            color: filterOpen ? `${COLORS.frenchBlue} !important` : COLORS.slateGray,
          },
          '& svg.hoverableIcon': {
            color: 'white',
          },
          '&:hover': {
            '& svg.hoverableIcon': {
              color: filterOpen ? `${COLORS.frenchBlue} !important` : COLORS.slateGray
            },
          },
        }}
      >
        {columnFilter &&
          <>
            {Icon && <Icon/>}
            <Chip
              color="default"
              label={getChipValue(columnFilter)}
              size="small"
              sx={{
                fontWeight: 400,
                alignSelf: 'center',
                maxWidth: '100px',
              }}
              variant="filled"
            />
          </>
          ||
          <FilterAltOutlinedIcon className={'hoverableIcon'} fontSize="small"/>
        }
      </Stack>

      {filterOpen &&
        <FilterOptions column={column} popupState={filterPopupState} anchorEl={anchorEl} onClose={() => setFilterOpen(false)} onApply={() => setFilterOpen(false)} existingColumnFilter={{operator:columnFilter?.operator || '', value:columnFilter?.value || []}} horizontalAlign='left'/>
      }
    </Box>
  )
}

export default function TableHead() {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <MTableHead className={classes.thead}>
      <MTableRow>
        {dataManager.getVisibleColumns().map((column, index) => (
          <TableCell key={`column${index}`} column={column} />
        ))}
      </MTableRow>
    </MTableHead>
  )
}
