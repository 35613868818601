import { getIn, useFormikContext } from 'components/Formik/forms'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { OPERATORS } from 'components/pages/LandingPages/data/constants'


export default function RuleOperator({ path, ...props }) {
  const { values, setFieldValue } = useFormikContext()

  const handleOperatorSelect = event => {
    setFieldValue(path, event.target.value)
  }

  return (
    <TextField
      select
      value={OPERATORS.find(operator => operator.id === getIn(values, path))?.id || ''}
      onChange={handleOperatorSelect}
      label='Operator'
      id={`rule_operator_select_${Math.random()}`}
      fullWidth
      {...props}
    >
      {OPERATORS.map(operator => (
        <MenuItem value={operator.id} key={`rule_operator_${operator.id}`}>{operator.value}</MenuItem>
      ))}
    </TextField>
  )
}
