import { useCallback, useContext, useEffect, useMemo } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useMutation, useSuspenseQuery } from "@apollo/client"
import { useFormikContext } from "formik"
import { Box } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SectionTitle from "components/UI/SectionTitle"
import { useNotifications } from "lib/NotificationsProvider"
import Suspenseful from '../../../../../Suspenseful'
import StepCard from './StepCard'
import { useBookends } from "lib/BookendsProvider"
import { validateOrFixPositions, addItemAtIndex, removeItemAtIndex, changeItemIndex, getErrorMessage } from "../../../data/utils"
import FormWrapper from "components/Formik/FormWrapper"
import { buildStepMutation, UPDATE_LANDING_PAGE_MUTATION } from "components/pages/LandingPages/data/mutations"
import { GET_CONTRACTS_QUERY } from "components/pages/LandingPages/data/queries"
import { questionsSchema } from "components/pages/LandingPages/data/yupSchemas"
import { useCurrentUser } from "lib/CurrentUserProvider"
import { getRemoteId } from "lib/DataModel"
import { useLandingPageActionsContext, useLandingPageStateContext } from "../../LandingPageProvider"
import { getClientIdsFromLandingPage } from "components/pages/LandingPages/data/helpers"
import { NavigationContext } from "../Navigation/LandingPageEditNavigation"
import { useOrbit } from "providers/OrbitProvider"


function QuestionsPageForm({ clientFieldGroups }) {
  const { dirty, isSubmitting, submitForm, resetForm, errors, values, setFieldValue } = useFormikContext()
  const { showPrompt } = useContext(NavigationContext)
  const { setBookends } = useBookends()

  const handleAddStep = useCallback(() => {
    const newStep = {
      id: null,
      position: null,
      title: null,
      questions: [],
    }
    const stepsWithNewStep = addItemAtIndex(values.steps, newStep)

    setFieldValue('steps', validateOrFixPositions(stepsWithNewStep))
  }, [setFieldValue, values.steps])

  const handleDeleteStep = useCallback(( stepIndex ) => {
    const remainingSteps = removeItemAtIndex(values.steps, stepIndex)
    setFieldValue('steps', validateOrFixPositions(remainingSteps))
  }, [setFieldValue, values.steps])

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: "secondary",
            label: "Cancel",
            onClick: () => {
              showPrompt().then(() => resetForm())
            },
          },
          {
            type: "primary",
            label: "Add New Step",
            onClick: handleAddStep,
          },
          {
            type: "primary",
            label: isSubmitting ? 'Please wait...' : 'Save',
            disabled: !dirty || isSubmitting,
            onClick: submitForm,
          },
        ],
      },
      state: {
        form: {
          dirty: dirty,
          errors: errors,
        },
      },
    })
  }, [dirty, errors, isSubmitting, handleAddStep, setBookends, submitForm, resetForm, showPrompt])

  const onDragEnd = useCallback(( { destination, source } ) => {
    if(!destination) { return }

    const oldQuestionIndex = source.index
    const newQuestionIndex = destination.index
    const oldStepIndex = source.droppableId.substr(5)
    const newStepIndex = destination.droppableId.substr(5)

    if(newStepIndex === oldStepIndex) {
      if(newQuestionIndex === oldQuestionIndex) { return }

      const reorderedQuestions = changeItemIndex(values.steps[oldStepIndex].questions, oldQuestionIndex, newQuestionIndex)
      setFieldValue(`steps[${oldStepIndex}].questions`, validateOrFixPositions(reorderedQuestions))
    } else {
      const movedQuestion = values.steps[oldStepIndex].questions[oldQuestionIndex]
      const questionsWithoutMovedQuestion = removeItemAtIndex(values.steps[oldStepIndex].questions, oldQuestionIndex)
      const questionsWithMovedQuestion = addItemAtIndex(values.steps[newStepIndex].questions, movedQuestion, newQuestionIndex)

      setFieldValue(`steps[${newStepIndex}].questions`, validateOrFixPositions(questionsWithMovedQuestion))
      setFieldValue(`steps[${oldStepIndex}].questions`, validateOrFixPositions(questionsWithoutMovedQuestion))
    }
  }, [setFieldValue, values.steps])

  return (
    <Box sx={{ marginTop: "24px" }}>
      <SectionTitle
        title='Questions'
        controls={[{icon: <AddIcon/>, onClick: handleAddStep, label: 'Add'}]}
        sx={{mb: '12px'}}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        {values.steps.map((_step, index) =>
          <StepCard
            key={`step_${index}`}
            handleDeleteStep={() => handleDeleteStep(index)}
            clientFieldGroups={clientFieldGroups}
            stepIndex={index}
          />
        )}
      </DragDropContext>
    </Box>
  )
}

function QuestionsPage({ landingPage, clientFieldGroups }) {
  const { setLandingPage } = useLandingPageActionsContext()
  const { addNotification } = useNotifications()
  const [gqlUpdateSteps] = useMutation(UPDATE_LANDING_PAGE_MUTATION)

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const { createSteps, updateSteps, destroySteps } = buildStepMutation(landingPage.steps, values.steps)

    gqlUpdateSteps({ variables:
      { id: landingPage.id,
        createSteps: createSteps,
        updateSteps: updateSteps,
        destroySteps: destroySteps,
      }
    }).then(({ data }) => {
      setLandingPage(data.mutationResult.landingPage)
      addNotification({variant: 'notice', message: "Your changes have been saved."})
    }).catch(error => {
      addNotification({variant: 'alert', message: getErrorMessage(error)})
      console.log(error)
    }).finally(() => {
      setSubmitting(false)
    })
  }, [addNotification, gqlUpdateSteps, landingPage.steps, landingPage.id, setLandingPage])

  return (
    <FormWrapper data={landingPage} schema={questionsSchema} onSubmit={handleSubmit}>
      <QuestionsPageForm
        clientFieldGroups={clientFieldGroups}
      />
    </FormWrapper>
  )
}
// TODO: fix arbitrary selection of client / vendor
function GetClientsInfo() {
  const { landingPage } = useLandingPageStateContext()

  const { currentUser } = useCurrentUser()
  const { store } = useOrbit()
  const vendorId = getRemoteId(store.cache.query(q => q.findRelatedRecords(currentUser, 'vendors'))[0])
  const clientIdList = getClientIdsFromLandingPage(landingPage)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: clientData } = useSuspenseQuery(GET_CONTRACTS_QUERY, { variables: { clientIds: clientIdList, vendorId: vendorId }, fetchPolicy: 'network-only'})
  const clientFieldGroups = useMemo(() => clientData.contracts, [clientData.contracts])

  return (
    <DndProvider backend={HTML5Backend}>
      <QuestionsPage
        landingPage={landingPage}
        clientFieldGroups={clientFieldGroups}
      />
    </DndProvider>
  )
}

export default function QuestionsPageWrapper() {
  return (
    <Suspenseful component={GetClientsInfo} />
  )
}
