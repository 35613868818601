import React from "react";
import { useArgs } from "@storybook/preview-api";

import Dropdown from "./";

export default {
  title: "UI/Dropdown",
  component: Dropdown,
  parameters: {
    zeplinLink: "https://zpl.io/V1LeYkB",
  },
  argTypes: {
    selectFirstByDefault: {
      control: false,
    },
    loading: {
      control: false,
    },
    disabled: {
      control: false,
    },
  },
};

const Template = (args) => {
  const [, updateArgs] = useArgs();
  const setItem = (item) => updateArgs({ ...args, item: item });

  return <Dropdown setItem={setItem} {...args} />;
};

export const Default = Template.bind({});
Default.args = {
  label: "Vendor",
  collection: [
    { id: 1, value: "Cygnus" },
    { id: 2, value: "Fake Vendor 1" },
    { id: 3, value: "Fake Vendor 2" },
  ],
  renderItem: (item) => item.value,
};

export const DefaultSelected = Template.bind({});
DefaultSelected.args = {
  ...Default.args,
  selectFirstByDefault: true,
};

export const Loading = Template.bind({});
Loading.args = {
  ...Default.args,
  loading: true,
};

export const Disabled = Template.bind({});
Disabled.args = {
  ...Default.args,
  disabled: true,
};
