import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useCallback } from 'react'
import { PersistedErrorHandler } from 'components/Formik/forms'


export default function FreeTextMultiSelect({label, value, setValue, path = '', disabled = false, options = [], placeholder = '', error = false, required = false, sx= {} }) {
  const handleChange = useCallback((_e, value) => {
    setValue && setValue(value)
  }, [setValue])

  const handlePaste = (e) => {
    e.preventDefault()
    const pastedValue = e.clipboardData.getData('text/plain')
    const trimmedText = pastedValue.trim().replace(/\s\s+/g, ' ')
    const valueWithoutPastedValue = value.filter(entry => entry !== pastedValue)

    if (trimmedText.includes("\n")) {
        setValue(valueWithoutPastedValue.concat(trimmedText.split("\n").map(v => v.trim())))
    } else if (trimmedText.includes(",")) {
      setValue(valueWithoutPastedValue.concat(trimmedText.split(",").map(v => v.trim())))
    } else if (trimmedText.includes(" ")) {
        setValue(valueWithoutPastedValue.concat(trimmedText.split(" ")))
    } else {
        setValue(valueWithoutPastedValue.concat(pastedValue))
    }
  }

  return (
    <Autocomplete
      multiple
      id={`${label}_${Math.random()}`}
      options={options}
      value={value}
      freeSolo
      onChange={handleChange}
      autoSelect
      sx={{
        '.MuiAutocomplete-inputRoot': {minHeight: '48px'},
        '.MuiAutocomplete-input': {paddingY: '2.5px !important'},
        width: '100%',
        ...sx
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="default"
            size="small"
            variant="filled"
            label={option}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
      renderInput={(params) => {
        const textField = (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            error={error}
            onPaste={handlePaste}
          />
        )
        if(path.length > 0) {
          return (
            <PersistedErrorHandler name={path}>
              {textField}
            </PersistedErrorHandler>
          )
        } else {
          return textField
        }
      }}
    />
  )
}
