import StandardDialog from "components/UI/Dialog/StandardDialog"
import { useHostname } from "lib/HostnameProvider"

interface creativePreviewDialogProps {
  onCancel: (() => void),
  creativeUrlPath?: string,
  creativeType: string | unknown,
  cid: string | number | undefined
}

export function CreativePreviewDialog({onCancel, creativeUrlPath, creativeType, cid}: creativePreviewDialogProps) {
  /* eslint-disable react/no-unescaped-entities */
  const { complyedHostname } = useHostname()
  const creativeUrl = `https://${complyedHostname}${creativeUrlPath}`

  return (
    <StandardDialog
      title={`View Creative #${cid}`}
      onCancel={onCancel}
      cancelText=''
      sx={{
        '.MuiDialogContent-root': {
          backgroundColor: 'black',
          textAlign: 'center',
        },
        '.MuiDialogTitle-root, .MuiDialogActions-spacing': {
          height: '60px'
        },
        '.MuiPaper-root': {
          maxWidth: '840px'
        }
      }}
    >
      {creativeType == 'Video Ad' && (
        <video controls width="600" height="450">
          <source src={`${creativeUrl}`} />
          Sorry, your browser doesn't support embedded videos,
          <a href={creativeUrl}>download</a> and watch with your favorite video player!
        </video>
      ) || creativeType == 'Image Ad' && (
        <img src={creativeUrl} />
      )}
    </StandardDialog>
  )
}
