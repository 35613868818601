import { Typography } from "@mui/material";

export default {
  title: "UI/Typography",
  component: Typography,
  argTypes: {
    variant: {
      control: "select",
      options: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "status",
        "table",
        "modal",
        "body1",
        "body2",
        "subtitle1",
        "subtitle2",
        "subtitle3",
        "button",
      ],
    },
    color: {
      control: "select",
      options: [
        "default",
        "primary",
        "secondary",
        "error",
        "warning.main",
        "success.main",
        "info.main",
      ],
    },
    fontWeight: {
      control: {
        type: "select",
        labels: {
          500: "normal",
          600: "semi-bold",
          700: "bold",
        },
      },
      options: ["default", 500, 600, 700],
    },
    align: {
      control: "select",
      options: ["left", "center", "right"],
    },
  },
};

const Template = (args) => <Typography {...args}></Typography>;

export const Default = Template.bind({});
Default.args = {
  children: "Sample text",
};

export const H1 = Template.bind({});
H1.storyName = "H1";
H1.args = {
  variant: "h1",
  children: "Title Page / Modal",
};

export const H2 = Template.bind({});
H2.storyName = "H2";
H2.args = {
  variant: "h2",
  children: "Title Section / Card",
};

export const H3 = Template.bind({});
H3.storyName = "H3";
H3.args = {
  variant: "h3",
  children: "Title Added Item",
};

export const H4 = Template.bind({});
H4.storyName = "H4";
H4.args = {
  variant: "h4",
  children: "Title Modal Subsection",
};

export const H5 = Template.bind({});
H5.storyName = "H5";
H5.args = {
  variant: "h5",
  children: "Title Card Subsection",
};

export const StatusError = Template.bind({});
StatusError.storyName = "Status - Error";
StatusError.args = {
  variant: "status",
  color: "error",
  children: "Error Text / Static Notification",
};

export const StatusWarning = Template.bind({});
StatusWarning.storyName = "Status - Warning";
StatusWarning.args = {
  variant: "status",
  color: "warning.main",
  children: "Warning Text / Static Notification",
};

export const StatusSuccess = Template.bind({});
StatusSuccess.storyName = "Status - Success";
StatusSuccess.args = {
  variant: "status",
  color: "success.main",
  children: "Success Text / Static Notification",
};

export const TableTotal = Template.bind({});
TableTotal.storyName = "Table Total";
TableTotal.args = {
  variant: "table",
  fontWeight: "700",
  children: "Table Total",
};

export const TableTotalNumber = Template.bind({});
TableTotalNumber.storyName = "Table Total (number)";
TableTotalNumber.args = {
  variant: "table",
  fontWeight: "700",
  align: "right",
  children: "Table Total (number)",
};

export const Table = Template.bind({});
Table.storyName = "Table Body";
Table.args = {
  variant: "table",
  children: "Table Body",
};

export const TableNumber = Template.bind({});
TableNumber.storyName = "Table Body (number)";
TableNumber.args = {
  variant: "table",
  align: "right",
  children: "Table Body (number)",
};

export const Modal = Template.bind({});
Modal.args = {
  variant: "modal",
  children: "Copy Modal",
};

export const Body1 = Template.bind({});
Body1.args = {
  variant: "body1",
  children: "Copy Normal",
};

export const Body1Placeholder = Template.bind({});
Body1Placeholder.storyName = "Body 1 (Placeholder)";
Body1Placeholder.args = {
  variant: "body1",
  color: "info.main",
  children: "Copy (Placeholder)",
};

export const Body1Upload = Template.bind({});
Body1Upload.storyName = "Body 1 (Upload)";
Body1Upload.args = {
  variant: "body1",
  color: "primary",
  children: "Copy (Upload)",
};

export const Body2 = Template.bind({});
Body2.args = {
  variant: "body2",
  children: "Copy Table / Dropdown Checklist, Label Filter",
};

export const Body2Note = Template.bind({});
Body2Note.storyName = "Body 2 (Note)";
Body2Note.args = {
  variant: "body2",
  color: "info.main",
  children: "Note",
};

export const Subtitle1 = Template.bind({});
Subtitle1.args = {
  variant: "subtitle1",
  children: "Label",
};

export const Subtitle2 = Template.bind({});
Subtitle2.args = {
  variant: "subtitle2",
  children: "Label Graph",
};

export const Subtitle3 = Template.bind({});
Subtitle3.args = {
  variant: "subtitle3",
  children: "Label Small",
};

// UNUSED
export const Button = Template.bind({});
Button.storyName = "UNUSED - Button (Primary)";
Button.args = {
  variant: "button",
  color: "white",
  children: "Button Text (Primary)",
};

// UNUSED
export const ButtonSecondary = Template.bind({});
ButtonSecondary.storyName = "UNUSED - Button (Secondary)";
ButtonSecondary.args = {
  variant: "button",
  color: "secondary",
  children: "Button Text (Secondary)",
};

// UNUSED
export const ButtonSpecialNavSelected = Template.bind({});
ButtonSpecialNavSelected.storyName =
  "UNUSED - Button (Special) / Nav (Selected)";
ButtonSpecialNavSelected.args = {
  variant: "button",
  color: "primary",
  children: "Button (Special) / Nav (Selected)",
};

// UNUSED
export const ButtonDisabledNavUnselected = Template.bind({});
ButtonDisabledNavUnselected.storyName =
  "UNUSED - Button (Disabled) / Nav (Unselected)";
ButtonDisabledNavUnselected.args = {
  variant: "button",
  color: "info.main",
  children: "Button (Disabled) / Nav (Unselected)",
};
