import { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useFormikContext, FormTextField, getIn } from 'components/Formik/forms'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { changeItemIndex, removeItemAtIndex, validateOrFixPositions } from '../../../data/utils'
import StyledCard, { ErrorWrapper } from 'components/UI/StyledCard'
import SubsectionTitle from 'components/UI/SubsectionTitle.jsx/SubsectionTitle'
import EmptyStateSection from './EmptyStateSection'
import ActionType from './ActionType'
import COLORS from '../../../../../../lib/colors'
import { RuleCondition } from './CampaignSelectionRules'
import { buildRuleCondition } from 'components/pages/LandingPages/data/helpers'


function LandingPageRule({ path, index, title, dragHandleProps }) {
  const { values, setFieldValue } = useFormikContext()

  const rule = getIn(values, path)

  const handleDeleteLandingPageExclusionRule = useCallback(() => {
    const updatedRules = removeItemAtIndex(values.landingPageRules, index)
    setFieldValue('landingPageRules', validateOrFixPositions(updatedRules))
  }, [index, setFieldValue, values.landingPageRules])

  const handleAddCondition = useCallback(() => {
    setFieldValue(`${path}.conditions[${rule.conditions.length}]`, buildRuleCondition())
  }, [rule?.conditions?.length, path, setFieldValue])

  return(
    <ErrorWrapper>
      <Stack direction='column' spacing={2} marginBottom={1} role="listitem" aria-label={`Rule ${index+1}`} aria-roledescription="Landing Page Exclusion Rule">
        <SubsectionTitle
          title={title}
          controls={[
            { icon:  <AddIcon/>, label: 'Add Condition', onClick: handleAddCondition },
            { icon:  <CloseIcon/>, label: 'Delete Landing Page Exclusion Rule', onClick: handleDeleteLandingPageExclusionRule }
          ]}
          dragHandleProps={dragHandleProps}
        />
        {rule?.conditions?.map((_, index) =>
          <RuleCondition rulePath={path} index={index} key={`landing_page_rule_condition_${index}`}/>
        )}
        <ActionType path={path} />
      </Stack>
    </ErrorWrapper>
  )
}

function LandingPageRulesSection() {
  const { values, setFieldValue } = useFormikContext()
  const landingPageRules = values.landingPageRules || []

  const onDragEnd = useCallback(( { destination, source } ) => {
    if(!destination) { return }

    const oldIndex = source.index
    const newIndex = destination.index

    if(newIndex === oldIndex) { return }

    const reorderedRules = changeItemIndex(values.landingPageRules, oldIndex, newIndex)
    setFieldValue('landingPageRules', validateOrFixPositions(reorderedRules))
  },[setFieldValue, values.landingPageRules])

  return(
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`landing_page_rules_droppable`} key={`landing_page_rules_droppable`}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Stack
              direction="column"
              divider={<Divider light flexItem sx={{ mx: 4 }}/>}
              role="list"
              aria-roledescription="Landing Page Exclusion Rules"
            >
              {landingPageRules.length === 0 &&
                <EmptyStateSection message="No portal exclusion rules." />
              }
              {landingPageRules.map((rule, index) =>
                <Draggable
                  draggableId={`draggable_landing_page_rule_${rule.id}`}
                  key={`draggable_landing_page_rule_${rule.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      sx={{
                        ...(snapshot.isDragging === true && {
                          backgroundColor: COLORS.backgroundGray
                        })
                      }}
                      {...provided.draggableProps}

                      ref={provided.innerRef}
                    >
                      <LandingPageRule
                        path={`landingPageRules[${index}]`}
                        key={`landing_page_rule_${rule.id}`}
                        index={index} title={`Rule ${index + 1}`}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </Box>
                  )}
                </Draggable>
              )}
            </Stack>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default function LandingPageExclusionRules({ handleAddLandingPageExclusionRule }) {
  return (
    <StyledCard
      title='Landing Page Exclusion Rules'
      note='Excluding programs removes those selected from available results. Rules applied in arranged order.'
      controls={[
        {icon: <AddIcon/>, label: 'Add Landing Page Exclusion Rule', onClick: handleAddLandingPageExclusionRule}
      ]}
      isSubsection
      hasStickyHeader
      role="group"
    >
      <ErrorWrapper>
        <Stack direction='column' spacing={2}>
          <SubsectionTitle
            title="Default Redirect URL"
          />
          <FormTextField label="Redirect URL" name="variables.redirectUrlFailure" />
        </Stack>
      </ErrorWrapper>

      <Divider light sx={{ mx: 4 }}/>

      <LandingPageRulesSection/>
    </StyledCard>
  )
}
