import MTableBody from '@mui/material/TableBody'
import MTableCell from '@mui/material/TableCell'
import MTableRow from '@mui/material/TableRow'

import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import clsx from 'clsx'
import useStyles from './styles'
import HoverableTableCell from './HoverableTableCell'
import { renderCell, renderSummary } from './render'
import { useDataManager } from './DataManager'

function EmptyBody({children}) {
  const classes = useStyles()
  const dataManager = useDataManager()

  return (
    <MTableBody className={classes.tbody}>
      <MTableRow>
        <MTableCell className={classes.td} colSpan={dataManager.getColSpan()} align="center">
          {children}
        </MTableCell>
      </MTableRow>
    </MTableBody>
  )
}

function TableRow({row, rowSpans, skipColumns, hover, hoverClass, eventContext, renderContext, rowErrors={}}) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const hasRowError = rowErrors[row.id?.toString()]

  return (
    <MTableRow data-testid={hasRowError ? 'errorRow' : 'tableRow'} className={hasRowError ? classes.errorRow : null}>
      {dataManager.getVisibleColumns().map((column, colIndex) => {
        const TableCell = column.tableCellComponent || HoverableTableCell

        return (
          !skipColumns[colIndex] && (
            <TableCell
              row={row}
              column={column}
              renderContext={renderContext}
              className={classes.td}
              key={colIndex}
              rowSpan={rowSpans[colIndex]}
              hover={hover}
              hoverClass={hoverClass}
              onClick={column.click}
              eventContext={eventContext}
              align={column.align}
              sx={column.sx}
            >
              {renderCell(row, column, renderContext)}
            </TableCell>
          )
        )
      })}
    </MTableRow>
  )
}

function getNumColumnsToCollapse(columns) {
  const firstDynamicSummary = columns.findIndex(column => column.summary && typeof(column.summary) === 'function' || typeof(column.summary) === 'string')
  if(firstDynamicSummary === -1) {
    return columns.length
  } else {
    return firstDynamicSummary
  }
}

function computeSkipColumns(summaryCollapse, visibleColumns) {
  if(summaryCollapse) {
    // numColumnsToCollapse is the number of consecutive columns at the beginning of the table with no dynamic summary
    const numColumnsToCollapse = getNumColumnsToCollapse(visibleColumns)
    if(numColumnsToCollapse > 1) {
      return [false, ...Array(numColumnsToCollapse - 1).fill(true), ...Array(visibleColumns.length - numColumnsToCollapse).fill(false)]
    }
  }

  return Array(visibleColumns.length).fill(false)
}

function SummaryRow({ hover, hoverClass, renderContext, summaryCollapse, summaryData=null, skipColumns }) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const data = summaryData || dataManager.getVisibleData()
  const visibleColumns = dataManager.getVisibleColumns()

  if(!skipColumns) {
    skipColumns = computeSkipColumns(summaryCollapse, visibleColumns)
  }

  return (
    <MTableRow className={clsx(classes.summaryRow, summaryData && classes.subtotalRow)}>
      <>
        {visibleColumns.map((column, colIndex) => {
          // colSpan is the number of consecutive true values in skipColumns immediately following the current column
          const colSpan = 1 + [...skipColumns.slice(colIndex + 1), false].findIndex(v => v === false)
          return !skipColumns[colIndex] && (
            <HoverableTableCell key={colIndex} colSpan={colSpan} className={classes.td} hover={hover} hoverClass={hoverClass} align={column.align} sx={column.sx}>
              {renderSummary(data, column, renderContext)}
            </HoverableTableCell>
          )
        })}
      </>
    </MTableRow>
  )
}

export default function TableBody({hover, hoverClass, summaryRow, eventContext, renderContext, summaryCollapse, rowErrors = {}}) {
  const classes = useStyles()
  const dataManager = useDataManager()

  if(dataManager.error) {
    return (
      <EmptyBody>
        Failed to load data: {dataManager.error.message}
        <IconButton onClick={() => dataManager.reload()} size="large" aria-label="Refresh">
          <RefreshIcon/>
        </IconButton>
      </EmptyBody>
    );
  } else if(dataManager.getVisibleData().length === 0) {
    return (
      <EmptyBody>
        {!dataManager.loading && 'No data to show.'}
      </EmptyBody>
    )
  } else {

    return (
      <MTableBody className={classes.tbody} key={dataManager.version}>
        {dataManager.group(dataManager.getVisibleData()).map((rowProps, rowIndex) => {
          if (rowProps.hideRow) {
            return
          }
          if (rowProps.summaryRow) {
            return (
              <SummaryRow summaryCollapse hover={hover} hoverClass={hoverClass} renderContext={renderContext} summaryData={rowProps.summaryData} skipColumns={rowProps.skipColumns} />
            )
          } else {
            return (
              <TableRow key={rowIndex} hover={hover} hoverClass={hoverClass} eventContext={eventContext} renderContext={renderContext} rowErrors={rowErrors} {...rowProps}/>
            )
          }
          })}
        {!!summaryRow && (
          <SummaryRow summaryCollapse={summaryCollapse} hover={hover} hoverClass={hoverClass} renderContext={renderContext} skipColumns={false}/>
        )}
      </MTableBody>
    )
  }
}
