import Divider from '@mui/material/Divider'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import IconButton from '@mui/material/IconButton'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import COLORS from '../../../../../lib/colors'
import { styled } from '@mui/system'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { arrayDiff, sortBy, sum, toFormattedNumber } from 'lib/utils'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useNotifications } from 'lib/NotificationsProvider'
import DialogContentWithBookends from './DialogContentWithBookends'


// @ts-ignore
const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragging',
})(({ theme, isDragging }) => ({
  backgroundColor: isDragging && theme.palette.lightBackground,
}))

const ListPlaceholder = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
}))

function List(props) {
  const { provided, innerRef, children, list } = props
  if(children.length > 0) {
    return (
      <div {...provided.droppableProps} ref={innerRef}>
        {children}
      </div>
    )

  } else {
    return <ListPlaceholder {...provided.droppableProps} ref={innerRef}>{list.placeholder}</ListPlaceholder>
  }
}

function FormField({ value, setValue, error }) {
  const handleChange = useCallback(event => {
    if ((event.target.value === undefined) || (event.target.value >= 0 && event.target.value <= 100)) {
      const parsedNumber = toFormattedNumber(event.target.value)
      setValue(parsedNumber === '' ? undefined : Number(parsedNumber))
    }
  }, [setValue])

  return (
    <Box sx={{position:'relative'}}>
      <TextField sx={{width: 96}} value={typeof(value) === 'number' ? value : ''} onChange={handleChange} label="Results" error={error}/>
      <Box component='span' sx={{position:'absolute', left:'70px', top:'12px', color: error ? COLORS.copperRed : COLORS.slateGray}}>%</Box>
    </Box>
  )
}

function ClientWeight({ editable, client, setWeight, error }) {
  const setValue = useCallback(newWeight => {
    setWeight(newWeight)
  }, [setWeight])

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item md={6} display="flex" alignItems="center">
        <IconButton tabIndex={-1}>
          <DragHandleIcon sx={{ color: COLORS.slateGray }} />
        </IconButton>
        <Box sx={{paddingRight: '12px'}}>
          {client.name}
        </Box>
        {editable &&
          <Box sx={{flex: 1, height:'1px', backgroundColor:COLORS.frenchBlue}}/>
        }
      </Grid>
      <Grid sx={{color: COLORS.copperRed}} item md={6}>
        {editable && (
          <FormField value={client.weight} setValue={setValue} error={error} />
        )}
      </Grid>
    </Grid>
  )
}

function DraggableClientWeight({ clientId, index, editable, clients, setLandingPage, error }) {
  const setWeight = useCallback(weight => {
    setLandingPage(landingPage => {
      const index = landingPage.clientWeights.findIndex(clientWeight => clientWeight.clientId === clientId)
      return {...landingPage, clientWeights: [...landingPage.clientWeights.slice(0, index), {clientId, weight}, ...landingPage.clientWeights.slice(index + 1)]}
  })}, [clientId, setLandingPage])
  const editableStyling = {padding: '6px 0 6px 0', marginLeft: '-5px'}
  const nonEditableStyling = {margin: '0 0 -6px -5px'}

  return (
    <Draggable draggableId={clientId} key={index} index={index}>
      {(provided, snapshot) => (
        <Container
          sx={editable ? editableStyling : nonEditableStyling}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          // @ts-ignore
          isDragging={snapshot.isDragging}
          tabIndex={-1}
        >
          <ClientWeight client={clients[clientId]} setWeight={setWeight} editable={editable} error={error} />
        </Container>
      )}
    </Draggable>
  )
}

function RemainingWeight({weight}) {
  const remainingWeight = 100 - weight
  let content = ''
  let invalid = false
  if (weight === 0) {
    content = 'Remaining'
  } else if (remainingWeight >= 0) {
    content = remainingWeight + '% Remaining'
  } else if (remainingWeight < 0) {
    content = Math.abs(remainingWeight) + '% Over'
    invalid = true
  }

  return (
    <Box component='span' sx={{color: invalid ? COLORS.copperRed : 'inherit', fontWeight: invalid ? '600' : 'normal'}}>
      {content}
    </Box>
  )
}

export default function ClientWeightForm({ clients: allClients, clientIds, landingPage, setLandingPage,
  onClose, isSubmitting, stepNumber, onClickBackButton, onClickSelectButton: onClickSelectButtonParent, modalTitle}) {
  const clientWeights = landingPage.clientWeights
  const showNextButton = true
  const modalSubTitle = <>Assign a <b>probability</b> for <b>client order</b> among initial results. Secondary results will appear in random order after initial results.</>
  const [error, setError] = useState(false)
  const { addNotification } = useNotifications()
  const clientNames = useMemo(() => Object.fromEntries(allClients.map(client => [client.id, client.internalName || client.name])), [allClients])

  const state = useMemo(() => {
    const clients = Object.fromEntries(clientIds.map(clientId => [
      clientId, {
        id: clientId,
        name: clientNames[clientId],
        weight: clientWeights.find(clientWeight => clientWeight.clientId === clientId)?.weight,
      }
    ]))
    const weightedClientIds = clientWeights.filter(({clientId}) => clientIds.some(id => clientId === id)).map(clientWeight => clientWeight.clientId)
    const unweightedClientIds = sortBy(arrayDiff(clientIds, weightedClientIds), clientId => clientNames[clientId])

    return {
      clients: clients,
      lists: {
        'weighted': {
          id: 'weighted',
          clientIds: weightedClientIds,
          placeholder: 'Click and drag Clients here to be included in initial results probability.',
         },
        'unweighted': {
          id: 'unweighted',
          clientIds: unweightedClientIds,
          placeholder: 'Click and drag Clients here to be included in secondary results.',
        },
      },
    }
  }, [clientIds, clientNames, clientWeights])

  const totalWeight = useMemo(() => sum(clientWeights, 'weight'), [clientWeights])

  // Reset clientWeights to account for previously entered weights where the clientId was later deselected
  useEffect(() => {
    setLandingPage(currentLandingPage => {
      return {...currentLandingPage, clientWeights: clientWeights.filter(({clientId}) => clientIds.some(id => clientId === id))}
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIds])

  useEffect(() => {
    setError(false)
  }, [clientWeights, setError])

  const validateInputs = useCallback(() => {
    if (clientWeights.some(e => e.weight === undefined || e.weight === 0)) {
      setError(true)
      addNotification({variant: 'alert', message: "Initial results require a probability between 1% and 100%."})
    } else if (clientWeights.length > 0  && totalWeight != 100) {
      setError(true)
      addNotification({variant: 'alert', message: "Results probability must total 100%."})
    } else {
      onClickSelectButtonParent()
    }
  }, [clientWeights, totalWeight, addNotification, onClickSelectButtonParent])

  const onDragEnd = useCallback(result => {
    const { destination, source, draggableId: clientId } = result

    if (!destination) { return }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) { return }
    if (
      source.droppableId === 'unweighted' &&
      destination.droppableId === 'unweighted'
    ) { return }

    const newClientWeights = [...clientWeights]
    let clientWeight
    if (source.droppableId === "weighted") {
      [clientWeight] = newClientWeights.splice(source.index, 1)
    } else {
      clientWeight = {clientId: clientId, weight: undefined}
    }

    if (destination.droppableId === "weighted") {
      newClientWeights.splice(destination.index, 0, clientWeight)
    }

    setLandingPage({...landingPage, clientWeights: newClientWeights})
  }, [clientWeights, landingPage, setLandingPage])

  return (
    <DialogContentWithBookends
      onClose={onClose}
      showBackButton={stepNumber > 0}
      onClickBackButton={onClickBackButton}
      onClickSelectButton={validateInputs}
      onCancel={onClose}
      isSubmitting={isSubmitting}
      modalTitle={modalTitle}
      buttonText='Next'
      modalSubTitle={modalSubTitle}
      disabled={!showNextButton}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Box aria-label="initialresults">
          <Grid container direction="row" alignItems="left" >
            <Grid item md={6}>
              <Typography sx={{margin: '0 0 8px 0'}} variant="h4">Initial Results</Typography>
            </Grid>
            {state.lists.weighted.clientIds.length > 0 &&
              <Grid item md={6}>
                <Typography sx={{margin: '0 0 8px 0', color: error ? COLORS.copperRed : undefined}} variant="h4">Probability</Typography>
              </Grid>
            }
          </Grid>
          <Droppable droppableId={state.lists.weighted.id} >
            {provided => (
              <>
                <List provided={provided} innerRef={provided.innerRef} list={state.lists.weighted}>
                  {state.lists.weighted.clientIds.map((clientId, index) => (
                    <DraggableClientWeight clients={state.clients} setLandingPage={setLandingPage} clientId={clientId} index={index} editable={true} key={clientId} error={error} />
                  ))}
                </List>
                {provided.placeholder}
              </>
            )}
          </Droppable>
          {state.lists.weighted.clientIds.length > 0 &&
            <Grid container direction="row" alignItems="left">
              <Grid item md={6}/>
              <Grid item md={6}>
                <Typography sx={{margin:'6px 0 19px 0', color: error ? COLORS.copperRed : undefined}} variant="h4">
                  {totalWeight === 0 && (
                    <span>Total</span>
                  ) || (
                    <span>= {totalWeight}%</span>
                  )}
                  <Box component="span" sx={{paddingLeft: '12px', fontSize: '14px', color: error ? COLORS.copperRed : COLORS.slateGray }}>
                    <RemainingWeight weight={totalWeight}/>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          }
        </Box>
        <Divider light sx={{margin:"18px 0"}}/>
        <Box aria-label="secondaryresults">
          <Typography sx={{margin: '18px 0 6px 0'}} variant="h4">Secondary Results</Typography>
          <Droppable droppableId={state.lists.unweighted.id}>
            {provided => (
              <>
                <List provided={provided} innerRef={provided.innerRef} list={state.lists.unweighted}>
                  {state.lists.unweighted.clientIds.map((clientId, index) => (
                    <DraggableClientWeight clients={state.clients} setLandingPage={setLandingPage} clientId={clientId} index={index} editable={false} key={clientId} error={error} />
                  ))}
                </List>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </Box>
      </DragDropContext>
    </DialogContentWithBookends>
  )
}
