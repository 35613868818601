import ExpandingTextField from "./ExpandingTextField";
import { Formik, Form as FormikForm } from "formik";
import { useState } from "react";
import { userEvent, within } from "@storybook/test";

export default {
  title: "UI/ExpandingTextField",
  component: ExpandingTextField,
};

function SampleForm({ args }) {
  const [passedArgs, setPassedArgs] = useState(args);

  return (
    <FormikForm
      autoComplete="off"
      onChange={() => {
        setPassedArgs({ ...args, error: false });
      }}
    >
      <ExpandingTextField {...passedArgs} />
    </FormikForm>
  );
}

const minimumText = `Before it receives focus this field is 1 line tall irrespective of content length \nAfter it receives focus it's always at least 4 lines tall, but never more than 20.`;
const mediumText = `Before it receives focus this field is 1 line tall irrespective of content length \nAfter it receives focus it's always at least 4 lines tall, but never more than 20.\n\n\n\n\nContent >4 and <20 lines in length results in an input just tall enough to frame the content`;
const maximumText = `Before it receives focus this field is 1 line tall irrespective of content length \nAfter it receives focus it's always at least 4 lines tall, but never more than 20.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n20th line\nThis content requires scrolling,\nbut\nyou\ncan\nstill\nsee\nit\njust\nfine...`;

const Template = (args) => (
  <Formik
    initialValues={{
      minimum: minimumText,
      medium: mediumText,
      maximum: maximumText,
    }}
    onSubmit={() => {
      /*no op*/
    }}
  >
    <SampleForm args={args} />
  </Formik>
);

export const Default = Template.bind({});
Default.args = {
  name: "example",
  label: "Example",
};

export const BeforeFocus = Template.bind({});
BeforeFocus.args = {
  name: "minimum",
  label: "Before Focus Example",
};

export const AfterFocusMinimum = Template.bind({});
AfterFocusMinimum.args = {
  name: "minimum",
  label: "After Focus Minimum",
};
AfterFocusMinimum.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("After Focus Minimum"));
};

export const AfterFocusMedium = Template.bind({});
AfterFocusMedium.args = {
  name: "medium",
  label: "After Focus Medium",
};
AfterFocusMedium.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("After Focus Medium"));
};

export const AfterFocusMaximum = Template.bind({});
AfterFocusMaximum.args = {
  name: "maximum",
  label: "After Focus Maximum",
};
AfterFocusMaximum.play = async ({ canvasElement }) => {
  const canvas = within(canvasElement);

  await userEvent.click(canvas.getByLabelText("After Focus Maximum"));
};
