import EditIcon from "@mui/icons-material/Edit";
import { Divider, Stack } from "@mui/material";
import StyledCard, {
  ErrorWrapper,
  CollapseWrapper,
  ContentWrapper,
  PlaceholderWrapper,
} from "./";

export default {
  title: "UI/StyledCard",
  component: StyledCard,
};

const handleEditClick = () => console.log("clicked Edit");

const Template = (args) => {
  return <StyledCard {...args}>{args.children}</StyledCard>;
};

const withTitleContent = (
  <ContentWrapper>
    Contrary to popular belief, Lorem Ipsum is not simply random text. It has
    roots in a piece of classical Latin literature from 45 BC, making it over
    2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
    College in Virginia, looked up one of the more obscure Latin words,
    consectetur, from a Lorem Ipsum passage, and going through the cites of the
    word in classical literature, discovered the undoubtable source. Lorem Ipsum
    comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum
    (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a
    treatise on the theory of ethics, very popular during the Renaissance.
  </ContentWrapper>
);
const noTitleContent = (
  <PlaceholderWrapper>
    Contrary to popular belief, Lorem Ipsum is not simply random text. It has
    roots in a piece of classical Latin literature from 45 BC, making it over
    2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
    College in Virginia, looked up one of the more obscure Latin words,
    consectetur, from a Lorem Ipsum passage, and going through the cites of the
    word in classical literature, discovered the undoubtable source. Lorem Ipsum
    comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum
    (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a
    treatise on the theory of ethics, very popular during the Renaissance.
  </PlaceholderWrapper>
);
const collapseContent = (
  <CollapseWrapper>
    Contrary to popular belief, Lorem Ipsum is not simply random text. It has
    roots in a piece of classical Latin literature from 45 BC, making it over
    2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
    College in Virginia, looked up one of the more obscure Latin words,
    consectetur, from a Lorem Ipsum passage, and going through the cites of the
    word in classical literature, discovered the undoubtable source. Lorem Ipsum
    comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum
    (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a
    treatise on the theory of ethics, very popular during the Renaissance.
  </CollapseWrapper>
);
const cardHasPersistedError = (
  <ContentWrapper>
    <div className="persistedError">This whole card has a persisted error</div>
  </ContentWrapper>
);
const sectionHasPersistedError = (
  <Stack direction="column" divider={<Divider light flexItem sx={{ mx: 4 }} />}>
    <ErrorWrapper>
      <div className="persistedError">This section has a persisted error</div>
    </ErrorWrapper>
    <ErrorWrapper>
      <div>While this one has no error</div>
    </ErrorWrapper>
  </Stack>
);

export const Default = Template.bind({});
Default.args = {
  title: "Sample Section Title",
  note: "A note describes the section in more detail",
  controls: [
    { icon: <EditIcon />, onClick: handleEditClick, label: "sampleEditButton" },
  ],
  height: null,
  width: null,
  maxHeight: null,
  isSubsection: false,
  hasStickyHeader: false,
  label: "sample label",
  role: "sample role",
  children: withTitleContent,
};

export const Empty = Template.bind({});
Empty.args = {
  width: "1056px",
  children: noTitleContent,
};

export const WithTitle = Template.bind({});
WithTitle.args = {
  title: "Section Title",
  width: "1056px",
  children: withTitleContent,
};

export const WithTitleAndNote = Template.bind({});
WithTitleAndNote.args = {
  title: "Section Title",
  note: "Note describes a section in more detail.",
  width: "1056px",
  children: withTitleContent,
};

export const CardPersistedError = Template.bind({});
CardPersistedError.args = {
  title: "Section Title",
  note: "Note describes a section in more detail.",
  width: "1056px",
  children: cardHasPersistedError,
};

export const SectionPersistedError = Template.bind({});
SectionPersistedError.args = {
  title: "Section Title",
  note: "Note describes a section in more detail.",
  width: "1056px",
  children: sectionHasPersistedError,
};

export const ExpandableWithTitle = Template.bind({});
ExpandableWithTitle.args = {
  title: "Section Title",
  maxHeight: 100,
  width: "1056px",
  children: collapseContent,
};

export const ExpandableWithTitleAndNote = Template.bind({});
ExpandableWithTitleAndNote.args = {
  title: "Section Title",
  note: "Note describes a section in more detail.",
  maxHeight: 100,
  width: "1056px",
  children: collapseContent,
};

export const Subsection = Template.bind({});
Subsection.args = {
  title: "Section Title",
  isSubsection: true,
  width: "1056px",
  children: noTitleContent,
};

export const SubsectionWithStickyHeader = Template.bind({});
SubsectionWithStickyHeader.args = {
  title: "Section Title",
  isSubsection: true,
  hasStickyHeader: true,
  height: "4000px",
  width: "1056px",
  children: noTitleContent,
};
