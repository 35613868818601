import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';

import { FilePicker } from 'react-file-picker';

import { failOnHttpError, readFile } from '../lib/utils'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

function format(date) {
  return moment(date).calendar();
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: 400,
    maxWidth: '100%',
    minHeight: 100,
    maxHeight: '100%',
  },
}));

function UploadDialog({onComplete, onClose}) {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleChange = file => {
    setError(null);
    setUploading(true);
    readFile(file)
      .then(fileContents => (
        fetch('/api/v1/leads', {
          method: 'PUT',
          body: fileContents,
          headers: {
            'Content-type': 'text/csv',
          },
        })
      ))
      .then(failOnHttpError)
      .then(response => response.json())
      .then(result => {
        setMessage(result.message);
        onComplete();
      })
      .catch(error => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleError = error => {
    setError(error);
    console.log(error);
  };

  return (
    <Dialog
      onClose={onClose}
      open={true}
    >
      <DialogTitle onClose={onClose}>
        Upload CSV
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!message && (
          <FilePicker
            extensions={['csv']}
            onChange={handleChange}
            onError={handleError}
          >
            <Button variant="outlined" disabled={uploading}>{uploading ? 'Please wait...' : 'Select file...'}</Button>
          </FilePicker>
        )}
        {message && <Typography fontWeight="fontWeightBold">{message}</Typography>}
        {error && <Typography color="error">{error.toString()}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {message ? 'Dismiss' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
