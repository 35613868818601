import { gql } from '@apollo/client';


export const VARIABLE_FIELDS = gql`
  fragment VariableFields on LandingPageVariables {
    heading
    subheading
    redirectUrlFailure
    trackingPixelFb
    trackingPixelSn
    trackingPixelTt
    jornayaEnabled
    jornayaCampaignId
    continueUrl
    accredInfo
    autoOpenModal
    blurb
    complianceText
    copyright
    cta
    cta2
    customCss
    footerText
    googleOptimizeContainerId
    hasAccredInfo
    hasSecondCta
    hasTestimonial
    implicitTcpa
    modalHeading
    modalOnlyDisclaimer
    modalSubheading
    privacyPolicy
    primaryColor
    programName
    programShortName
    schoolName
    secondCta
    secondCta2
    secondCtaHeader
    secondCtaLeft
    secondCtaRight
    secondaryColor
    showClock
    showLabels
    showSecurityMessage
    submitButtonLabel
    tcpaDisclosure
    testimonial
    testimonialFooter
    testimonialFootnote
    testimonialName
    title
    videoUrl
  }`
