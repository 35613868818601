import { get } from 'lodash-es'
import InputAdornment from "@mui/material/InputAdornment"
import { FormTextField, useFormikContext } from "components/Formik/forms"
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import COLORS from 'lib/colors';
import { openInNewTab } from 'components/pages/LandingPages/data/utils';


export function Path() {
  const { values } = useFormikContext()
  const hostname = get(values, 'routes[0].hostname') || ''
  const path = get(values, 'routes[0].path') || ''

  const handleCopy = (e) => {
    e.preventDefault()
    if (e.metaKey || e.ctrlKey) {
      e.stopPropagation()
      openInNewTab(`https://${hostname}/${path}`)
    }
  }

  return (
    <FormTextField
      label="Path"
      name="routes[0].path"
      required
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            color="textSecondary"
          >
            {`https://${hostname}/`}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            color="textSecondary"
          >
            <CopyToClipboard text={`https://${hostname}/${path}`}>
              <IconButton aria-label="Copy URL" onClickCapture={handleCopy} sx={{ width: '30px', height: '30px' }}>
                <ContentCopyRoundedIcon sx={{ color: COLORS.slateGray }}/>
              </IconButton>
            </CopyToClipboard>
          </InputAdornment>
        )
      }}
      sx={{
        '&:not(:hover) .MuiInputAdornment-positionEnd': {visibility: 'hidden'}
      }}
    />
  )
}
