import React, { useState } from 'react';

export default function Accordion(props) {
  const [expanded, setExpanded] = useState(props.defaultPanelId);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {React.Children.map(props.children, child =>
        child && React.cloneElement(child, {expanded, handleChange})
      )}
    </div>
  );
}
