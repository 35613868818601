import AlertBadge from "./AlertBadge";
import { userEvent, within } from "@storybook/test";

export default {
  title: "UI/AlertBadge",
  component: AlertBadge,
  decorators: [
    (Story) => (
      <div style={{ height: "300px", width: "300px" }}>
        <Story />
      </div>
    ),
  ],
  parameters: {
    zeplinLink: "https://zpl.io/Z09PwmJ",
    layout: "fullscreen",
  },
};

const Template = (args) => {
  return <AlertBadge {...args} />;
};

export const Error = Template.bind({});
Error.args = {
  variant: "error",
  tipTitle: "Some important detail",
};

export const Warning = Template.bind({});
Warning.args = {
  variant: "warning",
  tipTitle: "A warning detail",
};

Error.play = async ({ canvasElement }) => {
  const alertBadge =
    await within(canvasElement).findByTestId("ErrorOutlineIcon");

  await userEvent.pointer({ target: alertBadge, coords: { clientX: 10, clientY: 10 }});
};

Warning.play = async ({ canvasElement }) => {
  const alertBadge =
    await within(canvasElement).findByTestId("WarningAmberIcon");

  await userEvent.pointer({ target: alertBadge, coords: { clientX: 10, clientY: 10 }});
};
