import { useCallback, useMemo } from 'react'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import makeStyles from '@mui/styles/makeStyles';

import PerformanceTable from 'components/PerformanceTable/PerformanceTable';
import PerformanceCharts from '../../analytics/PerformanceCharts'


import MapIcon from '@mui/icons-material/Map'
import TableChartIcon from '@mui/icons-material/TableChart'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { getDefaultDateRange } from 'components/PerformanceTable/utils'
import Box from '@mui/material/Box';
import { useTimezone } from 'lib/TimezoneProvider';
import moment from 'moment-timezone'
import { TimeRange, RELATIVE_RANGE_LABELS } from 'components/DatePickerPopover';
import { isArray } from 'lodash-es';


const useStyles = makeStyles(theme => ({
  container: {
  },
  tabs: {
    marginBottom: theme.spacing(2),
    minHeight: '72px',
  },
}))

export const tabs = {
  QUALITY: 'quality',
  HEATMAP: 'heatmap',
}

export default function Performance() {
  const classes = useStyles()
  const navigate = useNavigate()
  const match = useMatch({ path: '/performance/:tab', end: false })
  const selectedTab = match?.params?.tab || tabs.QUALITY

  const { timezone } = useTimezone()
  const [dateRangeQueryString, setDateRangeQueryString] = useQueryParam('dateRange', withDefault(JsonParam, getDefaultDateRange(timezone)))

  const dateRange: TimeRange = useMemo(() => {
    if (isArray(dateRangeQueryString) && dateRangeQueryString.length === 2) {
      return {
        label: null,
        timeRange: [
          moment.tz(dateRangeQueryString[0], timezone),
          moment.tz(dateRangeQueryString[1], timezone),
        ],
      };
    } else if (RELATIVE_RANGE_LABELS.includes(dateRangeQueryString)) {
      return {
        label: dateRangeQueryString,
        timeRange: null
      }
    } else {
      return {
        label: 'Lifetime',
        timeRange: null
      };
    }
  }, [dateRangeQueryString, timezone]);

  const setDateRange = useCallback(newValue => {
    if(typeof(newValue) === 'function') {
      newValue = newValue(dateRange)
    }

    setDateRangeQueryString(newValue.label || newValue.timeRange)
  }, [setDateRangeQueryString, dateRange])

  const handleNavigation = useCallback(newValue => {
    const searchString = `dateRange=${encodeURIComponent(JsonParam.encode(dateRangeQueryString))}`
    navigate({
      pathname: `/performance/${newValue}`,
      search: searchString
    })
  },[dateRangeQueryString, navigate])

  return (
    <Box sx={{
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      px: 3,
    }}>
      <Tabs
        value={selectedTab}
        onChange={(_event, newValue) => handleNavigation(newValue)}
        className={classes.tabs}
      >
        <Tab sx={{ minWidth: 92 }} icon={<TableChartIcon/>} label="Quality" value={tabs.QUALITY} />
        <Tab sx={{ minWidth: 92 }} icon={<MapIcon/>} label="Heat Map" value={tabs.HEATMAP} />
      </Tabs>

      <Routes>
        <Route path='quality' element={<PerformanceTable dateRange={dateRange} setDateRange={setDateRange}/>}/>
        <Route path='heatmap' element={<PerformanceCharts dateRange={dateRange} setDateRange={setDateRange}/>}/>
        <Route path='*' element={<Navigate to="quality" replace />}/>
      </Routes>
    </Box>
  )
}
