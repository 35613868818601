import { action } from "@storybook/addon-actions";
import CygIconButton from "../Button/CygIconButton";
import NotificationSnackbar from "./";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";

export default {
  title: "UI/Notification",
  component: NotificationSnackbar,
};

const Template = (args) => {
  return (
    <main>
      <NotificationSnackbar {...args} />
    </main>
  );
};

export const Default = Template.bind({});
Default.args = {
  notification: { message: "Test Message" },
};

export const AutoHide = Template.bind({});
AutoHide.args = {
  ...Default.args,
  hideDuration: 6000,
};

export const WithButton = Template.bind({});
WithButton.args = {
  notification: {
    message: "Test Message",
    variant: "notice",
    actionComponent: (
      <CygIconButton
        text="Click Me!"
        icon={<RecommendOutlinedIcon />}
        onClick={action("onClick handler")}
      />
    ),
  },
};

export const Success = Template.bind({});
Success.args = {
  notification: {
    message: "Test Message",
    variant: "notice",
  },
};

export const Error = Template.bind({});
Error.args = {
  notification: {
    message: "Test Message",
    variant: "alert",
  },
};

export const Persistent = Template.bind({});
Persistent.args = {
  notification: {
    message: "Important Message",
    variant: "alert",
    actionComponent: (
      <CygIconButton
        text="Do something!"
        icon={<RecommendOutlinedIcon />}
        onClick={action("onClick handler")}
      />
    ),
    isPersistent: true,
  },
};
