import Box from "@mui/material/Box"
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined"
import Stack from "@mui/material/Stack"
import { useState } from "react"
import AlertBadge from "components/UI/AlertBadge/AlertBadge"
import COLORS from '../../lib/colors'
import CircularProgress from "@mui/material/CircularProgress"
import { useHostname } from "lib/HostnameProvider"
import { CreativePreviewDialog } from "components/UI/CreativePreviewDialog/CreativePreviewDialog"
import getCreativeInfo, { ComplyedState } from "services/get-creative-info"
import HoverableTableCell, { BaseHoverableTableCellProps, ClickableTableCellProps } from "components/MetricsTable/HoverableTableCell"
import { rawCell } from "components/MetricsTable/render"
import { Notification, useNotifications } from "lib/NotificationsProvider"
import CygIconButton from "components/UI/Button/CygIconButton"
import Login from "@mui/icons-material/Login"


export default function CreativePreviewTableCell({
  row,
  column,
  rowSpan,
  sx,
  onClick: _onClick,
  ...props
} : BaseHoverableTableCellProps & ClickableTableCellProps) {
  const [hoveredOver, setHoveredOver] = useState(false)
  const [complyedState, setComplyedState] = useState<ComplyedState>({pending: false})
  const { complyedHostname } = useHostname()
  const [showCreativePreview, setShowCreativePreview] = useState(false)
  const cid = rawCell(row, column)
  const { addNotification } = useNotifications()

  const handleClick = (() => {
    if(cid && (!showCreativePreview || complyedState.creativeError === 'Log in to ComplyEd to view creative.')) {
      // Do not force the user to reload the page after logging into Complyed. Always retry getCreativeInfo if login error.
      if(complyedState.creativeUrlPath) {
        setShowCreativePreview(true)
      } else if(!complyedState.creativeError || complyedState.creativeError === 'Log in to ComplyEd to view creative.') {
        setComplyedState({ pending: true })
        getCreativeInfo(cid, complyedHostname).then((updatedComplyedState:ComplyedState) => {
          setComplyedState(updatedComplyedState)
          if(updatedComplyedState.creativeError) {
            const note: Notification = {
              variant: 'alert',
              message: updatedComplyedState.creativeError,
            }
            if(updatedComplyedState.needComplyedLogin) {
              note.actionComponent = <CygIconButton text='Log in to ComplyEd' icon={<Login/>} onClick={() => window.open(`https://${complyedHostname}`, "_blank", 'location=yes,height=600,width=8000,scrollbars=yes,status=yes,menubar=yes')}/>
              note.isPersistent = true
            }
            addNotification(note)
          }
        })
        setShowCreativePreview(true)
      }
    }
  })

  const handleCancelPreviewDialog = (() => {
    setShowCreativePreview(false)
    setHoveredOver(false)
  })

  return (
    <HoverableTableCell
      row={row}
      onClick={handleClick}
      onMouseEnter={() => {setHoveredOver(true)}}
      onMouseLeave={() => {setHoveredOver(false)}}
      rowSpan={rowSpan}
      sx={{
        width: 92,
        '&:hover': {
          textDecoration: 'underline',
          fontWeight: 600,
          ...((complyedState.creativeError) && {
            backgroundColor: `${COLORS.mistyRose} !important`,
            color: `${COLORS.copperRed} !important`,
          }),
          ...((complyedState.pending) && {
            backgroundColor: `#f0f0f0 !important`,
            color: COLORS.frenchBlue,
          }),
        },
      }}
      {...props}
    >
      <>
        {!cid && (
          'none'
        ) || (
          <Stack
            direction="row"
            sx={{
              margin: '-6px -13px',
              padding: '0 4px 0 12px',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              ...sx
            }}
          >

            {cid}

            { hoveredOver && complyedState.creativeError && (
              <AlertBadge variant='error' tipTitle={complyedState.creativeError} sx={{position: 'relative', marginRight: '-2px'}}/>
            ) || hoveredOver && complyedState.pending && (
              <Box sx={{ display: 'block', height: 20 }}>
                <CircularProgress size='20px' />
              </Box>
            ) || hoveredOver && (
              <VisibilityOutlined sx={{height: 20, width: 20, color: COLORS.slateGray}}/>
            )}

            { showCreativePreview && complyedState.creativeUrlPath && !complyedState.creativeError && (
              <CreativePreviewDialog
                onCancel={handleCancelPreviewDialog}
                creativeUrlPath={complyedState.creativeUrlPath}
                creativeType={complyedState.creativeData && complyedState.creativeData[1].value}
                cid={cid}
              />
            )}
          </Stack>
        )}
      </>
    </HoverableTableCell>
  )
}
