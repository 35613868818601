import SvgIcon from "@mui/material/SvgIcon"

export default function NotBlankIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="qv08khf91a" d="M0 0h20v18H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="12" cy="12" r="12"/>
          <g transform="translate(2 3)">
            <mask id="qmdv2n59qb" fill="#fff">
              <use xlinkHref="#qv08khf91a"/>
            </mask>
            <rect stroke="#353F4C" strokeWidth="1.35" mask="url(#qmdv2n59qb)" x=".675" y="1.675" width="18.65" height="14.65" rx="3"/>
          </g>
          <path fill="#353F4C" d="m20.427 4.988.794 1.092L3.423 19.012l-.794-1.092z"/>
        </g>
      </svg>
    </SvgIcon>
  )
}
