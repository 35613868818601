import { forwardRef, useImperativeHandle, useRef, useCallback, useState, useEffect } from 'react'
import MUIRichTextEditor from "mui-rte"
import { convertToRaw } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import TextField from '@mui/material/TextField'
import SuperscriptIcon from '@mui/icons-material/Superscript'
import TitleIcon from '@mui/icons-material/Title'
import useId from '@mui/utils/useId'

// Adapted from mui-rte example https://github.com/niuware/mui-rte/pull/131/files

const RichTextInput = forwardRef(function RichTextInput({
  focused,
  controls,
  placeholder,
  value,
  defaultValue = '',
  onChange,
  ...richTextProps
}, ref) {
  const [initialValue, setInitialValue] = useState()
  const onStateChange = (state) => {
    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style == 'SUPERSCRIPT') {
          return <sup />
        }
      },
      blockToHTML: (block) => {
        if (block.type === 'HEADER-THREE') {
          return <h3 />
        }
        else if(block.type === 'PARAGRAPH') {
          return <p />
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          const url = entity.data.url.replace(/^(https?:\/\/)?/i, (a)=>a || 'http://')
          return <a target='_blank' rel="noreferrer" href={url}>{originalText}</a>
        }
        return originalText;
      }
    })(state.getCurrentContent())
    onChange(html)
  }

  const richTextRef = useRef()

  const focusRichText = useCallback(() => richTextRef.current?.focus(), [richTextRef])

  useImperativeHandle(ref, () => ({
    focus: () => { focusRichText }
  }))

  useEffect(() => {
    if (focused) {
      focusRichText()
    }
  }, [focused, focusRichText])

  useEffect(() => {
    if(!initialValue) {
      const initialState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
          if (nodeName === 'sup') {
            return currentStyle.add('SUPERSCRIPT')
          } else {
            return currentStyle
          }
        },
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity(
              'LINK',
              'MUTABLE',
              {url: node.href}
            )
          }
        },
      })(value || defaultValue)
      setInitialValue(JSON.stringify(convertToRaw(initialState)))
    }
  }, [initialValue, setInitialValue, value, defaultValue])

  return (
    <MUIRichTextEditor
      ref={richTextRef}
      label={placeholder}
      onChange={onStateChange}
      defaultValue={initialValue || ''}
      inlineToolbar={false}
      controls={controls}
      customControls={[
        {
          name: "superscript",
          icon: <SuperscriptIcon/>,
          type: "inline",
          inlineStyle: {verticalAlign: 'super', fontSize: 'smaller'}
        },
        {
          name: "header-three",
          icon: <TitleIcon/>,
          type: "block",
          blockWrapper: <h3/>
        }
      ]}
      {...richTextProps}
    />
  )
})

export default function RichTextField({
  variant,
  controls,
  defaultValue,
  value,
  placeholder,
  sx,
  InputLabelProps,
  onChange,
  ...textFieldProps
}) {
  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = useCallback(() => setIsFocused(true), [setIsFocused])
  const handleBlur = useCallback(() => setIsFocused(false), [setIsFocused])
  const editorId = useId()

  return (
    <TextField
      {...textFieldProps}
      variant={variant}
      fullWidth={'fullWidth' in textFieldProps ? textFieldProps.fullWidth : true}
      focused={isFocused}
      onClick={() => setIsFocused(true)}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      InputProps={{
        inputComponent: RichTextInput,
        inputProps: {
          focused: isFocused,
          controls: controls,
          value: value || defaultValue,
          label: placeholder,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: onChange,
        },
        id: editorId,
      }}
      sx={{
        marginTop: '16px',
        marginBottom: '8px',
        [`& #${editorId}-root`]: {
          width: '100%',
        },
        [`& #${editorId}-container`]: {
          margin: 0
        },
        [`& #${editorId}-toolbar`]: {
          borderBottom: '1px solid',
          borderColor: 'divider',
          padding: '5px 5px 0 5px',
        },
        [`& #${editorId}-toolbar svg`]: {
          color: '#95a4b7',
        },
        [`& #${editorId}-editor`]: {
          padding: '0 14px',
          minHeight: 98,
          overflow: 'scroll',
        },
        [`& #${editorId}-editor div[data-block]`]: {
          margin: '16px 0',
        },
        ...sx
      }}
    />
  )
}
