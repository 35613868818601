import Container from "@mui/material/Container";
import Allocations from "./";
import { reactRouterNestedAncestors, reactRouterParameters } from 'storybook-addon-remix-react-router'
import FeaturesProvider from "providers/FeaturesProvider";


export default {
  title: "Pages/Allocations",
  component: Allocations,
};

const Template = (args) => {
  return (
    <Container maxWidth="lg">
      <FeaturesProvider features={{ smart_score: true }}>
        <Allocations {...args} />
      </FeaturesProvider>
    </Container>
  )
};

export const Admin = Template.bind({});
Admin.parameters = {
  reactRouter: reactRouterParameters({
    location: {
      pathParams: { clientId: '123' },
    },
    routing: reactRouterNestedAncestors([
      {
        path: 'clients',
      },
      {
        path: ':clientId',
      },
      {
        path: 'allocations',
      }
    ]),
  }),
  orbit: {
    user: {
      permitGlobalAdmin: true,
    },
    records: [
      {
        type: "clients",
        id: "123",
        attributes: {
          id: 134,
          name: "American InterContinental University",
          "created-at": "2020-11-05T16:35:35.962Z",
          "updated-at": "2021-10-05T13:29:17.510Z",
          "secondary-color": "#0065A4",
          "primary-color": "#474A59",
          "tcpa-disclosure":
          "By checking this box and providing my contact information, I am giving American InterContinental University, a member of the AIU system, consent to contact me by email or telephone, including by text message regarding educational services. I acknowledge these calls may be pre-recorded messages and/or generated using an automated dialing system. I understand that I am under no obligation to provide consent to purchase any goods or services. Msg and data rates may apply",
          "internal-name": "American InterContinental University",
          "lp-email-sender": null,
          "lp-area-codes": null,
          "lp-continue-url": null,
          "response-time": "five_minutes",
          "implicit-tcpa": false,
          "google-conversion-id": null,
          "complyed-client-id": "102",
          "campus-explorer-college-id": null,
          "num-programs": 3,
          aor: false,
          "contact-name": null,
          "contact-email": null,
          "contact-address": null,
          "contact-city": null,
          "contact-state": null,
          "contact-zip": null,
          "contact-phone": null,
          "time-zone": "America/New_York",
          notes: "test",
          logo: null,
          "compliance-logo": null,
          "brand-guidelines-document": null,
          "brand-guidelines-document-filename": null,
        },
      },
      {
        type: "vendors",
        id: "1",
        attributes: {
          id: 1,
          name: "Cygnus Education",
          "created-at": "2021-01-25T01:26:50.313Z",
          "updated-at": "2021-01-25T01:26:50.313Z",
          "contact-name": null,
          "contact-email": null,
          "street-address": null,
          city: null,
          state: null,
          "zip-code": null,
          phone: null,
          "external-config": {},
        },
        relationships: { organization: { data: { id: "1", type: "organizations" } } },
      },
      {
        type: "contracts",
        id: "45",
        relationships: {
          client: { data: { id: "123", type: "clients" } },
          vendor: { data: { id: "1", type: "vendors" } },
        },
      },
      {
        type: "program-groups",
        id: "96",
        attributes: {
          id: 96,
          "client-id": 123,
          description: "Health",
        },
        relationships: {
          client: { data: { id: "123", type: "clients" } },
        },
      },
      {
        type: "program-groups",
        id: "97",
        attributes: {
          id: 97,
          "client-id": 123,
          description: "Education",
        },
        relationships: {
          client: { data: { id: "123", type: "clients" } },
        },
      },
      {
        type: "program-groups",
        id: "98",
        attributes: {
          id: 98,
          "client-id": 123,
          description: "Liberal Arts",
        },
        relationships: {
          client: { data: { id: "123", type: "clients" } },
        },
      },
      {
        type: "client-campaigns",
        id: "33",
        attributes: {
          name: "Health",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 808,
          "billable-lead-count": 808,
          "smart-score": "green",
          "public-id": "00003820",
          "total-cost-in-cents": 6970000,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "96",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "34",
        attributes: {
          name: "Two Line Campaign Title Example",
          "campaign-type": "shared",
          status: "active",
          "good-lead-count": 111,
          "billable-lead-count": 111,
          "smart-score": "yellow",
          "public-id": "29300280",
          "total-cost-in-cents": 569000,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "97",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "135",
        attributes: {
          name: "Education",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 634,
          "billable-lead-count": 634,
          "smart-score": "red",
          "public-id": "00003820",
          "total-cost-in-cents": 0,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "96",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "136",
        attributes: {
          name: "Liberal Arts",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 105,
          "billable-lead-count": 105,
          "smart-score": null,
          "public-id": "29300280",
          "total-cost-in-cents": 0,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "98",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "33",
        attributes: {
          name: "Health",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 808,
          "billable-lead-count": 808,
          "smart-score": "green",
          "public-id": "00003820",
          "total-cost-in-cents": 6970000,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "96",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "34",
        attributes: {
          name: "Two Line Campaign Title Example",
          "campaign-type": "shared",
          status: "active",
          "good-lead-count": 111,
          "billable-lead-count": 111,
          "smart-score": "yellow",
          "public-id": "29300280",
          "total-cost-in-cents": 569000,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "97",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "135",
        attributes: {
          name: "Education",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 634,
          "billable-lead-count": 634,
          "smart-score": "red",
          "public-id": "00003820",
          "total-cost-in-cents": 0,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "96",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "client-campaigns",
        id: "136",
        attributes: {
          name: "Liberal Arts",
          "campaign-type": "exclusive",
          status: "active",
          "good-lead-count": 105,
          "billable-lead-count": 105,
          "smart-score": null,
          "public-id": "29300280",
          "total-cost-in-cents": 0,
        },
        relationships: {
          contract: {
            data: {
              id: "45",
              type: "contracts",
            },
          },
          "program-group": {
            data: {
              id: "98",
              type: "program-groups",
            },
          },
        },
      },
      {
        type: "allocations",
        id: "439",
        attributes: {
          id: 439,
          "client-campaign-id": 33,
          year: 2021,
          month: 1,
          cap: 3000,
          "cpl-in-cents": 8800,
          "vendor-projection": null,
          alerted: false,
          "created-at": "2022-02-03T00:30:01.493Z",
          "updated-at": "2022-02-03T00:30:01.493Z",
        },
        relationships: {
          "client-campaign": {
            data: { id: "33", type: "client-campaigns" },
          },
        },
      },
      {
        type: "allocations",
        id: "440",
        attributes: {
          id: 440,
          "client-campaign-id": 34,
          year: 2021,
          month: 1,
          cap: 250,
          "cpl-in-cents": 6000,
          "vendor-projection": 4200,
          alerted: false,
          "created-at": "2022-02-03T00:30:01.501Z",
          "updated-at": "2022-02-03T00:30:01.501Z",
        },
        relationships: {
          "client-campaign": {
            data: { id: "34", type: "client-campaigns" },
          },
        },
      },
    ],
  },
};

export const Vendor = Template.bind({});
Vendor.args = {
  ...Admin.args,
};

Vendor.parameters = {
  ...Admin.parameters,
  orbit: {
    ...Admin.parameters.orbit,
    user: {
      accessibleVendorIds: ['1'],
    },
  },
  reactRouter: reactRouterParameters({
    location: {
      pathParams: { contractId: '45' },
    },
    routing: reactRouterNestedAncestors([
      {
        path: 'contracts',
      },
      {
        path: ':contractId',
      },
      {
        path: 'allocations',
      }
    ]),
  }),
};

export const Client = Template.bind({});
Client.args = {
  ...Admin.args,
};

Client.parameters = {
  ...Admin.parameters,
  orbit: {
    ...Admin.parameters.orbit,
    user: {
      accessibleClientIds: ['123'],
    },
  },
};
