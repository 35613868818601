import React from "react";

import MenuButton from "./";

export default {
  title: "UI/MenuButton",
  component: MenuButton,
  parameters: {
    delay: 2000,
  },
};

const Template = (args) => {
  return (
    <div style={args.defaultOpen ? { paddingTop: 50, paddingLeft: 60 } : undefined}>
      <MenuButton {...args} />
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {
  text: "Save",
  items: [
    { text: "Save", onClick: () => console.log("Clicked Save") },
    {
      text: "Save & Send IO",
      onClick: () => console.log("Clicked Save & Send IO"),
    },
  ],
};

export const Open = Template.bind({});
Open.args = {
  ...Default.args,
  defaultOpen: true,
  menuProps: {
    transitionDuration: 0,
    PaperProps: {
      sx: {
        width: 180,
      },
    },
  },
};

export const Disabled = Template.bind({});
Disabled.args = {
  ...Default.args,
  disabled: true,
};
