import ProgramAndCampaignContent from '../../ui/LandingPageEditor/MainPage/ProgramAndCampaignContent'
import DialogContentWithBookends from './DialogContentWithBookends'


export default function LeadTargetsConfirmation({ leadTargets, onClose, isSubmitting, stepNumber, onClickBackButton, onClickSelectButton, modalTitle }) {
  const modalSubTitle = <>Review and confirm the <b>Clients, Programs,</b> and <b>Campaigns</b> to include as recipients of leads for this landing page.</>

  return (
    <DialogContentWithBookends
      onClose={onClose}
      showBackButton={stepNumber > 0}
      onClickBackButton={onClickBackButton}
      onClickSelectButton={onClickSelectButton}
      onCancel={onClose}
      isSubmitting={isSubmitting}
      modalTitle={modalTitle}
      buttonText='Next'
      modalSubTitle={modalSubTitle}
      disabled={false}
    >
      <ProgramAndCampaignContent leadTargets={leadTargets}/>
    </DialogContentWithBookends>
  )
}
