export default {
    "__typename": "LandingPage",
    "id": "d5a493a9",
    "name": "Find Your Dream Degree | Business",
    "template": {
      "name": "portal",
      "multiClient": true,
    },
    "leadTargets": [
      {
        "id": "3953",
        "clientCampaign": {
          "id": "724885d4",
          "name": "Cygnus - Healthcare - Online - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "223",
            "description": "Online - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "643",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3954",
        "clientCampaign": {
          "id": "5c2ecdbf",
          "name": "Cygnus - Healthcare - Waco - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "222",
            "description": "Waco - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "641",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3955",
        "clientCampaign": {
          "id": "7ea8c861",
          "name": "Cygnus - Healthcare - SAS - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "221",
            "description": "San Antonio South - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "640",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3956",
        "clientCampaign": {
          "id": "2bbb10d5",
          "name": "Cygnus - Healthcare - SAN - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "220",
            "description": "San Antonio North - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "639",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3957",
        "clientCampaign": {
          "id": "cbf9395b",
          "name": "Cygnus - Healthcare - Austin - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "167",
            "description": "Austin - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "638",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3958",
        "clientCampaign": {
          "id": "7090d2ef",
          "name": "Cygnus - Healthcare - Pharr - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "219",
            "description": "Pharr - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "637",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3959",
        "clientCampaign": {
          "id": "2cd3ee00",
          "name": "Cygnus - Healthcare - Harlingen - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "218",
            "description": "Harlingen - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "636",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3960",
        "clientCampaign": {
          "id": "2fc9c34b",
          "name": "Cygnus - Healthcare - Corpus - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "217",
            "description": "Corpus Christi - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "635",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3961",
        "clientCampaign": {
          "id": "996697a3",
          "name": "Cygnus - Healthcare - Brownsville - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "216",
            "description": "Brownsville - All",
            "client": {
              "id": "188",
              "internalName": null,
              "name": "Southern Careers Institute",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "634",
          "name": "Business Administration",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "3962",
        "clientCampaign": {
          "id": "9d1b47c",
          "name": "Business - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "9",
            "description": "Business",
            "client": {
              "id": "29",
              "internalName": "GCU Undergrad",
              "name": "Grand Canyon University",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "561",
          "name": "Bachelor of Science Degree in <span class=\"secondary-color\">Business Administration</span>",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      },
      {
        "id": "4611",
        "clientCampaign": {
          "id": "f68993d",
          "name": "General - Shared",
          "campaignType": "shared",
          "programGroup": {
            "id": "107",
            "description": "General",
            "client": {
              "id": "184",
              "internalName": "BISK",
              "name": "Florida Tech",
              "__typename": "Client"
            },
            "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
        },
        "degreeProgram": {
          "id": "382",
          "name": "BA in <span class=\"secondary-color\">Business Administration Management</span> Online Degree",
          "__typename": "DegreeProgram"
        },
        "__typename": "LeadTarget"
      }
    ],
    "fallbackLeadTarget": {
      "__typename": "LeadTarget",
      "id": "205",
      "clientCampaign": {
          "__typename": "ClientCampaign",
          "id": "a9ba99e5",
          "name": "YDD Filtered Campaign",
          "campaignType": "shared",
          "programGroup": {
              "__typename": "ProgramGroup",
              "id": "240",
              "description": "YDD Filtered ProgramGroup",
              "client": {
                  "__typename": "Client",
                  "id": "195",
                  "internalName": null,
                  "name": "YDD Filtered Client"
              }
          }
      },
      "degreeProgram": {
          "__typename": "DegreeProgram",
          "id": "696",
          "name": "YDD Filtered DegreeProgram"
      }
    },
    "clientCampaignRules": [
      {
        "id": "369",
        "default": true,
        "position": '1',
        "clientMapping": [
          {
            "clientId": "188",
            "programGroupId": "223",
            "clientCampaignIds": ["724885d4"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "222",
            "clientCampaignIds": ["5c2ecdbf"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "221",
            "clientCampaignIds": ["7ea8c861"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "220",
            "clientCampaignIds": ["2bbb10d5"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "167",
            "clientCampaignIds": ["cbf9395b"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "219",
            "clientCampaignIds": ["7090d2ef"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "218",
            "clientCampaignIds": ["2cd3ee00"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "217",
            "clientCampaignIds": ["2fc9c34b"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "188",
            "programGroupId": "216",
            "clientCampaignIds": ["996697a3"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "29",
            "programGroupId": "9",
            "clientCampaignIds": ["9d1b47c"],
            "__typename": "ClientMapping"
          },
          {
            "clientId": "184",
            "programGroupId": "107",
            "clientCampaignIds": ["f68993d"],
            "__typename": "ClientMapping"
          }
        ],
        "__typename": "ClientCampaignRule"
      }
    ],
    "clientWeights": [
      {
        "clientId": "188",
        "weight": 100,
        "__typename": "ClientWeight"
      }
    ],
    "variables": {
      "heading": "Business Degrees Are Booming!",
      "subheading": "Find The Right Online Business Degree Today",
      "title": "Business Degrees Landing Page",
      "redirectUrlFailure": "https://www.redirect-here.com",
      "trackingPixelFb":"6b219aa6-a7e1-11ed-afa1-0242ac120002",
      "trackingPixelSn":"d088f40a-e9d8-4d97-802b-163ceabde2d6",
      "trackingPixelTt":"C3A7S3OEDD9B746L426G",
      "__typename": "LandingPageVariables"
    },
    "tags": {
      "header":"<script>\n  (function(w,d,s,l,i){\n    w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});\n    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;\n    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n  })(window,document,'script','dataLayer','GTM-XXXXXX');\n</script>",
      "footer":'<script type="text/javascript">\n_linkedin_partner_id = "1622060";\nwindow._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];\nwindow._linkedin_data_partner_ids.push(_linkedin_partner_id);\n</script><script type="text/javascript">\n(function(){var s = document.getElementsByTagName("script")[0];\nvar b = document.createElement("script");\nb.type = "text/javascript";b.async = true;\nb.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";\ns.parentNode.insertBefore(b, s);})();\n</script>',
      "__typename": "LandingPageTags"
    },
    "routes": [
      {
        "id": "2381",
        "hostname": "yourdreamdegree.com",
        "path": "business",
        "__typename": "Route"
      },
      {
        "id": "2382",
        "hostname": "www.yourdreamdegree.com",
        "path": "business",
        "__typename": "Route"
      },
      {
        "id": "2383",
        "hostname": "yourdreamdegrees.com",
        "path": "business",
        "__typename": "Route"
      },
      {
        "id": "2384",
        "hostname": "www.yourdreamdegrees.com",
        "path": "business",
        "__typename": "Route"
      },
      {
        "id": "2385",
        "hostname": "find.degreegrad.com",
        "path": "business",
        "__typename": "Route"
      },
      {
        "id": "2386",
        "hostname": "get.degreegrad.com",
        "path": "business",
        "__typename": "Route"
      }
    ],
    "steps": [
      {
        "id": "4420",
        "position": 1,
        "title": "Select highest level of education:",
        "questions": [
          {
            "id": "8520",
            "position": 1,
            "text": "Highest Education Level",
            "key": "education",
            "options": [
              "High School Grad",
              "Some College (0-29 Credits)",
              "Some College (30-59 Credits)",
              "Some College (60+ Credits)",
              "Associate's Degree",
              "Bachelor's Degree",
              "Master's Degree",
              "GED",
              "No High School Diploma"
            ],
            "fieldMapping": [
              {
                "values": [
                  "High School Diploma/GED",
                  "Some College",
                  "Some College",
                  "Some College",
                  "Associate Degree",
                  "Bachelor Degree",
                  "Master Degree",
                  "High School Diploma/GED",
                  "Current High School Student"
                ],
                "field": "HighestEdu",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": [
                  "2",
                  "4",
                  "4",
                  "4",
                  "5",
                  "7",
                  "10",
                  "2",
                  "1"
                ],
                "field": "Education",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": [
                  "High School",
                  "Some College",
                  "Some College",
                  "Some College",
                  "Associates",
                  "Bachelors",
                  "Masters",
                  "High School",
                  "Currently Enrolled in High School",
                  "RN (Diploma Nurse)",
                  "Some College",
                  "Some Graduate"
                ],
                "field": "EducationLevel",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4421",
        "position": 2,
        "title": "Are you a U.S. Citizen or legal resident?",
        "questions": [
          {
            "id": "8521",
            "position": 1,
            "text": "Are You A U.S. Citizen?",
            "key": "Citizen",
            "options": [
              "Yes",
              "No"
            ],
            "fieldMapping": [
              {
                "values": [
                  "Yes",
                  "No"
                ],
                "field": "Citizen",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": [
                  "1",
                  "0"
                ],
                "field": "Citizen",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": [
                  "Yes",
                  "No"
                ],
                "field": "Citizen",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4422",
        "position": 3,
        "title": "How Old Are You?",
        "questions": [
          {
            "id": "8522",
            "position": 1,
            "text": "What Is Your Age?",
            "key": "Age",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "Age",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Age",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          },
          {
            "id": "8523",
            "position": 2,
            "text": "Your High School Grad Year",
            "key": "GraduationYear",
            "options": [
              "2022",
              "2021",
              "2020",
              "2019",
              "2018",
              "2017",
              "2016",
              "2015",
              "2014",
              "2013",
              "2012",
              "2011",
              "2010",
              "2009",
              "2008",
              "2007",
              "2006",
              "2005",
              "2004",
              "2003",
              "2002",
              "2001",
              "2000",
              "1999",
              "1998",
              "1997",
              "1996",
              "1995",
              "1994",
              "1993",
              "1992",
              "1991",
              "1990",
              "1989",
              "1988",
              "1987",
              "1986",
              "1985",
              "1984",
              "1983",
              "1982",
              "1981",
              "1980",
              "1979",
              "1978",
              "1977",
              "1976",
              "1975",
              "1974",
              "1973",
              "1972",
              "1971",
              "1970",
              "1969",
              "1968",
              "1967",
              "Other",
              "No HS Diploma Yet"
            ],
            "fieldMapping": [
              {
                "values": [
                  "2022",
                  "2021",
                  "2020",
                  "2019",
                  "2018",
                  "2017",
                  "2016",
                  "2015",
                  "2014",
                  "2013",
                  "2012",
                  "2011",
                  "2010",
                  "2009",
                  "2008",
                  "2007",
                  "2006",
                  "2005",
                  "2004",
                  "2003",
                  "2002",
                  "2001",
                  "2000",
                  "1999",
                  "1998",
                  "1997",
                  "1996",
                  "1995",
                  "1994",
                  "1993",
                  "1992",
                  "1991",
                  "1990",
                  "1989",
                  "1988",
                  "1987",
                  "1986",
                  "1985",
                  "1984",
                  "1983",
                  "1982",
                  "1981",
                  "1980",
                  "1979",
                  "1978",
                  "1977",
                  "1976",
                  "1975",
                  "1974",
                  "1973",
                  "1972",
                  "1971",
                  "1970",
                  "1969",
                  "1968",
                  "1967",
                  "Other",
                  "No HS Diploma Yet"
                ],
                "field": "GraduationYear",
                "clientId": "29",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4423",
        "position": 4,
        "title": "Are You or Your Spouse Affiliated With the Military?",
        "questions": [
          {
            "id": "8524",
            "position": 1,
            "text": "Military Affiliation",
            "key": "military",
            "options": [
              "Yes",
              "No"
            ],
            "fieldMapping": [],
            "__typename": "Question"
          },
          {
            "id": "8525",
            "position": 2,
            "text": "Military Status",
            "key": "military_status",
            "options": [
              "Active Duty",
              "Veteran",
              "Military Spouse",
              "Dependent",
              "None"
            ],
            "fieldMapping": [
              {
                "values": [
                  "Servicemember",
                  "Veteran",
                  "Spouse of Servicemember",
                  "Dependent",
                  "None"
                ],
                "field": "MilitaryStatus",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4424",
        "position": 5,
        "title": "When Do You Plan to Enroll in Classes?",
        "questions": [
          {
            "id": "8526",
            "position": 1,
            "text": "When Can You Start Classes?",
            "key": "StartClasses",
            "options": [
              "Immediately",
              "1-3 Months",
              "3-6 Months",
              "More Than 6 Months"
            ],
            "fieldMapping": [
              {
                "values": [
                  "Immediately",
                  "1-3 Months",
                  "3-6 Months",
                  "More Than 6 Months"
                ],
                "field": "StartClasses",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": [
                  "Immediately",
                  "1-3 Months",
                  "3-6 Months",
                  "More Than 6 Months"
                ],
                "field": "StartClasses",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4425",
        "position": 6,
        "title": "What is Your Name?",
        "questions": [
          {
            "id": "8527",
            "position": 1,
            "text": "First Name",
            "key": "FirstName",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "FirstName",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "FirstName",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "FirstName",
                "clientId": "283",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "FirstName",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          },
          {
            "id": "8528",
            "position": 2,
            "text": "Last Name",
            "key": "LastName",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "LastName",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "LastName",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "LastName",
                "clientId": "283",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "LastName",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4426",
        "position": 7,
        "title": "Please Enter Your Email",
        "questions": [
          {
            "id": "8529",
            "position": 1,
            "text": "Email",
            "key": "Email",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "Email",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Email",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Email",
                "clientId": "283",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Email",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4427",
        "position": 8,
        "title": "Please Enter Your Address",
        "questions": [
          {
            "id": "8530",
            "position": 1,
            "text": "Street Address",
            "key": "StreetAddress",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "StreetAddress",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "StreetAddress",
                "clientId": "184",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "StreetAddress",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "StreetAddress",
                "clientId": "283",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          },
          {
            "id": "8531",
            "position": 2,
            "text": "City",
            "key": "City",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "City",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "City",
                "clientId": "184",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "City",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "City",
                "clientId": "283",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          },
          {
            "id": "8532",
            "position": 3,
            "text": "State",
            "key": "State",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "State",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "State",
                "clientId": "184",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "State",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "State",
                "clientId": "283",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          },
          {
            "id": "8533",
            "position": 4,
            "text": "ZIP Code",
            "key": "ZIP_Code",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "ZIP_Code",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "ZIP_Code",
                "clientId": "184",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "ZIP_Code",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "ZIP_Code",
                "clientId": "283",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      },
      {
        "id": "4428",
        "position": 9,
        "title": "Last Step! What is Your Phone Number?",
        "questions": [
          {
            "id": "8534",
            "position": 1,
            "text": "Phone",
            "key": "Phone",
            "options": null,
            "fieldMapping": [
              {
                "values": null,
                "field": "Phone",
                "clientId": "188",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Phone",
                "clientId": "29",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Phone",
                "clientId": "283",
                "__typename": "FieldMapping"
              },
              {
                "values": null,
                "field": "Phone",
                "clientId": "184",
                "__typename": "FieldMapping"
              }
            ],
            "__typename": "Question"
          }
        ],
        "__typename": "Step"
      }
    ],
    "landingPageRules": [
      {
        "id": "1",
        "redirectUrl": null,
        "position": 1,
        "actionType": "exclude_programs",
        "conditions": [
          {
            "id": "1",
            "key": null,
            "operator": "in",
            "value": [
              "21", "22"
            ],
            "question": {
              "id": "8522",
              "key": "Age"
            }
          }
        ],
        "degreeProgramSets": [
          {
            "id": "3",
            "client": {
              "id": "188",
            },
            "degreeProgramIds": ["643"]
          }
        ],
      },
    ],
    "implicitLandingPageRules": [
      {
        "id": "1",
        "questionId": "8521",
        "operator": "in",
        "value": [
          "Yes"
        ],
        "exclusionRule": {
          "id": "815",
          "appliesTo": {
            "__typename": "DegreeProgram",
            "id": "643"
          }
        }
      },
      {
        "id": "2",
        "questionId": "8522",
        "operator": "less_than",
        "value": [
          "18"
        ],
        "exclusionRule": {
          "id": "818",
          "appliesTo": {
            "__typename": "Client",
            "id": "29"
          }
        }
      },

    ],
  }
