import { SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS } from "components/pages/LandingPages/data/constants"
import MultiGroupDropdown, { MultiGroupRadioButton, InputGroupSection } from "components/UI/MultiGroupDropdown"
import { useCallback } from "react"
import { compact, findIndex } from "lodash-es"
import { removeItemAtIndex, replaceItemAtIndex } from "components/pages/LandingPages/data/utils"

const buildFieldMapEntry = function(clientId, values, field) {
  return {clientId: clientId, field: field, values: values}
}

const FieldMapEntry = function({ fieldMapping, setFieldMapping, client, index, questionOptions }) {
  const numQuestionOptions = questionOptions?.length || 0
  const fieldMapEntry = fieldMapping[index]

  const removeFieldMapEntry = useCallback(() => {
    setFieldMapping(removeItemAtIndex(fieldMapping, index))
  },[fieldMapping, index, setFieldMapping])

  const setFieldMapEntry = useCallback(newFieldMapEntry => {
      setFieldMapping(replaceItemAtIndex(fieldMapping, newFieldMapEntry, index))
  }, [fieldMapping, index, setFieldMapping])

  const handleChange = useCallback(event => {
    if(SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS.includes(event.target.value)) {
      setFieldMapEntry({clientId: client.id, field: event.target.value})
    } else {
      const blankValues = new Array(numQuestionOptions).fill(null)
      setFieldMapEntry(buildFieldMapEntry(client.id, blankValues, event.target.value))
    }
  }, [client.id, numQuestionOptions, setFieldMapEntry])

  return client && (
    <InputGroupSection
      title={client.internalName || client.name}
      role='radiogroup'
    >
      {client.fields.map((field) =>
      ( (field.options && questionOptions) ||
         (!field.options && !questionOptions) ) &&
      (
        <MultiGroupRadioButton
          key={`client_field_${client.id}_${field.name}`}
          value={field.name}
          checked={!fieldMapEntry && field.name === 'None' ? true : fieldMapEntry?.field === field.name }
          onChange={handleChange}
          label={field.name}
        />
      ))}
      <MultiGroupRadioButton
        value={'None'}
        checked={!fieldMapEntry}
        onChange={removeFieldMapEntry}
        label="None"
      />
    </InputGroupSection>
  )
}

export function FieldMappingDropdown({ clientFieldGroups, question, setFieldMapping }) {
  const mappedFieldCount = question.fieldMapping.length
  const totalFieldCount = clientFieldGroups.length
  const unmappedFields = totalFieldCount > mappedFieldCount
  const renderFieldMapping = useCallback(value => compact([unmappedFields ? 'None' : null, ...new Set(value.map(entry => entry.field))]).join(', '), [unmappedFields])

  return (
    <MultiGroupDropdown
      value={question.fieldMapping}
      renderValue={renderFieldMapping}
      inputLabel={`Question Match (${mappedFieldCount}/${totalFieldCount})`}
    >
      {clientFieldGroups.map((clientFieldGroup, _clientFieldGroupIndex) => (
        <FieldMapEntry
          key={`${clientFieldGroup.client.id}_${clientFieldGroup.client.name}`}
          fieldMapping={question.fieldMapping}
          setFieldMapping={setFieldMapping}
          client={clientFieldGroup.client}
          index={findIndex(question.fieldMapping, { clientId: clientFieldGroup.client.id })}
          questionOptions={question.options}
        />
      ))}
    </MultiGroupDropdown>
  )
}
