import Button, { ButtonProps } from "@mui/material/Button";
import COLORS from 'lib/colors'

export default function CygIconButton({
    text,
    icon,
    onClick,
    dashedOutline = false,
    disabled = false,
    sx = {},
    ...props
  } : {
    text: string,
    icon: React.ReactNode,
    onClick: (() => void),
    dashedOutline?: boolean,
    disabled?: boolean,
  } & ButtonProps) {

  const borderColor = disabled ? COLORS.slateGray : COLORS.beauBlue

  const overrideStyle = dashedOutline ? {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${encodeURIComponent(borderColor)}' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    border: 'none !important',
    height: '48px'
  } : {}

  return (
    <Button
      startIcon={icon}
      variant='outlined'
      onClick={onClick}
      sx={{
        fontSize: '16px',
        color: disabled ? COLORS.slateGray : COLORS.frenchBlue,
        backgroundColor: disabled ? COLORS.veryLightGray : 'white',
        borderColor: disabled ? COLORS.slateGray : COLORS.beauBlue,
        ...overrideStyle,
        ".MuiButton-startIcon": {
          marginRight: '12px',
          marginLeft: '-4px !important',
        },
        "svg": {
          fontSize: '24px !important',
          color: disabled ? COLORS.slateGray : COLORS.frenchBlue,
        },
        ...sx
      }}
      {...props}
    >
      {text}
    </Button>
  )
}
