import { HeaderTabs } from "./LandingPageEditNavigation";

export default {
  title: "Components/LandingPageEdit/HeaderTabs",
  component: HeaderTabs,
  parameters: {
    zeplinLink: "https://zpl.io/Z09PwmJ",
  },
};

const Template = (args) => {
  return <HeaderTabs {...args} />;
};

export const Default = Template.bind({});
Default.args = {
  selectedTab: "main",
  landingPageId: "123",
  persistedErrors: { main: [], questions: [], rules: [], tracking: [] },
  formErrors: [],
};

export const AllErrors = Template.bind({});
AllErrors.args = {
  ...Default.args,
  persistedErrors: {
    main: ["Error one"],
    questions: ["Error one", "Error two"],
    rules: ["Error"],
    tracking: ["Error one", "Error two", "Error three", "Error four"],
  },
};

export const NewLandingPage = Template.bind({});
NewLandingPage.args = {
  ...Default.args,
  landingPageId: undefined,
};
