import React from "react";

import { http, HttpResponse } from "msw";
import moment from 'moment-timezone'
import DailyCapModal from "./DailyCapModal";
import ClientCampaignsResource from "../../resources/ClientCampaignsResource";
import { useOrbit } from "providers/OrbitProvider";

export default {
  title: "Components/DailyCapModal",
  component: DailyCapModal,
};

const contract = {
  type: "contract",
  id: "dbe2b437-d8fc-4a9a-ad24-01cf170eb6a5",
  keys: {
    remoteId: "45",
  },
  relationships: {
    client: {
      data: {
        type: "client",
        id: "123",
      },
    },
    vendor: {
      data: {
        type: "vendor",
        id: "b472d1af-b00a-45fc-a4c8-59f8f449c2b3",
      },
    },
  },
};

const month = moment("2022-02-02T04:00:00Z");

const Template = (args) => {
  const { remote } = useOrbit()
  const clientCampaignsResource = new ClientCampaignsResource({
    remote,
    contractOrClient: contract,
    year: month.year(),
    month: month.month() + 1,
  });
  return (
    <DailyCapModal
      {...args}
      clientCampaignsResource={clientCampaignsResource}
    />
  );
};

export const Default = Template.bind({});
Default.args = {
  contract: contract,
  campaignCaps: [
    {
      clientCampaign: {
        type: "clientCampaign",
        id: "e5b35c31-6823-4a6f-b2fb-e82cd6e96f72",
        keys: {
          remoteId: "33",
        },
        attributes: {
          name: "Healthcare Management - Exclusive",
          campaignType: "exclusive",
          status: "active",
          billableLeadCount: 0,
        },
        relationships: {
          contract: {
            data: {
              type: "contract",
              id: "f1cce329-2722-41ce-819c-fd4c9e1fec83",
            },
          },
          programGroup: {
            data: {
              type: "programGroup",
              id: "96",
            },
          },
        },
      },
      cap: 0,
    },
    {
      clientCampaign: {
        type: "clientCampaign",
        id: "5e929b4c-1d51-4967-971c-672a16071752",
        keys: {
          remoteId: "34",
        },
        attributes: {
          name: "Master's Programs - Exclusive",
          campaignType: "exclusive",
          status: "active",
          billableLeadCount: 0,
        },
        relationships: {
          contract: {
            data: {
              type: "contract",
              id: "f1cce329-2722-41ce-819c-fd4c9e1fec83",
            },
          },
          programGroup: {
            data: {
              type: "programGroup",
              id: "97",
            },
          },
        },
      },
      cap: 0,
    },
    {
      clientCampaign: {
        type: "clientCampaign",
        id: "50674733-3963-4f47-bd28-81ba67c650d1",
        keys: {
          remoteId: "135",
        },
        attributes: {
          name: "Healthcare Management - Shared",
          campaignType: "shared",
          status: "active",
          billableLeadCount: 0,
        },
        relationships: {
          contract: {
            data: {
              type: "contract",
              id: "f1cce329-2722-41ce-819c-fd4c9e1fec83",
            },
          },
          programGroup: {
            data: {
              type: "programGroup",
              id: "96",
            },
          },
        },
      },
      cap: 0,
    },
    {
      clientCampaign: {
        type: "clientCampaign",
        id: "0c06ed34-f3c6-4d9d-909a-bda07325a984",
        keys: {
          remoteId: "136",
        },
        attributes: {
          name: "Master's Programs - Shared",
          campaignType: "shared",
          status: "active",
          billableLeadCount: 0,
        },
        relationships: {
          contract: {
            data: {
              type: "contract",
              id: "f1cce329-2722-41ce-819c-fd4c9e1fec83",
            },
          },
          programGroup: {
            data: {
              type: "programGroup",
              id: "97",
            },
          },
        },
      },
      cap: 0,
    },
  ],
  month: month,
};
Default.parameters = {
  msw: {
    handlers: [
      http.get("/api/v1/contracts/client-campaign-groups", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "3",
            type: "client-campaign-groups",
            attributes: {
              id: 3,
              name: "Exclusive",
              "contract-id": 44,
              "campaign-type": "exclusive",
              "created-at": "2021-10-26T13:54:56.764Z",
              "updated-at": "2021-10-26T13:54:56.764Z",
              "discarded-at": null,
              "client-campaign-ids": [],
            },
            relationships: {
              "client-campaigns": { data: [] },
              "daily-caps": {
                data: [
                  { id: "13", type: "daily-caps" },
                  { id: "4", type: "daily-caps" },
                  { id: "3", type: "daily-caps" },
                  { id: "11", type: "daily-caps" },
                  { id: "19", type: "daily-caps" },
                  { id: "23", type: "daily-caps" },
                  { id: "24", type: "daily-caps" },
                  { id: "25", type: "daily-caps" },
                  { id: "6", type: "daily-caps" },
                  { id: "15", type: "daily-caps" },
                  { id: "21", type: "daily-caps" },
                  { id: "7", type: "daily-caps" },
                  { id: "16", type: "daily-caps" },
                  { id: "20", type: "daily-caps" },
                  { id: "26", type: "daily-caps" },
                  { id: "27", type: "daily-caps" },
                  { id: "28", type: "daily-caps" },
                  { id: "29", type: "daily-caps" },
                  { id: "30", type: "daily-caps" },
                  { id: "31", type: "daily-caps" },
                  { id: "32", type: "daily-caps" },
                  { id: "33", type: "daily-caps" },
                  { id: "34", type: "daily-caps" },
                  { id: "35", type: "daily-caps" },
                  { id: "36", type: "daily-caps" },
                  { id: "37", type: "daily-caps" },
                  { id: "38", type: "daily-caps" },
                  { id: "39", type: "daily-caps" },
                  { id: "40", type: "daily-caps" },
                  { id: "41", type: "daily-caps" },
                  { id: "42", type: "daily-caps" },
                  { id: "48", type: "daily-caps" },
                  { id: "49", type: "daily-caps" },
                  { id: "50", type: "daily-caps" },
                  { id: "51", type: "daily-caps" },
                  { id: "105", type: "daily-caps" },
                  { id: "106", type: "daily-caps" },
                  { id: "107", type: "daily-caps" },
                  { id: "43", type: "daily-caps" },
                  { id: "44", type: "daily-caps" },
                  { id: "45", type: "daily-caps" },
                  { id: "46", type: "daily-caps" },
                  { id: "47", type: "daily-caps" },
                  { id: "52", type: "daily-caps" },
                  { id: "86", type: "daily-caps" },
                  { id: "87", type: "daily-caps" },
                  { id: "88", type: "daily-caps" },
                  { id: "89", type: "daily-caps" },
                  { id: "90", type: "daily-caps" },
                  { id: "91", type: "daily-caps" },
                  { id: "92", type: "daily-caps" },
                  { id: "93", type: "daily-caps" },
                  { id: "94", type: "daily-caps" },
                  { id: "95", type: "daily-caps" },
                  { id: "96", type: "daily-caps" },
                  { id: "97", type: "daily-caps" },
                  { id: "98", type: "daily-caps" },
                  { id: "99", type: "daily-caps" },
                  { id: "100", type: "daily-caps" },
                  { id: "101", type: "daily-caps" },
                  { id: "102", type: "daily-caps" },
                  { id: "103", type: "daily-caps" },
                  { id: "104", type: "daily-caps" },
                  { id: "108", type: "daily-caps" },
                  { id: "109", type: "daily-caps" },
                  { id: "110", type: "daily-caps" },
                  { id: "111", type: "daily-caps" },
                  { id: "112", type: "daily-caps" },
                  { id: "152", type: "daily-caps" },
                  { id: "113", type: "daily-caps" },
                  { id: "114", type: "daily-caps" },
                  { id: "115", type: "daily-caps" },
                  { id: "116", type: "daily-caps" },
                  { id: "148", type: "daily-caps" },
                  { id: "149", type: "daily-caps" },
                  { id: "150", type: "daily-caps" },
                  { id: "151", type: "daily-caps" },
                  { id: "153", type: "daily-caps" },
                  { id: "154", type: "daily-caps" },
                  { id: "155", type: "daily-caps" },
                  { id: "156", type: "daily-caps" },
                  { id: "157", type: "daily-caps" },
                  { id: "159", type: "daily-caps" },
                  { id: "163", type: "daily-caps" },
                  { id: "160", type: "daily-caps" },
                  { id: "161", type: "daily-caps" },
                  { id: "162", type: "daily-caps" },
                  { id: "164", type: "daily-caps" },
                  { id: "165", type: "daily-caps" },
                  { id: "166", type: "daily-caps" },
                  { id: "169", type: "daily-caps" },
                  { id: "171", type: "daily-caps" },
                  { id: "167", type: "daily-caps" },
                  { id: "168", type: "daily-caps" },
                  { id: "170", type: "daily-caps" },
                  { id: "172", type: "daily-caps" },
                  { id: "173", type: "daily-caps" },
                  { id: "158", type: "daily-caps" },
                  { id: "174", type: "daily-caps" },
                  { id: "175", type: "daily-caps" },
                  { id: "176", type: "daily-caps" },
                  { id: "177", type: "daily-caps" },
                  { id: "178", type: "daily-caps" },
                  { id: "425", type: "daily-caps" },
                  { id: "427", type: "daily-caps" },
                  { id: "428", type: "daily-caps" },
                  { id: "429", type: "daily-caps" },
                  { id: "430", type: "daily-caps" },
                  { id: "431", type: "daily-caps" },
                  { id: "432", type: "daily-caps" },
                  { id: "433", type: "daily-caps" },
                  { id: "435", type: "daily-caps" },
                  { id: "436", type: "daily-caps" },
                  { id: "442", type: "daily-caps" },
                  { id: "443", type: "daily-caps" },
                  { id: "449", type: "daily-caps" },
                  { id: "450", type: "daily-caps" },
                  { id: "426", type: "daily-caps" },
                  { id: "441", type: "daily-caps" },
                  { id: "439", type: "daily-caps" },
                  { id: "440", type: "daily-caps" },
                  { id: "444", type: "daily-caps" },
                  { id: "445", type: "daily-caps" },
                  { id: "446", type: "daily-caps" },
                  { id: "447", type: "daily-caps" },
                  { id: "448", type: "daily-caps" },
                  { id: "451", type: "daily-caps" },
                  { id: "452", type: "daily-caps" },
                  { id: "453", type: "daily-caps" },
                  { id: "434", type: "daily-caps" },
                  { id: "437", type: "daily-caps" },
                  { id: "438", type: "daily-caps" },
                  { id: "454", type: "daily-caps" },
                  { id: "613", type: "daily-caps" },
                  { id: "614", type: "daily-caps" },
                  { id: "615", type: "daily-caps" },
                ],
              },
              contract: { data: { id: "44", type: "contracts" } },
            },
          },
            {
              id: "10",
              type: "client-campaign-groups",
              attributes: {
                id: 10,
                name: "testing testing 1 2 3 ",
                "contract-id": 44,
                "campaign-type": null,
                "created-at": "2021-10-26T19:52:58.406Z",
                "updated-at": "2021-10-26T19:52:58.406Z",
                "discarded-at": null,
                "client-campaign-ids": [102, 128, 129, 131],
              },
              relationships: {
                "client-campaigns": {
                  data: [
                    { id: "102", type: "client-campaigns" },
                    { id: "128", type: "client-campaigns" },
                    { id: "129", type: "client-campaigns" },
                    { id: "131", type: "client-campaigns" },
                  ],
                },
                "daily-caps": {
                  data: [
                    { id: "510", type: "daily-caps" },
                    { id: "511", type: "daily-caps" },
                    { id: "512", type: "daily-caps" },
                    { id: "513", type: "daily-caps" },
                    { id: "514", type: "daily-caps" },
                    { id: "515", type: "daily-caps" },
                    { id: "516", type: "daily-caps" },
                    { id: "518", type: "daily-caps" },
                    { id: "519", type: "daily-caps" },
                    { id: "520", type: "daily-caps" },
                    { id: "521", type: "daily-caps" },
                    { id: "522", type: "daily-caps" },
                    { id: "523", type: "daily-caps" },
                    { id: "524", type: "daily-caps" },
                    { id: "525", type: "daily-caps" },
                    { id: "526", type: "daily-caps" },
                    { id: "527", type: "daily-caps" },
                    { id: "528", type: "daily-caps" },
                    { id: "529", type: "daily-caps" },
                    { id: "530", type: "daily-caps" },
                    { id: "531", type: "daily-caps" },
                    { id: "532", type: "daily-caps" },
                    { id: "533", type: "daily-caps" },
                    { id: "534", type: "daily-caps" },
                    { id: "535", type: "daily-caps" },
                    { id: "536", type: "daily-caps" },
                    { id: "537", type: "daily-caps" },
                    { id: "572", type: "daily-caps" },
                    { id: "538", type: "daily-caps" },
                    { id: "606", type: "daily-caps" },
                  ],
                },
                contract: { data: { id: "44", type: "contracts" } },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/*client-campaigns*", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Healthcare Management - Exclusive",
              "campaign-type": "exclusive",
              "billable-lead-count": 0,
            },
            relationships: {
              contract: { data: { id: "45", type: "contracts" } },
              "program-group": {
                data: { id: "96", type: "program-groups" },
              },
            },
          },
          {
            id: "34",
            type: "client-campaigns",
            attributes: {
              name: "Master's Programs - Exclusive",
              "campaign-type": "exclusive",
              "billable-lead-count": 0,
            },
            relationships: {
              contract: { data: { id: "45", type: "contracts" } },
              "program-group": {
                data: { id: "97", type: "program-groups" },
              },
            },
          },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Healthcare Management - Shared",
                "campaign-type": "shared",
                "billable-lead-count": 0,
              },
              relationships: {
                contract: { data: { id: "45", type: "contracts" } },
                "program-group": {
                  data: { id: "96", type: "program-groups" },
                },
              },
            },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Master's Programs - Shared",
                "campaign-type": "shared",
                "billable-lead-count": 0,
              },
              relationships: {
                contract: { data: { id: "45", type: "contracts" } },
                "program-group": {
                  data: { id: "97", type: "program-groups" },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/daily-caps*", (_request, _params, _cookies) => {
        return HttpResponse.json({ data: [] });
      }),
    ],
  },
};
