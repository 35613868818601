import { gql } from '@apollo/client';


export const STEPS_FIELDS = gql`
  fragment StepsFields on Step {
    id
    position
    title
    questions {
      id
      position
      text
      key
      options
      fieldMapping {
        values
        field
        clientId
      }
    }
  }`
