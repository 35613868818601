import Box from "@mui/material/Box";
import FooterBar from "./";
import BookendsProvider, { useBookends } from "lib/BookendsProvider";
import { useEffect } from "react";
import { action } from "@storybook/addon-actions";

export default {
  title: "UI/FooterBar",
  component: FooterBar,
};

const FooterBarWrapper = (props) => {
  const { setBookends } = useBookends();

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: "primary",
            label: "Test Button",
            onClick: action("Clicked button"),
          },
        ],
      },
    });
  }, [setBookends]);

  return <FooterBar {...props} />;
};

const Template = (args) => {
  return (
    <BookendsProvider>
      <Box sx={{ pt: "20px" }}>
        <FooterBarWrapper {...args} />
      </Box>
    </BookendsProvider>
  );
};

export const Default = Template.bind({});
Default.args = {};

export const WithPersistedErrors = Template.bind({});
WithPersistedErrors.args = {
  errorMessage: "Something you've done elsewhere requires changes here!",
};
