import { useCallback, useEffect, useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import DeleteIcon from '@mui/icons-material/Delete'

import Options from './Options'
import { FormCheckbox, FormSelect, FormTextField, getIn, useFormikContext } from '../Formik/forms'
import { constantize } from '../../lib/utils'
import Box from '@mui/material/Box'
import MainContainer from 'components/layout/MainContainer/MainContainer'

const FIELD_TYPES = [
  { text: 'First Name', value: 'first_name' },
  { text: 'Last Name', value: 'last_name' },
  { text: 'Phone', value: 'phone' },
  { text: 'Email', value: 'email' },
  { text: 'Street Address', value: 'street_address' },
  { text: 'City', value: 'city' },
  { text: 'State', value: 'state' },
  { text: 'ZIP code', value: 'zip_code', field_name: 'ZIP_Code' },
  { text: 'Custom drop-down', value: 'custom_select' },
  { text: 'Custom free-text', value: 'custom_text' },
]

const FIELD_TYPE_TO_NAME = FIELD_TYPES
  .reduce((mapping, { value, field_name }) => { if(field_name) { mapping[value] = field_name } return mapping }, {})

export default function Field({item: field, path, onRemove}) {
  const { setFieldValue, handleChange } = useFormikContext()
  const fieldType = useMemo(() => getIn(field, 'field_type'), [field])
  const defaultName = useMemo(() => FIELD_TYPE_TO_NAME[fieldType] || constantize(fieldType), [fieldType])
  const name = useMemo(() => getIn(field, 'name'), [field])
  const handleNameChange = useCallback(event => { setCustomName(true); handleChange(event) }, [handleChange])
  const isCustom = useMemo(() => fieldType === 'custom_select' || fieldType === 'custom_text', [fieldType])
  const [customName, setCustomName] = useState(() => !!name && name != defaultName)

  useEffect(() => {
    if(!isCustom && !customName) {
      setFieldValue(`${path}.name`, defaultName)
    }
  }, [fieldType, isCustom, customName, path, setFieldValue, defaultName])

  return (
    <MainContainer>
      <Box sx={{ '.MuiTextField-root': { marginBottom: '4px' }, '.MuiCheckbox-root': { marginY: '0px' } }} >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormSelect
              sx={{width: 200}}
              label="Type"
              name={`${path}.field_type`}
            >
              {FIELD_TYPES.map(({text, value}, index) => (
                <MenuItem key={index} value={value}>{text}</MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item sx={{width: 200}}>
            <FormTextField
              label="Name"
              name={`${path}.name`}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item sx={{flexGrow: 1}}>
            <FormTextField
              label="Question text"
              name={`${path}.description`}
            />
          </Grid>
          <Grid item>
            <FormCheckbox
              label="Required?"
              name={`${path}.required`}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={onRemove} aria-label="Remove field">
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
        {fieldType === 'custom_select' && (
          <Options
            path={`${path}.options`}
          />
        )}
      </Box>
    </MainContainer>
  )
}
