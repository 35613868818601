import { useCallback, useMemo } from 'react'
import { getIn, useFormikContext } from '../Formik/forms'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

function Option({option, options, setOptions, index}) {
  const spliceOption = useCallback((...optionsToSplice) => {
    const newOptions = [...options.slice(0, index), ...optionsToSplice, ...options.slice(index+1)]
    setOptions(newOptions)
  }, [options, setOptions, index])

  const handleChange = useCallback(event => {
    spliceOption({...option, [event.target.name]: event.target.value})
  }, [option, spliceOption])

  const handleBlur = useCallback(event => {
    const value = event.target.value
    const trimmed = value.trim()
    const newOption = { ...option, [event.target.name]: trimmed }
    if(newOption.text === '' && newOption.value === '') {
      spliceOption() // Remove the option entirely
    }
    else if(trimmed !== value) {
      spliceOption(newOption)
    }
  }, [option, spliceOption])

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <TextField
          variant="standard"
          label="Display Text"
          value={option.text}
          name="text"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item>
        <TextField
          variant="standard"
          label="Value"
          value={option.value}
          name="value"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  )
}

export default function Options({path}) {
  const { values, setFieldValue } = useFormikContext()
  const options = useMemo(() => getIn(values, path) || [], [values, path])
  const optionsPlusPlaceholder = useMemo(() => [...options, { text: '', value: '' }], [options])
  const setOptions = useCallback(newOptions => {
    setFieldValue(path, newOptions)
  }, [path, setFieldValue])

  return (
    <Box sx={{boxShadow: 1, display: 'inline-block', ml: 2, px: 2, py: 1}}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3" my={2}>Options</Typography>
        </Grid>
        {optionsPlusPlaceholder.map((option, index) => (
          <Grid item key={index}>
            <Option option={option} options={options} setOptions={setOptions} index={index}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
