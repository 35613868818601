import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {ReorderableHandle} from './Reorderable';
import { ChangeEvent } from 'react';

const OPERATORS = {
  'in': 'is one of the following:',
  'not_in': 'is not any of the following:',
  'less_than': 'is less than',
  'greater_than': 'is greater than',
};

export type Operator = keyof typeof OPERATORS

export interface Option {
  text: string
  value: string
}

interface ValueProps {
  index: number
  options?: Option[]
  canDelete: boolean
  value: string[]
  setValue: (v: string[]) => unknown
}

function Value({
  index,
  options,
  canDelete,
  value,
  setValue,
} : ValueProps) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    value[index] = event.target.value
    setValue(value)
  }

  return (
    <div>
      {options && options.length > 0 && (
        <TextField
          sx={{minWidth: 240}}
          select
          aria-label="Value"
          value={value[index] || ''}
          onChange={onChange}
          SelectProps={{
            displayEmpty: true
          }}
          variant="standard"
        >
          <MenuItem disabled value=""><Typography color="textSecondary">Value</Typography></MenuItem>
          {options.map(option => (
            typeof(option) === 'object' ? (
              <MenuItem value={option.value} key={option.value}>{option.text}</MenuItem>
            ) : (
              <MenuItem value={option} key={option}>{option}</MenuItem>
            )
          ))}
        </TextField>
      ) || (
        <TextField
          sx={{minWidth: 240}}
          placeholder="Value"
          value={value[index] || ''}
          onChange={onChange}
          variant="standard"
        />
      )}
      {canDelete && (
        <IconButton size="small" tabIndex={-1} onClick={() => { value.splice(index, 1); setValue(value); }}>
          <DeleteIcon/>
        </IconButton>
      )}
    </div>
  );
}

export interface RuleProps<EntityId extends string | number> {
  entityName: string
  entityId: EntityId
  setEntityId: (v: string) => unknown
  entities: Record<EntityId, string>
  entityOptions?: Option[]
  operator: Operator
  setOperator: (o: Operator) => unknown
  value: string[]
  setValue: (v: string[]) => unknown
  canBeEmpty?: boolean
  reorderable?: boolean
  onRemove?: () => void
}

export default function Rule<EntityId extends string | number>({
  entityName,
  entityId,
  setEntityId,
  entities,
  entityOptions,
  operator,
  setOperator,
  value,
  setValue,
  canBeEmpty = false,
  reorderable = false,
  onRemove,
} : RuleProps<EntityId>) {
  if(!Array.isArray(value)) {
    value = []
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          {reorderable && (
            <Grid item>
              <ReorderableHandle/>
            </Grid>
          )}

          {onRemove && (
            <Grid item>
              <IconButton onClick={onRemove} size="large">
                <RemoveCircleIcon/>
              </IconButton>
            </Grid>
          )}

          <Grid item>
            <TextField
              sx={{minWidth: 180}}
              select
              InputLabelProps={{
                shrink: true,
              }}
              label={entityName}
              value={entityId || ''}
              onChange={event => setEntityId(event.target.value)}
              SelectProps={{
                displayEmpty: canBeEmpty
              }}
            >
              {canBeEmpty && (
                <MenuItem value="">None</MenuItem>
              )}
              {Object.keys(entities).sort().map(id => (
                <MenuItem value={id} key={id}>{entities[id]}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {entityId && (
        <>
          <Grid item>
            <TextField
              sx={{minWidth: 180}}
              select
              InputLabelProps={{
                shrink: true,
              }}
              label="Operator"
              value={operator || ''}
              onChange={event => setOperator(event.target.value as Operator)}
            >
              {Object.entries(OPERATORS).map(([operator, description]) => (
                  <MenuItem value={operator} key={operator}>{description}</MenuItem>
                )
              )}
            </TextField>
          </Grid>

          <Grid item>
            <div>
              {(operator === 'in' || operator === 'not_in') && (
                value.concat('').map((_, index) => (
                  <Value key={index} index={index} value={value} setValue={setValue} options={entityOptions} canDelete={index < value.length} />
                ))
              ) || (
                <Value index={0} value={value} setValue={setValue} options={entityOptions} canDelete={false} />
              )}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  )
}
