import { useCallback, useMemo } from 'react'
import ResourceItemSelector from './ResourceItemSelector'
import ContractsResource from '../resources/ContractsResource'

import { getIn } from './Formik/forms'
import { titlecase } from '../lib/utils'
import PromisedResource from 'resources/PromisedResource'
import { useOrbit } from 'providers/OrbitProvider'

export const ALL_CONTRACT = {
  type: 'contract',
  id: 'all-contract-option',
  keys: {},
  attributes: {},
  relationships: {},
}

export function isAllContract(contract) {
  return contract ? contract.id === ALL_CONTRACT.id : false
}

class WrapperResource extends PromisedResource<ContractsResource> {
  constructor(resource, allOption) {
    super(resource.promise.then(result => {
      return result.length > 1 ? [allOption, ...result] : result
    }))
  }
}

export default function ContractSelector({ contract, setContract, model, clientId = '', showAll = false }) {
  const { remote, store } = useOrbit()

  const contractsResource = useMemo(() => {
    const actualContractsResource = new ContractsResource({remote, clientId})

    if(showAll) {
      const wrapper = new WrapperResource(actualContractsResource, ALL_CONTRACT)
      return wrapper
    }
    else {
      return actualContractsResource
    }
  }, [clientId, remote, showAll])


  const renderItem = useCallback((itemOption) => {
    if(itemOption.id === ALL_CONTRACT.id) {
      return "-- All Vendors --"
    } else {
      return getIn(store.cache.query(q => q.findRelatedRecord(itemOption, model)), 'attributes.name')
    }
  }, [model, store])

  return (
    <ResourceItemSelector
      resource={contractsResource}
      sortSpec={renderItem}
      item={contract}
      setItem={setContract}
      label={titlecase(model)}
      renderItem={renderItem}
      selectFirstByDefault={true}
    />
  )
}
