import Dropdown from './UI/Dropdown'
import Suspenseful from './Suspenseful'
import { sortBy } from '../lib/utils'

function ResourceItemSelector({
  resource,
  sortSpec = null,
  ...props
}) {
  if (!resource) return null
  let collection = resource.get()
  if(sortSpec !== null) {
    collection = sortBy(collection, sortSpec)
  }

  return (
    <Dropdown collection={collection} {...props} />
  )
}

export default function ResourceItemSelectorWrapper(props) {
  const { sx } = props

  return (
    <Suspenseful
      component={ResourceItemSelector}
      fallback={
        <Dropdown label={props.label} loading={true} sx={sx} />
      }
      {...props}
    />
  )
}
