import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

export default function MainContainer({ children }) {
return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        overflow: 'auto',
      }}
    >
      <Container
        sx={{
          padding: '24px',
          margin: 0,
        }}
      >
        {children}
      </Container>
    </Box>
  )
}
