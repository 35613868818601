import { Path } from './Path'
import DefaultedTextField from '../../../shared/DefaultedTextField'
import GridItem from '../../../shared/GridItem'


export function PortalMainForm() {
  return (
    <>
      <GridItem>
        <DefaultedTextField label="Landing Page Name" name="name" required />
      </GridItem>

      <GridItem>
        <Path />
      </GridItem>

      <GridItem fullWidth>
        <DefaultedTextField
          label="Page Title"
          name="variables.title"
          defaultValue='Earn Your Degree'
          required
        />
      </GridItem>

      <GridItem>
        <DefaultedTextField label="Heading" name="variables.heading" />
      </GridItem>

      <GridItem>
        <DefaultedTextField label="Subheading" name="variables.subheading" />
      </GridItem>
    </>
  )
}
