import { useMemo, useState } from 'react'
import { getRemoteId } from '../../../lib/DataModel'
import { useTimezone } from '../../../lib/TimezoneProvider'

import ClientsResource from 'resources/ClientsResource'
import Dashboard from '../Dashboard/Dashboard'
import CurrentMonthPacingResource from '../../../resources/CurrentMonthPacingResource'
import DailyCountsResource from '../../../resources/DailyCountsResource'
import { useRefreshable } from 'lib/hooks'
import { useOrbit } from 'providers/OrbitProvider'

export default function GlobalDashboard() {
  const { store, remote } = useOrbit()
  const [clientsResource] = useState(() => new ClientsResource({ remote }))
  const { timezone } = useTimezone()
  const [currentMonthPacingResource, refreshCurrentMonthPacingResource] = useRefreshable(() => new CurrentMonthPacingResource({ store, remote, timezone: timezone }), [timezone])
  const dailyCountsResource = useMemo(() => new DailyCountsResource({remote, timezone}), [remote, timezone])
  const clientMapPromise = useMemo(() => clientsResource.promise.then(clients => Object.fromEntries(clients.map(client => [getRemoteId(client), client.attributes.internalName || client.attributes.name]))), [clientsResource])
  const groupingColumns = useMemo(() => [
    { title: 'Client', field: 'clientId', lookup: clientMapPromise, summary: { text: 'Total' }, visible: true },
  ], [clientMapPromise])

  return (
    <Dashboard
      groupingColumns={groupingColumns}
      pacingTableProps={{clientMap: clientMapPromise}}
      dailyCountsResource={dailyCountsResource}
      currentMonthPacingResource={currentMonthPacingResource}
      refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource}
      dashboardUrl="/api/v1/dashboard"
    />
  )
}
