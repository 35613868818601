import { useMutation } from "@apollo/client"
import FormWrapper from "components/Formik/FormWrapper"
import { UPDATE_LANDING_PAGE_MUTATION, buildMutation } from "components/pages/LandingPages/data/mutations"
import { getErrorMessage } from "components/pages/LandingPages/data/utils"
import { trackingSchema } from "components/pages/LandingPages/data/yupSchemas"
import SectionTitle from "components/UI/SectionTitle"
import { PageSection } from "components/UI/Structure/PageSection"
import StyledCard, { ContentWrapper } from "components/UI/StyledCard"
import { useFormikContext } from "formik"
import { useNotifications } from "lib/NotificationsProvider"
import { useCallback, useContext, useEffect } from "react"
import { useLandingPageStateContext, useLandingPageActionsContext } from "../../LandingPageProvider"
import { useBookends } from 'lib/BookendsProvider'
import { FormCheckbox, FormTextField } from "components/Formik/forms"
import Grid from "@mui/material/Grid"
import ExpandingTextField from "components/UI/ExpandingTextField/ExpandingTextField"
import DefaultedTextField from "components/pages/LandingPages/shared/DefaultedTextField"
import { NavigationContext } from "../Navigation/LandingPageEditNavigation"
import { get } from "lodash-es"


function ConversionPageCard() {
  const { landingPage } = useLandingPageStateContext()
  return (
    <StyledCard
      title='Conversion Page'
      isSubsection
      hasStickyHeader
    >
      <ContentWrapper>
        <Grid container direction="row" spacing={2} mt={2} >
          <Grid item xs={12} >
            <ExpandingTextField label="Header Conversion Tags" name="tags.conversionHeaderSuccess" />
          </Grid>
          <Grid item xs={12} >
            <ExpandingTextField label="Footer Conversion Tags" name="tags.conversionFooterSuccess" />
          </Grid>
          <Grid item xs={12} >
            <ExpandingTextField label="Header Tags" name="tags.conversionHeader" />
          </Grid>
          <Grid item xs={12} >
            <ExpandingTextField label="Footer Tags" name="tags.conversionFooter" />
          </Grid>
          <Grid item xs={12} >
            <FormTextField label="Continue URL" name="variables.continueUrl" />
          </Grid>
          <Grid item xs={12} >
            <FormCheckbox
              name="variables.showClock"
              label="Show clock"
              defaultValue={get(landingPage.skeletonLandingPage, "variables.showClock") ?? true}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    </StyledCard>
  )
}

function TrackingIdsCard() {
  const { values } = useFormikContext()
  const jornayaEnabled = values.variables.jornayaEnabled

  return (
    <StyledCard
      title='Tracking IDs'
      isSubsection
      hasStickyHeader
    >
      <ContentWrapper>
        <Grid container direction="row" spacing={2} mt={2} >
          <Grid item xs={12} md={6} >
            <DefaultedTextField label="Facebook Pixel ID" name="variables.trackingPixelFb" />
          </Grid>
          <Grid item xs={12} md={6} >
            <DefaultedTextField label="Snapchat Pixel ID" name="variables.trackingPixelSn" />
          </Grid>
          <Grid item xs={12} md={6} >
            <DefaultedTextField label="Tiktok Pixel ID" name="variables.trackingPixelTt" />
          </Grid>
          <Grid item xs={12} >
            <FormCheckbox
              label="Enable Jornaya Campaign ID"
              name="variables.jornayaEnabled"
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <FormTextField
              useFastTextField={false}
              label={`Jornaya Campaign ID ${jornayaEnabled ? '(leave blank to use default)' : ''}`}
              name="variables.jornayaCampaignId"
              disabled={!jornayaEnabled}
            />
          </Grid>
          <Grid item xs={12} >
            <ExpandingTextField label={"Header Tags"} name={"tags.header"} />
          </Grid>
          <Grid item xs={12} >
            <ExpandingTextField label={"Footer Tags"} name={"tags.footer"} />
          </Grid>
        </Grid>
      </ContentWrapper>
    </StyledCard>
  )
}

function TrackingPageForm() {
  const { dirty, isSubmitting, submitForm, errors, resetForm } = useFormikContext()
  const { showPrompt } = useContext(NavigationContext)
  const { setBookends } = useBookends()

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: "secondary",
            label: "Cancel",
            onClick: () => {
              showPrompt().then(() => resetForm())
            },
          },
          {
            type: "primary",
            label: isSubmitting ? 'Please wait...' : 'Save',
            disabled: !dirty || isSubmitting,
            onClick: submitForm,
          },
        ],
      },
      state: {
        form: {
          dirty: dirty,
          errors: errors,
        },
      },
    })
  }, [dirty, errors, isSubmitting, setBookends, submitForm, resetForm, showPrompt])

  return (
    <PageSection>
      <SectionTitle title='Tracking' />
      <TrackingIdsCard />
      <ConversionPageCard />
    </PageSection>
  )
}

export default function TrackingPage() {
  const { landingPage } = useLandingPageStateContext()
  const { setLandingPage } = useLandingPageActionsContext()

  const { addNotification } = useNotifications()

  const [gqlUpdateLandingPage] = useMutation(UPDATE_LANDING_PAGE_MUTATION)

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const mutation = buildMutation(landingPage, values)

    gqlUpdateLandingPage({variables: mutation}).then(({ data }) => {
      setLandingPage(data.mutationResult.landingPage)
      addNotification({variant: 'notice', message: "Your changes have been saved."})
    }).catch(error => {
      addNotification({variant: 'alert', message: getErrorMessage(error)})
      console.log(error)
    }).finally(() => {
      setSubmitting(false)
    })
  }, [addNotification, gqlUpdateLandingPage, landingPage, setLandingPage])

  return (
    <FormWrapper data={landingPage} schema={trackingSchema} onSubmit={handleSubmit}>
      <TrackingPageForm />
    </FormWrapper>
  )
}
