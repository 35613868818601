import SvgIcon from "@mui/material/SvgIcon"

export default function ListIcon() {
  return (
    <SvgIcon>
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>F4323592-A89D-4B97-887E-F9DF7B59B7A9</title>
          <g id="Performance-Table" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Column---Text-Filter" transform="translate(-477, -599)">
                  <g id="Filter-by-Az" transform="translate(462, 588)">
                      <g id="program" transform="translate(0, 5)">
                          <g id="Icon/Charcoal/24px-Small/List" transform="translate(15, 6)">
                              <circle id="Oval" cx="12" cy="12" r="12"></circle>
                              <g id="List-Small" transform="translate(5, 8)" fill="currentColor" fillRule="nonzero">
                                  <path d="M0.754395833,8 C0.543131944,8 0.364583333,7.92854861 0.21875,7.78564583 C0.0729166667,7.64272917 0,7.46564583 0,7.25439583 C0,7.04313194 0.0714513889,6.86458333 0.214354167,6.71875 C0.357270833,6.57291667 0.534354167,6.5 0.745604167,6.5 C0.956868056,6.5 1.13541667,6.57145139 1.28125,6.71435417 C1.42708333,6.85727083 1.5,7.03435417 1.5,7.24560417 C1.5,7.45686806 1.42854861,7.63541667 1.28564583,7.78125 C1.14272917,7.92708333 0.965645833,8 0.754395833,8 Z M0.754395833,4.75 C0.543131944,4.75 0.364583333,4.67854861 0.21875,4.53564583 C0.0729166667,4.39272917 0,4.21564583 0,4.00439583 C0,3.79313194 0.0714513889,3.61458333 0.214354167,3.46875 C0.357270833,3.32291667 0.534354167,3.25 0.745604167,3.25 C0.956868056,3.25 1.13541667,3.32145139 1.28125,3.46435417 C1.42708333,3.60727083 1.5,3.78435417 1.5,3.99560417 C1.5,4.20686806 1.42854861,4.38541667 1.28564583,4.53125 C1.14272917,4.67708333 0.965645833,4.75 0.754395833,4.75 Z M0.754395833,1.5 C0.543131944,1.5 0.364583333,1.42854861 0.21875,1.28564583 C0.0729166667,1.14272917 0,0.965645833 0,0.754395833 C0,0.543131944 0.0714513889,0.364583333 0.214354167,0.21875 C0.357270833,0.0729166667 0.534354167,0 0.745604167,0 C0.956868056,0 1.13541667,0.0714513889 1.28125,0.214354167 C1.42708333,0.357270833 1.5,0.534354167 1.5,0.745604167 C1.5,0.956868056 1.42854861,1.13541667 1.28564583,1.28125 C1.14272917,1.42708333 0.965645833,1.5 0.754395833,1.5 Z M3,8 L3,6.5 L14,6.5 L14,8 L3,8 Z M3,4.75 L3,3.25 L14,3.25 L14,4.75 L3,4.75 Z M3,1.5 L3,0 L14,0 L14,1.5 L3,1.5 Z" id="Shape"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </SvgIcon>
  )
}
