import { createContext, useContext } from 'react'

const hostnameContext = createContext({
  defaultLeadsHostname: 'unknown',
  portalHostname:       'unknown',
  complyedHostname:     'unknown',
})

export default function HostnameProvider({ children, defaultLeadsHostname, portalHostname, complyedHostname }) {
  return (
    <hostnameContext.Provider value={{ defaultLeadsHostname, portalHostname, complyedHostname }}>
      {children}
    </hostnameContext.Provider>
  )
}

export function useHostname() {
  return useContext(hostnameContext)
}
