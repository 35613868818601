import { forwardRef } from 'react'
import DailyCapForm from './DailyCapForm'

import Suspenseful from '../Suspenseful'

function DailyCapBody({
  dailyCapsCalendarResource,
  clientCampaignGroup,
  setClientCampaignGroup,
  dailyCapsResource,
  setDailyCapsResource,
  campaignCaps,
  setFormDirty,
  onClose,
  setCapsEmpty,
  forwardedRef
}) {
  if (!dailyCapsCalendarResource) return null
  const dailyCaps = dailyCapsCalendarResource.get()

  return (
      <DailyCapForm
        clientCampaignGroup={clientCampaignGroup}
        setClientCampaignGroup={setClientCampaignGroup}
        dailyCaps={dailyCaps}
        dailyCapsResource={dailyCapsResource}
        setDailyCapsResource={setDailyCapsResource}
        campaignCaps={campaignCaps}
        setFormDirty={setFormDirty}
        onCancel={onClose}
        setCapsEmpty={setCapsEmpty}
        forwardedRef={forwardedRef}
      />
  )
}

function DailyCapBodyWrapper(props, ref) {
  return (
    <Suspenseful
      component={DailyCapBody}
      forwardedRef={ref}
      {...props}
    />
  )
}

export default forwardRef(DailyCapBodyWrapper)
