export default [
    {
        "field": "templateId",
        "title": "Landing Page Template",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Branded V3",
            "2": "Portal",
            "3": "Legacy Branded"
        }
    },
    {
        "field": "primaryClientId",
        "title": "Landing Page Client",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Fortis",
            "2": "Denver College of Nursing",
            "3": "Ashford University",
            "24": "Herzing University",
            "25": "Bryant & Stratton Online College",
            "26": "George Mason University",
            "27": "Saint Leo University",
            "29": "GCU Undergrad",
            "31": "UMA",
            "32": "National University",
            "35": "Northcentral University",
            "36": "Testing",
            "37": "GCU Graduate",
            "38": "GCU Undergrad HS",
            "39": "Grantham",
            "40": "Full Sail Unbranded",
            "41": "Walden University",
            "42": "Post University ",
            "43": "Pepperdine",
            "44": "FS Unbranded New",
            "45": "Bellevue",
            "79": "Ashford University Masters",
            "80": "Colorado Technical University",
            "88": "PG Undergrad",
            "134": "American InterContinental University",
            "135": "UAGC",
            "144": "American University",
            "145": "Southern New Hampshire University",
            "146": "SNHU Undergrad",
            "147": "Full Sail Portal",
            "148": "Central Christian College of Kansas",
            "181": "SNHU Graduate",
            "182": "Bellarmine",
            "183": "Saint Louis University",
            "184": "BISK",
            "185": "Universal Technical Institute",
            "186": "ACE",
            "187": "University of Phoenix",
            "188": "Southern Careers Institute",
            "189": "PIMA Medical Institute",
            "190": "Liberty University",
            "191": "StrataTech",
            "192": "SNHU Undergrad T2",
            "193": "GCU Grad - Pixel",
            "194": "Snapchat Autopauser",
            "196": "CareerStep"
        }
    },
    {
        "field": "landingPagePublicId",
        "title": "Landing Page",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "430dc20a": "",
            "e5eff62f": "hgdfhfg",
            "a440b421": "Good Test1",
            "c7f10b2d": "Testing Save",
            "e65dac4e": "Testing Save",
            "e45eaa4b": "Exclusive and Share",
            "714c00c6": "delete",
            "96e7c18f": "fooasd",
            "79ac0492": "delete",
            "ce880d8c": "delete",
            "7cb531de": "fasdg",
            "966cc091": "delet",
            "53d71949": "Testing Save",
            "8b15ce2e": "sdfa",
            "8b1e27e4": "asdf",
            "14de830d": "a",
            "eb961279": "asdaaa",
            "6d47ec71": "LandingPageCreate",
            "941ff2c9": "a",
            "4150a7a5": "Test 11",
            "62ee9a9b": "Test12",
            "b1c6ca8b": "MultiClient=false",
            "3f2cc6cd": "Testee",
            "54e00526": "some",
            "b33f01c0": "dfsgssdf",
            "42209d71": "asdfasdf",
            "1dd4cbd4": "Sane Defaults test",
            "543aece7": "Name",
            "d97cf382": "184999811",
            "1491d87a": "Online Medical Billing and Coding Programs",
            "3902d5c8": "defaults test (has questions)"
        }
    },
    {
        "field": "complyedCreativeId",
        "title": "Creative ID",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "clientId",
        "title": "Client",
        "category": "main",
        "groupable": true,
        "visible": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Fortis",
            "2": "Denver College of Nursing",
            "3": "Ashford University",
            "24": "Herzing University",
            "25": "Bryant & Stratton Online College",
            "26": "George Mason University",
            "27": "Saint Leo University",
            "29": "GCU Undergrad",
            "31": "UMA",
            "32": "National University",
            "35": "Northcentral University",
            "36": "Testing",
            "37": "GCU Graduate",
            "38": "GCU Undergrad HS",
            "39": "Grantham",
            "40": "Full Sail Unbranded",
            "41": "Walden University",
            "42": "Post University ",
            "43": "Pepperdine",
            "44": "FS Unbranded New",
            "45": "Bellevue",
            "79": "Ashford University Masters",
            "80": "Colorado Technical University",
            "88": "PG Undergrad",
            "134": "American InterContinental University",
            "135": "UAGC",
            "144": "American University",
            "145": "Southern New Hampshire University",
            "146": "SNHU Undergrad",
            "147": "Full Sail Portal",
            "148": "Central Christian College of Kansas",
            "181": "SNHU Graduate",
            "182": "Bellarmine",
            "183": "Saint Louis University",
            "184": "BISK",
            "185": "Universal Technical Institute",
            "186": "ACE",
            "187": "University of Phoenix",
            "188": "Southern Careers Institute",
            "189": "PIMA Medical Institute",
            "190": "Liberty University",
            "191": "StrataTech",
            "192": "SNHU Undergrad T2",
            "193": "GCU Grad - Pixel",
            "194": "Snapchat Autopauser",
            "196": "CareerStep"
        }
    },
    {
        "field": "clientCampaignId",
        "title": "Campaign",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Psychology - Exclusive",
            "2": "Education - Exclusive",
            "3": "Health - Exclusive",
            "4": "Liberal Arts - Exclusive",
            "5": "Business - Exclusive",
            "6": "Education - Exclusive",
            "7": "Medical Billing and Coding - Exclusive",
            "8": "Healthcare Accounting - Exclusive",
            "9": "Business - Exclusive",
            "10": "Counseling - Exclusive",
            "11": "Health Care - Exclusive",
            "12": "Nursing - Exclusive",
            "13": "Technology - Exclusive",
            "14": "Counseling - Exclusive",
            "15": "Education - Exclusive",
            "16": "MBA - Exclusive",
            "17": "Nursing - Exclusive",
            "18": "Psychology - Exclusive",
            "19": "Technology - Exclusive",
            "20": "Counseling - Exclusive",
            "21": "Education - Exclusive",
            "22": "Psychology - Exclusive",
            "23": "Technology - Exclusive",
            "24": "General - Exclusive",
            "25": "Health - Exclusive",
            "26": "Education - Exclusive",
            "27": "Liberal Arts - Exclusive",
            "28": "Business - Exclusive",
            "29": "Master's - Exclusive",
            "30": "LV-Cygnus Music Exclusive - Exclusive",
            "31": "LV-Cygnus Tech - Exclusive",
            "32": "LV-Cygnus DCBS - Exclusive",
            "33": "Healthcare Management - Exclusive",
            "34": "Master's Programs - Exclusive",
            "35": "[35] Undergraduate Programs - Exclusive",
            "36": "Graduate Programs - Exclusive",
            "37": "General - Exclusive",
            "38": "Group 1 - Exclusive",
            "39": "Health - Exclusive",
            "40": "General - Exclusive",
            "41": "General - Exclusive",
            "42": "General - Exclusive",
            "43": "General - Exclusive",
            "44": "Grad - Exclusive",
            "45": "Undergrad - Exclusive",
            "46": "Other - Exclusive",
            "47": "Other - Exclusive",
            "48": "Other - Exclusive",
            "49": "Other - Exclusive",
            "50": "Other - Exclusive",
            "51": "Other - Exclusive",
            "52": "Other - Exclusive",
            "53": "Other - Exclusive",
            "54": "Other - Exclusive",
            "55": "Other - Exclusive",
            "56": "Other - Exclusive",
            "57": "Other - Exclusive",
            "58": "Other - Exclusive",
            "59": "Other - Exclusive",
            "60": "Other - Exclusive",
            "61": "Other - Exclusive",
            "62": "Other - Exclusive",
            "63": "Other - Exclusive",
            "64": "Other - Exclusive",
            "65": "Other - Exclusive",
            "66": "Other - Exclusive",
            "67": "Other - Exclusive",
            "68": "Other - Exclusive",
            "69": "Other - Exclusive",
            "70": "Other - Exclusive",
            "71": "Other - Exclusive",
            "72": "Other - Exclusive",
            "73": "Other - Exclusive",
            "74": "Other - Exclusive",
            "75": "Other - Exclusive",
            "76": "Other - Exclusive",
            "77": "Other - Exclusive",
            "78": "Other - Exclusive",
            "79": "Other - Exclusive",
            "80": "Other - Exclusive",
            "81": "Other - Exclusive",
            "82": "Other - Exclusive",
            "83": "Other - Exclusive",
            "84": "Other - Exclusive",
            "85": "Other - Exclusive",
            "86": "Other - Exclusive",
            "87": "Other - Exclusive",
            "88": "Other - Exclusive",
            "89": "Other - Exclusive",
            "90": "Other - Exclusive",
            "91": "Other - Exclusive",
            "92": "Other - Exclusive",
            "93": "Other - Exclusive",
            "94": "Other - Exclusive",
            "95": "Other - Exclusive",
            "96": "Other - Exclusive",
            "97": "Other - Exclusive",
            "98": "Health Information Management - Exclusive",
            "99": "Masters - Exclusive",
            "100": "Masters - Exclusive",
            "101": "Undergrad - Exclusive",
            "102": "Psychology - Shared",
            "103": "Education - Shared",
            "104": "Health - Shared",
            "105": "Liberal Arts - Shared",
            "106": "Business - Shared",
            "107": "Education - Shared",
            "108": "Medical Billing and Coding - Shared",
            "109": "Healthcare Accounting - Shared",
            "110": "Business - Shared",
            "111": "Counseling - Shared",
            "112": "Health Care - Shared",
            "113": "Nursing - Shared",
            "114": "Technology - Shared",
            "115": "Counseling - Shared",
            "116": "Education - Shared",
            "117": "MBA - Shared",
            "118": "Nursing - Shared",
            "119": "Psychology - Shared",
            "120": "Technology - Shared",
            "121": "Counseling - Shared",
            "122": "Education - Shared",
            "123": "Psychology - Shared",
            "124": "Technology - Shared",
            "125": "General - Shared",
            "126": "Health - Shared",
            "127": "Education - Shared",
            "128": "Liberal Arts - Shared",
            "129": "Business - Shared",
            "130": "Master's - Shared",
            "131": "LV-Cygnus Music Exclusive - Shared",
            "132": "LV-Cygnus Tech - Shared",
            "133": "LV-Cygnus DCBS - Shared",
            "134": "Healthcare Management - Shared",
            "135": "Master's Programs - Shared",
            "136": "[136] Undergraduate Programs - Shared",
            "137": "Graduate Programs - Shared",
            "138": "General - Shared",
            "139": "Group 1 - Shared",
            "140": "Health - Shared",
            "141": "General - Shared",
            "142": "General - Shared",
            "143": "General - Shared",
            "144": "General - Shared",
            "145": "Grad - Shared",
            "146": "Undergrad - Shared",
            "147": "Other - Shared",
            "148": "Other - Shared",
            "149": "Other - Shared",
            "150": "Other - Shared",
            "151": "Other - Shared",
            "152": "Other - Shared",
            "153": "Other - Shared",
            "154": "Other - Shared",
            "155": "Other - Shared",
            "156": "Other - Shared",
            "157": "Other - Shared",
            "158": "Other - Shared",
            "159": "Other - Shared",
            "160": "Other - Shared",
            "161": "Other - Shared",
            "162": "Other - Shared",
            "163": "Other - Shared",
            "164": "Other - Shared",
            "165": "Other - Shared",
            "166": "Other - Shared",
            "167": "Other - Shared",
            "168": "Other - Shared",
            "169": "Other - Shared",
            "170": "Other - Shared",
            "171": "Other - Shared",
            "172": "Other - Shared",
            "173": "Other - Shared",
            "174": "Other - Shared",
            "175": "Other - Shared",
            "176": "Other - Shared",
            "177": "Other - Shared",
            "178": "Other - Shared",
            "179": "Other - Shared",
            "180": "Other - Shared",
            "181": "Other - Shared",
            "182": "Other - Shared",
            "183": "Other - Shared",
            "184": "Other - Shared",
            "185": "Other - Shared",
            "186": "Other - Shared",
            "187": "Other - Shared",
            "188": "Other - Shared",
            "189": "Other - Shared",
            "190": "Other - Shared",
            "191": "Other - Shared",
            "192": "Other - Shared",
            "193": "Other - Shared",
            "194": "Other - Shared",
            "195": "Other - Shared",
            "196": "Other - Shared",
            "197": "Other - Shared",
            "198": "Other - Shared",
            "199": "Health Information Management - Shared",
            "200": "Masters - Shared",
            "201": "Masters - Shared",
            "202": "Undergrad - Shared",
            "203": "General - Exclusive - Tier 2",
            "204": "LV-Cygnus Certificates - Exclusive",
            "205": "Medical Billing and Coding - Tier 2 - Exclusive",
            "206": "Healthcare Accounting - Tier 2 - Exclusive",
            "207": "LV-Cygnus Certs - Exclusive",
            "217": "Platinum",
            "218": "Gold",
            "219": "Silver",
            "220": "Education - Shared",
            "221": "LV-Cygnus Business - Exclusive",
            "222": "LV-Cygnus Arts and Design - Exclusive",
            "223": "Medical Administrative Assistant - Exclusive",
            "224": "General Tiered - Exclusive",
            "225": "Test Tier 1",
            "226": "Test Tier 2",
            "227": "Psychology - TikTok",
            "228": "Education - TikTok",
            "229": "Counseling - TikTok",
            "230": "Cygnus - TUL - Welding",
            "231": "Healthcare Management - Exclusive",
            "232": "Cygnus - JAX - Welding",
            "233": "Cygnus - HOU - Welding",
            "234": "Cygnus - PHX - Electric",
            "235": "Cygnus - JAX - Electric",
            "236": "Internal - Search - JAX - Mechanical",
            "237": "Internal - Social - HOU - Welding",
            "238": "Internal - Display - HOU - Welding",
            "239": "CDL Career Now - JAX - Refrig, Heating, AC",
            "240": "Internal - Main Site - PHX - Refrig, Heating, AC",
            "241": "Higher Ed Growth - JAX - Refrig, Heating, AC",
            "242": "AcademixDirect - HOU",
            "243": "All Star Directories - HOU",
            "244": "Education Dynamics - HOU - Welding",
            "245": "Internal - Social - JAX - Welding",
            "246": "Internal - Main Site - JAX - Welding",
            "247": "Higher Ed Growth - JAX - Welding",
            "248": "Internal - Referral - HOU - Welding",
            "249": "Internal - Search - JAX - Electric",
            "250": "Internal - Main Site - TUL - Welding",
            "251": "Education Dynamics - JAX - Welding",
            "252": "Internal - Display - HOU - Refrig, Heating, AC",
            "253": "Internal - Search - HOU - Welding",
            "254": "Internal - Display - PHX - Refrig, Heating, AC",
            "255": "Internal - Referral - TUL - Welding",
            "256": "Internal - Search - HOU - Refrig, Heating, AC",
            "257": "Internal - Social - PHX - Refrig, Heating, AC",
            "258": "Internal - Social - HOU - Refrig, Heating, AC",
            "259": "Internal - Search - JAX - Welding",
            "260": "Internal - Referral - JAX - Welding",
            "261": "Internal - Display - TUL - Welding",
            "262": "Internal - Search - TUL - Welding",
            "263": "CDL Career Now - PHX - Electric",
            "264": "Internal - Main Site - HOU - Welding",
            "265": "Internal - Social - HOU",
            "266": "Internal - Search - PHX - Electric",
            "267": "CDL Career Now - PHX - Mechanical",
            "268": "Internal - Social - TUL - Welding",
            "269": "Internal - Search - PHX - Refrig, Heating, AC",
            "270": "CDL Career Now - PHX - Refrig, Heating, AC",
            "271": "Internal - Offline Media - HOU - Welding",
            "272": "Education Dynamics - PHX - Refrig, Heating, AC",
            "273": "Internal - Offline Media - JAX - Welding",
            "274": "CDL Career Now - PHX",
            "275": "CDL Career Now - HOU - Refrig, Heating, AC",
            "276": "Internal - Search - JAX - Refrig, Heating, AC",
            "277": "Higher Ed Growth - JAX - Mechanical",
            "278": "AcademixDirect - PHX",
            "279": "Internal - Main Site - PHX - Mechanical",
            "280": "Education Dynamics - HOU",
            "281": "AcademixDirect - JAX - Mechanical",
            "282": "Education Dynamics - TUL - Welding",
            "283": "Internal - Main Site - HOU - Refrig, Heating, AC",
            "284": "Internal - Main Site - PHX",
            "285": "Internal - Social - JAX - Mechanical",
            "286": "Internal - Social - JAX - Refrig, Heating, AC",
            "287": "Internal - Main Site - PHX - Electric",
            "288": "Media Spike - TUL - Welding",
            "289": "Media Spike - HOU",
            "290": "Internal - Display - JAX - Welding",
            "291": "Neutron Interactive - HOU - Welding",
            "292": "Internal - Social - PHX",
            "293": "Internal - Search - HOU",
            "294": "Higher Level Education - HOU",
            "295": "Media Spike - PHX - Refrig, Heating, AC",
            "296": "EduInteractive - PHX - Electric",
            "297": "EduInteractive - HOU",
            "298": "Media Spike - JAX - Electric",
            "299": "Internal - Main Site - PHX - Welding",
            "300": "Internal - Social - PHX - Electric",
            "301": "Media Spike - JAX - Refrig, Heating, AC",
            "302": "EduInteractive - TUL - Welding",
            "303": "Internal - Referral - HOU",
            "304": "Internal - Referral - PHX - Refrig, Heating, AC",
            "305": "Internal - Referral - PHX - Mechanical",
            "306": "All Star Directories - TUL - Welding",
            "307": "AcademixDirect - PHX - Electric",
            "308": "Internal - Referral - PHX - Electric",
            "309": "Internal - Search - PHX",
            "310": "Internal - Display - PHX",
            "311": "Internal - Offline Media - TUL - Welding",
            "312": "Internal - Main Site - JAX - Electric",
            "313": "Internal - Display - JAX - Electric",
            "314": "Internal - Social - JAX - Electric",
            "315": "Media Spike - JAX - Mechanical",
            "316": "Internal - Referral - JAX - Electric",
            "317": "Internal - Main Site - HOU",
            "318": "Beelineweb.com - PHX - Refrig, Heating, AC",
            "319": "EduInteractive - PHX - Refrig, Heating, AC",
            "320": "Internal - Referral - JAX - Refrig, Heating, AC",
            "321": "Internal - Social - PHX - Mechanical",
            "322": "AcademixDirect - JAX - Refrig, Heating, AC",
            "323": "All Star Directories - HOU - Refrig, Heating, AC",
            "324": "Niche.com - TUL - Welding",
            "325": "Internal - Display - JAX - Refrig, Heating, AC",
            "326": "Media Spike - PHX",
            "327": "Higher Level Education - JAX - Welding",
            "328": "Internal - Referral - HOU - Refrig, Heating, AC",
            "329": "AcademixDirect - TUL - Welding",
            "330": "Niche.com - HOU - Welding",
            "331": "Internal - Referral - JAX - Mechanical",
            "332": "Internal - Offline Media - HOU",
            "333": "Internal - Offline Media - HOU - Refrig, Heating, AC",
            "334": "EduInteractive - JAX - Welding",
            "335": "Internal - Referral - PHX",
            "336": "CDL Career Now - JAX - Electric",
            "337": "CDL Career Now - TUL - Welding",
            "338": "AcademixDirect - PHX - Refrig, Heating, AC",
            "339": "Neutron Interactive - HOU",
            "340": "CDL Career Now - HOU",
            "341": "Media Spike - HOU - Welding",
            "342": "Internal - Search - PHX - Mechanical",
            "343": "EduInteractive - PHX - Mechanical",
            "344": "Media Spike - JAX - Welding",
            "345": "CDL Career Now - JAX - Welding",
            "346": "Imagine America Foundation - PHX",
            "347": "Internal - Main Site - JAX - Refrig, Heating, AC",
            "348": "EduInteractive - HOU - Welding",
            "349": "All Star Directories - PHX",
            "350": "All Star Directories - JAX - Welding",
            "351": "Internal - Main Site - JAX - Mechanical",
            "352": "Imagine America Foundation - JAX - Welding",
            "353": "Imagine America Foundation - JAX - Electric",
            "354": "Imagine America Foundation - TUL - Welding",
            "355": "Beelineweb.com - TUL - Welding",
            "356": "All Star Directories - PHX - Electric",
            "357": "All Star Directories - JAX - Refrig, Heating, AC",
            "358": "AcademixDirect - PHX - Mechanical",
            "359": "Internal - Search - TUL",
            "360": "All Star Directories - JAX - Electric",
            "361": "Beelineweb.com - PHX",
            "362": "Internal - Email - TUL - Welding",
            "363": "Internal - Display - PHX - Electric",
            "364": "All Star Directories - PHX - Refrig, Heating, AC",
            "365": "All Star Directories - JAX - Mechanical",
            "366": "Internal - Offline Media - JAX - Electric",
            "367": "Internal - Social - TUL",
            "368": "Niche.com - HOU - Mechanical",
            "369": "Niche.com - TUL - Mechanical",
            "370": "Internal - Email - JAX - Welding",
            "371": "Beelineweb.com - HOU - Welding",
            "372": "Internal - Military - JAX - Welding",
            "373": "Education Dynamics - JAX - Mechanical",
            "374": "AcademixDirect - JAX - Electric",
            "375": "Education Dynamics - JAX - Electric",
            "376": "Education Dynamics - JAX - Refrig, Heating, AC",
            "377": "AcademixDirect - JAX - Welding",
            "378": "Beelineweb.com - JAX - Mechanical",
            "379": "Internal - Display - PHX - Mechanical",
            "380": "Imagine America Foundation - HOU - Welding",
            "381": "Internal - Offline Media - JAX - Refrig, Heating, AC",
            "382": "Internal - Display - HOU",
            "383": "AcademixDirect - HOU - Welding",
            "384": "Internal - Display - JAX - Mechanical",
            "385": "Beelineweb.com - PHX - Electric",
            "386": "Niche.com - PHX - Refrig, Heating, AC",
            "387": "Internal - Social - HOU - Mechanical",
            "388": "Internal - Email - HOU - Welding",
            "389": "Internal - Offline Media - PHX",
            "390": "Internal - Offline Media - PHX - Electric",
            "391": "All Star Directories - HOU - Welding",
            "392": "Internal - Offline Media - JAX - Mechanical",
            "393": "Imagine America Foundation - JAX - Mechanical",
            "394": "Internal - Search - PHX - Welding",
            "395": "Internal - Search - TUL - Refrig, Heating, AC",
            "396": "Niche.com - JAX - Welding",
            "397": "Niche.com - PHX - Welding",
            "398": "Internal - Email - PHX - Electric",
            "399": "Internal - Offline Media - PHX - Mechanical",
            "400": "Internal - Military - HOU - Welding",
            "401": "Imagine America Foundation - JAX - Refrig, Heating, AC",
            "402": "Niche.com - PHX - Electric",
            "403": "Beelineweb.com - JAX - Electric",
            "404": "Internal - Military - TUL - Welding",
            "405": "Imagine America Foundation - PHX - Refrig, Heating, AC",
            "406": "Imagine America Foundation - PHX - Electric",
            "407": "Internal - Military - PHX - Refrig, Heating, AC",
            "408": "Niche.com - JAX - Mechanical",
            "409": "Internal - Offline Media - PHX - Refrig, Heating, AC",
            "410": "Internal - Phone - HOU - Welding",
            "411": "Internal - Phone - JAX - Mechanical",
            "412": "Beelineweb.com - JAX - Welding",
            "413": "Higher Ed Growth - TUL - Welding",
            "414": "Higher Ed Growth - HOU - Refrig, Heating, AC",
            "415": "Higher Ed Growth - HOU",
            "416": "Higher Ed Growth - HOU - Welding",
            "417": "Internal - Phone - PHX - Refrig, Heating, AC",
            "418": "Higher Ed Growth - TUL",
            "419": "Internal - Military - PHX - Electric",
            "420": "Higher Ed Growth - PHX",
            "421": "Higher Ed Growth - PHX - Refrig, Heating, AC",
            "422": "Higher Ed Growth - PHX - Electric",
            "423": "Higher Ed Growth - PHX - Mechanical",
            "424": "Neutron Interactive - PHX - Electric",
            "425": "Neutron Interactive - PHX - Mechanical",
            "426": "Neutron Interactive - PHX",
            "427": "Neutron Interactive - PHX - Refrig, Heating, AC",
            "428": "Neutron Interactive - JAX - Refrig, Heating, AC",
            "429": "Neutron Interactive - TUL - Welding",
            "430": "Neutron Interactive - JAX - Mechanical",
            "431": "Higher Ed Growth - JAX - Electric",
            "432": "Internal - Phone - PHX - Mechanical",
            "433": "CDL Career Now - HOU - Welding",
            "434": "Internal - Email - HOU - Refrig, Heating, AC",
            "435": "Internal - Email - JAX - Refrig, Heating, AC",
            "436": "Beelineweb.com - HOU - Refrig, Heating, AC",
            "437": "Internal - Email - HOU",
            "438": "Internal - Channel Marketing - PHX - Refrig, Heating, AC",
            "439": "AcademixDirect - HOU - Refrig, Heating, AC",
            "440": "All Star Directories - PHX - Mechanical",
            "441": "Neutron Interactive - HOU - Refrig, Heating, AC",
            "442": "Internal - Military - PHX - Mechanical",
            "443": "Internal - Email - PHX - Refrig, Heating, AC",
            "444": "Internal - Email - PHX - Mechanical",
            "445": "Internal - Phone - PHX - Electric",
            "446": "Internal - Main Site - JAX",
            "447": "Internal - Email - PHX",
            "448": "Neutron Interactive - JAX - Welding",
            "451": "Neutron Interactive - JAX - Electric",
            "453": "Cygnus - Healthcare - Austin - Shared",
            "454": "Cygnus - Healthcare - Online - Shared",
            "455": "Cygnus - Healthcare - Waco - Shared",
            "456": "Cygnus - Healthcare - SAS - Shared",
            "457": "Cygnus - Healthcare - SAN - Shared",
            "458": "Cygnus - Healthcare - Pharr - Shared",
            "459": "Cygnus - Healthcare - Harlingen - Shared",
            "460": "Cygnus - Healthcare - Corpus - Shared",
            "461": "Cygnus - Healthcare - Brownsville - Shared",
            "462": "Liberty - UG - Exclusive",
            "463": "Liberty - Grad - Exclusive",
            "464": "Internal - Offline Media - PHX - Welding",
            "465": "Internal - Main Site - TUL - Refrig, Heating, AC",
            "466": "Internal - Channel Marketing - JAX - Welding",
            "467": "Internal - Channel Marketing - HOU - Welding",
            "468": "Internal - Main Site - TUL - Electric",
            "469": "Internal - Main Site - TUL",
            "470": "Internal - Channel Marketing - TUL - Refrig, Heating, AC",
            "471": "Internal - Channel Marketing - TUL - Mechanical",
            "472": "Internal - Channel Marketing - TUL - Electric",
            "473": "Internal - Main Site - TUL - Mechanical",
            "474": "Internal - Social - TUL - Electric",
            "475": "Internal - Social - TUL - Refrig, Heating, AC",
            "476": "Internal - Channel Marketing - TUL - Welding",
            "477": "Internal - Search - TUL - Electric",
            "478": "Internal - Referral - TUL - Electric",
            "479": "Internal - Offline Media - HOU - Electric",
            "480": "Internal - Offline Media - HOU - Mechanical",
            "481": "Internal - Referral - TUL - Mechanical",
            "482": "Internal - Referral - TUL - Refrig, Heating, AC",
            "483": "Internal - Search - HOU - Electric",
            "484": "AcademixDirect - TUL - Electric",
            "485": "AcademixDirect - TUL - Refrig, Heating, AC",
            "486": "Internal - Search - HOU - Mechanical",
            "487": "Neutron Interactive - TUL - Refrig, Heating, AC",
            "488": "TWS - HOU - Electric",
            "489": "TWS - TUL - Electric",
            "490": "Neutron Interactive - HOU - Mechanical",
            "491": "Neutron Interactive - HOU - Electric",
            "492": "CDL Career Now - TUL - Electric",
            "493": "CDL Career Now - HOU - Electric",
            "494": "Internal - Social - TUL - Mechanical",
            "495": "All Star Directories - TUL - Refrig, Heating, AC",
            "496": "Neutron Interactive - TUL - Mechanical",
            "497": "Internal - Display - TUL - Electric",
            "498": "Internal - Social - HOU - Electric",
            "499": "All Star Directories - HOU - Electric",
            "500": "All Star Directories - TUL - Electric",
            "501": "Media Spike - HOU - Electric",
            "502": "Media Spike - HOU - Mechanical",
            "503": "Neutron Interactive - TUL - Electric",
            "504": "Internal - Main Site - HOU - Electric",
            "505": "Media Spike - TUL - Mechanical",
            "506": "Internal - Display - TUL - Refrig, Heating, AC",
            "507": "Internal - Display - TUL - Mechanical",
            "508": "Internal - Search - TUL - Mechanical",
            "509": "Internal - Referral - HOU - Mechanical",
            "510": "Internal - Referral - HOU - Electric",
            "511": "Media Spike - TUL - Electric",
            "512": "AcademixDirect - HOU - Electric",
            "513": "Internal - Main Site - HOU - Mechanical",
            "514": "CDL Career Now - TUL - Refrig, Heating, AC",
            "515": "Medical Billing and Coding - Platinum - Exclusive",
            "516": "Healthcare Management - Platinum - Exclusive\t",
            "517": "Healthcare Accounting - Platinum - Exclusive",
            "518": "Medical Administrative Assistant - Platinum - Exclusive",
            "519": "Health Information Management - Platinum - Exclusive",
            "520": "Internal - Email - HOU - Mechanical",
            "521": "Beelineweb.com - HOU - Electric",
            "522": "Medical Administrative Assistant - Tier 2 - Exclusive",
            "523": "Healthcare Management - Tier 2 - Exclusive",
            "524": "Health Information Management - Tier 2 - Exclusive",
            "525": "Placeholder",
            "526": "Apples and Coffee"
        }
    },
    {
        "field": "campaignType",
        "title": "Campaign Type",
        "category": "main",
        "summary": {
            "text": "Total"
        },
        "groupable": true,
    },
    {
        "field": "programGroupId",
        "title": "Program Group",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Psychology",
            "2": "Health",
            "3": "Education",
            "4": "Liberal Arts",
            "5": "Business",
            "6": "Education",
            "7": "Medical Billing and Coding",
            "8": "Healthcare Accounting",
            "9": "Business",
            "10": "Counseling",
            "11": "Health Care",
            "12": "Nursing",
            "13": "Technology",
            "14": "Counseling",
            "15": "Education",
            "16": "MBA",
            "17": "Nursing",
            "18": "Psychology",
            "19": "Technology",
            "20": "Counseling",
            "21": "Education",
            "22": "Psychology",
            "23": "Technology",
            "24": "LV-Cygnus Tech",
            "25": "General",
            "58": "Health",
            "59": "Education",
            "60": "Liberal Arts",
            "61": "Business",
            "62": "Master's",
            "63": "LV-Cygnus Music",
            "64": "LV-Cygnus Film",
            "96": "Healthcare Management",
            "97": "Master's Programs",
            "98": "Undergraduate Programs",
            "99": "Graduate Programs",
            "100": "General",
            "101": "Group 1",
            "102": "Health",
            "103": "General",
            "104": "General",
            "105": "Undergrad",
            "106": "General",
            "107": "General",
            "108": "Grad",
            "109": "Masters",
            "110": "Other",
            "111": "Other",
            "112": "Other",
            "113": "Other",
            "114": "Other",
            "115": "Other",
            "116": "Other",
            "117": "Other",
            "118": "Other",
            "119": "Other",
            "120": "Other",
            "121": "Other",
            "122": "Other",
            "123": "Other",
            "124": "Other",
            "125": "Other",
            "126": "Other",
            "127": "Other",
            "128": "Other",
            "129": "Other",
            "130": "Undergrad",
            "131": "Other",
            "132": "Other",
            "133": "Other",
            "134": "Other",
            "135": "Other",
            "136": "Other",
            "137": "Other",
            "138": "Other",
            "139": "Other",
            "140": "Other",
            "141": "Other",
            "142": "Other",
            "143": "Other",
            "144": "Other",
            "145": "Other",
            "146": "Other",
            "147": "Other",
            "148": "Other",
            "149": "Other",
            "150": "Other",
            "151": "Other",
            "152": "Other",
            "153": "Other",
            "154": "Other",
            "155": "Other",
            "156": "Other",
            "157": "Other",
            "158": "All",
            "159": "Other",
            "160": "Other",
            "161": "Other",
            "162": "Other",
            "163": "Health Information Management",
            "164": "Masters",
            "165": "Certificates",
            "166": "Education",
            "167": "Austin - Healthcare",
            "168": "Associates",
            "169": "LV-Cygnus Business",
            "170": "LV-Cygnus Arts and Design",
            "171": "All Programs",
            "172": "Education",
            "173": "Social Work",
            "206": "Social Work",
            "207": "Undergrad - LU",
            "208": "Health Sciences - Medical Administrative Assistant",
            "209": "TWS - Tulsa - Welding",
            "210": "Group 1",
            "211": "Healthcare Management",
            "212": "Masters - LU",
            "213": "Education",
            "214": "Psychology",
            "215": "Counseling",
            "216": "Brownsville - Healthcare",
            "217": "Corpus Christi - Healthcare",
            "218": "Harlingen - Healthcare",
            "219": "Pharr - Healthcare",
            "220": "San Antonio North - Healthcare",
            "221": "San Antonio South - Healthcare",
            "222": "Waco - Healthcare",
            "223": "Online - Healthcare",
            "224": "TWS - Jacksonville - Welding",
            "225": "TWS - Houston - Welding",
            "226": "RSI - Electric",
            "227": "TWS - Jacksonville - Electric",
            "228": "TWS - Jacksonville - Mechanical",
            "229": "TWS - Jacksonville - Refrig, Heating, AC",
            "230": "RSI - Refrig, Heating, AC",
            "231": "TWS - Houston - Refrig, Heating, AC",
            "232": "RSI - Mechanical",
            "233": "RSI - Welding",
            "234": "TWS - Houston - Mechanical",
            "235": "TWS - Tulsa - Mechanical",
            "236": "TWS - Tulsa - Refrig, Heating, AC",
            "237": "TWS - Tulsa - Electric",
            "238": "TWS - Houston - Electric",
            "239": "TWS - Houston - Electric",
            "240": "Placeholder"
        }
    },
    {
        "field": "degreeProgramId",
        "title": "Program",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Medical Billing & Coding Degree Program",
            "13": "Practical Nursing & LPN Courses",
            "14": "Online RN to BSN Degree Program",
            "15": "Online Master's In Nursing Degree Program",
            "16": "Online Master's Psychology Degree Program",
            "17": "BA in Psychology Online Degree",
            "18": "BA in Early Childhood Education Online Degree",
            "19": "Online BA in Health Care Administration Degree Program",
            "20": "Online Data Analytics Degree Program",
            "21": "Online Healthcare Management Degree Program",
            "22": "Online Health Information Management Degree Program",
            "23": "Online Health Services Administration Degree",
            "24": "Online Master Of Business Administration Program",
            "25": "Online Nursing Master's Degree Program",
            "26": "Online Organizational Psychology Master's Degree Program",
            "27": "Online Master of Business Administration (MBA) Degree Program",
            "28": "Online Psychology Master's Degree Program",
            "29": "Online Social Work Master's Degree Program",
            "30": "Online Criminal Justice Master's Degree Program",
            "31": "Online Cybersecurity Master's Degree Program",
            "33": "Do Not Use",
            "35": "Online Medical Billing and Coding Programs",
            "36": "Bachelor of Arts in Early Childhood Education",
            "40": "Psychology Degree",
            "41": "Test",
            "42": "Online Childhood and Adolescence Disorders Master's Degree",
            "44": "Online Psychology Degree Program",
            "45": "Online Behavioral Counseling Degree Program",
            "46": "Online Computer Science Bachelor's Program",
            "47": "Online Digital Marketing Bachelor's Degree",
            "48": "Online Clinical Psychology Degree Program",
            "49": "Online Elementary Education Degree Program",
            "50": "Online Behavioral Counseling Degree Program",
            "51": "Online Bachelor's Degree in Elementary Education ",
            "52": "Online Elementary Education Degree Program",
            "53": "Online Psychology Degree Program",
            "54": "Online Psychology Degree Program",
            "55": "Online Business/Marketing Bachelor's Degree",
            "56": "Online Gerontology Degree Program",
            "57": "BA in Child Development  Online Degree",
            "58": "Online Health Care Administration Degree Program",
            "59": "Online Digital Cinematography Bachelor's Degree",
            "60": "Online Sportscasting Bachelor's Degree",
            "61": "Online Computer Science Bachelor's Degree",
            "62": "Online Master Of Business Administration Degree Program",
            "63": "Online Mental Health Counseling Master's Degree",
            "64": "Online Trauma Master's Degree",
            "65": "Online Software Development Degree Program",
            "66": "Online Psychology Master's Degree",
            "67": "Online Psychology Master's Degree",
            "68": "Online Computer Information Systems Degree Program",
            "69": "Online Software Development Degree Program",
            "70": "Online Accredited Degree Programs",
            "71": "Online Healthcare Degrees & Programs",
            "72": "Online Marketing Bachelor's Degree",
            "73": "Online Sport Management Bachelor's Degree",
            "74": "Online Child Studies Degree Program",
            "75": "Online Technology Master's Degree",
            "108": "Military Online Business/Marketing Bachelor's Degree",
            "109": "Military Online Computer Science Bachelor's Degree",
            "110": "Online Healthcare Programs",
            "111": "BS in Computer Software Technology Online Degree",
            "112": "BA in Web & Mobile App Technology Degree",
            "113": "BA in Applied Behavioral Science Online Degree",
            "114": "BA in Law Enforcement Administration Online Degree",
            "115": "Online Digital Cinematography Bachelor's Degree",
            "116": "Online Early Childhood Development Degree Program",
            "117": "Online Criminal Justice Bachelor's Degree",
            "118": "Sportscasting Online Degree",
            "119": "Online Digital Marketing Bachelor's Degree",
            "120": "BA in Sports and Recreation Management Online Degree",
            "121": "BA in Sport Management Online Degree",
            "122": "BS in Behavioral Science Online Degree",
            "123": "BS in Psychology Online Degree",
            "124": "Online Sports and Entertainment Management Degree Program",
            "157": "Audio Production Online Degree",
            "158": "Sports Marketing  Degree",
            "159": "Online Computer Science Bachelor's Degree",
            "160": "Earn your bachelor's degree inDigital CinematographyFast-paced. 100% online.",
            "161": "Earn your bachelor's degree inCybersecurityFast-paced. 100% online.",
            "162": "Earn your BA inSports and Recreation ManagementDegree 100% online.",
            "163": "Online Pharmacy Technician Program",
            "164": "MA in Psychology Online Degree",
            "165": "Elementary Education Master's Online Degree Program",
            "166": "BA in Child Development  Online Degree",
            "167": "BA in Psychology Online Degree",
            "168": "BS in Emergency Management Online Degree",
            "169": "BS in Healthcare Management Online Degree",
            "170": "BS in Psychology Online Degree",
            "171": "Online Medical Billing and Coding Programs",
            "172": "Online Healthcare Programs",
            "173": "B.A. in Psychology",
            "174": "B.S. in Child Studies",
            "175": "Bachelor of Arts in Psychology (Online Only)",
            "176": "Practical Nursing",
            "177": "Bachelor of Arts in Psychology (Online Only)",
            "178": "B.S. Psychology (Online)",
            "179": "B.S. Psychology minor in Applied Behavior and Analysis (Online)",
            "180": "Bachelor of Arts in Psychology",
            "181": "Bachelor of Arts in Psychology",
            "182": "Bachelor of Science in Psychology",
            "183": "Bachelor of Arts in Psychology",
            "184": "Medical Assistant",
            "185": "Medical Insurance Biller",
            "186": "Clinical Medical Assistant",
            "187": "Emergency Medical Technician",
            "188": "A.S. in Marketing",
            "189": "B.S. in Accounting",
            "190": "B.S. in Marketing",
            "191": "Dual Education Major",
            "192": "Early Childhood Education Associate's Degree - Child Development Specialization",
            "193": "Early Childhood Education Associate's Degree - Special Needs Specialization",
            "194": "BA Business Information Systems",
            "195": "Accounting Associate's Degree",
            "196": "Business Management Associate's Program",
            "197": "Human Resources and Organizational Leadership Associate's Degree",
            "198": "Marketing Associate's Degree Program",
            "199": "Bachelor of Arts in Accounting",
            "200": "Bachelor of Arts in Business Administration",
            "201": "Bachelor of Arts in Business Administration - Hospitality Management",
            "202": "Bachelor of Arts in Business Administration - Hospitality Management (Hybrid)",
            "203": "Bachelor of Arts in Business Administration - Hospitality Management (Online Only)",
            "204": "Bachelor of Arts in Business Administration with a concentration in Finance",
            "205": "Bachelor of Arts in Business Administration with a concentration in Human Resource Management",
            "206": "Bachelor of Arts in Business Administration with a concentration in International Business",
            "207": "Bachelor of Arts in Business Administration with a concentration in Management",
            "208": "Bachelor of Arts in Business Administration with a concentration in Marketing",
            "209": "Bachelor of Arts in Business Administration with a concentration in Transportation and Logistics",
            "210": "Bachelor of Science in Management Information Systems",
            "211": "Online RN to BSN Degree Program",
            "212": "Associate of Arts in Liberal Studies - Elementary/Early Childhood Education",
            "213": "B.S. in Behavioral Health Science",
            "214": "B.S. in Counseling with an Emphasis in Addiction, Chemical Dependency, and Substance Abuse",
            "215": "B.S. in Psychology",
            "216": "B.S. in Psychology with an Emphasis in Forensic Psychology",
            "217": "B.S. in Psychology with an Emphasis in Performance and Sports Psychology",
            "218": "B.S. in Sociology with an Emphasis in Social Work",
            "219": "Online Elementary Education Degree",
            "220": "Online Psychology Degree",
            "221": "Entrepreneurship, Management, and Marketing - Bachelors Degree",
            "222": "Associate of Arts in Accounting (Online Only)",
            "223": "Bachelor of Arts in Accounting (Online Only)",
            "224": "Bachelor of Arts in Business Administration",
            "225": "Bachelor of Arts in Business Administration - Hospitality Management",
            "226": "Bachelor of Arts in Business Administration - Hospitality Management (Online Only)",
            "227": "Bachelor of Arts in Business Administration with a concentration in Finance",
            "228": "Bachelor of Arts in Business Administration with a concentration in Human Resource Management",
            "229": "Bachelor of Arts in Business Administration with a concentration in International Business",
            "230": "Bachelor of Arts in Business Administration with a concentration in Management",
            "231": "Bachelor of Arts in Business Administration with a concentration in Marketing",
            "232": "Bachelor of Arts in Business Administration with a concentration in Transportation and Logistics",
            "233": "Bachelor of Science in Integrated Marketing Communications",
            "234": "Bachelor of Science in Management Information Systems (Online Only)",
            "235": "Bachelor of Arts in Psychology",
            "236": "Bachelor of Arts in Psychology w/ concentrations in Human Services, Developmental Psychology, Health Care and Fitness, and Business",
            "237": "Bachelor of Arts in Psychology w/ concentrations in Human Services, Developmental Psychology, Health Care and Fitness, and Business (Hybrid)",
            "238": "Bachelor of Arts in Psychology w/ concentrations in Human Services, Developmental Psychology, Health Care and Fitness, and Business (Online Only)",
            "268": "Online Nursing Master's Degree",
            "269": "Audio Production Online Degree",
            "270": "Online Child Studies Degree",
            "271": "Online Medical Billing and Coding Programs",
            "272": "Bachelor of Arts in Psychology",
            "273": "Bachelor of Arts in Psychology",
            "274": "Online Behavioral Counseling Degree",
            "275": "BS in Healthcare Management Online Degree",
            "276": "BS in Computer Science-Software Engineering Online Degree",
            "277": "MS in Computer Science Online Degree",
            "278": "Bachelor's in Healthcare Management",
            "279": "Master's in Business Administration – Management Online Degree",
            "280": "Online Master's in Education - Elementary Education Degree",
            "281": "BA in Child Development  Online Degree",
            "282": "Online Healthcare Management Program",
            "283": "B.A. Elementary Education",
            "284": "MA in Psychology Online Degree",
            "285": "Earn your BA inSports and Recreation ManagementDegree 100% online.",
            "286": "BA in Sports and Recreation Management Online Degree",
            "287": "BA in Web & Mobile App Technology Degree",
            "288": "BS in Computer Software Technology Online Degree",
            "289": "Online Gerontology Degree Program",
            "290": "BA in Applied Behavioral Science Online Degree",
            "291": "Online BA in Health Care Administration Degree Program",
            "292": "BA in Early Childhood Education Online Degree",
            "293": "BA in Business Information Systems",
            "294": "BA in Child Development  Online Degree",
            "295": "BA in Child Development  Online Degree",
            "296": "BA in Psychology Online Degree",
            "297": "BA in Law Enforcement Administration Online Degree",
            "298": "BA in Child Development  Online Degree",
            "299": "BA in Psychology Online Degree",
            "300": "Audio Production Online Degree Program",
            "301": "Bachelor of Arts in Psychology",
            "302": "Audio Production Online Degree",
            "303": "Business Office Administration",
            "304": "Associates in Business Management - Accounting",
            "305": "Bachelors in Finance",
            "306": "Online B.A. in Accounting",
            "307": "Associate of Arts in Accounting",
            "308": "Bachelor of Arts in Busines Administration with a concentration in Management",
            "309": "Bachelor of Arts in Business Administration",
            "310": "Bachelor of Arts in Psychology",
            "311": "Dental Assistant",
            "312": "Medical Assistant",
            "313": "Pharmacy Technician",
            "314": "Bachelor of Arts in Psychology - Applied Behavior Analysis Concentration",
            "315": "BA in Psychology",
            "316": "Bachelor of Arts in Psychology - Applied Behavior Analysis Concentration",
            "317": "Bachelor of Arts in Psychology",
            "318": "Online Master Of Business Administration Program",
            "319": "Online Master in Business Analytics Program",
            "320": "Bachelor of Science in Human Services",
            "321": "Bachelor of Science in Early Childhood Administration",
            "322": "Bachelor of Science Degree in Nutrition",
            "323": "Bachelor of Science Degree in Analytics",
            "324": "BA in Psychology Online Degree",
            "325": "Earn Your Psychology Degree Online",
            "326": "Online Computer Science Bachelor's Degree",
            "327": "BA in Psychology Online Degree",
            "360": "Online Elementary Education Degree Program",
            "361": "Earn Your Master's in Psychology Degree Online",
            "362": "Earn Your Master's in Data Analytics Degree Online",
            "363": "Earn Your Computer Science Degree Online",
            "364": "Earn Your Data Analytics Degree Online",
            "365": "Earn Your Healthcare Administration Degree Online",
            "366": "Earn Your Sports Management Degree Online",
            "367": "Online Digital Cinematography Bachelor's Degree",
            "368": "Medical Laboratory Science Degree Program",
            "369": "Music Production Online Degree",
            "370": "Online Mobile Development Bachelor's Degree",
            "371": "Online Mobile Development Bachelor's Degree",
            "372": "Online Bachelor's Degree in Psychology ",
            "373": "Online Master's Degree in Psychology ",
            "374": "Online Bachelor's Degree in Social Work ",
            "375": "Online Bachelor's Degree in Business Administration ",
            "376": "Online Master's Degree in Clinical Mental Health Counseling ",
            "377": "Online Bachelor's Degree in Human Services ",
            "378": "BA in Social and Criminal Justice Online Degree",
            "379": "Medical Laboratory Science Degree Program",
            "380": "BA in Applied Psychology Online Degree",
            "381": "BS in Computer Information Systems Online Degree",
            "382": "BA in Business Administration Management Online Degree",
            "383": "MS in Information Assurance and Cybersecurity Online Degree",
            "384": "Master of Business Administration Online Degree",
            "385": "Automotive Technology Program",
            "386": "Diesel Technology Program",
            "387": "Motorcycle Technician Training Program",
            "388": "Universal Technical Institute ",
            "389": "BA in Psychology Online Degree",
            "390": "BA in Business Administration Online Degree",
            "391": "BA in Business Leadership Online Degree",
            "392": "BA in Marketing Online Degree",
            "393": "Master of Education Early Childhood Education Online Degree",
            "394": "Online Healthcare Accounting",
            "395": "Master's in Business Administration in Social Impact Online Degree",
            "396": "Master of Education Educational Leadership Online Degree",
            "397": "Master of Education in Special Education Online Degree",
            "398": "Master of Science in Nursing - BSN to MSN Online Degree",
            "431": "Digital Cinematography Online Degree",
            "432": "Bachelor of Science Degree in Business Administration",
            "433": "Online Health Information Management Program",
            "434": "Online Health Care Administration Degree Program",
            "435": "Digital Cinematography Certificate",
            "436": "Audio Production Certificate",
            "438": "Computer Science Certificate",
            "439": "Online Healthcare Accounting Program",
            "472": "Bachelor of Science in Education/Early Childhood Education",
            "473": "Medical Billing ＆ Coding Specialist",
            "474": "Health Care Administration",
            "475": "Earn Your Demo Degree Online",
            "476": "Online Master's of Education Degree Program",
            "477": "Game Art Online Degree",
            "478": "Online Social Work Degree Program",
            "479": "Game Development Online Degree",
            "480": "Cybersecurity Online Degree",
            "513": "Social Work Master's Degree",
            "514": "Bachelor of Science in Early Childhood Education",
            "515": "Test 2",
            "516": "Online Medical Administrative Assistant Programs",
            "517": "Earn Your Criminal Justice Degree Online",
            "518": "Earn Your Game Programming  Degree Online",
            "519": "Professional Welder",
            "521": "Earn Your Psychology Degree Online",
            "522": "Electrical Applications",
            "555": "Online Healthcare Management Program",
            "556": "Bachelor of Science in Psychology",
            "557": "Master's in Applied Psychology",
            "558": "Online Elementary Education Degree Program",
            "559": "Online Behavioral Counseling Degree Program",
            "560": "Online Psychology Degree Program",
            "561": "Bachelor of Science Degree in Business Administration",
            "562": "Medical Billing ＆ Coding Specialist",
            "563": "Medical Billing ＆ Coding Specialist",
            "564": "Medical Billing ＆ Coding Specialist",
            "565": "Medical Billing ＆ Coding Specialist",
            "566": "Medical Billing ＆ Coding Specialist",
            "567": "Medical Billing ＆ Coding Specialist",
            "568": "Medical Billing ＆ Coding Specialist",
            "569": "Medical Billing ＆ Coding Specialist",
            "570": "Professional Welder",
            "571": "Professional Welder",
            "572": "Electrical Applications",
            "573": "Electro-Mechanical Technologies",
            "574": "Refrigeration Technologies",
            "575": "Refrigeration Technologies",
            "576": "Welding Specialist with Pipefitting",
            "577": "Welding Specialist",
            "578": "Professional Welder with Pipefitting",
            "579": "Refrigeration Technologies",
            "580": "Professional Welder with Pipefitting",
            "581": "Electro-Mechanical Technologies",
            "582": "Welding Specialist",
            "583": "Associate of Occupational Studies in Mechanical Maintenance Engineering",
            "584": "Electrical Technologies",
            "585": "Professional Welder",
            "586": "Professional Welder with Pipefitting",
            "587": "Associate of Occupational Studies - Welding Technology",
            "588": "Pipefitting",
            "589": "Welding Specialist",
            "590": "Associate of Occupational Studies - Welding Technology",
            "591": "Welding Specialist with Pipefitting",
            "592": "Associate of Occupational Studies in Mechanical Maintenance Engineering",
            "593": "Associate of Occupational Studies in Mechanical Maintenance Engineering",
            "594": "Associate of Occupational Studies - Welding Technology",
            "595": "Electro-Mechanical Technologies",
            "596": "Refrigeration Technologies",
            "597": "Associate of Occupational Studies in Mechanical Maintenance Engineering",
            "598": "Welding Specialist with Pipefitting",
            "599": "Medical Assistant",
            "600": "Medical Assistant",
            "601": "Medical Assistant",
            "602": "Medical Assistant",
            "603": "Medical Assistant",
            "604": "Medical Assistant",
            "605": "Medical Assistant",
            "606": "Medical Assistant",
            "607": "Electrical Technologies",
            "608": "Earn Your Game Programming  Degree Online",
            "609": "Earn Your Criminal Justice Degree Online",
            "610": "Computer Animation Online Degree",
            "611": "Graphic Design Online Degree",
            "612": "Game Business & Esports Online Degree",
            "613": "Music Business Online Degree",
            "614": "Visual Design Undergraduate Certificate",
            "615": "Business Undergraduate Certificate",
            "616": "Electrical Applications",
            "617": "Electrical Applications",
            "618": "Electro-Mechanical Technologies",
            "619": "Electrical Applications",
            "620": "Web Development Online Degree",
            "621": "User Experience Online Degree",
            "622": "Entertainment Business Online Degree",
            "623": "Bachelor of Science in Criminal Justice"
        }
    },
    {
        "field": "vendorId",
        "title": "Vendor",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "lookup": {
            "1": "Cygnus Education",
            "2": "Internal",
            "3": "CDL Career Now",
            "4": "Higher Ed Growth",
            "5": "AcademixDirect",
            "6": "All Star Directories",
            "7": "Education Dynamics",
            "8": "Media Spike",
            "9": "Neutron Interactive",
            "10": "Higher Level Education",
            "11": "EduInteractive",
            "12": "Beelineweb.com",
            "13": "Niche.com",
            "14": "Imagine America Foundation",
            "15": "Cygnus"
        }
    },
    {
        "field": "source",
        "title": "Source",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "subid",
        "title": "SubID",
        "category": "main",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "year",
        "title": "Year",
        "category": "time",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "quarter",
        "title": "Quarter",
        "category": "time",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "month",
        "title": "Month",
        "category": "time",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "day",
        "title": "Day",
        "category": "time",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "hour",
        "title": "Hour",
        "category": "time",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "city",
        "title": "City",
        "category": "location",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "state",
        "title": "State",
        "category": "location",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "customFields:education",
        "title": "Education",
        "category": "clientFields",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "customFields:GraduationYear",
        "title": "GraduationYear",
        "category": "clientFields",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "customFields:Military",
        "title": "Military",
        "category": "clientFields",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
    },
    {
        "field": "customFields:StartClasses",
        "title": "StartClasses",
        "category": "clientFields",
        "groupable": true,
        "summary": {
            "text": "Total"
        },
        "visible": true,
    },
    {
        "field": "leadCount",
        "title": "Total Leads",
        "category": "metrics",
        "subCategory": "Total",
        "fieldType": "count",
        "align": "right",
        "summary": "sum",
        "visible": true,
    },
    {
        "field": "goodLeadCount",
        "title": "Good Leads",
        "category": "metrics",
        "subCategory": "Good",
        "fieldType": "count",
        "align": "right",
        "summary": "sum",
    },
    {
        "field": "goodLeadRate",
        "title": "Good Lead Rate",
        "category": "metrics",
        "subCategory": "Good",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "billableLeadCount",
        "title": "Billable Leads",
        "category": "metrics",
        "subCategory": "Billable",
        "fieldType": "count",
        "align": "right",
        "summary": "sum",
        "visible": true,
    },
    {
        "field": "billableLeadRate",
        "title": "Billable Lead Rate",
        "category": "metrics",
        "subCategory": "Billable",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "filteredLeadCount",
        "title": "Filtered Leads",
        "category": "metrics",
        "subCategory": "Filtered",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "filteredLeadRate",
        "title": "Filter Rate",
        "category": "metrics",
        "subCategory": "Filtered",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "rejectedLeadCount",
        "title": "Rejected Leads",
        "category": "metrics",
        "subCategory": "Rejected",
        "fieldType": "count",
        "align": "right",
        "visible": true,
        "summary": "sum"
    },
    {
        "field": "rejectedLeadRate",
        "title": "Reject Rate",
        "category": "metrics",
        "subCategory": "Rejected",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "errorLeadCount",
        "title": "Error Leads",
        "category": "metrics",
        "subCategory": "Error",
        "fieldType": "count",
        "align": "right",
        "visible": true,
        "summary": "sum"
    },
    {
        "field": "errorLeadRate",
        "title": "Error Rate",
        "category": "metrics",
        "subCategory": "Error",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "returnedLeadCount",
        "title": "Returned Leads",
        "category": "metrics",
        "subCategory": "Returned",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "returnedLeadRate",
        "title": "Return Rate",
        "category": "metrics",
        "subCategory": "Returned",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "outlay",
        "title": "Outlay",
        "category": "leadCosts",
        "align": "right",
        "visible": true,
    },
    {
        "field": "costPerLead",
        "title": "Cost Per Lead",
        "category": "leadCosts",
        "align": "right",
    },
    {
        "field": "contactedLeadCount",
        "title": "Contacted Leads",
        "category": "performance",
        "subCategory": "Contacted",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "contactedLeadRate",
        "title": "Contact Rate",
        "category": "performance",
        "subCategory": "Contacted",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "interviewedLeadCount",
        "title": "Interviewed Leads",
        "category": "performance",
        "subCategory": "Interviewed",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "interviewedLeadRate",
        "title": "Interview Rate",
        "category": "performance",
        "subCategory": "Interviewed",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "applicationStartedLeadCount",
        "title": "Application Started Leads",
        "category": "performance",
        "subCategory": "Application Started",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "applicationStartedLeadRate",
        "title": "Application Started Rate",
        "category": "performance",
        "subCategory": "Application Started",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "appliedLeadCount",
        "title": "Applied Leads",
        "category": "performance",
        "subCategory": "Applied",
        "fieldType": "count",
        "align": "right",
        "visible": true,
        "summary": "sum"
    },
    {
        "field": "appliedLeadRate",
        "title": "Apply Rate",
        "category": "performance",
        "subCategory": "Applied",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "admittedLeadCount",
        "title": "Admitted Leads",
        "category": "performance",
        "subCategory": "Admitted",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "admittedLeadRate",
        "title": "Admit Rate",
        "category": "performance",
        "subCategory": "Admitted",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "enrolledLeadCount",
        "title": "Enrolled Leads",
        "category": "performance",
        "subCategory": "Enrolled",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "enrolledLeadRate",
        "title": "Enroll Rate",
        "category": "performance",
        "subCategory": "Enrolled",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "startedLeadCount",
        "title": "Started Leads",
        "category": "performance",
        "subCategory": "Started",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "startedLeadRate",
        "title": "Start Rate",
        "category": "performance",
        "subCategory": "Started",
        "fieldType": "rate",
        "align": "right",
        "visible": true,
    },
    {
        "field": "classPassedLeadCount",
        "title": "Class Passed Leads",
        "category": "performance",
        "subCategory": "Class Passed",
        "fieldType": "count",
        "align": "right",
        "summary": "sum"
    },
    {
        "field": "classPassedLeadRate",
        "title": "Class Passed Rate",
        "category": "performance",
        "subCategory": "Class Passed",
        "fieldType": "rate",
        "align": "right",
    },
    {
        "field": "contactedLeadCost",
        "title": "Cost Per Contact",
        "category": "performance",
        "subCategory": "Contacted",
        "fieldType": "cost",
        "align": "right",
        "visible": true,
    },
    {
        "field": "interviewedLeadCost",
        "title": "Cost Per Interview",
        "category": "performance",
        "subCategory": "Interviewed",
        "fieldType": "cost",
        "align": "right",
    },
    {
        "field": "applicationStartedLeadCost",
        "title": "Cost Per Application Started",
        "category": "performance",
        "subCategory": "Application Started",
        "fieldType": "cost",
        "align": "right",
        "visible": true,
    },
    {
        "field": "appliedLeadCost",
        "title": "Cost Per Apply",
        "category": "performance",
        "subCategory": "Applied",
        "fieldType": "cost",
        "align": "right",
    },
    {
        "field": "admittedLeadCost",
        "title": "Cost Per Admit",
        "category": "performance",
        "subCategory": "Admitted",
        "fieldType": "cost",
        "align": "right",
    },
    {
        "field": "enrolledLeadCost",
        "title": "Cost Per Enroll",
        "category": "performance",
        "subCategory": "Enrolled",
        "fieldType": "cost",
        "align": "right",
    },
    {
        "field": "startedLeadCost",
        "title": "Cost Per Start",
        "category": "performance",
        "subCategory": "Started",
        "fieldType": "cost",
        "align": "right",
    },
    {
        "field": "classPassedLeadCost",
        "title": "Cost Per Class Passed",
        "category": "performance",
        "subCategory": "Class Passed",
        "fieldType": "cost",
        "align": "right",
    }
]
