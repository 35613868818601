import { createContext, useRef, useContext, useState } from 'react'
import DragHandleImage from './controls/assets/drag-handle.png'

import { useDrag, useDrop } from 'react-dnd'

function getYPositions(monitor, element) {
  // Determine rectangle on screen
  const boundingRect = element.getBoundingClientRect()
  // Get vertical middle
  const middleY =
    (boundingRect.bottom - boundingRect.top) / 2
  // Determine mouse position
  const clientOffset = monitor.getClientOffset()
  // Get pixels to the top
  const clientY = clientOffset.y - boundingRect.top

  return { top: boundingRect.top, clientY, middleY }
}

const ReorderableContext = createContext()

export function ReorderableSource({ type, object, children }) {
  const ref = useRef(null)

  const [_, drag, preview] = useDrag({
    type,
    item: { object, ref },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  preview(ref)

  return (
    <div ref={ref}>
      <ReorderableContext.Provider value={drag}>
        {children}
      </ReorderableContext.Provider>
    </div>
  )
}

export function ReorderableTarget({ type, object, updatePosition, children }) {
  const ref = useRef(null)
  const [hover, setHover] = useState()

  const checkHover = function(item, monitor) {
    if(!monitor.isOver({shallow: true})) {
      return
    }

    const dragItem    = monitor.getItem()
    const dragRef     = dragItem.ref

    if (!ref.current || !dragRef.current) {
      return
    }

    const { top: _hoverTop, clientY: hoverClientY, middleY: hoverMiddleY } = getYPositions(monitor, ref.current)
    const { top: _dragTop } = getYPositions(monitor, dragRef.current)

    return hoverClientY < hoverMiddleY ? 'before' : 'after'
  }

  const [, drop] = useDrop({
    accept: type,
    drop(item, monitor) {
      if(!monitor.isOver({shallow: true})) {
        return
      }

      if(!hover) {
        return
      }

      updatePosition(item, object, hover)
    },
    hover: (item, monitor) => {
      setHover(checkHover(item, monitor))
    },
    collect: (monitor) => {
      setHover(checkHover(null, monitor))
      return {}
    },
  })

  drop(ref)

  return (
    <div ref={ref}>
      {typeof(children) === 'function' ? children({isBefore: hover === 'before', isAfter: hover === 'after'}) : children}
    </div>
  )
}

export default function Reorderable({children, ...props}) {
  return (
    <ReorderableSource {...props}>
      <ReorderableTarget {...props}>
        {children}
      </ReorderableTarget>
    </ReorderableSource>
  )
}

export function ReorderableHandle() {
  const drag = useContext(ReorderableContext)

  return (
    <span ref={drag}>
      <img src={DragHandleImage} style={{width: 12, height: 9.5}}/>
    </span>
  )
}
