import Notifications from "./";
import NotificationsProvider from "lib/NotificationsProvider";
import CygIconButton from "../Button/CygIconButton";
import { action } from "@storybook/addon-actions";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";

export default {
  title: "UI/Notifications",
  component: Notifications,
};

const Template = (args) => {
  return (
    <main>
      <NotificationsProvider initialNotifications={args.notifications}>
        <Notifications {...args} />
      </NotificationsProvider>
    </main>
  );
};

export const Default = Template.bind({});
Default.args = {
  notifications: [{ variant: "notice", message: "Success" }],
};

export const Persistent = Template.bind({});
Persistent.args = {
  notifications: [
    { variant: "alert", message: "Important Message", isPersistent: true },
  ],
};

export const Multiple = Template.bind({});
Multiple.args = {
  notifications: [
    {
      variant: "alert",
      message: "Error",
      actionComponent: (
        <CygIconButton
          text="Click Me!"
          icon={<RecommendOutlinedIcon />}
          onClick={action("onClick handler")}
        />
      ),
    },
    { variant: "notice", message: "Success" },
  ],
};
