import { useMemo } from 'react'
import { getRecordIdentity, getRemoteId } from '../../../lib/DataModel'
import { useTimezone } from '../../../lib/TimezoneProvider'

import Dashboard from '../Dashboard/Dashboard'
import ClientCampaignsResource from '../../../resources/ClientCampaignsResource'
import CurrentMonthPacingResource from '../../../resources/CurrentMonthPacingResource'
import DailyCountsResource from '../../../resources/DailyCountsResource'
import { useRefreshable } from 'lib/hooks'
import { useParams } from 'react-router'
import { useOrbit } from 'providers/OrbitProvider'

export default function VendorDashboard() {
  const { remote, store } = useOrbit()
  const { contractId } = useParams()
  const contract = store.cache.query(q => q.findRecord(getRecordIdentity('contract', contractId)))
  const campaignsResource = useMemo(() => contract && new ClientCampaignsResource({remote, contractOrClient: contract}), [remote, contract])
  const { timezone } = useTimezone()
  const [currentMonthPacingResource, refreshCurrentMonthPacingResource] = useRefreshable(() => contract && new CurrentMonthPacingResource({ store, remote, contract, timezone }), [contract])
  const dailyCountsResource = useMemo(() => contract && new DailyCountsResource({remote, contract, timezone}), [contract, remote, timezone])
  const campaignMapPromise = useMemo(() => campaignsResource && campaignsResource.promise.then(campaigns => Object.fromEntries(campaigns.map(campaign => [getRemoteId(campaign), campaign.attributes.name]))), [campaignsResource])
  const groupingColumns = useMemo(() => [
    { title: 'Campaign', field: 'clientCampaignId', lookup: campaignMapPromise, summary: { text: 'Total' }, visible: true },
  ], [campaignMapPromise])
  const url = useMemo(() => contract && `/api/v1/contracts/${getRemoteId(contract)}/dashboard`, [contract])

  return contract && (
    <Dashboard
      groupingColumns={groupingColumns}
      dailyCountsResource={dailyCountsResource}
      currentMonthPacingResource={currentMonthPacingResource}
      refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource}
      dashboardUrl={url}
    />
  ) || null
}
