import Grid from "@mui/material/Grid"
import { SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS } from "components/pages/LandingPages/data/constants"
import { buildAutoFieldMapping } from "components/pages/LandingPages/data/helpers"
import { removeItemAtIndex, changeItemIndex } from "components/pages/LandingPages/data/utils"
import { useFormikContext } from "formik"
import { useCallback, useMemo } from "react"
import QuestionKeyAndQuestionText from "./QuestionKeyAndQuestionText"
import QuestionMatchAndAnswerOptions from "./QuestionMatchAndAnswerOptions"

export default function QuestionBodySummary({ question, clientFieldGroups, stepIndex, questionIndex, displayedOptionIndex, setDisplayedOptionIndex }) {
  const { setFieldValue, errors, touched } = useFormikContext()
  const questionPath = `steps[${stepIndex}].questions[${questionIndex}]`
  const typeAheadDropDownError = useMemo(() => {
    const questionTouched = touched.steps?.[stepIndex]?.questions?.[questionIndex]?.key
    const questionErrored = errors.steps?.[stepIndex]?.questions?.[questionIndex]?.key
    return Boolean(questionTouched && questionErrored)
  }, [errors.steps, questionIndex, stepIndex, touched.steps])

  const handleSetFieldMapping = useCallback((newFieldMapping) => {
    setFieldValue(`steps[${stepIndex}].questions[${questionIndex}].fieldMapping`, newFieldMapping)
  }, [questionIndex, setFieldValue, stepIndex])

  const handleChange = useCallback((_event, questionKey) => {
    if (question.key === questionKey) { return }

    const isCustomSelect = !SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS.includes(questionKey)
    const wasCustomSelect = !SUGGESTED_QUESTIONS_WITH_NO_ANSWER_OPTIONS.includes(question.key)

    const updatedQuestion = {
      ...question,
      key: questionKey,
    }

    if(isCustomSelect) {
      if(!wasCustomSelect) {
        updatedQuestion.fieldMapping = []
      }
      if(!wasCustomSelect || !question.options) {
        updatedQuestion.options = []
      }
    }

    if(!isCustomSelect) {
      updatedQuestion.fieldMapping = buildAutoFieldMapping({ question: updatedQuestion, clients: clientFieldGroups.map(contract => contract.client) })
      updatedQuestion.options = null
    }

    setFieldValue(`steps[${stepIndex}].questions[${questionIndex}]`, updatedQuestion)
  }, [clientFieldGroups, question, questionIndex, setFieldValue, stepIndex])

  const handleRemoveOption = useCallback((questionOption) => {
    const indexOfRemovedOption = question.options.indexOf(questionOption)
    const newFieldMapping = []

    question.fieldMapping.forEach(fieldMap => {
      newFieldMapping.push({
        ...fieldMap,
        values: removeItemAtIndex(fieldMap.values, indexOfRemovedOption),
      })
    })

    const updatedQuestion = {
      ...question,
      fieldMapping: newFieldMapping,
      options: removeItemAtIndex(question.options, indexOfRemovedOption),
    }

    setFieldValue(questionPath, updatedQuestion)

    if(displayedOptionIndex > 0 && displayedOptionIndex >= indexOfRemovedOption){
      setDisplayedOptionIndex(displayedOptionIndex - 1)
    }
  },[question, setFieldValue, questionPath, displayedOptionIndex, setDisplayedOptionIndex])

  const handleOnChangeOption = useCallback((target, optionIndex) => {
    if(optionIndex >= question.options.length) {
      const newFieldMapping = []

      question.fieldMapping.forEach(fieldMap => {
        newFieldMapping.push({
          ...fieldMap,
          values: [...fieldMap.values, null]
        })
      })

      const newOptions = [...question.options, target.value]
      const updatedQuestion = {
        ...question,
        fieldMapping: newFieldMapping,
        options: newOptions,
      }

      setFieldValue(questionPath, updatedQuestion)
      target.value = null
    } else {
      setFieldValue(`${questionPath}.options[${optionIndex}]`, target.value)
    }
  }, [question, questionPath, setFieldValue])

  const onDragAnswerOptionEnd = useCallback(({ destination, source }) => {
    if(!destination) { return }

    const oldOptionIndex = source.index
    const newOptionIndex = destination.index

    if(newOptionIndex === oldOptionIndex) { return }

    const displayedOption = question.options[displayedOptionIndex]
    const newFieldMapping = []

    question.fieldMapping.forEach(fieldMap => {
      newFieldMapping.push({
        ...fieldMap,
        values: changeItemIndex(fieldMap.values, oldOptionIndex, newOptionIndex),
      })
    })

    const updatedQuestion = {
      ...question,
      fieldMapping: newFieldMapping,
      options: changeItemIndex(question.options, oldOptionIndex, newOptionIndex),
    }

    const updatedDisplayedOptionIndex = updatedQuestion.options.indexOf(displayedOption) >= 0 ? updatedQuestion.options.indexOf(displayedOption) : 0
    setFieldValue(questionPath, updatedQuestion)
    setDisplayedOptionIndex(updatedDisplayedOptionIndex)
  },[displayedOptionIndex, question, questionPath, setDisplayedOptionIndex, setFieldValue])

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <QuestionKeyAndQuestionText
        question={question}
        onChange={handleChange}
        typeAheadDropDownError={typeAheadDropDownError}
        questionPath={questionPath}
      />
      <QuestionMatchAndAnswerOptions
        clientFieldGroups={clientFieldGroups}
        question={question}
        handleSetFieldMapping={handleSetFieldMapping}
        questionPath={questionPath}
        onRemoveOption={handleRemoveOption}
        onChangeOption={handleOnChangeOption}
        onDragAnswerOptionEnd={onDragAnswerOptionEnd}
        errors={errors}
      />
    </Grid>
  )
}
