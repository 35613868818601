import { Form, Formik, useFormikContext, yupToFormErrors } from "formik"
import { useNotifications } from "lib/NotificationsProvider"
import { useCallback, useEffect, useMemo } from "react"
import { getUniqueErrors, stringFromArray } from "./utils"


function WithinFormikContext({ children }) {
  const { submitCount, setTouched } = useFormikContext()

  useEffect(() => {
    if (submitCount > 0) {
      setTouched({}, false)
    }
  }, [setTouched, submitCount])

  return (
    <Form autoComplete="off">
      {children}
    </Form>
  )
}

export default function FormWrapper({ data, schema, onSubmit, children }) {
  const { addNotification } = useNotifications()
  const maxErrors = 3

  const handleValidate = useCallback((values) => {
    return schema.validate(values, { abortEarly: false })
    .then(() => null)
    .catch(err => {
      if (err.name === 'ValidationError') {
        const errors = yupToFormErrors(err)

        const uniqueErrorList = getUniqueErrors(errors)

        if(uniqueErrorList.length === 0) {
          return
        }

        const errorsString = stringFromArray(uniqueErrorList, maxErrors)

        addNotification({variant: 'alert', message: errorsString})

        return errors
      } else {
        throw err
      }
    })
  }, [addNotification, schema])

  const persistedErrors = useMemo(() => {
    if(data.id) {
      try {
        schema.validateSync(data, { abortEarly: false })
      }
      catch(err) {
        if (err.name === 'ValidationError') {
          return yupToFormErrors(err)
        }
      }
    }
  }, [data, schema])

  return (
    <Formik
      initialValues={data}
      initialErrors={persistedErrors}
      enableReinitialize={true}
      validate={handleValidate}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <WithinFormikContext>
        {children}
      </WithinFormikContext>
    </Formik>
  )
}
