import { FormTextField } from "components/Formik/forms";
import { useState } from "react";
import useId from '@mui/utils/useId'

export default function ExpandingTextField({label, name, minRows=4, maxRows=20, inactiveRows=1}) {
  const [active, setActive] = useState(false)
  const textFieldId = useId()

  return (
    <FormTextField
      useFastTextField={false}
      sx={{
        '.MuiOutlinedInput-root': {
          padding: '0',
          paddingRight: '6px',
        },
        '.MuiOutlinedInput-input': {
          overflow: active ? null : 'hidden',
        },
      }}
      id={textFieldId}
      onFocus={() => setActive(true)}
      label={label}
      name={name}
      multiline
      minRows={active ? minRows : inactiveRows}
      maxRows={active ? maxRows : inactiveRows}
    />
  )
}
