import SvgIcon from '@mui/material/SvgIcon'

export default function GoogleIcon({...props}) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="15" cy="15" r="15"/>
              <g fillRule="nonzero">
                  <path d="M25 15.248c0-.69-.067-1.4-.178-2.068h-9.625v3.935h5.513a4.638 4.638 0 0 1-2.045 3.09l3.29 2.556C23.889 20.96 25 18.337 25 15.248z" fill="#4285F4"/>
                  <path d="M15.197 25.206c2.757 0 5.068-.911 6.758-2.467l-3.29-2.534c-.911.622-2.09.978-3.468.978-2.667 0-4.912-1.8-5.735-4.201l-3.378 2.6a10.193 10.193 0 0 0 9.113 5.624z" fill="#34A853"/>
                  <path d="M9.462 16.982a6.267 6.267 0 0 1 0-3.935l-3.378-2.623a10.216 10.216 0 0 0 0 9.158l3.378-2.6z" fill="#FBBC05"/>
                  <path d="M15.197 8.846a5.566 5.566 0 0 1 3.912 1.534l2.912-2.934A9.819 9.819 0 0 0 15.197 4.8c-3.867 0-7.38 2.179-9.113 5.624l3.378 2.623c.823-2.423 3.068-4.201 5.735-4.201z" fill="#EA4335"/>
              </g>
          </g>
      </svg>
    </SvgIcon>
  )
}
