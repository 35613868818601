import { expect, MockInstance } from "@storybook/test";

export function expectCalledOnce(fn: MockInstance, ...args: unknown[]) {
  expect(fn).toHaveBeenCalledOnce()
  expect(fn).toHaveBeenCalledWith(...args)
}

export function expectCalledOnceAndClear(fn: MockInstance, ...args: unknown[]) {
  expectCalledOnce(fn, ...args)
  fn.mockClear()
}
