import { getIn, PersistedErrorHandler, useFormikContext } from 'components/Formik/forms'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import RuleOperator from './RuleOperator'
import RuleValue from './RuleValue'
import useId from '@mui/utils/useId'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'

function buildMenuGroup(attributeGroup) {
  const children = []
  children.push(<ListSubheader sx={{fontSize: '10px', lineHeight: '14px'}}>{attributeGroup.title}</ListSubheader>)

  attributeGroup.attributes.forEach((attribute) => (
    children.push(<MenuItem value={attribute} key={`rule_attribute_${attribute.id}`}>{attribute.text}</MenuItem>)
  ))

  return children
}

// ruleAttributeKey is the key on the rule that identifies the attribute
// attributeKey identifies the attribute from attributeSelectOptions
export default function QuestionOperatorValue({ path, attributeSelectOptions }) {
  const { values, setFieldValue, errors } = useFormikContext()
  const textFieldId = useId()

  const condition = getIn(values, `${path}`)

  const selectedAttribute = attributeSelectOptions.flatMap(s => s.attributes).find(a => a.key === condition.key || a.id === condition.question?.id )
  const attributeKey = selectedAttribute?.question?.id ? 'question.id' : 'key'
  const attributeError = getIn(errors, `${path}.questionOrKey`)

  const handleAttributeSelect = event => {
    const selection = event.target.value
    const selectionIsQuestion = !!selection.position
    if(selectionIsQuestion) {
      setFieldValue(`${path}.question.id`, event.target.value['id'])
      setFieldValue(`${path}.key`, null)
    } else {
      setFieldValue(`${path}.key`, event.target.value['key'])
      setFieldValue(`${path}.question`, null)
    }
    setFieldValue(`${path}.value`, [])
  }

  const handleOperatorSelect = event => {
    if (operatorChangedTypeToLessOrGreater(`${path}.operator`, event.target.value)) {
      setFieldValue(`${path}.value`, [])
    }
    setFieldValue(`${path}.operator`, event.target.value)
  }

  function operatorChangedTypeToLessOrGreater(rulePath, value) {
    const prevOperator = getIn(values, rulePath)
    if ((prevOperator ===  undefined || 'in' || 'not_in') &&
        (value === ('less_than' || 'greater_than'))) {
      return true
    }
    return false
  }

  return(
    <Stack direction='row' spacing={2} >
      <PersistedErrorHandler name={`${path}.${attributeKey}`}>
        <TextField
          select
          value={selectedAttribute || ''}
          onChange={handleAttributeSelect}
          label='Attribute'
          fullWidth
          id={textFieldId}
          required
          error={attributeError}
        >
          {attributeSelectOptions.map((attributeGroup) => (
            buildMenuGroup(attributeGroup)
          ))}
        </TextField>
      </PersistedErrorHandler>

      <PersistedErrorHandler name={`${path}.operator`}>
        <RuleOperator path={`${path}.operator`} onChange={handleOperatorSelect} />
      </PersistedErrorHandler>

      <RuleValue
        path={path}
        selectedAttribute={selectedAttribute}
      />
    </Stack>
  )
}
