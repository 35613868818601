import { getIn } from "formik"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { memo, useCallback } from "react"
import { isEqual } from 'lodash-es'
import CloseIcon from '@mui/icons-material/Close'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import Box from "@mui/material/Box"
import Input from "@mui/material/Input"
import IconButton from "@mui/material/IconButton"
import styled from "@mui/system/styled"
import COLORS from '../../../../../../lib/colors'
import { isShallowEqual } from "lib/utils"
import GrayHeaderBox from "components/UI/GrayHeaderBox"
import { FieldMappingDropdown } from "./FieldMappingDropdown"
import Grid from "@mui/material/Grid"


function isAnswerOptionError(errors, questionPath, optionIndex) {
  const optionErrors = getIn(errors, `${questionPath}.options`)

  return Boolean(optionErrors && optionErrors[optionIndex])
}

const OptionList = styled(Box)({
  paddingTop: '7px',
  paddingBottom: '8px',
})

// @ts-ignore
const OptionItem = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragging' && prop !== 'hasError',
})(({ isDragging, hasError }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
  marginBottom: '5px',
  ...(isDragging === true && {
    backgroundColor: COLORS.backgroundGray
  } || hasError && {
    backgroundColor: COLORS.seashell
  }),
}))

function AnswerOptionInput({ sx={}, questionOption, questionOptionIndex, onRemove, onChange, hasError=false }) {
  const handleChange = useCallback((event) => onChange(event.target, questionOptionIndex),[onChange, questionOptionIndex])
  const handleClick = useCallback(() => onRemove(questionOption),[onRemove, questionOption])

  return (
    <>
      <Input
        disableUnderline={hasError}
        type="text"
        sx={{
          paddingLeft: '4px',
          color: hasError ? COLORS.copperRed : null,
          borderBottom: hasError ? `2px solid ${COLORS.copperRed}` : null,
          paddingBottom: '2px',
          ...sx
        }}
        placeholder={`Answer ${questionOptionIndex + 1}`}
        value={questionOption}
        onChange={handleChange}
      />
      <IconButton size="small" onClick={handleClick} sx={{visibility: onRemove ? null : 'hidden'}}>
        <CloseIcon sx={{ color: hasError ? COLORS.copperRed : COLORS.slateGray }}/>
      </IconButton>
    </>
  )
}

function AnswerOption({ isNew, errors, questionPath, optionIndex, question, option, onRemove, onChange }) {
  const hasError = isAnswerOptionError( errors, questionPath, optionIndex)

  return (
    <Draggable
      draggableId={`draggable_${question.id}_${optionIndex}`}
      key={`draggable__${question.id}_${optionIndex}`}
      index={optionIndex}
      isDragDisabled={isNew}
    >
      {(provided, snapshot) => (
        <OptionItem
          // @ts-ignore
          isDragging={snapshot.isDragging}
          hasError={hasError}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >

          <Box
            component="span"
            {...provided.dragHandleProps}
          >
            <DragHandleIcon
              sx={{
                color: hasError ? COLORS.copperRed : COLORS.slateGray,
                marginRight: '4px',
                visibility: isNew ? 'hidden' : null,
              }}
            />
          </Box>

          <AnswerOptionInput
            sx={{ width: '100%' }}
            questionOption={option}
            questionOptionIndex={optionIndex}
            onRemove={isNew ? null : onRemove}
            onChange={onChange}
            hasError={hasError}
          />
        </OptionItem>
      )}
    </Draggable>
  )
}

function AnswerOptions({ question, questionPath, onRemove, onChange, onDragAnswerOptionEnd, errors }) {
  const options = [...question.options, '']

  return (
    <OptionList>
      <DragDropContext onDragEnd={onDragAnswerOptionEnd}>
        <Droppable droppableId={`option_${question.id}`} key={`droppable_option_${question.id}`}>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                options.map((option, optionIndex) => (
                  <AnswerOption
                    key={optionIndex}
                    isNew={optionIndex === question.options.length}
                    errors={errors}
                    questionPath={questionPath}
                    optionIndex={optionIndex}
                    question={question}
                    option={option}
                    onRemove={onRemove}
                    onChange={onChange}
                  />
                ))
              }
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </OptionList>
  )
}

function QuestionMatchAndAnswerOptions({  clientFieldGroups,
                                          question,
                                          handleSetFieldMapping,
                                          questionPath,
                                          onRemoveOption,
                                          onChangeOption,
                                          onDragAnswerOptionEnd,
                                          errors }) {

  return (
    <>
      <Grid item sm={4}>
        <FieldMappingDropdown
          clientFieldGroups={clientFieldGroups}
          question={question}
          setFieldMapping={handleSetFieldMapping}
        />
      </Grid>

      {question.options && (
        <Grid item sm={4}>
          <GrayHeaderBox title="Answer Options">
            <AnswerOptions
              question={question}
              questionPath={questionPath}
              errors={errors}
              onRemove={onRemoveOption}
              onChange={onChangeOption}
              onDragAnswerOptionEnd={onDragAnswerOptionEnd}
            />
          </GrayHeaderBox>
        </Grid>
      )}
    </>
  )
}

function propsAreEqual({ question: prevQuestion, errors: prevErrors, clientFieldGroups: prevClientFieldGroups, ...prevProps },
  { question: nextQuestion, errors: nextErrors, clientFieldGroups: nextClientFieldGroups, ...nextProps }) {

  return isEqual(prevQuestion, nextQuestion) &&
         isEqual(prevErrors, nextErrors) &&
         isEqual(prevClientFieldGroups, nextClientFieldGroups) &&
         isShallowEqual(prevProps, nextProps)
}

export default memo(QuestionMatchAndAnswerOptions, propsAreEqual)
