export default class PromisedResource<T> {
  resolved: boolean
  promise: Promise<T>
  caughtPromise: Promise<void | T>
  resource?: T
  error?: Error

  constructor(promise: Promise<T>) {
    this.resolved = false
    this.promise = promise
    this.caughtPromise = promise
      .then(resource => {
        this.resource = resource
        this.resolved = true
        return resource
      })
      .catch(error => {
        this.error = error
      })
  }

  isResolved(): this is { resource: T } {
    return this.resolved
  }

  get(): T {
    if(this.isResolved()) {
      return this.resource
    } else {
      throw this.error || this.caughtPromise
    }
  }
}
