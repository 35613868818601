import { useCallback, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import ResourceItemSelector from '../ResourceItemSelector'

import { getIn } from 'formik'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import COLORS from 'lib/colors';
import { useOrbit } from 'providers/OrbitProvider';

const useStyles = makeStyles({
  clientCampaignGroupSelector: {
    marginRight: 6
  },
  buttonContainer: {
    marginLeft: 6,
  },
  container: {
    marginBottom: 12,
  },
})

export default function DailyCapHeader({
  clientCampaignGroupsResource,
  selectedClientCampaignGroup,
  setSelectedClientCampaignGroup,
  onClickEdit,
  onClickAdd,
  onClickClear,
  disabled,
  capsEmpty,
  dailyCapsResource
}) {
  const classes = useStyles()
  const { store } = useOrbit()
  const canEdit = useMemo(() => (
    !getIn(selectedClientCampaignGroup, 'attributes.campaignType')
  ), [selectedClientCampaignGroup])

  const renderItem = useCallback((itemOption) => {
    const caps = store.cache.query(q => {
      return q.findRelatedRecords(itemOption, 'dailyCaps')
              .filter(
                { attribute: 'month', value: dailyCapsResource.month },
                { attribute: 'year', value: dailyCapsResource.year }
              )
    }) || []
    const hasCaps = caps.length > 0

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          height: 36
        }}>
        <div>
          {getIn(itemOption, 'attributes.name')}
        </div>
        {hasCaps && (
          <div>
            <Tooltip title="Existing daily caps">
              <span>
                <IconButton disabled={true}>
                  <EventAvailableIcon/>
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )}
      </Box>
    )
  }, [dailyCapsResource, store])

  return (
    <Grid container direction="row" alignItems="center" className={classes.container}>
      <Grid item>
        <ResourceItemSelector
          className={classes.clientCampaignGroupSelector}
          resource={clientCampaignGroupsResource}
          item={selectedClientCampaignGroup}
          setItem={setSelectedClientCampaignGroup}
          label="Campaign Group"
          renderItem={renderItem}
          selectFirstByDefault
          disabled={disabled}
          sx={{
            minWidth: 336
          }}
        />
      </Grid>
      {selectedClientCampaignGroup && canEdit && (
        <Grid item className={classes.buttonContainer}>
          <Tooltip title="Edit campaign group">
            <IconButton disabled={disabled} aria-label="Edit campaign group" onClick={onClickEdit}
              size="large">
              <EditIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item className={classes.buttonContainer}>
        <Tooltip title="Clear daily caps">
          <span>
            <IconButton disabled={capsEmpty} aria-label="Clear daily caps" onClick={onClickClear}
              size="large" sx={{ color: COLORS.frenchBlue }}>
              <EventBusyIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Tooltip title="Add new campaign group">
          <IconButton disabled={disabled} aria-label="Add new campaign group" onClick={onClickAdd}
            size="large" sx={{ color: COLORS.frenchBlue }}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
