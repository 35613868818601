export default {
  backgroundGray: '#FAFAFA',
  veryLightGray: '#F0F3F5',
  mediumGray: '#E1E5EB',
  cadetGray: '#95a4b7',
  slateGray: '#647790',
  charcoal: '#353F4C',

  frenchBlue: "#006EB3",
  azuraWhite: "#F2F8FB",    //  5% frechBlue
  aliceBlue: "#E5F0F7",     // 10% frechBlue
  mistyBlue: "#CCE2FO",     // 20% frechBlue
  beauBlue: "#B2D3E8",      // 30% frechBlue
  coastalWaters: "#7FB6D9", // 50% frechBlue

  regalBlue: "#004977",

  olivine: '#94B463',
  cabbageLeaf: '#DEE8D0',   // 30% olivine

  goldenrod: '#DDAB49',
  island: '#F8EEDB',        // 20% goldenrod
  champagne: '#F4E5C8',     // 30% goldenrod

  copperRed: '#CD7865',
  seashell: '#F8EBE8',      // 15% copperRed
  mistyRose: '#F0D6D0',     // 30% copperRed
}
