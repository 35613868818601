import { useCallback, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'

import { arrayUnion, arrayDiff, sortBy } from '../../../lib/utils';

const useStyles = makeStyles({
  selectAll: {
    marginBottom: 10,
  },
  checkbox: {
    padding: '2px 4px 2px 9px',
  }
})

function AdCampaignCheckbox({adCampaign, selectedAdCampaignIds, setSelectedAdCampaignIds}) {
  const classes = useStyles()

  const checked = useMemo(() => (
    selectedAdCampaignIds.includes(adCampaign.id)
  ), [adCampaign, selectedAdCampaignIds])

  const handleChange = useCallback(event => {
    setSelectedAdCampaignIds(event.target.checked ? arrayUnion(selectedAdCampaignIds, [adCampaign.id]) : arrayDiff(selectedAdCampaignIds, [adCampaign.id]))
  }, [adCampaign, selectedAdCampaignIds, setSelectedAdCampaignIds])

  return (
    <FormControlLabel
      control={<Checkbox className={classes.checkbox} checked={checked} onChange={handleChange} />}
      label={adCampaign.attributes.name}
    />
  )
}

export default function AdCampaignsSelector({adCampaignIds, setAdCampaignIds, adCampaignsResource, selectAll, setSelectAll}) {
  const classes = useStyles()

  const handleSelectAll = useCallback(event => {
    setSelectAll(state => !state)

    if(event.target.checked) {
      setAdCampaignIds([])
    }
  }, [setSelectAll, setAdCampaignIds])

  return (
    <Grid container direction="column">
      <Grid item className={classes.selectAll}>
        <FormControlLabel
          control={<Checkbox className={classes.checkbox} checked={selectAll} onChange={handleSelectAll} />}
          label="Attach All Ad Campaigns"
        />
      </Grid>

      {!selectAll && adCampaignIds && sortBy(adCampaignsResource.get(), 'attributes.name').map(item => (
        <Grid item key={item.id}>
          <AdCampaignCheckbox
            adCampaign={item}
            selectedAdCampaignIds={adCampaignIds}
            setSelectedAdCampaignIds={setAdCampaignIds}
          />
        </Grid>
      ))}
    </Grid>
  )
}
