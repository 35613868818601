import { Box, IconButton, Typography } from "@mui/material"
import COLORS from '../../../lib/colors'
import type { SxProps } from "@mui/system"
import type { MouseEventHandler } from "react"

export type SectionTitleControl = {
  icon: React.ReactNode
  onClick: MouseEventHandler
  label: string
  disabled: boolean
}
export type SectionTitleControls = SectionTitleControl[]

export default function SectionTitle({ title, note, controls, isInCard = false, children, sx }: { title?: string, note?: string, controls?: SectionTitleControls, isInCard?: boolean, children?: React.ReactNode, sx?: SxProps }) {
  return (
    <Box sx={{ height: '27px', display: 'flex', alignItems: 'center', ...sx }}>
      <Box component="span" sx={{ display: 'flex', alignItems: 'baseline', width:'100%' }}>
        <Typography variant="h2">
          {title || children}
        </Typography>
        {note &&
          <Box component="span" sx={{color: COLORS.slateGray, fontSize:'14px', paddingLeft:'12px'}}>
            {note}
          </Box>
        }
      </Box>
      {controls &&
        controls.map(({icon, onClick, label, disabled}, index) =>
          <IconButton onClick={onClick} disabled={disabled} aria-label={label} size="small" sx={{color: isInCard ? COLORS.charcoal : COLORS.frenchBlue, padding: .5 }} key={`${label}${index}`}>
            {icon}
          </IconButton>
        )
      }
    </Box>
  )
}
