import makeStyles from '@mui/styles/makeStyles';
import COLORS from 'lib/colors';

export default makeStyles(theme => ({
  root: {
    padding: '25px 30px',
    boxShadow: [
      '0px 4.709951877593994px 8.876447677612305px 0px rgba(0, 0, 0, 0.0244)',
      '0px 13.022414207458496px 24.5422420501709px 0px rgba(0, 0, 0, 0.035)',
      '0px 31.352956771850586px 59.08826446533203px 0px rgba(0, 0, 0, 0.0456)',
      '0px 104px 196px 0px rgba(0, 0, 0, 0.07)',
    ],
  },
  title: {
    marginBottom: 18,
  },
  titleChildren: {
    display: 'flex',
  },
  options: {
    marginTop: 12,
    marginBottom: 12,
  },
  table: {
    backgroundColor: 'white',
    position: 'relative',
    whiteSpace: 'pre-wrap',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  thead: {
    '& $td': {
      cursor: 'pointer',
      fontSize: 12,
      lineHeight: '14px',
      color: theme.palette.text.secondary,
      position: 'relative',
      verticalAlign: 'bottom',
      borderBottom: 'none',
      '&::after': {
        content: "''",
      },
    },
    '& th': {
      background: 'white',
      position: 'sticky !important',
      top: 0,
    },
  },
  tbody: {
    '& tr:last-of-type td': {
      borderBottom: 'none',
    },
    '& $td': {
      fontSize: 14,
      lineHeight: '16px',
      minWidth: 'max-content',
      color: theme.palette.text.primary,
    },
  },
  tfoot: {
    '& $td': {
      paddingTop: 29,
      borderBottom: 'none',
      borderTop: '1px solid #e6e6e6',
      fontSize: 13,
    },
  },
  subtotalRow: {
    '& $td': {
      fontWeight: '600 !important',
      backgroundColor: '#fafafa',
    },
  },
  summaryRow: {
    '& $td': {
      fontWeight: 700,
    },
  },
  td: {
    borderColor: '#e6e6e6',
    padding: '12px',
    '&.hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  sortIcon: {
    position: 'absolute',
    height: 13,
    fontSize: 13,
    marginLeft: 2,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sortIconAscending: {
    transform: 'rotate(270deg)',
  },
  sortIconDescending: {
    transform: 'rotate(90deg)',
  },
  paginationMenu: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  paginator: {
    display: 'flex',
    flexDirection: 'row',
  },
  paginatorWidget: {
    backgroundColor: '#EAEDF1',
    borderRadius: 50,
    fontSize: 13,
    height: 25,
    minWidth: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    marginLeft: 15,
  },
  paginatorPrevNext: {
    cursor: 'pointer',
    '&[disabled]': {
      cursor: 'default',
      opacity: 0.5,
    },
  },
  paginatorPage: {
    display: 'inline',
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
  },
  paginatorDivider: {
    display: 'inline',
    paddingLeft: 10,
    paddingRight: 10,
  },
  paginatorPageActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    boxShadow: `0px 0px 8px 1px ${COLORS.aliceBlue}`,
    borderRadius: 5,
    lineHeight: '25px',
    cursor: 'default',
  },
  paginatorIcon: {
    fontSize: 13,
  },
  actionIcon: {
    height: 18,
  },
  filterButton: {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
    '& img': {
      marginRight: 15,
    },
  },
  filterButtonActive: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  filterFieldActive: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  errorRow: {
    backgroundColor: theme.palette.error.light
  }
}))
