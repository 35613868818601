import { gql } from '@apollo/client';
import { DEGREE_PROGRAM_FIELDS } from './DegreeProgramFields'
import { CLIENT_CAMPAIGN_FIELDS } from './ClientCampaignFields'
import { STEPS_FIELDS } from './StepsFields'
import { VARIABLE_FIELDS } from './VariableFields'

export const LANDING_PAGE_FIELDS = gql`
  ${DEGREE_PROGRAM_FIELDS}
  ${CLIENT_CAMPAIGN_FIELDS}
  ${STEPS_FIELDS}
  ${VARIABLE_FIELDS}
  fragment LandingPageFields on LandingPage {
    id
    name
    image1Url
    image2Url
    duplicateRedirectUrl
    template {
      name
      multiClient
    }
    skeletonLandingPage {
      id
      image1Url
      image2Url
      name
      template {
        name
      }
      variables {
        ...VariableFields
      }
    }
    leadTargets {
      id
      clientCampaign {
        ...ClientCampaignFields
      }
      degreeProgram {
        ...DegreeProgramFields
      }
    }
    fallbackLeadTarget {
      id
      clientCampaign {
        ...ClientCampaignFields
      }
      degreeProgram {
        ...DegreeProgramFields
      }
    }
    clientCampaignRules {
      id
      default
      position
      clientMapping {
        clientId
        programGroupId
        clientCampaignIds
      }
      conditions {
        id
        key
        operator
        value
        question {
          id
          key
        }
      }
    }
    degreeProgramRules {
      id
      default
      position
      degreeProgramId
      conditions {
        id
        key
        operator
        value
        question {
          id
          key
        }
      }
    }
    clientWeights {
      clientId
      weight
    }
    variables {
      ...VariableFields
    }
    tags {
      header
      footer
      conversionHeaderSuccess
      conversionFooterSuccess
      conversionHeader
      conversionFooter
    }
    routes {
      id
      hostname
      path
    }
    steps {
      ...StepsFields
    }
    landingPageRules {
      id
      redirectUrl
      position
      actionType
      conditions {
        id
        key
        operator
        value
        question {
          id
          key
        }
      }
      degreeProgramSets {
        id
        client {
          id
        }
        degreeProgramIds
      }
    }
    implicitLandingPageRules {
      id
      questionId
      value
      operator
      exclusionRule {
        id
        appliesTo {
          ... on Client {
            id
          }
          ... on DegreeProgram {
            id
          }
        }
      }
    }
  }
`
