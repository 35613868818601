export default {
  "id": "1d0aaf81",
  "name": "BA in Psychology Online Degree",
  "template": {
    "name": "branded-v3",
    "multiClient": false,
  },
  "leadTargets":
  [
      {
          "id": "4771",
          "clientCampaign":
          {
              "id": "b632e191",
              "name": "Gold",
              "campaignType": "exclusive",
              "programGroup":
              {
                  "id": "171",
                  "description": "All Programs",
                  "client":
                  {
                      "id": "135",
                      "internalName": "UAGC",
                      "name": "UAGC",
                      "__typename": "Client"
                  },
                  "__typename": "ProgramGroup"
              },
              "__typename": "ClientCampaign"
          },
          "degreeProgram":
          {
              "id": "299",
              "name": "BA in <span class=\"secondary-color\">Psychology</span> Online Degree",
              "__typename": "DegreeProgram"
          },
          "__typename": "LeadTarget"
      },
      {
          "id": "4770",
          "clientCampaign":
          {
              "id": "0d74030b",
              "name": "Platinum",
              "campaignType": "exclusive",
              "programGroup":
              {
                  "id": "171",
                  "description": "All Programs",
                  "client":
                  {
                      "id": "135",
                      "internalName": "UAGC",
                      "name": "UAGC",
                      "__typename": "Client"
                  },
                  "__typename": "ProgramGroup"
              },
              "__typename": "ClientCampaign"
          },
          "degreeProgram":
          {
              "id": "299",
              "name": "BA in <span class=\"secondary-color\">Psychology</span> Online Degree",
              "__typename": "DegreeProgram"
          },
          "__typename": "LeadTarget"
      },
      {
          "id": "4769",
          "clientCampaign":
          {
              "id": "1c16e28d",
              "name": "Silver",
              "campaignType": "exclusive",
              "programGroup":
              {
                  "id": "171",
                  "description": "All Programs",
                  "client":
                  {
                      "id": "135",
                      "internalName": "UAGC",
                      "name": "UAGC",
                      "__typename": "Client"
                  },
                  "__typename": "ProgramGroup"
              },
              "__typename": "ClientCampaign"
          },
          "degreeProgram":
          {
              "id": "299",
              "name": "BA in <span class=\"secondary-color\">Psychology</span> Online Degree",
              "__typename": "DegreeProgram"
          },
          "__typename": "LeadTarget"
      }
  ],
  "fallbackLeadTarget":
  {
      "id": "4772",
      "clientCampaign":
      {
          "id": "fac5face",
          "name": "YDD Filtered",
          "campaignType": "shared",
          "programGroup":
          {
              "id": "333",
              "description": "YDD Filtered",
              "client":
              {
                  "id": "283",
                  "internalName": null,
                  "name": "YDD Filtered",
                  "__typename": "Client"
              },
              "__typename": "ProgramGroup"
          },
          "__typename": "ClientCampaign"
      },
      "degreeProgram":
      {
          "id": "836",
          "name": "YDD Filtered",
          "__typename": "DegreeProgram"
      },
      "__typename": "LeadTarget"
  },
  "degreeProgramRules":
  [
    {
        "id": "1212",
        "default": false,
        "position": 1,
        "degreeProgramId": "299",
        "conditions":
          [
              {
                  "id": "59",
                  "key": "GraduationYear",
                  "operator": "in",
                  "value":
                  [
                      "2020",
                      "2019",
                      "2018",
                      "2017",
                      "2016",
                      "2015",
                      "2014"
                  ],
                  "question": null,
                  "__typename": "RuleCondition"
              }
          ],
        "__typename": "DegreeProgramRule"
    },
    {
        "id": "3434",
        "default": true,
        "position": 2,
        "degreeProgramId": "299",
        "conditions": [],
        "__typename": "DegreeProgramRule"
    },
  ],
  "clientCampaignRules":
  [
      {
          "id": "515",
          "default": false,
          "position": 1,
          "clientMapping": [
            {
                "clientId": "135",
                "programGroupId": "171",
                "clientCampaignIds": [
                    "0d74030b"
                ]
            },
          ],
          "conditions":
          [
              {
                  "id": "58",
                  "key": "GraduationYear",
                  "operator": "in",
                  "value":
                  [
                      "2020",
                      "2019",
                      "2018",
                      "2017",
                      "2016",
                      "2015",
                      "2014"
                  ],
                  "question": null,
                  "__typename": "RuleCondition"
              }
          ],
          "__typename": "ClientCampaignRule"
      },
      {
          "id": "513",
          "default": true,
          "position": 2,
          "clientMapping": [
            {
                "clientId": "135",
                "programGroupId": "171",
                "clientCampaignIds": [
                    "b632e191"
                ]
            },
          ],
          "conditions": [],
          "__typename": "ClientCampaignRule"
      },
  ],
  "clientWeights":
  [],
  "variables":
  {
      "heading": null,
      "subheading": null,
      "title": "BA Landing Page",
      "redirectUrlFailure": "https://go.degreegrad.com/r/psychology",
      "trackingPixelFb": null,
      "trackingPixelSn": null,
      "trackingPixelTt": null,
      "jornayaEnabled": true,
      "jornayaCampaignId": null,
      "continueUrl": null,
      "__typename": "LandingPageVariables"
  },
  "tags":
  {
      "header": null,
      "footer": null,
      "conversionHeaderSuccess": null,
      "conversionFooterSuccess": null,
      "conversionHeader": null,
      "conversionFooter": null,
      "__typename": "LandingPageTags"
  },
  "routes":
  [
      {
          "id": "2960",
          "hostname": "go.degreegrad.com",
          "path": "new/uagc/psy",
          "__typename": "Route"
      }
  ],
  "steps":
  [
      {
          "id": "5126",
          "position": 1,
          "title": null,
          "questions":
          [
              {
                  "id": "9906",
                  "position": 1,
                  "text": "When Can You Start Classes?",
                  "key": "StartClasses",
                  "options":
                  [
                      "Immediately",
                      "1-3 Months",
                      "3-6 Months",
                      "More Than 6 Months"
                  ],
                  "fieldMapping":
                  [
                      {
                          "values":
                          [
                              "Immediately",
                              "1-3 Months",
                              "3-6 Months",
                              "More Than 6 Months"
                          ],
                          "field": "StartClasses",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9907",
                  "position": 2,
                  "text": "Are you a U.S. Citizen or Legal Resident?",
                  "key": "custom_citizen",
                  "options":
                  [
                      "Yes",
                      "No"
                  ],
                  "fieldMapping":
                  [
                      {
                          "values":
                          [
                              "Yes",
                              "No"
                          ],
                          "field": "Citizen",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9908",
                  "position": 3,
                  "text": "Your High School Grad Year",
                  "key": "GraduationYear",
                  "options":
                  [
                      "2022",
                      "2021",
                      "2020",
                      "2019",
                      "2018",
                      "2017",
                      "2016",
                      "2015",
                      "2014",
                      "2013",
                      "2012",
                      "2011",
                      "2010",
                      "2009",
                      "2008",
                      "2007",
                      "2006",
                      "2005",
                      "2004",
                      "2003",
                      "2002",
                      "2001",
                      "2000",
                      "1999",
                      "1998",
                      "1997",
                      "1996",
                      "1995",
                      "1994",
                      "1993",
                      "1992",
                      "1991",
                      "1990",
                      "1989",
                      "1988",
                      "1987",
                      "1986",
                      "1985",
                      "1984",
                      "1983",
                      "1982",
                      "1981",
                      "1980",
                      "1979",
                      "1978",
                      "1977",
                      "1976",
                      "1975",
                      "1974",
                      "1973",
                      "1972",
                      "1971",
                      "1970",
                      "1969",
                      "1968",
                      "1967",
                      "Other",
                      "No HS Diploma Yet"
                  ],
                  "fieldMapping":
                  [
                      {
                          "values":
                          [
                              "2022",
                              "2021",
                              "2020",
                              "2019",
                              "2018",
                              "2017",
                              "2016",
                              "2015",
                              "2014",
                              "2013",
                              "2012",
                              "2011",
                              "2010",
                              "2009",
                              "2008",
                              "2007",
                              "2006",
                              "2005",
                              "2004",
                              "2003",
                              "2002",
                              "2001",
                              "2000",
                              "1999",
                              "1998",
                              "1997",
                              "1996",
                              "1995",
                              "1994",
                              "1993",
                              "1992",
                              "1991",
                              "1990",
                              "1989",
                              "1988",
                              "1987",
                              "1986",
                              "1985",
                              "1984",
                              "1983",
                              "1982",
                              "1981",
                              "1980",
                              "1979",
                              "1978",
                              "1977",
                              "1976",
                              "1975",
                              "1974",
                              "1973",
                              "1972",
                              "1971",
                              "1970",
                              "1969",
                              "1968",
                              "1967",
                              "Other",
                              "No HS Diploma Yet"
                          ],
                          "field": "GraduationYear",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9909",
                  "position": 4,
                  "text": "Can You Apply Today?",
                  "key": "app",
                  "options":
                  [
                      "Yes",
                      "No"
                  ],
                  "fieldMapping":
                  [],
                  "__typename": "Question"
              },
              {
                  "id": "9910",
                  "position": 5,
                  "text": "Are You or Your Spouse Affiliated With the Military?",
                  "key": "military",
                  "options":
                  [
                      "Yes",
                      "No"
                  ],
                  "fieldMapping":
                  [
                      {
                          "values":
                          [
                              "Yes",
                              "No"
                          ],
                          "field": "Military",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              }
          ],
          "__typename": "Step"
      },
      {
          "id": "5127",
          "position": 2,
          "title": null,
          "questions":
          [
              {
                  "id": "9911",
                  "position": 1,
                  "text": "Street Address",
                  "key": "StreetAddress",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values":
                          [],
                          "field": "StreetAddress",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values":
                          [],
                          "field": "StreetAddress",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9912",
                  "position": 2,
                  "text": "City",
                  "key": "City",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values":
                          [],
                          "field": "City",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values":
                          [],
                          "field": "City",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9913",
                  "position": 3,
                  "text": "State",
                  "key": "State",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values":
                          [],
                          "field": "State",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values":
                          [],
                          "field": "State",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9914",
                  "position": 4,
                  "text": "ZIP Code",
                  "key": "ZIP_Code",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values":
                          [],
                          "field": "ZIP_Code",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values":
                          [],
                          "field": "ZIP_Code",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              }
          ],
          "__typename": "Step"
      },
      {
          "id": "5128",
          "position": 3,
          "title": null,
          "questions":
          [
              {
                  "id": "9915",
                  "position": 1,
                  "text": "First Name",
                  "key": "FirstName",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values": null,
                          "field": "FirstName",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values": null,
                          "field": "FirstName",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9916",
                  "position": 2,
                  "text": "Last Name",
                  "key": "LastName",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values": null,
                          "field": "LastName",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values": null,
                          "field": "LastName",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9917",
                  "position": 3,
                  "text": "Email",
                  "key": "Email",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values": null,
                          "field": "Email",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values": null,
                          "field": "Email",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              },
              {
                  "id": "9918",
                  "position": 4,
                  "text": "Phone",
                  "key": "Phone",
                  "options": null,
                  "fieldMapping":
                  [
                      {
                          "values": null,
                          "field": "Phone",
                          "clientId": "135",
                          "__typename": "FieldMapping"
                      },
                      {
                          "values": null,
                          "field": "Phone",
                          "clientId": "283",
                          "__typename": "FieldMapping"
                      }
                  ],
                  "__typename": "Question"
              }
          ],
          "__typename": "Step"
      }
  ],
  "landingPageRules":
  [
    {
        "id": "59",
        "redirectUrl": "https://yourdreamdegree.com/psychology?source=fb&subid=sharepsysh01",
        "position": 2,
        "actionType": "redirect",
        "conditions": [
            {
                "id": 1,
                "value": [ "2005"],
                "operator": "less_than",
                "key": null,
                "question": {
                    "id": "9908",
                    "key": "GraduationYear"
                }
            }
        ],
        "degreeProgramSets":
        [],
        "__typename": "LandingPageRule"
    }
  ],
  "implicitLandingPageRules":[
    {
        "id": "1165",
        "questionId": "9913",
        "value":
        [
            "AR",
            "MA",
            "MN",
            "NY",
            "DC",
            "RI"
        ],
        "operator": "in",
        "exclusionRule":
        {
            "id": "1165",
            "appliesTo":
            {
                "id": "135",
                "__typename": "Client"
            },
            "__typename": "ExclusionRule"
        },
        "__typename": "ImplicitLandingPageRule"
    },
  ],
  "__typename": "LandingPage"
}
