import React from "react";

import RichTextField from "./";

export default {
  title: "UI/RichTextField",
  component: RichTextField,
};

const Template = (args) => {
  return <RichTextField {...args} />;
};
export const Default = Template.bind({});
Default.args = {
  label: "Blurb",
  name: "blurb",
  controls: ["title", "bold", "italic", "underline", "clear", "link"],
};
