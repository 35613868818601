import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { forwardRef } from 'react'
import { StatusIcon } from '../AlertBadge/AlertBadge'
import { SxProps } from "@mui/system"


export interface Status {
  variant: string
  statusText: string
}

export interface StatusItemProps {
  status: Status
  leftIcon?: boolean
  sx?: SxProps,
  variant?: 'body2' | 'body1',
}

const StatusItem = forwardRef<HTMLDivElement, StatusItemProps>(function StatusItem({status, leftIcon = false, sx={}, variant="body2", ...props}, ref) {
  return (
    <Stack ref={ref} {...props} direction="row" alignItems="center" sx={{marginTop: '5px', ...sx}}>
      {leftIcon && (<StatusIcon variant={status.variant} />)}

      <Typography
        color={status.variant + '.main'}
        variant={variant}
        fontWeight="600"
        textAlign='end'
        marginRight={leftIcon ? '0px' : '5px'}
        marginLeft={leftIcon ? '5px' : '0px'}>
          {status.statusText}
      </Typography>

      {!leftIcon && (<StatusIcon variant={status.variant} />)}
    </Stack>
  )
})

export default StatusItem
