import { Form, FormSelect, FormTextField } from '../Formik/forms'

import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'

import { titlecase } from '../../lib/utils'
import { CAMPAIGN_TYPES_VALUES } from 'components/pages/LandingPages/data/constants'

export default function AddClientCampaignForm({contract, clientCampaign, programGroupsResource, onSave}) {
  return (
    <Form
      resource={clientCampaign}
      relatedResource={contract}
      onSave={onSave}
      redirectAfterSave={false}
      promptOnNavigate={false}
      relationshipIds={['programGroupId']}
    >
      <Stack spacing={2} mt={2}>
        <FormSelect
          name="campaignType"
          label="Campaign Type"
        >
          {CAMPAIGN_TYPES_VALUES.map(campaignType => (
            <MenuItem key={campaignType} value={campaignType}>{titlecase(campaignType)}</MenuItem>
          ))}
        </FormSelect>
        <FormSelect name="programGroupId" label="Program Group">
          {programGroupsResource.get()?.map(programGroup => (
            <MenuItem value={programGroup.id} key={programGroup.id}>{programGroup.attributes.description}</MenuItem>
          ))}
        </FormSelect>
        <FormTextField name="name" label="Name"/>
      </Stack>
    </Form>
  )
}
