import { http, HttpResponse } from "msw";
import LandingPages from "./LandingPageIndex";
import { userEvent, screen } from "@storybook/test";
import OutletContainer from "components/layout/OutletContainer/OutletContainer";

export default {
  title: "Pages/LandingPages",
  component: LandingPages,
};

const Template = (args) => {
  return (
    <OutletContainer>
      <LandingPages {...args} />
    </OutletContainer>
  );
};

export const Default = Template.bind({});
Default.args = {};
Default.play = async () => {};

Default.parameters = {
  msw: {
    handlers: [
      http.get("/api/v1/landing-pages", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "fc8fb6fe",
            type: "landing-pages",
            attributes: {
              "created-at": "2022-07-07T16:06:09.434Z",
              "updated-at": "2022-07-12T00:40:21.406Z",
              tags: {},
              variables: {
                "created-from-portal-id": 1,
              },
              uuid: "d21e01f2-7076-4b3c-8deb-a936a2b750b6",
              "public-id": "fc8fb6fe",
              name: "Find Your Dream Degree | Psychology",
              "template-id": 1,
              "client-weights": [],
              "template-name": "portal",
              hostname: "dev.yourdreamdegree.com",
              path: "new/psychology",
            },
            relationships: {
              "primary-client": {
                data: null,
              },
              template: {
                data: {
                  id: "1",
                  type: "templates",
                },
              },
            },
          },
            {
              id: "5ecd599f",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "5ecd599f",
                name: "UAGC Psychology",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "uagc/psy",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "135",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
            {
              id: "263ce115",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "263ce115",
                name: "Online Medical Administrative Assistant Programs",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "uma/omaa",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "31",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
            {
              id: "38dbb226",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "38dbb226",
                name: "Online Medical Billing and Coding Programs",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "uma/ombc",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "31",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
            {
              id: "de2be85e",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "de2be85e",
                name: "Online Healthcare Programs",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "uma/health",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "31",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },

            {
              id: "de2be85e",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "de2be85e",
                name: "Online Computer Science Bachelor's Degree",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "courses/computerscience",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "40",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
            {
              id: "de2be85e",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "de2be85e",
                name: "Game Art Online Degree",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "courses/gameart",
              },
              relationships: {
                "primary-client": {
                  data: {
                    id: "40",
                    type: "clients",
                  },
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
            {
              id: "de2be8ff",
              type: "landing-pages",
              attributes: {
                "created-at": "2022-04-12T18:11:01.305Z",
                "updated-at": "2022-06-25T02:20:11.708Z",
                tags: {},
                variables: {},
                uuid: "782bae04-3755-4079-920b-9826605ff0fe",
                "public-id": "de2be8ff",
                name: "Basket Weaving",
                "template-id": 2,
                "client-weights": [],
                "template-name": "branded",
                hostname: "go.dev.degreegrad.com",
                path: "foo/basketweaving",
              },
              relationships: {
                "primary-client": {
                  data: null,
                },
                template: {
                  data: {
                    id: "2",
                    type: "templates",
                  },
                },
              },
            },
          ],
          included: [
            {
              id: "40",
              type: "clients",
              attributes: {
                name: "Full Sail University",
              },
            },
            {
              id: "135",
              type: "clients",
              attributes: {
                name: "UAGC",
                "internal-name": "UAGC",
              },
            },
            {
              id: "31",
              type: "clients",
              attributes: {
                name: "UMA",
                "internal-name": "UMA",
              },
            },
            {
              id: "1",
              type: "templates",
              attributes: {
                name: "portal",
                "multi-client": true,
              },
            },
            {
              id: "2",
              type: "templates",
              attributes: {
                name: "branded",
                "multi-client": false,
              },
            },
          ],
        });
      }),
    ],
  },
};

export const ExpandedPortals = Template.bind({});
ExpandedPortals.args = {};
ExpandedPortals.parameters = { ...Default.parameters };
ExpandedPortals.play = async () => {
  const portalsAccordion = await screen.findByText("Portals");
  await userEvent.click(portalsAccordion);
  const background = await screen.findByText("Landing Page Management");
  await userEvent.click(background);
};

export const ExpandedBranded = Template.bind({});
ExpandedBranded.args = {};
ExpandedBranded.parameters = { ...Default.parameters };
ExpandedBranded.play = async () => {
  const uagcAccordion = await screen.findByText("UAGC");
  await userEvent.click(uagcAccordion);
  const background = await screen.findByText("Landing Page Management");
  await userEvent.click(background);
};
