import TableTitle from './TableTitle'
import TableContent from './TableContent'
import type { SxProps } from "@mui/system"
import ListAltIcon from '@mui/icons-material/ListAlt';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import Paper from '@mui/material/Paper'
import { ReactNode } from 'react';

export type ColumnLookupTable = Record<string,string>
export type ColumnLookup = ColumnLookupTable | Promise<ColumnLookupTable>

export const SETTINGS_SECTIONS = [
  {
    title: "Saved Reports",
    icon: ListAltIcon,
    lists: [
      { key: "savedReports" }
    ],
  },
  {
    title: "Fields",
    icon: TableChartOutlinedIcon,
    lists: [
      { key: "main", title: "Main" },
      { key: "media", title: "Media" },
      { key: "location", title: "Location" },
      { key: "time", title: "Time" },
      { key: "clientFields", title: "Client Fields" },
    ],
  },
  {
    title: "Metrics",
    icon: AnalyticsOutlinedIcon,
    lists: [
      { key: "metrics", title: ['Leads', 'Count', 'Rate'] },
      { key: "leadCosts", title: "Lead Costs" },
      { key: "customMetrics", title: "Custom Metrics" },
    ],
  },
  {
    title: "Performance",
    icon: InsightsOutlinedIcon,
    lists: [
      { key: "performance", title: ['Leads', 'Count', 'Rate', 'Cost'] },
    ],
  },
] as const
type Category = typeof SETTINGS_SECTIONS[number]['lists'][number]['key']
type Row = Record<string,unknown>

export interface BaseColumn<CellType = string | number> {
  title: string
  category: Category
  align?: string
  value?: (a: Record<string,CellType>) => unknown
  render?: (a: CellType) => ReactNode
  sort?: (a: Row) => string | number
  summary: ((a: Record<string, CellType>[]) => CellType) | ({text: string}) | (string)
  field: string
  visible?: boolean
  groupable?: boolean
  hasSubtotals?: boolean
  lookup?: ColumnLookup
  sx?: SxProps
}

export interface Options {
  hover?: boolean
  summaryRow?: boolean
  summaryCollapse?: boolean
  sortColumn?: string
  sortOrder?: 1 | -1
  columnsButton?: boolean
  refreshButton?: boolean
}

export function MetricsTable({
  title,
  actions = [],
  options = {},
  sx = null
} : {
  title?: string,
  actions: unknown[],
  options: Options,
  sx?: SxProps,
}) {
  return (
    <Paper sx={{p: '25px 30px', maxHeight: '100%', display: 'flex', flexDirection: 'column', ...sx}}>
      <TableTitle title={title} refreshButton={options.refreshButton} actions={actions} />
      <TableContent
        title={title}
        options={options}
      />
    </Paper>
  )
}
