import { ReactNode, useCallback } from 'react'

import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Box } from '@mui/material'
import COLORS from 'lib/colors'
import { isBlank, isPresent } from 'lib/utils'

type BaseOptionType = string | {
  id: string
}

function comparableValue(item: BaseOptionType) {
  if(typeof(item) === 'object') {
    return item.id
  }

  return item
}

type DropdownCheckboxProps<T extends BaseOptionType> = TextFieldProps & {
  collection: T[]
  selected?: T[] | null
  setSelected?: ((v: T[]) => unknown) | null
  renderItem?: (v: T) => ReactNode
  loading?: boolean
  inputPrefix?: string
  isPreset?: boolean
  disableMenuItems?: boolean
}

export default function DropdownCheckbox<T extends BaseOptionType>({
  label = null,
  collection = [],
  selected = null,
  setSelected = null,
  renderItem = item => typeof(item) === 'object' ? item.id : item,
  disabled = false,
  loading = false,
  inputPrefix = '',
  isPreset = false,
  placeholder = undefined,
  required = false,
  error = false,
  disableMenuItems = false,
  ...props
} : DropdownCheckboxProps<T>) {
  const handleChange = useCallback(event => {
    setSelected && setSelected(event.target.value.filter(item => collection.includes(item)))
  }, [collection, setSelected])

  const prefix = inputPrefix ? inputPrefix.substring(0,15) + `: ` : ''

  const renderValue = useCallback(value => {
    if(isBlank(value) && isPresent(placeholder)) {
      return (
        <Box sx={{ color: COLORS.slateGray }} >
          {placeholder}
        </Box>
      )
    } else if (isPreset) {
      return (
        <Box sx={{
          py: .5,
          px: '8px',
          borderRadius: "3px",
          backgroundColor: disabled || disableMenuItems ? COLORS.backgroundGray : error ? COLORS.mistyRose : COLORS.aliceBlue,
          border: disabled || disableMenuItems ? `solid 1px ${COLORS.slateGray}` : error ? `solid 1px ${COLORS.copperRed}` : 'unset',
          color: disableMenuItems ? COLORS.slateGray : undefined,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 'fit-content'
        }}>
          {prefix + value.map(item => renderItem(item)).join(', ')}
        </Box>
      )
    } else {
      return prefix + value.map(item => renderItem(item)).join(', ')
    }
  }, [placeholder, isPreset, disabled, disableMenuItems, error, prefix, renderItem])

  return (
    <TextField
      select
      fullWidth
      label={label}
      value={selected}
      onChange={handleChange}
      disabled={disabled}
      required={required}
      error={error}
      SelectProps={{
        renderValue: renderValue,
        multiple: true,
        displayEmpty: isPresent(placeholder),
        sx: {
          maxHeight: 48,
          backgroundColor: disableMenuItems ? COLORS.veryLightGray : undefined,
        },
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 360,
            },
          },
        },
      }}
      InputLabelProps={{
        sx: {
          color: disableMenuItems ? COLORS.slateGray : undefined,
        },
        shrink: isPresent(placeholder) ? true : undefined,
      }}
      InputProps={{
        sx: {
          '& fieldset': { border: disableMenuItems ? `1px solid ${COLORS.slateGray}` : undefined }
        }
      }}


      {...props}
    >
      { loading && (
        <MenuItem value="-" sx={{maxHeight: 36, pl: '9px'}}>
          Please wait...
        </MenuItem>
      )}

      {!loading && collection.map((item, index) => (
        <MenuItem disabled={disableMenuItems} key={index} value={item as string /* Even though item isn't necessarily a string, MenuItem (incorrectly) expects a string, so we cast it to avoid TS errors */} sx={{maxHeight: 36, pl: '9px'}}>
          <Checkbox checked={!!selected && selected.map(s => comparableValue(s)).includes(comparableValue(item))} />
          <ListItemText primary={renderItem(item)} />
        </MenuItem>
      ))}
    </TextField>
  )
}
