import { ReactElement, useEffect, useMemo, useState } from "react"

import Box from "@mui/material/Box"
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from "@mui/material/TableRow"
import CircularProgress from "@mui/material/CircularProgress"
import { styled } from '@mui/material/styles'
import SchoolIcon from '@mui/icons-material/School'
import PersonIcon from '@mui/icons-material/Person'
import Login from "@mui/icons-material/Login"
import PublicIcon from '@mui/icons-material/Public'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MediationIcon from '@mui/icons-material/Mediation'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import RuleIcon from '@mui/icons-material/Rule'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined"
import { renderCell } from '../SimpleTable/render'
import { useHostname } from "lib/HostnameProvider"
import StatusItem from "../UI/StatusItem/StatusItem"
import COLORS from '../../lib/colors'
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { CreativePreviewDialog } from "../UI/CreativePreviewDialog/CreativePreviewDialog"
import getCreativeInfo, { ComplyedState, LeadDetailField } from "services/get-creative-info"
import CygIconButton from "components/UI/Button/CygIconButton"


const StyledTableRow = styled(TableRow)({
    height: "28px",
    verticalAlign: 'top'
})

const StyledTableCell = styled(TableCell)({
  padding: 0,
  borderBottom: 'none',
})

const SECTIONS = [
  {
    sectionTitle: 'Applicant',
    icon: PersonIcon,
    fields: [
      'firstName',
      'lastName',
      'email',
      'phone',
      'streetAddress',
      'city',
      'state',
      'zipCode',
    ]
  },
  {
    sectionTitle: 'Requested Program',
    icon: SchoolIcon,
    fields: [
      'clientId',
      'degreeProgramId',
      'secondaryTcpa',
    ]
  },
  {
    sectionTitle: 'Status',
    icon: ListAltIcon,
    fields: [
      'createdAt',
      'vendorId',
      'externalId',
      'status',
      'statusChangedAt',
      'billable',
      'leadAmountCents',
      'rejectionReason',
      'filteredReason',
    ],
  },
  {
    sectionTitle: 'Traffic Details',
    icon: PublicIcon,
    fields: [
      'subid',
      'source',
    ],
  },
  {
    sectionTitle: 'Technical Details',
    icon: MediationIcon,
    fields: [
      'publicId',
      'remoteIp',
      'userAgent',
    ],
  },
]

function Loader() {
  return (
    <Box sx={{ display: 'block' }}>
      <CircularProgress size='28px' />
    </Box>
  )
}

function Fields({fields}) {
  return (
    <>
      {fields.map((field, index) => (
        <StyledTableRow key={index}>
          <StyledTableCell sx={{
            fontSize: 12,
            color: COLORS.slateGray,
            paddingLeft: '34px',
            width: '196px',
            paddingRight: '12px',
            whiteSpace: 'nowrap'
          }} >
            {field.title}
          </StyledTableCell>
          <StyledTableCell sx={{fontSize: 14, color: COLORS.charcoal}} >
            {field.value}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  )
}

function LoginButton({complyedHostname}) {
  return (
    <CygIconButton
      text='Log in to ComplyEd'
      icon={<Login/>}
      onClick={() => window.open(`https://${complyedHostname}`, "_blank", 'location=yes,height=600,width=8000,scrollbars=yes,status=yes,menubar=yes')}
      sx={{ml: '34px'}}
    />
  )
}

function CreativePreview({creativeUrlPath, creativeType, cid}) {
  const [showCreativePreview, setShowCreativePreview] = useState(false)

  return (
    <Box sx={{ml: '34px'}}>
      <CygIconButton
        text='View Creative'
        icon={<VisibilityOutlined/>}
        onClick={() => setShowCreativePreview(true)}
      />

      { showCreativePreview && (
        <CreativePreviewDialog
          onCancel={() => setShowCreativePreview(false)}
          creativeUrlPath={creativeUrlPath}
          creativeType={creativeType}
          cid={cid}
        />
      )}
    </Box>
  )
}

interface DetailsSectionProps {
  sectionTitle: string
  icon: ReactElement
  fields?: LeadDetailField[]
  pending?: boolean
  footer?: ReactElement | false
}

function DetailsSection({sectionTitle, icon, fields, pending = false, footer}: DetailsSectionProps) {
  return (
    <>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell colSpan={6} sx={{paddingTop: '21px', paddingBottom: '8px'}} >
            <Stack direction="row" spacing={1.5} sx={{alignItems: 'center', marginRight: '-24px'}}>
              <Box sx={{ color: COLORS.slateGray, height: '24px' }}>{icon}</Box>
              <Typography variant="body1" sx={{ color: COLORS.slateGray, fontSize: 14, fontWeight: 600, whiteSpace: 'nowrap' }}>{sectionTitle}</Typography>
              <Divider sx={{ width: '100%', height: '1px', my: 'auto !important', borderColor: COLORS.mediumGray, flex: 1 }} />
            </Stack>
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>

      <TableBody>
        { pending && (
          <StyledTableRow>
            <StyledTableCell colSpan={6} sx={{textAlign: 'center'}}>
              <Loader />
            </StyledTableCell>
          </StyledTableRow>
        ) || fields && (
          <Fields fields={fields} />
        )}

        {footer && (
          <TableRow>
            <StyledTableCell colSpan={6} >
              {footer}
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  )
}

function objectToFields(object) {
  if(typeof(object) === 'string') {
    try {
      return objectToFields(JSON.parse(object))
    } catch(e) {
      return []
    }
  }

  if(!object || typeof(object) !== 'object') {
    return []
  }

  return Object.entries(object).map(([title, value]) => ({title, value}))
}

function LeadDetails({columns, lead}) {
  const [complyedState, setComplyedState] = useState<ComplyedState>({pending: false})

  const sections = useMemo(() => (
    SECTIONS.map(({sectionTitle, icon, fields}) => (
      {
        sectionTitle,
        icon,
        fields: fields.map(field => {
          const column = columns.find(column => column.field === field)
          return column && {
            title: column.title,
            value: renderCell(lead, column),
          }
        }).filter(value => !!value),
      }
    ))
  ), [columns, lead])

  const rawParams = useMemo(() => (
    objectToFields(lead.rawParams)
  ), [lead])

  const trackingParams = useMemo(() => (
    objectToFields(lead.trackingParams)
  ), [lead])

  const leadidToken = lead.jornayaLeadidToken

  const { complyedHostname } = useHostname()

  useEffect(() => {
    if(lead.complyedCreativeId) {
      getCreativeInfo(lead.complyedCreativeId, complyedHostname)
      .then((updatedComplyedState) => setComplyedState(updatedComplyedState))
    }
  }, [complyedHostname, setComplyedState, lead.complyedCreativeId])

  return (
    <div>
      <Typography variant="h3" sx={{color: COLORS.charcoal, paddingBottom: '6px'}}>
        Lead Details — {lead.firstName} {lead.lastName}
      </Typography>
      <Table role="table" aria-label="Lead Details Section" sx={{marginTop: '-6px', marginLeft: '-4px'}}>
        {sections.map(({sectionTitle, icon: IconComponent, fields}, sectionIndex) => (
          <DetailsSection key={sectionIndex} sectionTitle={sectionTitle} icon={<IconComponent/>} fields={fields} />
        ))}
        <DetailsSection sectionTitle="Raw Parameters" icon={<FormatListBulletedIcon/>} fields={rawParams} />
        <DetailsSection sectionTitle="Tracking Parameters" icon={<IntegrationInstructionsIcon/>} fields={trackingParams} />

        {lead.complyedCreativeId && (
          <DetailsSection
            icon={<PermMediaIcon/>}
            sectionTitle="Creative"
            fields={complyedState.creativeData}
            pending={complyedState.pending}
            footer={
              <>
                {complyedState.creativeError && (
                  <StatusItem
                    leftIcon={true}
                    variant='body1'
                    sx={{paddingLeft: '32px', paddingTop: '5px', fontSize: '16px !important', marginBottom: '2px'}}
                    status={{variant: 'error', statusText: complyedState.creativeError}}
                  />
                ) || complyedState.creativeUrlPath && (
                  <CreativePreview
                    creativeUrlPath={complyedState.creativeUrlPath}
                    creativeType={complyedState.creativeData && complyedState.creativeData[1].value}
                    cid={complyedState.creativeData && complyedState.creativeData[0].value} />
                )}

                {complyedState.needComplyedLogin && (
                  <LoginButton complyedHostname={complyedHostname} />
                )}
              </>
            }
          />
        )}

        {leadidToken && <ComplianceSection leadidToken={leadidToken}/>}
      </Table>
    </div>
  )
}

function ComplianceSection({ leadidToken }) {
  return (
    <DetailsSection
      icon={<RuleIcon/>}
      sectionTitle="Compliance"
      fields={[ { title: 'Universal Lead ID', value: leadidToken } ]}
      footer={
        <CygIconButton
          text='View Compliance Report'
          icon={<Login/>}
          onClick={() => window.open(`https://app.leadid.com/leads/lead?token=${leadidToken}`, '_blank', 'noopener,noreferrer')}
          sx={{ml: '34px'}}
        />
      }
    />
  )
}

export default function LeadDetailsDrawer({columns, lead, onClose}) {
  const [open, setOpen] = useState(!!lead)
  const [currentLead, setCurrentLead] = useState(lead) // Make sure the current lead is still visible while the drawer is being closed, even though `lead` is null by then

  useEffect(() => {
    setOpen(!!lead)
    if(lead) {
      setCurrentLead(lead)
    }
  }, [lead, setOpen, setCurrentLead])

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{
          width: 576,
          maxWidth: '50vw',
          padding: '44px 24px',
        }}>
        {currentLead && (
          <LeadDetails columns={columns} lead={currentLead}/>
        )}
      </Box>
    </Drawer>
  )
}
