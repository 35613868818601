
class ComplyedCreativeError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
  }
}

class ComplyedLoginError extends ComplyedCreativeError {
  constructor() {
    super('Log in to ComplyEd to view creative.')
  }
}

class ComplyedNotFoundError extends ComplyedCreativeError {
  constructor() {
    super('Creative ID not found.')
  }
}

function parseDate(date) {
  const dateParts = date.split("-")

  return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
}

export interface LeadDetailField {
  title: string,
  value: string | unknown
}

export interface ComplyedState {
  pending: boolean
  creativeError?: string
  creativeData?: Array<LeadDetailField>
  needComplyedLogin?: boolean
  creativeUrlPath?: string
}

export default function getCreativeInfo(cid, complyedHostname) {
  return fetch(`https://${complyedHostname}/creatives/${cid}.json`, {
      method: "GET",
      credentials: "include",
      mode: "cors"
    })
    .then((response) => {
      if(response.status === 200) {
        return response.json()
      } else if(response.status === 401) {
        throw new ComplyedLoginError()
      } else if(response.status === 404) {
        throw new ComplyedNotFoundError()
      } else {
        throw new ComplyedCreativeError('Something went wrong. Please try again.')
      }
    })
    .then(data => {
      const dateAndTime = data.approved_at?.split(/ (.*)/s, 2)
      const parsedData = [
          { 'title': 'CID', 'value': data.id },
          { 'title': 'Type', 'value': data.ad_type || '—' },
          { 'title': 'Approval Date', 'value': dateAndTime ? parseDate(dateAndTime[0]) : 'None' },
          { 'title': 'Approval Time', 'value': dateAndTime?.[1] || "None" }
        ]

      if(data.url){
        return { pending: false, creativeUrlPath: data.url, creativeData: parsedData }
      } else {
        return { pending: false, creativeError: 'Creative has no visual attachment.', creativeData: parsedData }
      }
    })
    .catch(error => {
      if(error instanceof ComplyedLoginError) {
        return { pending: false, needComplyedLogin: true, creativeError: 'Log in to ComplyEd to view creative.' }
      } else if(error instanceof ComplyedCreativeError) {
        return { pending: false, creativeError: error.message }
      } else {
        return { pending: false, creativeError: 'Something went wrong. Please try again.'}
      }
    }
  )
}
