import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { useState } from 'react'
import SectionTitle, { SectionTitleControls } from '../SectionTitle'
import COLORS from 'lib/colors'
import { useRef } from 'react'
import { useIsOverflow } from 'hooks/useIsOverflow'

export default function StyledCard({
  title,
  note,
  controls,
  height,
  width,
  label,
  role,
  isSubsection = false,
  hasStickyHeader = false,
  children
}: {
  title?: string,
  note?: string,
  controls?: SectionTitleControls,
  height?: string | number,
  width?: string | number,
  label?: string,
  role?: string,
  isSubsection?: boolean,
  hasStickyHeader?: boolean,
  children?: React.ReactNode,
}) {

  return (
    <>
      {!isSubsection &&
        <Card
          aria-label={label || title}
          role={role}
          sx={{
            height: height,
            width: width,
            margin: '12px 0 24px 0',
            position: 'relative',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            overflow: hasStickyHeader ? 'visible' : null,
          }}
        >
          <Box
            className='errorFull'
            sx={{
              ":not(:has(.errorSection)):has(.persistedError)": {borderLeft: `solid 6px ${COLORS.copperRed}`},
            }}
          >
            <Box sx={{":not(:has(.errorSection)):has(.persistedError)": {marginLeft: '-6px'}}}>
              {title &&
                <Box sx={{ padding: '18px 18px 12px 24px' }}>
                  <SectionTitle title={title} note={note} controls={controls} isInCard={true}/>
                </Box>
              }
              {children}
            </Box>
          </Box>
        </Card>
      }
      {isSubsection &&
        <Card
          aria-label={label || title}
          role={role}
          sx={{
            height: height,
            width: width,
            margin: '12px 0 24px 0',
            position: 'relative',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            overflow: hasStickyHeader ? 'visible' : null,
          }}
        >
          {title &&
            <Box sx={{
              padding: '11px 18px 10px 24px',
              backgroundColor: COLORS.aliceBlue,
              zIndex: hasStickyHeader ? 100 : null,
              position: hasStickyHeader ? 'sticky' : 'relative',
              top: hasStickyHeader ? 0 : null,
              borderRadius: hasStickyHeader ? '10px 10px 0px 0px' : null,
            }}>
              <SectionTitle title={title} note={note} controls={controls} isInCard={true}/>
            </Box>
          }
          {children}
        </Card>
      }
    </>
  )
}

// -------------------------------------------------------------------------------
// Wrappers should be used independently of each other (one wrapper type per card)
// -------------------------------------------------------------------------------

// ErrorWrapper is used to apply error styling only to specific portions of a card (instead of to the whole card if no error wrapper is used)
// Generally will wrap a component created multiple times via `map`
// Multiple use
export function ErrorWrapper({ children, sx={} }) {
  return(
    <Box
      className='errorSection'
      sx={{
        padding: "18px 24px 24px 24px",
        ":has(.persistedError)": {borderLeft: `solid 6px ${COLORS.copperRed}`},
        ...sx
      }}
    >
      <Box sx={{":has(.persistedError)": {marginLeft: '-6px'}}}>
        {children}
      </Box>
    </Box>
  )
}

// ContentWrapper should wrap the content of a card which has a title
// Can only use full card error
// Single use
export function ContentWrapper({ children }) {
  return(
    <Box sx={{padding: "0 24px 24px 24px"}}>
      {children}
    </Box>
  )
}

// PlaceholderWrapper styles the content of a card which has no title (including Subsection)
// Can only use full card error
// Single use
export function PlaceholderWrapper({ children }) {
  return(
    <Box sx={{padding: "18px 24px 18px 24px"}}>
      {children}
    </Box>
  )
}

// Collapse Wrapper allows collapsable card content and is intended for a card with a title
// Can only use full card error
// Single use
export function CollapseWrapper({ children, maxHeight = 120 }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useRef()
  const isOverflow = useIsOverflow(ref)

  return(
    <>
      <Collapse
        in={isExpanded}
        collapsedSize={`${maxHeight}px`}
        sx={{ overflow: 'auto' }}
        ref={ref}
      >
        <Box sx={{ margin: '0 126px 0 24px' }}>
          {children}
        </Box>
      </Collapse>
      <Box
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '14px', fontWeight: '600', position: 'relative', right: '30px', bottom: '10px' }}
      >
        {isOverflow &&
          <>
            Expand
            <ArrowDropDownIcon/>
          </>
        }
        {isExpanded && !isOverflow &&
          <>
            Hide
            <ArrowDropUpIcon/>
          </>
        }
      </Box>
    </>
  )
}
