import { Box, Checkbox, Chip, FormControlLabel, MenuItem } from "@mui/material"
import MultiGroupDropdown, { InputGroupSection } from "components/UI/MultiGroupDropdown"
import { groupBy, replaceElement } from "lib/utils"
import { xor } from "lodash-es"
import { useMemo, useCallback } from "react"


const ProgramGroupSection = function({ clientMapping, setClientMapping, programGroup, clientCampaigns, showWaterfall }) {
  const selectedCampaignIds = useMemo(() => clientMapping.find(entry => entry.programGroupId === programGroup.id)?.clientCampaignIds || [], [clientMapping, programGroup.id])

  const setClientMapEntry = useCallback(selectedClientCampaignId => {
    const selectedCampaign = clientCampaigns.find(clientCampaign => clientCampaign.id === selectedClientCampaignId)
    const selectedClientId = selectedCampaign.programGroup.client.id
    const selectedProgramGroupId = selectedCampaign.programGroup.id

    const existingEntry = clientMapping.find(entry => (
      entry.clientId === selectedClientId &&
      entry.programGroupId === selectedProgramGroupId
    ))

    const updatedClientMapping = replaceElement(
      clientMapping,
      existingEntry,
      {
        clientId: selectedClientId,
        programGroupId: selectedProgramGroupId,
        clientCampaignIds: xor(selectedCampaignIds, [selectedClientCampaignId]),
      }
    )

    setClientMapping(updatedClientMapping)
  }, [clientCampaigns, clientMapping, selectedCampaignIds, setClientMapping])

  return (
    <Box>
      {clientCampaigns.length > 0 &&
        <InputGroupSection
          title={programGroup.description}
        >
          {clientCampaigns.map((clientCampaign, index) => (
            <MenuItem key={index} value={clientCampaign.id} onClick={(e) => {setClientMapEntry(clientCampaign.id); e.preventDefault()}} sx={{maxHeight: 36, pl: '16px', justifyContent: 'space-between', width: '100%', '&.Mui-selected': { backgroundColor: 'white' } }}>
              <FormControlLabel control={<Checkbox checked={selectedCampaignIds.includes(clientCampaign.id)} />} label={clientCampaign.name} />
              {showWaterfall &&
                (selectedCampaignIds && selectedCampaignIds.indexOf(clientCampaign.id) >= 0 &&
                  <Chip
                    label={selectedCampaignIds && selectedCampaignIds.indexOf(clientCampaign.id) + 1}
                    size="small"
                    sx={{ ml: 1.5 }}
                  />
                ) || (
                  <Box sx={{width: 30}} />
                )
              }
            </MenuItem>
          ))}
        </InputGroupSection>
      }
    </Box>
  )
}

export default function CampaignSelector({ inputLabel, clientCampaigns, clientMapping, setClientMapping, sx, error=false }) {
  const campaignNames = useMemo(() => Object.fromEntries(clientCampaigns.map(clientCampaign => [clientCampaign.id, clientCampaign.name])), [clientCampaigns])
  const renderClientMapping = useCallback(clientMapping => clientMapping.flatMap(entry => entry.clientCampaignIds?.map(id => campaignNames[id])).filter(Boolean).join(', '), [campaignNames])

  const groupedCampaignsByProgramGroup = groupBy(clientCampaigns,
    'programGroup',
    { comparator: (a,b) => a.id === b.id, asEntries: true}
  )

  return (
    <MultiGroupDropdown
      value={clientMapping}
      renderValue={renderClientMapping}
      inputLabel={inputLabel}
      error={error}
      sx={sx}
    >
      {groupedCampaignsByProgramGroup.map(([programGroup, clientCampaigns], index) => (
        <ProgramGroupSection
          clientMapping={clientMapping}
          setClientMapping={setClientMapping}
          programGroup={programGroup}
          clientCampaigns={clientCampaigns}
          key={index}
          showWaterfall
        />
      ))}
    </MultiGroupDropdown>
  )
}
