import { useRef, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import TextField from '@mui/material/TextField';
import { SketchPicker } from 'react-color';

const useStyles = makeStyles(theme => ({
  colorPicker: {
    width: '100%',
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popover: {
    position: 'absolute',
    top: '60px',
    left: '16px',
    zIndex: '2',
  },
}))

function validateColor(value, defaultValue) {
  if (value && value.match(/^#[a-fA-F0-9]{6}$/)) {
    return value;
  } else {
    return defaultValue;
  }
}

function isDescendant(child, ancestor) {
  if (child === ancestor) {
    return true;
  }
  if (!child.parentNode) {
    return false;
  }
  return isDescendant(child.parentNode, ancestor);
}

export default function ColorPicker(props) {
  const classes = useStyles();
  const [showPicker, setShowPicker] = useState();
  const [colorText, setColorText] = useState(props.defaultValue || '');
  const [color, setColor] = useState(validateColor(props.defaultValue, '#000000'));
  const popoverRef = useRef(null);

  const handleBlur = (event) => {
    if(event.relatedTarget && !isDescendant(event.relatedTarget, popoverRef.current)) {
      setShowPicker(false);
    }
  }

  return (
    <div className={classes.colorPicker}>
      {showPicker && (
        <div className={classes.popover} ref={popoverRef}>
          <div className={classes.cover} onClick={() => setShowPicker(false)}/>
          <SketchPicker
            color={color}
            disableAlpha={true}
            onChangeComplete={(color) => { setColor(color.hex); setColorText(color.hex); props.onChange(color.hex) }}
          />
        </div>
      )}
      <TextField
        label={props.label}
        placeholder={props.placeholder}
        margin="normal"
        className={classes.textField}
        value={colorText}
        onFocus={() => setShowPicker(true)}
        onBlur={handleBlur}
        onChange={(event) => { setColorText(event.target.value); setColor(validateColor(event.target.value, color)); props.onChange(validateColor(event.target.value, '')); }}
      />
    </div>
  );
}
