import { getIn, useFormikContext } from 'components/Formik/forms';
import SubsectionTitle from 'components/UI/SubsectionTitle.jsx/SubsectionTitle'
import { useCallback, useEffect, useState } from 'react';
import CampaignSelector from './CampaignSelector';
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import StyledCard, { ErrorWrapper } from 'components/UI/StyledCard';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { changeItemIndex, removeItemAtIndex, validateOrFixPositions } from '../../../data/utils';
import { getSelectedClientsForRule, getClientsAndCampaigns, buildImpliedClientMappingEntries, buildRuleCondition, removeUnusedClientMappingEntries } from 'components/pages/LandingPages/data/helpers';
import ClientSelector from './ClientSelector';
import EmptyStateSection from './EmptyStateSection';
import FilteredCampaignSelection from './FilteredCampaignSelection'
import QuestionOperatorValue from './QuestionOperatorValue';
import { DefaultCampaignSelectionMulti } from './DefaultCampaignSelection';
import { generateRange } from 'lib/utils';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Box from '@mui/material/Box';
import COLORS from '../../../../../../lib/colors'
import IconButton from '@mui/material/IconButton';
import { upperFirst } from 'lodash-es';
import { ProgramSelector } from './DegreeProgramRules';


const COMMON_LEAD_ATTRIBUTES = [
  { text: 'Source', key: 'source', id: 'source' },
  { text: 'SubID', key: 'subid', id: 'subid' },
  { text: 'Creative ID', key: 'cid', id: 'cid' },
  { text: "Hour (in Vendor's time zone)", key: 'hour', id: 'hour', options: [...generateRange(0,23)].map(i => i.toString()) },
]

function ClientAndCampaignSelection({ path, rule }) {
  const { values, setFieldValue, errors } = useFormikContext()

  const hasError = !!getIn(errors, path)
  const clientsAndCampaigns = getClientsAndCampaigns(values.leadTargets)
  const clients = clientsAndCampaigns.map(([clients, _]) => clients)
  const campaigns = clientsAndCampaigns.flatMap(([_, clientCampaigns]) => clientCampaigns)
  const [clientsSelected, setClientsSelected] = useState(() => getSelectedClientsForRule(rule, clients))
  const selectedClientsAndCampaigns =  clientsAndCampaigns.filter(([client, _]) => clientsSelected.map(c => c.id).includes(client.id))

  useEffect(() => {
    const selectedClientIds = clientsSelected.map(c => c.id)
    const filteredCampaigns = campaigns.filter(c => selectedClientIds.includes(c.programGroup.client.id))
    const clientMapping = removeUnusedClientMappingEntries(filteredCampaigns, rule.clientMapping)
    setFieldValue(`${path}.clientMapping`, buildImpliedClientMappingEntries(filteredCampaigns, clientMapping, true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsSelected])

  // offset Grid width and left margin it aligns with the Stack
  const offset = 2 * 6

  return(
    <Grid container direction='row' spacing={2} sx={{width: `calc(100% + ${offset}px)`, ml: `-${offset}px !important`, mt: '0 !important' }}>
      {clients.length === 1 &&
        <>
          {clientsAndCampaigns.map(([client, clientCampaigns]) =>
            <Grid item xs={4} key={"lead_target_dropdown" + client.id}>
              <CampaignSelector
                inputLabel='Campaigns'
                clientCampaigns={clientCampaigns}
                clientMapping={getIn(values, `${path}.clientMapping`)}
                setClientMapping={(selected) => setFieldValue(`${path}.clientMapping`, selected)}
                error={hasError}
                sx={{
                  flexGrow: 1,
                }}
              />
            </Grid>
          )}
        </>
      }
      {clients.length > 1 &&
        <>
          <Grid item xs={4}>
            <ClientSelector
              selected={clientsSelected}
              setSelected={setClientsSelected}
              path={path}
            />
          </Grid>
          {selectedClientsAndCampaigns.map(([client, clientCampaigns]) =>
            <Grid item xs={4} key={"lead_target_dropdown" + client.id}>
              <CampaignSelector
                inputLabel={client.name}
                clientCampaigns={clientCampaigns}
                clientMapping={getIn(values, `${path}.clientMapping`)}
                setClientMapping={(selected) => setFieldValue(`${path}.clientMapping`, selected)}
                error={hasError}
                sx={{
                  flexGrow: 1,
                }}
              />
            </Grid>
          )}
        </>
      }
    </Grid>
  )
}

function RemoveButton({className, onClick}) {
  return(
    <Box
      className={className}
      sx={{
        position: 'absolute',
        top: 0,
        right: -36,
      }}
    >
      <Box
        sx={{
          borderRadius: '10px',
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
          width: '33px',
          height: '48px',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bottom: 3,
            left: -3,
            width: '24px',
            height: '56px',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              top: 3,
              left: 3,
              padding: '12px 6px 12px 3px',
              borderRadius: '10px',
              width: '33px',
              height: '48px',
              backgroundColor: 'white',
            }}
          >
            <IconButton onClick={onClick}  color='secondary'>
              <CloseIcon sx={{ color: COLORS.slateGray }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export function RuleCondition({ rulePath, index }) {
  const { values, setFieldValue } = useFormikContext()
  const conditionPath = `${rulePath}.conditions[${index}]`
  const otherMenuGroup = { title: 'Lead Attributes', attributes: COMMON_LEAD_ATTRIBUTES}

  const attributeSelectOptions = [
    ...values.steps.map(step => ({ title: `Step ${step.position}`, attributes: step.questions })),
    otherMenuGroup,
  ]

  const handleDeleteCondition = useCallback(() => {
    const conditionsPath = `${rulePath}.conditions`
    const conditions = getIn(values, conditionsPath)
    const updatedConditions = removeItemAtIndex(conditions, index)
    setFieldValue(conditionsPath, updatedConditions)
  }, [index, rulePath, setFieldValue, values])

  return(
    <Box
      sx={{
        position: 'relative',
        '&:not(:hover) .visibleOnlyOnHover': {visibility: 'hidden'},
      }}
    >
      <QuestionOperatorValue
        path={conditionPath}
        attributeSelectOptions={attributeSelectOptions}
      />
      <RemoveButton className='visibleOnlyOnHover' onClick={handleDeleteCondition} />
    </Box>
  )
}

function CustomRule({ path, index, title, dragHandleProps, ruleType, description }) {
  const { values, setFieldValue } = useFormikContext()

  const rule = getIn(values, path)
  const rules = values[ruleType]

  const handleDeleteRule = useCallback(() => {
    const updatedRules = removeItemAtIndex(rules, index)
    setFieldValue(ruleType, updatedRules)
  }, [rules, index, setFieldValue, ruleType])

  const handleAddCondition = useCallback(() => {
    setFieldValue(`${path}.conditions[${rule.conditions.length}]`, buildRuleCondition())
  }, [rule?.conditions?.length, path, setFieldValue])

  return(
    <ErrorWrapper>
      <Stack direction='column' spacing={2} marginBottom={1} role="listitem" aria-roledescription={`Custom ${upperFirst(description)} Rule`} aria-label={`Custom ${upperFirst(description)} Rule ${index}`} >
        <SubsectionTitle
          title={title}
          controls={[
            { icon:  <AddIcon/>, label: 'Add Condition', onClick: handleAddCondition },
            { icon:  <CloseIcon/>, label: 'Delete Rule', onClick: handleDeleteRule }
          ]}
          dragHandleProps={dragHandleProps}
        />
        {rule?.conditions?.map((_, index) =>
          <RuleCondition rulePath={path} index={index} key={`rule_condition_${index}`}/>
        )}
        {ruleType === 'clientCampaignRules' &&
          <ClientAndCampaignSelection path={path} rule={rule} />
        }
        {ruleType === 'degreeProgramRules' &&
          <ProgramSelector rule={rule} originalIndex={index} />
        }
      </Stack>
    </ErrorWrapper>
  )
}

export function CustomRuleSection({ name, description}) {
  const { values, setFieldValue } = useFormikContext()
  const customRules = values[name]
    .map((rule, index) => ({rule, originalIndex: index}))
    .filter(({rule}) => !rule.default)

  const onDragEnd = useCallback(( { destination, source } ) => {
    if(!destination) { return }

    const oldIndex = customRules[source.index].originalIndex
    const newIndex = customRules[destination.index].originalIndex

    if(newIndex === oldIndex) { return }

    const reorderedRules = changeItemIndex(values[name], oldIndex, newIndex)
    setFieldValue(name, validateOrFixPositions(reorderedRules))
    // linter is mistaken, it doesn't know how to handle the dynamically accessed attribute of values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customRules, setFieldValue, values[name]])

  return(
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`custom_${description}_rules_droppable`} key={`custom_${description}_rules_droppable`}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Stack
              direction="column"
              divider={<Divider light flexItem sx={{ mx: 4 }}/>}
              role="list"
              aria-roledescription={`Custom ${upperFirst(description)} Rules`}
            >
              {customRules.map(({rule, originalIndex}, index) =>
                <Draggable
                  draggableId={`draggable_custom_${description}_rule_${rule.id}`}
                  key={`draggable_custom_${description}_rule_${rule.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      sx={{
                        ...(snapshot.isDragging === true && {
                          backgroundColor: COLORS.backgroundGray
                        })
                      }}
                      {...provided.draggableProps}

                      ref={provided.innerRef}
                    >
                      <CustomRule
                        path={`[${name}][${originalIndex}]`}
                        key={`${description}_selection_rule_${rule.id}`}
                        index={originalIndex}
                        title={`${upperFirst(description)} Rule ${index + 1}`}
                        dragHandleProps={provided.dragHandleProps}
                        ruleType={name}
                        description={description}
                      />
                    </Box>
                  )}
                </Draggable>
              )}
              {customRules.length === 0 &&
                <EmptyStateSection message={`No ${description} selection rules.`} />
              }
            </Stack>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default function CampaignSelectionRules({ handleAddCampaignRule }) {
  return (
    <StyledCard
      title='Campaign Selection Rules'
      note='Leads sent to default campaigns or reassigned based on answers to questions.'
      controls={[
        { icon:  <AddIcon/>, label: 'Add Campaign Rule', onClick: handleAddCampaignRule },
      ]}
      isSubsection
      hasStickyHeader
      role="group"
    >
      <Stack
        direction="column"
        divider={<Divider light flexItem sx={{ mx: 4 }}/>}
      >
        <DefaultCampaignSelectionMulti />
        <FilteredCampaignSelection />
        <CustomRuleSection name='clientCampaignRules' description='campaign'/>
      </Stack>
    </StyledCard>
  )
}
