import { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server';

import makeStyles from '@mui/styles/makeStyles';

import Chip from '@mui/material/Chip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import TextField from '@mui/material/TextField'

const useStyles = makeStyles(theme => ({
  templatable: {
    outline: 'none',
    padding: '9px 10.5px',
    height: '36px',
    lineHeight: '18px',
    width: '100%',
    overflow: 'hidden',
  },
  templatableContainer: {
    width: '100%',
  },
  chip: {
    display: 'inline-flex',
    marginTop: '-9px',
  },
  plainText: {
    backgroundColor: '#ffffe0'
  },
  popupMenu: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
}));

const splitParts = function(string) {
  const templateRegex = /{[^}]+}/g
  const ranges = [{start: 0, end: string.length, isTemplate: false}]
  let result

  while(result = templateRegex.exec(string)) {
    const range = {start: result.index, end: result.index + result[0].length, isTemplate: true}
    ranges[ranges.length-1].end = result.index
    ranges.push(range)
    ranges.push({start: range.end, end: string.length, isTemplate: false})
  }

  // Remove empty ranges
  return ranges.filter(range => range.end > range.start)
}

const TemplatableInputComponent = function({inputRef, value, onChange, menuItems, ChipProps, style, ...props}) {
  const classes = useStyles();
  const parts = splitParts(value)
  const anchorRef = useRef(null)
  const hiddenRef = useRef(null)
  const popperRef = useRef(null)
  const [html, setHtml] = useState('')
  const [counter, setCounter] = useState(0)
  const [popperOpen, setPopperOpen] = useState(false)

  useEffect(() => {
    const listener = event => {
      (anchorRef.current && anchorRef.current.contains(event.target)) ||
        (popperRef.current && popperRef.current.contains(event.target)) ||
        setPopperOpen(false)
    }

    document.addEventListener('focus', listener, { capture: true })
    return () => {
      document.removeEventListener('focus', listener, { capture: true })
    }
  }, [])

  useEffect(() => {
    if(hiddenRef.current) {
      setHtml(hiddenRef.current.innerHTML)
    }
  }, [hiddenRef.current, counter])

  const setValue = newValue => {
    onChange({ target: { name: props.name, value: newValue } })
    setPopperOpen(false)
    setCounter(counter+1)
  }

  const handleChange = () => {
    const element = anchorRef.current
    if(!element) {
      return
    }
    const newValue = [...element.childNodes].map(child => {
      if(child instanceof Text) {
        return child.nodeValue
      } else if(child instanceof Element) {
        return child.getAttribute("data-value") || child.textContent
      } else {
        return ""
      }
    }).join("")
    if(newValue !== value) {
      setValue(newValue)
    }
  }

  const handleKeyPress = () => {
    if(!anchorRef.current) {
      return
    }

    // Remove any elements other than the ones we created, but keep any text content that may have been pasted in
    anchorRef.current.querySelectorAll('*').forEach(element => {
      if(!element.matches(`span.${classes.chip}, span.${classes.chip} *, span.${classes.plainText}`) && element.parentNode) {
        element.parentNode.replaceChild(new Text(element.textContent), element)
      }
    })
  }

  const appendToValue = appendValue => {
    setValue(`${value}${appendValue}`)
  }

  return (
    <ClickAwayListener onClickAway={() => { setPopperOpen(false) }}>
      <div className={classes.templatableContainer}>
        <div style={{display: 'none'}} ref={hiddenRef}>
          {parts.map((part, index) => (
            part.isTemplate && (
              <span key={index} className={classes.chip} data-value={value.substring(part.start, part.end)} contentEditable={false}><Chip label={value.substring(part.start+1, part.end-1)} {...ChipProps}/></span>
            ) || (
              <span key={index} className={classes.plainText}>
                {value.substring(part.start, part.end)}
              </span>
            )
          ))}
        </div>
        <div
          ref={anchorRef}
          contentEditable={true}
          suppressContentEditableWarning={true}
          className={classes.templatable}
          style={style}
          spellCheck={false}
          onFocus={() => setPopperOpen(true)}
          onBlur={handleChange}
          onInput={handleKeyPress}
          dangerouslySetInnerHTML={{__html: html}}
        >
        </div>
        {anchorRef.current && (
          <Popper open={popperOpen} ref={popperRef} anchorEl={anchorRef.current} placement="top-start">
            <Paper className={classes.popupMenu}>
              <MenuList onClick={(event) => event.target.dataset.value && appendToValue(event.target.dataset.value)}>
                {menuItems}
              </MenuList>
            </Paper>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}

export { TemplatableInputComponent }

export default function TemplatableTextField(props) {
  return (
    <TextField
      InputProps={{
        inputComponent: TemplatableInputComponent
      }}
      inputProps={{
        foo: 'bar',
        value: props.value,
        onChange: props.onChange
      }}
      {...props}
    />
  )
}
