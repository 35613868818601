import React from "react";

import MonthSelector from "./";

export default {
  title: "UI/MonthSelector",
  component: MonthSelector,
};

const Template = (args) => <MonthSelector {...args} />;

export const Default = Template.bind({});
Default.args = {};
