import { Stack } from "@mui/material";
import { FormTextField } from "components/Formik/forms";
import StyledCard, { ErrorWrapper } from "components/UI/StyledCard";
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle";

export default function DuplicateLeads() {

  return (
    <StyledCard
      title='Duplicate Leads'
      note='Leads redirected when rejected from clients due to duplication.'
      isSubsection
      hasStickyHeader
      role="group"
    >
      <ErrorWrapper>
        <Stack direction='column' spacing={2}>
          <SubsectionTitle title="Default Duplicate Redirect URL" />
          <FormTextField label="Redirect URL" name="duplicateRedirectUrl" />
        </Stack>
      </ErrorWrapper>
    </StyledCard>
  )
}
