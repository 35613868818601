import { useCallback, useContext, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useNotifications } from "lib/NotificationsProvider"
import { useFormikContext } from 'formik'
import { useBookends } from 'lib/BookendsProvider'
import { useMutation } from '@apollo/client'
import CampaignSelectionRules from './CampaignSelectionRules'
import LandingPageExclusionRules from './LandingPageExclusionRules'
import FormWrapper from 'components/Formik/FormWrapper'
import { buildMutation, UPDATE_LANDING_PAGE_MUTATION } from 'components/pages/LandingPages/data/mutations'
import { rulesSchema } from 'components/pages/LandingPages/data/yupSchemas'
import { useLandingPageActionsContext, useLandingPageStateContext } from 'components/pages/LandingPages/ui/LandingPageProvider'
import { addItemAtIndex } from '../../../data/utils';
import { buildClientCampaignRule, buildDegreeProgramRule, buildLandingPageExclusionRule } from 'components/pages/LandingPages/data/helpers';
import { getErrorMessage } from 'components/pages/LandingPages/data/utils'
import ClientAndProgramRules from './ClientAndProgramRules'
import { NavigationContext } from '../Navigation/LandingPageEditNavigation'
import DuplicateLeads from './DuplicateLeads'
import SectionTitle from 'components/UI/SectionTitle'
import { PageSection } from 'components/UI/Structure/PageSection'
import DegreeProgramRules from './DegreeProgramRules'


function RulesPageForm() {
  const { values, setFieldValue, dirty, isSubmitting, submitForm, resetForm, errors } = useFormikContext()
  const { showPrompt } = useContext(NavigationContext)
  const { isMultiClient } = useLandingPageStateContext()

  const handleAddDegreeRule = useCallback(() => {
    const numExistingDegreeProgramRules = values.degreeProgramRules.filter(l => !l.default).length
    const updatedDegreeProgramRules = addItemAtIndex(values.degreeProgramRules, buildDegreeProgramRule({ position: (numExistingDegreeProgramRules + 1) }))
    setFieldValue('degreeProgramRules', updatedDegreeProgramRules)
  }, [setFieldValue, values.degreeProgramRules])


  const handleAddCampaignRule = useCallback(() => {
    const numExistingClientCampaignRules = values.clientCampaignRules.filter(l => !l.default).length
    const updatedClientCampaignRules = addItemAtIndex(values.clientCampaignRules, buildClientCampaignRule({ position: (numExistingClientCampaignRules + 1) }))
    setFieldValue('clientCampaignRules', updatedClientCampaignRules)
  }, [setFieldValue, values.clientCampaignRules])

  const handleAddLandingPageExclusionRule = useCallback(() => {
    const numExistingLandingPageRules = values.landingPageRules.length + 1
    const rulesWithNewRule = addItemAtIndex(values.landingPageRules, buildLandingPageExclusionRule({ landingPageId: values.id, position: numExistingLandingPageRules,  }))
    setFieldValue(`landingPageRules`, rulesWithNewRule)
  }, [setFieldValue, values.id, values.landingPageRules])

  const { setBookends } = useBookends()

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: "secondary",
            label: "Cancel",
            onClick: () => {
              showPrompt().then(() => resetForm())
            },
          },
          {
            type: "menu",
            label: "Add new",
            items: [
              {
                text: "Portal Rule",
                onClick: handleAddLandingPageExclusionRule,
              },
              {
                text: "Campaign Selection Rule",
                onClick: handleAddCampaignRule,
              },
            ],
          },
          {
            type: "primary",
            label: isSubmitting ? 'Please wait...' : 'Save',
            disabled: !dirty || isSubmitting,
            onClick: submitForm,
          },
        ],
      },
      state: {
        form: {
          dirty: dirty,
          errors: errors,
        },
      },
    })
  }, [dirty, errors, handleAddCampaignRule, handleAddLandingPageExclusionRule, isSubmitting, resetForm, setBookends, showPrompt, submitForm])

  return (
    <>
      <PageSection>
        <SectionTitle title='Rules' />
        <LandingPageExclusionRules handleAddLandingPageExclusionRule={handleAddLandingPageExclusionRule}/>
      </PageSection>

      <PageSection>
        <ClientAndProgramRules />
      </PageSection>

      <PageSection>
        <DuplicateLeads />
      </PageSection>

      {!isMultiClient &&
        <PageSection>
          <DegreeProgramRules handleAddDegreeProgramRule={handleAddDegreeRule}/>
        </PageSection>
      }

      <PageSection>
        <CampaignSelectionRules handleAddCampaignRule={handleAddCampaignRule}/>
      </PageSection>
    </>
  )
}

export default function RulesPage() {
  const { landingPage } = useLandingPageStateContext()
  const { setLandingPage } = useLandingPageActionsContext()

  const { addNotification } = useNotifications()

  const [gqlUpdateLandingPage] = useMutation(UPDATE_LANDING_PAGE_MUTATION)

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const mutation = buildMutation(landingPage, values)

    gqlUpdateLandingPage({variables: mutation}).then(({ data }) => {
      setLandingPage(data.mutationResult.landingPage)
      addNotification({variant: 'notice', message: "Your changes have been saved."})
    }).catch(error => {
      addNotification({variant: 'alert', message: getErrorMessage(error)})
      console.log(error)
    }).finally(() => {
      setSubmitting(false)
    })
  }, [addNotification, gqlUpdateLandingPage, landingPage, setLandingPage])

  return (
    <FormWrapper data={landingPage} schema={rulesSchema} onSubmit={handleSubmit}>
      <DndProvider backend={HTML5Backend}>
        <RulesPageForm />
      </DndProvider>
    </FormWrapper>
  )
}
