import SimpleTable from ".";

export default {
  title: "Components/SimpleTable",
  component: SimpleTable,
};

const Template = (args) => <SimpleTable {...args} />;

export const Default = Template.bind({});
Default.args = {
  title: "Sample Table",
  data: [
    {
      clientCampaignName: "Healthcare Management - Exclusive",
      clientCampaignNum: 33,
      clientCampaignType: "Exclusive",
    },
    {
      clientCampaignName: "Healthcare Management - Shared",
      clientCampaignNum: 135,
      clientCampaignType: "Shared",
    },
    {
      clientCampaignName: "Master's Programs - Shared",
      clientCampaignNum: 136,
      clientCampaignType: "Shared",
    },
  ],
  columns: [
    {
      title: "Campaign",
      field: "clientCampaignName",
      summary: { text: "Foo" },
      visible: true,
    },
    {
      title: "Type",
      field: "clientCampaignType",
      summary: { text: "Bar" },
      visible: true,
    },
    {
      title: "Campaign Num",
      field: "clientCampaignNum",
      summary: "sum",
      visible: true,
    },
  ],
  options: {
    columnsButton: true,
    hover: true,
  },
};

export const Pagination = Template.bind({});
Pagination.args = {
  ...Default.args,
  options: { ...Default.args.options, paginated: true, pageSize: 3 },
};

export const Filtering = Template.bind({});
Filtering.args = {
  ...Default.args,
  options: { ...Default.args.options, filtering: true },
};

export const Summary = Template.bind({});
Summary.args = {
  ...Default.args,
  options: { ...Default.args.options, summaryRow: true },
};

export const SummaryCollapse = Template.bind({});
SummaryCollapse.args = {
  ...Summary.args,
  options: { ...Summary.args.options, summaryCollapse: true },
};
