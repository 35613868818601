import SvgIcon from "@mui/material/SvgIcon"

export default function GreaterThanOrEqualIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path fill="currentColor" d="M7 17.85V16.5h9.35v1.35z"/>
              <path d="M7.031 15v-1.477l6.41-3.246-6.41-3.175V5.707l9.293 4.57z" fill="currentColor" fillRule="nonzero"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
