import { standardInflectors } from '@orbit/serializers'

import PlainDate from '../lib/PlainDate'
import { dateRangeToTimeRange } from '../lib/time'

const { dasherize } = standardInflectors

function transformFilterValue(value, { timezone }) {
  if(typeof(value) === 'object' && value instanceof Array && value.length === 2 && value.every(v => typeof(v) === 'object' && v instanceof PlainDate)) {
    return dateRangeToTimeRange(value, timezone).timeRange?.map(m => m.format()).toString()
  } else {
    return value.toString()
  }
}

export function buildQueryParams({sortColumn, sortOrder, page=1, pageSize=10, filters=[], timezone}) {
  const params = [
    ['page', page],
    ['per_page', pageSize],
  ];
  params.push.apply(params, filters.map(filter => [`filter[${dasherize(filter.field)}]`, transformFilterValue(filter.value, {timezone})]))
  if(sortColumn) {
    params.push([`order[${dasherize(sortColumn)}]`, sortOrder])
  }
  return params
}

const buildQueryString = query => {
  return buildQueryParams(query).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
};

const loadTableData = (remote, queryFn, addNotification, timezone) => (dataOptions) => {
  const options = {
    sources: {
      remote: {
        settings: {
          params: Object.fromEntries(buildQueryParams({ ...dataOptions, timezone })),
        },
      },
    },
    fullResponse: true,
  }

  return remote.query(queryFn, options)
    .then(response => ({
      data: response.data.map(record => ({ id: record.id, ...record.attributes })),
      page: dataOptions.page,
      totalCount: response.details[0].document.meta.total,
    }))
    .catch(_error => {
      addNotification({variant: 'alert', message: 'Failed to load data.'});
    })
};

export { loadTableData, buildQueryString };
