import Box from "@mui/material/Box";
import StatusItem from "./StatusItem";

export default {
  title: "UI/StatusItem",
  component: StatusItem,
  parameters: {
    zeplinLink: "https://zpl.io/9qvv0Rl",
    layout: "fullscreen",
  },
};

const Template = (args) => {
  return (
    <Box sx={{ height: "300px" }}>
      <StatusItem {...args} />
    </Box>
  );
};

export const Default = Template.bind({});
Default.args = {
  status: { variant: "success", statusText: "Data valid through 2/28/23" },
};

export const VariantWith16pxFont = Template.bind({});
VariantWith16pxFont.args = {
  ...Default.args,
  variant: "body1",
};

export const LeftIcon = Template.bind({});
LeftIcon.args = {
  ...Default.args,
  leftIcon: true,
};
