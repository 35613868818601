import SvgIcon from "@mui/material/SvgIcon"

export default function LessThanIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path d="M16.324 7.357v1.441l-6.41 3.235 6.41 3.176v1.441l-9.293-4.617z" fill="currentColor" fillRule="nonzero"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
