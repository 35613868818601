import Tooltip from "@mui/material/Tooltip"
import Stack from '@mui/material/Stack'
import StatusItem, { Status } from "../StatusItem/StatusItem"
import { SxProps } from "@mui/system"


interface StatusPanelProps {
  statuses: StatusWithTooltip[]
  leftIcon?: boolean
  sx?: SxProps
}

export interface StatusWithTooltip extends Status {
  tipTitle: React.ReactNode
}

export function StatusWithToolTip({status, leftIcon = false}) {
  return (
    <Tooltip
      title={status.tipTitle}
      placement={leftIcon ? 'bottom-start' : 'bottom-end'}
      followCursor={false}
      componentsProps={{
        tooltip: {
          sx: {
            mt: '0 !important',
            bgcolor: 'white',
            color: status.variant + '.main',
            padding: '12px 18px',
            borderRadius: '10px',
            boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            fontWeight: '600',
            maxWidth: 'none',
          },
        },
      }}
    >
      <StatusItem status={status} leftIcon={leftIcon}/>
    </Tooltip>
  )
}

export function StatusPanelWrapper({ children, leftIcon = false, sx={} }) {
  return (
    <Stack direction="column" alignItems={leftIcon ? "flex-start" : "flex-end"} justifyContent='end' sx={{...sx}}>
      {children}
    </Stack>
  )
}

export default function StatusPanel({ statuses, leftIcon = false, sx={} }: StatusPanelProps) {

  return (
    <StatusPanelWrapper leftIcon={leftIcon} sx={{...sx}}>
      {statuses.map((status, index) => (
        <StatusWithToolTip status={status} leftIcon={leftIcon} key={index}/>
      ))}
    </StatusPanelWrapper>
  )
}
