import PromisedResource from './PromisedResource'

function getPromise({ remote, dateRange, clientId }) {
  const options = {
    sources: {
      remote: {
        settings: {
          params: {
            start_time: dateRange[0].format(),
            end_time: dateRange[1].format(),
          }
        }
      }
    }
  }

  return remote.query(q => q.findRelatedRecords({ type: 'client', id: clientId }, 'stateStats'), options)
}

export default class StateStatsResource extends PromisedResource {
  constructor({ remote, dateRange, clientId }) {
    super(getPromise({ remote, dateRange, clientId }))
  }
}
