import GrayHeaderBox from "components/UI/GrayHeaderBox"
import MultiGroupSelect from "components/UI/MultiGroupSelect"
import RadioButton from "components/UI/RadioButton"
import { isEqual } from "lodash-es"
import { getIn } from "formik"
import { spliceCopy, isShallowEqual } from "lib/utils"
import { useMemo, useCallback, useState, useEffect, memo } from "react"
import { removeTypename } from "components/pages/LandingPages/data/utils"
import { useNotifications } from 'lib/NotificationsProvider'
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import COLORS from '../../../../../../lib/colors'
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"


function isMatchingError(errors, questionPath, optionIndex) {
  const fieldMappingErrors = getIn(errors, `${questionPath}.fieldMapping`)
  return Boolean(fieldMappingErrors?.some(error => getIn(error, `values[${optionIndex}]`)))
}

function buildMatchedAnswerString(index, fieldMapping, clientFieldGroups) {
  const mappedValues = fieldMapping.map(fieldMap => {
    const client = clientFieldGroups.find(c => c.client.id === fieldMap.clientId)?.client
    if(!client) {
      return
    }
    const clientField = client.fields.find(field => field.name === fieldMap.field)
    if(!clientField || !clientField?.options) {
      return
    }
    const clientAnswer = clientField.options.find(option => option.value === fieldMap.values[index])
    if(!clientAnswer) {
      return
    }

   return clientAnswer.text
  })

  return mappedValues.filter(value => typeof(value) === 'string').join(', ')
}

function MatchedAnswersBody({ clientFieldGroups, stepIndex, questionIndex, question, setFieldValue, errors }) {
  const questionPath = useMemo(() => (`steps[${stepIndex}].questions[${questionIndex}]`), [questionIndex, stepIndex])

  const clearOptionMatches = useCallback(index => {
    const updatedFieldMap = []
    question.fieldMapping.forEach(fieldMap => {
      updatedFieldMap.push({...fieldMap, values: spliceCopy(fieldMap.values, index, 1, null)})
    })
    setFieldValue(`steps[${stepIndex}].questions[${questionIndex}].fieldMapping`, updatedFieldMap)
  }, [question.fieldMapping, questionIndex, setFieldValue, stepIndex])

  return (
    <Box role="list" aria-label="Matched Answers" sx={{ margin: '2px 0px 2px 0px', pb: '4px' }}>
      {question.options.map((option, index) => {
        const hasError = isMatchingError( errors, questionPath, index)

        return (
          <Box
            role="listitem"
            aria-label={option}
            key={`matched_answer_${question.id}_${index}_${option}`}
            className={"matchedAnswersResult"}
            sx={{ display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: hasError ? COLORS.seashell : null,
                  padding: '0 4px 0px 13px',
                  my: '6px',
                }}
          >
          <Box sx={{ padding:"4px 6px 6px 6px",
                     borderBottom: hasError ? `2px solid ${COLORS.copperRed}` : `1px solid ${COLORS.slateGray}`,
                     width:"100%",
                     color: hasError ? COLORS.copperRed : null,
                     marginLeft:"6px",
                  }}
          >
            <Typography variant="body1" component="span">
              <Typography sx={{ fontWeight: 600, padding: '0 3px 0 0' }} component="span">
                {`${option}: `}
              </Typography>
              {buildMatchedAnswerString(index, question.fieldMapping, clientFieldGroups)}
            </Typography>
          </Box>
          <IconButton aria-label='Clear' size="small" onClick={() => clearOptionMatches(index)}>
            <CloseIcon sx={{ color: hasError ? COLORS.copperRed : COLORS.slateGray }}/>
          </IconButton>
        </Box>
        )
      })}
    </Box>
  )
}

function DisplayedAnswersBody({ question, displayedOptionIndex, setDisplayedOptionIndex }) {
  return (
    <Box
      role='radiogroup'
      aria-label={question.key}
      sx={{ margin: '10px 18px', display: 'flex', flexDirection: 'column' }}
    >
      {question.options.map((option, index) => (
        <RadioButton
          key={`${question.id}_${index}_${option}`}
          value={option}
          checked={question.options[displayedOptionIndex] === option}
          onChange={() => setDisplayedOptionIndex(index)}
          label={option}
        />
      ))}
    </Box>
  )
}

function DisplayedAnswers({question, displayedOptionIndex, setDisplayedOptionIndex}) {
  return (
    <GrayHeaderBox title="Displayed Answers">
      <DisplayedAnswersBody
        question={question}
        displayedOptionIndex={displayedOptionIndex}
        setDisplayedOptionIndex={setDisplayedOptionIndex}
      />
   </GrayHeaderBox>
  )
}

function ClientAnswers({unlinkedFieldMapping, setUnlinkedFieldMapping, clientFieldGroups, displayedOptionIndex, linkErrorsByClientId}) {
  return (
    <GrayHeaderBox title="Client Answers">
      <MultiGroupSelect
        fieldMapping={unlinkedFieldMapping}
        setFieldMapping={setUnlinkedFieldMapping}
        clientFieldGroups={clientFieldGroups}
        displayedOptionIndex={displayedOptionIndex}
        linkErrorsByClientId={linkErrorsByClientId}
        sx={{ marginTop: '10px', display: 'flex' }}
      />
    </GrayHeaderBox>
  )
}

function MatchedAnswers({
  clientFieldGroups,
  stepIndex,
  questionIndex,
  unlinkedFieldMapping,
  displayedOptionIndex,
  setLinkErrorsByClientId,
  setFieldValue,
  question,
  errors,
}) {
  // @ts-ignore
  const { addNotification } = useNotifications()
  const [fieldMapIsDirty, setFieldMapIsDirty] = useState(false)

  useEffect(() => {
    const unlinkedWithoutTypename = removeTypename(unlinkedFieldMapping)
    const fieldMappingWithoutTypename = removeTypename(question.fieldMapping)

    setFieldMapIsDirty(!isEqual(unlinkedWithoutTypename, fieldMappingWithoutTypename))
  }, [question.fieldMapping, unlinkedFieldMapping])

  const linkQuestionMatches = useCallback(() => {
    const errorsByClientId = {}

    unlinkedFieldMapping.forEach(fieldMap => {
      if(fieldMap.values[displayedOptionIndex] === null) {
        if(displayedOptionIndex in errorsByClientId){
          errorsByClientId[displayedOptionIndex].push(fieldMap.clientId)
        } else {
          errorsByClientId[displayedOptionIndex] = [fieldMap.clientId]
        }
      }
    })
    setLinkErrorsByClientId(errorsByClientId)

    if(Object.keys(errorsByClientId).length !== 0) {
      addNotification({variant: 'alert', message: "Select missing client answer to match to displayed answer."})
    } else {
      setFieldValue(`steps[${stepIndex}].questions[${questionIndex}].fieldMapping`, unlinkedFieldMapping)
    }
  }, [addNotification, displayedOptionIndex, questionIndex, setFieldValue, setLinkErrorsByClientId, stepIndex, unlinkedFieldMapping])

  return (
    <Box>
      <Button
        sx={{ width: '155px', marginBottom: '12px' }}
        onClick={linkQuestionMatches}
        disabled={!fieldMapIsDirty}
        aria-label="Match answers"
      >
        Match answers
      </Button>

      <GrayHeaderBox title="Matched Answers">
        <MatchedAnswersBody
          question={question}
          clientFieldGroups={clientFieldGroups}
          stepIndex={stepIndex}
          questionIndex={questionIndex}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      </GrayHeaderBox>
    </Box>
  )
}

function AnswerMatchingBody({ question,
                              clientFieldGroups,
                              stepIndex,
                              questionIndex,
                              displayedOptionIndex,
                              setDisplayedOptionIndex,
                              setFieldValue,
                              errors}) {
  const [unlinkedFieldMapping, setUnlinkedFieldMapping] = useState([])
  const [linkErrorsByClientId, setLinkErrorsByClientId] = useState({})

  useEffect(() => {
    setUnlinkedFieldMapping(JSON.parse(JSON.stringify(question.fieldMapping)))
  }, [question.fieldMapping])

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={4}>
        <DisplayedAnswers
          question={question}
          displayedOptionIndex={displayedOptionIndex}
          setDisplayedOptionIndex={setDisplayedOptionIndex}
        />
      </Grid>

      <Grid item sm={4}>
        <ClientAnswers
          unlinkedFieldMapping={unlinkedFieldMapping}
          setUnlinkedFieldMapping={setUnlinkedFieldMapping}
          clientFieldGroups={clientFieldGroups}
          displayedOptionIndex={displayedOptionIndex}
          linkErrorsByClientId={linkErrorsByClientId}
        />
      </Grid>

      <Grid item sm={4}>
        <MatchedAnswers
          question={question}
          clientFieldGroups={clientFieldGroups}
          unlinkedFieldMapping={unlinkedFieldMapping}
          displayedOptionIndex={displayedOptionIndex}
          setLinkErrorsByClientId={setLinkErrorsByClientId}
          stepIndex={stepIndex}
          questionIndex={questionIndex}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </Grid>
    </Grid>
  )
}

function AnswerMatchingSubsection({ question,
                                    clientFieldGroups,
                                    stepIndex,
                                    questionIndex,
                                    displayedOptionIndex,
                                    setDisplayedOptionIndex,
                                    errors,
                                    setFieldValue }) {
  const [expanded, setExpanded] = useState(false)

  const handleExpandedClick = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <Box
      role="group"
      aria-label={`Question ${question.position} Answer Matching`}
      sx={{ position: "relative" }}
    >
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
        <SubsectionTitle
          title={`Question ${question.position} Answer Matching`}
          note="For each displayed answer, select all matching client answers to link together."
        />
        <Box
          sx={{
            mr: '4px',
            mt: '2px',
            mb: expanded ? '12px' : 0,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleExpandedClick}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            { expanded ? "Hide" : "Expand" }
          </Typography>
          <IconButton
            sx={{width: '24px', height: '24px', marginRight: '-4px', color: COLORS.charcoal}}
            aria-label="Expand"
          >
            <ArrowDropUpIcon
              sx={{ transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                    marginLeft: 'auto',
                    transition: 'transform .4s',
                  }}
            />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded} sx={{ display: expanded ? 'flex' : 'none' }} >
        <AnswerMatchingBody
          question={question}
          clientFieldGroups={clientFieldGroups}
          stepIndex={stepIndex}
          questionIndex={questionIndex}
          displayedOptionIndex={displayedOptionIndex}
          setDisplayedOptionIndex={setDisplayedOptionIndex}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </Collapse>
    </Box>
  )
}

function propsAreEqual({ question: prevQuestion, errors: prevErrors, clientFieldGroups: prevClientFieldGroups, ...prevProps },
  { question: nextQuestion, errors: nextErrors, clientFieldGroups: nextClientFieldGroups, ...nextProps }) {

  return isEqual(prevQuestion, nextQuestion) &&
        isEqual(prevErrors, nextErrors) &&
        isEqual(prevClientFieldGroups, nextClientFieldGroups) &&
        isShallowEqual(prevProps, nextProps)
}

export default memo(AnswerMatchingSubsection, propsAreEqual)
