import Box from '@mui/material/Box'
import NotificationSnackbar from '../Notification'
import { useNotifications } from '../../../lib/NotificationsProvider'

export default function Notifications({hideDuration = 5000}) {
  const { notifications } = useNotifications()

  return (
    <Box sx={{ top: 0, left: 0, width: '100%', position: 'fixed', zIndex: (theme) => theme.zIndex.modal + 1 }}>
      {notifications.map((notification, index) => {
        return <NotificationSnackbar key={index} index={index} notification={notification} hideDuration={hideDuration} />
      })}
    </Box>
  )
}
