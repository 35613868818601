import { useCallback, useMemo } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { Box, Divider } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import COLORS from '../../../../../../lib/colors'
import QuestionSection from "./QuestionSection"
import { useFormikContext } from "formik"
import { addItemAtIndex, generateNewRecordId, validateOrFixPositions } from "../../../data/utils"
import StyledCard from "components/UI/StyledCard"
import { FormTextField } from "components/Formik/forms"


export default function StepCard({ handleDeleteStep, clientFieldGroups, stepIndex }) {
  const { values, setFieldValue } = useFormikContext()
  const step = useMemo(() => values.steps[stepIndex], [stepIndex, values.steps])

  const handleAddQuestion = useCallback(( stepIndex ) => {
    const newQuestion = {
      id: generateNewRecordId(),
      text: null,
      key: null,
      options: null,
      fieldMapping: []
    }
    const questionsWithNewQuestion = addItemAtIndex(values.steps[stepIndex].questions, newQuestion)

    setFieldValue(`steps[${stepIndex}].questions`, validateOrFixPositions(questionsWithNewQuestion))
  }, [setFieldValue, values.steps])

  return (
    <Droppable droppableId={`step_${stepIndex}`} key={`droppable_step_${stepIndex}`}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <StyledCard
            title={`Step ${step.position}`}
            controls={[
              {icon: <AddIcon/>, onClick: () => {handleAddQuestion(stepIndex)}, label: 'Add'},
              {icon: <DeleteIcon/>, onClick: handleDeleteStep, label: 'Delete'}
            ]}
            isSubsection={true}
            hasStickyHeader={true}
            label={`Step ${step.position}`}
            role="group"
          >
            <FormTextField
              id={`step${step.position}Title`}
              label="Step Title"
              name={`steps[${stepIndex}].title`}
              sx={{margin: '18px 24px', display: 'flex', width: 'unset'}}
            />
            <Divider light sx={{margin: '0 24px'}}/>
            {step.questions && step.questions.map((question, questionIndex) =>
              <Draggable
                draggableId={`draggable_question_${question.id}`}
                key={`draggable_question_${question.id}`}
                index={questionIndex}
              >
                {(provided, snapshot) => (
                  <Box
                    sx={{
                      ...(snapshot.isDragging === true && {
                        backgroundColor: COLORS.backgroundGray
                      })
                    }}
                    {...provided.draggableProps}

                    ref={provided.innerRef}
                  >
                    <QuestionSection
                      key={`questionSection_${question.id}`}
                      clientFieldGroups={clientFieldGroups}
                      stepIndex={stepIndex}
                      questionIndex={questionIndex}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </Box>
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </StyledCard>
        </Box>
      )}
    </Droppable>
  )
}
