import { useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles({
  formControl: {
    minWidth: 250,
    marginBottom: 20,
  },
})

export default function EntitySelector({entitiesResource, entity, setEntity, label, nameAttribute = 'name'}) {
  const classes = useStyles()

  const entities = entitiesResource.get()

  const handleChange = useCallback(event => {
    setEntity(entities.find(item => item.id === event.target.value))
  }, [entities, setEntity])

  return (
    <TextField
      select
      className={classes.formControl}
      label={label}
      value={entity && entity.id || ''}
      onChange={handleChange}
    >
      {entities.map(item => (
        <MenuItem value={item.id} key={item.id}>{item.attributes[nameAttribute]}</MenuItem>
      ))}      
    </TextField>
  )
}
