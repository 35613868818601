export function getErrors( errorBucket ) {
  if(errorBucket === undefined) { return [] }

  if(Array.isArray(errorBucket)) {
    return errorBucket.flatMap( item =>
      getErrors(item)
    )
  } else if(typeof errorBucket === 'string') {
    return errorBucket
  } else {
    return Object.values(errorBucket).flatMap( value => {
      return getErrors(value)
    })
  }
}

export function getUniqueErrors( errorBucket ) {
  return Array.from(new Set(getErrors(errorBucket)))
}

export function buildErrorString(formErrors, persistedErrors) {
  if(formErrors.length > 0) {
    return stringFromArray(formErrors)
  }

  if(persistedErrors.length > 0) {
    return stringFromArray(persistedErrors)
  }

  return ""
}

export function stringFromArray( uniqueArray, maxValues=3 ) {
  if(uniqueArray.length > maxValues) {
    uniqueArray.splice(maxValues, uniqueArray.length - maxValues, '...')
  }
  return uniqueArray.join(", ")
}
