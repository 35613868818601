import { ApolloClient, ApolloProvider as RealApolloProvider, InMemoryCache } from '@apollo/client'

function mergeArrays(existing = [], incoming = [], { readField, mergeObjects }) {
  const merged = [...incoming]
  const existingIds = existing.map((item) => readField('id', item))

  merged.forEach((item, index) => {
    const itemId = readField('id', item)
    const existingIndex = existingIds.findIndex((id) => id === itemId)
    if (existingIndex !== -1) {
      merged[index] = mergeObjects(existing[existingIndex], merged[index])
    }
  })
  return merged
}

const apolloClient = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          degreePrograms: {
            read(existing, { args, readField, toReference }) {
              return existing || readField('degreePrograms', toReference({
                __typename: 'ProgramGroup',
                id: args.programGroupId,
              }))
            },
          },
        },
      },
      Question: {
        fields: {
          fieldMapping: {
            merge: false,
          }
        }
      },
      Step: {
        fields: {
          questions: {
            merge: mergeArrays,
          },
        },
      },
      ClientCampaignRules: {
        fields: {
          clientMapping: {
            merge: false,
          }
        }
      },
      LandingPage: {
        fields: {
          steps: {
            merge: mergeArrays,
          },
          leadTargets: {
            merge: mergeArrays,
          },
          clientCampaignRules: {
            merge: mergeArrays,
          },
        },
      },
    },
  }),
})

export default function ApolloProvider({ children }) {
  return (
    <RealApolloProvider client={apolloClient}>{children}</RealApolloProvider>
  )
}
