import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import COLORS from 'lib/colors'
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControlLabel from "@mui/material/FormControlLabel";

function SubMenu({ anchorEl, children }) {
  return (
    <>
      <ChevronRightIcon sx={{position:'absolute', right:'0'}}/>
      <Menu
        style={{ pointerEvents: 'none' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={{
          "& .MuiDivider-root": {
            borderColor: COLORS.mediumGray,
            marginBottom: 1
          }
        }}>
        <div style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </>
  )
}

export function MenuCheckbox({ label, checked, onChange }) {
  return (
    <FormControlLabel
      componentsProps={{ typography: { sx: { fontSize: '14px' } } }}
      control={<Checkbox size="medium" sx={{ padding: 0, marginRight: 1.5 }} checked={checked} onChange={onChange} />}
      label={label}
      sx={{ py: "8px", px: "16px", height: "100%", width: "100%" }}
    />
  )
}

export function MenuItem({ label, children=null }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const hasSubMenu = !!children
  const labelIsString = typeof label === 'string'

  function handleMouseOn(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleMouseOff() {
    setAnchorEl(null);
  }

  return (
    <>
    <ListItemButton
      aria-label={label}
      onMouseOver={handleMouseOn}
      onMouseLeave={handleMouseOff}
      onMouseDown={event => event.stopPropagation()}
      sx={{ minWidth: 200, ...(!labelIsString && { px: 0, py: 0 }) }}
    >
      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={'100%'} sx={{ ".MuiFormControlLabel-root": {marginX: 0 }}}>
        {label}
        {hasSubMenu &&
          <SubMenu anchorEl={anchorEl} >
            {children}
          </SubMenu>
        }
      </Stack>
    </ListItemButton>
    </>
  )
}

export function MenuCategory({ name, icon=null }) {

  return (
    <>
      <Stack direction="row" alignItems={"self-start"} marginLeft={3} sx={{ ".MuiSvgIcon-root": {
          color: COLORS.slateGray,
          fontSize: "12px",
          marginRight: icon ? "3px" : 0
      }}}>
        {icon}
        <Typography color="info.main" variant="body2" sx={{ fontSize: "10px" }} >{name}</Typography>
      </Stack>
    </>
  )
}

export default function NestingMenu({ icon, children, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handleOpen(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null)
    onClose()
  }

  return (
    <>
      {icon &&
        <IconButton data-testid="filterMenu" onClick={handleOpen}>
          {icon}
        </IconButton>
      }

      {open &&
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            style={{ pointerEvents: 'none' }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{vertical: 'center', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            sx={{
              "& .MuiDivider-root": {
                borderColor: COLORS.mediumGray,
                marginBottom: 1
              }
            }}
          >
            <div style={{ pointerEvents: 'auto' }}>
              {children}
            </div>
          </Menu>
        </ClickAwayListener>
      }
    </>
  )
}
