import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import makeStyles from '@mui/styles/makeStyles';

import { MetricsTable } from 'components/MetricsTable';
import SweetFilter from '../SweetFilter'

import { buildColumnList, fetchJson, createLookup, mapToName, sortByHash } from './utils'
import { useTimezone } from '../../lib/TimezoneProvider'
import DatePickerInput from '../DatePickerInput'
import { deepEqual, downloadData, setStorage, toFormattedNumber } from '../../lib/utils'
import { useDeepEffect } from '../../lib/hooks'
import { camelize, underscore } from '@orbit/serializers'

import DownloadImage from '@mui/icons-material/FileDownloadOutlined';
import useClientMetadata from 'hooks/useClientMetadata'
import Suspender from '../Suspender';
import {ReportingModeSelector} from '../UI/ReportingModeSelector/ReportingModeSelector';
import PerformanceSidebar, { ExportReportDialog } from './PerformanceSidebar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import Box from '@mui/material/Box';

import { isEqual, omit, sortBy, uniq } from 'lodash-es';

import { Notification, useNotifications } from 'lib/NotificationsProvider'
import { StatusPanelWrapper, StatusWithToolTip } from 'components/UI/StatusPanel/StatusPanel';
import COLORS from 'lib/colors';



import Suspenseful from '../Suspenseful'
import PromisedResource from '../../resources/PromisedResource'

import { getRemoteId } from '../../lib/DataModel'
import { titlecase } from '../../lib/utils'

import { userIsGlobalAdmin } from '../../lib/auth-helpers'
import { CAMPAIGN_TYPES_VALUES } from 'components/pages/LandingPages/data/constants.js'
import { gql, useLazyQuery } from '@apollo/client'
import { useFeatures } from 'providers/FeaturesProvider';
import * as React from 'react';
import { GET_MARKETING_PLATFORMS_QUERY, SpendValidityStatus } from './SpendDataValidity';
import { useSuspenseQuery } from '@apollo/client'
import { DataManagerProvider, useDataManager } from 'components/MetricsTable/DataManager';

import { RowFilter, ReportingMode, RawTableSettings, TableSettings } from './TableSettings/types';
import { TableSettingsAdapter } from './TableSettings/TableSettingsAdapter';
import validateTableSettings from './TableSettings/validateTableSettings';
import { useCurrentUser } from 'lib/CurrentUserProvider';
import { useOrbit } from 'providers/OrbitProvider';
import { isSameTimeRange, TimeRange } from 'components/DatePickerPopover';
import { convertToAbsoluteTimeRange } from '../DatePickerPopover';
import Typography from '@mui/material/Typography';
import SourceNotesIcon from 'components/icons/SourceNotesIcon';
import { SavedReport } from 'generated/graphql';
import { deserializeSavedReportConfig, deserializeTimeRange } from './serialization';
import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'


const DEFAULT_MAX_ROWS = 10_000

class LookupsResource extends PromisedResource {
  static getPromise({ remote, store, clientsResource, currentUser, baseURL, userHasMultipleVendors, userHasMultipleClients }) {
    const isGlobalAdmin = userIsGlobalAdmin({currentUser})

    const promises = [
      (userHasMultipleClients || isGlobalAdmin) ? clientsResource.promise : [],
      isGlobalAdmin ? remote.query(q => q.findRecords('landingPage')) : [],
      remote.query(q => q.findRecords('campus')),
     (userHasMultipleVendors || isGlobalAdmin) ? remote.query(q => q.findRecords('vendor')) :[],
      remote.query(q => q.findRecords('programGroup')),
      remote.query(q => q.findRecords('degreeProgram'), {
        sources: {
          remote: {
            settings: {
              params: {
                fields: {
                  'degree-program': ['name', 'client', 'program_group'].join(',')
                }
              }
            }
          }
        }
      }),
      remote.query(q => q.findRecords('clientCampaign')),
      fetchJson('GET', `${baseURL}/sources`)
    ]

    return Promise.all(promises).then(([clients, landingPages, campuses, vendors, programGroups, degreePrograms, clientCampaigns, sources]) => ({
      clientId:            createLookup(clients, client => client.internalName || client.name),
      primaryClientId:     createLookup(clients, client => client.internalName || client.name),
      campusId:            mapToName(campuses),
      vendorId:            mapToName(vendors),
      programGroupId:      mapToName(programGroups),
      degreeProgramId:     mapToName(degreePrograms),
      clientCampaignId:    mapToName(clientCampaigns),
      landingPagePublicId: mapToName(landingPages),
      templateId:          mapToName(store.cache.query(q => q.findRecords('template')), { transform: titlecase }),
      _source:             Object.fromEntries(sources.map(source => [source, source])),
    }))
  }

  constructor({ remote, store, clientsResource, currentUser, baseURL, userHasMultipleVendors, userHasMultipleClients }) {
    super(LookupsResource.getPromise({ remote, store, clientsResource, currentUser, baseURL, userHasMultipleVendors, userHasMultipleClients }))
  }
}

export const GET_CLIENTS_QUERY = gql`
  query GetClients {
    clients(includeFilteredLeadsClient: true) {
      id
      internalName
      name
      showDegreeProgramChanges
      fields {
        fieldType
        name
        groupable
        showChanges
      }
    }
  }
`

const DRAWER_WIDTH = 240

const useStyles = makeStyles(theme => ({
  tableSettings: {
    width: DRAWER_WIDTH,
    padding: theme.spacing(0, 2),
    paddingTop: 0,
    '& h1': {
      fontSize: 24,
      marginBottom: 0,
      fontWeight: 400,
    },
    '& h2': {
      marginBottom: 0,
      fontSize: 18,
    },
  },
  table: {
    flex: 1,
    minWidth: 0,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    '& td:not(:first-of-type), & th:not(:first-of-type)': {
      borderLeft: '1px solid #e0e0e0',
    },
    '& td:not(:last-of-type), & th:not(:last-of-type)': {
      borderRight: '1px solid #e0e0e0',
    },
    '& tbody tr': {
      transition: 'background-color 0s !important',
    },
    '& td, & th': {
      padding: '6px 13px',
    },
  },
}))

type AggregateFunction = 'sum'
type AggregateFilter = 'enrolled'

interface Aggregate {
  name: string
  field: string
  function: AggregateFunction
  filter?: AggregateFilter
  type?: 'bigint' | 'float'
}

interface PerformanceAPIRequest {
  group_by: string[]
  filter?: Record<string,string>
  start_time: string
  end_time: string
  timezone: string
  reporting_mode: ReportingMode
  limit?: number
  aggregates?: Aggregate[]
}

interface FetchDataProps {
  url: string
  groupBy: string[]
  filters: RowFilter[]
  timeRange: TimeRange
  timezone: string
  addNotification: (notification: Notification) => void
  reportingMode: ReportingMode
  maxRows?: number
  aggregates?: Aggregate[]
}

// This handles underscoring only the first element of a column reference, to allow JSONB column name
// to remain unchanged (an example of the desired output is `custom_fields:GraduationYear`)
function underscoreTopLevel(columnReference : string) {
  const references = columnReference.split(':')
  return references.map((reference, index) => index === 0 ? underscore(reference) : reference).join(':')
}

export function fetchData({
  url,
  groupBy,
  filters,
  timeRange,
  timezone,
  addNotification,
  reportingMode = 'cohort',
  maxRows,
  aggregates,
}: FetchDataProps) {
  const queryParams : PerformanceAPIRequest = {
    group_by: groupBy.map(c => underscoreTopLevel(c)),
    filter: Object.fromEntries(filters.map((filter: RowFilter) => [underscore(filter.field), filter.values.join(',')])),
    start_time: timeRange ? timeRange[0].format() : '',
    end_time: timeRange ? timeRange[1].format() : '',
    timezone: timezone,
    reporting_mode: reportingMode,
    aggregates: aggregates,
  }

  if(maxRows) {
    queryParams.limit = maxRows + 1
  }

  return fetchJson('POST', `${url}`, queryParams)
    .then(response => ({
      data: response.data.map(row => Object.fromEntries(Object.entries(row).map(([key, value]) => [camelize(key), value])))
    }))
    .catch(_error => {
      addNotification({variant: 'alert', message: 'Failed to fetch data'})
    })
}

export function currentSettingsMatchSelectedReport(selectedReport : SavedReport | null, tableSettings : TableSettings, timeRange : TimeRange) {
  if(!selectedReport) {
    return true
  }

  return isSameTimeRange(timeRange, deserializeTimeRange(selectedReport.reportConfig.timeRange)) &&
    isEqual(omit(tableSettings, ['savedReportId', 'savedReportName']), deserializeSavedReportConfig(selectedReport.reportConfig))
}

function MaxRowsStatus({maxRowsExceeded, maxRows}) {
  if(maxRowsExceeded) {
    const status = {
      variant: "warning",
      tipTitle: <Box><Box>Column sorting and filtering</Box><Box>not applied to full data set.</Box></Box>,
      statusText: `Data truncated to ${toFormattedNumber(maxRows)} lines`
    }
    return <StatusWithToolTip status={status} />
  } else {
    return null
  }
}

function SpendValidityStatusComponent({refetchMarketingPlatforms, dateRange, setDateRange, visibleColumnFieldNames, selectedPlatformValidity, setSelectedPlatformValidity}) {
  const { data: { marketingPlatforms } } = useSuspenseQuery(GET_MARKETING_PLATFORMS_QUERY, { queryKey: refetchMarketingPlatforms, fetchPolicy: 'network-only' })
  const filteredMarketingPlatforms = marketingPlatforms.filter(d => d.validFrom && d.lastSyncedAt)
  const spendColumnFieldNames = ['impressions','clicks','cpm', 'cpc', 'spend','profit','roas']
  const hasVisibleSpendColumns = visibleColumnFieldNames.some(field => spendColumnFieldNames.includes(field))
  if(hasVisibleSpendColumns && filteredMarketingPlatforms.length > 0) {
    return <SpendValidityStatus dateRange={dateRange} setDateRange={setDateRange} marketingPlatforms={filteredMarketingPlatforms} selectedPlatformValidity={selectedPlatformValidity} setSelectedPlatformValidity={setSelectedPlatformValidity} />
  } else {
    return null
  }
}

function StatusPanelStack({refetchMarketingPlatforms, maxRowsExceeded, maxRows, dateRange, setDateRange, visibleColumnFieldNames, isGlobalAdmin, selectedPlatformValidity, setSelectedPlatformValidity}) {
  const SpendValidityStatus = isGlobalAdmin ? <Suspenseful refetchMarketingPlatforms={refetchMarketingPlatforms} component={SpendValidityStatusComponent} dateRange={dateRange} setDateRange={setDateRange} visibleColumnFieldNames={visibleColumnFieldNames} selectedPlatformValidity={selectedPlatformValidity} setSelectedPlatformValidity={setSelectedPlatformValidity} /> : null

  return (
    <Stack
      direction="row"
      justifyContent="end"
      mt='-60px'
      mr='5px'
      minHeight='60px'
      zIndex='111'
    >
      <StatusPanelWrapper>
        <MaxRowsStatus maxRowsExceeded={maxRowsExceeded} maxRows={maxRows} />
        {SpendValidityStatus}
      </StatusPanelWrapper>
    </Stack>
  )
}

// TODO: Don't hard-code this, but make the aggregates configurable
const AGGREGATES_FOR_291 : Aggregate[] = [
  {
    name:     'customMetrics:bookings',
    field:    'totalContractPrice',
    function: 'sum',
    filter:   'enrolled',
    type:     'float',
  },
  {
    name:     'customMetrics:downPayment',
    field:    'downPayment',
    function: 'sum',
    type:     'float',
  },
]

function getAggregatesFor(clientIds) {
  if(clientIds?.includes('291')) {
    return AGGREGATES_FOR_291
  }
}

function TableControlRow({filterFieldsOrFn, lookups, dateRange, setDateRange, setSelectedPlatformValidity, clientId, isGlobalAdmin, setDataPromise, fetchDataFn, url, maxRows, refetchMarketingPlatforms, setRefetchMarketingPlatforms}) {
  const dataManager = useDataManager()
  const { addNotification } = useNotifications()
  const { timezone } = useTimezone()

  const filterFields = useMemo(() => {
    const filters = dataManager.tableSettings.rowFilters
    return typeof(filterFieldsOrFn) === 'function' ? filterFieldsOrFn({lookups, filters}) : filterFieldsOrFn
  }, [filterFieldsOrFn, lookups, dataManager.tableSettings.rowFilters])

  const setRowFilters = useCallback((newFilters: RowFilter[]) => {
    if(deepEqual(dataManager.tableSettings.rowFilters, newFilters)) {
      return
    }

    dataManager.tableSettings.rowFilters = newFilters
    dataManager.setTableSettings({...dataManager.tableSettings})
  }, [dataManager])

  const handleChangeReportingMode = useCallback((reportingMode : ReportingMode) => {
    dataManager.tableSettings.reportingMode = reportingMode
    dataManager.setTableSettings({...dataManager.tableSettings})
  }, [dataManager])

  // QUESTION: Why a useEffect here, why not set this in `handleChangeReportingMode` directly?
  useEffect(() => {
    setStorage('PerformanceTable.reportingMode', dataManager.tableSettings.reportingMode)
  }, [dataManager.tableSettings.reportingMode])

  const copyURL = () => {
    navigator.clipboard.writeText(location.href)
    addNotification({variant: 'notice', message: "Link for current table settings has been copied."})
  }

  const groupingColumns = dataManager.columns.filter(column => column.groupable && dataManager.tableSettings.visibleColumns.includes(column.field)).map(column => column.field)
  const absoluteTimeRange = convertToAbsoluteTimeRange(dateRange, timezone)

  const refreshData = () => {
    const clientIds = clientId ? [clientId] : isGlobalAdmin ? dataManager.tableSettings.rowFilters.find(filter => filter.field === 'client-id')?.values : null
    const aggregates = getAggregatesFor(clientIds)

    setDataPromise(_oldValue => () => fetchDataFn({
      url,
      groupBy: groupingColumns,
      filters: dataManager.tableSettings.rowFilters,
      timeRange: absoluteTimeRange,
      timezone,
      addNotification,
      reportingMode: dataManager.tableSettings.reportingMode,
      maxRows: maxRows,
      aggregates,
    }))

    setRefetchMarketingPlatforms(refetchMarketingPlatforms + 1)
  }

  useDeepEffect(refreshData, [url, clientId, groupingColumns, dataManager.tableSettings.rowFilters, absoluteTimeRange, timezone, dataManager.tableSettings.reportingMode])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      flexWrap='wrap'
      position="sticky"
    >
      <SweetFilter
        fields={filterFields}
        filters={dataManager.tableSettings.rowFilters}
        setFilters={setRowFilters}
      />
      <ReportingModeSelector
        sx={{ mt: 2 }}
        value={dataManager.tableSettings.reportingMode}
        setValue={handleChangeReportingMode}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        flexWrap='wrap'
        position="sticky" sx={{ svg: { color: COLORS.frenchBlue }}}
      >
        <DatePickerInput
          value={dateRange}
          onChange={(dateRange) => {setDateRange(dateRange); setSelectedPlatformValidity(null)}}
          timeInputsEnabled
        />
        <IconButton aria-label="Copy URL" onClick={copyURL} sx={{ marginLeft: '0 !important' }} >
          <IosShareOutlinedIcon />
        </IconButton>
        <IconButton aria-label="Refresh" onClick={refreshData} sx={{ marginLeft: '0 !important' }} >
          <SyncIcon style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Stack>
    </Stack>
  )
}

function PerformanceTableContent({clients, lookups, filterFieldsOrFn, url, dateRange, setDateRange, fetchDataFn, maxRows, clientMetadata, clientId, rawTableSettings, setTableSettings}) {
  const classes = useStyles()
  const { currentUser, accessibleClients, accessibleClientContracts, accessibleVendorContracts, accessibleVendors } = useCurrentUser()
  const isGlobalAdmin = userIsGlobalAdmin({currentUser})
  const { isFeatureFlagEnabled } = useFeatures()
  const hasMediaColumns = isFeatureFlagEnabled('performance_table_media_columns')
  const userHasMultipleClients = getAllAccessibleClientIds(accessibleClients, accessibleVendorContracts).length > 1
  const userHasMultipleVendors = getAllAccessibleVendorIds(accessibleVendors, accessibleClientContracts).length > 1
  const [maxRowsExceeded, setMaxRowsExceeded] = useState(false)
  const [selectedPlatformValidity, setSelectedPlatformValidity] = useState(null)
  const [refetchMarketingPlatforms, setRefetchMarketingPlatforms] = useState(0)
  const [dataPromise, setDataPromise] = useState<[] | (() => Promise<unknown>)>([])
  const [selectedReport, setSelectedReport] = useState<SavedReport | null>(null)
  const dataManagerRef = useRef(null)
  const [exportOpen, setExportOpen] = useState(false)

  const vendorIds = accessibleVendors.map(vendor => getRemoteId(vendor))
  const allColumns = useMemo(() => buildColumnList(
    {
      currentUser,
      clientMetadata,
      lookups,
      clients,
      clientId,
      hasMediaColumns,
      filters: rawTableSettings.rowFilters,
      userHasMultipleClients,
      userHasMultipleVendors,
      accessibleClients,
    }), [clientId, clientMetadata, clients, currentUser, hasMediaColumns, lookups, rawTableSettings.rowFilters, userHasMultipleClients, userHasMultipleVendors, accessibleClients])
  const sortedColumns = sortBy(allColumns, column => column.groupable ? 0 : 1)

  const validatedTableSettings = validateTableSettings(rawTableSettings, allColumns, isGlobalAdmin, userHasMultipleClients, vendorIds)

  useEffect(() => {
    if(validatedTableSettings !== rawTableSettings) {
      setTableSettings(validatedTableSettings)
    }
  }, [setTableSettings, validatedTableSettings, rawTableSettings])

  const handleExport = useCallback((fileName) => {
    dataManagerRef.current && downloadData(dataManagerRef.current.getCsvData(), ((fileName && `${fileName}.csv`) || 'export.csv'), 'text/csv')
  }, [dataManagerRef])

  const actions = useMemo(() => [
    {
      icon: <DownloadImage/>,
      tooltip: 'Export to CSV',
      onClick: () => setExportOpen(true),
    },
  ], [])

  const handleOnChange = useCallback(() => {
    if(dataManagerRef.current){
      setMaxRowsExceeded(dataManagerRef.current.totalCount > maxRows)
    }
  }, [maxRows])

  const savedReportHasChanges = !currentSettingsMatchSelectedReport(selectedReport, validatedTableSettings, dateRange)

  const revertToSelectedSavedReport = useCallback(() => {
    if(selectedReport) {
      setDateRange(deserializeTimeRange(selectedReport.reportConfig.timeRange))
      setTableSettings({
        ...deserializeSavedReportConfig(selectedReport.reportConfig),
        savedReportName: selectedReport.name,
        savedReportId: selectedReport.id
      })
    }
  }, [selectedReport, setDateRange, setTableSettings])

  const unselectCurrentSavedReport = useCallback(() => {
    setSelectedReport(null)
    setTableSettings(omit(validatedTableSettings, ['savedReportId', 'savedReportName']))
  }, [setTableSettings, validatedTableSettings])

  return (
    <DataManagerProvider columns={sortedColumns} tableSettings={validatedTableSettings} setTableSettings={setTableSettings} data={dataPromise} dataManagerRef={dataManagerRef} onChange={handleOnChange}>
      <Box sx={{ display: "flex", minHeight: 0 }}>
        <div className={classes.table}>
          <StatusPanelStack
            maxRowsExceeded={maxRowsExceeded}
            maxRows={maxRows}
            dateRange={dateRange}
            setDateRange={setDateRange}
            visibleColumnFieldNames={validatedTableSettings.visibleColumns}
            isGlobalAdmin={isGlobalAdmin}
            selectedPlatformValidity={selectedPlatformValidity}
            setSelectedPlatformValidity={setSelectedPlatformValidity}
            refetchMarketingPlatforms={refetchMarketingPlatforms}
          />
          {(selectedReport?.name || validatedTableSettings.savedReportName) &&
            <Stack direction='row' alignItems="center">
              <Typography variant='h2' sx={{ my: 2 }}>{validatedTableSettings.savedReportName || selectedReport?.name}</Typography>
              {savedReportHasChanges &&
                <Tooltip title="Reset to original table settings">
                  <IconButton aria-label="Reset to original table settings" onClick={revertToSelectedSavedReport} sx={{ ml: 1, height: 30, width: 30 }}>
                    <SourceNotesIcon />
                  </IconButton>
                </Tooltip>
              }
              {selectedReport &&
                <Tooltip title="Unselect current saved report">
                  <IconButton aria-label="Unselect current saved report" onClick={unselectCurrentSavedReport} sx={{ ml: 1, height: 30, width: 30, color: COLORS.slateGray }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              }
            </Stack>
          }
          <TableControlRow
            filterFieldsOrFn={filterFieldsOrFn}
            lookups={lookups}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSelectedPlatformValidity={setSelectedPlatformValidity}
            clientId={clientId}
            isGlobalAdmin={isGlobalAdmin}
            setDataPromise={setDataPromise}
            fetchDataFn={fetchDataFn}
            url={url}
            maxRows={maxRows}
            refetchMarketingPlatforms={refetchMarketingPlatforms}
            setRefetchMarketingPlatforms={setRefetchMarketingPlatforms}
          />
          <MetricsTable
            sx={{ minHeight: 0, flex: 1 }}
            actions={actions}
            options={{
              hover: true,
              summaryRow: true,
              summaryCollapse: true,
            }}
          />
        </div>
        <PerformanceSidebar selectedReport={selectedReport} setSelectedReport={setSelectedReport} tableSettings={validatedTableSettings} setTableSettings={setTableSettings} dateRange={dateRange} setDateRange={setDateRange} setExportOpen={setExportOpen} revertToSelectedSavedReport={revertToSelectedSavedReport} />
      </Box>
      {exportOpen &&
        <ExportReportDialog onClose={() => setExportOpen(false)} selectedReport={selectedReport} tableSettings={validatedTableSettings} handleExport={handleExport}/>
      }
    </DataManagerProvider>
  )
}

export function PerformanceTable({lookupsResource, clientsResource, filterFields: filterFieldsOrFn, url, dateRange, setDateRange, maxRows, fetchData: fetchDataFn = fetchData}) {
  const { accessibleClients, accessibleVendorContracts } = useCurrentUser()
  const [rawTableSettings, setTableSettings]: [RawTableSettings, React.Dispatch<React.SetStateAction<RawTableSettings>>] = useQueryParam('tableSettings', TableSettingsAdapter)
  const allAccessibleClientIds = getAllAccessibleClientIds(accessibleClients, accessibleVendorContracts)
  const userHasMultipleClients = allAccessibleClientIds.length > 1
  const clientId = useMemo(() => {
    const filterClientIds = rawTableSettings?.rowFilters?.find(filter => filter.field === 'client-id')?.values || []
    return !userHasMultipleClients ? allAccessibleClientIds[0] : filterClientIds.length == 1 ? filterClientIds[0] : null
  }, [allAccessibleClientIds, rawTableSettings?.rowFilters, userHasMultipleClients])

  const clientMetadata = useClientMetadata(clientId)
  const lookups = lookupsResource.get()

  const clients = useMemo(() => clientsResource?.get() || [], [clientsResource])

  return (
    <>
      {clientMetadata.query.loading && (
        <Suspender/>
      ) || (
        <PerformanceTableContent
          lookups={lookups}
          filterFieldsOrFn={filterFieldsOrFn}
          url={url}
          fetchDataFn={fetchDataFn}
          dateRange={dateRange}
          setDateRange={setDateRange}
          clients={clients}
          maxRows={maxRows}
          clientMetadata={clientMetadata}
          clientId={clientId}
          rawTableSettings={rawTableSettings}
          setTableSettings={setTableSettings}
        />
      )}
    </>
  )
}

export function getAllAccessibleClientIds(accessibleClients, accessibleVendorContracts) {
  const clientIds = accessibleClients.map(client => getRemoteId(client))
  const vendorContractClientIds = accessibleVendorContracts.map(contract => getRemoteId(contract.relationships.client.data))
  return uniq([...clientIds, ...vendorContractClientIds])
}

export function getAllAccessibleVendorIds(accessibleVendors, accessibleClientContracts) {
  const vendorIds = accessibleVendors.map(vendor => getRemoteId(vendor))
  const clientContractVendorIds = accessibleClientContracts.map(contract => getRemoteId(contract.relationships.vendor.data))
  return uniq([...vendorIds, ...clientContractVendorIds])
}

export default function PerformanceTableWrapper({dateRange, setDateRange, maxRows = DEFAULT_MAX_ROWS}) {
  const { remote, store } = useOrbit()
  const { currentUser, accessibleClients, accessibleClientContracts, accessibleVendors, accessibleVendorContracts } = useCurrentUser()
  const isGlobalAdmin = userIsGlobalAdmin({currentUser})
  const userHasMultipleClients = getAllAccessibleClientIds(accessibleClients, accessibleVendorContracts).length > 1
  const userHasMultipleVendors = getAllAccessibleVendorIds(accessibleVendors, accessibleClientContracts).length > 1

  const baseURL = useMemo(() => { return remote.requestProcessor.urlBuilder.resourceHost() }, [remote])

  const [getClients] = useLazyQuery(GET_CLIENTS_QUERY)
  const clientsResource = useMemo(() => new PromisedResource(getClients().then(result => result.data.clients)), [getClients])
  const lookupsResource = useMemo(() => new LookupsResource({ remote, store, clientsResource, currentUser, baseURL, userHasMultipleVendors, userHasMultipleClients }), [remote, store, clientsResource, currentUser, baseURL, userHasMultipleVendors, userHasMultipleClients])

  const getFilterFields = useCallback(({lookups, filters}) => {
    const selectedClientIds = ((filters.find(filter => filter.field === 'client-id') || {}).values || [])
    const selectedProgramGroupIds = ((filters.find(filter => filter.field === 'program-group-id') || {}).values || [])
    let degreeProgramIds = Object.keys(lookups['degreeProgramId'])
    if(selectedClientIds.length > 0) {
      degreeProgramIds = selectedClientIds.map(clientId =>
        store.cache.query(q => q.findRelatedRecords({type: 'client', id: clientId}, 'degreePrograms')).map(degreeProgram => degreeProgram.id)
      ).flat()
    }
    if(selectedProgramGroupIds.length > 0) {
      degreeProgramIds = selectedProgramGroupIds.map(programGroupId =>
        store.cache.query(q => q.findRelatedRecords({type: 'programGroup', id: programGroupId}, 'degreePrograms')).map(degreeProgram => degreeProgram.id)
      ).flat()
    }

    const clientFilterFields = [
      { name: 'client-id',              displayName: 'Client',                values: sortByHash(lookups['clientId']),                          render: id => lookups['clientId'][id] },
      { name: 'primary-client-id',      displayName: 'Landing Page Client',   values: sortByHash(lookups['primaryClientId']),                   render: id => lookups['primaryClientId'][id] },
    ]
    const vendorFilterFields = [
      { name: 'vendor-id',              displayName: 'Vendor',                values: sortByHash(lookups['vendorId']),                          render: id => lookups['vendorId'][id] },
    ]
    const filterFields = [
      { name: 'campus-id',              displayName: 'Campus',                values: sortByHash(lookups['campusId']),                          render: id => lookups['campusId'][id] },
      { name: 'program-group-id',       displayName: 'Program Group',         values: sortByHash(lookups['programGroupId']),                    render: id => lookups['programGroupId'][id] },
      { name: 'degree-program-id',      displayName: 'Program',               values: sortByHash(lookups['degreeProgramId'], degreeProgramIds), render: id => lookups['degreeProgramId'][id] },
      { name: 'client-campaign-id',     displayName: 'Campaign',              values: sortByHash(lookups['clientCampaignId']),                  render: id => lookups['clientCampaignId'][id] },
      { name: 'template-id',            displayName: 'Landing Page Template', values: sortByHash(lookups['templateId']),                        render: id => lookups['templateId'][id] },
      { name: 'landing-page-public-id', displayName: 'Landing Page',          values: sortByHash(lookups['landingPagePublicId']),               render: id => lookups['landingPagePublicId'][id] },
      { name: 'source',                 displayName: 'Source',                values: sortByHash(lookups['_source']) },
      { name: 'campaign-type',          displayName: 'Campaign Type',         values: CAMPAIGN_TYPES_VALUES,                                    render: campaignType => titlecase(campaignType) },
      { name: 'subid',                  displayName: 'SubID',                 values: null },
    ]

    const accessibleFilterFields = userHasMultipleClients || isGlobalAdmin ? [...clientFilterFields, ...filterFields] : filterFields
    return userHasMultipleVendors || isGlobalAdmin ? [...accessibleFilterFields, ...vendorFilterFields] : accessibleFilterFields
  }, [store, isGlobalAdmin, userHasMultipleClients, userHasMultipleVendors])

  return (
    <Suspenseful
      component={PerformanceTable}
      lookupsResource={lookupsResource}
      clientsResource={clientsResource}
      filterFields={getFilterFields}
      url={`${baseURL}/performance`}
      dateRange={dateRange}
      setDateRange={setDateRange}
      maxRows={maxRows}
    />
  )
}
