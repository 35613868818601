import PlainDate from './PlainDate';
import moment, { Moment } from "moment-timezone";
import { TimeRange } from 'components/DatePickerPopover';

export function pastDays(days, timezone) {
  return [
    moment.tz(timezone).subtract(days - 1, 'days').startOf('day'),
    moment.tz(timezone).endOf('day'),
  ]
}

export function dateRangeToTimeRange(dateRange : [Moment, Moment] | null, timezone : string) : TimeRange {
  if(!dateRange || dateRange.length !== 2 || (dateRange as [unknown, unknown]).includes(undefined)) {
    return { label: 'Lifetime', timeRange: null }
  }

  if(!timezone) {
    console.warn("No timezone provided; defaulting to America/New_York")
    timezone = 'America/New_York'
  }

  const startTime = moment.tz(dateRange[0].toString(), 'YYYY-MM-DD', timezone).startOf('day')
  const endTime = moment.tz(dateRange[1].toString(), 'YYYY-MM-DD', timezone).endOf('day')
  return { label: null, timeRange: [startTime, endTime] }
}

export function timeRangeToDateRange(timeRange) {
  if(!timeRange || timeRange.length !== 2 || timeRange.includes(undefined)) {
    return null
  }

  const startDate = new PlainDate(timeRange[0].format('YYYY-MM-DD'))
  const endDate = new PlainDate(timeRange[1].format('YYYY-MM-DD'))
  return [startDate, endDate]
}

export function formatDateRange(dateRange, formatString = 'MMM D') {
  if(!dateRange || dateRange.length !== 2) {
    return 'Lifetime'
  }

  return dateRange.map(date => moment(date, 'YYYY-MM-DD').format(formatString)).join(' - ')
}

export function isEndOfDay(time: Moment) : boolean {
  return moment(time).endOf('day').isSame(time, 'second')
}

export function formatDate(time: Moment, {dateFormat = "M/D/YY", useReferentialNames = false} = {}) : string {
  const timezone = time.tz()
  const today = moment.tz(timezone)
  const yesterday = moment.tz(timezone).subtract(1, 'days');
  if (useReferentialNames) {
    if (today.isSame(time, 'date')) {
      return 'Today'
    } else if (yesterday.isSame(time, 'date')) {
      return 'Yesterday'
    }
  }

  return time.format(dateFormat)
}

export function formatTime(time: Moment, startOrEnd: 'start' | 'end', formatString = 'hA') : string {
  if((startOrEnd === 'start' && time.isSame(time.clone().startOf('day'))) ||
     (startOrEnd === 'end' && time.isSame(time.clone().endOf('day')))) {
    return ''
  } else {
    return time.format(formatString)
  }
}

export function getTimeAndDate(time: Moment, startOrEnd: 'start' | 'end', {timeFormat = 'hA', dateFormat = "M/D/YY", useReferentialNames = false} = {}) : string {
  return [formatDate(time, {dateFormat, useReferentialNames}), formatTime(time, startOrEnd, timeFormat)].filter(Boolean).join(', ')
}

export class TimeRangeBuilder {
  constructor({timezone}) {
    this.timezone = timezone
  }

  today() {
    return this.toTimeRange(this.now(), this.now())
  }

  yesterday() {
    return this.toTimeRange(this.now().subtract(1, 'days'), this.now().subtract(1, 'days'))
  }

  weekToDate() {
    return this.toTimeRange(this.now().startOf('week'), this.now())
  }

  lastWeek() {
    return this.toTimeRange(this.now().subtract(1, 'week').startOf('week'), this.now().subtract(1, 'week').endOf('week'))
  }

  monthToDate() {
    return this.toTimeRange(this.now().startOf('month'), this.now())
  }

  lastMonth() {
    return this.toTimeRange(this.now().subtract(1, 'month').startOf('month'), this.now().subtract(1, 'month').endOf('month'))
  }

  quarterToDate() {
    return this.toTimeRange(this.now().startOf('quarter'), this.now())
  }

  lastQuarter() {
    return this.toTimeRange(this.now().subtract(1, 'quarter').startOf('quarter'), this.now().subtract(1, 'quarter').endOf('quarter'))
  }

  yearToDate() {
    return this.toTimeRange(this.now().startOf('year'), this.now())
  }

  lastYear() {
    return this.toTimeRange(this.now().subtract(1, 'year').startOf('year'), this.now().subtract(1, 'year').endOf('year'))

  }

  toTimeRange(start, end) : [Moment, Moment] {
    return [start.startOf('day'), end.endOf('day')]
  }

  now() {
    return moment.tz(this.timezone)
  }
}
