import { FormikErrors } from "formik"
import { createContext, useState, useContext, MouseEvent, Context } from "react"
import type { ButtonProps } from "@mui/material"
import type { SxProps } from "@mui/system"

interface BaseButtonConfig {
  disabled?: boolean
  sx?: SxProps
  buttonProps?: Omit<ButtonProps, "type" | "label" | "sx" | "disabled" | "onClick">
}

interface ButtonActionProps {
  label: string
  onClick: (e: MouseEvent) => void
}

type PrimaryButtonConfig = BaseButtonConfig & ButtonActionProps & {
  type: "primary"
}

type SecondaryButtonConfig = BaseButtonConfig & ButtonActionProps & {
  type: "secondary"
}

interface MenuButtonItem {
  text: string
  onClick: (e: MouseEvent) => void
  disabled?: boolean
}

type MenuButtonConfig = BaseButtonConfig & {
  type: "menu"
  label: string
  items: MenuButtonItem[]
}

export type ButtonConfig = PrimaryButtonConfig | SecondaryButtonConfig | MenuButtonConfig

interface FooterContext {
  buttons: ButtonConfig[]
}

type DefaultStateType = {
  form?: {
    dirty: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: FormikErrors<any>
  }
}

export interface Bookends<StateType> {
  footer: FooterContext
  state?: StateType
}

interface BookendsContext<StateType> {
  bookends: Bookends<StateType>
  setBookends: (newValue: Bookends<StateType> | ((currentValue: Bookends<StateType>) => Bookends<StateType>)) => void
}

const bookendsContext = createContext<BookendsContext<DefaultStateType>>({ bookends: { footer: { buttons: [] } }, setBookends: () => { return } })

export default function BookendsProvider({ children }) {
  const [bookends, setBookends] = useState(() => {
    const defaultValue : Bookends<DefaultStateType> = {
      footer: {
        buttons: []
      }
    }
    return defaultValue
  })

  return (
    <bookendsContext.Provider value={{ bookends, setBookends }}>
      {children}
    </bookendsContext.Provider>
  )
}

export function useBookends<StateType = DefaultStateType>() {
  return useContext<BookendsContext<StateType>>(bookendsContext as unknown as Context<(BookendsContext<StateType>)>)
}
