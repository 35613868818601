import { useCallback, useState } from 'react'
import MultiGroupDropdown from '.'
import { MultiGroupRadioButton, InputGroupSection } from "components/UI/MultiGroupDropdown"

export default {
  title: "UI/MultiGroupDropdown",
  component: MultiGroupDropdown,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4095d86838c1093a6aeb9",
  },
  argTypes: {
    selectFirstByDefault: {
      control: false,
    },
    loading: {
      control: false,
    },
    disabled: {
      control: false,
    },
  },
};

const cartoonA = {
  id: 1,
  name: "Looney Tunes",
  fields: [
    { name: "Daffy Duck" },
    { name: "Tweety Bird" },
    { name: "Road Runner" },
    { name: "Wile E. Coyote" },
    { name: "Bugs Bunny" },
  ],
};

const cartoonB = {
  id: 2,
  name: "Dr. Seuss",
  fields: [
    { name: "Horton" },
    { name: "The Grinch" },
    { name: "Dave" },
    { name: "Wild Green Pants" },
    { name: "Mayzie" },
  ],
};

const DropdownContents = function ({
  group,
  groupIndex,
  selectedValues,
  setSelectedValues,
  fieldGroups,
  passedArgs,
  setPassedArgs,
}) {
  const handleChange = useCallback(
    (event) => {
      selectedValues[groupIndex] = event.target.value;
      setSelectedValues([...selectedValues]);

      setPassedArgs({ ...passedArgs, error: false });
    },
    [groupIndex, passedArgs, selectedValues, setPassedArgs, setSelectedValues],
  );

  return (
    <InputGroupSection
      title={group.name}
      key={groupIndex}
      role='radiogroup'
    >
      {group.fields.map((field, index) => (
        <MultiGroupRadioButton
          key={`multi_group_radio_${group.id}_${field.name}`}
          value={field.name}
          checked={
            selectedValues[groupIndex] ===
            fieldGroups[groupIndex].fields[index].name
          }
          label={field.name}
          onChange={handleChange}
        />
      ))}
    </InputGroupSection>
  )
}

const Template = (args) => {
  const [selectedValues, setSelectedValues] = useState([
    "Road Runner",
    "Horton",
  ]);
  const fieldGroups = [cartoonA, cartoonB];
  const renderSelectedValues = useCallback(
    (value) => Array.from(new Set(value)).join(", "),
    [],
  );
  const [passedArgs, setPassedArgs] = useState(args);

  return (
    <div style={{ display: "flex" }}>
      <MultiGroupDropdown
        value={selectedValues}
        renderValue={renderSelectedValues}
        {...passedArgs}
      >
        {fieldGroups.map((group, groupIndex) => (
          <DropdownContents
            group={group}
            groupIndex={groupIndex}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            fieldGroups={fieldGroups}
            key={groupIndex}
            passedArgs={passedArgs}
            setPassedArgs={setPassedArgs}
          />
        ))}
      </MultiGroupDropdown>
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {
  inputLabel: "Favorite Cartoon Characters",
  sx: { m: "1", width: "300px" },
};

export const Error = Template.bind({});
Error.args = {
  ...Default.args,
  error: true,
};
