import SvgIcon from "@mui/material/SvgIcon"

export default function DoesNotEqualIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path d="M16.646 9.935v1.29H7.353v-1.29h9.293zm0 2.848v1.289H7.353v-1.29h9.293z" fill="currentColor" fillRule="nonzero"/>
              <path fill="currentColor" d="M15.25 6.347h1.35L8.6 17.5H7.25z"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
