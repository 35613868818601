import { getRemoteId } from '../../lib/DataModel'
import { bareInfinitive, failOnHttpError, isPresent, isTruthy, stripHTML, toCamelCase, toFormattedNumber } from '../../lib/utils'
import moment from 'moment-timezone'
import { toPercent, toDollars, sum, titlecase } from '../../lib/utils'
import { userIsGlobalAdmin } from '../../lib/auth-helpers'
import { pastDays } from 'lib/time'
import { InitializedRecord } from '@orbit/records'
import { BaseColumn, ColumnLookup } from 'components/MetricsTable'
import { camelCase, sortBy, uniqBy } from 'lodash-es'
import CreativePreviewTableCell from 'components/PerformanceTable/CreativePreviewCell'
import { RedYellowGreen } from 'components/UI/RedYellowGreen/RedYellowGreen'
import { RowFilter } from './TableSettings/types'

const FUNNEL_STATUSES = [
  'new_lead',
  'contacted',
  'interviewed',
  'qualified',
  'application_started',
  'applied',
  'admitted',
  'confirmed_acceptance',
  'enrolled',
  'started',
  'matriculated',
  'class_passed',
  'graduated'
]

const OMIT_STATUSES = [
  'pending',
  'new_lead',
]

export type Column = BaseColumn | MetricsColumn | PerformanceColumn

interface MetricsColumn extends BaseColumn {
  subCategory: string
  fieldType: "count" | "rate"
}

interface PerformanceColumn extends BaseColumn {
  subCategory: string
  fieldType: "count" | "rate" | "cost"
}

export function sortByHash(hash: Record<string,string>, list = Object.keys(hash)) {
  return list.sort((a,b) => (hash[a] || '').localeCompare(hash[b]))
}

export function fetchJson(method: 'GET' | 'POST', url: RequestInfo | URL, body: Record<string,unknown> | undefined | null = null) {
  const options : RequestInit = { method }

  if(body !== null && body !== undefined) {
    options.body = JSON.stringify(body)
    options.headers = { 'Content-type': 'application/json' }
  }

  return fetch(url, options)
    .then(failOnHttpError)
    .then(response => response.json())
}

// FIXME: duplicated between here and LeadTable
export function mapToName(records: InitializedRecord[], { transform = stripHTML } = {}) {
  return records.reduce((hash, record) => { hash[getRemoteId(record)] = transform(record.attributes?.internalName || record.attributes?.name || record.attributes?.description); return hash; }, {})
}

export function createLookup(records: {id: string}[], lookup: ((r: Record<string,string>) => string)) {
  return records.reduce((hash, record) => { hash[record.id] = lookup(record); return hash; }, {})
}

const CUSTOM_FIELDS_FOR_31 = [
  { field: 'customMetrics:costPerStart', title: 'Net Cost Per Start', category: 'customMetrics', value: row => (row.totalCostInCents + 2500 * row.billableLeadCount) / row.startedLeadCount, render: value => toDollars(value) || '—', align: 'right', summary: data => (sum(data, 'totalCostInCents') + 2500 * sum(data, 'billableLeadCount')) / sum(data, 'startedLeadCount') } as const,
]

const CUSTOM_FIELDS_FOR_197 = [
  { field: 'customMetrics:ltr', title: 'LTR', category: 'customMetrics', value: row => 217800 * row['enrolledLeadCount'], render: value => toDollars(value) || '—', align: 'right', summary: 'sum' } as const,
  { field: 'customMetrics:ltrPerCost', title: 'LTR / Cost', value: row => 217800 * row['enrolledLeadCount'] / row['totalCostInCents'], render: value => toFormattedNumber(value, { maximumFractionDigits: 4 }), category: 'customMetrics', align: 'right', summary: data => 217800 * sum(data, 'enrolledLeadCount') / sum(data, 'totalCostInCents') } as const,
]

const CUSTOM_FIELDS_FOR_291 = [
  { field: 'customMetrics:bookings', title: 'Bookings', category: 'customMetrics', render: value => toDollars(value * 100) || '—', align: 'right', summary: 'sum' } as const,
  { field: 'customMetrics:bookingsPerCost', title: 'Bookings / Cost', value: row => 100 * row['customMetrics:bookings'] / row['totalCostInCents'], render: value => toFormattedNumber(value, { maximumFractionDigits: 4 }), category: 'customMetrics', align: 'right', summary: data => 100 * sum(data, 'customMetrics:bookings') / sum(data, 'totalCostInCents') } as const,
  { field: 'customMetrics:downPaymentPerLead', title: 'Avg Down Payment', value: row => 100 * row['customMetrics:downPayment'] / row['enrolledLeadCount'], render: value => toDollars(value) || '—', category: 'customMetrics', align: 'right', summary: data => 100 * sum(data, 'customMetrics:downPayment') / sum(data, 'enrolledLeadCount') } as const,
  { field: 'customMetrics:ltr', title: 'LTR', category: 'customMetrics', value: row => 62000 * row['enrolledLeadCount'], render: value => toDollars(value) || '—', align: 'right', summary: 'sum' } as const,
  { field: 'customMetrics:ltrPerCost', title: 'LTR / Cost', value: row => 62000 * row['enrolledLeadCount'] / row['totalCostInCents'], render: value => toFormattedNumber(value, { maximumFractionDigits: 4 }), category: 'customMetrics', align: 'right', summary: data => 62000 * sum(data, 'enrolledLeadCount') / sum(data, 'totalCostInCents') } as const,
]

function getCustomMetrics(clientIds: string[], accessibleClients: InitializedRecord[], userIsGlobalAdmin: boolean): Column[] {
  return [
    ...((accessibleClients.some(client => getRemoteId(client) === '31') || userIsGlobalAdmin) && clientIds.includes('31') ? CUSTOM_FIELDS_FOR_31 : []),
    ...(clientIds.includes('197') ? CUSTOM_FIELDS_FOR_197 : []),
    ...(clientIds.includes('291') ? CUSTOM_FIELDS_FOR_291 : []),
  ]
}

function getClientIdsFromFilter(filters : RowFilter[]) {
  const filter = filters.find(filter => filter.field === 'client-id')
  if(filter) {
    return filter.values
  }

  return []
}

const DEFAULT_CUSTOM_FIELD_COLUMNS: Column[] = [
  { field: 'customFields:Education', title: 'Education', category: 'clientFields', groupable: true, summary: { text: 'Total' } } as const,
  { field: 'customFields:GraduationYear', title: 'GraduationYear', category: 'clientFields', groupable: true, summary: { text: 'Total' } } as const,
  { field: 'customFields:Military', title: 'Military', category: 'clientFields', groupable: true, summary: { text: 'Total' } } as const,
  { field: 'customFields:StartClasses', title: 'StartClasses', category: 'clientFields', groupable: true, summary: { text: 'Total' } } as const,
]

const GROUPABLE_STANDARD_FIELD_COLUMNS: Column[] = [
  { field: 'city', title: 'City', category: 'location', groupable: true, summary: { text: 'Total' } } as const,
  { field: 'state', title: 'State', category: 'location', groupable: true, summary: { text: 'Total' } } as const,
  { field: 'degreeProgramId', title: 'Program', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
]

function getInitialAndUpdatedFields(constructedField : Column, initialFieldName : string, updatedFieldName : string) {
  const initialField = {
    ...constructedField,
    field: initialFieldName,
    title: `Initial ${constructedField.title}`,
    sortKey: constructedField.title,
  }
  const updatedField = {
    ...constructedField,
    field: updatedFieldName,
    title: `Updated ${constructedField.title}`,
    sortKey: constructedField.title
  }

  return [initialField, updatedField]
}

function showChangesOrOriginal(field) {
  let constructedField: Column, fieldDescriptor: string, fieldReference: string
  if(['custom_text', 'custom_select'].includes(field.fieldType)) {
    constructedField = {
      field: `customFields:${field.name}`,
      title: field.name,
      category: 'clientFields',
      groupable: true,
      summary: { text: 'Total' },
    }
    fieldDescriptor = 'Custom'
    fieldReference = field.name
  } else if(['city', 'state'].includes(field.fieldType)) {
    constructedField = {
      field: field.fieldType,
      title: field.fieldType.toUpperCase(),
      category: 'location',
      groupable: true,
      summary: { text: 'Total' },
    }
    fieldDescriptor = 'Standard'
    fieldReference = field.fieldType
  } else {
    return
  }

  if(field.showChanges) {
    return getInitialAndUpdatedFields(constructedField, `original${fieldDescriptor}Fields:${fieldReference}`, fieldReference)
  } else {
    return constructedField
  }
}

function showDegreeChangesOrOriginal(showDegreeProgramChanges) {
  const constructedField = {
    field: 'degreeProgramId',
    title: 'Program',
    category: 'main',
    groupable: true,
    summary: { text: 'Total' }
  }

  if(showDegreeProgramChanges) {
    return getInitialAndUpdatedFields(constructedField, 'degreeProgramId', 'currentDegreeProgramId')
  } else {
    return [constructedField]
  }
}

function getClientFieldColumns(clients, clientIds, { isGlobalAdmin }): Column[] {
  const matchingClients = clients.filter(client => clientIds.includes(client.id))

  if(isGlobalAdmin && !matchingClients.length) {
    return [...DEFAULT_CUSTOM_FIELD_COLUMNS, ...GROUPABLE_STANDARD_FIELD_COLUMNS]
  } else {
    const clientFieldColumns = matchingClients
      .flatMap(client => (
        client.fields
          .filter(field => ['custom_text', 'custom_select', 'city', 'state'].includes(field.fieldType))
          .filter(field => field.groupable)
          .flatMap(field => showChangesOrOriginal(field))
      ))

    return sortBy(uniqBy<Column>(clientFieldColumns, 'field'), ['sortKey', 'title'])
  }
}

function getGroupingColumns({ isGlobalAdmin, clients, clientIds, hasMediaColumns, userHasMultipleClients, userHasMultipleVendors }): Column[] {
  const matchingClients = clients.filter(client => clientIds.includes(client.id))

  const MEDIA_COLUMNS = [
    { field: 'marketingPlatformId', title: 'Platform', category: 'media', groupable: true, summary: { text: 'Total' }, render: (_, row) => row.marketingPlatformName || '(None)', sort: row => row.marketingPlatformName } as const,
    { field: 'adAccountId', title: 'Ad Account', category: 'media', groupable: true, summary: { text: 'Total' }, render: (_, row) => row.adAccountName || '(None)', sort: row => row.adAccountName } as const,
    { field: 'adCampaignId', title: 'Ad Campaign', category: 'media', groupable: true, summary: { text: 'Total' }, render: (_, row) => row.adCampaignName || '(None)', sort: row => row.adCampaignName } as const,
    { field: 'adSetId', title: 'Ad Set', category: 'media', groupable: true, summary: { text: 'Total' }, render: (_, row) => row.adSetName || '(None)', sort: row => row.adSetName } as const,
    { field: 'adId', title: 'Ad', category: 'media', groupable: true, summary: { text: 'Total' }, render: (_, row) => row.adName || '(None)', sort: row => row.adName } as const,
  ]

  const TIME_COLUMNS = [
      { field: 'year', title: 'Year', category: 'time', groupable: true, summary: { text: 'Total' }, render: (year?: string | number) => typeof(year) === 'undefined' ? '' : `${year}` } as const,
      { field: 'quarter', title: 'Quarter', category: 'time', groupable: true, summary: { text: 'Total' } } as const,
      { field: 'month', title: 'Month', category: 'time', groupable: true, summary: { text: 'Total' }, render: (month?: string | number) => typeof(month) === 'undefined' ? '' : moment.months()[parseInt(`${month}`)-1] } as const,
      { field: 'day', title: 'Day', category: 'time', groupable: true, summary: { text: 'Total' }, render: (day?: string | number) => typeof(day) === 'undefined' ? '' : moment.weekdays()[day] } as const,
      { field: 'hour', title: 'Hour', category: 'time', groupable: true, render: (hour?: string | number) => typeof(hour) === 'undefined' ? '' : moment().hour(hour).format('h:00 A'), summary: { text: 'Total' } } as const,
  ]

  // order is important here - it determines the display order of columns
  return [
    ...(isGlobalAdmin && hasMediaColumns && MEDIA_COLUMNS || []),
    isGlobalAdmin && { field: 'templateId', title: 'Landing Page Template', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    (userHasMultipleClients || isGlobalAdmin) && { field: 'primaryClientId', title: 'Landing Page Client', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    isGlobalAdmin && { field: 'landingPagePublicId', title: 'Landing Page', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    isGlobalAdmin && { field: 'complyedCreativeId', title: 'Creative ID', category: 'main', groupable: true, summary: { text: 'Total' }, tableCellComponent: CreativePreviewTableCell } as const,
    { field: 'campusId', title: 'Campus', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    (userHasMultipleClients || isGlobalAdmin) && { field: 'clientId', title: 'Client', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    (userHasMultipleVendors || isGlobalAdmin) && { field: 'vendorId', title: 'Vendor', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    { field: 'clientCampaignId', title: 'Campaign', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    { field: 'campaignType', title: 'Campaign Type', category: 'main', summary: { text: 'Total' }, groupable: true, render: (campaignType?: string) => titlecase(campaignType) } as const,
    { field: 'programGroupId', title: 'Program Group', category: 'main', groupable: true, summary: { text: 'Total' } } as const,
    ...showDegreeChangesOrOriginal(matchingClients.some(c => c.showDegreeProgramChanges)),
    { field: 'source', title: 'Source', category: 'main', groupable: true, summary: { text: 'Total' }, render: (source?: string) => source || '(uncategorized)' } as const,
    { field: 'subid', title: 'SubID', category: 'main', groupable: true, summary: { text: 'Total' }, render: (subid?: string) => subid || '(unknown)' } as const,
    ...TIME_COLUMNS,
    ...(getClientFieldColumns(clients, clientIds, { isGlobalAdmin }) || []),
  ].filter(isTruthy)
}

function getStatusMetricsColumns({ clientMetadata }): MetricsColumn[] {
  return [
    { field: 'leadCount', title: 'Total Leads', category: 'metrics', subCategory: 'Total', fieldType: 'count', align: 'right', summary: 'sum' },
    { field: 'goodLeadCount', title: 'Good Leads', category: 'metrics', subCategory: 'Good', fieldType: 'count', align: 'right', summary: 'sum' },
    { field: 'goodLeadRate', title: 'Good Lead Rate', category: 'metrics', subCategory: 'Good', fieldType: 'rate', align: 'right', value: row => (row.goodLeadCount as number)/(row.leadCount as number), render: value => toPercent(value), summary: data => sum(data, 'goodLeadCount')/sum(data, 'leadCount'), sort: row => (row.goodLeadCount as number)/(row.leadCount as number) * 100 },
    { field: 'billableLeadCount', title: 'Billable Leads', category: 'metrics', subCategory: 'Billable', fieldType: 'count', align: 'right', summary: 'sum' },
    { field: 'billableLeadRate', title: 'Billable Lead Rate', category: 'metrics', subCategory: 'Billable', fieldType: 'rate', align: 'right', value: row => (row.billableLeadCount as number)/(row.leadCount as number), render: value => toPercent(value), summary: data => sum(data, 'billableLeadCount')/sum(data, 'leadCount'), sort: row => (row.billableLeadCount as number)/(row.leadCount as number) * 100 },
    ...Object.entries(clientMetadata.statuses).flatMap(([status, statusTitle]) => {
      if(FUNNEL_STATUSES.includes(status) || OMIT_STATUSES.includes(status)) {
        return []
      }
      const countField = `${toCamelCase(status)}LeadCount`
      const rateField = `${toCamelCase(status)}LeadRate`

      return [
        { field: countField, title: `${statusTitle} Leads`, category: 'metrics', subCategory: `${statusTitle}`, fieldType: 'count', align: 'right', summary: 'sum' } as const,
        { field: rateField, title: `${bareInfinitive(statusTitle)} Rate`, category: 'metrics', subCategory: `${statusTitle}`, fieldType: 'rate', align: 'right', value: row => row[countField]/row.leadCount, render: value => toPercent(value), summary: data => sum(data, countField)/sum(data, 'leadCount'), sort: row => (row[countField]/row.leadCount) * 100 } as const,
      ]
    })
  ]
}

function getOtherMetricsColumns({ isGlobalAdmin }): Column[] {
  return [
    { field: 'costPerLead', title: 'Cost Per Lead', category: 'leadCosts', align: 'right', value: row => (row.totalCostInCents as number) / (row.billableLeadCount as number), render: value => toDollars(value) || '—', summary: data => sum(data, 'totalCostInCents')/sum(data, 'billableLeadCount'), sort: row => (row.totalCostInCents as number) / (row.billableLeadCount as number) / 100 } as const,
    ...(isGlobalAdmin ? [
      { field: 'impressions', title: 'Impressions', category: 'leadCosts', align: 'right', value: row => row.impressions as number, render: value => toFormattedNumber(value, { fallback: '—' }), summary: data => sum(data, 'impressions') } as const,
      { field: 'clicks', title: 'Clicks', category: 'leadCosts', align: 'right', value: row => row.clicks as number, render: value => toFormattedNumber(value, { fallback: '—' }), summary: data => sum(data, 'clicks') } as const,
      { field: 'ctr', title: 'CTR', category: 'leadCosts', align: 'right', value: row => (row.clicks as number)/(row.impressions as number), render: value => toPercent(value), summary: data => sum(data, 'clicks')/sum(data, 'impressions'), sort: row => (row.clicks as number)/(row.impressions as number) * 100 } as const,
      { field: 'cpm', title: 'CPM', category: 'leadCosts', align: 'right', value: row => 1000 * (row.totalSpendInCents as number) / (row.impressions as number), render: value => toDollars(value) || '—', summary: data => 1000 * sum(data, 'totalSpendInCents')/sum(data, 'impressions'), sort: row => 1000 * (row.totalSpendInCents as number) / (row.impressions as number) / 100 } as const,
      { field: 'cpc', title: 'CPC', category: 'leadCosts', align: 'right', value: row => (row.totalSpendInCents as number) / (row.clicks as number), render: value => toDollars(value) || '—', summary: data => sum(data, 'totalSpendInCents')/sum(data, 'clicks'), sort: row => (row.totalSpendInCents as number) / (row.clicks as number) / 100 } as const,
      { field: 'adjustedCpm', title: 'Adjusted CPM', category: 'adjustedSpend', align: 'right', value: row => 1000 * (row.totalAdjustedSpendInCents as number) / (row.impressions as number), render: value => toDollars(value) || '—', summary: data => 1000 * sum(data, 'totalAdjustedSpendInCents')/sum(data, 'impressions'), sort: row => 1000 * (row.totalAdjustedSpendInCents as number) / (row.impressions as number) / 100 } as const,
      { field: 'adjustedCpc', title: 'Adjusted CPC', category: 'adjustedSpend', align: 'right', value: row => (row.totalAdjustedSpendInCents as number) / (row.clicks as number), render: value => toDollars(value) || '—', summary: data => sum(data, 'totalAdjustedSpendInCents')/sum(data, 'clicks'), sort: row => (row.totalAdjustedSpendInCents as number) / (row.clicks as number) / 100 } as const,
      { field: 'rawConvRate', title: 'Raw Conv Rate', category: 'leadCosts', align: 'right', value: row => (row.leadCount as number)/(row.clicks as number), render: value => toPercent(value), summary: data => sum(data, 'leadCount')/sum(data, 'clicks'), sort: row => (row.leadCount as number)/(row.clicks as number) * 100 } as const,
      { field: 'goodConvRate', title: 'Good Conv Rate', category: 'leadCosts', align: 'right', value: row => (row.goodLeadCount as number)/(row.clicks as number), render: value => toPercent(value), summary: data => sum(data, 'goodLeadCount')/sum(data, 'clicks'), sort: row => (row.goodLeadCount as number)/(row.clicks as number) * 100 } as const,
    ] : []),
    { field: 'outlay', title: 'Outlay', category: 'leadCosts', align: 'right', value: row => row.totalCostInCents as number, render: value => toDollars(value) || '—', summary: data => sum(data, 'totalCostInCents'), sort: row => row.totalCostInCents as number / 100 } as const,
    ...(isGlobalAdmin ? [
      { field: 'spend', title: 'Spend', category: 'leadCosts', align: 'right', value: row => row.totalSpendInCents as number, render: value => toDollars(value) || '—', summary: data => sum(data, 'totalSpendInCents'), sort: row => row.totalSpendInCents as number / 100 } as const,
      { field: 'profit', title: 'Profit', category: 'leadCosts', align: 'right', value: row => isPresent(row.totalSpendInCents) ? (row.totalCostInCents as number) - (row.totalSpendInCents as number) : null, render: value => toDollars(value) || '—', summary: data => (sum(data, 'totalCostInCents')-sum(data, 'totalSpendInCents')), sort: row => (row.totalCostInCents as number - row.totalSpendInCents as number) / 100 } as const,
      { field: 'roas', title: 'ROAS-1', category: 'leadCosts', align: 'right', value: row => ((row.totalCostInCents as number) - (row.totalSpendInCents as number)) / (row.totalSpendInCents as number), render: value => <RedYellowGreen value={value as number} lower={0} upper={0.3}>{toPercent(value) || '—'}</RedYellowGreen>, summary: data => (sum(data, 'totalCostInCents')-sum(data, 'totalSpendInCents'))/sum(data, 'totalSpendInCents'), sort: row => (((row.totalCostInCents as number) - (row.totalSpendInCents as number)) / (row.totalSpendInCents as number)) * 100 } as const,
      { field: 'adjustedSpend', title: 'Adjusted Spend', category: 'adjustedSpend', align: 'right', value: row => row.totalAdjustedSpendInCents as number, render: value => toDollars(value) || '—', summary: data => sum(data, 'totalAdjustedSpendInCents'), sort: row => row.totalAdjustedSpendInCents as number / 100 } as const,
      { field: 'adjustedProfit', title: 'Adjusted Profit', category: 'adjustedSpend', align: 'right', value: row => isPresent(row.totalAdjustedSpendInCents) ? (row.totalCostInCents as number) - (row.totalAdjustedSpendInCents as number) : null, render: value => toDollars(value) || '—', summary: data => (sum(data, 'totalCostInCents')-sum(data, 'totalAdjustedSpendInCents')), sort: row => (row.totalCostInCents as number - row.totalAdjustedSpendInCents as number) / 100 } as const,
      { field: 'adjustedRoas', title: 'Adjusted ROAS-1', category: 'adjustedSpend', align: 'right', value: row => ((row.totalCostInCents as number) - (row.totalAdjustedSpendInCents as number)) / (row.totalAdjustedSpendInCents as number), render: value => <RedYellowGreen value={value as number} lower={0} upper={0.3}>{toPercent(value) || '—'}</RedYellowGreen>, summary: data => (sum(data, 'totalCostInCents')-sum(data, 'totalAdjustedSpendInCents'))/sum(data, 'totalAdjustedSpendInCents'), sort: row => (((row.totalCostInCents as number) - (row.totalAdjustedSpendInCents as number)) / (row.totalAdjustedSpendInCents as number)) * 100 } as const,
    ] : []),
  ]
}

export function getClientFunnelStatuses(clientMetadata) {
  return FUNNEL_STATUSES.map(status => {
    const statusTitle = clientMetadata.statuses[status]
    if(!statusTitle) {
      return null
    }
    return {
      status,
      statusTitle,
    }
  }).filter(isTruthy)
}

function getPerformanceColumns({ clientMetadata }): PerformanceColumn[] {
  const costType = 'totalCostInCents'

  const statuses = getClientFunnelStatuses(clientMetadata)
    .filter(({status}) => !OMIT_STATUSES.includes(status))
    .map(({status, statusTitle}) => ({
      statusTitle: statusTitle,
      countField: `${toCamelCase(status)}LeadCount`,
      rateField: `${toCamelCase(status)}LeadRate`,
      costField: `${toCamelCase(status)}LeadCost`,
    }))

  return [
    ...statuses.flatMap(({statusTitle, countField, rateField}) => [
      { field: countField, title: `${statusTitle} Leads`, category: 'performance', subCategory: `${statusTitle}`, fieldType: 'count', align: 'right', summary: 'sum' } as const,
      { field: rateField, title: `${bareInfinitive(statusTitle)} Rate`, category: 'performance', subCategory: `${statusTitle}`, fieldType: 'rate', align: 'right', value: row => row[countField]/row.goodLeadCount, render: value => toPercent(value), summary: data => sum(data, countField)/sum(data, 'goodLeadCount'), sort: row => ( row[countField] / row.goodLeadCount ) * 100 } as const,
    ]),
    ...statuses.map(({statusTitle, countField, costField}) => (
      { field: costField, title: `Cost Per ${bareInfinitive(statusTitle)}`, category: 'performance', subCategory: `${statusTitle}`, fieldType: 'cost', align: 'right', value: row => row[costType]/row[countField], render: value => toDollars(value) || '—', summary: data => sum(data, costType)/sum(data, countField), sort: row => ( row[costType] / row[countField] ) / 100 } as const
    )),
  ]
}

function getLookup(lookups: Record<string,ColumnLookup>, column : Column): ColumnLookup {
  return lookups[column.field] ||
    lookups[camelCase(column.field.replace(/^original/, ''))] ||
    lookups[camelCase(column.field.replace(/^current/, ''))] ||
    column.lookup
}

export function buildColumnList({ currentUser, clientMetadata, lookups, clients, clientId, hasMediaColumns, filters, userHasMultipleClients, userHasMultipleVendors, accessibleClients }): Column[] {
  const isGlobalAdmin = userIsGlobalAdmin({currentUser})

  const clientIds = isGlobalAdmin ? getClientIdsFromFilter(filters || []) : clientId ? [clientId] : []

  const columns = [
    ...getGroupingColumns({ isGlobalAdmin, clients, clientIds, hasMediaColumns, userHasMultipleClients, userHasMultipleVendors }),
    ...getStatusMetricsColumns({clientMetadata}),
    ...getOtherMetricsColumns({isGlobalAdmin}),
    ...getPerformanceColumns({clientMetadata}),
    ...(getCustomMetrics(clientIds, accessibleClients, isGlobalAdmin)),
  ].filter(isTruthy)

  return [...uniqBy<Column>(columns, 'field')]
    .map(column => ({...column, lookup: getLookup(lookups, column) }))
}

export function getDefaultDateRange(timezone: string) {
  return pastDays(1, timezone)
}
