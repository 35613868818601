import GreaterThanIcon from 'components/UI/icons/GreaterThanIcon';
import GreaterThanOrEqualIcon from 'components/UI/icons/GreaterThanOrEqualIcon';
import LessThanIcon from 'components/UI/icons/LessThanIcon';
import LessThanOrEqualIcon from 'components/UI/icons/LessThanOrEqualIcon';
import EqualsIcon from 'components/UI/icons/EqualsIcon';
import DoesNotEqualIcon from 'components/UI/icons/DoesNotEqualIcon';
import BetweenIcon from 'components/UI/icons/BetweenIcon';
import IsNotBetweenIcon from 'components/UI/icons/IsNotBetweenIcon';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { bindPopover } from 'material-ui-popup-state';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ComponentType, useState } from 'react';
import useId from '@mui/utils/useId'
import { useDataManager } from './DataManager';
import ListIcon from 'components/UI/icons/ListIcon';
import EndsWithIcon from 'components/UI/icons/EndsWithIcon';
import StartsWithIcon from 'components/UI/icons/StartsWithIcon';
import DoesNotContainIcon from 'components/UI/icons/DoesNotContainIcon';
import BlankIcon from 'components/UI/icons/BlankIcon';
import NotBlankIcon from 'components/UI/icons/NotBlankIcon';
import ContainsIcon from 'components/UI/icons/ContainsIcon';
import FreeTextMultiSelect from 'components/UI/FreeTextMultiSelect/FreeTextMultiSelect';
import { BaseColumn } from '.';
import { isBlank, isPresent, parseNumber } from 'lib/utils';


const NULLARY_OPERATORS = ['blank', 'not-blank'] as const
const NUMERIC_UNARY_OPERATORS = ['greater-than', 'greater-than-or-equal', 'less-than', 'less-than-or-equal', 'equals', 'not-equal'] as const
const NUMERIC_BINARY_OPERATORS = ['between', 'not-between'] as const
const TEXT_UNARY_OPERATORS = ['contains', 'not-contains', 'starts-with', 'ends-with'] as const
const LIST_OPERATORS = ['list'] as const

type NullaryOperator = typeof NULLARY_OPERATORS[number]
type NumericUnaryOperator = typeof NUMERIC_UNARY_OPERATORS[number]
type NumericBinaryOperator = typeof NUMERIC_BINARY_OPERATORS[number]
type TextUnaryOperator = typeof TEXT_UNARY_OPERATORS[number]
type ListOperator = typeof LIST_OPERATORS[number]

type NumericOperator = NullaryOperator | NumericUnaryOperator | NumericBinaryOperator | ListOperator
type TextOperator = NullaryOperator | TextUnaryOperator | ListOperator

type Operator = NullaryOperator | NumericOperator | TextOperator

interface NullaryFilter {
  operator: NullaryOperator
  value: [number]
}

interface UnaryNumberFilter {
  operator: NumericUnaryOperator
  value: [number]
}
interface BinaryNumberFilter {
  operator: NumericBinaryOperator
  value: [number, number]
}
interface UnaryTextFilter {
  operator:  TextUnaryOperator
  value: [string]
}
interface ListFilter {
  operator: ListOperator
  value: string[]
}
export type Filter = NullaryFilter | UnaryNumberFilter | BinaryNumberFilter | UnaryTextFilter | ListFilter

export function matchesColumnFilter(filter : Filter, value : string) {
  const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ''))

  switch(filter.operator) {
    case 'greater-than':
      return numericValue > filter.value[0]
    case 'greater-than-or-equal':
      return numericValue >= filter.value[0]
    case 'less-than':
      return numericValue < filter.value[0]
    case 'less-than-or-equal':
      return numericValue <= filter.value[0]
    case 'equals':
      return numericValue === filter.value[0]
    case 'not-equal':
      return numericValue !== filter.value[0]
    case 'between':
      return filter.value[0] <= numericValue && numericValue <= filter.value[1]
    case 'not-between':
      return !(filter.value[0] <= numericValue && numericValue <= filter.value[1])
    case 'contains':
      return value.toLowerCase().includes(filter.value[0].toLowerCase())
    case 'not-contains':
      return !value.toLowerCase().includes(filter.value[0].toLowerCase())
    case 'starts-with':
      return value.toLowerCase().startsWith(filter.value[0].toLowerCase())
    case 'ends-with':
      return value.toLowerCase().endsWith(filter.value[0].toLowerCase())
    case 'list':
      return filter.value.map(v => v.toLowerCase()).includes(value.toLowerCase())
    case 'blank':
      return isBlank(value)
    case 'not-blank':
      return isPresent(value)
  }
}

type NumericColumnFilters = {
  [key in NumericOperator]: [string, ComponentType]
}

const NUMERIC_COLUMN_FILTERS : NumericColumnFilters = {
  'greater-than': ['Greater than', GreaterThanIcon],
  'greater-than-or-equal': ['Greater than or equal to', GreaterThanOrEqualIcon],
  'less-than': ['Less than', LessThanIcon],
  'less-than-or-equal': ['Less than or equal', LessThanOrEqualIcon],
  'equals': ['Equals', EqualsIcon],
  'not-equal': ['Does not equal', DoesNotEqualIcon],
  'between': ['Is between', BetweenIcon],
  'not-between': ['Is not between', IsNotBetweenIcon],
  'blank': ['Blank', BlankIcon],
  'not-blank': ['Not Blank', NotBlankIcon],
  'list': ['List', ListIcon],
}

type TextColumnFilters = {
  [key in TextOperator]: [string, ComponentType]
}

const TEXT_COLUMN_FILTERS : TextColumnFilters = {
  'list': ['List', ListIcon],
  'contains': ['Contains', ContainsIcon],
  'not-contains': ['Does not contain', DoesNotContainIcon],
  'blank': ['Blank', BlankIcon],
  'not-blank': ['Not Blank', NotBlankIcon],
  'starts-with': ['Starts with', StartsWithIcon],
  'ends-with': ['Ends with', EndsWithIcon],
}

export function getAvailableFiltersForColumn(column: BaseColumn) {
  // In the future, we may want to add a prop to Column to indicate whether it's textual/numeric,
  // but for now, we can infer it from column.groupable and column.field
  if(!column.groupable || ['year', 'quarter', 'hour'].includes(column.field as string)) {
    return NUMERIC_COLUMN_FILTERS
  } else {
    return TEXT_COLUMN_FILTERS
  }
}

function FilterBySelect({operator, setColumnFilter, filters}) {
  const uniqueId = useId()

  return (
    <FormControl fullWidth>
      <InputLabel id={uniqueId + '-label'}>Filter By</InputLabel>
      <Select
        // Important to reset the columnFilter state here
        // otherwise the second field could be hidden by the new choice and retained unintentionally at Apply
        onChange={(e) => setColumnFilter(newColumnFilter(e.target.value))}
        value={operator}
        label={"Filter By"}
        labelId={uniqueId + '-label'}
        id={uniqueId}
        sx={{
          '.MuiListItemIcon-root': {
            display: 'none'
          },
          '.MuiListItemText-root': {
            my: 0
          }
        }}
      >
        {Object.keys(filters).map((value, index) => {
          const [text, Icon] = filters[value]
          return (
            <MenuItem key={index} value={value.toString()}>
              <ListItemIcon>
                {Icon && <Icon/>}
              </ListItemIcon>
              <ListItemText>{text.toString()}</ListItemText>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export type ColumnFilter = {
  operator: string,
  value: string[],
}

export function newColumnFilter(operator='', value=[]): ColumnFilter {
  return {
    operator: operator,
    value: value,
  }
}

export function isNullaryOperator(operator: Operator) : operator is NullaryOperator {
  return NULLARY_OPERATORS.some(o => o === operator)
}

export function isNumericUnaryOperator(operator: Operator) : operator is NumericUnaryOperator {
  return NUMERIC_UNARY_OPERATORS.some(o => o === operator)
}

export function isNumericBinaryOperator(operator: Operator) : operator is NumericBinaryOperator {
  return NUMERIC_BINARY_OPERATORS.some(o => o === operator)
}

export function isNumericOperator(operator: Operator) : operator is NumericOperator {
  return isNumericUnaryOperator(operator) || isNumericBinaryOperator(operator)
}

export function isTextUnaryOperator(operator: Operator) : operator is TextUnaryOperator {
  return TEXT_UNARY_OPERATORS.some(o => o === operator)
}

export function isListOperator(operator: Operator) : operator is ListOperator {
  return LIST_OPERATORS.some(o => o === operator)
}

function getFilterValue(columnFilter: Filter): (string | number)[] {
  const {operator, value} = columnFilter

  switch(true) {
    case isNullaryOperator(operator):
      return []
    case isNumericUnaryOperator(operator):
      return [parseNumber(value[0])]
    case isNumericBinaryOperator(operator):
      return [parseNumber(value[0]), parseNumber(value[1])]
    case isTextUnaryOperator(operator):
      return [value[0]]
    case isListOperator(operator):
      return value
    default: // Should never happen, since the operator must fall into one of the above categories
      return []
  }
}

export default function FilterOptions({column, popupState, anchorEl, onClose, onApply, existingColumnFilter, horizontalAlign = 'right'}) {
  const [columnFilter, setColumnFilter] = useState(existingColumnFilter)
  const dataManager = useDataManager()
  const applyEnabled = isPresent(columnFilter.operator) && (
    isNullaryOperator(columnFilter.operator) || 
    (isPresent(columnFilter.value[0]) && (!isNumericBinaryOperator(columnFilter.operator) || isPresent(columnFilter.value[1])))
  )
  const showDelete = !!dataManager.tableSettings.columnFilters[column.field]

  function isValid(value: string) {
    return /^[-]?((\d*\.?\d+)|(\d+\.?\d*))$/.test(value) || value === '' || value === '-'
  }

  return (
    <Popover
      {...bindPopover(popupState)}
      open={true}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontalAlign,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontalAlign,
      }}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
      role="dialog"
      sx={{
        ".MuiPaper-root": {
          p: 3,
          width: '372px'
        }
      }}
    >
      <Stack direction='column' gap={2}>
        <FilterBySelect operator={columnFilter.operator} setColumnFilter={setColumnFilter} filters={getAvailableFiltersForColumn(column)} />

        <Stack direction='row'>
          {
            (!columnFilter.operator || isNullaryOperator(columnFilter.operator)) &&
              <TextField disabled={true} label='Value' role="textbox" sx={{flex: 1}} />
            || isNumericOperator(columnFilter.operator) &&
              <>
                <TextField label='Value' role="textbox" value={isPresent(columnFilter.value[0]) ? columnFilter.value[0] : ''} onChange={(e) => isValid(e.target.value) && setColumnFilter(prevState => ({...prevState, value: [e.target.value, prevState.value[1]] }))} sx={{flex: 1}} />
                {isNumericBinaryOperator(columnFilter.operator) &&
                  <>
                    <Typography variant="subtitle3" sx={{mx: 0.5, alignSelf:'center'}}>and</Typography>
                    <TextField label='Value' role="textbox" value={isPresent(columnFilter.value[1]) ? columnFilter.value[1] : ''} onChange={(e) => isValid(e.target.value) && setColumnFilter(prevState => ({...prevState, value: [prevState.value[0], e.target.value] }))} sx={{flex: 1}} />
                  </>
                }
              </>
            || isTextUnaryOperator(columnFilter.operator) &&
              <TextField label='Value' role="textbox" value={isPresent(columnFilter.value[0]) ? columnFilter.value[0] : ''} onChange={(e) => setColumnFilter(prevState => ({...prevState, value: [e.target.value]}))} sx={{flex: 1}} />
            || isListOperator(columnFilter.operator) &&
              <FreeTextMultiSelect label='Value' value={columnFilter.value} setValue={(value) => setColumnFilter(prevState => ({...prevState, value: value}))} sx={{flex: 1}} />
          }
        </Stack>

        <Stack direction='row-reverse' sx={{justifyContent: 'space-between'}}>
          <Button disabled={!applyEnabled} sx={{width: '97px', alignSelf: 'end'}} onClick={(e) => { e.stopPropagation(); dataManager.setColumnFilter(column.field, {operator: columnFilter.operator, value: getFilterValue(columnFilter) }); onApply?.(columnFilter)}}>
            Apply
          </Button>
          {showDelete &&
            <Button variant='outlined' sx={{width: '97px', alignSelf: 'start'}} onClick={() => { dataManager.setColumnFilter(column.field, {}); setColumnFilter(newColumnFilter()); onClose?.() }}>
              Delete
            </Button>
          }
        </Stack>
      </Stack>
    </Popover>
  )
}
