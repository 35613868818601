import { forwardRef, useEffect, useRef, useState } from 'react'
import Inputmask from 'inputmask'

function InputMaskTextField({mask, definitions, ...props}, inputRef) {
  const ref = useRef(null)

  useEffect(() => {
    inputRef(ref.current)
    if(ref.current) {
      Inputmask(mask, { definitions: definitions })
        .mask(ref.current)
    } else {
    }
  }, [ref.current])

  return <input ref={ref} {...props}/>
}

export default forwardRef(InputMaskTextField)
