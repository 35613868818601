import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'

import useStyles from './styles'
import { useDataManager } from './DataManager'
import COLORS from 'lib/colors'

export default function TableTitle({title, refreshButton=false, actions=[], children = undefined}) {
  const classes = useStyles()
  const dataManager = useDataManager()

  if(title || refreshButton || actions.length) {
    return (
      <>
      {children && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.title}>
          <Grid item>
            {title && (
              <Typography variant="h3">{title}</Typography>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.title} role="heading" aria-roledescription="Table Title" aria-label={title}>
        <Grid item className={classes.titleChildren}>
          {children ? (
            children
          ) : (
            <Typography variant="h3">{title}</Typography>
          )}
        </Grid>
        <Grid item>
          {actions.map((action, index) => (
            action.component && action.component({ key: index }) || (
              <Tooltip key={index} title={action.tooltip}>
                <IconButton key={index} onClick={action.onClick} size="large" sx={{ color: COLORS.frenchBlue }}>
                  {typeof(action.icon) === 'string' && (
                    <img src={action.icon} className={classes.actionIcon}/>
                  ) ||
                  typeof(action.icon) === 'function' && (
                    action.icon()
                  ) || (
                    action.icon
                  )}
                </IconButton>
              </Tooltip>
            )
          ))}
          {refreshButton &&
            <IconButton aria-label="Refresh" onClick={() => dataManager.reload()} sx={{ marginLeft: '0 !important', color: COLORS.frenchBlue }} >
              <SyncIcon style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          }
        </Grid>
      </Grid>
      </>
    )
  } else {
    return null
  }
}
