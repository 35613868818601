import makeStyles from '@mui/styles/makeStyles';
import MaterialAccordion from '@mui/material/Accordion';
import MaterialAccordionDetails from '@mui/material/AccordionDetails';
import MaterialAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import COLORS from '../lib/colors'

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    "&.MuiAccordion-root:before": {
    }
  },
  contents: {
    display: 'block',
  },
  heading: {
    color: theme.palette.text.secondary,
  },
  selectedHeading: {
    fontWeight: 600,
  },
}));

export default function AccordionPanel(props) {
  const classes = useStyles();

  const isSelected = props.panelId === props.expanded;

  return (
    <MaterialAccordion
      className={classes.accordionRoot}
      expanded={isSelected}
      onChange={props.handleChange(props.panelId)}
      sx={{
        '&': {
          minHeight: 48,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        },
        '&:before': {
          backgroundColor: COLORS.mediumGray,
        },
        '&.Mui-expanded + .MuiAccordion-root': {
          '&:before': {
            height: 0
          }
        }
      }}
    >
      <MaterialAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${props.panelId}-content`}
        id={`panel-${props.panelId}-header`}
        sx={{
          '&': {
            padding: 0,
            '.MuiAccordionSummary-content': {
              margin: '0',
              padding: '0 0 0 24px',
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              marginRight: '12px',
            }
          },
          '&.Mui-expanded': {
            minHeight: 48,
            '.MuiAccordionSummary-content.Mui-expanded': {
              margin: '0',
              padding: '0 0 0 24px',
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              marginRight: '12px',
            }
          }
        }}
      >
        <Typography className={isSelected ? classes.selectedHeading : classes.heading}>{props.label}</Typography>
      </MaterialAccordionSummary>
      <MaterialAccordionDetails className={classes.contents}>
        {props.children}
      </MaterialAccordionDetails>
    </MaterialAccordion>
  );
}
