import { http, HttpResponse } from "msw";
import LeadDetailsDrawer from "./LeadDetailsDrawer";
import HostnameProvider from "lib/HostnameProvider";

export default {
  title: "Components/LeadDetailsDrawer",
  component: LeadDetailsDrawer,
  decorators: [
    (Story) => (
      <div style={{ margin: "3em", width: "1200px" }}>
        <Story />
      </div>
    ),
  ],
  parameters: {
    zeplinLink: "https://zpl.io/5EdM3dQ",
    layout: "fullscreen",
    chromatic: {
      modes: {
        tall: {
          viewport: {
            height: 2500,
            width: 1500,
          },
        },
      },
    },
  },
};

const lead = {
  publicId: "39bae7d4",
  degreeProgramId: 274,
  externalId: "UMA-0000000000",
  firstName: "Paul",
  lastName: "Bunyon",
  email: "bunyon@woods.com",
  phone: "8454692112",
  streetAddress: "1 Elizabeth Dr",
  city: "Chester",
  state: "NY",
  zipCode: "10918",
  status: "new_lead",
  statusChangedAt: "2023-08-01T14:33:17.303Z",
  leadAmountCents: null,
  rejectionReason: null,
  rawParams:
    '{"HS":"Yes - High School Diploma","City":"Chester","Agree":"1","Email":"bunyon@woods.com","Phone":"(845) 469-2112","State":"NY","subid":"umahcmsnsyllwpocketsnnsn01","source":"sn","variant":"3","LastName":"Bunyon","ZIP_Code":"10918","resident":"Yes","FirstName":"Paul","StartClasses":"3-6 Months","leadid_token":"DBFE9790-38AD-5CC0-C874-13BAB3A15BF4","StreetAddress":"1 Elizabeth Drive","GraduationYear":"2010"}',
  createdAt: "2023-08-01T14:33:16.634Z",
  updatedAt: "2023-08-02T16:01:41.967Z",
  subid: "umahcmsnsyllwpocketsnnsn01",
  source: "sn",
  remoteIp: "98.183.198.50",
  secondaryTcpa: false,
  trustedFormCertUrl: null,
  trustedFormCertClaimed: false,
  filteredReason: null,
  userAgent:
    "Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Mobile/15E148 Snapchat/12.28.0.27 (like Safari/8614.2.9.0.10)",
  trackingParams: '{"_scid":"e89103d7-bde0-47b2-b23d-c385a9261484"}',
  vendorId: 1,
  billable: true,
  test: false,
  landingPageId: null,
  disposition: null,
  complyedCreativeId: 1234,
  clientId: 31,
  campaignType: "exclusive",
  jornayaLeadidToken: "DBFE9790-38AD-5CC0-C874-13BAB3A15BF4",
};

const columns = [
  {
    title: "Date",
    field: "createdAt",
    type: "date",
    defaultFilter: {
      operator: "between",
      value: [
        {
          iso8601String: "2023-08-03",
        },
        {
          iso8601String: "2023-08-03",
        },
      ],
    },
    hidden: false,
  },
  {
    title: "Email",
    field: "email",
    hidden: false,
  },
  {
    title: "Raw request parameters",
    field: "rawParams",
    hidden: false,
  },
  {
    title: "Client",
    field: "clientId",
    lookup: {
      31: "UMA",
    },
    subfield: "degreeProgramId",
    hidden: false,
    subfieldMap: {
      31: ["274"],
    },
  },
  {
    title: "VEGA ID",
    field: "publicId",
    hidden: true,
  },
  {
    title: "Vendor",
    field: "vendorId",
    lookup: {
      1: "Cygnus Education",
    },
    hidden: true,
  },
  {
    title: "Degree Program",
    field: "degreeProgramId",
    lookup: {
      274: "Environmental Conservation Degree",
    },
    hidden: false,
  },
  {
    title: "External ID",
    field: "externalId",
    hidden: false,
  },
  {
    title: "SubID",
    field: "subid",
    hidden: true,
  },
  {
    title: "Source",
    field: "source",
    hidden: true,
  },
  {
    title: "Campaign Type",
    field: "campaignType",
    lookup: {
      exclusive: "Exclusive",
      shared: "Shared",
    },
    hidden: false,
  },
  {
    title: "First name",
    field: "firstName",
    hidden: false,
  },
  {
    title: "Last name",
    field: "lastName",
    hidden: false,
  },
  {
    title: "Phone Number",
    field: "phone",
    hidden: true,
  },
  {
    title: "Street Address",
    field: "streetAddress",
    hidden: true,
  },
  {
    title: "City",
    field: "city",
    hidden: false,
  },
  {
    title: "State",
    field: "state",
    hidden: true,
  },
  {
    title: "ZIP Code",
    field: "zipCode",
    hidden: true,
  },
  {
    title: "Status",
    field: "status",
    lookup: {
      new_lead: "New lead",
    },
    hidden: true,
  },
  {
    title: "Disposition",
    field: "disposition",
    lookup: {
      not_qualified: "Not Qualified",
      not_interested: "Not Interested",
      closed_lost: "Closed Lost",
      bad_lead: "Bad Lead",
    },
    hidden: false,
  },
  {
    title: "Status changed at",
    field: "statusChangedAt",
    type: "datetime",
    hidden: true,
  },
  {
    title: "Billable",
    field: "billable",
    lookup: {
      true: "Yes",
      false: "No",
    },
    filterExclusive: true,
    hidden: false,
  },
  {
    title: "Lead amount",
    field: "leadAmountCents",
    hidden: true,
  },
  {
    title: "IP Address",
    field: "remoteIp",
    hidden: true,
  },
  {
    title: "User Agent",
    field: "userAgent",
    hidden: true,
  },
  {
    title: "Secondary TCPA",
    field: "secondaryTcpa",
    lookup: {
      true: "Yes",
      false: "No",
    },
    hidden: true,
  },
  {
    title: "Rejection reason",
    field: "rejectionReason",
    hidden: false,
  },
  {
    title: "Filtered reason",
    field: "filteredReason",
    hidden: false,
  },
  {
    title: "Tracking parameters",
    field: "trackingParams",
    hidden: true,
  },
  {
    title: "Test",
    field: "test",
    type: "checkbox",
    hidden: true,
  },
  {
    title: "Landing Page ID",
    field: "landingPageId",
    hidden: true,
  },
];

const Template = (args) => {
  return (
    <HostnameProvider
      portalHostname={"portalHostname"}
      complyedHostname={"test.complyed.com"}
    >
      <LeadDetailsDrawer
        columns={args.columns}
        lead={args.lead}
        onClose={() => console.log("closed")}
      />
    </HostnameProvider>
  );
};

export const Default = Template.bind({});
Default.args = {
  lead: lead,
  columns: columns,
};
Default.parameters = {
  msw: {
    handlers: [
      http.get(
        "https://test.complyed.com/creatives/1234.json",
        (_request, _params, _cookies) => {
          return HttpResponse.json({
            id: 1234,
            approved_at: "2023-08-02 01:57:47 UTC",
            ad_type: "Video Ad",
            url: "/nothing_real.mov",
          });
        },
      ),
    ],
  },
};

export const ComplyedLoginNeeded = Template.bind({});
ComplyedLoginNeeded.args = {
  ...Default.args,
  lead: { ...lead, complyedCreativeId: 5678 },
};
ComplyedLoginNeeded.parameters = {
  msw: {
    handlers: [
      http.get(
        "https://test.complyed.com/creatives/5678.json",
        (_request, _params, _cookies) => {
          return new HttpResponse('Forbidden', { status: 401 })
        },
      ),
    ],
  },
};

export const NoCreativeAttachment = Template.bind({});
NoCreativeAttachment.args = {
  ...Default.args,
  lead: { ...lead, complyedCreativeId: 9999 },
};
NoCreativeAttachment.parameters = {
  msw: {
    handlers: [
      http.get(
        "https://test.complyed.com/creatives/9999.json",
        (_request, _params, _cookies) => {
          return HttpResponse.json({
            id: 1234,
            approved_at: "2023-08-02 01:57:47 UTC",
            ad_type: null,
            url: null,
          });
        },
      ),
    ],
  },
};

export const NoCID = Template.bind({});
NoCID.args = {
  ...Default.args,
  lead: { ...lead, complyedCreativeId: null },
};
NoCID.parameters = {};
