import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Checkbox, styled } from "@mui/material";
import { useCallback } from "react";
import { useDataManager } from "./DataManager";
import { xor, upperFirst } from "lodash-es";

const MenuCheckbox = styled(Checkbox)(() => ({
  width: '20px',
  height: '20px',
}))

const adjustableSpendColumns = ['cpm', 'cpc', 'spend', 'profit', 'roas']

function getVisibleColumns( showAdjusted, visibleColumns) {
  if(!showAdjusted) {
    return visibleColumns.filter(col => !col.startsWith('adjusted'))
  }

  const addedAdjustableSpendColumns = visibleColumns
    .filter(col => adjustableSpendColumns.includes(col))
    .map( adjCol => `adjusted${upperFirst(adjCol)}`)
  return visibleColumns.concat(addedAdjustableSpendColumns)
}

export default function ColumnSettingMenu({menuPopupState, filterPopupState, showSettingsMenu, dispatch, column}) {
  const dataManager = useDataManager()

  const handleToggleSubtotal = useCallback(column => _event => {
    const subtotalColumns = xor(dataManager.tableSettings.subtotalColumns, [column.field])
    dataManager.setTableSettings({...dataManager.tableSettings, subtotalColumns: subtotalColumns})
  }, [dataManager])

  const handleToggleAdjusted = useCallback(() => {
    const showAdjusted = dataManager.tableSettings.showAdjusted ? false : true
    const updatedVisibleColumns = getVisibleColumns(showAdjusted, dataManager.tableSettings.visibleColumns)

    dataManager.setTableSettings({...dataManager.tableSettings, visibleColumns: updatedVisibleColumns, showAdjusted: showAdjusted})
  }, [dataManager])

  return (
    <Menu
      {...bindMenu(menuPopupState)}
      open={showSettingsMenu}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      disableAutoFocusItem
      sx={{
        '.MuiTypography-root': {fontSize: '14px'},
        '.MuiPaper-root': {width: 200}
      }}
    >
      {column.groupable &&
        <MenuItem onClick={handleToggleSubtotal(column)}>
          <ListItemIcon>
            <MenuCheckbox checked={dataManager.tableSettings.subtotalColumns.includes(column.field)} disableRipple/>
          </ListItemIcon>
          <ListItemText>Subtotal</ListItemText>
        </MenuItem>
      }
      {adjustableSpendColumns.includes(column.field) &&
        <MenuItem onClick={() => handleToggleAdjusted()}>
          <ListItemIcon>
            <MenuCheckbox checked={dataManager.tableSettings.showAdjusted} disableRipple/>
          </ListItemIcon>
          <ListItemText>View Adjusted Spend</ListItemText>
        </MenuItem>
      }
      <MenuItem {...bindTrigger(filterPopupState)} onClick={() => dispatch({ type: 'SHOW_FILTER_OPTIONS' })}>
        <ListItemIcon>
          <FilterAltOutlinedIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>Filter column</ListItemText>
      </MenuItem>
    </Menu>
  )
}
