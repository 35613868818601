import { Typography } from '@mui/material'
import { PlaceholderWrapper } from 'components/UI/StyledCard'

export default function EmptyStateSection({ message }) {
  return (
    <PlaceholderWrapper>
      <Typography
        color="info.main"
        variant="body2"
        align="center"
      >
        {message}
      </Typography>
    </PlaceholderWrapper>
  )
}
