import { useMemo } from 'react'
import DatePickerPopover, { DatePickerPopoverProps } from './DatePickerPopover'
import DateRangeIcon from '@mui/icons-material/DateRange';
import Typography from '@mui/material/Typography'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { getTimeAndDate, isEndOfDay } from 'lib/time';

const timeAndDateProps = {
  timeFormat: 'hA',
  dateFormat: 'MMM D',
  useReferentialNames: false,
}

export type DatePickerInputProps = DatePickerPopoverProps

export default function DatePickerInput({value, ...props} : DatePickerInputProps) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'dateRange' })
  const formattedValue = useMemo(() => value.timeRange ? [getTimeAndDate(value.timeRange[0], 'start', timeAndDateProps), getTimeAndDate(isEndOfDay(value.timeRange[1]) ? value.timeRange[1] : value.timeRange[1].clone().add(1, 'second'), 'end', timeAndDateProps)].join(' - ') : value.label, [value.label, value.timeRange])

  const popoverProps = bindPopover(popupState)

  return (
    <>
      <Button variant='text' aria-label="Select Date Range" {...bindTrigger(popupState)} sx={{ p: '2px', m: '4px !important', borderRadius: '3px' }}>
        <Stack direction='row' gap={1} sx={{ alignItems: 'center' }}>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap', ml: .5 }}>
            {formattedValue}
          </Typography>
          <DateRangeIcon />
        </Stack>
      </Button>

      <DatePickerPopover
        {...popoverProps}
        value={value}
        {...props}
      />
    </>
  )
}
