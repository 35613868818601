import MultiStepModal from ".";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 18,
    marginBottom: 18,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 18,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default {
  title: "UI/MultiStepModal",
  component: MultiStepModal,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4091186d5d5b2f6b20056",
  },
};

const Template = (args) => {
  return (
    <>
      <MultiStepModal {...args}>
        <Child></Child>
      </MultiStepModal>
    </>
  );
};

function Child() {
  return (
    <div style={{ width: "420px", height: "200px" }} className="someContent">
      I&apos;m baby vaporware poke yr whatever, chicharrones hashtag tumeric.
      Chartreuse cliche fingerstache, hammock shaman pork belly chia kombucha
      vinyl put a bird on it vape affogato lomo. Salvia tofu plaid waistcoat
      mustache hashtag wolf sartorial narwhal chicharrones godard coloring book.
      Butcher salvia green juice vegan adaptogen chartreuse lo-fi disrupt
      shaman. Pork belly taiyaki literally offal, godard PBR&B live-edge
      aesthetic ugh wayfarers crucifix listicle seitan selfies leggings. Hoodie
      affogato tumblr, williamsburg schlitz chicharrones taiyaki Church-key
      shaman synth glossier slow-carb franzen. Tumeric celiac kinfolk tote bag
      messenger bag retro hoodie yr craft beer. Meditation seitan small batch,
      fixie hell of sriracha mumblecore VHS chartreuse chicharrones gastropub
      prism wayfarers aesthetic meggings. Mlkshk yuccie mixtape, swag tbh +1 meh
      knausgaard deep v. Master cleanse air plant prism portland before they
      sold out kitsch. Farm-to-table pinterest hexagon, fanny pack chambray
      marfa post-ironic pabst. Brunch bitters organic hot chicken migas.
    </div>
  );
}

function SubTitle() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subtitle}>
        This is a <b>SubTitle</b>.
      </div>
      <Divider light className={classes.divider} />
    </>
  );
}

const handleClose = (_event, reason) => {
  if (reason === "backdropClick") {
    console.log("not closing");
  } else {
    console.log("closed");
  }
};

export const Default = Template.bind({});
Default.args = {
  onClose: handleClose,
  onClickBackButton: () => console.log("back clicked"),
  onCancel: () => console.log("cancel"),
  scroll: "paper",
  buttonText: "Next",
  modalTitle: "A Title for this Modal",
  showBackButton: false,
  children: Child,
};

export const WithBackArrow = Template.bind({});
WithBackArrow.args = {
  ...Default.args,
  showBackButton: true,
};

export const WithSubtitle = Template.bind({});
WithSubtitle.args = {
  ...Default.args,
  modalSubTitle: <SubTitle></SubTitle>,
};
