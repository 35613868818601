import { Typography } from '@mui/material'
import { ReactNode } from 'react'
import COLORS from '../../../lib/colors'

export type RedYellowGreenProps = {
  value: number
  lower: number
  upper: number
  children: ReactNode
}

export function RedYellowGreen({ value, lower, upper, children }: RedYellowGreenProps) {
  const color = !isFinite(value) ? null : value < lower ? COLORS.copperRed : value < upper ? COLORS.goldenrod : COLORS.olivine

  return <Typography sx={{ color: color }}>
    {children}
  </Typography>
}
