import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { FormTextField } from './Formik/forms';

const useStyles = makeStyles(theme => ({
  withMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const SelectWithOther = function(props) {
  const { children, onChange, name, value, ...selectProps } = props;
  const selectedChild = value === '' ? undefined : React.Children.toArray(children).find(child => child.props.value === value);
  const isOther = !selectedChild;

  const classes = useStyles();
  const selectValue = isOther ? '' : value;

  const wrapOnChange = (event, ...args) => {
    //setSelectValue(event.target.value);
    onChange(event, ...args);
  };

  return (
    <>
      <TextField
        select
        SelectProps={{
          displayEmpty: true
        }}
        className={classes.withMargin}
        {...selectProps}
        value={selectValue}
        onChange={wrapOnChange}
      >
        {children}
      </TextField>
      {isOther && (
        <FormTextField
          className={classes.withMargin}
          fullWidth={false}
          name={name}
          label="Value"
        />
      )}
    </>
  );
};

export default SelectWithOther;
