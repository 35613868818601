import { useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Dialog from '@mui/material/Dialog';

import { DialogTitle, DialogContent } from '../../dialogs';
import SimpleTable from '../../SimpleTable';
import { failOnHttpError } from '../../../lib/utils'
import { loadTableData } from '../../table-utils';
import { Form, FormTextField } from '../../Formik/forms';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import MainContainer from "components/layout/MainContainer/MainContainer";
import { useNotifications } from "lib/NotificationsProvider";
import { useOrbit } from "providers/OrbitProvider";

const COLUMNS = [
  { title: 'ID', field: 'id', type: 'numeric', width: '0px', visible: true },
  { title: 'Name', field: 'name', visible: true },
]

const AddOrganizationModal = function(props) {
  const [organization, setOrganization] = useState(props.organization);

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={props.onClose}>
      <DialogTitle id="modal-title" onClose={props.onClose}>
        {organization.id ? 'Edit' : 'Add'} organization
      </DialogTitle>
      <DialogContent>
        <Form
          resource={organization}
          setResource={setOrganization}
          onSave={props.onSave}
          redirectAfterSave={false}
          promptOnNavigate={false}
          backwardsCompatibleMargins={true}
        >
          <FormTextField name="name" label="Name"/>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default function OrganizationTable() {
  const { remote } = useOrbit()
  const { addNotification } = useNotifications()
  const [organizationForModal, setOrganizationForModal] = useState();
  const dataManagerRef = useRef(null);
  const navigate = useNavigate();

  const refreshTable = () => {
    dataManagerRef.current && dataManagerRef.current.reload();
  };

  const handleDelete = (_event, rowData) => {
    if(!confirm(`Are you sure you want to delete the organization ${rowData['name']}?`)) {
      return;
    }

    fetch(`/api/v1/organizations/${rowData.id}`, { method: 'DELETE' })
      .then(failOnHttpError)
      .then(() => {
        addNotification({variant: 'notice', message: 'Deleted organization.'});
        refreshTable();
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to delete organization.'});
      });
  };

  const handleEdit = (_event, rowData) => {
    setOrganizationForModal({type: 'organization', id: rowData.id, attributes: {name: rowData.name}});
  };

  const handleManage = (_event, rowData) => {
    navigate(`/organizations/${rowData.id}/users`)
  };

  const handleAdd = (_event, _rowData) => {
    setOrganizationForModal({type: 'organization', attributes: {name: '', email: '', password: ''}});
  };

  const closeModal = () => {
    setOrganizationForModal(null);
  };

  const afterAddOrganization = (_organization) => {
    closeModal();
    refreshTable();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataFn = useCallback(loadTableData(remote, q => q.findRecords('organization'), addNotification), [addNotification, remote])

  return (
    <MainContainer>
      <div style={{ maxWidth: "100%" }}>
        <SimpleTable
          dataManagerRef={dataManagerRef}
          columns={COLUMNS}
          data={dataFn}
          options={{
            paginated: true,
            pageSize: 10,
            columnsButton: true,
          }}
          title="Organizations"
          rowActions={[
            {
              icon: () => <DeleteOutlineIcon/>,
              tooltip: 'Delete Organization',
              onClick: handleDelete,
            },
            {
              icon: () => <EditIcon/>,
              tooltip: 'Edit organization',
              onClick: handleEdit,
            },
            {
              icon: () => <PeopleIcon/>,
              tooltip: 'Manage organization members',
              onClick: handleManage,
            },
          ]}
          actions={[
            {
              icon: () => <AddCircleIcon/>,
              isFreeAction: true,
              tooltip: 'Add new organization',
              onClick: handleAdd,
            },
          ]}
        />
      </div>
      {organizationForModal && (
        <AddOrganizationModal
          organization={organizationForModal}
          onClose={closeModal}
          onSave={afterAddOrganization}
        />
      )}
    </MainContainer>
  )
}
