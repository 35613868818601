import { useMemo, useState } from 'react'
import { getRecordIdentity, getRemoteId } from '../../../lib/DataModel'
import { useTimezone } from '../../../lib/TimezoneProvider'
import { useRefreshable } from 'lib/hooks'

import VendorsResource from '../../../resources/VendorsResource'
import Dashboard from '../Dashboard/Dashboard'
import CurrentMonthPacingResource from '../../../resources/CurrentMonthPacingResource'
import DailyCountsResource from '../../../resources/DailyCountsResource'
import { getIn } from '../../Formik/forms'
import { TimeRangeBuilder, timeRangeToDateRange } from '../../../lib/time'
import { useParams } from 'react-router-dom'
import { useOrbit } from 'providers/OrbitProvider'

export default function ClientDashboard() {
  const { clientId } = useParams()
  const { remote, store } = useOrbit()
  const client = store.cache.query(q => q.findRecord(getRecordIdentity('client', clientId)))
  const [vendorsResource] = useState(() => new VendorsResource({ remote, client }))
  const { timezone } = useTimezone()
  const [currentMonthPacingResource, refreshCurrentMonthPacingResource] = useRefreshable(() => new CurrentMonthPacingResource({ store, remote, clientId, timezone }), [clientId])
  const initialDateRange = useMemo(() => timeRangeToDateRange(new TimeRangeBuilder({ timezone }).monthToDate()), [timezone])
  const dailyCountsResource = useMemo(() => new DailyCountsResource({ remote, clientId, timezone }), [clientId, remote, timezone])
  const vendorMapPromise = useMemo(() => vendorsResource.promise.then(vendors => Object.fromEntries(vendors.map(vendor => [getRemoteId(vendor), vendor.attributes.name]))), [vendorsResource])
  const campaignMapPromise = useMemo(() => currentMonthPacingResource.promise.then(() => (
    Object.fromEntries(store.cache.query(q => q.findRelatedRecords(client, 'clientCampaigns')).map(record => [getRemoteId(record), getIn(record, 'attributes.name')]))
  )), [store, client, currentMonthPacingResource.promise])
  const groupingColumns = useMemo(() => [
    { title: 'Vendor', field: 'vendorId', lookup: vendorMapPromise, summary: { text: 'Total' }, visible: false },
    { title: 'Campaign', field: 'clientCampaignId', lookup: campaignMapPromise, summary: { text: 'Total' }, visible: true },
  ], [campaignMapPromise, vendorMapPromise])

  return (
    <Dashboard
      groupingColumns={groupingColumns}
      dailyCountsResource={dailyCountsResource}
      currentMonthPacingResource={currentMonthPacingResource}
      refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource}
      dashboardUrl={`/api/v1/clients/${clientId}/dashboard`}
      initialDateRange={initialDateRange}
    />
  )
}
