import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddIcon from '@mui/icons-material/Add'

import { getIn } from './Formik/forms'
import { sortBy } from '../lib/utils'
import { useOrbit } from 'providers/OrbitProvider'

function ContractRow({contract, onRemove = () => {}}) {
  const { remote, store } = useOrbit()
  const [isRemoving, setIsRemoving] = useState(false)

  const handleRemove = () => {
    if(!confirm(`Are you sure you want to remove vendor ${getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name')} from ${getIn(store.cache.query(q => q.findRelatedRecord(contract, 'client')), 'attributes.name')}? This action cannot be undone.`)) {
      return
    }

    setIsRemoving(true)
    remote.update(q => q.removeRecord(contract)).then(() => {
      onRemove()
    })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setIsRemoving(false)
      })
  }

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <IconButton onClick={handleRemove} aria-label="Remove" disabled={isRemoving} size="large">
          <RemoveCircleIcon/>
        </IconButton>
      </Grid>
      <Grid item>
        {getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name')}
      </Grid>
    </Grid>
  );
}

const AddVendorModal = function({client, onAdd, onClose}) {
  const { remote } = useOrbit()
  const [vendor, setVendor] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [vendors, setVendors] = useState([])

  useEffect(() => {
    remote.query(q => q.findRecords('vendor')).then(vendors => {
      setVendors(vendors)
    })
  }, [remote])

  const handleChange = event => {
    setVendor(event.target.value)
  }

  const handleClose = () => {
    if(!isSaving) {
      onClose()
    }
  }

  const handleSave = () => {
    setIsSaving(true)
    remote.update(q => q.addRecord({ type: 'contract', relationships: { client: { data: client }, vendor: { data: vendor } } })).then(() => {
      onAdd()
      onClose()
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      setIsSaving(false)
    })
  }

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={handleClose}>
      <DialogTitle id="modal-title" onClose={handleClose}>
        Add Vendor
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <InputLabel htmlFor="vendor-select-label">Vendor</InputLabel>
          <Select
            labelId="vendor-select-label"
            value={vendor}
            onChange={handleChange}
            style={{minWidth: 300}}
          >
            {sortBy(vendors, 'attributes.name').map(vendor => (
              <MenuItem key={vendor.id} value={vendor}>{getIn(vendor, 'attributes.name')}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button onClick={handleSave} disabled={isSaving}>
          {isSaving && <CircularProgress size={28}/>}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function ContractManagement({client, resource, refreshResource}) {
  const { store } = useOrbit()
  const [showAddModal, setShowAddModal] = useState(false)

  const openModal = () => {
    setShowAddModal(true)
  }

  const closeModal = () => {
    setShowAddModal(false)
  }

  return (
    <>
      <Grid container direction="column">
        {sortBy(resource.get(), contract => getIn(store.cache.query(q => q.findRelatedRecord(contract, 'vendor')), 'attributes.name')).map(contract => (
          <Grid item key={contract.id}>
            <ContractRow contract={contract} onRemove={refreshResource}/>
          </Grid>
        ))}
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <IconButton onClick={openModal} aria-label="Add Vendor" size="large">
                <AddIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showAddModal && <AddVendorModal client={client} onAdd={refreshResource} onClose={closeModal}/>}
    </>
  )
}
