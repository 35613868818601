import { Box } from "@mui/material";
import GrayHeaderBox from ".";

export default {
  title: "UI/GrayHeaderBox",
  component: GrayHeaderBox,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4095d86838c1093a6aeb9",
  },
};

const Template = (args) => {
  return (
    <GrayHeaderBox
      {...args}
      title={"Some Heading Text"}
      sx={{ width: "250px" }}
    >
      <Box sx={{ height: "100px", margin: "20px" }}>
        Here is some content for our GrayHeaderBox
      </Box>
    </GrayHeaderBox>
  );
};

export const Default = Template.bind({});
