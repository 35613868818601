import SvgIcon from "@mui/material/SvgIcon"

export default function EndsWithIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
              <path id="k2zmn6282a" d="M0 0h18v18H0z"/>
          </defs>
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <g transform="translate(3 3)">
                  <mask id="my8cbk9f5b" fill="#fff">
                      <use xlinkHref="#k2zmn6282a"/>
                  </mask>
                  <rect stroke="currentColor" strokeWidth="1.35" mask="url(#my8cbk9f5b)" x="-34.325" y="1.675" width="51.65" height="14.65" rx="3"/>
                  <g mask="url(#my8cbk9f5b)" fill="currentColor" fillRule="nonzero">
                      <path d="M1.008 13.096c-.28 0-.48-.096-.6-.288-.12-.192-.116-.424.012-.696l3.168-7.02c.104-.232.23-.398.378-.498a.89.89 0 0 1 .51-.15c.184 0 .35.05.498.15.148.1.274.266.378.498l3.18 7.02c.128.28.136.514.024.702-.112.188-.304.282-.576.282-.224 0-.398-.054-.522-.162-.124-.108-.234-.274-.33-.498l-.6-1.392H2.4l-.588 1.392c-.104.232-.212.4-.324.504-.112.104-.272.156-.48.156zm3.444-6.888L2.928 9.82H6L4.476 6.208h-.024zM9.544 13c-.208 0-.364-.066-.468-.198a.647.647 0 0 1-.132-.474c.016-.184.1-.36.252-.528l2.82-3.54H9.472c-.384 0-.576-.188-.576-.564 0-.376.192-.564.576-.564h3.552c.24 0 .418.064.534.192a.6.6 0 0 1 .156.462c-.012.18-.094.354-.246.522l-2.856 3.564H13.3c.384 0 .576.188.576.564 0 .376-.192.564-.576.564H9.544z"/>
                  </g>
              </g>
          </g>
      </svg>
    </SvgIcon>
  )
}
