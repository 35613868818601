import { useCallback, useEffect, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { Link, useParams } from 'react-router-dom';
import EditableField from '../../EditableField'
import { Form, getIn } from '../../Formik/forms';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { getRemoteId, isNew } from '../../../lib/DataModel'
import { stripHTML } from '../../../lib/utils'
import { useNotifications } from 'lib/NotificationsProvider';
import { PageSection } from 'components/UI/Structure/PageSection';
import SectionTitle from 'components/UI/SectionTitle';
import { useOrbit } from 'providers/OrbitProvider';
import { useCurrentUser } from 'lib/CurrentUserProvider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: theme.spacing(3),
  },
  clientRow: {
    backgroundColor: '#f0f0f0',
  },
  hover: {
    '& $clientRow': {
      backgroundColor: '#e0e0e0',
    },
  },
  programRow: {
    backgroundColor: '#f8f8f8',
  },
  programLink: {
    color: '#303030',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  programLinkDisabled: {
    pointerEvents: 'none',
    color: 'gray',
  },
  programName: {
    width: '100%',
  },
  actionCol: {
    border: '1px solid transparent',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'white',
  },
  tableBody: {
    // border: '1px solid #eee',
  },
  draggable: {
    cursor: 'move',
  },
  hoverIcon: {
    '&:not(:hover)': {
      opacity: 0.5,
    },
  },
}));

function ProgramGroupHeader({permitEdit, programGroup, degreePrograms, onDelete, onChange}) {
  const classes = useStyles()
  const { store } = useOrbit()

  const handleDelete = () => {
    deleteProgramGroup(store, programGroup).then(onDelete)
  }

  const handleChange = (value) => {
    store.update(q => q.replaceAttribute(programGroup, 'description', value)).then(onChange)
  }

  return (
    <>
      <TableRow className={classes.clientRow}>
        <TableCell colSpan={2} style={{fontWeight: 700}}>
          <EditableField value={programGroup.attributes.description} setValue={handleChange} readOnly={!permitEdit} />
        </TableCell>
        <TableCell className={classes.actionCol}>
          {permitEdit && (
            degreePrograms.length > 0 && (
              <Tooltip title="Only empty groups can be deleted.">
                <span>
                  <IconButton disabled title="Only empty groups can be deleted." size="large">
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                </span>
              </Tooltip>
            ) || (
              <Tooltip title="Delete group">
                <IconButton className={classes.hoverIcon} onClick={handleDelete} size="large">
                  <DeleteIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            )
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

function DegreeProgramRow({permitEdit, degreeProgram}) {
  const classes = useStyles();
  const [{ isDragging }, drag, _preview] = useDrag({
    type: 'degreeProgram',
    item: degreeProgram,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => permitEdit,
  })

  return (
    <TableRow className={classes.programRow} style={{opacity: isDragging ? 0.4 : 1}} ref={drag}>
      <TableCell>
        <DragIndicatorIcon color="disabled" fontSize="small" ref={drag} className={classes.draggable}/>
      </TableCell>
      <TableCell className={classes.programName}>
        <Link className={classes.programLink} to={`${degreeProgram.id}`}>
          {stripHTML(degreeProgram.attributes.name)}
        </Link>
      </TableCell>
    </TableRow>
  )
}

function ProgramGroup({programGroup: programGroupOrFn, onChange, children}) {
  const classes = useStyles()
  const { store } = useOrbit()

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'degreeProgram',
    drop: (degreeProgram, _monitor) => {
      const programGroupPromise = typeof(programGroupOrFn) === 'function' ? programGroupOrFn() : Promise.resolve(programGroupOrFn)
      programGroupPromise.then(newProgramGroup => {
        const oldProgramGroup = store.cache.query(q => q.findRelatedRecord(degreeProgram, 'programGroup'))
        if(oldProgramGroup && newProgramGroup && oldProgramGroup.id === newProgramGroup.id) {
          return
        }
        store.update(q => q.replaceRelatedRecord(degreeProgram, 'programGroup', newProgramGroup)).then(() => {
          onChange(degreeProgram)
        })
      })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isDropHover = canDrop && isOver;

  return (
    <TableBody className={`${isDropHover ? classes.hover : ''} ${classes.tableBody}`} ref={drop}>
      {children}
    </TableBody>
  )
}

function newGroupName(store, client) {
  for(let i=1;; i++) {
    const description = `Group ${i}`
    if(!store.cache.query(q => q.findRelatedRecords(client, 'programGroups').filter({attribute: 'description', value: description}))[0]) {
      return description
    }
  }
}

function newProgramGroup(store, client) {
  return store.update(q => q.addRecord({
    type: 'programGroup',
    attributes: { description: newGroupName(store, client) },
    relationships: { client: { data: client } },
  }))
}

function deleteProgramGroup(store, programGroup) {
  return store.update(q => q.removeRecord(programGroup))
}

function DegreeProgramsTable({permitEdit, client, programGroups, setProgramGroups, onChangeDegreeProgram, onChangeProgramGroup, onDeleteProgramGroup}) {
  const classes = useStyles();
  const { store } = useOrbit()
  const [programGroupPrograms, setProgramGroupPrograms] = useState({})

  const updateProgramGroupPrograms = useCallback((arg) => {
    const programGroups = store.cache.query(q => q.findRelatedRecords(client, 'programGroups'))
    const newProgramGroupPrograms = Object.fromEntries(programGroups.map(programGroup => [programGroup.id, store.cache.query(q => q.findRelatedRecords(programGroup, 'degreePrograms'))]))
    setProgramGroupPrograms(newProgramGroupPrograms)
    return arg
  }, [client, store])

  const updateProgramGroups = (arg) => {
    setProgramGroups([...store.cache.query(q => q.findRelatedRecords(client, 'programGroups')).map(obj => ({...obj}))])
    return arg
  }

  useEffect(() => {
    updateProgramGroupPrograms()
  }, [programGroups, setProgramGroupPrograms, updateProgramGroupPrograms])

  return (
    <DndProvider backend={HTML5Backend}>
      <Table className={classes.table}>
        {programGroups.map(programGroup => (
          <ProgramGroup key={programGroup.id} programGroup={programGroup} onChange={degreeProgram => { updateProgramGroupPrograms(); onChangeDegreeProgram(degreeProgram) }}>
            <ProgramGroupHeader permitEdit={permitEdit} programGroup={programGroup} degreePrograms={programGroupPrograms[programGroup.id] || []} onDelete={programGroup => { updateProgramGroups(); onDeleteProgramGroup(programGroup) }} onChange={programGroup => { updateProgramGroups(); onChangeProgramGroup(programGroup) }} />
            {(programGroupPrograms[programGroup.id] || []).map((degreeProgram, idx) => (
              <DegreeProgramRow key={idx} permitEdit={permitEdit} degreeProgram={degreeProgram}/>
            ))}
            {permitEdit && (
              <TableRow className={classes.programRow}>
                <TableCell>
                  <AddIcon/>
                </TableCell>
                <TableCell className={classes.programName}>
                  {isNew(programGroup) && (
                    <span className={classes.programLinkDisabled}>
                      Add program
                    </span>
                  ) || (
                    <Link className={classes.programLink} to={`new?programGroupId=${getRemoteId(programGroup)}`}>
                      Add program
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            )}
          </ProgramGroup>
        ))}

        <ProgramGroup programGroup={() => newProgramGroup(store, client).then(programGroup => { updateProgramGroups(); onChangeProgramGroup(programGroup); return programGroup })} onChange={degreeProgram => { updateProgramGroupPrograms(); onChangeDegreeProgram(degreeProgram) }}>
          {permitEdit && (
            <TableRow className={classes.clientRow}>
              <TableCell colSpan={2} style={{textAlign: 'center'}}>
                <Button onClick={() => { newProgramGroup(store, client).then(updateProgramGroups).then(onChangeProgramGroup) }}>
                  <AddIcon/>
                  Add group
                </Button>
              </TableCell>
            </TableRow>
          )}
        </ProgramGroup>
      </Table>
    </DndProvider>
  )
}

export default function DegreePrograms() {
  const { addNotification } = useNotifications()
  const { remote, store } = useOrbit()
  const { currentUser } = useCurrentUser()
  const permitEdit = getIn(currentUser, 'attributes.permitGlobalAdmin')
  const { clientId } = useParams()
  const [_degreePrograms, setDegreePrograms] = useState([]);
  const [programGroups, setProgramGroups] = useState([])
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const promises = [
      remote.query(q => q.findRecord({type: 'client', id: clientId})),
      remote.query(q => q.findRelatedRecords({type: 'client', id: clientId}, 'degreePrograms')),
      remote.query(q => q.findRelatedRecords({type: 'client', id: clientId}, 'programGroups')),
    ];
    Promise.all(promises).then(([client, degreePrograms, programGroups]) => {
      setClient(client);
      setDegreePrograms(degreePrograms);
      setProgramGroups(programGroups)
      setLoading(false);
    }).catch(error => {
      console.log(error);
      addNotification({variant: 'alert', message: 'Error loading degree program list'})
      setLoading(false);
    })
  }, [addNotification, clientId, remote]);

  const handleSave = useCallback(() => (
    remote.query(q => q.findRelatedRecords({type: 'client', id: clientId}, 'programGroups'))
      .then((programGroups) => {
        setProgramGroups(programGroups)
      }
    )
  ), [clientId, remote])

  if(loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    return (
      <PageSection>
        <SectionTitle title='Degree Programs' />
        <Form resource={client} setResource={setClient} onSave={handleSave} withoutSubmitButton={!permitEdit} backwardsCompatibleMargins={true}>
          {({setNestedValue, deleteNestedValue}) => (
            <DegreeProgramsTable
              permitEdit={permitEdit}
              client={client}
              programGroups={programGroups}
              setProgramGroups={setProgramGroups}
              onChangeDegreeProgram={(degreeProgram) => {
                const programGroup = store.cache.query(q => q.findRelatedRecord(degreeProgram, 'programGroup'))
                // FIXME: this should work with attributes: [], but it breaks when adding a degree program to a new program group
                setNestedValue('programGroups', programGroup, {hasManyRelationships: ['degreePrograms']})
              }}
              onChangeProgramGroup={(programGroup) => { setNestedValue('programGroups', programGroup) }}
              onDeleteProgramGroup={(programGroup) => { deleteNestedValue('programGroups', programGroup) }}
            />
          )}
        </Form>
      </PageSection>
    );
  }
}
