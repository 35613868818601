import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import COLORS from '../../../lib/colors'
import styled from '@mui/system/styled'
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useFeatures } from "providers/FeaturesProvider";

// taken from https://codepen.io/andreas_hjortland/pen/WjJVqB (appears to be public domain)
const StyledSvg = styled('svg')`
@keyframes slide {
  0% {
    transform : translate(0, 0);
  }
  2% {
    transform : translate(33px, 0);
  }

  12.5% {
    transform : translate(33px, 0);
  }
  15.5% {
    transform : translate(calc(2 * 33px), 0);
  }

  25% {
    transform : translate(calc(2 * 33px), 0);
  }
  27% {
    transform : translate(calc(2 * 33px), 33px);
  }

  37.5% {
    transform : translate(calc(2 * 33px), 33px);
  }
  39.5% {
    transform : translate(33px, 33px);
  }

  50% {
    transform : translate(33px, 33px);
  }
  52% {
    transform : translate(33px, calc(2 * 33px));
  }

  62.5% {
    transform : translate(33px, calc(2 * 33px));
  }
  64.5% {
    transform : translate(0, calc(2 * 33px));
  }

  75% {
    transform : translate(0, calc(2 * 33px));
  }
  77% {
    transform : translate(0, 33px);
  }

  87.5% {
    transform : translate(0, 33px);
  }
  89.5% {
    transform : translate(0, 0);
  }

  100% {
    transform : translate(0, 0);
  }
}

// @keyframes gradient1 {
//   from {
//     stop-color: #006EB3;
//   }
//   to {
//     stop-color: #004977;
//   }
// }
// @keyframes gradient2 {
//   from {
//     stop-color: #004977;
//   }
//   to {
//     stop-color: #006EB3;
//   }
// }

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  display : block;
  margin  : auto;
  width   : 90px;
  height  : auto;
  //transform : rotate(180deg);

  //animation: rotate 7.5s linear 0s infinite;

  // #gradient > stop[offset="0%"] {
  //   animation: gradient1 15s ease 0s infinite;
  // }
  // #gradient > stop[offset="100%"] {
  //   animation: gradient2 15s ease 0s infinite;
  // }
  .rect {
    animation: slide 15s ease infinite;
  }
  #rect1 {
    animation-delay : calc(0.5 * 15s / 7);
  }
  #rect2 {
    animation-delay : calc(-0.5 * 15s / 7);
  }
  #rect3 {
    animation-delay : calc(-1.5 * 15s / 7);
  }
  #rect4 {
    animation-delay : calc(-2.5 * 15s / 7);
  }
  #rect5 {
    animation-delay : calc(-3.5 * 15s / 7);
  }
  #rect6 {
    animation-delay : calc(-4.5 * 15s / 7);
  }
  #rect7 {
    animation-delay : calc(-5.5 * 15s / 7);
  }

  &:not(.animated) {
    .rect {
      animation-play-state: paused;
    }
  }
`

export function LoadingAnimation({ running }) {
  return (
    <StyledSvg id="loading" viewBox="0 0 100 80" className={running ? 'animated' : ''}>
      <defs>
        <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#006EB3" />
          <stop offset="100%" stopColor="#004977" />
        </linearGradient>

        <clipPath id="rects">
          <rect className="rect" id="rect1" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect2" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect3" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect4" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect5" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect6" x="0" y="0" width="30" height="30" rx="2" ry="2" />
          <rect className="rect" id="rect7" x="0" y="0" width="30" height="30" rx="2" ry="2" />
        </clipPath>
      </defs>
      <rect id="container" transform="translate(50) scale(0.707, 0.707) rotate(45)" x="0" y="0" width="100" height="100" fill="url(#gradient)" clipPath="url(#rects)">
      </rect>
    </StyledSvg>
  )
}

const MyLinearProgress = styled(LinearProgress)({
  backgroundColor: COLORS.veryLightGray,
  height: '12px',
  borderRadius: '4px',
  '.MuiLinearProgress-barColorPrimary': {
    backgroundImage: `linear-gradient(to bottom, ${COLORS.regalBlue}, ${COLORS.frenchBlue})`,
  },
})

export function AkkioLoadingDialog({ onComplete, onClose }) {
  const { getFeatureConfig } = useFeatures()

  const initialTime = getFeatureConfig('akkio_mock', 'initial_time', 72) // TODO: get initial time from feature configuration
  const secondsPerMessage = getFeatureConfig('akkio_mock', 'seconds_per_message', 7) // TODO: get secondsPerMessage time from feature configuration
  const statusMessages = getFeatureConfig('akkio_mock', 'status_messages', [ // TODO: get from feature configuration
    'Loading Leads to Training Database',
    'Connecting to Neural Network',
    'Applying Machine Learning',
    'Evaluating Predictive Modeling',
    'Confirming Data Projections',
    'Creating Data Visualizations',
  ])

  const [timerStatus, setTimerStatus] = useState(() => ({ running: false, timeRemaining: initialTime}))

  useEffect(() => {
    if(timerStatus.running) {
      if(timerStatus.timeRemaining < 1) {
        onComplete()
      }
      else {
        setTimeout(() => {
          setTimerStatus(oldValue => ({ ...oldValue, timeRemaining: oldValue.timeRemaining - 1 }))
        }, 1000)
      }
    }
  }, [onComplete, timerStatus])

  const remainingTimeMessage =
    timerStatus.timeRemaining >= 60 ? `~${Math.floor(timerStatus.timeRemaining / 60)}:${(timerStatus.timeRemaining % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })} minutes` :
    `~${timerStatus.timeRemaining} seconds`

  const timeElapsed = Math.max(0, initialTime - Math.max(0, timerStatus.timeRemaining))

  const percentComplete = 100 * (timeElapsed / initialTime)

  const messageIndex = Math.floor(timeElapsed / secondsPerMessage) % statusMessages.length

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent sx={{ py: 3, px: 4 }}>
        <Box sx={{ mt: '28px', textAlign: 'center' }}>
          <Typography variant="h1">
            Launch AI Dashboard
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <LoadingAnimation running={timerStatus.running}/>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body1">
            {timerStatus.running? (
              statusMessages[messageIndex]
            ) : (
              'Do you want to launch the VEGA AI Leads Dashboard?'
            )}
          </Typography>
        </Box>
        <Box sx={{ mt: 3, mb: 2 }}>
          <MyLinearProgress variant="determinate" value={percentComplete}/>
        </Box>
        <Box sx={{ textAlign: "center", color: COLORS.slateGray }}>
          <Typography variant="body2">
            {!timerStatus.running && 'Estimated processing time: '}
            {remainingTimeMessage}
            {timerStatus.running && ' remaining'}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 3, pt: 1, px: 4 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        {!timerStatus.running && (
          <Button onClick={() => { setTimerStatus(oldValue => ({ ...oldValue, running: true })) }}>
            Launch
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
