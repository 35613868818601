import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import { DialogTitle } from '../../dialogs'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.text.primary,
    marginRight: 12,
    marginLeft: -9,
  },
  divider: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 18,
    marginBottom: 18,
  },
}))

export default function MultiStepHeader({ modalTitle, onClose, showBackButton, onClickBackButton }) {
  const classes = useStyles()

  return (
    <DialogTitle id="modal-title" onClose={onClose} sx={{ p: 0, m: '18px 24px 12px 24px', height: '30px'}}>
      {showBackButton && (
        <IconButton size="small" aria-label="Back" onClick={onClickBackButton} className={classes.backButton}>
          <ArrowBackIcon/>
        </IconButton>
      )}
      <Box sx={{ flexGrow: 1 }}>
        {modalTitle}
      </Box>
    </DialogTitle>
  )
}
