import MainContainer from 'components/layout/MainContainer/MainContainer'
import AllocationsTable from '../../AllocationsTable'
import BookendsProvider from 'lib/BookendsProvider'
import FooterBar from 'components/UI/FooterBar'
import { useParams } from 'react-router-dom'

export default function Allocations() {
  const { clientId } = useParams()

  // TODO: handle stories (for each user type)
  return (
    <BookendsProvider>
      <MainContainer>
        <AllocationsTable
          hasTabNavigation={!!clientId}
        />
      </MainContainer>

      <FooterBar/>
    </BookendsProvider>
  )
}
