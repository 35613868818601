import SvgIcon from '@mui/material/SvgIcon'

export default function MicrosoftIcon({...props}) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="15" cy="15" r="15"/>
              <path fill="#E15B35" d="M5.5 5.5h9v9h-9z"/>
              <path fill="#8DB739" d="M15.5 5.5h9v9h-9z"/>
              <path fill="#4FA3E9" d="M5.5 15.5h9v9h-9z"/>
              <path fill="#F2B942" d="M15.5 15.5h9v9h-9z"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
