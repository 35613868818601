import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import StopIcon from '@mui/icons-material/Stop'
import Typography from "@mui/material/Typography"
import { groupBy, sortBy, stripHTML } from "lib/utils"


function ClientCampaign({clientCampaign, leadTargets}) {
  const sortedDegreePrograms = sortBy(leadTargets.map(degreeProgram => degreeProgram.degreeProgram), 'name')

  return (
    <List disablePadding >
      <ListItem disablePadding sx={{alignItems:'baseline', mt: '6px'}}>
        {/* TODO: Add campaign status indicator as part of #183947447 */}
        {/* <ListItemIcon sx={{mt: 0, minWidth: '16px'}}><CircleIcon sx={{fontSize:'12px'}} /></ListItemIcon> */}
        <ListItemText sx={{my: 0}}>
          <Typography lineHeight='18px'>
            {clientCampaign.name}
          </Typography>
        </ListItemText>
      </ListItem>
      {sortedDegreePrograms.map((degreeProgram) =>
        <ListItem disablePadding key={"degreeProgram_" + degreeProgram.id} sx={{mt: '6px', mb: '2px', lineHeight:'18px', alignItems:'baseline' }}>
          <ListItemIcon sx={{ml: '-1px', minWidth: '19px'}}><StopIcon sx={{fontSize:'8px'}} /></ListItemIcon>
          <ListItemText sx={{my:'0px', lineHeight:'18px'}}>
            <Typography lineHeight='18px'>
              {stripHTML(degreeProgram.name)}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </List>
  )
}

function ClientLeadTargets({ client, leadTargets, clientWeight }) {
  const groupedLeadTargets = groupBy(leadTargets,
                                        'clientCampaign',
                                        { comparator: (a,b) => a.id === b.id,
                                          asEntries: true})

  return (
    <Box
      sx={{
        minWidth: '276px',
        flexBasis: `calc((100% - 36px) / 3)`,
      }}
      role="listitem"
      aria-roledescription="Client Program and Campaign Selection"
    >
      <Stack direction="row" spacing={2} role="heading">
        <Box sx={{fontWeight: 'bold', lineHeight: '18px', mt: '2px'}}>
          {client.internalName || client.name}
        </Box>
        { clientWeight && (
          <Chip label={`${clientWeight}%`}/>
        )}
      </Stack>
      {groupedLeadTargets.map(([clientCampaign, leadTargets]) =>
        <ClientCampaign
          key={`clientCampaign_${clientCampaign.id.toString()}`}
          clientCampaign={clientCampaign}
          leadTargets={leadTargets}
        />
      )}
    </Box>
  )
}

export default function ProgramAndCampaignContent({ leadTargets, clientWeights = null }) {
  const getClientWeight = client => clientWeights?.find(cw => cw.clientId === client.id)?.weight

  const groupedLeadTargets = groupBy(
    leadTargets,
    'clientCampaign.programGroup.client', {
      comparator: (a,b) => a.id === b.id,
      asEntries: true
    })

  const sortedLeadTargets = sortBy(
    groupedLeadTargets,
    { key: ([client, _leadTargets]) => getClientWeight(client), desc: true },
    ([client, _leadTargets]) => client.internalName || client.name,
  )

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        columnGap:'18px',
        rowGap:'12px',
      }}
      role="list"
      aria-roledescription="Program & Campaign Selection"
    >
      {sortedLeadTargets.map(([client, leadTargets], index) =>
        <ClientLeadTargets
          key={"clientLeadTargets_" + index}
          client={client}
          leadTargets={leadTargets}
          clientWeight={getClientWeight(client)}
        />
      )}
    </Box>
  )
}
