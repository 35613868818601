import { useCallback, useContext, useEffect, useState } from "react"
import { useBookends } from "lib/BookendsProvider"
import { useNotifications } from "lib/NotificationsProvider"
import { useMutation } from '@apollo/client'
import { useFormikContext } from "formik"
import EditIcon from '@mui/icons-material/Edit'
import FormWrapper from "components/Formik/FormWrapper"
import SectionTitle from "components/UI/SectionTitle"
import StyledCard, { CollapseWrapper, ContentWrapper } from "components/UI/StyledCard"
import { mainSchema } from "components/pages/LandingPages/data/yupSchemas"
import PortalProgramSelectionModal from "components/pages/LandingPages/shared/PortalProgramSelectionModal"
import { CREATE_LANDING_PAGE_MUTATION, buildMutation, UPDATE_LANDING_PAGE_MUTATION } from "components/pages/LandingPages/data/mutations"
import ProgramAndCampaignContent from "./ProgramAndCampaignContent"
import { useLandingPageActionsContext, useLandingPageStateContext } from "components/pages/LandingPages/ui/LandingPageProvider"
import { PageSection } from "components/UI/Structure/PageSection"
import { getErrorMessage } from "components/pages/LandingPages/data/utils"
import { PortalMainForm } from "./PortalMainForm"
import { BrandedMainForm } from "./BrandedMainForm"
import Grid from "@mui/material/Grid"
import { NavigationContext } from "../Navigation/LandingPageEditNavigation"
import Stack from "@mui/material/Stack"
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"


function MainFormContent() {
  const { isMultiClient } = useLandingPageStateContext()

  return (
    <Grid container direction="row" spacing={2} >
      {isMultiClient && (
        <PortalMainForm />
      ) || !isMultiClient && (
        <BrandedMainForm />
      )}
    </Grid>
  )
}

function MainPageForm() {
  const { dirty, isSubmitting, submitForm, resetForm, values, errors, setValues } = useFormikContext()
  const { setBookends } = useBookends()
  const { showPrompt } = useContext(NavigationContext)
  const [programSelectionModalOpen, setProgramSelectionModalOpen] = useState(false)

  const handleProgramModalComplete = useCallback(landingPage => {
    setValues(landingPage)
  }, [setValues])

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: "secondary",
            label: "Cancel",
            onClick: () => {
              showPrompt().then(() => resetForm())
            },
          },
          {
            type: "primary",
            label: isSubmitting ? 'Please wait...' : 'Save',
            disabled: !dirty || isSubmitting,
            onClick: submitForm,
          },
        ],
      },
      state: {
        form: {
          dirty: dirty,
          errors: errors,
        },
      },
    })
  }, [dirty, errors, isSubmitting, setBookends, submitForm, resetForm, showPrompt])

  const handleClose = useCallback((_event, reason) => {
    if(reason !== 'backdropClick') {
      setProgramSelectionModalOpen(false)
    }
  }, [])

  return <>
    <PageSection>
      <SectionTitle title='Main'/>
      <StyledCard
        title='Landing Page Details'
        isSubsection={true}
        hasStickyHeader={true}
      >
        <CollapseWrapper maxHeight={140}>
          <Stack direction='column' spacing={2} marginTop={3}>
            <SubsectionTitle
              title='Program & Campaign Selection'
              controls={[{icon: <EditIcon/>, onClick: () => setProgramSelectionModalOpen(true), label: 'Edit Portal'}]}
            />
            <ProgramAndCampaignContent
              leadTargets={values.leadTargets}
              clientWeights={values.clientWeights}
            />
          </Stack>
        </CollapseWrapper>
      </StyledCard>

      <StyledCard
        title='Landing Page Content'
        isSubsection={true}
        hasStickyHeader={true}
      >
        <ContentWrapper>
          <SubsectionTitle title='Main Content' sx={{ mt: 3, mb: 2 }}/>
          <MainFormContent />
        </ContentWrapper>
      </StyledCard>
    </PageSection>

    {programSelectionModalOpen && (
      <PortalProgramSelectionModal
        onClose={handleClose}
        onComplete={handleProgramModalComplete}
        landingPage={values}
      />
    )}
  </>
}

export default function MainPage() {
  const { landingPage } = useLandingPageStateContext()
  const { setLandingPage } = useLandingPageActionsContext()
  const { addNotification } = useNotifications()

  const isNew = !landingPage.id
  const [gqlSave] = useMutation(isNew ? CREATE_LANDING_PAGE_MUTATION : UPDATE_LANDING_PAGE_MUTATION)

  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const mutation = buildMutation(isNew ? {} : landingPage, values)
    gqlSave({ variables: mutation }).then(({ data }) => {
      setLandingPage(data.mutationResult.landingPage)
      addNotification({variant: 'notice', message: "Your changes have been saved."})
    }).catch(error => {
      addNotification({variant: 'alert', message: getErrorMessage(error)})
      console.log(error)
    }).finally(() => {
      setSubmitting(false)
    })
  }, [addNotification, gqlSave, isNew, landingPage, setLandingPage])

  return (
    <FormWrapper data={landingPage} schema={mainSchema} onSubmit={handleSubmit}>
      <MainPageForm />
    </FormWrapper>
  )
}
