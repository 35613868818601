import { spliceCopy } from 'lib/utils'
import objectKeyFilter from 'object-key-filter'
import { v4 as uuidv4 } from "uuid"

// Utils is for general helper functions which can be useful in a larger scope
// Eventually these will live at a higher level to be shared more broadly

const NEW_RECORD_PREFIX = 'new-record:'

export function validateOrFixPositions(values) {
  return values.map((value, index) => value.position === index + 1 ? value : { ...value, position: index + 1 })
}

export function changeItemIndex(arr, from, to) {
  const item = arr[from]
  const deletedArray = removeItemAtIndex(arr, from)
  return addItemAtIndex(deletedArray, item, to)
}

export function removeItemAtIndex(arr, index) {
  return spliceCopy(arr, index, 1)
}

export function addItemAtIndex(arr, item, index = arr.length) {
  return  [ ...arr.slice(0, index),
            item,
            ...arr.slice(index)
          ]
}

export function replaceItemAtIndex(arr, item, index) {
  return spliceCopy(arr, index, 1, item)
}

export function removeTypename(data) {
  return typeof data === 'object' ? objectKeyFilter(data, ['__typename'], true) : data;
}

export function isNewRecord(record) {
  return !record.id || record.id.startsWith(NEW_RECORD_PREFIX)
}

export function generateNewRecordId() {
  return NEW_RECORD_PREFIX + uuidv4()
}

export function getErrorMessage(error) {
  if(error?.networkError) {
    return 'A network error has occurred. Please try again.'
  } else {
    return 'An unknown error has occurred.'
  }
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
