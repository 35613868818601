import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import AlertBadge from "../AlertBadge/AlertBadge"
import Tab from "@mui/material/Tab"
import { humanize, titlecase } from "lib/utils"
import { buildErrorString, getUniqueErrors } from "components/Formik/utils"

export default function HeaderTab({ disabled=false, icon, value, formErrors=[], persistedErrors=[], badgeOffsetLeft='undefined', ...props }) {
  const hasErrorIcon = (persistedErrors[value]?.length > 0) || (formErrors.length > 0 && props.selected)
  const uniquePersistedErrors = getUniqueErrors(persistedErrors[value])
  const currentTabFormErrors = props.selected ? formErrors : []
  const errorString = buildErrorString(currentTabFormErrors, uniquePersistedErrors)
  const displayValue = titlecase(humanize(value))
  const tabLabel = <>
          {disabled && ( <Tooltip title="Section available upon Save">
            <div>{displayValue}</div>
          </Tooltip>) || (<div>{displayValue}</div>)}
          </>

  const tabIcon = <Box sx={{position: 'relative'}}>
          {hasErrorIcon && (
            <AlertBadge tipTitle={errorString} sx={{left: badgeOffsetLeft, top: '-11px'}}/>
          )}
          {icon}
        </Box>

  return (
    <Tab
      style={{ pointerEvents: "auto" }}
      disabled={disabled}
      icon={tabIcon}
      label={tabLabel}
      value={value}
      {...props}
    />
  )
}
