import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import TypeAheadDropDown from "./";

export default {
  title: "UI/TypeAheadDropDown",
  component: TypeAheadDropDown,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4095d86838c1093a6aeb9",
  },
};

const suggestions = [
  "GraduationYear",
  "StartClasses",
  "FirstName",
  "LastName",
  "StreetAddress",
  "City",
  "ZIP_Code",
  "Address",
  "ZipCodeSource",
  "AddressFromZip",
  "Email",
  "Phone",
  "State",
  "Citizen",
  "Age",
];

const Template = (args) => {
  const [passedArgs, setPassedArgs] = useState(args);
  const [value, setValue] = useState("");
  const handleChange = useCallback(
    (_event, newValue) => {
      setPassedArgs({ ...args, error: false });
      setValue(newValue);
    },
    [args],
  );

  return (
    <Box sx={{ width: "200px" }}>
      <TypeAheadDropDown
        {...passedArgs}
        value={value}
        onChange={handleChange}
        suggestions={suggestions}
        label={"Question"}
      />
      <div>Current value: {value}</div>
    </Box>
  );
};

export const Default = Template.bind({});

export const WithError = Template.bind({});
WithError.args = {
  ...Default.args,
  error: true,
};
