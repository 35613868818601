import { groupBy, stripHTML } from "lib/utils"
import { compact, find, unionBy, uniq, uniqBy } from "lodash-es"
import { CLIENT_FIELD_TYPE_BY_QUESTION_KEY } from "./constants"
import { generateNewRecordId } from "./utils"

// Helpers is for helper function specific to this context.
// They would not be useful at a higher level

export function buildLandingPage(initialProps = {}) {
  return {
    id: null,
    name: "",
    tags: {},
    variables: { jornayaEnabled: true },
    leadTargets: [],
    clientCampaignRules: [],
    degreeProgramRules: [],
    clientWeights: [],
    steps: [],
    landingPageRules: [],
    ...initialProps,
  }
}


export function buildRuleCondition(initialProps = {}) {
  return {
    id: null,
    key: null,
    operator: 'in',
    value: [],
    question: {
      id: null,
      key: '',
    },
    ...initialProps
  }
}

export function buildDegreeProgramRule(props = {}) {
  const { conditionProps, ...initialProps } = props
  return {
    id: null,
    default: false,
    degreeProgramId: null,
    conditions: [
      buildRuleCondition({...conditionProps})
    ],
    position: null,
    ...initialProps
  }
}


export function buildClientCampaignRule(props = {}) {
  const { conditionProps, ...initialProps } = props
  return {
    id: null,
    default: false,
    clientMapping: [],
    conditions: [
      buildRuleCondition({...conditionProps})
    ],
    position: null,
    ...initialProps
  }
}

export function buildLandingPageExclusionRule(initialProps = {}) {
  return {
    id: generateNewRecordId(),
    actionType: 'redirect',
    value: [],
    position: null,
    landingPageId: initialProps.landingPageId,
    conditions: [
      buildRuleCondition({})
    ],
    ...initialProps
  }
}

export function buildDefaultClientCampaignRule(initialProps = {}) {
  return {
    id: null,
    default: true,
    clientMapping: [],
    position: 1,
    ...initialProps
  }
}

export function buildDefaultDegreeProgramRule(initialProps = {}) {
  return {
    id: null,
    default: true,
    degreeProgramId: null,
    position: 1,
    ...initialProps
  }
}

export function buildClientMapping(clientCampaign) {
  return {
    clientId: clientCampaign.programGroup.client.id,
    programGroupId: clientCampaign.programGroup.id,
    clientCampaignIds: [clientCampaign.id],
  }
}

export function getSelectedClientsForRule(constraint, clients) {
  const uniqueClientIds = [...new Set(constraint?.clientMapping.map(clientMapping => clientMapping.clientId))]

  return compact(uniqueClientIds.map(clientId => clients.find(client => client.id === clientId)))
}

export function getClientsAndCampaigns(leadTargets) {
  const clientCampaigns = uniqBy(leadTargets.map(leadTarget => leadTarget.clientCampaign), 'id')

  return groupBy(
    clientCampaigns,
    'programGroup.client', {
      comparator: (a,b) => a.id === b.id,
      asEntries: true
    })
}

export function removeUnusedClientMappingEntries(clientCampaigns, clientMapping) {
  const clientCampaignIds = new Set(clientCampaigns.map(c => c.id))

  const clientIds = uniq(clientCampaigns.map(clientCampaign => clientCampaign.programGroup.client.id))
  const filteredClientMapping = clientMapping.filter(entry => clientIds.includes(entry.clientId))

  return filteredClientMapping.map(entry => {
    const filteredCampaignIds = entry.clientCampaignIds.filter(id => clientCampaignIds.has(id))
    return {
      ...entry,
      clientCampaignIds: filteredCampaignIds,
    }
  })
}

export function buildImpliedClientMappingEntries(clientCampaigns, clientMapping, addNewClients) {
  const groupedCampaignsByProgramGroup = groupBy(
    clientCampaigns,
    'programGroup',
    { comparator: (a,b) => a.id === b.id, asEntries: true}
  )

  const newClientMapping = []
  groupedCampaignsByProgramGroup.forEach(([programGroup, clientCampaigns]) => {
    const mappedClientIds = [...new Set(clientMapping.map(cm => cm.clientId))]
    if (!addNewClients && !mappedClientIds.includes(programGroup.client.id)) {
      return
    }

    if (clientCampaigns.length === 1) {
      newClientMapping.push(buildClientMapping(clientCampaigns[0]))
    }
  })

  return unionBy(clientMapping, newClientMapping, 'programGroupId')
}

export function getClientIdsFromLandingPage(landingPage) {
  const leadTargets = compact([
    ...landingPage.leadTargets,
    landingPage.fallbackLeadTarget
  ])

  return uniq(leadTargets.map(leadTarget => leadTarget.clientCampaign.programGroup.client.id))
}

function getDegreeProgramIdsFromLandingPage(landingPage) {
  const leadTargets = compact([
    ...landingPage.leadTargets,
    landingPage.fallbackLeadTarget  //TODO: can we remove this?
  ])

  return uniq(leadTargets.map(lt => lt.degreeProgram.id))
}

export function getClientNamesFromLandingPage(landingPage) {
  const leadTargets = compact([
    ...landingPage.leadTargets,
    landingPage.fallbackLeadTarget
  ])

  const clientIdNamePairs = uniq(leadTargets.map(lt => [lt.clientCampaign.programGroup.client.id, lt.clientCampaign.programGroup.client.internalName || lt.clientCampaign.programGroup.client.name]))
  return Object.fromEntries(clientIdNamePairs)
}

export function getDegreeProgramNamesFromLandingPage(landingPage) {
  const leadTargets = compact([
    ...landingPage.leadTargets,
    landingPage.fallbackLeadTarget
  ])

  const programIdNamePairs = uniq(leadTargets.map(lt => [lt.degreeProgram.id, stripHTML(lt.degreeProgram.name)]))
  return Object.fromEntries(programIdNamePairs)
}

export function getClientCampaignNamesFromLeadTargets(leadTargets) {
  return Object.fromEntries(leadTargets.map(leadTarget =>
    [
      leadTarget.clientCampaign.id,
      leadTarget.clientCampaign.name
    ]
  ))
}

export function getProgramGroupDescriptionFromLeadTargets(leadTargets) {
  return Object.fromEntries(leadTargets.map(leadTarget =>
    [
      leadTarget.clientCampaign.programGroup.id,
      leadTarget.clientCampaign.programGroup.description
    ]
  ))
}

export function getDegreeProgramNamesFromLeadTargets(leadTargets) {
  return Object.fromEntries(leadTargets.map(leadTarget =>
    [
      leadTarget.degreeProgram.id,
      stripHTML(leadTarget.degreeProgram.name)
    ]
  ))
}

function updateRuleDegreeProgramSets(rule, degreeProgramIds, clientIds) {
  const setsWithUpdatedDegreeProgramIds = rule.degreeProgramSets.map(set => {
    return {
      ...set,
      degreeProgramIds: set.degreeProgramIds.filter(dpId => degreeProgramIds.includes(dpId))
    }
  })

  const setsWithDegreeProgramIdsAndValidClient = setsWithUpdatedDegreeProgramIds.filter(set => set.degreeProgramIds.length > 0).filter(set => clientIds.includes(set.client.id))

  return {
    ...rule,
    degreeProgramSets: setsWithDegreeProgramIdsAndValidClient
  }
}

function updateDegreeProgramRule(landingPage, rule) {
  const isMultiClient = landingPage.template.multiClient
  if(isMultiClient) {
    return null
  }

  const degreeProgramIds = getDegreeProgramIdsFromLandingPage(landingPage)
  if(degreeProgramIds.includes(rule.degreeProgramId)) {
    return rule
  }

  if(degreeProgramIds.length === 1) {
    return {
      ...rule,
      degreeProgramId: degreeProgramIds[0]
    }
  }

  return null
}

export function updateClientCampaignRule(landingPage, rule) {
  const clientCampaigns = uniqBy(landingPage.leadTargets.map(leadTarget => leadTarget.clientCampaign), 'id')
  let clientMapping = removeUnusedClientMappingEntries(clientCampaigns, rule.clientMapping)
  clientMapping = buildImpliedClientMappingEntries(clientCampaigns, clientMapping, rule.default)

  if(clientMapping.length === 0) {
    return null
  } else {
    return {
      ...rule,
      clientMapping,
    }
  }
}

function updateFieldMapping({ landingPage, allClients, question }) {
  const clientIds = getClientIdsFromLandingPage(landingPage)
  const clients = allClients.filter(c => clientIds.includes(c.id))

  return unionBy(question.fieldMapping.filter(fm => clientIds.includes(fm.clientId)), buildAutoFieldMapping({ question, clients }), 'clientId')
}

function updateLandingPageRule({ landingPage, rule }) {
  const clientIds = getClientIdsFromLandingPage(landingPage)
  const degreeProgramIds = getDegreeProgramIdsFromLandingPage(landingPage)

  if(rule.actionType === 'exclude_programs') {
    const updatedRule = updateRuleDegreeProgramSets(rule, degreeProgramIds, clientIds)
    return updatedRule.degreeProgramSets.length > 0 ? updatedRule : null
  } else {
    return rule
  }
}

// Update dependencies of leadTargets:
//   * Create or update default campaign and program rules, if they can be inferred
//   * For each campaign rule:
//     * Remove unused clients
//     * For each clientMapping entry referencing a campaign that's missing from leadTargets, either replace it (if the campaign can be inferred) or remove it
//     * Remove the rule entirely if the clientMapping becomes empty
//   * For each program rule:
//     * If the rule references a program that's missing from leadTargets, either replace it (if the program can be inferred) or remove it
//   * Remove unused clients from field mappings
//   * Automatically populate field mappings when they can be inferred
//      (e.g. when the client's field name matches the question key)
export function recomputeDependencies({ landingPage, allClients }) {
  const defaultClientCampaignRule = landingPage.clientCampaignRules.find(rule => rule.default) || buildDefaultClientCampaignRule()
  const customClientCampaignRules = landingPage.clientCampaignRules.filter(rule => !rule.default)
  const clientCampaignRules = compact([defaultClientCampaignRule, ...customClientCampaignRules].map(rule => updateClientCampaignRule(landingPage, rule)))

  const defaultDegreeProgramRule = landingPage.degreeProgramRules.find(rule => rule.default) || buildDefaultDegreeProgramRule()
  const customDegreeProgramRules = landingPage.degreeProgramRules.filter(rule => !rule.default)
  const degreeProgramRules = compact([defaultDegreeProgramRule, ...customDegreeProgramRules].map(rule => updateDegreeProgramRule(landingPage, rule)))

  const steps = landingPage.steps.map(step => ({
    ...step,
    questions:step.questions.map(question => ({
      ...question,
      fieldMapping: updateFieldMapping({ landingPage, allClients, question })
    }))
  }))

  const landingPageRules = compact(landingPage.landingPageRules.map(rule => updateLandingPageRule({ landingPage, rule })))

  return {
    ...landingPage,
    landingPageRules,
    degreeProgramRules,
    clientCampaignRules,
    steps,
  }
}

export function buildAutoFieldMapping({ question, clients }) {
  const fieldType = CLIENT_FIELD_TYPE_BY_QUESTION_KEY[question.key]

  if(!fieldType) {
    return []
  }

  return compact(clients
    .map(client => {
      const field = find(client.fields, { fieldType })
      if(field) {
        return { clientId: client.id, field: field.name }
      }
    })
  )
}
