import { useCallback, useMemo} from 'react'
import makeStyles from '@mui/styles/makeStyles';

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { sortBy, partition } from '../../../lib/utils'
import { useFormikContext, FormikFieldArray, getIn } from '../../Formik/forms'
import COLORS from 'lib/colors';

const useStyles = makeStyles({
  root: {
    marginLeft: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'start',
    margin: '0 0 8px -6px',
    marginRight: 0,
  },
  labelInner: {
    lineHeight: 1,
  },
  checkbox: {
    padding: '2px 4px',
    marginTop: -6,
    marginBottom: 0,
    marginRight: 5,
  },
  checkboxChecked: {
    color: COLORS.regalBlue,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    width: (792 - 2*24 - 1) / 2,
  },
  columnDivider: {
    borderLeft: '1px solid #f0f3f5',
    paddingLeft: '36px',
    marginLeft: '-10px'
  },
})

function CollectionCheckbox({
  item,
  render,
  selectedIds,
  push,
  remove,
  disabled,
  getItemId,
}) {
  const classes = useStyles()

  const itemRemoteId = useMemo(() => getItemId(item), [getItemId, item])

  const selectedIndex = useMemo(() => (
    selectedIds.findIndex(selectedId => String(selectedId) === String(itemRemoteId))
  ), [selectedIds, itemRemoteId])

  const checked = selectedIndex !== -1

  const handleChange = useCallback(event => {
    if(event.target.checked) {
      push(itemRemoteId)
    } else {
      if(selectedIndex !== -1) {
        remove(selectedIndex)
      }
    }
  }, [selectedIndex, itemRemoteId, push, remove])

  return (
    <FormControlLabel
      control={<Checkbox classes={{root: classes.checkbox, checked: classes.checkboxChecked}} checked={checked} onChange={handleChange} disabled={disabled} />}
      label={render(item)}
      classes={{root: classes.label, label: classes.labelInner}}
    />
  )
}

export default function FormCollectionSelector({
  collection,
  name,
  render,
  numColumns = 1,
  minItemsPerColumn = 1,
  disabled,
  getItemId = item => item.id,
}) {

  const sortedCollection = useMemo(() => (
    sortBy(collection, render)
  ), [collection, render])

  const columns = useMemo(() => (
    partition(sortedCollection, numColumns, { minimumItemsPerPartition: minItemsPerColumn} )
  ), [sortedCollection, numColumns, minItemsPerColumn])

  const classes = useStyles()

  const { values } = useFormikContext()
  const selectedIds = useMemo(() => (
    getIn(values, name) || []
  ), [values, name])

  return (
    <FormikFieldArray
      name={name}
      render={({remove, push}) => (
        <div className={classes.container}>
          {columns.map((column, index) => (
            <div key={index}>
              <div className={`${(index > 0) ? classes.columnDivider : ''} ${classes.column}`}>
                {column.map(item => (
                  <div className={classes.item} key={item.id}>
                    <CollectionCheckbox
                      item={item}
                      render={render}
                      selectedIds={selectedIds}
                      remove={remove}
                      push={push}
                      disabled={disabled}
                      getItemId={getItemId}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  )
}
