import Box from '@mui/material/Box'
import COLORS from '../../../lib/colors'

export default function OutletContainer({ children }) {
  return (
    <Box sx={{ flex: 1, display: 'flex', minWidth: 0 }}>
      <Box
        sx={{
          minWidth: 0,
          height: '100%',
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: COLORS.backgroundGray,
        }}
      >
        <Box
          component="main"
          sx={{
            minHeight: 0,
            flexGrow: 1,
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
