import { Box } from "@mui/material";
import COLORS from '../../../lib/colors'

export default function GrayHeaderBox({ title, sx={}, children }) {
  return (
    <Box
      sx={{ borderRadius: '10px',
            border: `solid 1px ${COLORS.slateGray}`,
            ...sx
          }}
    >
      <Box
        sx={{ borderRadius: '10px 10px 0px 0px',
              backgroundColor: COLORS.aliceBlue,
              height: '47px',
              fontSize: '16px',
              fontWeight: '600',
              verticalAlign: 'middle',
              lineHeight: '47px',
              paddingLeft: '18px'
            }}
      >{title}</Box>
      {children}
    </Box>
  )
}
