import { useState } from "react"
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Box, IconButton, Popover, Typography } from "@mui/material"
import COLORS from '../../../lib/colors'


export default function SubsectionTitle({ title, note = null, controls = null, dragHandleProps = null, sx = {} }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box sx={{ height: 24, display: 'flex', alignItems: 'center', ...sx }}>
      {dragHandleProps &&
        <Box
          sx={{ display: 'flex' }}
          {...dragHandleProps}
        >
          <DragHandleIcon sx={{ color: COLORS.slateGray, mr: '6px' }} />
        </Box>
      }
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant="h3">
          {title}
        </Typography>
        {note &&
          <Box sx={{color: 'text.secondary', fontSize:'14px', pl:'12px'}}>
            {note}
          </Box>
        }
      </Box>
      {controls && controls.map(({icon, onClick, label, popoverContent}, index) =>
        <Box key={`subsection_title_control_${index}`} sx={{pl:'10px', alignSelf: 'self-end'}}>
          <IconButton onClick={onClick} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} aria-label={label} color='secondary' key={`${label}${index}`}>
            {icon}
          </IconButton>
          {popoverContent &&
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {popoverContent}
            </Popover>
          }
        </Box>
      )}
    </Box>
  )
}
