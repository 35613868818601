import SvgIcon from "@mui/material/SvgIcon"

export default function DoesNotContainIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
              <path id="d9ap5hubxa" d="M0 0h20v18H0z"/>
          </defs>
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <g transform="translate(2 3)">
                  <mask id="dv2mof3kbb" fill="#fff">
                      <use xlinkHref="#d9ap5hubxa"/>
                  </mask>
                  <rect stroke="currentColor" strokeWidth="1.35" mask="url(#dv2mof3kbb)" x=".675" y="1.675" width="18.65" height="14.65" rx="3"/>
                  <g mask="url(#dv2mof3kbb)" fill="currentColor" fillRule="nonzero">
                      <path d="M4.008 13.096c-.28 0-.48-.096-.6-.288-.12-.192-.116-.424.012-.696l3.168-7.02c.104-.232.23-.398.378-.498a.89.89 0 0 1 .51-.15c.184 0 .35.05.498.15.148.1.274.266.378.498l3.18 7.02c.128.28.136.514.024.702-.112.188-.304.282-.576.282-.224 0-.398-.054-.522-.162-.124-.108-.234-.274-.33-.498l-.6-1.392H5.4l-.588 1.392c-.104.232-.212.4-.324.504-.112.104-.272.156-.48.156zm3.444-6.888L5.928 9.82H9L7.476 6.208h-.024zM12.544 13c-.208 0-.364-.066-.468-.198a.647.647 0 0 1-.132-.474c.016-.184.1-.36.252-.528l2.82-3.54h-2.544c-.384 0-.576-.188-.576-.564 0-.376.192-.564.576-.564h3.552c.24 0 .418.064.534.192a.6.6 0 0 1 .156.462c-.012.18-.094.354-.246.522l-2.856 3.564H16.3c.384 0 .576.188.576.564 0 .376-.192.564-.576.564h-3.756z"/>
                  </g>
              </g>
              <path fill="currentColor" d="m20.427 4.988.794 1.092L3.423 19.012l-.794-1.092z"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
