import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Moment from 'react-moment';
import { Link, useNavigate } from "react-router-dom";
import SimpleTable from '../../SimpleTable'
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { getRemoteId } from '../../../lib/DataModel'
import MainContainer from 'components/layout/MainContainer/MainContainer';
import { useNotifications } from 'lib/NotificationsProvider';
import { useOrbit } from 'providers/OrbitProvider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

// TODO: allow SweetTable to take a per-row click handler

const CLICK_HANDLER = (_event, client, {navigate}) => navigate(`/clients/${getRemoteId(client)}`)

const COLUMNS = [
  { title: 'Client name', value: client => client.attributes.internalName || client.attributes.name, click: CLICK_HANDLER, visible: true },
  { title: 'AOR', align: 'center', value: client => !!client.attributes.aor, render: value => value ? <DoneIcon/> : null, click: CLICK_HANDLER, visible: true },
  { title: '# of programs', value: client => client.attributes.numPrograms, click: CLICK_HANDLER, visible: true },
  { title: 'Created', value: client => client.attributes.createdAt, render: value => <Moment local format="LLLL">{value}</Moment>, click: CLICK_HANDLER, visible: true },
]

export default function ClientTable() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { remote } = useOrbit()
  const { addNotification } = useNotifications()
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    remote.query(q => q.findRecords("client")).then((clients) => {
      setClients(clients)
      setLoading(false);
    }).catch(error => {
      console.log(error);
      addNotification({variant: 'alert', message: 'Error loading client list'})
      setLoading(false);
    })
  }, [addNotification, remote]);

  if(loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    return (
      <MainContainer>
        <Paper className={classes.root}>
          <Toolbar>
            <Typography variant="h6">
              Clients
            </Typography>
          </Toolbar>

          <SimpleTable
            className={classes.table}
            columns={COLUMNS}
            data={clients}
            options={{
              hover: true,
              eventContext: {navigate},
            }}
          />
          <div style={{height: '56px'}}/>
        </Paper>
        <Link to="/clients/new" style={{display: 'flex', justifyContent: 'center', marginTop: '-28px'}}>
          <Fab color="primary" aria-label="add" className={classes.fab}>
            <AddIcon />
          </Fab>
        </Link>
      </MainContainer>
    );
  }
}
