import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Box from '@mui/material/Box'
import COLORS from 'lib/colors'
import MultiStepHeader from '../../../../UI/MultiStepModal/MultiStepHeader'

export default function DialogContentWithBookends({
  onClose = null,
  showBackButton = false,
  onClickBackButton = null,
  modalTitle,
  modalSubTitle,
  buttonText,
  onCancel = null,
  isSubmitting = false,
  onClickSelectButton,
  disabled = false,
  warningText = null,
  hasErrors = false,
  dialogContentSx = null,
  children,
}) {

  return (
    <>
      <MultiStepHeader
        modalTitle={modalTitle}
        onClose={onClose}
        showBackButton={showBackButton}
        onClickBackButton={onClickBackButton}
      />

      <Box
        sx={{color: hasErrors ? COLORS.copperRed : COLORS.charcoal,
            fontSize: '18px',
            pl: 3,
            pr: 3,
        }}
      >
        {modalSubTitle}
      </Box>
      <Divider light
        sx={{ml: 3,
          mr: 3,
          mt: '18px',
          mb: '18px',
        }}
      />

      <DialogContent sx={{...dialogContentSx, py: 1}}>
        {children}
      </DialogContent>

      {warningText && (
        <Box
          sx={{ fontSize: '16px',
                fontWeight: '600',
                color: COLORS.goldenrod,
                display: 'flex',
                alignItems: 'center',
            }}
        >
          <WarningAmberIcon sx={{ margin: '0px 3px 0px 21px' }}/>
          {warningText}
        </Box>
      )}

      <Divider light sx={{margin: '24px 24px 18px 10px' }} />

      <DialogActions sx={{marginBottom: '10px', paddingRight: '24px'}}>
        <Button
          disableElevation={true}
          variant="text"
          color='secondary'
          onClick={onCancel}
          disabled={isSubmitting}
        >Cancel</Button>
        <Button
          disableElevation
          type="submit"
          aria-label={buttonText}
          onClick={onClickSelectButton}
          disabled={isSubmitting || disabled}>
          {isSubmitting ? 'Please wait...' : buttonText}
        </Button>
      </DialogActions>
    </>
  )
}
