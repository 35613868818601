import { timezoneContext } from "lib/TimezoneProvider"
import DatePickerPopover, { DatePickerPopoverProps, TimeRange } from "./DatePickerPopover"
import { userEvent, within, screen, expect, fn } from '@storybook/test';
import { expectCalledOnceAndClear } from 'test_support/expectations'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useRef, useState } from 'react';

export default {
  title: "Components/DatePickerPopover",
  component: DatePickerPopover,
};

type StoryArgs = Omit<DatePickerPopoverProps, "open"> & {
  timezone: string,
  extra: React.FC<{ setValue: React.Dispatch<React.SetStateAction<TimeRange>> }>
}

const Template = ({ timezone, extra: ExtraComponent, value: valueFromArgs, ...props }: StoryArgs) => {
  const [value, setValue] = useState(valueFromArgs)
  const ref = useRef(null)

  return (
    <Box sx={{ minHeight: 500 }}>
      <Box ref={ref}/>
      {ref.current && (
        <timezoneContext.Provider value={{ timezone: timezone, setTimezone: () => { return } }}>
          {ExtraComponent && <ExtraComponent setValue={setValue}/>}
          <DatePickerPopover
            open
            container={ref.current}
            hideBackdrop
            value={value}
            slotProps={{
              root: {
                sx: {
                  position: 'static',
                },
              },
            }}
            {...props}
          />
        </timezoneContext.Provider>
      )}
    </Box>
  );
};

// function getAndClearEvents

export const Default = {
  render: Template,
  args: {
    value: { label: 'Lifetime' },
    timezone: 'America/Los_Angeles',
    timeInputsEnabled: true,
    onChange: fn(),
    onClose: fn(),
  },
  parameters: {
    mockTime: {
      time: '2024-02-03T07:00:00Z',
    },
  },
  play: async ({canvasElement}) => {
    const canvas = within(canvasElement);
    expect(canvas.queryByLabelText('Toggle Time Fields')).toBeInTheDocument()
    expect(canvas.queryByLabelText('Toggle Time Fields')).toBeDisabled()
    expect(canvas.queryByLabelText('Toggle Time Fields')).toHaveAttribute('aria-pressed', 'false')
    expect(canvas.queryByLabelText('Start Time')).not.toBeInTheDocument()
    expect(canvas.queryByLabelText('End Time')).not.toBeInTheDocument()
  },
}

export const WithoutTime = {
  ...Default,
  args: {
    ...Default.args,
    timeInputsEnabled: false,
  },
  play: async ({canvasElement}) => {
    const canvas = within(canvasElement);
    expect(canvas.queryByLabelText('Toggle Time Fields')).not.toBeInTheDocument()
    expect(canvas.queryByLabelText('Start Time')).not.toBeInTheDocument()
    expect(canvas.queryByLabelText('End Time')).not.toBeInTheDocument()
  },
}

export const ResetValue = {
  ...Default,
  args: {
    ...Default.args,
    extra: ({ setValue }) => {
      return (
        <Box sx={{ ml: '700px' }}>
          <Button onClick={() => setValue({ label: 'Yesterday' })}>Reset</Button>
        </Box>
      )
    },
  },
  play: async ({args, canvasElement}) => {
    const canvas = within(canvasElement);
    expect(await canvas.findByLabelText('Start Date')).toHaveValue('')
    expect(await canvas.findByLabelText('End Date')).toHaveValue('')

    await userEvent.click(await canvas.findByLabelText('Date Range'))
    await userEvent.click(await screen.findByText('Last Year'))

    expectCalledOnceAndClear(args.onChange, { label: 'Last Year' })
    expectCalledOnceAndClear(args.onClose),

    expect(await canvas.findByLabelText('Start Date')).toHaveValue('01/01/2023')
    expect(await canvas.findByLabelText('End Date')).toHaveValue('12/31/2023')

    await userEvent.click(await screen.findByText('Reset'))
    expect(await canvas.findByLabelText('Date Range')).toHaveTextContent('Yesterday')
    expect(await canvas.findByLabelText('Start Date')).toHaveValue('02/01/2024')
    expect(await canvas.findByLabelText('End Date')).toHaveValue('02/01/2024')

    expect(args.onChange).not.toHaveBeenCalled()
    expect(args.onClose).not.toHaveBeenCalled()
  }
}

export const Presets = {
  ...Default,
  play: async ({args, canvasElement}) => {
    const canvas = within(canvasElement);
    expect(await canvas.findByLabelText('Start Date')).toHaveValue('')
    expect(await canvas.findByLabelText('End Date')).toHaveValue('')

    const tests = [
      { label: 'Today', expectedDateRange: ['02/02/2024', '02/02/2024'] },
      { label: 'Yesterday', expectedDateRange: ['02/01/2024', '02/01/2024'] },
      { label: 'This Week', expectedDateRange: ['01/28/2024', '02/02/2024'] },
      { label: 'Last Week', expectedDateRange: ['01/21/2024', '01/27/2024'] },
      { label: 'This Month', expectedDateRange: ['02/01/2024', '02/02/2024'] },
    ]

    for await (const { label, expectedDateRange } of tests) {
      await userEvent.click(await canvas.findByLabelText('Date Range'))
      await userEvent.click(await screen.findByText(label))

      expectCalledOnceAndClear(args.onChange, { label: label })
      expectCalledOnceAndClear(args.onClose)

      expect(await canvas.findByLabelText('Start Date')).toHaveValue(expectedDateRange[0])
      expect(await canvas.findByLabelText('End Date')).toHaveValue(expectedDateRange[1])
    }
  }
}

export const TimeRangeHandling = {
  ...Default,
  play: async ({args, canvasElement, step}) => {
    const canvas = within(canvasElement);

    // Time selector is disabled when date range is Lifetime
    await step('Initial state assertions', async() => {
      expect(await canvas.findByLabelText('Toggle Time Fields')).toBeDisabled()
      expect(await canvas.findByLabelText('Toggle Time Fields')).toHaveAttribute('aria-pressed', 'false')
      expect(canvas.queryByLabelText('Start Time')).not.toBeInTheDocument()
      expect(canvas.queryByLabelText('End Time')).not.toBeInTheDocument()
    })

    await step('Set date range', async() => {
      await userEvent.click(await canvas.findByLabelText('Date Range'))
      await userEvent.click(await screen.findByText('This Month'))

      expectCalledOnceAndClear(args.onChange, { label: 'This Month' })
      expectCalledOnceAndClear(args.onClose)

      expect(await canvas.findByLabelText('Start Date')).toHaveValue('02/01/2024')
      expect(await canvas.findByLabelText('End Date')).toHaveValue('02/02/2024')

      expect(await canvas.findByLabelText('Toggle Time Fields')).not.toBeDisabled()
      expect(await canvas.findByLabelText('Toggle Time Fields')).toHaveAttribute('aria-pressed', 'false')

      expect(canvas.queryByLabelText('Start Time')).not.toBeInTheDocument()
      expect(canvas.queryByLabelText('End Time')).not.toBeInTheDocument()
    })

    await step('Set time range', async() => {
      await userEvent.click(await canvas.findByLabelText('Toggle Time Fields'))

      expect(await canvas.findByLabelText('Toggle Time Fields')).toHaveAttribute('aria-pressed', 'true')
      expect(canvas.queryByLabelText('Start Time')).toBeInTheDocument()
      expect(canvas.queryByLabelText('End Time')).toBeInTheDocument()

      expect(await canvas.findByLabelText('Start Time')).toHaveTextContent('12 AM')
      expect(await canvas.findByLabelText('End Time')).toHaveTextContent('Midnight')

      expect(await canvas.findByLabelText('Date Range')).toHaveTextContent('This Month')

      await userEvent.click(await canvas.findByLabelText('Start Time'))
      await userEvent.click(await screen.findByText('3 AM'))

      expect(await canvas.findByLabelText('Date Range')).toHaveTextContent('Custom')

      expect(await canvas.findByLabelText('Start Date')).toHaveValue('02/01/2024')
      expect(await canvas.findByLabelText('End Date')).toHaveValue('02/02/2024')
      expect(await canvas.findByLabelText('Start Time')).toHaveTextContent('3 AM')
      expect(await canvas.findByLabelText('End Time')).toHaveTextContent('Midnight')

      expect(args.onChange).not.toHaveBeenCalled()
      expect(args.onClose).not.toHaveBeenCalled()

      // "Close" the popover to trigger a change event
      await userEvent.keyboard('{Esc}')

      expectCalledOnceAndClear(args.onClose)
      expect(args.onChange).toHaveBeenCalledOnce()
      expect(args.onChange.mock.lastCall[0].timeRange[0].toISOString()).toEqual('2024-02-01T11:00:00.000Z')
      expect(args.onChange.mock.lastCall[0].timeRange[1].toISOString()).toEqual('2024-02-03T07:59:59.999Z')
      args.onChange.mockClear()
    })
  }
}

export const ResetTimeRange = {
  ...ResetValue,
  args: {
    ...ResetValue.args,
    value: { label: 'This Month' },
  },
  play: async ({args, canvasElement, step}) => {
    const canvas = within(canvasElement);

    await step('Set to custom time range', async() => {
      expect(await canvas.findByLabelText('Date Range')).toHaveTextContent('This Month')

      await userEvent.click(await canvas.findByLabelText('Toggle Time Fields'))
      await userEvent.click(await canvas.findByLabelText('Start Time'))
      await userEvent.click(await screen.findByText('3 AM'))
      await userEvent.keyboard('{Esc}')

      expectCalledOnceAndClear(args.onClose)
      expect(args.onChange).toHaveBeenCalledOnce()
      expect(args.onChange.mock.lastCall[0].timeRange[0].toISOString()).toEqual('2024-02-01T11:00:00.000Z')
      expect(args.onChange.mock.lastCall[0].timeRange[1].toISOString()).toEqual('2024-02-03T07:59:59.999Z')
      args.onChange.mockClear()
    })

    await step('Reset to Yesterday', async() => {
      await userEvent.click(await screen.findByText('Reset'))
      expect(await canvas.findByLabelText('Date Range')).toHaveTextContent('Yesterday')
      expect(await canvas.findByLabelText('Start Date')).toHaveValue('02/01/2024')
      expect(await canvas.findByLabelText('End Date')).toHaveValue('02/01/2024')

      expect(args.onChange).not.toHaveBeenCalled()
      expect(args.onClose).not.toHaveBeenCalled()

      expect(canvas.queryByLabelText('Toggle Time Fields')).toBeInTheDocument()
      expect(canvas.queryByLabelText('Toggle Time Fields')).not.toBeDisabled()
      expect(canvas.queryByLabelText('Toggle Time Fields')).toHaveAttribute('aria-pressed', 'false')
      expect(canvas.queryByLabelText('Start Time')).not.toBeInTheDocument()
      expect(canvas.queryByLabelText('End Time')).not.toBeInTheDocument()
    })
  }
}
