import DropdownCheckbox from 'components/UI/DropdownCheckbox/DropdownCheckbox'
import { useLandingPageStateContext } from '../../LandingPageProvider'
import { PersistedErrorHandler } from '../../../../../../components/Formik/forms'

export default function ClientSelector({ selected, setSelected, path = '' }) {
  const { clients } = useLandingPageStateContext()

  return (
    <PersistedErrorHandler name={path}>
      <DropdownCheckbox
        collection={clients}
        selected={selected}
        setSelected={setSelected}
        renderItem={(item) => item.internalName || item.name}
        label='Clients'
        InputLabelProps={{ shrink: true }}
        placeholder="Select clients"
      />
    </PersistedErrorHandler>
  )
}
