import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import COLORS from 'lib/colors'
import { spliceCopy } from 'lib/utils'
import { useCallback, useMemo } from 'react'
import RadioButton from '../RadioButton'


function RadioGroupSection({ fieldMapEntry, setFieldMapEntry, client, displayedOptionIndex, linkErrorsByClientId }) {
  const field = useMemo(() => client.fields.find(field => field.name === fieldMapEntry.field), [client.fields, fieldMapEntry.field])
  const hasError = useMemo(() => linkErrorsByClientId[displayedOptionIndex] && linkErrorsByClientId[displayedOptionIndex].includes(client.id),[client.id, linkErrorsByClientId, displayedOptionIndex] )

  const handleChange = useCallback((event) => {
    const newValues = spliceCopy(fieldMapEntry.values, displayedOptionIndex, 1, event.target.value)
    setFieldMapEntry({ clientId: client.id, field: fieldMapEntry.field, values: newValues})
  }, [client.id, fieldMapEntry.field, fieldMapEntry.values, displayedOptionIndex, setFieldMapEntry])

  return (
    <Box
      role='radiogroup'
      aria-label={client.name}
      sx={{ backgroundColor: hasError ? COLORS.seashell : null,
            mb: '5px',
            px: '18px',
            display: 'flex',
            flexDirection: 'column',
         }}
    >
      <Box
        sx={{ fontSize: '16px',
              fontWeight: '600',
              color: hasError ? COLORS.copperRed : COLORS.charcoal,
              marginBottom: '3px'
           }}
      >{client.internalName || client.name}</Box>
      {field?.options && field.options.map((fieldOption) =>  (
         <RadioButton
          key={`fieldOption_${client.id}_${fieldOption.text}_${fieldOption.value}`}
          value={fieldOption.value}
          checked={fieldMapEntry.values[displayedOptionIndex] === fieldOption.value}
          onChange={handleChange}
          label={fieldOption.text}
          error={hasError}
        />
      ))}
    </Box>
  )
}

const FieldMapEntry = function({ fieldMapping, setFieldMapping, clientFieldGroups, index, displayedOptionIndex, linkErrorsByClientId }) {
  const entry = fieldMapping[index]
  const setFieldMapEntry = useCallback(newFieldMapEntry => {
    setFieldMapping(spliceCopy(fieldMapping, index, 1, newFieldMapEntry))
  }, [fieldMapping, index, setFieldMapping])
  const client = useMemo(() => clientFieldGroups.find(c => c.client.id === entry.clientId)?.client || null, [clientFieldGroups, entry.clientId])

  return client && (
    <RadioGroupSection
      fieldMapEntry={entry}
      setFieldMapEntry={setFieldMapEntry}
      client={client}
      displayedOptionIndex={displayedOptionIndex}
      linkErrorsByClientId={linkErrorsByClientId}
    />
  )
}

export default function MultiGroupSelect({ clientFieldGroups, fieldMapping, setFieldMapping, displayedOptionIndex, linkErrorsByClientId, sx }) {

  return (
    <div>
      <FormControl sx={{ ...sx }}>
        {fieldMapping.map((_fieldMapEntry, fieldMapIndex) => (
          <FieldMapEntry
            key={fieldMapIndex}
            fieldMapping={fieldMapping}
            setFieldMapping={setFieldMapping}
            clientFieldGroups={clientFieldGroups}
            index={fieldMapIndex}
            displayedOptionIndex={displayedOptionIndex}
            linkErrorsByClientId={linkErrorsByClientId}
          />
        ))}
      </FormControl>
    </div>
  )
}
