import { Formik, Form as FormikForm } from "formik";
import { userEvent, within } from "@storybook/test";
import ClientAndProgramRules from "./ClientAndProgramRules";
import SampleLandingPage from "test_support/data/SampleLandingPage";
import LandingPageProvider from "../../LandingPageProvider";

export default {
  title: "Composites/ClientAndProgramRules",
  component: ClientAndProgramRules,
};

const Template = (args, { parameters }) => {
  const landingPage = {
    ...SampleLandingPage,
    implicitLandingPageRules: parameters.updatedImplicitLandingPageRules,
  };

  return (
    <LandingPageProvider initialState={landingPage} isRefetching={false}>
      <Formik initialValues={landingPage}>
        <FormikForm autoComplete="off">
          <ClientAndProgramRules {...args} />
        </FormikForm>
      </Formik>
    </LandingPageProvider>
  );
};

export const Default = Template.bind({});
Default.args = {};
Default.parameters = {
  updatedImplicitLandingPageRules: [
    ...SampleLandingPage.implicitLandingPageRules,
  ],
};

export const EmptyState = Template.bind({});
EmptyState.args = {};
EmptyState.parameters = {
  updatedImplicitLandingPageRules: [],
};

export const MergedClientsAndPrograms = Template.bind({});
MergedClientsAndPrograms.args = {};
MergedClientsAndPrograms.parameters = {
  updatedImplicitLandingPageRules: [
    {
      id: "1",
      questionId: "8521",
      operator: "in",
      value: ["Yes"],
      exclusionRule: {
        id: "815",
        appliesTo: {
          __typename: "DegreeProgram",
          id: "643",
        },
      },
    },
  ],
};

MergedClientsAndPrograms.play = async ({ canvasElement }) => {
  const alertBadge = await within(canvasElement).findByTestId("SchoolIcon");

  await userEvent.pointer({ target: alertBadge, coords: { clientX: 10, clientY: 10 }});
};
