import { useCallback, useMemo, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import GlobeIcon from '@mui/icons-material/Public'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'

import { useTimezone } from '../lib/TimezoneProvider'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 24,
    display: 'flex',
    lineHeight: 1,
    height: 36,
  },
  menuSection: {
    display: 'flex',
    fontSize: 12,
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuIcon: {
    width: 30,
    height: 30,
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    width: 30,
    height: 30,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.action.active,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
  menuButton: {
    border: 'none',
    background: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    fontSize: 12,
    height: '18px',
  },
  collapsedMenuButton: {
  },
  sidebarIcon: {
    margin: '0 auto',
    display: 'block',
  },
}))

const TIMEZONE_OPTIONS = [
  { tz: 'America/New_York',    name: 'Eastern Time (US & Canada)' },
  { tz: 'America/Chicago',     name: 'Central Time (US & Canada)' },
  { tz: 'America/Denver',      name: 'Mountain Time (US & Canada)' },
  { tz: 'America/Phoenix',     name: 'Arizona' },
  { tz: 'America/Los_Angeles', name: 'Pacific Time (US & Canada)' },
  { tz: 'UTC',                 name: 'UTC' },
]

function TimezoneMenuItem({tz, name, selected, setTimezone, handleClose}) {
  const handleClick = useCallback(() => {
    setTimezone(tz)
    handleClose()
  }, [setTimezone, handleClose, tz])

  return (
    <MenuItem value={tz} selected={selected} onClick={handleClick}>{name}</MenuItem>
  )
}

export default function TimezoneSelector({isExpanded}) {
  const classes = useStyles()
  const { timezone, setTimezone } = useTimezone()
  const timezoneName = useMemo(() => (TIMEZONE_OPTIONS.find(tzo => tzo.tz === timezone) || {name: timezone}).name, [timezone])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.menuIcon}>
          {isExpanded && (
            <GlobeIcon className={classes.sidebarIcon} />
          ) || (
            <Tooltip title={'Timezone'}>
              <IconButton
                className={classes.iconButton}
                aria-label="Select Timezone"
                onClick={handleClick}
              >
                <GlobeIcon className={classes.sidebarIcon} />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <Collapse orientation="horizontal" in={isExpanded}>
          <div className={classes.menuSection}>
            <div>Timezone:</div>
            <Button
              className={classes.menuButton}
              aria-label="Select Timezone"
              onClick={handleClick}
              variant="text"
            >
                {timezoneName}
            </Button>
          </div>
        </Collapse>
      </div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {TIMEZONE_OPTIONS.map(({tz, name}) => (
          <TimezoneMenuItem
            key={tz}
            tz={tz}
            selected={tz === timezone}
            name={name}
            setTimezone={setTimezone}
            handleClose={handleClose}
          />
        ))}
      </Menu>
    </>
  )
}
