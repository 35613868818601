import React from "react";

import BuildPageIcon from "./";

export default {
  title: "UI/icons/BuildPageIcon",
  component: BuildPageIcon,
};

const Template = (args) => <BuildPageIcon {...args} />;
export const Default = Template.bind({});
Default.args = {
  sx: { color: "text.secondary" },
};
export const Selected = Template.bind({});
Selected.args = {
  sx: { color: "primary.main" },
};
