import CircularProgress from '@mui/material/CircularProgress'
import useStyles from './styles'

import { useDataManager } from './DataManager'

export default function TableContentLoading() {
  const dataManager = useDataManager()
  const classes = useStyles()

  return dataManager.loading && (
    <tbody className={classes.overlay}>
      <tr>
        <td>
          <CircularProgress/>
        </td>
      </tr>
    </tbody>
  )
}
