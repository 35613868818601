import SvgIcon from "@mui/material/SvgIcon"

export default function GreaterThanIcon() {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="12"/>
              <path d="M7.031 16.65v-1.477l6.41-3.246-6.41-3.175V7.357l9.293 4.57z" fill="currentColor" fillRule="nonzero"/>
          </g>
      </svg>
    </SvgIcon>
  )
}
