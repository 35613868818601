import { useCallback, useEffect, useMemo, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import Checkbox from '@mui/material/Checkbox'
import Drawer from '@mui/material/Drawer'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Slider from '@mui/material/Slider'

import DatePickerInput from '../DatePickerInput'
import { useTimezone } from '../../lib/TimezoneProvider'

import Suspenseful from '../Suspenseful'
import StateStatsResource from '../../resources/StateStatsResource'
import { sortBy, titlecase, toSentence, humanize } from '../../lib/utils'

import { userIsGlobalAdmin } from '../../lib/auth-helpers'
import useClientMetadata from '../../hooks/useClientMetadata'
import { getClientFunnelStatuses } from 'components/PerformanceTable/utils'

import Plotly from 'plotly.js-geo-dist-min'

// customizable method: use your own `Plotly` object
import createPlotlyComponent from 'react-plotly.js/factory';
import Stack from '@mui/material/Stack';
import { useOrbit } from 'providers/OrbitProvider';
import { useCurrentUser } from 'lib/CurrentUserProvider';
import { getAllAccessibleClientIds } from 'components/PerformanceTable/PerformanceTable';
import COLORS from 'lib/colors';
import { convertToAbsoluteTimeRange } from 'components/DatePickerPopover';
const Plot = createPlotlyComponent(Plotly);

const DRAWER_WIDTH = 240

const useStyles = makeStyles(theme => ({
  selectors: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  slider: {
    width: 900,
    margin: theme.spacing(0, 4, 2, 4),
  },
  table: {
    marginRight: DRAWER_WIDTH,
  },
  chartSettings: {
    padding: theme.spacing(0, 2),
    paddingTop: 64,
    '& h1': {
      fontSize: 24,
      marginBottom: 0,
      fontWeight: 400,
    },
    '& h2': {
      marginBottom: 0,
      fontSize: 18,
    },
    width: DRAWER_WIDTH,
  },
}))

function HeatMap({stateStatsResource, includeDownstreamStatuses, funnelStatuses, allStatuses, downstreamStatuses, range}) {
  const stateStats = stateStatsResource.get()
  const stateData = useMemo(() => {
    const result = {}
    stateStats.forEach(stateStat => {
      if(stateStat.attributes.state) {
        const { state, status, count } = stateStat.attributes
        if(!result[state]) {
          result[state] = []
        }
        result[state].push({state, status, count})
      }
    })
    return result
  }, [stateStats])

  const stateHeat = useMemo(() => (
    Object.fromEntries(Object.entries(stateData).map(([state, stats]) => {
      const total = stats
        .filter(({status}) => funnelStatuses.findIndex(statusObj => statusObj.status === status) >= funnelStatuses.indexOf(range[0]))
        .reduce((sum, {count}) => sum + count, 0)
      const passed = stats
        .filter(({status}) => status === range[1].status || (includeDownstreamStatuses && downstreamStatuses.some(statusObj => statusObj.status === status)))
        .reduce((sum, {count}) => sum + count, 0)
      return [state, 100 * passed / total]
    }))
  ), [downstreamStatuses, funnelStatuses, includeDownstreamStatuses, range, stateData])

  const zmax = Math.min(100, Math.max(...Object.values(stateHeat), 5))

  const stateLabels = useMemo(() => (
    Object.entries(stateHeat).map(([state, val]) => {
      const percent = Math.round(val*100)/100
      const totalCount = stateData[state].reduce((sum, {count}) => sum + count, 0)
      const statusCounts = allStatuses.map(({status, statusTitle}) => {
        const count = stateData[state].filter(({status: itemStatus}) => itemStatus === status).reduce((sum, {count}) => sum + count, 0)
        if(count === 0) {
          return null
        }
        return `${count} ${statusTitle}`
      }).filter(Boolean)
      return `${state}: ${percent}% ${range[1].statusTitle}<br>${totalCount} leads<br>${statusCounts.join(', ')}`
    })
  ), [allStatuses, range, stateData, stateHeat])

  return (
    <Plot
      data={[{
        type: 'choropleth',
          locationmode: 'USA-states',
          locations: Object.keys(stateHeat),
          z: Object.values(stateHeat),
          hoverinfo: 'text',
          text: stateLabels,
          zmin: 0,
          zmax: zmax,
          colorscale: [
            [0, 'rgb(255,255,255)'], [1, 'rgb(12, 35, 75)']
          ],
          colorbar: {
            title: `${range[1].statusTitle} (%)`,
              thickness: 0.2
          },
          marker: {
            line:{
              color: 'rgb(255,255,255)',
                width: 2
            }
          }
      }]}
      layout={ {
        geo: {
          scope: 'usa',
          countrycolor: 'rgb(255,255,255)',
          showland: true,
          landcolor: 'rgb(217, 217, 217)',
          showlakes: false,
        },
        autosize: true,
      } }
      config={{
        displayModeBar: false,
        responsive: true,
      }}
      style={{
        width: '100%',
        minHeight: '35vw',
      }}
      useResizeHandler={true}
    />
  )
}

const FunnelSlider = withStyles({
  mark: {
    backgroundColor: '#888',
    height: 6,
    width: 6,
    borderRadius: 8,
    marginTop: 0,
    marginLeft: -3,
  },
  markLabel: {
    whiteSpace: 'break-spaces',
    maxWidth: 80,
    textAlign: 'center',
  },
  markActive: {
    backgroundColor: COLORS.frenchBlue,
    marginTop: -10,
    width: 0,
    height: 0,
    fontSize: 14,
    '&::before': {
      content: '"▶"',
    },
    opacity: 1,
  },
  rail: {
    backgroundColor: '#888',
    height: 1,
  },
  track: {
    height: 1,
  },
  root: {
    color: COLORS.frenchBlue,
  },
})(Slider)

const FunnelCheckbox = withStyles({
  root: {
    marginLeft: 20,
    color: COLORS.frenchBlue,
    '&$checked': {
      color: COLORS.frenchBlue,
    },
  },
  checked: {},
})(Checkbox)

function ChartSettings({dateRange, setDateRange}) {
  return (
    <>
      <h1>Chart Settings</h1>
      <h2>Date range</h2>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <DatePickerInput
          value={dateRange}
          onChange={(dateRange) => setDateRange(dateRange)}
          timeInputsEnabled
        />
      </Stack>
    </>
  )
}

function ClientSelector({onChange}) {
  const { remote } = useOrbit()
  const [loading, setLoading] = useState(true)
  const [clients, setClients] = useState([])

  const getClientLabel = useCallback(client => client.attributes.internalName || client.attributes.name, [])

  useState(() => {
    remote.query(q => q.findRecords('client'))
      .then(clients => {
        setClients(sortBy(clients, getClientLabel))
      })
      .catch(_error => {
      })
      .finally(() => {
        setLoading(false)
      })
  })

  const renderInput = useCallback(props => (
    <TextField {...props} label="Client"/>
  ), [])

  const handleChange = useCallback((event, value) => {
    onChange(value)
  }, [onChange])

  return loading ? (
    'Loading'
  ) : (
    <Autocomplete
      options={clients}
      getOptionLabel={getClientLabel}
      renderInput={renderInput}
      onChange={handleChange}
    />
  )
}

export default function PerformanceCharts({dateRange, setDateRange}) {
  const { currentUser, accessibleClients, accessibleVendorContracts } = useCurrentUser()
  const classes = useStyles()
  const { remote } = useOrbit()
  const { timezone } = useTimezone()
  const allAccessibleClientIds = getAllAccessibleClientIds(accessibleClients, accessibleVendorContracts)
  const userHasMultipleClients = allAccessibleClientIds.length > 1
  const [clientId, setClientId] = useState(() => !userHasMultipleClients ? allAccessibleClientIds[0] : null)
  const clientMetadata = useClientMetadata(clientId)
  const [includeDownstreamStatuses, setincludeDownstreamStatuses] = useState(true)
  const funnelStatuses = useMemo(() => getClientFunnelStatuses(clientMetadata), [clientMetadata])
  const allStatuses = useMemo(() => [
    ...funnelStatuses,
    ...Object.entries(clientMetadata.statuses)
      .filter(([status, _]) => !funnelStatuses.some(statusObj => statusObj.status === status))
      .map(([status, statusTitle]) => ({ status, statusTitle }))
  ], [clientMetadata.statuses, funnelStatuses])
  const [range, setRange] = useState(() => [
    funnelStatuses.find(({status}) => status === 'new_lead') || funnelStatuses[0],
    funnelStatuses.find(({status}) => status === 'applied') || funnelStatuses[funnelStatuses.length-1],
  ])

  const [stateStatsResource, setStateStatsResource] = useState(null)

  const downstreamStatuses = funnelStatuses.filter((_, index) => index > funnelStatuses.indexOf(range[1]))

  const isGlobalAdmin = userIsGlobalAdmin({currentUser})

  useEffect(() => {
    if(!clientId) {
      setStateStatsResource(null)
      return
    }

    setStateStatsResource(new StateStatsResource({ remote, dateRange: convertToAbsoluteTimeRange(dateRange, timezone), clientId }))
  }, [dateRange, timezone, clientId, isGlobalAdmin, remote])

  // Whenever funnelStatuses array changes, ensure that range contains objects that are present in funnelStatuses
  useEffect(() => {
    setRange(oldRange => [
      funnelStatuses.find(({status}) => status === oldRange[0].status) || funnelStatuses[0],
      funnelStatuses.find(({status}) => status === oldRange[1].status) || funnelStatuses[funnelStatuses.length-1],
    ])
  }, [funnelStatuses])

  return (
    <>
      <div className={classes.table}>
        <Grid container direction="column">
          <Grid item>
            <div className={classes.slider}>
              <FunnelSlider
                value={range.map(statusObj => funnelStatuses.indexOf(statusObj))}
                onChange={(event, /** @type number[] */newValue) => setRange(newValue.map(i => funnelStatuses[i]))}
                getAriaLabel={i => funnelStatuses[i]?.statusTitle}
                marks={funnelStatuses.map((statusObj, index) => (
                  {
                    value: index,
                    label: (
                      <span style={{textDecoration: downstreamStatuses.includes(statusObj) && !includeDownstreamStatuses ? 'line-through' : 'none'}}>{titlecase(statusObj.statusTitle)}</span>
                    )
                  }
                ))}
                min={0}
                max={funnelStatuses.length-1}
              />
            </div>
            <div className={classes.selectors}>
              {downstreamStatuses.length > 0 && (
                <FormControlLabel
                  label={`Include down-funnel (${toSentence(downstreamStatuses.map(({statusTitle}) => humanize(statusTitle)), 'and')}) leads`}
                  control={
                    <FunnelCheckbox
                      checked={includeDownstreamStatuses}
                      onChange={event => setincludeDownstreamStatuses(event.target.checked)}
                    />
                  }
                />
              )}
            </div>
          </Grid>
          {(isGlobalAdmin || userHasMultipleClients) && (
            <ClientSelector onChange={client => setClientId(client && client.id)}/>
          )}
        </Grid>
        <Grid>
          {stateStatsResource && (
            <Suspenseful component={HeatMap} stateStatsResource={stateStatsResource} funnelStatuses={funnelStatuses} downstreamStatuses={downstreamStatuses} allStatuses={allStatuses} includeDownstreamStatuses={includeDownstreamStatuses} range={range}/>
          )}
        </Grid>
      </div>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.chartSettings
        }}
        anchor="right"
      >
        <ChartSettings dateRange={dateRange} setDateRange={setDateRange}/>
      </Drawer>
    </>
  )
}
