import { useCallback, useState } from "react";
import MultiGroupSelect from ".";

export default {
  title: "UI/MultiGroupSelect",
  component: MultiGroupSelect,
  parameters: {
    zeplinLink:
      "https://app.zeplin.io/project/61c0cc344b8b6f135abce99a/screen/61f4095d86838c1093a6aeb9",
  },
  argTypes: {
    selectFirstByDefault: {
      control: false,
    },
    loading: {
      control: false,
    },
    disabled: {
      control: false,
    },
  },
};

const clientA = {
  id: 1,
  name: "Looney Tunes",
  internalName: "",
  fields: [
    {
      name: "Daffy Duck",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Tweety Bird",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Road Runner",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Wile E. Coyote",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Bugs Bunny",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
  ],
};

const clientB = {
  id: 2,
  name: "Dr. Seuss",
  internalName: "Seuss",
  fields: [
    {
      name: "Horton",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "The Grinch",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Dave",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Wild Green Pants",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
    {
      name: "Mayzie",
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ],
    },
  ],
};

const Template = (args) => {
  const [passedArgs, setPassedArgs] = useState(args);

  const handleSetFieldMapping = useCallback(
    (newFieldMapping) => {
      if (newFieldMapping[0].values.length > 0) {
        setPassedArgs({
          ...args,
          linkErrorsByClientId: {},
          fieldMapping: newFieldMapping,
        });
      } else {
        setPassedArgs({ ...args, fieldMapping: newFieldMapping });
      }
    },
    [args, setPassedArgs],
  );

  return (
    <div style={{ display: "flex" }}>
      <MultiGroupSelect
        clientFieldGroups={passedArgs.clientFieldGroups}
        fieldMapping={passedArgs.fieldMapping}
        setFieldMapping={handleSetFieldMapping}
        displayedOptionIndex={0}
        {...passedArgs}
      />
      <div>
        <h2>Current field mapping:</h2>
        <table
          border={2}
          cellPadding={4}
          style={{ borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th>Client ID</th>
              <th>Field name</th>
              <th>FieldMapping Value</th>
            </tr>
          </thead>
          <tbody>
            {passedArgs.fieldMapping.map((fieldMapEntry, index) => (
              <tr key={index}>
                <td>{fieldMapEntry.clientId}</td>
                <td>{fieldMapEntry.field}</td>
                <td>{JSON.stringify(fieldMapEntry.values)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const Default = Template.bind({});
Default.args = {
  inputLabel: "Question Match",
  linkErrorsByClientId: {},
  sx: { m: "1", width: 300 },
  clientFieldGroups: [{ client: clientA }, { client: clientB }],
  fieldMapping: [
    {
      clientId: 1,
      field: "Road Runner",
      values: ["1"],
    },
    {
      clientId: 2,
      field: "Horton",
      values: ["1"],
    },
  ],
};

export const Error = Template.bind({});
Error.args = {
  ...Default.args,
  linkErrorsByClientId: { 0: [1] },
  clientFieldGroups: [{ client: clientA }, { client: clientB }],
  fieldMapping: [
    {
      clientId: 1,
      field: "Road Runner",
      values: [],
    },
    {
      clientId: 2,
      field: "Horton",
      values: ["1"],
    },
  ],
};

// Component exludes clients in fieldMapping that are missing from clientFieldGroups
export const ClientMissing = Template.bind({});
ClientMissing.args = {
  inputLabel: "Question Match",
  linkErrorsByClientId: {},
  sx: { m: "1", width: 300 },
  clientFieldGroups: [{ client: clientA }],
  fieldMapping: [
    {
      clientId: 1,
      field: "Road Runner",
      values: ["1"],
    },
    {
      clientId: 2,
      field: "Horton",
      values: ["1"],
    },
  ],
};
