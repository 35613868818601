import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import Dialog from '@mui/material/Dialog';

import { DialogTitle, DialogContent } from '../../dialogs';
import SimpleTable from '../../SimpleTable';
import { failOnHttpError } from '../../../lib/utils'
import { loadTableData } from '../../table-utils';
import { Form, FormCheckbox, FormTextField, useFormikContext } from '../../Formik/forms';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check'
import { useParams } from "react-router-dom"
import MainContainer from '../../../components/layout/MainContainer/MainContainer'
import { useNotifications } from "lib/NotificationsProvider";
import { useOrbit } from "providers/OrbitProvider";
import { getRecordIdentity, store } from "lib/DataModel";
import { Roles } from "./Roles";
import { InitializedRecord } from '@orbit/records'
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Dropdown from "components/UI/Dropdown";


const COLUMNS = [
  { title: 'ID', field: 'id', type: 'numeric', width: '0px', visible: true },
  { title: 'Name', field: 'name', visible: true },
  { title: 'Email', field: 'email', visible: true },
  { title: 'Roles', render: (_value, row) => <Roles user={row}/>, visible: true },
  { title: 'Organization Admin?', field: 'permitOrganizationAdmin', render: permitOrganizationAdmin => permitOrganizationAdmin ? <CheckIcon/> : null, visible: true },
]

const noneOption = { id: '(none)', attributes: {name: '(none)'}}

const findMatchingRole = (roleOptions, selectedClientRoleIds) => {
  const selectedMatch = roleOptions.find(role =>
    selectedClientRoleIds.includes(role.id)
  )

  return selectedMatch || noneOption
}

function ClientRoleDropdown({ client, selectedClientRoleIds, setSelectedClientRoleIds }) {
  const { setFieldValue } = useFormikContext()

  const clientRoles = store.cache.query(q => q.findRelatedRecords(client, 'clientRoles')) as InitializedRecord[]
  const roleOptions = [noneOption, ...clientRoles]

  const selectedRole =  findMatchingRole(roleOptions, selectedClientRoleIds);

  // remove this client's client role ids and set to the item chosen
  const handleSelect = (selected) => {
    setSelectedClientRoleIds(prevIds => {
      const filteredIds = prevIds?.filter(id => !clientRoles.map(roles => roles.id).includes(id)) || []
      const updatedClientRoleIds = [
        ...filteredIds,
        selected.id
      ].filter(id => id !== noneOption.id)

      setFieldValue('clientRoleIds', updatedClientRoleIds)
      return updatedClientRoleIds
    })
  }

  const renderItem = useCallback((itemOption) => {
    return itemOption.attributes.name
  }, [])

  return (
    <Dropdown
      key={client.id}
      className='clientRoleSelector'
      label='Role'
      collection={roleOptions}
      item={selectedRole}
      setItem={handleSelect}
      renderItem={renderItem}
      selectFirstByDefault={false}
      sx={{ maxWidth: 'unset' }}
    />
  )
}

function ClientRoles({ user, organization }) {
  const userClientRoles = user.id ? store.cache.query(q => q.findRelatedRecords(user, 'clientRoles')) as InitializedRecord[] : []
  const [selectedClientRoleIds, setSelectedClientRoleIds] = useState(userClientRoles.map(role => role.id))
  const clients = store.cache.query(q => q.findRelatedRecords(organization, 'clients')) as InitializedRecord[]

  if(clients.length > 0) {
    return (
      <>
        <Typography variant="h2">Client Roles</Typography>
        { clients?.map(client => (
          <Stack key={client.id} direction='row' gap={4} sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
            <Box sx={{ width: '50%' }}>{`${client.attributes?.name}`}</Box>
            <Box sx={{ width: '50%' }}><ClientRoleDropdown client={client} selectedClientRoleIds={selectedClientRoleIds} setSelectedClientRoleIds={setSelectedClientRoleIds} /></Box>
          </Stack>
          ))
        }
      </>
    )
  } else {
    return null
  }
}

const AddUserModal = function(props) {
  const [user, setUser] = useState(props.user);

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={props.onClose}>
      <DialogTitle id="modal-title" onClose={props.onClose}>
        {user.id ? 'Edit' : 'Add'} user
      </DialogTitle>
      <DialogContent>
        <Form
          resource={user}
          setResource={setUser}
          relatedResource={props.organization}
          onSave={props.onSave}
          redirectAfterSave={false}
          promptOnNavigate={false}
          backwardsCompatibleMargins={true}
        >
          <FormTextField name="name" label="Name"/>
          <FormTextField name="email" label="Email" inputProps={{autoComplete: 'new-password'}}/>
          <FormTextField name="password" label="Password" type="password" inputProps={{autoComplete: 'new-password'}}/>
          <FormCheckbox name="permitOrganizationAdmin" label="Organization admin?"/>
          <ClientRoles user={user} organization={props.organization} />
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default function UserTable() {
  const { organizationId } = useParams()
  const { store, remote } = useOrbit()
  const { addNotification } = useNotifications()
  const [userForModal, setUserForModal] = useState<null | {type: null | string, id: null | string, attributes: null | {name: null | string, email: null | string, permitOrganizationAdmin: null | boolean, password: null | string}}>()
  const [organization, setOrganization] = useState();
  const [first, setFirst] = useState(true);
  const dataManagerRef = useRef(null);

  useEffect(() => {
    remote.query(q =>
      q.findRecord({type: "organization", id: organizationId}), {
        sources: {
          remote: {
            include: ['clients', 'clients.client_roles'],
            fields: { 'client': ['name', 'client_roles'], 'client_role': ['name'] }
          }
        }
      })
      .then(organization => {
        setOrganization(organization)
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to load organization.'});
      })
  }, [addNotification, organizationId, remote, store.cache]);

  const refreshTable = () => {
    dataManagerRef.current && dataManagerRef.current.reload();
  };

  useEffect(() => {
    if(first) {
      setFirst(false)
    } else {
      refreshTable()
    }
  }, [first, organizationId])

  const handleDelete = (_event, rowData) => {
    if(!confirm(`Are you sure you want to delete the user ${rowData['email']}?`)) {
      return;
    }

    fetch(`/api/v1/organizations/${organizationId}/users/${rowData.id}`, { method: 'DELETE' })
      .then(failOnHttpError)
      .then(() => {
        addNotification({variant: 'notice', message: 'Deleted user.'});
        refreshTable();
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to delete user.'});
      });
  };

  const handleEdit = (_event, rowData) => {
    const user = store.cache.query(q => q.findRecord(getRecordIdentity('user', rowData.id)))
    setUserForModal(user);
  };

  const handleAdd = (_event, _rowData) => {
    setUserForModal({type: 'user', id: null, attributes: {name: '', email: '', password: '', permitOrganizationAdmin: false}});
  };

  const closeModal = () => {
    setUserForModal(null);
  };

  const afterAddUser = (_user) => {
    closeModal();
    refreshTable();
  };

  const dataFn = useMemo(() => {
    if(organization) {
      return loadTableData(remote, q => q.findRelatedRecords(organization, 'users'), addNotification)
    } else {
      return []
    }
  }, [addNotification, organization, remote])

  return (
    <MainContainer>
      <div style={{ maxWidth: "100%" }}>
        <SimpleTable
          dataManagerRef={dataManagerRef}
          columns={COLUMNS}
          padding="dense"
          data={dataFn}
          options={{
            paginated: true,
            pageSize: 10,
            columnsButton: true,
          }}
          title={`Users for ${organization && organization.attributes.name || ''}`}
          rowActions={[
            {
              icon: () => <DeleteOutlineIcon/>,
              tooltip: 'Delete User',
              onClick: handleDelete,
            },
            {
              icon: () => <EditIcon/>,
              tooltip: 'Edit user',
              onClick: handleEdit,
            },
          ]}
          actions={[
            {
              icon: () => <AddCircleIcon/>,
              isFreeAction: true,
              tooltip: 'Add new user',
              onClick: handleAdd,
            },
          ]}
        />
      </div>
      {userForModal && (
        <AddUserModal
          user={userForModal}
          organization={organization}
          onClose={closeModal}
          onSave={afterAddUser}
        />
      )}
    </MainContainer>
  )
}
