import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import VegaIcon from 'vega-icon.svg';
import VegaLogo from 'vega-logo-white.svg';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { NotificationContent } from './UI/Notification';
import { ThemeProvider, styled } from '@mui/material/styles'
import { customTheme } from './Application';
import COLORS from 'lib/colors';
import Link from '@mui/material/Link';
import GoogleIcon from './UI/icons/GoogleIcon/GoogleIcon';
import MicrosoftIcon from './UI/icons/MicrosoftIcon/MicrosoftIcon';
import { NotificationVariant } from 'lib/NotificationsProvider';



export default function SignInPage(props) {
  const SignInButton = styled(Button)({
    height: '60px',
    borderRadius: '10px',
    border: `solid 1px ${COLORS.charcoal}`,
    textTransform: 'none',
    color: COLORS.charcoal,
    paddingX: '20px',
    fontSize: '16px',
    '.MuiSvgIcon-root': {
      fontSize: '30px !important'
    }
  })

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: '100vh', overflow: 'auto', backgroundImage: `linear-gradient(to bottom, ${COLORS.regalBlue}, ${COLORS.frenchBlue})`}}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
          position: 'relative',
        }}>
          <Box
            component="img"
            alt='V VEGA'
            src={VegaLogo}
            sx={{
              margin: '39px 0 0 33px',
              width: 130,
              height: 32,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Container component="main" maxWidth='sm' disableGutters sx={{ py: '100px' }}>
            <CssBaseline />
            <Card sx={{ borderRadius: '10px', padding: '48px 48px 24px 48px' }}>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box
                  component="img"
                  alt='VEGA'
                  src={VegaIcon}
                  sx={{
                    height: 120,
                    width: 127.4,
                  }}
                />
                <Typography component="h1" sx={{
                    fontSize: '32px',
                    fontWeight: 700,
                    color: COLORS.regalBlue,
                    marginTop: 4
                }}>
                  Welcome!
                </Typography>
                <Typography component="h2" variant='h1' sx={{marginTop: 1}}>
                  Sign in to access your VEGA account.
                </Typography>
                <Stack direction='row' spacing={2} marginTop='24px'>
                  <SignInButton
                    startIcon={<GoogleIcon />}
                    variant='outlined'
                    href='/auth/google?mode=login'
                  >
                    Sign in with Google
                  </SignInButton>
                  <SignInButton
                    startIcon={<MicrosoftIcon />}
                    variant='outlined'
                    href='/auth/microsoft?mode=login'
                  >
                    Sign in with Microsoft
                  </SignInButton>
                </Stack>
                <Divider light flexItem sx={{
                      fontSize: '12px',
                      color: COLORS.slateGray,
                      margin: '24px 0 18px 0 !important',
                      backgroundColor: 'unset',
                      '&::before': {
                        borderColor: COLORS.mediumGray,
                      },
                      '&::after': {
                        borderColor: COLORS.mediumGray,
                      }
                  }}>
                    Or sign in with
                </Divider>
                <Box sx={{ width: '80%' }}>
                  <form noValidate action="/users/sign_in" method="POST">
                    <input type="hidden" name="authenticity_token" value={props.authenticityToken} />
                    <Stack direction='column' gap={2}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="user[email]"
                        autoComplete="email"
                        autoFocus
                        sx={{
                          mt: 0
                        }}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="user[password]"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        sx={{
                          mt: 0
                        }}
                      />
                      <Box>
                        <Button
                          type="submit"
                          fullWidth
                          data-disable-with="Please wait..."
                          sx={{
                            height: '48px',
                            borderRadius: '10px',
                            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                            backgroundImage: `linear-gradient(to bottom, ${COLORS.regalBlue}, ${COLORS.frenchBlue})`,
                            textTransform: 'none',
                            color: 'white',
                          }}
                        >
                          Sign In
                        </Button>
                        <Box
                          sx={{
                            minHeight: '30px',
                            mt: '4px',
                            mb: '14px',
                            span: {
                              height: 'unset',
                              lineHeight: 'normal',
                              pt: '4px'
                            }
                          }}
                        >
                          {Object.entries(props.snackbars).map(([type, message], index)=> (
                              <NotificationContent key={index} notification={{variant: type as NotificationVariant, message: message as string}}/>
                          ))}
                        </Box>
                      </Box>
                    </Stack>
                  </form>
                </Box>
                <Box sx={{ textAlign: 'center', fontFamily: 'Nunito', fontSize: '12px', color: COLORS.slateGray }}>
                  Don&#39;t have an account? <Link href="https://www.cygnuseducation.com/get-in-touch/">Register Your Instituition</Link>
                </Box>
              </Box>
            </Card>
            <Box sx={{ textAlign: 'center', fontFamily: 'Nunito', fontSize: '13px', color: '#fff', mt: '48px' }}>
              © 2023 Cygnus Education, Inc.
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
