import moment from 'moment-timezone'
import { getRecordIdentity, getRemoteId } from '../lib/DataModel'
import { getIn } from '../components/Formik/forms'
import { buildQueryString, failOnHttpError, groupBy, notNullish, sum } from '../lib/utils'
import { camelize } from '@orbit/serializers'
import { InitializedRecord } from '@orbit/records'
import { CampaignType } from 'components/pages/LandingPages/data/types'

import PromisedResource from './PromisedResource'

interface PacingDataRow {
  clientCampaignId: number | string
  date: string
  mtdProjectedCount: number
  goodLeadCount: number
}

interface CurrentMonthPacingRow {
  clientCampaignId: string
  acceptedLeadCount: string
  vendorId: string
  contractId: string
  clientId: string
  programGroupId: string
  campaignType: CampaignType
  name: string
}

function getPacingData({ clientId, contract, timezone }): Promise<PacingDataRow[]> {
  const baseUrl = clientId ? `/api/v1/clients/${clientId}/pacing` : contract ? `/api/v1/contracts/${getRemoteId(contract)}/pacing` : `/api/v1/pacing`
  const queryString = buildQueryString({ timezone })
  const url = queryString ? `${baseUrl}?${queryString}` : baseUrl

  return fetch(url)
    .then(failOnHttpError)
    .then(response => response.json())
    .then(response =>
      response.data.pacing.map(row => Object.fromEntries(Object.entries(row).map(([key, value]) => [camelize(key), value])))
    )
}

const getCurrentAllocations = (remote, clientOrContract: InitializedRecord) => (
  remote.query(q =>
    (clientOrContract ? q.findRelatedRecords(clientOrContract, 'allocations') : q.findRecords('allocation'))
    .filter(
      {attribute: 'year', value: moment().year()},
      {attribute: 'month', value: moment().month()+1},
    )
  )
)

const getClientCampaigns = (remote, clientOrContract: InitializedRecord) => (
  remote.query(q =>
    (clientOrContract ? q.findRelatedRecords(clientOrContract, 'clientCampaigns') : q.findRecords('clientCampaign')), {
      sources: {
        remote: {
          include: ['contract','contract.vendor', 'program_group']
        }
      }
    }
  )
)

function extractEntities(store, row) {
  const clientCampaign = store.cache.query(q => q.findRecord(getRecordIdentity('clientCampaign', String(row.clientCampaignId)))) as InitializedRecord | undefined
  if(!clientCampaign) {
    return null
  }
  const contract = store.cache.query(q => q.findRelatedRecord(clientCampaign, 'contract')) as InitializedRecord | undefined
  if(!contract) {
    return null
  }
  const vendor = store.cache.query(q => q.findRelatedRecord(contract, 'vendor'))

  return { row, clientCampaign, contract, vendor }
}

const getPromise = ({ store, remote, clientId, contract, timezone }) : Promise<CurrentMonthPacingRow[]> => {
  const clientOrContract = clientId ? { type: 'client', id: clientId } : contract

  return Promise.all([getPacingData({ clientId, contract, timezone }), getCurrentAllocations(remote, clientOrContract), getClientCampaigns(remote, clientOrContract)])
    .then(([pacingData, allocations, _clientCampaigns]) => {
      const clientCampaignToRow = Object.fromEntries(
        pacingData
          .map(row => extractEntities(store, row))
          .filter(notNullish)
          .map(({ row, clientCampaign, contract, vendor }) => [
            row.clientCampaignId, {
              ...row,
              clientCampaignId: String(row.clientCampaignId),
              vendorId: getRemoteId(vendor),
              contractId: getRemoteId(contract),
              clientId: getIn(contract, 'relationships.client.data.id'),
              programGroupId: getIn(clientCampaign, 'relationships.programGroup.data.id'),
              campaignType: getIn(clientCampaign, 'attributes.campaignType'),
              name: getIn(clientCampaign, 'attributes.name'),
              monthlyCap: null
            }
          ])
      )
      groupBy(allocations, allocation => `${getRemoteId(allocation.relationships.clientCampaign.data)}`, {asEntries: true}).forEach(([keys, groupAllocations]) => {
        const [clientCampaignId] = keys.split('/')
        let row
        if(clientCampaignToRow[keys]) {
          row = clientCampaignToRow[keys]
        } else {
          const clientCampaign = store.cache.query(q => q.findRecord(getRecordIdentity('clientCampaign', clientCampaignId)))
          const contract = store.cache.query(q => q.findRelatedRecord(clientCampaign, 'contract'))
          const vendor = store.cache.query(q => q.findRelatedRecord(contract, 'vendor'))

          row = clientCampaignToRow[keys] = {
            clientCampaignId,
            acceptedLeadCount: 0,
            vendorId: getRemoteId(vendor),
            contractId: getRemoteId(contract),
            clientId: getIn(contract, 'relationships.client.data.id'),
            programGroupId: getIn(clientCampaign, 'relationships.programGroup.data.id'),
            campaignType: getIn(clientCampaign, 'attributes.campaignType'),
            name: getIn(clientCampaign, 'attributes.name'),
          }
        }
        row.monthlyCap = groupAllocations.some(allocation => !getIn(allocation, 'attributes.cap') && getIn(allocation, 'attributes.cap') !== 0) ? null : sum(groupAllocations, 'attributes.cap')
      })
      return Object.values(clientCampaignToRow)
    })
}

export default class CurrentMonthPacingResource extends PromisedResource<CurrentMonthPacingRow[]> {
  constructor({ store, remote, clientId = '', contract = null, timezone }) {
    super(getPromise({ store, remote, clientId, contract, timezone }))
  }
}
