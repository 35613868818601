export default {
  "__typename": "ClientCampaignRule",
  "id": "14",
  "default": false,
  "clientMapping": [
    {
      "__typename": "ClientMapping",
      "clientId": "188",
      "programGroupId": "216",
      "clientCampaignIds": ["996697a3"]
    },
    {
      "__typename": "ClientMapping",
      "clientId": "29",
      "programGroupId": "9",
      "clientCampaignIds": ["9d1b47c"]
    }
  ],
  "conditions": [
    {
      "__typename": "RuleCondition",
      "id": "cond1",
      "key": "GraduationYear",
      "operator": "in",
      "value": ["2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
      "question": null
    }
  ]
}
