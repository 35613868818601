import { createContext, useContext, useEffect, useState } from 'react'

// From https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

const viewportContext = createContext({ width: null, height: null })

export default function ViewportProvider({ children }) {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  )
}

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export function useViewport() {
  return useContext(viewportContext)
}
