import PromisedResource from './PromisedResource'

function getOptions(year, month) {
  return {
    sources: {
      remote: {
        settings: {
          params: {
            year: year,
            month: month,
          }
        }
      }
    }
  }
}

function getPromise(remote, contract, year, month) {
  return remote.query(q => q.findRelatedRecords(contract, 'dailyCaps'), getOptions(year, month))
}

export default class DailyCapsResource extends PromisedResource {
  constructor({remote, contract, year, month}) {
    super(getPromise(remote, contract, year, month))
    this.contract = contract
    this.year = year
    this.month = month
  }
}
