import Paper from '@mui/material/Paper'
import type { Column } from 'components/PerformanceTable/utils'
import { DataManagerProvider } from './DataManager'
import Box from '@mui/material/Box'

export default function TableContainer({
  columns,
  data,
  options,
  sx = null,
  onChangeColumns = null,
  dataManagerRef = null,
  onChange = null,
  hasTabNavigation=false,
  children,
}: {
  columns: Column[],
  data,
  options,
  sx,
  onChangeColumns,
  dataManagerRef,
  onChange,
  hasTabNavigation,
  children,
}
) {
  return (
    <DataManagerProvider columns={columns} data={data} options={options} onChangeColumns={onChangeColumns} dataManagerRef={dataManagerRef} onChange={onChange}>
      { hasTabNavigation &&
        <Box sx={{pt: 4, pb: 6, maxHeight: '100%', display: 'flex', flexDirection: 'column', ...sx}}>
          {children}
        </Box>
      ||
        <Paper sx={{p: '25px 30px', maxHeight: '100%', display: 'flex', flexDirection: 'column', ...sx}}>
          {children}
        </Paper>
      }
    </DataManagerProvider>
  )
}
