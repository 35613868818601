import { InitializedRecord, RecordOperation, RecordTransform, recordDiffs } from '@orbit/records'
import { buildTransform } from '@orbit/data'
import JSONAPISource, { JSONAPISerializers, ResourceDocument, RecordTransformRequest, JSONAPIRequestOptions, JSONAPIDocumentSerializer, RecordDocument, JSONAPIResponse, TransformRequestProcessorResponse, JSONAPIRequestProcessor, AddRecordRequest, UpdateRecordRequest } from '@orbit/jsonapi'
import { isNew } from 'lib/DataModel'


/**
 * The following is copied verbatim from @orbit/jsonapi/src/lib/transform-requests.ts
 */
function handleChanges(
  record: InitializedRecord,
  recordDoc: RecordDocument,
  details: JSONAPIResponse
): TransformRequestProcessorResponse {
  const updatedRecord: InitializedRecord = recordDoc.data as InitializedRecord;
  const transforms: RecordTransform[] = [];
  const updateOps = recordDiffs(record, updatedRecord);
  if (updateOps.length > 0) {
    transforms.push(buildTransform(updateOps));
  }
  if (recordDoc.included && recordDoc.included.length > 0) {
    const includedOps = recordDoc.included.map((record) => {
      return { op: 'updateRecord', record } as RecordOperation;
    });
    transforms.push(buildTransform<RecordOperation>(includedOps));
  }
  return { transforms, data: updatedRecord, details };
}



async function addRecordWithPayload(requestProcessor: JSONAPIRequestProcessor, request: RecordTransformRequest, requestDoc: ResourceDocument): Promise<TransformRequestProcessorResponse> {
  /*
   * The following is copied from @orbit/jsonapi/src/lib/transform-requests.ts,
   * with the construction of requestDoc removed and replaced with the
   * passed-in requestDoc.
   */
  const { record } = request as AddRecordRequest;
  const serializer = requestProcessor.serializerFor(
    JSONAPISerializers.ResourceDocument
  ) as JSONAPIDocumentSerializer;
  const settings = {
    ...requestProcessor.buildFetchSettings(request),
    method: 'POST',
    json: requestDoc
  };
  const url =
    request.options?.url ??
    requestProcessor.urlBuilder.resourceURL(record.type);

  const details = await requestProcessor.fetch(url, settings);
  const document = details.document as ResourceDocument;
  requestProcessor.preprocessResponseDocument(document, request);

  const recordDoc = serializer.deserialize(document, {
    primaryRecord: record
  });
  return handleChanges(record, recordDoc, details);
}

async function updateRecordWithPayload(requestProcessor: JSONAPIRequestProcessor, request: RecordTransformRequest, requestDoc: ResourceDocument): Promise<TransformRequestProcessorResponse> {
  /*
   * The following is copied from @orbit/jsonapi/src/lib/transform-requests.ts,
   * with the construction of requestDoc removed and replaced with the
   * passed-in requestDoc.
   */
  const { record } = request as UpdateRecordRequest;
  const { type, id } = record;
  const serializer = requestProcessor.serializerFor(
    JSONAPISerializers.ResourceDocument
  ) as JSONAPIDocumentSerializer;
  const settings = {
    ...requestProcessor.buildFetchSettings(request),
    method: 'PATCH',
    json: requestDoc
  };
  const url =
    request.options?.url ?? requestProcessor.urlBuilder.resourceURL(type, id);

  const details = await requestProcessor.fetch(url, settings);
  const { document } = details;
  if (document) {
    requestProcessor.preprocessResponseDocument(document, request);
    const recordDoc = serializer.deserialize(document, {
      primaryRecord: record
    });
    return handleChanges(record, recordDoc, details);
  } else {
    return { transforms: [], data: record, details };
  }

}

export async function saveWithPayload(remote: JSONAPISource, record: InitializedRecord, requestDoc: ResourceDocument, options: JSONAPIRequestOptions = {}) {
  const { requestProcessor } = remote;
  let result : TransformRequestProcessorResponse

  if (isNew(record)) {
    const request: RecordTransformRequest = {
      op: 'addRecord',
      options,
      record
    }
    result = await addRecordWithPayload(requestProcessor, request, requestDoc)
  }
  else {
    const request: RecordTransformRequest = {
      op: 'updateRecord',
      options,
      record
    }
    result = await updateRecordWithPayload(requestProcessor, request, requestDoc)
  }

  return result.data
}
